<template>
    <div
        :class="{
            selection,
            inline,
            dense,
            size,
        }"
        class="ws-select-color-item"
    >
        <div
            class="color-item"
            :style="{ 'background-color': localColor }"
        />
        <span v-if="!colorOnly">{{ colorTranslation }}</span>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StampColorsPaletteRGBEnum, StampColorsPaletteRGBText } from '@/domain/stamp/constants/StampColorsPaletteRGBEnum';

@Component
export default class WsSelectColorItem extends Vue {
    @Prop({ required: true }) public color!: any;
    @Prop({ type: String, default: '' }) public colorTextKey!: string;
    @Prop({ default: 'default' }) public size!: 'default' | 'mini';
    // default - 38px, mini - 16px

    @Prop({ default: false, type: Boolean }) public rgb!: boolean;
    @Prop({ default: false, type: Boolean }) public selection!: boolean;
    @Prop({ default: false, type: Boolean }) public inline!: boolean;
    @Prop({ default: false, type: Boolean }) public dense!: boolean;
    @Prop({ default: false, type: Boolean }) public colorOnly!: boolean;

    public StampColorsPaletteRGBText = StampColorsPaletteRGBText;
    public StampColorsPaletteRGBEnum = StampColorsPaletteRGBEnum;

    get localColor() {
        if (this.rgb && this.color) {
            const colors = this.color?.split(',');
            return `rgb(${colors?.[0]},${colors?.[1]},${colors?.[2]})`;
        } else {
            return this.color;
        }
    }

    get colorTranslation() {
        if (this.colorTextKey) {
            return this.$t(`Colors.${this.colorTextKey}`);
        }

        const colorIndex = Number(StampColorsPaletteRGBEnum[this.color]);

        if (isNaN(colorIndex)) {
            return '';
        }

        return this.$t(`Colors.${StampColorsPaletteRGBText[colorIndex]}`);
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.ws-select-color-item {
    line-height: 24px;
    display: flex;
    width: 200px;

    &.selection {
        margin: 0;
    }

    &.mini {
        line-height: $line-height-small;

        .color-item {
            width: 16px;
            height: 16px;
        }
    }

    &.dense {
        line-height: $line-height-large;

        .color-item {
            height: 24px;
            width: 48px;
        }

        &.mini {
            line-height: $line-height-small;

            .color-item {
                width: 32px;
                height: 16px;
            }
        }
    }

    &.inline {
        display: inline-block;
        margin: 0;
        width: auto;

        .color-item {
            display: inline-block;
            margin: 0 6px -3px 0;
        }
    }
}

.color-item {
    border-radius: 38px;
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
}
</style>
