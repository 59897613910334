import _ from 'lodash';
import Vue from 'vue';
import { Dict } from '@/types/Dict';
import LicenseApi, { IMemberChangeAuthMethod } from '@/api/license.api';
import { AuthMethod, IBulkResult } from '@/models';

export interface IAuthMethodsStorage {
    authMethods: Dict<AuthMethod[]>;
    isLoadingAuthMethod: boolean;
    isSendingCreateAuthMethod: boolean;
    isSendingUpdateAuthMethod: boolean;
    isSendingDeleteAuthMethod: boolean;
    isNoPermission: Dict<boolean>;
}

export default {
    state: {
        authMethods: {},
        isLoadingAuthMethod: false,
        isSendingCreateAuthMethod: false,
        isSendingUpdateAuthMethod: false,
        isSendingDeleteAuthMethod: false,
        isNoPermission: {},
    },
    getters: {
        authMethodsByLicenseUuid(state: IAuthMethodsStorage): (licenseUuid: string) => AuthMethod[] {
            return (licenseUuid) => state.authMethods[licenseUuid] || [];
        },
        authMethodsByLicenseUuidByUuid(_state: IAuthMethodsStorage, getters: any): (licenseUuid: string, uuid: string) => Dict<AuthMethod> {
            return _.memoize((licenseUuid) =>
                _.keyBy(getters.authMethodsByLicenseUuid(licenseUuid), 'uuid') as Dict<AuthMethod>,
            );
        },
        activeAuthMethodsByLicenseUuid(state: IAuthMethodsStorage, getters: any): (licenseUuid: string) => AuthMethod[] {
            return (licenseUuid) => getters.authMethodsByLicenseUuid(licenseUuid)
                .filter(({ active }: AuthMethod) => active);
        },
        isLoadingAuthMethods(state: IAuthMethodsStorage): boolean {
            return state.isLoadingAuthMethod;
        },
        isSendingCreateAuthMethod(state: IAuthMethodsStorage): boolean {
            return state.isSendingCreateAuthMethod;
        },
        isSendingUpdateAuthMethod(state: IAuthMethodsStorage): boolean {
            return state.isSendingUpdateAuthMethod;
        },
        isSendingDeleteAuthMethod(state: IAuthMethodsStorage): boolean {
            return state.isSendingDeleteAuthMethod;
        },
        isNoPermissionToAuthMethods(state: IAuthMethodsStorage): (licenseUuid: string) => boolean {
            return (licenseUuid) => state.isNoPermission[licenseUuid];
        },
    },
    mutations: {
        setAuthMethods(
            state: IAuthMethodsStorage,
            { licenseUuid, authMethods }: { licenseUuid: string; authMethods: AuthMethod[] },
        ) {
            Vue.set(state.authMethods, licenseUuid, authMethods);
        },
        setIsLoadingAuthMethods(state: IAuthMethodsStorage, value: boolean) {
            state.isLoadingAuthMethod = value;
        },
        setIsSendingCreateAuthMethod(state: IAuthMethodsStorage, value: boolean) {
            state.isSendingCreateAuthMethod = value;
        },
        setIsSendingUpdateAuthMethod(state: IAuthMethodsStorage, value: boolean) {
            state.isSendingUpdateAuthMethod = value;
        },
        setIsSendingDeleteAuthMethod(state: IAuthMethodsStorage, value: boolean) {
            state.isSendingDeleteAuthMethod = value;
        },
        setIsNoPermission(state: IAuthMethodsStorage, { licenseUuid, value }: { licenseUuid: string, value: boolean }) {
            Vue.set(state.isNoPermission, licenseUuid, value);
        },
    },
    actions: {
        loadAuthMethods({ commit }: any, { licenseUuid }: { licenseUuid: string }): Promise<AuthMethod[]> {
            commit('setIsLoadingAuthMethods', true);
            return LicenseApi.getAuthMethods(licenseUuid).then((response) => {
                const authMethods = response.map((authMethod: any) => new AuthMethod(authMethod));
                commit('setAuthMethods', { licenseUuid, authMethods });
                commit('setIsNoPermission', { licenseUuid, value: false });
                return authMethods;
            }).catch(() => {
                commit('setIsNoPermission', { licenseUuid, value: true });
            }).finally(() => {
                commit('setIsLoadingAuthMethods', false);
            });
        },
        sendCreateAuthMethod({ commit }: any, params: any): Promise<AuthMethod>  {
            commit('setIsSendingCreateAuthMethod', true);
            return LicenseApi.postCreateAuthMethod(params).finally(() => {
                commit('setIsSendingCreateAuthMethod', false);
            });
        },
        sendUpdateAuthMethod({ commit }: any, params: any): Promise<AuthMethod>  {
            commit('setIsSendingUpdateAuthMethod', true);
            return LicenseApi.postUpdateAuthMethod(params).finally(() => {
                commit('setIsSendingUpdateAuthMethod', false);
            });
        },
        sendChangeActivityAuthMethod(
            context: any,
            { authMethod, switchUuid, active }
                : { authMethod: AuthMethod, switchUuid?: string, active?: boolean },
        ): Promise<AuthMethod>  {
            const switchUuidPart = switchUuid ? { switchUuid } : {};
            return LicenseApi.postChangeActivityAuthMethod({
                uuid: authMethod.uuid,
                active: active || Number(authMethod.active),
                ...switchUuidPart,
            });
        },
        sendDeleteAuthMethod({ commit }: any, params: { uuid: string, switchUuid: string }): Promise<AuthMethod>  {
            commit('setIsSendingDeleteAuthMethod', true);
            return LicenseApi.postDeleteAuthMethod(params).finally(() => {
                commit('setIsSendingDeleteAuthMethod', false);
            });
        },
        sendChangeAuthMethod(context: any, params: IMemberChangeAuthMethod): Promise<IBulkResult>  {
            return LicenseApi.postChangeAuthMethod(params);
        },
    },
};
