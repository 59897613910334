<template>
    <div class="table-select" data-test="t-select-all">
        <v-simple-checkbox
            :value="isSelectedAllItemsOnPage"
            :indeterminate="props.indeterminate"
            :disabled="items.length === 0"
            primary
            hide-details
            @click="checkboxAction"
        />

        <v-menu offset-y>
            <template #activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="onOpenMenu"
                >
                    <span class="arrow-down" />
                </v-btn>
            </template>

            <v-list data-test="t-select-options-list">
                <v-list-item @click="selectCurrentPage">{{ $t('Table.selectCurrentPage') }}</v-list-item>
                <v-list-item @click="selectAllPages">{{ $t('Table.selectAllPages') }}</v-list-item>
                <v-list-item @click="unselectCurrentPage">{{ $t('Table.unselectCurrentPage') }}</v-list-item>
                <v-list-item @click="unselectAllPages">{{ $t('Table.unselectAllPages') }}</v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { amplitudeLog } from '@/services';

interface WsTableSelectLogKeys {
    openMenu: string;
    selectCurrentPage: string;
    selectAllPages: string;
    unselectCurrentPage: string;
    unselectAllPages: string;
}

@Component
export default class WsTableSelect extends Vue {
    @Prop({ required: true }) public items!: any[];
    @Prop({ required: true }) public selected!: any[];
    @Prop({ required: true }) public props!: any;
    @Prop({ required: true }) public tableRef!: any;
    @Prop() public logKeys!: WsTableSelectLogKeys;

    get itemsOnCurrentPage(): any[] {
        return this.tableRef?.internalCurrentItems || [];
    }
    get selectedItemsOnOthersPages(): any[] {
        return _.difference(this.selected, this.itemsOnCurrentPage);
    }
    get isSelectedAllItemsOnPage(): boolean {
        return Boolean(this.itemsOnCurrentPage.length)
            && this.itemsOnCurrentPage.length === _.intersection(this.selected, this.itemsOnCurrentPage).length;
    }
    get isSelectedAtLeastOneItemOnPage(): boolean {
        return Boolean(_.intersection(this.selected, this.itemsOnCurrentPage).length);
    }

    @Emit()
    public change(items: any[]): any[] {
        return items;
    }

    @Emit()
    public selectCurrent() {
        return;
    }

    @Emit()
    public unselectCurrent() {
        return;
    }

    public onOpenMenu() {
        if (this.logKeys?.openMenu) {
            amplitudeLog(this.logKeys.openMenu);
        }
    }

    public checkboxAction() {
        const selectedItemsOnCurrentPage = this.isSelectedAtLeastOneItemOnPage ? [] : this.itemsOnCurrentPage;
        this.change(_.concat(this.selectedItemsOnOthersPages, selectedItemsOnCurrentPage));
        if (selectedItemsOnCurrentPage.length) {
            this.selectCurrent();
            if (this.logKeys?.selectCurrentPage) {
                amplitudeLog(this.logKeys.selectCurrentPage);
            }
        } else {
            if (this.logKeys?.unselectCurrentPage) {
                amplitudeLog(this.logKeys.unselectCurrentPage);
            }
            this.unselectCurrent();
        }
    }

    public selectCurrentPage() {
        this.change(this.itemsOnCurrentPage);
        this.selectCurrent();

        if (this.logKeys?.selectCurrentPage) {
            amplitudeLog(this.logKeys.selectCurrentPage);
        }
    }

    public selectAllPages() {
        this.change(this.items);

        if (this.logKeys?.selectAllPages) {
            amplitudeLog(this.logKeys.selectAllPages);
        }
    }

    public unselectCurrentPage() {
        this.change(this.selectedItemsOnOthersPages);
        this.unselectCurrent();

        if (this.logKeys?.unselectCurrentPage) {
            amplitudeLog(this.logKeys.unselectCurrentPage);
        }
    }

    public unselectAllPages() {
        this.change([]);
        this.unselectCurrent();

        if (this.logKeys?.unselectAllPages) {
            amplitudeLog(this.logKeys.unselectAllPages);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.table-select {
    display: flex;

    .arrow-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $select-black;
    }
}

::v-deep .v-input--selection-controls__ripple {
    height: 28px;
    width: 22px;
    left: -6px;
    top: calc(50% - 21px);
    border-radius: $border-radius-small;
    color: rgba(0, 0, 0, 0.4);
}

::v-deep .v-input--selection-controls__input {
    margin-right: 0 !important;
}

::v-deep .v-btn--icon.v-size--default {
    height: 36px;
    width: 22px;
    border-radius: $border-radius-small;
}

::v-deep .v-ripple__container {
    display: none;
}

::v-deep .theme--light.v-btn.v-btn--icon {
    color: rgba(0, 0, 0, 0.4);
}

::v-deep .theme--light.v-btn {
    &:focus::before,
    &:hover::before {
        opacity: 0.2;
    }
}
</style>
