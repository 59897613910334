<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :editable="editable"
        :label="label"
        :multi-text="showFieldMultipleText"
        :multi-changed="isNewMultiValue"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <WsSwitch
            v-if="!isMultiselectEditModeActive"
            v-model="currentValue"
            :disabled="isCurrentIssueDeleted || !editable"
        />

        <template v-if="isMultiselectEditModeActive">
            {{ currentValue ? $t('Simple_word.yes') : $t('Simple_word.no') }}
        </template>

        <template #action>
            <IconSvg16
                v-if="!inlineEdit && isMultiselectEditModeActive"
                icon-name="edit"
                stateless
                @click="startEdit"
            />

            <div v-if="inlineEdit" class="visibility-popup">
                <WsButton
                    type="primary"

                    @click="setValue(true)"
                >
                    {{ $t('Simple_word.yes') }}
                </WsButton>
                <WsButton
                    @click="setValue(false)"
                >
                    {{ $t('Simple_word.no') }}
                </WsButton>
            </div>
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import WsSwitch from '@/components/common/WsSwitch.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IssueStatus from '@/domain/issue/components/IssueStatus.vue';
import { Issue } from '@/domain/issue/models/Issue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';

@Component({
    components: {
        IssueDetailsItem,
        IssueStatus,
        WsSwitch,
        IconSvg16,
    },
})
export default class IssueDetailsVisibility extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    public inlineEdit = false;
    public localValue: boolean | undefined = undefined;

    get isCurrentIssueDeleted() {
        return this.currentIssue.isDeleted;
    }
    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get currentValue(): boolean {
        if (this.multiEditNewValues) {
            return this.multiEditNewValues.value;
        }
        return this.showFieldMultipleText
            ? this.multiSelectIssueValues
            : this.currentIssue[this.valueKey as keyof Issue];
    }

    set currentValue(value) {
        this.handleChange(value);
    }

    public handleChange(value: boolean) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
        }
    }

    public setValue(value: boolean) {
        this.localValue = value;
        this.handleSave();
    }

    public handleSave() {
        if (this.localValue !== this.currentValue) {
            this.change(this.localValue);
        }
        this.stopEdit();
    }

    public startEdit() {
        this.inlineEdit = true;
    }

    public stopEdit() {
        this.inlineEdit = false;
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.pointer {
    cursor: pointer;
}

.visibility-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    background: white;
    border: solid 1px $divider-gray;
    border-radius: 6px;

}
</style>
