import { IReleaseNote, ReleaseNoteComponent } from '@/types/ReleaseNotes';

export class ReleaseNote implements IReleaseNote {
    public id: number;
    public title: string;
    public components: ReleaseNoteComponent[];
    public releaseMonth: string;
    public notificationDate: string;
    public content: string;

    constructor(releaseNote: IReleaseNote | any = {}) {
        this.id = releaseNote.id || 0;
        this.title = releaseNote.title || '';
        this.components = releaseNote.components || [];
        this.releaseMonth = releaseNote.releaseMonth || '';
        this.content = releaseNote.content || '';
        this.notificationDate = releaseNote.notificationDate || '';
    }

    get releaseNoteYear() {
        return this.releaseMonth.split('-')[0];
    }
}
