<template>
    <div :class="[{ 'ws-select-wrapper': isNew }]">
        <label v-if="isNew">{{ label }}</label>
        <v-select
            ref="vselect"
            v-model="model"
            :attach="attach"
            :items="localItemsFiltered"
            :label="isNew ? undefined : label"
            :outlined="outlined"
            :dense="dense"
            :error-count="maxMessages"
            :messages="messages"
            :error-messages="errorMessages"
            :success-messages="successMessages"
            :error="error"
            :rules="rules"
            :hide-details="hideDetails"
            :hide-selected="hideSelected"
            :required="required"
            :placeholder="placeholder"
            :clearable="clearable"
            :item-text="itemText"
            :item-value="itemValue"
            :item-disabled="itemDisabled"
            :disabled="disabled"
            :append-icon="icon"
            :return-object="returnObject"
            :multiple="multiple"
            :autofocus="autofocus"
            :rounded="rounded"
            :class="{
                'ws-select': isNew,
                ['spacing-' + spacing]: true,
            }"
            :ripple="false"
            @change="change"
            @input="input"
            @click="updateTestAttributes"
        >
            <slot />
            <template v-if="$slots.item || $scopedSlots.item" #item="{ item }">
                <div :class="{ 'amp-mask': ampMask }">
                    <slot :item="item" name="item" />
                </div>
            </template>
            <template v-else-if="!($slots.item || $scopedSlots.item)" #item="{ item }">
                <div class="v-list-item__content">
                    <div class="v-list-item__title">
                        <WsTruncateAuto
                            :value="getItemText(item) || item"
                            :limit="selectLimit"
                            :css-class="ampMask ? 'amp-mask' : ''"
                            single-line
                        />
                    </div>
                </div>
            </template>
            <template #label v-if="required">
                <span class="required-star">*</span>{{ label }}
            </template>
            <template v-if="showSelectionSlot" #selection="{ item }">
                <div :class="{ 'amp-mask': ampMask, 'v-select__slot': !multiple }">
                    <slot v-if="showSelectionSlot" :item="item" name="selection" />
                    <div v-else :class="{ 'v-select__selections': !multiple }">
                        <span class="v-select__selection v-select__selection--comma">{{ getText(item) }}</span><span v-if="multiple && index + 1 < numSelected">,&nbsp;</span>
                    </div>
                </div>
            </template>
            <template v-if="searchable || allowSelectAll" #prepend-item>
                <v-list-item v-if="searchable">
                    <v-list-item-content>
                        <v-text-field
                            :value="searchString"
                            :placeholder="$t('Simple_word.search')"
                            autofocus
                            outlined
                            dense
                            clearable
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            @blur="blurSearchString"
                            @input="searchString = $event"
                        />
                    </v-list-item-content>
                </v-list-item>
                <template v-if="allowSelectAll">
                    <v-list-item
                        ripple
                        @click="toggleSelectAll"
                    >
                        <template>
                            <v-list-item-action>
                                <v-checkbox
                                    :input-value="allSelected"
                                    :indeterminate="someSelected"
                                />
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title><span class="select-all">{{ $t('Text.selectAll') }}</span></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <hr class="ws-select-hr">
                </template>
                <template v-if="$slots.prepend">
                    <slot name="prepend" />
                </template>
            </template>
        </v-select>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { MULTIPLE } from '@/constants/Common';
import VuetifyElement from '@/components/common/VuetifyElement.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';

// https://vuetifyjs.com/en/api/v-select/#props

@Component({
    components: {
        WsTruncateAuto,
    },
})
export default class WsSelect extends VuetifyElement {
    @Prop({ required: true, default: () => [] }) public items!: any[] | { [key: string]: any }; // можно закидывать объекты, обработаются только значения, без ключей
    @Prop({ default: () => [] }) public disabledValues!: any[];
    @Prop() public translationKey!: string;
    @Prop({ default: 'text' }) public itemText!: string | ((item: any) => TranslateResult);
    @Prop({ default: 'value' }) public itemValue!: string;
    @Prop({ default: 'disabled' }) public itemDisabled!: any;
    @Prop({ type: Boolean, default: false }) public multiple!: boolean;
    @Prop({ type: Boolean, default: false }) public rounded!: boolean;
    @Prop({ type: Boolean, default: false }) public returnObject!: boolean;
    @Prop({ type: Boolean, default: false }) public hideSelected!: boolean;
    @Prop({ type: Boolean, default: false }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public allowSelectAll!: boolean;
    @Prop({ type: Boolean, default: false }) public showSelectionSlot!: boolean;
    @Prop({ type: Boolean, default: false }) public autofocus!: boolean;
    @Prop({ type: String }) public selectDataTest!: string;
    @Prop({ type: String }) public selectedItemDataTest!: string;
    @Prop({ type: String }) public labelDataTest!: string;
    @Prop({ type: String }) public itemsListDataTest!: string;
    @Prop({ type: String }) public clearButtonDataTest!: string;
    @Prop({ type: Number }) public truncateLength!: number;
    @Prop({ type: Number, default: 200 }) public selectLimit!: number;
    @Prop({ type: Boolean, default: false }) public new!: boolean;
    @Prop({ type: Boolean, default: false }) public small!: boolean;
    @Prop({ type: Boolean, default: false }) public addMultipleOption!: boolean;
    @Prop() public appendIcon!: string;
    @Prop({ required: false }) public attach!: boolean;
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;

    public searchString: string = '';
    public headerKey: string = 'header';
    public isItemsListDataTestAdded: boolean = false;
    public isSelectDataTestAdded: boolean = false;

    get numSelected() {
        return _.isArray(this.value) ? this.value.length : 0;
    }

    get isNew() {
        return this.new;
    }

    get spacing() {
        if (this.small) {
            return 'small';
        }

        return 'medium';
    }

    get icon() {
        if (this.appendIcon) {
            return this.appendIcon;
        }

        return 'mdi-menu-down';
    }

    get localItems() {
        const items = _.isPlainObject(this.items) ? Object.values(this.items) : this.items;
        const multipleItem = { text: this.$t('Collocation.multiple'), value: MULTIPLE };

        if (this.translationKey) {
            const optionsWithTranslations = items.map((item: any) => {
                const value = (typeof item === 'object') ? item[this.itemValue] : item;
                const partOfKey = (typeof item === 'object') ? this.getItemText(item) : item;
                const text = item.value === MULTIPLE ? item.text : this.$t(`${this.translationKey}.${partOfKey }`);
                const disabled = item?.disabled ?? this.disabledValues.includes(value);

                return {
                    disabled,
                    text,
                    value,
                };
            });

            if (this.addMultipleOption) {
                return [...optionsWithTranslations, multipleItem];
            } else {
                return optionsWithTranslations;
            }
        }

        if (this.addMultipleOption) {
            return [...items as any[], multipleItem];
        }

        return items;
    }

    get localItemsFiltered() {
        if (!this.searchable || !this.searchString) {
            return this.localItems;
        }
        const toLowerTrim = (str: string) => {
            return str.toLowerCase().trim();
        };
        const normalizedSearch = toLowerTrim(this.searchString);
        return this.localItems.filter((item: any) => {
            const normalizedItemText = toLowerTrim(this.getItemText(item) || item[this.headerKey]);
            return normalizedItemText.includes(normalizedSearch);
        });
    }

    get allSelected() {
        return (this.model?.length === this.localItemsFiltered?.length) && (this.localItemsFiltered.length > 0);
    }

    get someSelected() {
        return this.model?.length > 0 && !this.allSelected;
    }

    @Emit()
    public click() {}

    @Watch('model')
    public onChangeModel(value: any) {
        if (value) {
            this.addDataTestToFirstSelectedItem();
            this.addDataTestToClearButton();
        }
    }

    public mounted() {
        if (this.selectDataTest && !this.isSelectDataTestAdded) {
            this.addDataTestToSelect();
        }

        if (this.itemsListDataTest && !this.isItemsListDataTestAdded) {
            this.addDataTestToItemsList();
        }

        if (this.labelDataTest) {
            this.addDataTestToLabel();
        }

        if (this.selectedItemDataTest) {
            this.addDataTestToFirstSelectedItem();
        }
    }

    public getText(item: any) {
        if (_.isString(this.itemText)) {
            return item[this.itemText as string];
        } else {
            // @ts-ignore
            return this.itemText(item);
        }
    }

    public updateTestAttributes() {
        this.click();
        if (this.itemsListDataTest && !this.isItemsListDataTestAdded) {
            this.addDataTestToItemsList();
        }
    }

    public toggleSelectAll() {
        this.$nextTick(() => {
            if (this.allSelected) {
                this.model = [];
            } else {
                this.model = this.localItemsFiltered.map((item: any) => item[this.itemValue]);
            }
        });
    }

    public async blurSearchString() {
        await this.$nextTick();
        this.searchString = '';
    }

    public addDataTestToItemsList() {
        this.$nextTick(() => {
            const vSelectEl = this.$refs.vselect as Vue;
            const menuEl = vSelectEl?.$refs?.menu as Vue;
            const itemsListEl = menuEl?.$refs?.content as HTMLDivElement;
            const listBoxEl = itemsListEl?.firstChild as HTMLDivElement;
            if (!listBoxEl) {
                return;
            }

            listBoxEl.dataset.test = this.itemsListDataTest;
            this.isItemsListDataTestAdded = true;
        });
    }

    public addDataTestToSelect() {
        this.$nextTick(() => {
            const vSelectButtonEl = this.$el.querySelector('[role=button]') as HTMLDivElement;
            if (!vSelectButtonEl) {
                return;
            }

            vSelectButtonEl.dataset.test = this.selectDataTest;
            this.isSelectDataTestAdded = true;
        });
    }

    public addDataTestToLabel() {
        const vSelectEl = this.$refs.vselect as Vue;
        const labelEl = vSelectEl.$refs.label as HTMLDivElement;

        if (!labelEl) {
            return;
        }

        labelEl.dataset.test = this.labelDataTest;
    }

    public addDataTestToFirstSelectedItem() {
        this.$nextTick(() => {
            const selectionEl = this.$el.querySelector('.v-select__selection') as HTMLDivElement;
            if (!selectionEl) {
                return;
            }

            selectionEl.dataset.test = this.selectedItemDataTest;
        });
    }

    public addDataTestToClearButton() {
        this.$nextTick(() => {
            const clearButtonDivEl = this.$el.querySelector('.v-input__icon--clear ') as HTMLDivElement;
            if (!clearButtonDivEl) {
                return;
            }

            const buttonEl = clearButtonDivEl.firstChild as HTMLButtonElement;
            buttonEl.dataset.test = this.clearButtonDataTest;
        });
    }

    public getItemText(item: any) {
        if (typeof this.itemText === 'function') {
            return this.itemText(item);
        }

        if (_.isString(item)) {
            return item;
        }

        return item[this.itemText];
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-text-field {
    ::v-deep input {
        padding: 6px 0 6px;
    }
}
.v-text-field--outlined.v-input--dense.v-text-field--outlined {
    ::v-deep .v-input__control {
        > .v-input__slot {
            min-height: 36px;
        }
    }
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense {
    ::v-deep .v-select__selections {
        padding: 0;
    }
}
.v-input {
    ::v-deep .v-messages__message {
        line-height: 1.4;
    }
}
.v-input:not(.v-input--is-disabled) {
    ::v-deep .v-select__selection--disabled {
        color: $select-black;
    }
}

.ws-select ::v-deep div.v-select__slot {
    padding-left: 0 !important;
    font-size: $font-size-small;
}

.ws-select-wrapper > label {
    display: block;
    color: $light-solid-40;
    font-size: $font-size-xsmall;
    margin-bottom: 6px;
}

.ws-select-hr {
    width: calc(100% - 15px);
}

::v-deep {
    .v-input__append-inner {
        margin-top: 6px !important;
    }
}
</style>
