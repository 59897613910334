<template>
    <v-select
        v-model="model"
        :items="items"
        :label="label"
        :outlined="outlined"
        :dense="dense"
        :error-count="maxMessages"
        :messages="messages"
        :error-messages="errorMessages"
        :success-messages="successMessages"
        :error="error"
        :rules="rules"
        :hide-details="hideDetails"
        :required="required"
        :placeholder="placeholder"
        :clearable="clearable"
        :disabled="disabled"
        :class="{ 'empty-border': !model && showEmptyBorder }"
    >
        <template #item="{ item }">
            <WsSelectColorItem
                :rgb="rgb"
                :color="item"
                :color-text-key="itemsTextKeys && itemsEnum && itemsTextKeys[itemsEnum[item]]"
                :class="itemClasses"
                :inline="inlineItems"
            />
        </template>
        <template #selection="{ item }">
            <div
                :class="disabled ? 'item-disabled' : null"
                :style="{ background: `rgb(${item})` }"
                class="selected-color"
            />
        </template>
    </v-select>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import VuetifyElement from '@/components/common/VuetifyElement.vue';
import WsSelectColorItem from '@/components/common/WsSelectColorItem.vue';

// https://vuetifyjs.com/en/api/v-select/#props

@Component({
    components: {
        WsSelectColorItem,
    },
})
export default class WsSelectColor extends VuetifyElement {
    @Prop({ required: true }) public items!: any[];
    @Prop({ type: Object, default: undefined }) public itemsEnum!: { [key: string]: number };
    @Prop({ type: Object, default: undefined }) public itemsTextKeys!: { [key: number]: string };
    @Prop({ default: false, type: Boolean }) public rgb!: boolean;
    @Prop({ type: Array, default: () => [] }) public itemClasses!: string[];
    @Prop({ type: Boolean, default: false }) public inlineItems!: boolean;
    @Prop({ type: Boolean, default: false }) public showEmptyBorder!: boolean;
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.selected-color {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: inline-block;
    border: solid 1px $disable-gray;
}

::v-deep {
    fieldset {
        border: none !important;
    }

    .v-input__append-inner {
        display: none !important;
    }

    .v-input__slot {
        padding: 0 !important;
        width: 24px !important;
    }

    .v-select__slot {
        padding: 0 !important;
        width: 24px !important;
    }

    .v-text-field__details {
        display: none !important;
    }

    .item-disabled {
        opacity: 0.3;
    }
}

.empty-border ::v-deep .v-select__slot {
    border: solid 1px $disable-gray !important;
    border-radius: 50%;
}
</style>
