<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="handleClick"
    >
        <template v-if="priority === IssuePriorityEnum.none">
            <g clip-path="url(#clip0)">
                <path d="M6.66411e-05 3C-6.43228e-05 4 6.66411e-05 4 6.66411e-05 4H2.00007V3C2.00007 2.44772 2.44778 2 3.00007 2H4.00007V0C4.00007 0 4.00003 0.000131986 3.00007 0C1.34321 -0.000218555 0.000283629 1.34315 6.66411e-05 3Z" :fill="iconColor" />
                <path d="M9.00001 0H5.00001V2H9.00001V0Z" :fill="iconColor" />
                <path d="M14 0H10V2H14V0Z" :fill="iconColor" />
                <path d="M20 0V2H21C21.5523 2 22 2.44772 22 3V4H24V3C24 1.34315 22.6569 -0.000210144 21 0C20 0.000126872 20 0 20 0Z" :fill="iconColor" />
                <path d="M24 5H22V9H24V5Z" :fill="iconColor" />
                <path d="M24 10H22V14H24V10Z" :fill="iconColor" />
                <path d="M24 20H22V21C22 21.5523 21.5523 22 21 22H20V24H21C22.6569 24 24 22.6569 24 21V20Z" :fill="iconColor" />
                <path d="M14 24V22H10V24H14Z" :fill="iconColor" />
                <path d="M9.00001 24V22H5.00001V24H9.00001Z" :fill="iconColor" />
                <path d="M8.43527e-06 21C6.92229e-05 22.6569 1.3432 24 3.00007 24H4V22H3.00001C2.44772 22 1.99998 21.5523 2.00001 21L2.00007 20H6.66411e-05C6.66411e-05 20 -2.82578e-05 19.9999 8.43527e-06 21Z" :fill="iconColor" />
                <path d="M6.66411e-05 10L8.43527e-06 14H2.00001V10H6.66411e-05Z" :fill="iconColor" />
                <path d="M6.66411e-05 5L8.43527e-06 9H2.00001V5H6.66411e-05Z" :fill="iconColor" />
                <path d="M19 0H15V2H19V0Z" :fill="iconColor" />
                <path d="M24 15H22V19H24V15Z" :fill="iconColor" />
                <path d="M19 24V22H15V24H19Z" :fill="iconColor" />
                <path d="M6.66411e-05 15L8.43527e-06 19H2.00001V15H6.66411e-05Z" :fill="iconColor" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </template>

        <template v-else>
            <rect
                x="1"
                y="1"
                width="24"
                height="24"
                rx="2"
                fill="white"
            />
            <path
                v-if="priority === IssuePriorityEnum.critical"
                :fill="rectFill"
                d="M12 5V15M12 5L7 10M12 5L17 10M12 18C11.7239 18 11.5 18.2239 11.5 18.5C11.5 18.7761 11.7239 19 12 19M12 18C12.2761 18 12.5 18.2239 12.5 18.5C12.5 18.7761 12.2761 19 12 19M12 18V19"
                stroke="#CC2929"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />

            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                :d="pathD"
                :fill="rectFill"
            />
            <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="2"
                :stroke="rectFill"
                stroke-width="2"
            />
        </template>
    </svg>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import { IssuePriorityEnum } from '../constants/IssuePriority';

@Component
export default class IssuePriorityIcon extends Vue {
    @Prop() public priority!: IssuePriorityEnum;

    public iconColor = Color.defaultBlack;
    public IssuePriorityEnum = IssuePriorityEnum;

    @Emit()
    public click() {
        return;
    }

    get rectFill() {
        const colors: { [key in IssuePriorityEnum]: string } = {
            [IssuePriorityEnum.blocker]: '#660000',
            [IssuePriorityEnum.critical]: '#CC2929',
            [IssuePriorityEnum.major]: '#FA9600',
            [IssuePriorityEnum.minor]: '#02B609',
            [IssuePriorityEnum.trivial]: '#24B2B2',
            [IssuePriorityEnum.none]: '',
        };
        return colors[this.priority];
    }

    get pathD() {
        const d: { [key in IssuePriorityEnum]: string } = {
            [IssuePriorityEnum.blocker]: 'M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 6C8.68629 6 6 8.68629 6 12C6 13.1127 6.30289 14.1547 6.83073 15.048L15.048 6.83073C14.1547 6.30289 13.1127 6 12 6ZM18 12C18 15.3137 15.3137 18 12 18C10.8873 18 9.84532 17.6971 8.95205 17.1693L17.1693 8.95205C17.6971 9.84532 18 10.8873 18 12Z',
            [IssuePriorityEnum.critical]: 'M10.9999 3.50086C11.5857 2.91508 12.5354 2.91508 13.1212 3.50086L18.6212 9.00086C19.207 9.58665 19.207 10.5364 18.6212 11.1222C18.0354 11.708 17.0857 11.708 16.4999 11.1222L13.5605 8.18284V14.0615C13.5605 14.89 12.889 15.5615 12.0605 15.5615C11.2321 15.5615 10.5605 14.89 10.5605 14.0615V8.18284L7.62121 11.1222C7.03542 11.708 6.08567 11.708 5.49989 11.1222C4.9141 10.5364 4.9141 9.58665 5.49989 9.00086L10.9999 3.50086Z',
            [IssuePriorityEnum.major]: 'M10.9393 5.43934C11.5251 4.85355 12.4749 4.85355 13.0607 5.43934L18.5607 10.9393C19.1464 11.5251 19.1464 12.4749 18.5607 13.0607C17.9749 13.6464 17.0251 13.6464 16.4393 13.0607L13.5 10.1213V17.5C13.5 18.3284 12.8284 19 12 19C11.1716 19 10.5 18.3284 10.5 17.5V10.1213L7.56066 13.0607C6.97487 13.6464 6.02513 13.6464 5.43934 13.0607C4.85355 12.4749 4.85355 11.5251 5.43934 10.9393L10.9393 5.43934Z',
            [IssuePriorityEnum.minor]: 'M13.0607 18.5607C12.4749 19.1464 11.5251 19.1464 10.9393 18.5607L5.43934 13.0607C4.85355 12.4749 4.85355 11.5251 5.43934 10.9393C6.02513 10.3536 6.97487 10.3536 7.56066 10.9393L10.5 13.8787L10.5 6.5C10.5 5.67157 11.1716 5 12 5C12.8284 5 13.5 5.67157 13.5 6.5L13.5 13.8787L16.4393 10.9393C17.0251 10.3536 17.9749 10.3536 18.5607 10.9393C19.1464 11.5251 19.1464 12.4749 18.5607 13.0607L13.0607 18.5607Z',
            [IssuePriorityEnum.trivial]: 'M13.0607 16.5607C12.4749 17.1464 11.5251 17.1464 10.9393 16.5607L5.43934 11.0607C4.85355 10.4749 4.85355 9.52513 5.43934 8.93934C6.02513 8.35355 6.97487 8.35355 7.56066 8.93934L10.5 11.8787L10.5 8.5C10.5 7.67157 11.1716 7 12 7C12.8284 7 13.5 7.67157 13.5 8.5L13.5 11.8787L16.4393 8.93934C17.0251 8.35355 17.9749 8.35355 18.5607 8.93934C19.1464 9.52513 19.1464 10.4749 18.5607 11.0607L13.0607 16.5607Z',
            [IssuePriorityEnum.none]: '',
        };
        return d[this.priority];
    }

    public handleClick() {
        this.click();
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
</style>
