import { ITimezone } from '@/types/User';

export class User {
    public readonly id: number;
    public readonly uuid: string;
    public readonly email: string;
    public readonly role: string;
    public readonly firstname: string;
    public readonly lastname: string;
    public readonly avatar: string;
    public readonly language: string;
    public readonly frozen: boolean;
    public readonly activated: boolean;
    public readonly lastActive: string;
    public readonly twoFactorMethod: string;
    public readonly timezone: ITimezone;
    public readonly autoUpdateTimezone: boolean;
    
    private readonly fullName: string;

    constructor(user: any = {}) {
        this.id = user.id;
        this.uuid = user.uuid;
        this.email = user.email;
        this.role = user.role;
        this.firstname = user.firstname;
        this.lastname = user.lastname;
        this.fullName = user.fullname;
        this.avatar = user.avatar;
        this.language = user.language;
        this.frozen = user.frozen || false;
        this.activated = user.activated;
        this.lastActive = user.lastActive;
        this.twoFactorMethod = user.twoFactorMethod;
        this.timezone = user.timezone;
        this.autoUpdateTimezone = user.autoUpdateTimezone;
    }

    get fullname() {
        return (this.fullName || this.firstname + ' ' + this.lastname).trim();
    }

    get displayName() {
        return this.fullname || this.email;
    }

    get fullnameWithEmail() {
        return this.fullname ? `${this.fullname} (${this.email})` : this.email;
    }
}
