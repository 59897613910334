<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :collapsable="collapsable"
        :collapsing-key="collapsingKey"
        :counter="currentValue.length"
        :editable="editable"
        :label="multiselectChanges ? $t('IssueTracker.info.tags.currentTags') : label"
        :multi-text="showFieldMultipleText"
        :multi-changed="isNewMultiValue"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <template #default="{ collapsed }">
            <template v-if="!multiselectChanges">
                <span
                    v-for="(tag, index) in tagsList"
                    :key="'current ' + index"
                    class="filter-link amp-mask"
                    @click="click(tag)"
                >
                    {{ tag }}{{ getCommaForListItem(tagsList, index) }}
                </span>
            </template>
            <template v-else>
                <span
                    v-for="(tag, index) in multiselectChanges.previous"
                    :key="index"
                    class="amp-mask"
                >
                    {{ tag }}{{ getCommaForListItem(multiselectChanges.previous, index) }}
                </span>
                <div class="detail-title">{{ $t('IssueTracker.info.tags.addTags') }}</div>
                <span
                    v-for="(tag, index) in multiselectChanges.added"
                    :key="'add ' + index"
                    class="amp-mask"
                >
                    {{ tag }}{{ getCommaForListItem(multiselectChanges.added, index) }}
                </span>
                <div class="detail-title">{{ $t('IssueTracker.info.tags.removeTags') }}</div>
                <span
                    v-for="(tag, index) in multiselectChanges.removed"
                    :key="'remove ' + index"
                    class="amp-mask"
                >
                    {{ tag }}{{ getCommaForListItem(multiselectChanges.removed, index) }}
                </span>
            </template>
        </template>

        <template #action>
            <IconSvg16
                icon-name="edit"
                stateless
                @click="edit"
            />

            <DialogEditTags
                v-if="isVisibleDialogEditTags"
                v-model="localTags"
                :items="sortedItems"
                :allow-create-tag="allowCreateTag"
                :is-show-manage-tags="isShowManageTags"
                @save="save"
                @cancel="restore"
            />
            <DialogMultiEditTags
                v-if="isVisibleDialogMultiEditTags"
                :tags-entries="multiSelectTagsEntries"
                :issue-count="multiSelectIssues.length"
                :items="sortedItems"
                :allow-create-tag="allowCreateTag"
                :is-show-manage-tags="isShowManageTags"
                @save="saveMultiEdit"
                @cancel="restore"
            />
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { MultiEditIssueFieldPayload } from '@/types/MultiEditIssueFieldPayload';
import { LOCAL_STORAGE_TAG_KEY } from '@/constants';
import { MAX_NOT_COLLAPSED_LENGTH } from '@/constants/IssueTracker';
import { Project } from '@/models';
import { compareAlphanumerically } from '@/services';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import ProjectMemberName from '@/components/project/ProjectMemberName.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsCombobox from '@/components/common/WsCombobox.vue';
import WsButton from '@/components/common/WsButton.vue';
import DialogEditTags from '@/components/project/issueTracker/modals/DialogEditTags.vue';
import DialogMultiEditTags from '@/components/project/issueTracker/modals/DialogMultiEditTags.vue';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/domain/issue/components/details/IssueDetailsEditPopover.vue';
import { Issue } from '@/domain/issue/models/Issue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';

@Component({
    components: {
        DialogEditTags,
        DialogMultiEditTags,
        IssueDetailsItem,
        IssueDetailsEditPopover,
        ProjectMemberName,
        WsTruncateAuto,
        IconSvg16,
        WsCombobox,
        WsButton,
    },
})
export default class IssueDetailsTags extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];
    @Prop() public allowCreateTag!: boolean;

    public readonly collapsingKey = LOCAL_STORAGE_TAG_KEY;

    public isVisibleDialogEditTags = false;
    public isVisibleDialogMultiEditTags = false;

    public localTags = [];
    public multiselectChanges: null | {
        previous: string[];
        added: string[];
        removed: string[];
    } = null;

    public readonly getCommaForListItem = getCommaForListItem;

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get joinedList(): string {
        if (Array.isArray(this.currentValue)) {
            return this.currentValue.join(this.$t('Punctuation.comma') as string);
        }

        return '';
    }

    get multiSelectIssues(): Issue[] {
        return this.$store.getters.multiSelectedIssues;
    }

    get multiSelectTagsEntries(): Array<{ tag: string, count: number }> {
        const tagsEntries = new Map();

        this.multiSelectIssues.forEach((issue: Issue) => {
            issue.tags.forEach((tag) => {
                const currentTagCount = tagsEntries.get(tag);

                if (currentTagCount) {
                    tagsEntries.set(tag, currentTagCount + 1);
                } else {
                    tagsEntries.set(tag, 1);
                }
            });
        });

        return Array.from(tagsEntries, ([tag, count]) => ({
            tag,
            count,
        }));
    }

    get collapsable() {
        return this.stringLongerThanPossible(this.joinedList) || this.currentValue.length > 2;
    }

    get sortedItems() {
        return this.items.sort(compareAlphanumerically);
    }

    get isShowManageTags() {
        const project = this.$store.getters.projectById(Number(this.$route.params.projectId));

        return project?.isCanSeeIssueSettings;
    }

    get tagsList() {
        return this.multiEditNewValues?.value || this.currentIssue.tags;
    }

    @Watch('multiSelectIssues')
    public onMultiSelectIssuesChange() {
        this.multiselectChanges = null;
    }

    @Emit()
    public click(value: string[]) {
        return { value, type: IssuesFilterType.tags };
    }

    public mounted() {
        this.localTags = this.currentValue;
    }

    public stringLongerThanPossible(string: string) {
        return string.length > MAX_NOT_COLLAPSED_LENGTH;
    }

    public edit() {
        this.localTags = this.currentValue;

        if (this.isMultiselectEditModeActive) {
            this.isVisibleDialogMultiEditTags = true;
        } else {
            this.isVisibleDialogEditTags = true;
        }
    }

    public save() {
        const isEqual = _.isEqual(this.currentValue.sort(), this.localTags.sort());
        if (!isEqual) {
            this.change(this.localTags);

            this.localTags.forEach((tag) => {
                this.currentProject.addIssueTag(tag);
            });
        }

        this.endEdit();
    }

    public saveMultiEdit(payload: MultiEditIssueFieldPayload) {
        this.multiselectChanges = {
            previous: this.multiSelectTagsEntries.map((entry) => entry.tag),
            added: payload.add,
            removed: payload.remove,
        };

        this.change(payload);
        this.endEdit();
    }

    public restore() {
        this.localTags = this.currentValue;
        this.endEdit();
    }

    public endEdit() {
        this.isVisibleDialogEditTags = false;
        this.isVisibleDialogMultiEditTags = false;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.detail-title {
    font-size: 12px;
    font-weight: 400;
    margin-top: 6px;
    padding-bottom: 3px;
}
</style>
