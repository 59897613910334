<template>
    <div class="skeleton-issue">
        <div class="issue-header">
            <SkeletonBone avatar />
            <SkeletonBone />
            <SkeletonBone text-short />
        </div>
        <div class="paragraph">
            <SkeletonBone text-full />
            <SkeletonBone />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonIssue extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-issue {
    padding: $container-padding;
    border-bottom: $border;
}
.issue-header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: $container-padding;
    margin-bottom: 20px;
}
.paragraph {
    display: grid;
    gap: 8px;
}
</style>
