<template>
    <WsTooltip
        :tooltip="tooltip"
        :disabled="disabled || !tooltip"
    >
        <template #activator="{ on }">
            <span
                @click="handleClick"
                v-on="on"
                class="icon-button"
                :class="{ gray, disabled }"
            >
                <SvgSource16 :icon="icon" :fill="fill" v-if="size === 16" />
                <SvgSource24 :icon="icon" :fill="fill" v-if="size === 24" />
                <span><slot /></span>
            </span>
        </template>
    </WsTooltip>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import SvgSource16 from '@/components/common/SvgSource16.vue';
import SvgSource24 from '@/components/common/SvgSource24.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

// DEPRECATED => IconSvg16 / IconSvg24
@Component({
    components: {
        WsTooltip,
        SvgSource16,
        SvgSource24,
    },
})
export default class WsIconButton extends Vue {
    @Prop() public text!: VueI18n.TranslateResult;
    @Prop() public tooltip!: VueI18n.TranslateResult;
    @Prop({ default: 'edit' }) public icon!: string;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public gray!: boolean;
    @Prop({ type: Number, default: 16 }) public size!: number;
    @Prop({ type: String, default: '#616161' }) public fill!: string;

    @Emit()
    public click() {
        return;
    }

    public handleClick() {
        if (!this.disabled) {
            this.click();
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.icon-button {
    cursor: pointer;
    display: inline-flex;
    align-items: flex-end;
    font-size: 14px;
    line-height: 14px;

    &:hover:not(.disabled) {
        color: $primary-blue;

        ::v-deep path {
            fill: $primary-blue;
        }
    }

    &.gray, &.disabled {
        color: $medium-gray;

        ::v-deep path {
            fill: $medium-gray;
        }
    }

    ::v-deep input[type="file"] {
        display: none;
    }
}
</style>
