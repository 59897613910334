import _ from 'lodash';
import { compareAlphanumerically } from '@/services';
import { CustomStatusCategory } from '@/domain/customStatus/constants/CustomStatusCategory';
import {
    DEFAULT_CUSTOM_STATUS_CLOSED,
    DEFAULT_CUSTOM_STATUS_IN_PROGRESS,
    DEFAULT_CUSTOM_STATUS_OPEN,
    DEFAULT_CUSTOM_STATUS_SOLVED,
} from '@/domain/workflow/constants/Workflow';
import { CustomStatus } from '@/domain/customStatus/models/CustomStatus';
import { NormalizeStatus } from '@/domain/dashboard/services/NormalizeStatus';
import { IssueStatusEnum } from '@/domain/issue/constants/IssueStatus';

const getLegacyVariants = (status: IssueStatusEnum) => [
    NormalizeStatus.reverse(status),
    status,
];

const compareStatuses = (a: CustomStatus, b: CustomStatus) => compareAlphanumerically(a.name, b.name);

const legacyStatusesUuids = [
    DEFAULT_CUSTOM_STATUS_OPEN,
    DEFAULT_CUSTOM_STATUS_IN_PROGRESS,
    DEFAULT_CUSTOM_STATUS_SOLVED,
    DEFAULT_CUSTOM_STATUS_CLOSED,
];

const legacyStatusesUuidsSet = new Set(legacyStatusesUuids);

export const makeCustomStatusesSortKeys = (statuses: CustomStatus[]) => {
    const statusesUuidsSet = new Set(_.map(statuses, 'uuid'));
    const hasStatusUuid = (statusUuid: string) => statusesUuidsSet.has(statusUuid);

    const hasLegacyStatusObj = _
        .chain(legacyStatusesUuids)
        .keyBy()
        .mapValues(hasStatusUuid)
        .value();

    const nonLegacyStatuses = _.reject(
        statuses,
        (status) => legacyStatusesUuidsSet.has(status.uuid),
    );

    const open = hasLegacyStatusObj[DEFAULT_CUSTOM_STATUS_OPEN]
        ? getLegacyVariants(IssueStatusEnum.open)
        : [];
    const in_progress = hasLegacyStatusObj[DEFAULT_CUSTOM_STATUS_IN_PROGRESS]
        ? getLegacyVariants(IssueStatusEnum.in_progress)
        : [];
    const solved = hasLegacyStatusObj[DEFAULT_CUSTOM_STATUS_SOLVED]
        ? getLegacyVariants(IssueStatusEnum.solved)
        : [];
    const closed = hasLegacyStatusObj[DEFAULT_CUSTOM_STATUS_CLOSED]
        ? getLegacyVariants(IssueStatusEnum.closed)
        : [];

    const toDo = _
        .filter(nonLegacyStatuses, { category: CustomStatusCategory.ToDo })
        .sort(compareStatuses);
    const tracking = _
        .filter(nonLegacyStatuses, {  category: CustomStatusCategory.Tracking })
        .sort(compareStatuses);
    const completed = _
        .filter(nonLegacyStatuses, { category: CustomStatusCategory.Completed })
        .sort(compareStatuses);

    const rawStatuses = [
        ...open,
        ...toDo,
        ...in_progress,
        ...tracking,
        ...solved,
        ...completed,
        ...closed,
    ];
    const getStatusName = (status: string | CustomStatus) => _.isString(status) ? status : status.name;
    return _.compact(rawStatuses).map(getStatusName);
};
