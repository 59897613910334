import { BaseDashboard } from './BaseDashboard';
import {
    DashboardVisibility,
    DashboardVisibilityPrivateList,
    DashboardVisibilityPublicList,
} from '@/domain/dashboard/constants/DashboardVisibility';
import { DashboardPrivacy } from '@/domain/dashboard/constants/DashboardPrivacy';
import { DashboardSharing } from '@/domain/dashboard/constants/DashboardSharing';

export class ProjectDashboard extends BaseDashboard {
    public preset: any;
    public members: string[];

    constructor(dashboard: any = {}) {
        super(dashboard);
        this.visibility = dashboard.visibility ?? DashboardVisibility.publicIssues;
        this.members = dashboard.members || [];
        this.privacy = this.initPrivacy(this.visibility);
        this.sharing = this.initSharing(this.visibility, this.members);
    }

    get isProjectDashboard(): boolean {
        return true;
    }

    get apiParams() {
        const baseParams = super.apiParams;
        return {
            ...baseParams,
            visibility: this.getVisibilityAsParam(this.privacy, this.sharing),
            teamMemberUuids: this.members,
            isFavorite: this.isFavorite,
        };
    }

    get isPublicVisibility(): boolean {
        return DashboardVisibilityPublicList.includes(this.visibility);
    }

    get isPrivateVisibility(): boolean {
        return DashboardVisibilityPrivateList.includes(this.visibility);
    }

    public initPrivacy(visibility: number): number {
        // see WEB-7654 for details
        if (visibility === DashboardVisibility.allIssues) {
            return DashboardPrivacy.privateForAdmin;
        }
        if (visibility === DashboardVisibility.groupPrivateIssues) {
            return DashboardPrivacy.privateForMember;
        }
        return DashboardPrivacy.public;
    }

    public initSharing(visibility: number, members: any[]): number {
        // see WEB-7654 for details
        if (visibility === DashboardVisibility.publicIssues) {
            return DashboardSharing.allProjectMembers;
        }
        if (members.length) {
            return DashboardSharing.byInvitation;
        }
        if (visibility === DashboardVisibility.allIssues) {
            return DashboardSharing.adminsOnly;
        }
        return DashboardSharing.onlyMe;
    }

    public getVisibilityAsParam(privacy: number, sharing: number): number {
        // see WEB-7654 for details
        if (privacy === DashboardPrivacy.privateForMember) {
            return DashboardVisibility.groupPrivateIssues;
        }
        if (privacy === DashboardPrivacy.privateForAdmin) {
            return DashboardVisibility.allIssues;
        }
        if (sharing === DashboardSharing.allProjectMembers) {
            return DashboardVisibility.publicIssues;
        }
        return DashboardVisibility.groupPublicIssues;
    }
}

export const isProjectDashboard = (dashboard: any): dashboard is ProjectDashboard => {
    return dashboard?.isProjectDashboard;
};
