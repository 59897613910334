<template>
    <div class="otp-input">
        <input
            v-for="{ value, index, classObj, ref, onInput, onKeydown, onFocus } in inputs"
            :value="value"
            :key="index"
            :class="classObj"
            class="otp-input-field"
            :ref="ref"
            type="text"
            @input="onInput"
            @keydown="onKeydown"
            @focus="onFocus"
        >
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { sleep } from '@/services';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

const Empty = {
    otp(length: number): string[] {
        return new Array(length).fill('');
    },
};

const Key = {
    Backspace: 'Backspace',
    Delete: 'Delete',
};

const isOneNumber = (value: string) => /^\d$/.test(value);

@Component
export default class OTPInput extends EventListenersBase {
    @Prop({ default: 6 }) public length!: number;

    public otp = Empty.otp(this.length);
    public activeIndex = NaN;

    get inputs() {
        return _
            .range(this.length)
            .map((index) => ({
                index,
                value: this.otp[index],
                ref: `otp-input_${index}`,
                classObj: { active: index === this.activeIndex },
                onInput: (event: InputEvent) => this.onInput(index, event),
                onKeydown: (event: KeyboardEvent) => this.onKeyDown(index, event),
                onFocus: () => this.highlightElement(index),
            }));
    }

    get isAllFilled() {
        return this.otp.every(isOneNumber);
    }

    get stringOtp() {
        return this.otp.join('');
    }

    get elements() {
        return this.inputs.flatMap(({ ref }) => this.$refs[ref] as HTMLInputElement);
    }

    @Emit()
    public input() {
        return this.stringOtp;
    }

    @Emit()
    public ready() {
        return;
    }

    public mounted() {
        this.focusElement(0);

        this.eventListeners.add({
            node: document,
            event: 'visibilitychange',
            handler: () => this.focusElement(this.activeIndex),
        });
    }

    public onInput(index: number, event: InputEvent) {
        const data = event.data || (event.target as HTMLInputElement)?.value || '';
        const value = data.replace(/\D/g, '');
        this.clearOtp(index);

        const numbers = value.split('');
        numbers.forEach((number, numberIndex) => {
            const shiftedIndex = index + numberIndex;
            this.setOtp(shiftedIndex, number);
            this.focusElement(shiftedIndex + 1);
        });

        this.input();

        if (this.isAllFilled) {
            this.blurActive();
            this.ready();
        }
    }

    public onKeyDown(index: number, event: KeyboardEvent) {
        if ([Key.Backspace, Key.Delete].includes(event.key)) {
            event.preventDefault();
            this.clearOtp(index);
            this.focusElement(index - 1);
        }
    }

    public setOtp(index: number, value: string) {
        if (index >= 0 && index < this.length) {
            this.$set(this.otp, index, value);
        }
    }

    public clearOtp(index: number) {
        this.setOtp(index, '');
    }

    public highlightElement(index: number = NaN) {
        this.activeIndex = index;
    }

    public async blurActive() {
        this.blurElement();
        await sleep(10);
        this.highlightElement();
    }

    public focusElement(index: number) {
        this.elements[index]?.focus();
    }

    public blurElement() {
        this.elements[this.activeIndex]?.blur();
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.otp-input {
    display: inline-flex;
    gap: 8px;

    .otp-input-field {
        width: 40px;
        height: 53px;
        text-align: center;
        font-size: 24px;
        border: none;
        outline: none;
        background-color: $light;
        border-radius: 8px;
        caret-color: transparent;

        &.active {
            border: 3px solid $light-accent;
        }
    }
}
</style>
