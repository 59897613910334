<template>
    <div class="issue-comment-diff-type" @click="click">
        <IssueCommentDiffChanged entity="customType" />
        <CustomIssueType :issue-type="customTypeDiff.oldType" inline />
        ➝
        <CustomIssueType :issue-type="customTypeDiff.newType" inline />
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { dateFormatter } from '@/services/MomentFormats';
import CustomIssueType from '@/domain/issueType/components/CustomIssueType.vue';
import IssueCommentDiffChanged from '@/domain/comment/components/IssueCommentDiffChanged.vue';
import { IDiffItem } from '@/domain/comment/models/DiffComment';

@Component({
    components: {
        CustomIssueType,
        IssueCommentDiffChanged,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueCommentDiffType extends Vue {
    @Prop({ required: true }) public diff!: Dict<IDiffItem>;

    get projectUuid(): string {
        const projectId = this.$route.params.projectId;
        return this.$store.getters.projectById(projectId)?.uuid || '';
    }

    get customTypeDiff() {
        return {
            newType: this.$store.getters.customIssueTypeByUuid(this.projectUuid, this.diff.customType.new),
            oldType: this.$store.getters.customIssueTypeByUuid(this.projectUuid, this.diff.customType.old),
        };
    }

    @Emit()
    public click() {
        return;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.diff:not(:last-child) {
    margin-bottom: 2em;
}

.issue-comment-diff-type {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    gap: 0 4px;
    overflow: hidden;
}
</style>
