<template>
    <v-menu v-model="isOpen" offset-y>
        <template #activator="{ on }">
            <WsIcon @click="isOpen = true" />
        </template>

        <slot />
    </v-menu>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import WsIcon from '@/components/common/WsIcon.vue';

@Component({
    components: {
        WsIcon,
    },
})
export default class WsTableRowMenu extends Vue {
    public isOpen: boolean = false;
}
</script>

<style lang="scss" scoped>
::v-deep .v-list .v-list-item {
    white-space: nowrap;
}
</style>
