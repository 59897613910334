<template>
    <div
        v-if="isVisible"
        :class="{ focused, removed }"
        :id="comment.uuid"
        class="comment"
    >
        <div>
            <div class="head">
                <MemberAvatar
                    :avatar-src="avatarSrc"
                    :member-id="memberId"
                />
                <div>
                    <div class="comment-info">
                        <div class="comment-name">
                            <WsTruncateAuto :value="reporterFullname" css-class="highlighting name amp-mask" />
                            <IconSvg16
                                v-if="!isPinnedFormat && comment.pinned"
                                icon-name="pin"
                                :color="Color.primaryOrange"
                                stateless
                                passive
                                @click="unpin"
                            />
                        </div>
                        <div class="time highlighting">
                            {{ timeFormatter(comment.created, true) }}
                        </div>
                    </div>
                    <WsTruncateAuto
                        v-if="additionalParams"
                        :value="additionalParams"
                        css-class="additional-info amp-mask"
                    />
                </div>
            </div>

            <IssueCommentDiff
                v-if="isDiff"
                :comment="comment"
                @click="click"
            />

            <IssueCommentFile
                v-if="isFile"
                :comment="comment"
                :comment-index="commentIndex"
                :is-pinned-format="isPinnedFormat"
                @show-image="showImage"
                @click="click"
                @img-loaded="onAttachmentLoaded"
            />

            <div
                v-if="isText"
                class="comment-text"
                @click="click"
            >
                <WsMarkdownText :value="comment.text" class="highlighting text" />
                <div class="highlighting">
                    {{ comment.internalProperties }}
                </div>
            </div>

            <div v-if="comment.pending" class="delivering">
                {{ $t('IssueTracker.chat.loader') }}
                <WsLoaderDotsInline />
            </div>

            <div v-if="comment.rejected" class="error comment-error">
                <div class="attention-error">
                    <!-- It works with offline internet -->
                    <IconAttention />
                </div>
                <div class="error-text">
                    {{ $t('IssueTracker.chat.error') }}
                    {{ errorMessageCode }}
                </div>
                <div class="error-actions">
                    <WsButton
                        size="xsmall"
                        type="error"
                        @click="deleteComment"
                    >
                        {{ $t('IssueTracker.button.delete') }}
                    </WsButton>
                    <WsButton
                        :disabled="disableResend"
                        size="xsmall"
                        type="error"
                        @click="resendComment"
                    >
                        {{ $t('IssueTracker.button.resend') }}
                    </WsButton>
                </div>
            </div>
        </div>

        <div v-if="!isPinnedFormat" class="comment-menu" @click.stop>
            <WsTooltip :tooltip="$t('Simple_word.actions')">
                <WsMenuIcon>
                    <v-list dense>
                        <slot name="menu">
                            <v-list-item @click="copyCommentUrl">{{ $t('Button.copy_link') }}</v-list-item>
                            <v-list-item
                                v-if="!comment.pinned && !selectedIssue.isDeleted"
                                @click="pin"
                            >
                                {{ $t('IssueTracker.chat.pin') }}
                            </v-list-item>
                            <v-list-item
                                v-if="comment.pinned && !selectedIssue.isDeleted"
                                @click="unpin"
                            >
                                {{ $t('IssueTracker.chat.unpin') }}
                            </v-list-item>
                        </slot>
                    </v-list>
                </WsMenuIcon>
            </WsTooltip>
        </div>

        <div v-if="isPinnedFormat && !selectedIssue.isDeleted" class="unpin">
            <IconSvg24
                activator-class="error-icon"
                icon-name="x-big"
                stateless
                @click="unpin"
            />
        </div>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import {
    AmplitudeEvent,
    Color,
    RouterNames,
} from '@/constants';
import {
    License,
    LicenseMember,
    ProjectMember,
} from '@/models';
import { amplitudeLog, copyUrlToBuffer, timeFormatter } from '@/services';
import MemberAvatar from '@/components/common/MemberAvatar.vue';
import WsMemberName from '@/components/common/WsMemberName.vue';
import WsMarkdownText from '@/components/common/WsMarkdownText.vue';
import WsLoaderDotsInline from '@/components/common/WsLoaderDotsInline.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsMenuIcon from '@/components/common/WsMenuIcon.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import IconAttention from '@/components/common/icon/IconAttention.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import IssueCommentFile from '@/domain/comment/components/IssueCommentFile.vue';
import IssueCommentDiff from '@/domain/comment/components/IssueCommentDiff.vue';
import { DiffComment } from '@/domain/comment/models/DiffComment';
import { FileComment } from '@/domain/comment/models/FileComment';
import { TextComment } from '@/domain/comment/models/TextComment';
import {
    IssueTrackerDiffInlineProperties,
    IssueTrackerDiffMultilineProperties,
    IssueTrackerDiffSpecialProperties,
} from '@/domain/comment/models/IssueTrackerDiffProperties';
import { ErrorsForDisableChat } from '@/domain/issue/constants/ErrorsForDisableChat';

@Component({
    components: {
        WsTooltip,
        MemberAvatar,
        IssueCommentDiff,
        IssueCommentFile,
        WsMemberName,
        WsMarkdownText,
        WsLoaderDotsInline,
        IconSvg24,
        IconSvg16,
        WsButton,
        WsMenuIcon,
        WsTruncateAuto,
        IconAttention,
    },
})
export default class IssueComment extends Vue {
    @Prop({ required: true }) public comment!: DiffComment | FileComment | TextComment;
    @Prop({ required: true }) public commentIndex!: number;
    @Prop({ default: false, type: Boolean }) public isPinnedFormat!: boolean;

    public readonly Color = Color;
    public readonly timeFormatter = timeFormatter;

    public focused = false;
    public removed = false;

    public timeoutIdForMountEvent = 0;

    get isDiff() {
        return this.comment instanceof DiffComment;
    }

    get isFile() {
        return this.comment instanceof FileComment;
    }

    get isText() {
        return this.comment instanceof TextComment;
    }

    get isVisible() {
        return !this.isDiff || _.intersection(
            Object.keys((this.comment as DiffComment).diff),
            [
                ...IssueTrackerDiffInlineProperties,
                ...IssueTrackerDiffMultilineProperties,
                ...IssueTrackerDiffSpecialProperties,
            ],
        ).length > 0;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get projectId(): string {
        return this.$route.params.projectId;
    }

    get licenseId(): string {
        return this.$route.params.licenseId;
    }

    get licenseMember(): LicenseMember | undefined {
        return this.$store.getters.licenseMemberByEmail(this.comment.reporter, this.licenseId);
    }

    get projectMember(): ProjectMember | undefined {
        return this.$store.getters.projectMemberByEmail(this.comment.reporter, this.projectId);
    }

    get reporter(): LicenseMember | ProjectMember | undefined {
        return this.licenseMember ?? this.projectMember;
    }

    get reporterFullname() {
        return this.reporter?.fullname ?? this.comment.reporter;
    }

    get additionalParams() {
        const { visibleCompany, visibleDepartment, visibleLocation } = this.license;
        const company = visibleCompany ? this.reporter?.company : null;
        const department = visibleDepartment ? this.reporter?.department : null;
        const location = visibleLocation ? this.reporter?.location : null;

        return [company, department, location].filter(Boolean).join(' • ');
    }

    get avatarSrc() {
        return this.reporter?.avatar;
    }

    get memberId() {
        return this.reporter?.id;
    }

    get language(): string {
        return this.$route.params.language;
    }

    get selectedIssue() {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get disableResend() {
        return ErrorsForDisableChat.includes(this.comment.pendingError as number);
    }

    get errorMessageCode() {
        if (!this.comment.pendingError) {
            return '';
        }

        if (ErrorsForDisableChat.includes(this.comment.pendingError)) {
            return this.$t(`IssueTracker.chat.errorDetails.${this.comment.pendingError}`);
        }

        return this.$t('IssueTracker.chat.errorDetails.-1');
    }

    @Emit()
    public showImage(comment: DiffComment | FileComment | TextComment) {
        return comment;
    }

    @Emit()
    public click() {
        return;
    }

    @Emit('mounted')
    public emitMountedEvent() {
        return;
    }

    @Watch('comment.focused', { deep: true, immediate: true })
    public onFocusComment(newValue: boolean) {
        if (newValue) {
            this.focusComment();
        }
    }

    @Watch('$route', { deep: true, immediate: true })
    public onRouteChange() {
        this.tryToFocus();
    }

    public mounted() {
        this.tryToFocus();

        if (!this.isFile) {
            this.emitMountedEvent();
        } else {
            this.timeoutIdForMountEvent = setTimeout(() => {
                this.emitMountedEvent();
            }, 2000); // If the file is not loaded, the event will be emitted after 3 seconds
        }
    }

    public beforeDestroy() {
        clearTimeout(this.timeoutIdForMountEvent);
    }

    public tryToFocus() {
        if (!this.isPinnedFormat) {
            const hashUuid = this.$route.hash.slice(1); // отрезаю #
            const isCommentInHash = hashUuid === this.comment.uuid;
            if (isCommentInHash || this.comment.focused) {
                this.focusComment();
            }
        }
    }

    public focusComment() {
        const focusInitialTime = 200;
        const focusDuration = 1000;
        setTimeout(() => {
            this.focused = true;
            setTimeout(() => {
                this.focused = false;
                this.comment.focused = false;
            }, focusDuration);
        }, focusInitialTime);
    }

    public copyCommentUrl() {
        const selectedIssue = this.$store.getters.selectedIssueByProjectId(this.projectId);
        const id = selectedIssue.id;
        const uuid = this.comment.uuid;
        const itPath = this.$router.resolve({
            name: RouterNames.ProjectIssueTracker,
            params: {
                language: this.language,
                licenseId: String(this.licenseId),
                projectId: String(this.projectId),
            },
        }).href;
        const commentUrl = `${location.origin}${itPath}?id=${id}#${uuid}`;
        copyUrlToBuffer(commentUrl);

        amplitudeLog(AmplitudeEvent.itCommentCopyLink);
    }

    public deleteComment() {
        this.removed = true;
        setTimeout(() => {
            this.$store.commit('removePendingCommentsForIssue', {
                projectId: this.projectId,
                issueUuid: this.selectedIssue.uuid,
                comments: [this.comment],
            });
        }, 300);
    }

    public resendComment() {
        this.$store.dispatch('resendCommentsForIssue', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            comments: [this.comment],
        });
    }

    public async pin() {
        await this.$store.dispatch('pinIssueComment', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            commentUuid: this.comment.uuid,
        });
        await this.$store.dispatch('loadCommentsForIssue', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            isForce: true,
        });

        amplitudeLog(AmplitudeEvent.pinComment);
    }

    public async unpin() {
        await this.$store.dispatch('unpinIssueComment', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            commentUuid: this.comment.uuid,
        });
        await this.$store.dispatch('loadCommentsForIssue', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            isForce: true,
        });

        amplitudeLog(AmplitudeEvent.unpinComment);
    }

    public onAttachmentLoaded() {
        this.emitMountedEvent();
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/mixins';

.comment {
    display: grid;
    grid-template-columns: calc(100% - 30px) 30px;
    padding: $container-padding;
    overflow: hidden;
    transition: all .3s ease-out;
}

.comment-menu {
    padding: 5px 0 0 0;
    transform: translateX(15px);
}

.head {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 15px;
    width: 100%;
    margin-bottom: 9px;
}

::v-deep .name {
    font-weight: 500;
    font-size: $font-size;
    line-height: 18px;
}

.time {
    text-align: right;
    font-size: 12px;
    color: $select-black;
}

.comment-info {
    display: grid;
    grid-template-columns: 1fr 24px auto;
    grid-gap: 15px;
}

::v-deep .comment-name {
    display: flex;

    .name {
        margin-right: 6px;
    }
}

::v-deep .additional-info {
    font-size: 13px;
    line-height: $line-height-small;
    color: $default-black;
}

.text {
    overflow-wrap: break-word;
}

.comment-text {
    ::v-deep a {
        color: $link;
        transition: all 0.2s;

        &:hover {
            text-decoration: underline;
        }
    }
}

.delivering {
    color: $primary-green;
}

.comment-error {
    display: grid;
    grid-gap: 4px;
    grid-template-areas:
        "icon   text"
        "icon   actions";
    grid-template-columns: 24px 1fr;

    ::v-deep .error-icon {
        grid-area: icon;
        align-self: flex-start;
    }
    .error-text {
        grid-area: text;
        font-size: 12px;
        line-height: $line-height-small;
    }
    .error-actions {
        grid-area: actions;

        > .ws-button.v-btn {
            margin-right: 8px;
            margin-left: 0;
        }
    }
}
.focused {
    background: $primary-light-blue;
}
.removed {
    opacity: 0;
}

.unpin {
    margin: 6px 0 0 10px;
}

::v-deep {
    .attention-error {
        position: relative;

        svg {
            position: absolute;
            top: 50%;
            margin-top: -50%;
        }
    }
}
</style>
