<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <template v-if="icon === 'approval'">
            <path
                :fill="fill"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12C2 6.47715 6.47715 2.00001 12 2C17.5292 1.99999 22.0122 6.49293 22 12.0221C21.9878 17.5386 17.5165 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4.00001 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4136 20 19.9902 16.4313 20 12.0177C20.0098 7.5948 16.4229 3.99999 12 4ZM16.7071 9.70711L10.5028 15.9114L7.29573 12.7298L8.70427 11.31L10.4972 13.0886L15.2929 8.29289L16.7071 9.70711Z"
            />
        </template>

        <template v-else-if="icon === 'cancel'">
            <path
                :fill="fill"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
            />
            <path
                :fill="fill"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5857 12.0001L7.79285 9.20718L9.20706 7.79297L12 10.5859L14.7928 7.79297L16.2071 9.20718L13.4142 12.0001L16.2071 14.793L14.7928 16.2072L12 13.4143L9.20706 16.2072L7.79285 14.793L10.5857 12.0001Z"
            />
        </template>
    </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgSource24 extends Vue {
    @Prop({ default: 'approval' }) public icon!: string;
    @Prop({ default: '#616161' }) public fill!: string;
}
</script>
