<template>
    <v-chip
        :class="{ 'tag-like': tagLike }"
        :color="color"
        small
        :text-color="Color.white"
    >
        <span class="highlighting">{{ $t(`IssueTracker.diffComments.procore.status.${status}`, { rfiNumber }) }}</span>
    </v-chip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import { ColorService } from '@/services';
import { IssueStatusEnum } from '@/domain/issue/constants/IssueStatus';

@Component
export default class IssueStatusProcoreText extends Vue {
    @Prop({ required: true }) public status!: IssueStatusEnum;
    @Prop({ type: Boolean, default: false }) public tagLike!: boolean;
    @Prop() public rfiNumber!: string;

    public readonly Color = Color;

    get color() {
        return ColorService.getColorForField(this.status);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.autostatus {
    width: 14px;
    height: 14px;
}

.v-chip.tag-like {
    margin-left: 5px;
    border-radius: 4px;
    padding: 0 8px;
    height: 18px;
}
</style>
