<template>
    <div class="icon-popover">
        <WsTooltip :max-width="300">
            <span class="circle-icon">{{ iconType }}</span>
            <template #tooltip>
                <div class="popover-body">
                    <div v-html="sanitizeHtml(texts)" />
                </div>
            </template>
        </WsTooltip>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { sanitizeHtml } from '@/services';

@Component({
    methods: {
        sanitizeHtml,
    },
})
export default class WsIconPopover extends Vue {
    @Prop({ default: '!' }) public iconType!: string;
    @Prop() public title!: TranslateResult;
    @Prop({ default: () => [] }) public texts!: TranslateResult[];
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.icon-popover {
    display: inline-block;
}

.popover-body {
    max-width: 300px;
    font-size: 12px;
    text-align: left;
    & > div:not(:last-of-type) {
        margin-bottom: 10px;
    }
}
.popover-title {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.1;
}
.circle-icon {
    display: inline-block;
    width: 13px;
    height: 13px;
    line-height: 12px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid $default-black;
    font-size: 10px;
    cursor: pointer;
    color: $default-black;
}
</style>
