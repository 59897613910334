<template>
    <v-input
        :class="{ outlined, wide }"
        :hide-details="hideDetails"
        :disabled="disabled"
        @click="click"
    >
        <IconSvg16
            :icon-name="icon"
            parent-hover
            stateless
            :disabled="disabled"
        />
        <span>
            <slot />
        </span>
    </v-input>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class ChartSettingsInlineButton extends Vue {
    @Prop({ required: true }) public icon!: string; // IconSvg16
    @Prop({ type: Boolean, default: true }) public hideDetails!: boolean;
    @Prop({ type: Boolean }) public outlined!: boolean;
    @Prop({ type: Boolean }) public wide!: boolean;
    @Prop({ type: Boolean }) public disabled!: boolean;

    @Emit()
    public click() {
        return;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.v-input {
    display: inline-block;
    width: auto;
    cursor: pointer;

    &:hover {
        color: $primary-blue;
    }

    &.outlined {
        border: 1px solid $default-black;
        border-radius: $border-radius-default;
        line-height: 34px;
        padding: 0 $container-padding;

        &.v-input--is-disabled {
            border-color: $disable-gray;
        }
    }
    &.wide {
        width: 100%;
    }
    ::v-deep .v-input__slot {
        justify-content: center;
    }
}
</style>
