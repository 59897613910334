import http from '@/api/http';
import { IProjectStamp } from '@/domain/stamp/types/ProjectStamp';

export default class StampApi {
    /**
     * add stamp
     */
    public static addStamp({ projectId, entities }: { projectId: number, entities: IProjectStamp[] }) {
        return http.post(`project/${projectId}/issue-preset/add`, { project_id: projectId, entities: JSON.stringify(entities) }).then((response) => response.data);
    }

    /**
     * edit stamp
     */
    public static editStamp({ projectId, entities }: { projectId: number, entities: IProjectStamp[] }) {
        return http.post(`project/${projectId}/issue-preset/edit`, { project_id: projectId, entities: JSON.stringify(entities) }).then((response) => response.data);
    }

    /**
     * Stamp list
     */
    public static getStampList({ projectId, page }: { projectId: number, page: number }) {
        return http.get(`project/${projectId}/issue-preset/list`, { project_id: projectId, page }).then((response) => response.data);
    }

    /**
     * remove stamp
     */
    public static removeStamp({ projectId, uuids }: { projectId: number, uuids: string[] }) {
        return http.post(`project/${projectId}/issue-preset/remove`, {
            project_id: projectId,
            entities: JSON.stringify(uuids.map((uuid: string) => {
                return {
                    uuid,
                };
            })),
        }).then((response) => response.data);
    }
}