<template>
    <span class="priority">
        <IssuePriorityIcon :priority="value" />
        {{ $t(`Dashboard.chart.settings.filter.priority.${value}`) }}
    </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import IssuePriorityIcon from '@/domain/issue/components/IssuePriorityIcon.vue';
import { IssuePriorityEnum } from '@/domain/issue/constants/IssuePriority';

@Component({
    components: {
        IssuePriorityIcon,
    },
})
export default class IssuePriorityItem extends Vue {
    @Prop({ required: true }) public value!: IssuePriorityEnum;
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.priority {
    display: flex;
    text-transform: capitalize;
    align-items: center;
    font-weight: 700;
    margin-top: 4px;

    svg {
        margin-right: 8px;
    }
}
</style>
