<template>
    <v-menu
        v-model="isOpen"
        :close-on-content-click="false"
        :max-width="width"
        :min-width="width"
        :disabled="disabled"
        content-class="menu"
        offset-y
        offset-overflow
        @input="change"
    >
        <template #activator="{ on, attrs }">
            <button
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
                type="button"
            >
                <span
                    :class="{ 'is-open': isOpen }"
                    class="activator"
                >
                    <WsTooltip
                        :disabled="!activatorTooltip.length"
                        :tooltip="activatorTooltip"
                        tooltip-class="tooltip"
                    >
                        <span :class="{ 'amp-mask': ampMask && !activatorTooltip }">{{ activatorText }}</span>
                    </WsTooltip>
                    <span
                        v-if="!disabled"
                        class="dropdown-arrow"
                        :class="isOpen ? 'up' : 'down'"
                    >
                        <IconSvg16
                            v-if="!disabled"
                            icon-name="arrow-down"
                            stateless
                        />
                    </span>
                </span>
            </button>
        </template>
        <v-list>
            <div>
                <slot name="header" />
                <div class="menu-list" :style="styles">
                    <div @click="close">
                        <slot name="list" />
                        <v-list-item v-if="isEmptySearch" disabled>
                            <div class="empty-warning">
                                {{ $t('errors.search_no_matches') }}
                            </div>
                        </v-list-item>
                        <v-list-item v-if="isNoItems" disabled>
                            <div class="empty-warning">
                                {{ $t('errors.noItemSelection') }}
                            </div>
                        </v-list-item>
                    </div>
                </div>
            </div>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
        IconSvg16,
    },
})
export default class WsDropdown extends Vue {
    @Prop({ default: true }) public arrow!: boolean;
    @Prop({ default: '' }) public activatorText!: TranslateResult;
    @Prop({ default: '' }) public activatorTooltip!: TranslateResult;
    @Prop({ default: 336 }) public maxHeight!: number;
    @Prop({ default: 351 }) public width!: number;
    @Prop({ default: [] }) public itemsToShow!: any[];
    @Prop({ default: '' }) public searchQuery!: string;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;

    public isOpen = false;

    get styles() {
        return `max-height: ${this.maxHeight}px;`;
    }

    get isEmptySearch() {
        return !this.itemsToShow?.length && this.searchQuery.length && !this.isNoItems;
    }

    get isNoItems() {
        return !this.itemsToShow?.length;
    }

    @Emit()
    public change(value: boolean) {
        this.isOpen = value;
        return value;
    }

    public close() {
        this.isOpen = false;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';
@import '@/styles/wsui/index.scss';

.activator {
    display: flex;
    align-items: center;
    color: $light-solid-60;
    white-space: nowrap;
    font-size: 14px;
    gap: 5px;

    &:hover {
        color: $color-accent-100;
    }

    &.is-open {
        color: $light-accent;
    }
}
.menu {
    background-color: $white;

    ::v-deep .v-list-item {
        padding: 0;
    }
}

.menu-list {
    @include scrollbar;
}

button:disabled {
    cursor: auto;
}

.empty-warning {
    padding: 0 16px;
    width: 100%;
    text-align: center;
}

.dropdown-arrow {
    display: flex;
    align-items: center;
    transition: transform 0.2s ease-in-out;

    ::v-deep .svg {
        color: currentColor;
    }

    &.up {
        transform: rotate(180deg);
    }
}

</style>
