<template>
    <div
        :class="{
            'issue-item__selected': selected || isIssueInMultiSelect,
            'issue-item__unread': isNew,
            'issue-item__deadlined': issue.isDeadlineExpired,
        }"
        class="component-issue-item issue-item"
        @click="onClickComponent({ clickOnCheckbox: false })"
    >
        <div
            class="issue-item-column-check"
            :class="{ 'issue-item-column-check-active': isIssueInMultiSelect }"
            @click.stop.prevent
        >
            <WsCheckbox
                :value="isIssueInMultiSelect"
                @change="onClickComponent({ clickOnCheckbox: true })"
            />
        </div>

        <div class="issue-item-column-content">
            <div class="content-area__id">
                <CustomIssueTypeIcon
                    v-if="customTypeIcon"
                    :icon="customTypeIcon.icon"
                    :icon-color="customTypeIcon.iconColor"
                />
                {{ issuePrefix }} <span class="id ml-1">{{ issue.id }}</span>
            </div>

            <div class="content-area__assignee">
                <ProjectMemberName :email="issue.assignee" />

                <div class="issue-menu" @click.stop>
                    <WsTooltip
                        :tooltip="$t('Simple_word.actions')"
                        placement="top"
                    >
                        <span class="d-flex align-center ml-1">
                            <WsMenuIcon size="16">
                                <v-list dense>
                                    <slot name="menu">
                                        <v-list-item @click="copyIssueUrl">{{ $t('IssueTracker.copy_link_web') }}</v-list-item>
                                        <v-list-item @click="copyAppIssueUrl">{{ $t('IssueTracker.copy_link_app') }}</v-list-item>
                                        <v-list-item @click="printIssue">{{ $t('Collocation.printToPdf') }}</v-list-item>
                                        <v-list-item v-if="canDelete" class="delete" @click="isVisibleDialogDeleteIssues = true">{{ $t('Button.delete') }}</v-list-item>
                                        <v-list-item v-if="issue.status === IssueStatusEnum.deleted" @click="undelete">{{ $t('IssueTracker.button.restore') }}</v-list-item>
                                    </slot>
                                </v-list>
                            </WsMenuIcon>
                        </span>
                    </WsTooltip>
                </div>
            </div>

            <div class="content-area__marks">
                <StampAbbr
                    v-if="issue.stampAbbr"
                    :abbr="issue.stampAbbr"
                    :color="issue.stampColor"
                />
                <IconSvg24
                    v-else
                    icon-name="issue"
                    passive
                />
            </div>

            <div class="content-area__statuses">
                <CustomStatusItem v-if="customStatus && !issue.isDeleted" :status="customStatus" />
                <IssueStatus
                    v-else-if="!issue.procore"
                    :status="issue.status"
                    :is-auto="issue.statusAuto"
                />
                <IssuePriorityIcon :priority="issue.priority" />
            </div>

            <div
                :class="{ 'content-area__title--deadlined': issue.isDeadLineToday || issue.isDeadlineExpired }"
                class="content-area__title"
            >
                <WsTruncate
                    :value="issue.title"
                    :size="titleMaxSize"
                    css-class="content-area__title-text amp-mask"
                />
            </div>

            <div class="content-area__notifications">
                <span v-if="issue.isUnread && !issue.isDeleted" class="unread" />
                <div v-if="issue.countUnreadComments > 0" class="unread-comments">
                    <IconSvg16
                        :color="Color.primaryGreen"
                        icon-name="comment"
                        stateless
                    />
                    <span>{{ issue.countUnreadComments }}</span>
                </div>
                <div
                    v-if="issue.deadline !== DEADLINE_NOT_SET && !issue.isDeadlineExpired"
                    :class="{ 'deadline-danger': issue.isDeadlineExpired }"
                    class="deadline deadline-warning"
                >
                    <IconSvg16
                        :color="issue.isDeadlineExpired ? Color.primaryRed : Color.primaryOrange"
                        icon-name="clock-up"
                        stateless
                        inline
                        passive
                    />
                    {{ deadlineExpiredText }}
                </div>
                <div
                    v-if="issue.isDeadlineExpired"
                    class="deadline deadline-warning"
                >
                    <IconSvg16
                        :color="Color.primaryRed"
                        icon-name="clock-down"
                        stateless
                        inline
                        passive
                    />
                    <span class="deadline-text">{{ deadlineExpiredText }}</span>
                </div>
                <div v-if="issue.procore" class="rfi">{{ $t('IssueTracker.rfi') }}</div>
                <div v-if="issue.isBCF" class="bcf">BCF</div>
            </div>

            <div class="content-area__binds">
                <template v-if="!issue.isClash && !issue.isRClash">
                    <span v-if="issue.is3d" class="custom-icon-issue-3d" />
                    <span v-if="issue.is2d" class="custom-icon-issue-2d" />
                </template>
                <template v-if="issue.isClash">
                    <span class="clash-count">{{ issue.countClashes }}</span>
                    <span class="custom-icon-issue-clash" />
                </template>
                <template v-if="issue.isRClash">
                    <span class="clash-count">{{ issue.countrClashes }}</span>
                    <span class="custom-icon-issue-r-clash" />
                </template>
            </div>
        </div>

        <DialogDeleteIssues
            v-if="isVisibleDialogDeleteIssues"
            :count="1"
            @remove="deleteIssue"
            @close="isVisibleDialogDeleteIssues = false"
        />
    </div>
</template>

<script lang="ts">
import moment from 'moment';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import {
    AmplitudeEvent,
    BusEvent,
    Color,
    DEADLINE_NOT_SET,
    MomentFormats,
    RouterNames,
} from '@/constants';
import { Project } from '@/models';
import { eventBus } from '@/services/eventBus';
import {
    amplitudeLog,
    copyUrlToBuffer,
    openInAppLink,
} from '@/services';
import ProjectMemberName from '@/components/project/ProjectMemberName.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import WsMenuIcon from '@/components/common/WsMenuIcon.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import DialogDeleteIssues from '@/components/project/issueTracker/modals/DialogDeleteIssues.vue';
import { IssueStatusEnum } from '../constants/IssueStatus';
import StampAbbr from '@/domain/stamp/components/StampAbbr.vue';
import CustomIssueTypeIcon from '@/domain/issueType/components/CustomIssueTypeIcon.vue';
import IssueStatus from '@/domain/issue/components/IssueStatus.vue';
import IssuePriorityIcon from '@/domain/issue/components/IssuePriorityIcon.vue';
import CustomStatusItem from '@/domain/customStatus/components/CustomStatusItem.vue';
import { CustomStatus } from '@/domain/customStatus/models/CustomStatus';
import { Issue } from '@/domain/issue/models/Issue';

const DiffOfComponentAndTitle = 80; // 80px of paddings and margins;

@Component({
    components: {
        CustomStatusItem,
        WsTruncateAuto,
        WsTooltip,
        WsCheckbox,
        WsMenuIcon,
        WsTruncate,
        IconSvg16,
        IconSvg24,
        IssuePriorityIcon,
        IssueStatus,
        CustomIssueTypeIcon,
        ProjectMemberName,
        StampAbbr,
        DialogDeleteIssues,
    },
})
export default class IssueItem extends Vue {
    @Prop({ required: true }) public issue!: Issue;
    @Prop({ default: false }) public selected!: boolean;

    @Ref() public readonly issueTitle!: HTMLElement;

    public readonly Color = Color;
    public titleMaxSize = 0;
    public isVisibleDialogDeleteIssues = false;
    public IssueStatusEnum = IssueStatusEnum;
    public DEADLINE_NOT_SET = DEADLINE_NOT_SET;

    get issuePrefix() {
        return this.currentProject.abbreviate || this.$t('Simple_word.id');
    }

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get nowDateObj() {
        return moment();
    }

    get deadlineDateObj() {
        return moment(this.issue.deadline, MomentFormats.serverSide).startOf('day');
    }

    get deadlineExpiredDays() {
        return this.deadlineDateObj.diff(this.nowDateObj, 'days', true);
    }

    get deadlineExpiredText() {
        if (this.deadlineExpiredDays > -1 && this.deadlineExpiredDays < 0) {
            return this.$t('DateDifferenceMessages.today');
        }

        if (this.deadlineExpiredDays >= -2 && this.deadlineExpiredDays <= -1) {
            return this.$t('DateDifferenceMessages.yesterday');
        }

        if (this.deadlineExpiredDays >= 0 && this.deadlineExpiredDays < 1) {
            return this.$t('DateDifferenceMessages.tomorrow');
        }

        const deadlineExpiredDaysAbs = Math.abs(Math.ceil(this.deadlineExpiredDays));

        if (deadlineExpiredDaysAbs < 30) {
            return this.$t('DateDifferenceMessages.days', { count: deadlineExpiredDaysAbs });
        }

        if (deadlineExpiredDaysAbs >= 30 && deadlineExpiredDaysAbs < 365) {
            return this.$t('DateDifferenceMessages.moreMonth', { count: Math.round(deadlineExpiredDaysAbs / 30) });
        }

        return this.$t('DateDifferenceMessages.moreYear', { count: Math.round(deadlineExpiredDaysAbs / 365) });
    }

    get deadline() {
        return this.issue.deadline;
    }

    get currentUserEmail() {
        return this.$store.getters.userData.email;
    }

    get iamWatcher() {
        return Boolean(this.issue.watchers.find((email) => email === this.currentUserEmail));
    }

    get isNew(): boolean {
        return this.issue.isUnread && (this.issue.status !== IssueStatusEnum.closed && (this.iamWatcher || this.issue.assignee === this.currentUserEmail));
    }

    get isIssueInMultiSelect() {
        return Boolean(this.$store.getters.multiSelectedIssues.filter((issue: Issue) => issue.id === this.issue.id).length);
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get permissions() {
        return this.currentProject.permissions;
    }

    get canDelete(): boolean {
        const deleteMarker = 'deleteMarker';
        return this.issue.hasPermissions(this.currentUserEmail, this.permissions[deleteMarker])
            && this.issue.status !== IssueStatusEnum.deleted;
    }

    get customTypeIcon() {
        if (!this.issue.customType) {
            return undefined;
        }

        return this.$store.getters.customIssueTypeByUuid(this.currentProject.uuid, this.issue.customType);
    }

    get customStatus(): CustomStatus | undefined {
        return this.$store.getters.customIssueStatusByUuid(this.currentProject.uuid, this.issue.customStatus);
    }

    @Watch('issue.title')
    onChangeIssueTitle() {
        this.titleMaxSize = this.calcMaxTitleSize(this.$el.clientWidth);
    }

    @Emit('click')
    public onClickComponent({ clickOnCheckbox }: { clickOnCheckbox: boolean; }) {
        return {
            issue: this.issue,
            clickOnCheckbox,
            value: !this.isIssueInMultiSelect,
        };
    }

    @Emit('mounted')
    public emitMountedEvent() {
        return;
    }

    public mounted() {
        eventBus.$on(BusEvent.issuesColumnWidth, this.onChangeComponentWidth);

        this.titleMaxSize = this.calcMaxTitleSize(this.$el.clientWidth);

        this.emitMountedEvent();
    }

    public beforeDestroy() {
        eventBus.$off(BusEvent.issuesColumnWidth, this.onChangeComponentWidth);
    }

    private onChangeComponentWidth(width: number) {
        setTimeout(() => { // Pass the event to the next event loop
            this.titleMaxSize = this.calcMaxTitleSize(width);
        });
    }

    private getTextWidthInPx(text: string) {
        const fakeSpan = document.createElement('span');
        fakeSpan.style.position = 'fixed';
        fakeSpan.style.top = '-1000px';
        fakeSpan.style.fontSize = '14px'; // like in css
        fakeSpan.style.fontWeight = '500';
        fakeSpan.innerText = text;
        document.body.appendChild(fakeSpan);
        const width = fakeSpan.clientWidth;
        fakeSpan.remove();

        return width;
    }

    public calcMaxTitleSize(elementWidth: number) {
        let truncatedTitle = this.issue.title;
        const doubleElementWidth = elementWidth * 2; // Because of the title have two lines
        const doublePadding = DiffOfComponentAndTitle * 2;
        const maxTitleWidth = doubleElementWidth - doublePadding;

        for (let i = 0; i < 10; i++) {
            const titleWidth = this.getTextWidthInPx(truncatedTitle);
            const diff = maxTitleWidth - titleWidth;

            if (diff > 0) {
                return truncatedTitle.length;
            }

            const aproximateLetterWidth = Math.round(titleWidth / truncatedTitle.length);
            const hiddenLettersCount = Math.round(Math.abs(diff) / aproximateLetterWidth);
            const smoothHiddenLettersCount = Math.round(hiddenLettersCount / 2); // All chars have different width, and we should do several's attempts to find best length

            truncatedTitle = truncatedTitle.slice(0, truncatedTitle.length - smoothHiddenLettersCount);
        }

        return truncatedTitle.length;
    }

    public onIssueSelectedChange() {
        this.$store.dispatch('handleIssueMultiSelect', this.issue);
    }

    public copyIssueUrl() {
        const itPath = this.$router.resolve({
            name: RouterNames.ProjectIssueTracker,
            params: {
                language: this.language,
                licenseId: String(this.licenseId),
                projectId: String(this.projectId),
            },
        }).href;
        const issueUrl = `${location.origin}${itPath}?id=${this.issue.id}`;
        copyUrlToBuffer(issueUrl);

        amplitudeLog(AmplitudeEvent.itIssueCopyLink, { type: 'web' });
    }

    public copyAppIssueUrl() {
        copyUrlToBuffer(openInAppLink(this.currentProject, this.projectId, this.issue.id));
        amplitudeLog(AmplitudeEvent.itIssueCopyLink, { type: 'app' });
    }

    public printIssue() {
        const uuid = this.issue.uuid;
        eventBus.$emit(BusEvent.printIssue, { uuid });
    }

    public async deleteIssue() {
        this.onIssueSelectedChange();

        await this.$store.dispatch('deleteIssue', { projectId: this.projectId, issueUuid: this.issue.uuid });
        await this.$store.dispatch('disableIssueFromOrder', { projectId: this.projectId, issueUuid: this.issue.uuid });
        await this.$store.dispatch('loadIssuesByProjectId', { projectId: this.projectId });
    }

    public async undelete() {
        this.onIssueSelectedChange();

        await this.$store.dispatch('undeleteIssue', {
            projectId: this.projectId,
            issueUuid: this.issue.uuid,
        });

        await this.$store.dispatch('loadDeletedIssues', {
            projectId: this.projectId,
            params: { reportSort: [{ field: 'priority', direction: 'asc' }] },
        }); // @todo issue sort base
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables';
@import '@/styles/mixins';
@import '@/styles/issue';

.issue-item {
    display: grid;
    grid-template-columns: 42px auto;
    border-bottom: 1px solid $divider-gray;
    overflow-y: auto;
    overflow-x: hidden;
}
.issue-item__selected {
    background: $primary-light-blue;
}
.issue-item__deadlined {
    background: $palette-red-light;
}

.issue-item:hover {
    background: $extra-light;
}
.issue-item__selected:hover {
    background: $primary-light-blue-hover;
}
.issue-item__deadlined:hover {
    background: $palette-danger-hover;
}
.issue-item__selected.issue-item__deadlined {
    background: $primary-light-blue;
}
.issue-item__selected.issue-item__deadlined:hover {
    background: $primary-light-blue-hover;
}

.issue-item__next_issue_same {
    border-bottom: 1px solid $white;
}

.issue-item-column-check {
    text-align: center;
    position: relative;
    z-index: 198;

    > .v-input--checkbox {
        position: absolute;
        right: -5px;
        top: 14px;
        margin: 0;
        padding: 0;

        .v-input--selection-controls__input {
            margin: 0;
            padding: 0;
        }
    }
}

.issue-item-column-content {
    padding: 14px 16px 10px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 24px 24px auto 24px;
    grid-gap: 10px 4px;
    grid-template-areas:
        'id id assignee assignee'
        'marks marks statuses statuses'
        'title title title title'
        'notifications notifications binds binds';
    width: 100%;
    overflow: auto;
}

.content-area__id {
    grid-area: id;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.content-area__assignee {
    grid-area: assignee;
    display: flex;
    justify-content: right;
    font-weight: $h6-font-weight;
    color: $select-black;
    overflow: hidden;
}

.content-area__marks {
    grid-area: marks;
    display: flex;
    align-items: center;
}

.content-area__statuses {
    grid-area: statuses;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 8px;
}

.content-area__title {
    grid-area: title;
    max-height: 42px;
    overflow: hidden;
    font-size: $font-size-small;
    color: $select-black;
}
.content-area__title--deadlined {
    font-weight: $h6-font-weight;
}
.content-area__title ::v-deep .content-area__title-text {
    display: inline-block;
    word-break: break-word;
}

.content-area__notifications {
    grid-area: notifications;
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
}

.content-area__binds {
    grid-area: binds;
    display: flex;
    justify-content: right;
    align-items: center;

    [class*='custom-icon-'] {
        margin: 0;
    }
}

.deadline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 12px;

    .svg {
        margin: 0 -1px 0 0;
    }
}

.deadline-text {
    color: $primary-red;
}

.deadline-warning {
    color: $primary-orange;
}

.deadline-danger {
    color: $primary-red;
}

.unread-comments {
    color: $primary-green;
    font-size: 15px;
    height: 13px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .svg {
        margin: 0 0 -4px 0 !important;
        display: inline-block;
    }
}

.delete {
    border-top: $border;
}

.rfi {
    color: $primary-orange;
}

.bcf {
    color: #AC1B8F;
}

.unread {
    display: inline-block;
    border-radius: $border-radius-circle;
    background-color: $light-accent;
    width: 8px;
    height: 8px;
}

.issue-menu {
    z-index: 199;
    display: inline-flex;
    align-items: center;
}

.assignee {
    font-weight: 500;
    color: $select-black;
    font-size: $font-size;
}

.id {
    color: $select-black;
    font-weight: $h6-font-weight;
    font-size: $font-size;
}

.clash-count {
    font-size: 12px;
    line-height: 22px;
}
</style>
