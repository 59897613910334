import moment from 'moment/moment';
import { getUtcMidday, makeShiftForAbsoluteDates } from '@/services';
import { DateFilterRuleType, IssueTrackerFilterByDateBase } from './IssueTrackerFilterByDateBase';
import { IssueFilterExpr } from '@/domain/issueFilter/constants/IssueFilterExpr';
import { TimeUnit } from '@/domain/chart/constants/TimeUnit';
import { Direction } from '@/domain/chart/constants/Direction';

// Don't export by index.ts in services dir for avoid dependency of Store in all services imports
export class IssueTrackerFilterByDeadlineValue extends IssueTrackerFilterByDateBase {
    constructor(type: string, dateFilterType: number, date?: any, date2?: any, days?: number, includeNow?: boolean) {
        super(type, dateFilterType, date, date2, days, includeNow);
    }
    public getApiParams() {
        return { type: this.type, ...this.dateFilter };
    }
    get dateFilter() {
        switch (this.dateFilterType) {
            case DateFilterRuleType.NotSet:
                return { expr: IssueFilterExpr.NOT_SET };
            case DateFilterRuleType.BeforeNow: {
                const expr = IssueFilterExpr.LESS_THAN;
                const value = getUtcMidday().unix();
                const timeUnit = TimeUnit.TODAY;
                return { expr, value, timeUnit, direction: Direction.AHEAD };
            }
            case DateFilterRuleType.MoreThanNDaysAgo: {
                const expr = IssueFilterExpr.LESS_THAN;
                const value = getUtcMidday().subtract(this.days, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit, direction: Direction.AGO };
            }
            case DateFilterRuleType.InNDaysFromNow: {
                if (this.includeNow) {
                    const expr = IssueFilterExpr.LESS_THAN;
                    const value = this.days;
                    const timeUnit = TimeUnit.DAYS;
                    const pairTimeUnit = TimeUnit.DAYS;
                    return { expr, value, timeUnit, pairValue: this.days, pairTimeUnit, direction: Direction.AHEAD, pairDirection: Direction.AHEAD };
                } else {
                    const expr = IssueFilterExpr.INTERVAL;
                    const value = 0;
                    const timeUnit = TimeUnit.DAYS;
                    const pairValue = this.days;
                    const pairTimeUnit = TimeUnit.DAYS;
                    return { expr, value, timeUnit, pairValue, pairTimeUnit, direction: Direction.AGO, pairDirection: Direction.AHEAD };
                }
            }
            case DateFilterRuleType.CustomEqual:
                return { expr: IssueFilterExpr.EQUALS, ...convertDate(this.date) };
            case DateFilterRuleType.CustomLess:
                return { expr: IssueFilterExpr.LESS_THAN, ...convertDate(this.date) };
            case DateFilterRuleType.CustomLessOrEqual:
                return { expr: IssueFilterExpr.LESS_OR_EQUAL_THAN, ...convertDate(this.date) };
            case DateFilterRuleType.CustomMore:
                return { expr: IssueFilterExpr.MORE_THAN, ...convertDate(this.date) };
            case DateFilterRuleType.CustomMoreOrEqual:
                return { expr: IssueFilterExpr.MORE_OR_EQUAL_THAN, ...convertDate(this.date) };
            case DateFilterRuleType.CustomBetween: {
                const { value: pairValue, direction: pairDirection, timeUnit: pairTimeUnit } = convertDate(this.date2);
                return { expr: IssueFilterExpr.INTERVAL, ...convertDate(this.date), pairValue, pairDirection, pairTimeUnit };
            }
        }

        function convertDate(dateObj: any) {
            const { isRelative, value, units } = dateObj;
            if (!isRelative) {
                return {
                    timeUnit: TimeUnit.EXACT_DATE,
                    value: moment(value, 'DD-MM-YYYY').add(makeShiftForAbsoluteDates(), 'second').unix(),
                    direction: Direction.AHEAD,
                };
            }
            const timeUnit = [
                TimeUnit.DAYS,
                TimeUnit.WEEKS,
                TimeUnit.MONTHS,
                TimeUnit.YEARS,
            ][units];
            const direction = Math.sign(dateObj.value) === 1 ? Direction.AHEAD : Direction.AGO;
            const valueAbs = Math.abs(dateObj.value);
            return { timeUnit, direction, value: valueAbs };
        }
        return {};
    }
}
