<template>
    <div class="skeleton-projects-table">
        <div
            v-for="index in getRows()"
            :key="index"
            class="skeleton-projects-row"
        >
            <SkeletonBone v-if="options.hasSelect" class="skeleton-projects-td-select" />
            <div class="skeleton-projects-title-td">
                <div class="skeleton-projects-title-td--content">
                    <SkeletonBone class="skeleton-projects-title-td--image" />
                    <SkeletonBone class="skeleton-projects-title-td--first-stroke" />
                    <SkeletonBone class="skeleton-projects-title-td--second-stroke" />
                </div>
            </div>

            <div class="skeleton-projects-td"><SkeletonBone /></div>
            <div class="skeleton-projects-td"><SkeletonBone /></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import SkeletonBaseTable from '@/components/common/skeleton/SkeletionBaseTable.vue';

@Component
export default class SkeletonProjectsTable extends SkeletonBaseTable {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-projects-row {
    width: 100%;
    height: 80px;
    padding-left: $default-padding;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $divider-gray;
}

.skeleton-projects-td {
    width: 145px;
    padding: $small-padding;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skeleton-projects-td ::v-deep .skeleton-bone {
    width: 72px;
}

.skeleton-projects-td-select {
    width: 20px;
    height: 20px;
    border-radius: $border-radius-small;
    margin-right: 18px;
}

.skeleton-projects-title-td {
    width: 395px;
}

.skeleton-projects-title-td--content {
    display: grid;
    grid-template-areas:
        "image first-stroke first-stroke"
        "image second-stroke .";
    grid-template-columns: 100px auto auto;
    grid-gap: 9px 15px;
    width: 100%;
}

.skeleton-projects-title-td--image {
    grid-area: image;
    width: $project-logo-width;
    min-height: $project-logo-height;
    border-radius: 0;
}

.skeleton-projects-title-td--first-stroke {
    grid-area: first-stroke;
    align-self: end;
    width: 85%;
}

.skeleton-projects-title-td--second-stroke {
    grid-area: second-stroke;
    align-self: start;
}
</style>
