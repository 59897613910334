<template>
    <WsDialog :title="$t('Button.edit_tags')" no-body-scroll @close="cancel">
        <WsCombobox
            v-model="localValue"
            :items="items"
            :allow-create="allowCreateTag"
            :rules="allowCreateTag ? rules : []"
            :maxlength="TAGS_MAX_LENGTH"
            searchable
            multiple
            is-limit
            autofocus
        />

        <div class="actions-wrapper">
            <WsButton
                v-show="isShowManageTags && project.isCanManageTags"
                size="xsmall"
                contained
                @click="goToManageTags"
            >
                {{ $t('IssueSettings.tags.linkFromIssueTracker') }}
            </WsButton>
            <div>
                <WsButton
                    :disabled="isDisableSave"
                    round
                    @click="save"
                >
                    {{ $t('Button.save') }}
                </WsButton>
                <WsButton round @click="cancel">{{ $t('Button.cancel') }}</WsButton>
            </div>
        </div>
    </WsDialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { RouterNames, TAGS_MAX_LENGTH } from '@/constants';
import { Project } from '@/models';
import { DialogEditTagsMixin } from '@/mixins';
import WsButton from '@/components/common/WsButton.vue';
import WsCombobox from '@/components/common/WsCombobox.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    components: {
        WsButton,
        WsCombobox,
        WsDialog,
    },
})
export default class DialogEditTags extends DialogEditTagsMixin {
    @Prop({ default: () => [] }) public value!: string[];
    @Prop({ default: () => [] }) public items!: string[];
    @Prop({ type: Boolean, default: false }) public allowCreateTag!: boolean;
    @Prop({ type: Boolean, default: false }) public isShowManageTags!: boolean;

    public readonly TAGS_MAX_LENGTH = TAGS_MAX_LENGTH;

    public localValue: string[] = [];

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }
    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get isDisableSave() {
        return this.isCheckDisableSave(this.localValue);
    }

    @Emit()
    public save() {
        return;
    }

    @Emit()
    public cancel() {
        return;
    }

    @Emit()
    public input(value: string[]) {
        return value;
    }

    @Watch('localValue')
    public watchLocalValue(newValue: string[]) {
        this.input(newValue);
    }

    public mounted() {
        this.localValue = this.value;
    }

    public goToManageTags() {
        this.$router.push({
            name: RouterNames.IssueSettings,
            params: {
                language: this.$route.params.language,
                projectId: this.$route.params.projectId,
                licenseId: this.$route.params.licenseId,
            },
            hash: '#tags',
        });
    }
}
</script>

<style lang="scss" scoped>

::v-deep .v-input__append-outer .text-limit {
    display: none;
}

.actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
</style>
