<template>
    <IssueDetailsItem
        v-if="!multiSelectMode && showIssueDetail"
        :label="label"
    >
        <span
            v-for="(file, index) in currentIssue.clashSourceFile"
            :key="index"
            class="issue-source-file amp-mask"
        >
            {{ file }}
        </span>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { TranslateResult } from 'vue-i18n';
import { Component, Prop } from 'vue-property-decorator';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsSourceFile extends IssueDetailsBase {
    @Prop({ required: true }) public label!: TranslateResult;

    get multiSelectMode() {
        return this.$store.getters.multiSelectedIssues.length > 1;
    }
}
</script>
