import moment from 'moment';
import { unitOfTime } from 'moment/moment';
import {
    dateObjToMoment,
    getUtcMidday,
    makeShiftForAbsoluteDates,
} from '@/services';
import { DateFilterRuleType, IssueTrackerFilterByDateBase } from './IssueTrackerFilterByDateBase';
import { IssueFilterExpr } from '@/domain/issueFilter/constants/IssueFilterExpr';
import { TimeUnit } from '@/domain/chart/constants/TimeUnit';
import { Direction } from '@/domain/chart/constants/Direction';

const TimeUnitStrings: { [key: number]: unitOfTime.DurationConstructor } = {
    [TimeUnit.DAYS]: 'day',
    [TimeUnit.WEEKS]: 'week',
    [TimeUnit.MONTHS]: 'month',
    [TimeUnit.YEARS]: 'year',
};

export class IssueTrackerFilterByDateValue extends IssueTrackerFilterByDateBase {
    constructor(type: string, dateFilterType: number, date?: any, date2?: any, days?: number) {
        super(type, dateFilterType, date, date2, days);
    }

    get dateFilter() {
        switch (this.dateFilterType) {
            case DateFilterRuleType.MoreThanNDaysAgo: {
                const expr = IssueFilterExpr.MORE_THAN;
                const value = getUtcMidday().subtract(this.days, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit };
            }
            case DateFilterRuleType.WithinLastNDays: {
                const expr = IssueFilterExpr.INTERVAL;
                const value = getUtcMidday().subtract(this.days, 'd').unix();
                const timeUnit = TimeUnit.EXACT_DATE;
                const pairValue = getUtcMidday().unix();
                const pairTimeUnit = TimeUnit.EXACT_DATE;
                return { expr, value, timeUnit, pairValue, pairTimeUnit };
            }
            case DateFilterRuleType.CustomEqual:
                if (this.date.isRelative && this.date.value > 0) {
                    const value = dateObjToMoment({ isRelative: true, value: 1, units: 0 }).format('DD-MM-YYYY');
                    return { expr: IssueFilterExpr.EQUALS, ...convertDate({ value, isRelative: false }) };
                }
                return { expr: IssueFilterExpr.EQUALS, ...convertDate(this.date) };
            case DateFilterRuleType.CustomLess:
                return { expr: IssueFilterExpr.MORE_THAN, ...convertDate(this.date) };
            case DateFilterRuleType.CustomLessOrEqual:
                return { expr: IssueFilterExpr.MORE_OR_EQUAL_THAN, ...convertDate(this.date) };
            case DateFilterRuleType.CustomMore:
                return { expr: IssueFilterExpr.LESS_THAN, ...convertDate(this.date) };
            case DateFilterRuleType.CustomMoreOrEqual:
                return { expr: IssueFilterExpr.LESS_OR_EQUAL_THAN, ...convertDate(this.date) };
            case DateFilterRuleType.CustomBetween: {
                const { value: pairValue, timeUnit: pairTimeUnit } = convertDate(this.date2);
                return { expr: IssueFilterExpr.INTERVAL, ...convertDate(this.date), pairValue, pairTimeUnit };
            }
        }

        function convertDate(dateObj: any) {
            const shiftForAbsoluteDates = makeShiftForAbsoluteDates();
            const { isRelative, value, units } = dateObj;
            if (!isRelative) {
                return {
                    timeUnit: TimeUnit.EXACT_DATE,
                    value: moment(value, 'DD-MM-YYYY').add(shiftForAbsoluteDates, 'second').unix(),
                };
            }
            const timeUnit = [
                TimeUnit.DAYS,
                TimeUnit.WEEKS,
                TimeUnit.MONTHS,
                TimeUnit.YEARS,
            ][units];
            const direction = Math.sign(dateObj.value) === 1 ? Direction.AHEAD : Direction.AGO;
            const valueAbs = dateObj.value >= 0 ? 0 : Math.abs(dateObj.value);
            if (direction === Direction.AGO) {
                return { timeUnit, value: valueAbs };
            } else {
                return {
                    timeUnit: TimeUnit.EXACT_DATE,
                    value: getUtcMidday().add(valueAbs, TimeUnitStrings[timeUnit]).unix(),
                };
            }
        }
        return {};
    }
}
