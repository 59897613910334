<template>
    <v-text-field
        ref="vtextfield"
        v-model="model"
        :label="label"
        :error-count="maxMessages"
        :messages="messages"
        :error-messages="errorMessages"
        :success-messages="successMessages"
        :error="error"
        :rules="rules"
        :hide-details="hideDetails"
        :hint="hint"
        :persistent-hint="persistentHint"
        :outlined="!isNew && outlined"
        :dense="dense"
        :required="required"
        :placeholder="placeholder"
        :type="localType"
        :append-icon="icon"
        :clearable="clearable"
        :autofocus="autofocus"
        :disabled="disabled"
        :name="name"
        :maxlength="maxlength"
        :validate-on-blur="validateOnBlur"
        :min="min"
        :max="max"
        :readonly="readonly"
        :class="{
            'ws-input': isNew,
            'ws-search': search,
            ['spacing-' + spacing]: true,
            'disabled': (isNew && disabled),
            'with-limit': isLimit,
            'ws-input__append-inner': isNew && $slots.append,
        }"
        @click:append="showText = !showText"
        @keypress.enter="enter"
        @change="change"
        @blur="onBlur"
        @click="click"
        @update:error="onUpdateError"
    >
        <slot />
        <template #label v-if="required">
            <span class="required-star">*</span>{{ label }}
        </template>
        <template v-if="isLimit && !disabled" #append>
            <div class="text-limit">
                <b>{{ valueLength }}</b>/{{ maxlength }}
            </div>
        </template>
        <template v-else-if="$slots.append" #append>
            <slot name="append" />
        </template>
    </v-text-field>
</template>

<script lang="ts">
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class WsInput extends VuetifyElement {
    @Prop({ default: 'text' }) public type!: 'text' | 'password' | 'email' | 'number';
    @Prop({ type: Boolean, default: false }) public showPassword!: boolean;
    @Prop({ type: Boolean, default: false }) public autofocus!: boolean;
    @Prop({ type: Boolean, default: false }) public validateOnBlur!: boolean;
    @Prop({ type: Number, default: 1024 }) public maxlength!: number;
    @Prop({ type: String }) public name!: string;
    @Prop() public appendIcon!: string;
    @Prop({ default: -Infinity }) public min!: number;
    @Prop({ default: Infinity }) public max!: number;
    @Prop({ type: String }) public inputDataTest!: string;
    @Prop({ type: String }) public labelDataTest!: string;
    @Prop({ type: String }) public errorDataTest!: string;

    @Prop({ type: Boolean }) public new!: boolean;
    @Prop({ type: Boolean }) public small!: boolean;
    // @ts-ignore
    @Prop({ type: String }) public placeholder!: string;
    @Prop({ type: Boolean, default: false }) public search!: boolean;
    @Prop({ type: Boolean, default: false }) public isLimit!: boolean;
    @Prop({ type: Boolean, default: false }) public readonly!: boolean;

    public showText = false;

    @Emit()
    public input(value: string) {
        return value?.trim();
    }

    @Emit()
    public change(value: string) {
        return value?.trim();
    }

    @Emit()
    public enter() {
        return this.model.startDate;
    }

    @Emit()
    public click() {
        return;
    }

    @Emit('blur')
    public onBlur() {
        if (this.errorDataTest) {
            this.addErrorMessageDataTest();
        }
        return;
    }

    @Emit('error')
    public onError(value: boolean) {
        return value;
    }

    get valueLength() {
        return this.model?.length || 0;
    }

    get localType() {
        if (this.type === 'password') {
            return this.showText ? 'text' : 'password';
        }
        return this.type;
    }

    get icon() {
        if (this.appendIcon) {
            return this.appendIcon;
        }
        if (this.showPassword) {
            return this.showText ? 'mdi-eye' : 'mdi-eye-off';
        }
        return '';
    }

    get isNew() {
        return this.new;
    }

    get spacing() {
        if (this.small) {
            return 'small';
        }
        return 'medium';
    }

    @Watch('errorMessages', { immediate: true })
    public onChangeErrorMessages(value: TranslateResult[] | TranslateResult) {
        if (value && this.errorDataTest) {
            this.addErrorMessageDataTest();
        }
    }

    public mounted() {
        if (this.inputDataTest) {
            this.addInputDataTest();
        }

        if (this.labelDataTest) {
            this.addLabelDataTest();
        }
    }

    public addInputDataTest() {
        const vTextFieldEl = this.$refs?.vtextfield as Vue;
        const inputEl = vTextFieldEl?.$refs?.input as HTMLInputElement;

        if (!inputEl) {
            return;
        }

        inputEl.dataset.test = this.inputDataTest;
    }

    public addLabelDataTest() {
        const vTextFieldEl = this.$refs?.vtextfield as Vue;
        const labelEl = vTextFieldEl?.$refs?.label as HTMLInputElement;

        if (!labelEl) {
            return;
        }

        labelEl.dataset.test = this.labelDataTest;
    }

    public onUpdateError(value: boolean) {
        this.onError(value);
        this.addErrorMessageDataTest();
    }

    public addErrorMessageDataTest() {
        this.$nextTick(() => {
            const messagesElement = this.$el.querySelector<HTMLDivElement>('.v-messages__message');

            if (!messagesElement) {
                return;
            }

            messagesElement.dataset.test = this.errorDataTest;
        });
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-text-field {
    ::v-deep input {
        padding: 6px 0 6px;
    }
}
.v-text-field--outlined.v-input--dense.v-text-field--outlined {
    ::v-deep .v-input__control {
        > .v-input__slot {
            min-height: 36px;
        }
    }
}
.v-input {
    ::v-deep .v-messages__message {
        line-height: 1.4;
    }
}

.with-limit {
    ::v-deep {
        .v-input__append-inner {
            position: absolute;
            top: -18px;
            right: 0;
            width: auto;
            height: auto;

            .text-limit {
                font-size: 12px;
                font-weight: 700;
                color: $light-solid-50;

                b {
                    color: $light-solid-100;
                }
            }
        }
    }
}
</style>
