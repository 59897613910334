<template>
    <div v-if="currentIssue" class="details" v-loading="isMultiEditLoading">
        <IssueDetailsPreview class="amp-block" />
        <IssueDetailsId />

        <IssueDetailsBody
            v-for="item in detailsRowsConfig"
            :key="item.row"
            :field="item.row"
            :rows="rows"
            :editable="isIssueItemEditable(item)"
            :items="auxiliaryLists[item.items]"
            :label="$t(`Issue.${item.title}`)"
            :value-key="item.valueKey"
            :allow-create-tag="isUserCanCreateTags"
            :source-value="getDetailsSourceValue(item)"
            :type="item.type"
            :row="item.row"
            @set-filter="setFilter"
        />
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { TUuid } from '@/types/common';
import {
    BusEvent,
} from '@/constants';
import { IssueTrackerFields } from '@/constants/IssueTrackerFields';
import {
    DatepickerDateRange,
    License,
    Project,
    ProjectMember,
    ProjectMembersOption,
} from '@/models';
import { eventBus } from '@/services/eventBus';
import {
    compareObjsAlphanumerically,
    sortByKeysArray,
} from '@/services';
import { dateFormatter } from '@/services/MomentFormats';
import { IssueStatusEnum } from '../constants/IssueStatus';
import { IssueDetailsConfigItem } from '@/domain/issue/models/IssueDetailsConfigItem';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import { IssueTrackerFilterValue } from '@/domain/issueFilter/services/IssueTrackerFilterValue';
import { IStatusItem } from '@/domain/workflow/types/workflow';
import { NAVISWORKS_TYPE_UUID } from '@/domain/issueType/constants/customIssueType';
import { IssueFilterExpr } from '@/domain/issueFilter/constants/IssueFilterExpr';
import { getEmptyFilters } from '@/domain/issueFilter/services/getEmptyFilters';
import { CustomStatus } from '@/domain/customStatus/models/CustomStatus';
import { Workflow } from '@/domain/workflow/models/Workflow';
import { IssueType } from '@/domain/issueType/models/IssueType';
import { IssueDetailsInputType } from '@/domain/issue/constants/IssueDetailsInputType';
import { Issue } from '@/domain/issue/models/Issue';
import { PermissionNameByIssueField } from '@/domain/issue/constants/PermissionNameByIssueField';
import { IssueClashValuesByDetailsRow } from '@/domain/issue/constants/IssueDetailsRows';
import { IssuePriorityEnum } from '@/domain/issue/constants/IssuePriority';
import IssueDetailsBody from '@/domain/issue/components/details/IssueDetailsBody.vue';
import IssueDetailsPreview from '@/domain/issue/components/details/IssueDetailsPreview.vue';
import IssueDetailsId from '@/domain/issue/components/details/IssueDetailsId.vue';
import { StampColorsPaletteRGB } from '@/domain/stamp/constants/StampColorsPaletteRGBEnum';

@Component({
    components: {
        IssueDetailsBody,
        IssueDetailsPreview,
        IssueDetailsId,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueDetails extends Vue {
    public IssuesFilterType = IssuesFilterType;
    public rows = IssueTrackerFields;
    public IssueStatusEnum = IssueStatusEnum;

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get projectId() {
        return Number(this.$route.params.projectId);
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get permissions() {
        return this.currentProject.permissions;
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get currentUserEmail(): string {
        return this.$store.getters.userData.email;
    }

    get detailsRowsConfig(): IssueDetailsConfigItem[] {
        const editable = true;

        let statusRow: IssueDetailsConfigItem;

        if (this.currentIssue.customStatus) {
            statusRow = {
                row: IssueTrackerFields.customStatus,
                title: 'status',
                type: IssueDetailsInputType.select,
                editable: !this.currentIssue.procore || this.license.isSeparatedProcoreStatuses,
                permissions: PermissionNameByIssueField[IssueTrackerFields.customStatus],
            } as IssueDetailsConfigItem;
        } else {
            statusRow = {
                row: IssueTrackerFields.status,
                type: IssueDetailsInputType.select,
                editable: !this.currentIssue.procore,
                permissions: PermissionNameByIssueField[IssueTrackerFields.customStatus],
            } as IssueDetailsConfigItem;
        }

        let typeRow;

        if (this.license.isWorkflowEnabled) {
            typeRow = {
                row: IssueTrackerFields.customType,
                title: 'type',
                type: IssueDetailsInputType.select,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.customType],
            };
        }

        return [
            {
                row: IssueTrackerFields.stampAbbr,
                title: 'abbreviation',
                type: IssueDetailsInputType.select,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.stampAbbr],
            },
            {
                row: IssueTrackerFields.title,
                type: IssueDetailsInputType.text,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.title],
            },
            typeRow,
            {
                row: IssueTrackerFields.procore,
                type: IssueDetailsInputType.procore,
            },
            {
                row: IssueTrackerFields.procoreStatus,
                type: IssueDetailsInputType.procoreStatus,
            },
            statusRow,
            {
                row: IssueTrackerFields.assignee,
                type: IssueDetailsInputType.select,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.assignee],
            },
            {
                row: IssueTrackerFields.priority,
                type: IssueDetailsInputType.select,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.priority],
            },
            {
                row: IssueTrackerFields.deadline,
                type: IssueDetailsInputType.date,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.deadline],
            },
            {
                row: IssueTrackerFields.stampColor,
                value: 'stampColorValue',
                title: 'color',
                type: IssueDetailsInputType.color,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.stampColor],
            },
            {
                row: IssueTrackerFields.created,
            },
            {
                row: IssueTrackerFields.reporter,
                type: IssueDetailsInputType.select,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.reporter],
            },
            {
                row: IssueTrackerFields.watchers,
                type: IssueDetailsInputType.multiselect,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.watchers],
            },
            {
                row: IssueTrackerFields.visibility,
                title: 'public',
                type: IssueDetailsInputType.switch,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.visibility],
            },
            {
                row: IssueTrackerFields.tags,
                type: IssueDetailsInputType.dialog,
                editable,
                permissions: PermissionNameByIssueField[IssueTrackerFields.tags],
            },
            {
                row: IssueTrackerFields.clashSourceFile,
                valueKey: IssueClashValuesByDetailsRow[IssueTrackerFields.clashSourceFile],
            },
            {
                row: IssueTrackerFields.grid,
                valueKey: IssueClashValuesByDetailsRow[IssueTrackerFields.grid],
            },
            {
                row: IssueTrackerFields.level,
                valueKey: IssueClashValuesByDetailsRow[IssueTrackerFields.level],
            },
            {
                row: IssueTrackerFields.room,
                valueKey: IssueClashValuesByDetailsRow[IssueTrackerFields.room],
            },
            {
                row: IssueTrackerFields.space,
                valueKey: IssueClashValuesByDetailsRow[IssueTrackerFields.space],
            },
            {
                row: IssueTrackerFields.area,
                valueKey: IssueClashValuesByDetailsRow[IssueTrackerFields.area],
            },
            {
                row: IssueTrackerFields.zone,
                valueKey: IssueClashValuesByDetailsRow[IssueTrackerFields.zone],
            },
        ]
            .filter(Boolean)
            .map((item) => new IssueDetailsConfigItem(item!));
    }

    get stampColors() {
        return StampColorsPaletteRGB;
    }

    get projectMembersOptions(): ProjectMembersOption[] {
        return this.$store.getters.projectMembersByProjectId(this.projectId).map((item: ProjectMember) => {
            return {
                text: item.fullname || item.email,
                value: item.email,
                search: item.company,
            };
        });
    }

    get issueStatuses(): any[] {
        const hasPermissionClose = this.currentIssue.hasPermissions(this.currentUserEmail, this.permissions.closeIssue);
        return [
            IssueStatusEnum.open,
            IssueStatusEnum.in_progress,
            IssueStatusEnum.solved,
            IssueStatusEnum.closed,
        ].filter((value: string) => value !== IssueStatusEnum.closed || hasPermissionClose);
    }

    get issueWorkflow(): Workflow {
        return this.$store.getters.workflowByCustomTypeUuid(this.currentProject.uuid, this.currentIssue.customType);
    }

    get customStatusesSortKeysWithWorkflow(): string[] {
        return this.$store.getters.customStatusesSortKeysByProjectUuidAndWorkflowUuid(this.currentProject.uuid, this.issueWorkflow.uuid);
    }

    get customStatusesSortKeys(): string[] {
        return this.$store.getters.customStatusesSortKeysByProjectUuid(this.currentProject.uuid);
    }

    get allProjectStatuses(): CustomStatus[] {
        return this.$store.getters.customStatusesByProjectUuid(this.currentProject.uuid);
    }

    get allProjectStatusesByUuid() {
        return this.allProjectStatuses.reduce((acc, status) => {
            acc[status.uuid] = status;
            return acc;
        }, {} as Dict<CustomStatus>);
    }

    get issueWorkflowActiveStatusesUuids(): TUuid[] {
        return this.issueWorkflow.statuses
            .filter((status: IStatusItem) => !status.deletedAt)
            .map((status: IStatusItem) => status.uuid);
    }

    get issueCustomStatuses() {
        if (!this.license.isWorkflowEnabled) {
            return this.allProjectStatuses.filter((status: CustomStatus) => {
                return status.isStandard;
            });
        }

        const statuses =this.allProjectStatuses.filter((status: CustomStatus) => {
            return this.issueWorkflowActiveStatusesUuids.includes(status.uuid);
        });

        return sortByKeysArray(statuses, this.customStatusesSortKeysWithWorkflow, 'uuid');
    }

    get issueCustomTypes() {
        const allTypes = this.$store.getters.issueTypesByProjectUuid(this.currentProject.uuid) as IssueType[];

        return allTypes
            .filter((type) => {
                return type.uuid !== NAVISWORKS_TYPE_UUID && !type.isDeleted;
            })
            .sort((a, b) => compareObjsAlphanumerically(a, b, false, 'name'));
    }

    get issuePriorities(): any[] {
        return [
            IssuePriorityEnum.blocker,
            IssuePriorityEnum.critical,
            IssuePriorityEnum.major,
            IssuePriorityEnum.minor,
            IssuePriorityEnum.trivial,
            IssuePriorityEnum.none,
        ];
    }

    get stamps() {
        return this.$store.getters.stampFormattedByProjectId(this.projectId);
    }

    get multiSelectedIssues(): Issue[] {
        return this.$store.getters.multiSelectedIssues;
    }

    get isUserCanChangeStatusToDoneCategory() {
        return this.currentIssue.hasPermissions(this.currentUserEmail, this.permissions.closeIssue);
    }

    get auxiliaryListsOfCustomStatuses() {
        // one issue selected mode
        if (!this.isMultiselectEditModeActive || !this.license.isWorkflowEnabled) {
            if (this.isUserCanChangeStatusToDoneCategory) {
                return this.issueCustomStatuses;
            }

            return this.issueCustomStatuses.filter((customStatus) => !customStatus.isCompletedCategory);
        }

        const typeUuid = this.multiEditNewValues[IssueTrackerFields.customType]?.value
            || (this.multiSelectIssueValues.customType.length === 1 && this.multiSelectIssueValues.customType[0]);

        if (typeUuid) {
            const workflow: Workflow = this.$store.getters.workflowByCustomTypeUuid(this.currentProject.uuid, typeUuid);
            const statuses = workflow.statuses.map((statusItem) => statusItem.uuid);

            return statuses.map((uuid) => this.allProjectStatusesByUuid[uuid]);
        }

        const selectedIssuesTypeUuids = this.multiSelectedIssues.map((issue) => issue.customType);
        const selectedIssuesWorkflows: Workflow[] = selectedIssuesTypeUuids
            .map((typeUuid) => this.$store.getters.workflowByCustomTypeUuid(this.currentProject.uuid, typeUuid));

        let customStatusesUuids: TUuid[] = [];
        selectedIssuesWorkflows.forEach((workflow) => {
            customStatusesUuids.push(...workflow.statuses.map((statusItem) => statusItem.uuid));
        });

        let uniqCustomStatuses = _.uniq(customStatusesUuids).map((uuid) => this.allProjectStatusesByUuid[uuid]);

        // sort for multi select mode
        uniqCustomStatuses = sortByKeysArray(
            uniqCustomStatuses,
            this.customStatusesSortKeys,
            'name',
        );

        if (this.isUserCanChangeStatusToDoneCategory) {
            return uniqCustomStatuses;
        }

        return uniqCustomStatuses.filter((customStatus) => !customStatus.isCompletedCategory);
    }

    get auxiliaryLists(): any {
        const issueWatchers = this.currentIssue.watchers
            .map((watcher: string) => {
                if (!this.projectMembersOptions.find((member) => member.value === watcher)) {
                    return {
                        text: watcher,
                        value: watcher,
                        search: watcher,
                    };
                }
            })
            .filter(Boolean);

        return {
            [IssueTrackerFields.customType]: this.issueCustomTypes,
            [IssueTrackerFields.customStatus]: this.auxiliaryListsOfCustomStatuses,
            [IssueTrackerFields.assignee]: [...this.projectMembersOptions],
            [IssueTrackerFields.watchers]: [...this.projectMembersOptions, ...issueWatchers],
            [IssueTrackerFields.reporter]: [...this.projectMembersOptions, this.currentIssue.reporter],
            [IssueTrackerFields.tags]: this.currentProject.issueTags,
            [IssueTrackerFields.status]: this.issueStatuses,
            [IssueTrackerFields.priority]: this.issuePriorities,
            [IssueTrackerFields.stampColor]: this.stampColors,
            [IssueTrackerFields.stampAbbr]: this.stamps.map((stamp: any) => {
                return {
                    ...stamp,
                    value: stamp.fields.stampAbbr,
                };
            }),
        };
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get isMultiEditLoading(): boolean {
        return this.$store.getters.isMultiEditLoading;
    }

    get multiSelectIssueValues(): any {
        return this.$store.getters.multiSelectIssueValues;
    }

    get multiEditNewValues(): any {
        return this.$store.getters.multiEditNewValues;
    }

    get isUserCanCreateTags(): boolean {
        return this.currentProject.isCanCreateTags;
    }

    public getMultiValue(field: string) {
        if (field === 'stampColorValue') {
            field = 'stampColor';
        }

        /*
        *   multi edit new values
        */

        if (this.multiEditNewValues[field]) {
            return this.multiEditNewValues[field].value;
        }

        /*
        *   multi edit value by selected issues
        */

        if (this.multiSelectIssueValues[field] && this.multiSelectIssueValues[field].length === 1) {
            if (field === IssueTrackerFields.tags) {
                return JSON.parse(this.multiSelectIssueValues[field][0]);
            }

            return this.multiSelectIssueValues[field][0];
        }

        /*
        *   multi edit default input values
        */

        if (field === IssueTrackerFields.deadline) {
            return new DatepickerDateRange();
        }

        if (field === IssueTrackerFields.tags) {
            return [];
        }

        return null;
    }

    public getDetailsSourceValue(item: IssueDetailsConfigItem) {
        if (this.isMultiselectEditModeActive) {
            return this.getMultiValue(item.valueKey);
        }

        return (this.currentIssue as any)[item.valueKey];
    }

    public isNewMultiValue(field: string): boolean {
        return (this.multiEditNewValues[field] && Object.keys(this.multiEditNewValues[field])?.length > 0);
    }

    public showFieldMultipleText(field: string) {
        const result: boolean = this.multiSelectIssueValues[field]?.length > 1
            && !this.isNewMultiValue(field);

        if (field === IssueTrackerFields.tags) {
            return result && this.getMultiValue(field)?.length === 0;
        }

        return result;
    }

    public setFilter({ type, value }: { type: any, value: any }) {
        if (type === 'createdFrom') {
            type = 'binding';
        }

        const filters = getEmptyFilters();
        if (_.isEqual(filters[type], value)) {
            return;
        }

        const modifier = type === IssuesFilterType.tags ? IssueFilterExpr.HAS_ONE_IN : IssueFilterExpr.IN;

        if (type === IssuesFilterType.clashGridX) {
            eventBus.$emit(BusEvent.openFilter, IssuesFilterType.clashGridX);
            eventBus.$emit(BusEvent.openFilter, IssuesFilterType.clashGridY);

            const values = value.split('-');
            filters[IssuesFilterType.clashGridX] = new IssueTrackerFilterValue(
                IssuesFilterType.clashGridX,
                modifier,
                [values[0]],
            );
            filters[IssuesFilterType.clashGridY] = new IssueTrackerFilterValue(
                IssuesFilterType.clashGridY,
                modifier,
                [values[1]],
            );
        } else {
            eventBus.$emit(BusEvent.openFilter, type);
            filters[type] = new IssueTrackerFilterValue(type, modifier, [value]);
        }

        this.$store.dispatch('updateTrackerFilters', { projectId: this.projectId, filters });
    }

    public isIssueItemEditable(item: IssueDetailsConfigItem): boolean {
        if (this.isMultiselectEditModeActive) {
            return this.multiSelectedIssues.some((issue: Issue) => {
                return this.checkItemEditable(issue, item);
            });
        }

        return this.checkItemEditable(this.currentIssue, item);
    }

    private checkItemEditable(issue: Issue, item: IssueDetailsConfigItem): boolean {
        return !issue.isDeleted
            && item.editable
            && (!item.permissions || issue.hasPermissions(this.currentUserEmail, this.permissions[item.permissions]));
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/issue';
@import '@/styles/mixins';

.details {
    @include scrollbar;
}

::v-deep .filter-link {
    color: $primary-blue;
    cursor: pointer;
}
</style>
