import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Protobuf } from '@/services';
import { ISorting } from '@/domain/issue/services/IssueOrderParse';
import { TrackerFilters } from '@/domain/issueFilter/types/ProjectIssuesFilters';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import { IssuesFilter } from '@/domain/issueFilter/models/IssuesFilter';
import { IIssueFilter } from '@/domain/issueFilter/types/IIssueFilter';
import { CustomStatusCategory } from '@/domain/customStatus/constants/CustomStatusCategory';
import { BrokenPresetEnum } from '@/domain/issuePreset/constants/BrokenPresetEnum';
import {
    convertDesktopFilterSetToTrackerFilters,
} from '@/domain/issueFilter/services/convertDesktopFilterSetToTrackerFilters';
import { IssueStatusEnum } from '@/domain/issue/constants/IssueStatus';

const mapOfLegacyStatusesToStatusCategory: Partial<Record<IssueStatusEnum, CustomStatusCategory>> = {
    [IssueStatusEnum.open]: CustomStatusCategory.ToDo,
    [IssueStatusEnum.in_progress]: CustomStatusCategory.Tracking,
    [IssueStatusEnum.solved]: CustomStatusCategory.Completed,
    [IssueStatusEnum.closed]: CustomStatusCategory.Completed,
};

const convertLegacyStatusPreset = (presetFilter: any) => {
    return new IssuesFilter({
        type: IssuesFilterType.statusCategory,
        expr: presetFilter.expr,
        value: presetFilter.value?.map((value: IssueStatusEnum) => mapOfLegacyStatusesToStatusCategory[value]),
    });
};

export class IssueFilterPreset {
    public uuid: string;
    public title: string;
    public visibility: any;
    public filters: IIssueFilter[];
    public sorting: ISorting;
    public isAllowShowCounts: boolean;
    public broken: BrokenPresetEnum;

    constructor(preset: any = {}) {
        let localPreset = _.cloneDeep(preset);

        this.uuid = localPreset.uuid || uuidv4();
        this.title = localPreset.title;
        this.visibility = localPreset.visibility;
        this.filters = localPreset.filters || [];
        this.sorting = _.isString(localPreset.sorting) ? Protobuf.decodeSorting(localPreset.sorting) : localPreset.sorting;
        this.isAllowShowCounts = localPreset.isAllowShowCounts || false;
        this.broken = localPreset.broken || BrokenPresetEnum.notBroken;
    }

    get trackerFilters(): TrackerFilters {
        const localFilters = _.cloneDeep(this.filters);
        const indexOfLegacyStatusFilter = localFilters?.findIndex((filter: any) => filter.type === IssuesFilterType.status);
        const indexOfCustomStatusFilter = localFilters?.findIndex((filter: any) => filter.type === IssuesFilterType.customStatus);
        const indexOfStatusCategoryFilter = localFilters?.findIndex((filter: any) => filter.type === IssuesFilterType.statusCategory);

        if (indexOfLegacyStatusFilter !== -1 && !(indexOfCustomStatusFilter !== -1 || indexOfStatusCategoryFilter !== -1)) {
            const convertedFilter = convertLegacyStatusPreset(localFilters[indexOfLegacyStatusFilter]);
            localFilters.splice(indexOfLegacyStatusFilter, 1, convertedFilter);
        }
        return convertDesktopFilterSetToTrackerFilters(localFilters);
    }
}
