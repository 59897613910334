<template>
    <div class="skeleton-default-table">
        <div
            v-for="index in getRows()"
            :key="index"
            class="skeleton-default-row"
        >
            <SkeletonBone v-if="options.hasSelect" class="skeleton-default-td-select" />
            <SkeletonBone class="skeleton-default-main-td" />
            <SkeletonBone class="skeleton-default-td" />
            <SkeletonBone class="skeleton-default-td" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import SkeletonBaseTable from '@/components/common/skeleton/SkeletionBaseTable.vue';

@Component
export default class SkeletonRolesTable extends SkeletonBaseTable {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-default-row {
    width: 100%;
    height: 42px;
    padding-left: $default-padding;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $divider-gray;
}

.skeleton-default-td {
    width: 72px;
    margin-right: 73px;
    padding: $small-padding;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skeleton-default-td-select {
    width: 20px;
    height: 20px;
    border-radius: $border-radius-small;
    margin-right: 18px;
}

.skeleton-default-main-td {
    width: 320px;
    margin-right: 110px;
    padding: $small-padding;
}
</style>
