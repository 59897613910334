<template>
    <div class="dialog-boarding-content">
        <h3 class="content-title">{{ title }}</h3>
        <div class="media">
            <video
                v-if="video"
                :src="media"
                width="560"
                height="314"
                playsinline
                muted
                loop
                autoplay
            />
            <img v-else :src="media" alt="">
        </div>
        <div
            v-html="description"
            class="description"
        />
        <WsButton
            v-if="downloadButton"
            class="download-button"
            type="primary"
            size="large"
            contained
            @click="click"
        >
            {{ $t('Boarding.welcomeStep.button') }}
        </WsButton>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        WsButton,
    },
})
export default class DialogBoardingContent extends Vue {
    @Prop({ required: true }) public title!: string;
    @Prop({ required: true }) public media!: string;
    @Prop({ required: true }) public description!: string;
    @Prop({ type: Boolean, default: false }) public downloadButton!: boolean;
    @Prop({ type: Boolean, default: false }) public video!: boolean;

    @Emit()
    public click() {
        return;
    }
}

</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.dialog-boarding-content {
    height: 100%;
    width: 100%;
    .content-title {
        text-align: left;
        font-size: $font-size-huge;
        line-height: 32px;
        color: $light-solid-80;
        margin: 0 0 24px;
    }

    .media {
        height: 314px;
        width: 100%;
        margin: 0 0 24px;
        overflow: hidden;
        border-radius: 4px;

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video {
            border: 1px solid $light-solid-10;
        }
    }

    .description {
        min-height: 184px;

        ::v-deep {
            p {
                margin: 0 0 14px;
                font-size: $font-size;
                line-height: 24px;

                &:last-child{
                    margin: 0;
                }
            }

            ul li {
                list-style: inherit;
            }
        }
    }

    .download-button {
        font-size: $font-size-huge;
        line-height: 32px;
        width: 326px;
        margin: 0 0 24px;
    }
}

</style>