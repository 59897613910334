<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IIssuesWithWarningsItem } from '@/types/issue-tracker';
import { BusEvent, Color, ReasonsWhyIssuesHaveProblems } from '@/constants';
import { IssueTrackerFields } from '@/constants/IssueTrackerFields';
import { DatepickerDateRange, Project, User } from '@/models';
import { eventBus } from '@/services/eventBus';
import { Issue } from '@/domain/issue/models/Issue';

@Component
export default class IssueDetailsBase extends Vue {
    @Prop({ required: true }) public valueKey!: string;
    @Prop({ required: true }) public field!: IssueTrackerFields;

    public readonly Color = Color;

    get showFieldMultipleText() {
        return this.isMultiselectEditModeActive && this.multiSelectIssueValues?.length > 1 && !this.isNewMultiValue;
    }

    get showIssueDetail(): boolean {
        const issue = this.currentIssue;

        const isClashField = [
            IssueTrackerFields.clashSourceFile,
            IssueTrackerFields.grid,
            IssueTrackerFields.level,
            IssueTrackerFields.room,
            IssueTrackerFields.space,
            IssueTrackerFields.area,
            IssueTrackerFields.zone,
        ].includes(this.field);

        if (isClashField) {
            return !_.isEmpty(issue[this.valueKey as keyof Issue]);
        }

        if (IssueTrackerFields.stamp === this.field) {
            return this.isMultiselectEditModeActive;
        }

        const isAlways = [
            IssueTrackerFields.visibility,
            IssueTrackerFields.title,
            IssueTrackerFields.deadline,
        ].includes(this.field);

        if (this.isMultiselectEditModeActive) {
            return this.multiSelectIssueValues?.length || isAlways;
        }

        if (this.field === IssueTrackerFields.procoreStatus && this.currentIssue.procore) {
            return true;
        }

        return issue[this.valueKey as keyof Issue] || isAlways;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get currentValue() {
        if (this.multiEditNewValues) {
            return this.multiEditNewValues.value;
        }

        return this.showFieldMultipleText
            ? []
            : this.currentIssue[this.valueKey as keyof Issue];
    }

    get multiValue() {
        let field = this.valueKey;
        if (field === 'stampColorValue') {
            field = 'stampColor';
        }

        //  multi edit new values
        if (this.multiEditNewValues) {
            return this.multiEditNewValues;
        }

        // multi edit value by selected issues
        if (this.multiSelectIssueValues?.length === 1) {
            if (field === IssueTrackerFields.tags) {
                return JSON.parse(this.multiSelectIssueValues[0]);
            }

            return this.multiSelectIssueValues[0];
        }

        // multi edit default input values
        if (field === IssueTrackerFields.deadline) {
            return new DatepickerDateRange();
        }

        if (field === IssueTrackerFields.tags) {
            return [];
        }

        return null;
    }

    get isNewMultiValue(): boolean {
        return Boolean(this.multiEditNewValues);
    }

    get multiEditNewValues(): any {
        return this.$store.getters.multiEditNewValues[this.valueKey];
    }

    get multiSelectIssueValues(): any {
        let field = this.valueKey;

        if (field === 'stampColorValue') {
            field = 'stampColor';
        }

        return this.$store.getters.multiSelectIssueValues[field];
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get currentUser(): User {
        return this.$store.getters.userData;
    }

    get issuesWithPermissionError(): Issue[] {
        const permissionErrors = this.$store.getters.getNewValuesPermissionsErrors(this.currentUser.email, this.currentProject.permissions);

        return permissionErrors[this.field] || [];
    }

    /**
     * Field can have different reasons for showing warning icon. Basically it's permission errors.
     */
    get isShowWarningIcon(): boolean {
        return this.issuesWithPermissionError?.length > 0;
    }

    @Emit()
    public change(value: any) {
        return value;
    }

    public showDialogWithWarningDescription() {
        const problems: IIssuesWithWarningsItem[] = [];

        if (this.issuesWithPermissionError.length > 0) {
            problems.push({
                reason: ReasonsWhyIssuesHaveProblems.permissionError,
                issues: this.issuesWithPermissionError,
            });
        }

        if (!problems.length) {
            return;
        }

        eventBus.$emit(BusEvent.showDialogIssuesWithProblems, problems);
    }
}
</script>
