<template>
    <div v-click-outside="close" class="ws-context-menu-wrapper">
        <div class="ws-context-menu">
            <div
                v-for="(option, index) in options"
                :key="index"
                :class="{ active: activeIndex === index }"
                class="ws-context-menu-item"
                @click="option.callback(option)"
            >
                <WsTruncateAuto :value="option.text" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ContextMenuItem } from '@/types/ui';

@Component({})
export default class CustomIssueType extends Vue {
    @Prop({ type: Array, required: true }) public options!: ContextMenuItem[];
    @Prop() public activeIndex!: number;

    @Emit()
    public close() {

    }
}
</script>

<style scoped lang="scss">

@import '@/styles/wsui/colors.scss';
@import '@/styles/variables.scss';
@import '@/styles/tables.scss';
@import '@/styles/mixins.scss';

</style>
