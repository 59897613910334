<template>
    <div class="skeleton-filter-panel">
        <div class="skeleton-filter__main">
            <div class="skeleton-filter__button">
                <SkeletonBone />
            </div>
            <SkeletonBone class="skeleton-filter__search" />
        </div>
        <div class="skeleton-filter__selects">
            <SkeletonBone />
            <SkeletonBone />
            <SkeletonBone />
            <SkeletonBone />
            <SkeletonBone />
            <SkeletonBone />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonFilterPanel extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-filter__main {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 0 15px;
}

.skeleton-filter__buttons {
    display: flex;
}

.skeleton-filter__button .skeleton-bone {
    min-height: 36px;
    width: 90px;
}

.skeleton-filter__search {
    width: 100%;
    height: 40px;
    border-radius: $border-radius-default;
}

.skeleton-filter__selects {
    display: flex;
    align-items: center;
    height: 45px;
    padding: 10px 15px 0 25px;
}
.skeleton-filter__selects .skeleton-bone {
    width: 110px;
    height: 19px;
    margin-right: 16px;
    border-radius: $border-radius-default;
}
</style>
