<template>
    <div class="skeleton-markup-preview">
        <svg
            width="140"
            height="140"
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M40 58L42.8284 55.1716C41.2663 53.6095 38.7337 53.6095 37.1716 55.1716L40 58ZM85 103L82.1716 105.828C83.7337 107.391 86.2663 107.391 87.8284 105.828L85 103ZM106 82L108.828 79.1716C107.266 77.6095 104.734 77.6095 103.172 79.1716L106 82ZM0 17V123H8V17H0ZM5 128H135V120H5V128ZM140 123V17H132V123H140ZM135 12H5V20H135V12ZM6.82843 96.8284L42.8284 60.8284L37.1716 55.1716L1.17157 91.1716L6.82843 96.8284ZM37.1716 60.8284L82.1716 105.828L87.8284 100.172L42.8284 55.1716L37.1716 60.8284ZM87.8284 105.828L108.828 84.8284L103.172 79.1716L82.1716 100.172L87.8284 105.828ZM103.172 84.8284L133.172 114.828L138.828 109.172L108.828 79.1716L103.172 84.8284ZM103 36C95.8203 36 90 41.8203 90 49H98C98 46.2386 100.239 44 103 44V36ZM90 49C90 56.1797 95.8203 62 103 62V54C100.239 54 98 51.7614 98 49H90ZM103 62C110.18 62 116 56.1797 116 49H108C108 51.7614 105.761 54 103 54V62ZM116 49C116 41.8203 110.18 36 103 36V44C105.761 44 108 46.2386 108 49H116ZM140 17C140 14.2386 137.761 12 135 12V20C133.343 20 132 18.6568 132 17H140ZM135 128C137.761 128 140 125.761 140 123H132C132 121.343 133.343 120 135 120V128ZM0 123C0 125.761 2.23856 128 5 128V120C6.65687 120 8 121.343 8 123H0ZM8 17C8 18.6568 6.65686 20 5 20V12C2.23857 12 0 14.2386 0 17H8Z" fill="#CFCFCF" />
        </svg>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonMarkupPreview extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-markup-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    > .skeleton-bone {
        width: 140px;
        height: 140px;
        border-radius: $border-radius-default;
    }
}
</style>
