<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :label="label"
        :multi-text="showFieldMultipleText"
    >
        <a :href="currentIssue.procore.RFILink" class="rfi-link" target="_blank">
            {{ $t(`Issue.${currentIssue.procore.RFIDraft ? 'draftRfi' : 'rfi'}`,
                  { subject: currentIssue.procore.RFISubject, number: currentIssue.procore.RFINumber })
            }}
        </a>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { Issue } from '@/domain/issue/models/Issue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsProcore extends IssueDetailsBase {
    // @ts-ignore
    @Prop({ required: false }) public valueKey!: keyof Issue;
    @Prop({ required: true }) public label!: any;
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.rfi-link {
    color: $link;
    font-weight: 500;
}
</style>
