import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
// @ts-ignore
import settings from 'reviztoSettings';
import { AmplitudeEvent } from '@/constants';

const { amplitudeApiKey } = settings;

const SAMPLE_RATE = 0.01;

const useAmplitude = () => {
    if (!amplitudeApiKey) {
        return;
    }
    amplitude.init(amplitudeApiKey, {
        autocapture: {
            pageViews: true,
            sessions: true,
            formInteractions: false,
            elementInteractions: true,
            fileDownloads: false,
        },
    });

    const sessionReplayTracking = sessionReplayPlugin({
        forceSessionTracking: false,
        sampleRate: SAMPLE_RATE,
    });

    amplitude.add(sessionReplayTracking);
    amplitude.logEvent(AmplitudeEvent.sessionReplayInitialized, { sampleRate: SAMPLE_RATE });
};

export { amplitude, useAmplitude };
