import { CookieName } from '@/constants';
import { CookieObject } from '@/services';

const ssoKeyObject = new CookieObject(CookieName.ssoKey);

export class SSOKey {
    public static set(email: string, regionId: string, value: string) {
        if (value) {
            ssoKeyObject.set([regionId, email], value);
        }
    }

    public static get(email: string, regionId: string) {
        return ssoKeyObject.get([regionId, email]) as string | undefined;
    }
}
