export enum SettingTransferOption {
    MEMBERS = 'members',
    FILTER_PRESETS = 'filterPresets',
    TAGS = 'tags',
    STAMP_TEMPLATES = 'stampsTemplates',
    CLASH_SYNC = 'clashSyncs',
    DASHBOARDS = 'dashboards',
    REPORTS = 'reports',
    SEARCH_SETS = 'searchSets',
    APPEAR_SETS = 'appearSets',
    FAVORITE_PROPERTY_SET = 'favoritePropertySet',
    CUSTOM_PROPERTY_SET = 'customPropertySet',
    VIEWPOINTS = 'viewpoints',
    WORKFLOW_SETTINGS = 'issueWorkflows',

    // неиспользуемые
    STAMP_TEMPLATES_CONFLICT = 'stampsTemplatesConflict',
    DASHBOARDS_CONFLICT = 'dashboardsConflict',
    REPORTS_CONFLICT = 'reportsConflict',
    SEARCH_SETS_CONFLICT = 'searchSetsConflict',
    APPEAR_SETS_CONFLICT = 'appearSetsConflict',
    FAVORITE_PROPERTY_SET_CONFLICT = 'favoritePropertySetConflict',
}

