import { Dict } from '@/types/Dict';
import { IssueStatusEnum, LegacyIssueStatusToCustomStatusName } from '@/domain/issue/constants/IssueStatus';

export const Color = {
    richBlack: '#000000',
    transparent: '#00000000',
    selectBlack: '#2B2B2B',
    closeBlack: '#909399',
    defaultBlack: '#616161',
    mediumGray: '#8C8C8C',
    disableGray: '#CFCFCF',
    dividerGray: '#DEDEDE',
    light: '#EDEDED',
    middleLight: '#F5F5F5',
    extraLight: '#F7F7F7',
    white: '#FFFFFF',
    primaryGrey: '#4D4D4D',

    darkGrey: '#666666',

    primaryRed: '#CC2929',
    primaryOrange: '#FA9600',
    primaryYellow: '#FFD32E',
    primaryGreen: '#24CE08',
    primaryBlue: '#1D6FED',
    primaryLightBlue: '#D2E2FB',

    transparentRed: '#CC292926',
    transparentOrange: '#FA960026',
    transparentYellow: '#FFD32E26',
    transparentGreen: '#24CE0826',
    transparentBlue: '#1D6FED26',
    transparentLightBlue: '#D2E2FB26',

    paletteRed: '#E01830',
    paletteRedLight: '#F7DFDF',
    paletteOrange: '#F8812F',
    paletteYellow: '#FCDC46',
    paletteLime: '#71DA41',
    paletteGreen: '#21932E',
    paletteSky: '#58F2F1',
    paletteBlue: '#201FE1',
    palettePurple: '#892EFB',
    paletteViolet: '#ED2CFC',

    miskRed: '#E9291B',
    miskGray: '#74858C',
    miskOldGreen: '#02B609',

    successLight: '#52CC74',
    errorLight: '#FF2000',
    alertLight: '#FFDD99',
    alertLight2: '#FFFBF2',

    activityBlue: '#1F77B4',
    lightBlue: '#006DFF',
    darkBlue: '#0057CB',

    singleInitial: '#CC0000',

    attentionOrange: '#FFAA00',

    solid5: '#F3F3F3',
    solid60: '#727272',
    accent100: '#006DFF',
    solid40: '#A1A1A1',
    solid10: '#E7E7E7',
    solid100: '#1A1A1A',
    success20: '#DCF5E3',
    success50: '#A8E5B9',
    error50: '#FF9180',
    support100: '#843CFA',
    error120: '#CC1A00',
    success120: '#47B266',
    alert120: '#E59900',
    red: '#FF0000',

    status: {
        open: '#CB0011',
        in_progress: '#FD8300',
        solved: '#6EB522',
        closed: '#8D8D8D',
        deleted: '#000000',
    } as Dict,

    convertedLegacyStatus: {
        [LegacyIssueStatusToCustomStatusName[IssueStatusEnum.open]]: '#CB0011',
        [LegacyIssueStatusToCustomStatusName[IssueStatusEnum.in_progress]]: '#FD8300',
        [LegacyIssueStatusToCustomStatusName[IssueStatusEnum.solved]]: '#6EB522',
        [LegacyIssueStatusToCustomStatusName[IssueStatusEnum.closed]]: '#8D8D8D',
        [LegacyIssueStatusToCustomStatusName[IssueStatusEnum.deleted]]: '#000000',
    } as Dict,

    priority: {
        blocker: '#660000',
        critical: '#D92020',
        major: '#FF8C19',
        minor: '#2CB92C',
        trivial: '#24B2B2',
        none: '#8C8C8C',
    } as Dict,

    timeline: {
        red: '#CC0000',
        green: '#00CC00',
        blue: '#0000CC',
        purple: '#9F1ABF',
        yellow: '#CCC23F',
    },

    get procoreDraft(): Dict {
        return {
            'draft-open': this.status.open,
            'draft-in_progress': this.status.in_progress,
            'draft-solved': this.status.solved,
            'draft-closed': this.status.closed,
        };
    },

    get predefined() {
        return {
            ...this.status,
            ...this.convertedLegacyStatus,
            ...this.priority,
            ...this.procoreDraft,
        };
    },

    get timelineInitialList() {
        return [this.timeline.red, this.timeline.green, this.timeline.blue, this.timeline.purple, this.timeline.yellow];
    },
};
