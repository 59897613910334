import { IssueDirections, IssueSort, IssueSorts } from '@/domain/issue/constants/IssueSort';

export const IssueSortBase = {
    [IssueSorts.primary]: {
        [IssueSort.field]: 'created',
        [IssueSort.direction]: IssueDirections.DESC,
    },
    [IssueSorts.secondary]: {
        [IssueSort.field]: 'created',
        [IssueSort.direction]: IssueDirections.DESC,
    },
    [IssueSorts.unread]: true,
};
