import _ from 'lodash';
import Vue from 'vue';
import { Dict } from '@/types/Dict';
import { RESPONSE } from '@/constants';
import { ActionLogApi } from '@/api';
import { ActionLog, IActionLog, LicenseDeletedMember, LicenseMember } from '@/models';

type DictMember = Dict<LicenseMember | LicenseDeletedMember>;

export interface IActionLogStorage {
    licenseLogs: {
        obj: Dict<ActionLog[]>;
        isLoading: boolean;
        isLoaded: boolean;
        errorMessage: string;
        pageObj: Dict<number>;
        loadNumber: number,
    };
    projectLogs: {
        obj: Dict<ActionLog[]>;
        isLoading: boolean;
        isLoaded: boolean;
        errorMessage: string;
        pageObj: Dict<number>;
        loadNumber: number,
    };
    defectiveLogsOpUuids: Dict<number>;
}

export default {
    state: {
        licenseLogs: {
            obj: {},
            isLoading: false,
            isLoaded: false,
            errorMessage: '',
            pageObj: {},
            hiddenLogs: {},
            loadNumber: 0,
        },
        projectLogs: {
            obj: {},
            isLoading: false,
            isLoaded: false,
            errorMessage: '',
            pageObj: {},
            loadNumber: 0,
        },
        defectiveLogsOpUuids: {},
    } as IActionLogStorage,
    getters: {
        licenseLogsById(state: IActionLogStorage) {
            return (licenseId: number) =>
                (state.licenseLogs.obj[licenseId] || [])
                    .filter((log) => !state.defectiveLogsOpUuids[log.operationUuid]);
        },
        licenseLogErrorMessage(state: IActionLogStorage) {
            return state.licenseLogs.errorMessage;
        },
        isLoadingLicenseLogs(state: IActionLogStorage) {
            return state.licenseLogs.isLoading;
        },
        licenseLogPage(state: IActionLogStorage) {
            return (licenseId: number) => {
                return state.licenseLogs.pageObj[licenseId] || 0;
            };
        },
        projectLogsById(state: IActionLogStorage) {
            return (projectId: number) => {
                return state.projectLogs.obj[projectId] || [];
            };
        },
        isLoadingProjectLogs(state: IActionLogStorage) {
            return state.projectLogs.isLoading;
        },
        projectLogPage(state: IActionLogStorage) {
            return (projectId: number) => {
                return state.projectLogs.pageObj[projectId] || 0;
            };
        },
        projectLogErrorMessage(state: IActionLogStorage) {
            return state.projectLogs.errorMessage;
        },
    },
    mutations: {
        addDefectiveLogOpUuid(state: IActionLogStorage, payload: { [uuid: string]: number }) {
            state.defectiveLogsOpUuids = { ...state.defectiveLogsOpUuids, ...payload };
        },
        setLicenseLogs(state: IActionLogStorage, { licenseId, logs }: { licenseId: number; logs: ActionLog[] }) {
            Vue.set(state.licenseLogs.obj, licenseId, logs);
        },
        resetLicenseLogs(state: IActionLogStorage, { licenseId }: { licenseId: number }) {
            Vue.set(state.licenseLogs.obj, licenseId, []);
        },
        setLoadingLicenseLogs(state: IActionLogStorage, value: boolean) {
            state.licenseLogs.isLoading = value;
        },
        setLoadedLicenseLogs(state: IActionLogStorage, value: boolean) {
            state.licenseLogs.isLoaded = value;
        },
        setLicenseLogErrorMessage(state: IActionLogStorage, value: string) {
            state.licenseLogs.errorMessage = value;
        },
        setLicenseLogPage(state: IActionLogStorage, { licenseId, value }: { licenseId?: number; value?: number }) {
            if (licenseId) {
                state.licenseLogs.pageObj[licenseId] = value || 0;
            }
        },
        incrementLicenseLogPage(state: IActionLogStorage, licenseId: number) {
            state.licenseLogs.pageObj[licenseId] = state.licenseLogs.pageObj[licenseId] || 0;
            state.licenseLogs.pageObj[licenseId]++;
        },
        setProjectLogs(state: IActionLogStorage, { projectId, logs }: { projectId: number; logs: ActionLog[] }) {
            Vue.set(state.projectLogs.obj, projectId, logs);
        },
        resetProjectLogs(state: IActionLogStorage, { projectId }: { projectId: number }) {
            Vue.set(state.projectLogs.obj, projectId, []);
        },
        setLoadingProjectLogs(state: IActionLogStorage, value: boolean) {
            state.projectLogs.isLoading = value;
        },
        setLoadedProjectLogs(state: IActionLogStorage, value: boolean) {
            state.projectLogs.isLoaded = value;
        },
        setProjectLogErrorMessage(state: IActionLogStorage, value: string) {
            state.projectLogs.errorMessage = value;
        },
        setProjectLogPage(state: IActionLogStorage, { projectId, value }: { projectId?: number; value?: number }) {
            if (projectId) {
                state.projectLogs.pageObj[projectId] = value || 0;
            }
        },
        incrementProjectLogPage(
            state: IActionLogStorage,
            projectId: number,
        ) {
            state.projectLogs.pageObj[projectId] = state.projectLogs.pageObj[projectId] || 0;
            state.projectLogs.pageObj[projectId]++;
        },
    },
    actions: {
        async loadLicenseLogs(
            { state, getters, commit, dispatch, rootGetters }: any,
            { licenseId, minDate }: { licenseId: number; minDate: number },
        ) {
            const localLoadNumber = ++state.licenseLogs.loadNumber;
            if (getters.licenseLogPage(licenseId) === 0) {
                commit('setLoadingLicenseLogs', true);
            }

            await Promise.all([
                dispatch('loadLicenseMembers', { licenseId }),
                dispatch('loadDeletedLicenseMembers', { licenseId }),
                dispatch('loadAccessRoles', { licenseId }),
                dispatch('loadLicenseProjects', { licenseId }),
            ]);

            const allMembersByUuid: DictMember = rootGetters.allMembersByLicenseIdByUuid(licenseId);

            loadPage();

            async function loadPage() {
                if (localLoadNumber !== state.licenseLogs.loadNumber) {
                    return;
                }
                const requestPage: number = getters.licenseLogPage(licenseId);
                const existingLogs: ActionLog[] = getters.licenseLogsById(licenseId);

                try {
                    const { list, split } = await ActionLogApi.getLicenseLogs(licenseId, requestPage);
                    if (!list.length) {
                        commit('setLoadingLicenseLogs', false);
                        commit('setLoadedLicenseLogs', true);
                        return;
                    }
                    if (localLoadNumber !== state.licenseLogs.loadNumber) {
                        return;
                    }
                    const { logs, date } = groupLogs({
                        allMembersByUuid,
                        existingLogs,
                        newProtoLogs: list,
                    });
                    commit('setLicenseLogs', { licenseId, logs });
                    commit('incrementLicenseLogPage', licenseId);
                    commit('setLoadingLicenseLogs', false);
                    commit('setLoadedLicenseLogs', true);
                    if ((split || (date > minDate)) && (list.length > 0)) {
                        loadPage();
                    }
                } catch (error: any) {
                    if (error.result === RESPONSE.SECURITY_ERROR) {
                        dispatch('navigateBack');
                    } else {
                        commit('setLicenseLogErrorMessage', error.message);
                        commit('setLoadingLicenseLogs', false);
                    }
                }
            }

        },
        async loadProjectLogs(
            { state, getters, commit, dispatch, rootGetters }: any,
            { licenseId, projectId, minDate }: { licenseId: number; projectId: number; minDate: number },
        ) {
            const localLoadNumber = ++state.projectLogs.loadNumber;
            if (getters.projectLogPage(projectId) === 0) {
                commit('setLoadingProjectLogs', true);
            }

            await Promise.all([
                dispatch('loadLicenseMembers', { licenseId, withActivity: false, withDeactivated: true }),
                dispatch('loadDeletedLicenseMembers', { licenseId }).catch(() => []),
                dispatch('loadAccessRoles', { licenseId }),
            ]);

            const allMembersByUuid: DictMember = rootGetters.allMembersByLicenseIdByUuid(licenseId);

            loadPage();

            async function loadPage() {
                if (localLoadNumber !== state.projectLogs.loadNumber) {
                    return;
                }
                const requestPage: number = getters.projectLogPage(projectId);
                const existingLogs: ActionLog[] = getters.projectLogsById(projectId);

                try {
                    const { list, split } = await ActionLogApi.getProjectLogs(projectId, requestPage);
                    if (!list.length) {
                        commit('setLoadingProjectLogs', false);
                        commit('setLoadedProjectLogs', true);
                        return;
                    }
                    if (localLoadNumber !== state.projectLogs.loadNumber) {
                        return;
                    }
                    const { logs, date } = groupLogs({
                        allMembersByUuid,
                        existingLogs,
                        newProtoLogs: list,
                    });
                    commit('setProjectLogs', { projectId, logs });
                    commit('incrementProjectLogPage', projectId);
                    commit('setLoadingProjectLogs', false);
                    commit('setLoadedProjectLogs', true);
                    if ((split || (date > minDate)) && (list.length > 0)) {
                        loadPage();
                    }
                } catch (error: any) {
                    commit('setProjectLogErrorMessage', error.message);
                    commit('setLoadingProjectLogs', false);
                }
            }
        },
    },
};

function groupLogs(
    { allMembersByUuid, existingLogs, newProtoLogs }
        : { allMembersByUuid: DictMember; existingLogs: ActionLog[]; newProtoLogs: IActionLog[] },
) {
    const logsObj = _.keyBy(existingLogs, 'operationUuid');
    const newLogs: ActionLog[] = newProtoLogs
        .map(ActionLog.instantiateImmutable)
        .map(ActionLog.enrichByMember(allMembersByUuid));
    newLogs.forEach((newLog) => {
        const { operationUuid } = newLog;
        const existingLog: ActionLog | undefined = logsObj[operationUuid];
        if (!existingLog) {
            logsObj[operationUuid] = newLog;
            return;
        }
        if (existingLog.actionType === existingLog.originActionType) {
            existingLog.addChild(newLog);
            return;
        }
        if (newLog.actionType === newLog.originActionType) {
            newLog.makeRootInsteadOf(existingLog);
            logsObj[operationUuid] = newLog;
            return;
        }
        logsObj[operationUuid].addChild(newLog);
    });
    const date = _(newLogs).map('createdAt').min() || Infinity;
    return {
        logs: _.values(logsObj),
        date,
    };
}
