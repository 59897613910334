<template>
    <div :class="`type-${type}`" class="notification-toast">
        <div class="icon-wrapper">
            <IconSvg16
                :icon-name="icon"
                stateless
            />
        </div>
        <div class="label">
            <span v-html="sanitizeHtml(text)" />
            <button
                v-if="isReloadLink"
                type="button"
                class="link"
                @click="reload"
            >
                {{ $t('error.reload') }}
            </button>
        </div>
        <button
            v-if="action"
            class="action"
            @click="onActionClick"
        >
            {{ action.text }}
        </button>
        <WsCloseButton class="close" @click="close" />
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator';
import { IToastAction } from '@/types/INotifier';
import { ToastType } from '@/constants/Notifier/ToastType';
import { sanitizeHtml } from '@/services/';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

const toastTypeDefault = ToastType.INFO;

const toastIcon = {
    [ToastType.INFO]: 'info-new',
    [ToastType.WARNING]: 'error',
    [ToastType.SUCCESS]: 'checked',
    [ToastType.ERROR]: 'error',
};

@Component({
    components: {
        WsCloseButton,
        IconSvg16,
    },
})
export default class WsToast extends EventListenersBase {
    @Prop({ type: String, default: toastTypeDefault }) type!: ToastType;
    @Prop({ type: String, required: true }) text!: string;
    @Prop({ type: Boolean, default: false }) isReloadLink!: boolean;
    @Prop({ type: Object, default: null }) action!: IToastAction;
    @Prop() onCloseAction!: () => void;

    public readonly sanitizeHtml = sanitizeHtml;

    get icon() {
        return toastIcon[this.type] || toastIcon[toastTypeDefault];
    }

    @Emit()
    public close() {
        if (this.onCloseAction) {
            this.onCloseAction();
        }
        return;
    }

    public onActionClick() {
        this.action.callback();
        this.close();
    }

    public reload() {
        window.location.reload();
    }

    // Listens to clicks and find links if they exist.
    public mounted() {
        this.eventListeners.add({
            event: 'click',
            handler: (event: any) => {
                const clickElement = event.target;
                
                if (!clickElement?.href) {
                    return;
                }

                const clickElementLink = new URL(clickElement?.href);
                
                if (clickElement?.classList.contains('toast-link') && clickElementLink) {
                    event.preventDefault();
                    this.$router.push({ path: clickElementLink.pathname });
                    this.close();
                }
            },
        });
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors/semantic.scss';
@import '@/styles/helpers/a11y.scss';
@import '@/styles/variables.scss';

.notification-toast {
    position: relative;
    display: flex;
    border-style: solid;
    border-width: 1px 1px 1px 0;
    border-radius: 0 8px 8px 0;
    padding: 7px 7px 7px 13px;
    font-size: 14px;
    line-height: 20px;
    color: $text-primary-default;
    box-shadow: $notification-toast-shadow;

    &::before {
        content: '';
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 0;
        width: 3px;

        background-color: $black;
    }

    &.type-info {
        border-color: $border-accent-subtle-default;
        background-color: $fill-accent-subtle-default;

        &::before {
            background-color: $border-accent-solid-default;
        }

        .icon-wrapper ::v-deep svg {
            color: $text-accent-default;
        }
        
        .close:hover,
        .close:focus {
            --close-button-background-color-hover: #{$fill-accent-subtle-hover};
        }
    }

    &.type-warning {
        border-color: $border-alert-subtle-default;
        background-color: $fill-alert-subtle-default;

        &::before {
            background-color: $border-alert-solid-default;
        }

        .icon-wrapper ::v-deep svg {
            color: $text-alert-default;
        }
        
        .close:hover,
        .close:focus {
            --close-button-background-color-hover: #{$fill-alert-subtle-hover};
        }
    }

    &.type-success {
        border-color: $border-success-subtle-default;
        background-color: $fill-success-subtle-default;

        &::before {
            background-color: $border-success-default;
        }

        .icon-wrapper ::v-deep svg  {
            color: $text-success-default;
        }
        
        .close:hover,
        .close:focus {
            --close-button-background-color-hover: #{$fill-success-subtle-hover};
        }
    }

    &.type-error {
        border-color: $border-error-subtle-default;
        background-color: $fill-error-subtle-default;

        &::before {
            background-color: $border-error-solid-default;
        }

        .icon-wrapper ::v-deep svg  {
            color: $text-error-default;
        }
        
        .close:hover,
        .close:focus {
            --close-button-background-color-hover: #{$fill-error-subtle-hover};
        }
    }

    .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        min-width: 24px;
        height: 24px;
    }

    .close {
        width: 24px;
        min-width: 24px;
        height: 24px;

        @include a11y-clickable-area-resize(8px 8px 8px 0)
    }

    .label {
        flex-grow: 1;
        min-width: 0;
        padding: 2px 0;
        margin-right: 12px;
        margin-left: 4px;
        overflow: hidden;
        color: $light-solid-100;
    }

    .action {
        padding: 2px 0;
        height: 24px;
        color: $text-secondary-default;

        @include a11y-clickable-area-set(relative, 8px 0)
    }

    ::v-deep .link {
        color: $light-accent;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>