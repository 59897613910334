<template>
    <div
        :class="`type-${type}`"
        class="notification"
    >
        <header class="header">
            <div class="icon">
                <IconSvg16
                    :icon-name="icon"
                    stateless
                />
            </div>
            <div class="title">{{ titleInternal }}</div>
            <WsCloseButton
                class="close"
                round
                @click="close"
            />
        </header>
        <div class="content" v-html="sanitizeHtml(text)" />
        <button
            v-if="isReloadLink"
            type="button"
            class="link"
            @click="reload"
        >
            {{ $t('error.reload') }}
        </button>
        <footer v-if="actions.length" class="footer">
            <WsButton
                v-for="(action, index) in actions"
                :key="index"
                :variant="getButtonVariant(action)"
                class="action"
                size="small"
                new
                @click="onActionClick(action.callback, action.needToClose)"
            >
                {{ action.text }}
            </WsButton>
        </footer>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { INotificationAction } from '@/types/INotifier';
import { ButtonVariant } from '@/constants';
import { NotificationType } from '@/constants/Notifier/NotificationType';
import { sanitizeHtml } from '@/services/';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import WsButton from '@/components/common/WsButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

const notificationTypeDefault = NotificationType.INFO;

const notificationIcon = {
    [NotificationType.INFO]: 'info-new',
    [NotificationType.WARNING]: 'error',
    [NotificationType.SUCCESS]: 'checked',
    [NotificationType.ERROR]: 'error',
    [NotificationType.CONFIRMATION]: 'confirmation',
};

const notificationTitle = {
    [NotificationType.INFO]: 'Info',
    [NotificationType.WARNING]: 'Warning',
    [NotificationType.SUCCESS]: 'Success',
    [NotificationType.ERROR]: 'Error',
    [NotificationType.CONFIRMATION]: 'Confirmation',
};

@Component({
    components: {
        IconSvg16,
        WsButton,
        WsCloseButton,
    },
})
export default class WsNotification extends Vue {
    @Prop({ default: NotificationType.INFO }) type!: NotificationType;
    @Prop({ type: String, default: '' }) title!: string;
    @Prop({ type: String }) text!: string;
    @Prop({ type: Boolean, default: false }) isReloadLink!: boolean;
    @Prop() onCloseAction!: () => void;
    @Prop({ default: () => [] }) actions!: INotificationAction[];

    public readonly sanitizeHtml = sanitizeHtml;
    public readonly ButtonVariant = ButtonVariant;

    get icon() {
        return notificationIcon[this.type] || notificationIcon[notificationTypeDefault];
    }

    get titleInternal() {
        return this.title || notificationTitle[this.type] || notificationTitle[notificationTypeDefault];
    }

    public getButtonVariant(action: INotificationAction) {
        return action?.variant || ButtonVariant.TERTIARY;
    }

    @Emit()
    public close() {
        if (this.onCloseAction) {
            this.onCloseAction();
        }
    }

    public onActionClick(callback: () => void, needToClose = true) {
        callback();

        if (needToClose) {
            this.close();
        }
    }

    public reload() {
        window.location.reload();
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors/semantic.scss';
@import '@/styles/helpers/a11y.scss';
@import '@/styles/variables.scss';

.notification {
    position: relative;
    border-radius: 0 8px 8px 0;
    font-size: 14px;
    line-height: 20px;
    color: $text-primary-default;
    background-color: $blank;
    box-shadow: $notification-shadow;
    padding: 0 0 4px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 3px;
        background-color: $black;
    }

    &.type-info {
        &::before {
            background-color: $border-accent-solid-default;
        }

        .icon ::v-deep svg {
            color: $text-accent-default;
        }
    }

    &.type-warning {
        &::before {
            background-color: $border-alert-solid-default;
        }

        .icon ::v-deep svg {
            color: $text-alert-default;
        }
    }

    &.type-success {
        &::before {
            background-color: $border-success-default;
        }

        .icon ::v-deep svg {
            color: $text-success-default;
        }
    }

    &.type-error {
        &::before {
            background-color: $border-error-solid-default;
        }

        .icon ::v-deep svg {
            color: $text-error-default;
        }
    }

    &.type-confirmation {
        &::before {
            background-color: $border-magic-default;
        }

        .icon ::v-deep svg {
            color: $text-magic-default;
        }
    }

    .header {
        display: flex;
        align-items: center;
        padding: 7px 12px 7px 20px;
        border-bottom: 1px solid $border-primary-default;
        text-wrap: nowrap;
    }

    .title {
        flex-grow: 1;
        min-width: 0;
        margin: 0 16px 0 8px;
        padding: 6px 0 4px;
        overflow: hidden;
        color: $light-solid-100;
    }

    .icon {
        min-width: 16px;
        display: flex;
        align-items: center;
    }

    .close {
        @include a11y-clickable-area-resize(8px)
    }

    .content {
        padding: 12px 16px 12px 21px;
        color: $light-solid-80;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px 16px 12px 19px;
    }

    .action {
        width: auto;

        @include a11y-clickable-area-set(relative, 8px 0)
    }

    .action + .action {
        margin-left: 16px;
    }
}
</style>
