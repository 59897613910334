<template>
    <!-- eslint-disable-next-line -->
    <v-tooltip
        :activator="activatorSelector"
        :open-delay="250"
        :z-index="10000"
        :disabled="localDisabled"
        :content-class="tooltipClassAmp"
        :max-width="maxWidth"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
    >
        <template v-if="$slots.default" #activator="{ on }">
            <span :class="activatorClasses" v-on="on">
                <slot />
            </span>
        </template>
        <template v-else-if="$slots.activator || $scopedSlots.activator" #activator="{ on }">
            <slot name="activator" :on="on" />
        </template>
        <template
            v-else-if="!($slots.activator || $scopedSlots.activator || $slots.default) && activator"
            #activator="{ on }"
        >
            <span
                v-if="sanitizeActivator"
                v-on="on"
                v-html="sanitizeHtml(activator)"
                :class="activatorClasses"
            />
            <span v-else :class="activatorClasses">{{ activator }}</span>
        </template>

        <template v-if="$slots.tooltip || $scopedSlots.tooltip">
            <slot name="tooltip" />
        </template>
        <template v-else-if="!($slots.tooltip || $scopedSlots.tooltip)">
            <span v-if="sanitizeTooltip" v-html="sanitizeHtml(tooltip)" :class="tooltipClassAmp" />
            <span v-else :class="tooltipClassAmp">{{ tooltip }}</span>
        </template>
    </v-tooltip>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TooltipPositions, TooltipPositionsType } from '@/constants';
import { sanitizeHtml } from '@/services/Sanitizer';

@Component({
    methods: {
        sanitizeHtml,
    },
})
export default class WsTooltip extends Vue {
    @Prop() public tooltip!: TranslateResult; // ignored if content in slot #tooltip
    @Prop() public activator!: TranslateResult; // ignored if content in slot #activator
    @Prop() public activatorSelector!: string;
    @Prop({ type: String, default: '' }) public tooltipClass!: string;
    @Prop() public activatorClass!: string | string[] | { [key: string]: boolean }; // ignored if content in slot #activator
    @Prop({ default: 'top' }) public placement!: TooltipPositionsType; // change only of it REALLY important
    @Prop({ type: Number }) public maxWidth!: number;
    @Prop({ type: Boolean }) public disabled!: boolean;
    @Prop({ type: Boolean }) public block!: boolean; // activator as block
    @Prop({ type: Boolean }) public inlineFlex!: boolean; // activator as inline-flex
    @Prop({ type: Boolean, default: true }) public sanitizeTooltip!: boolean; // ignored if content in slot #tooltip
    @Prop({ type: Boolean, default: true }) public sanitizeActivator!: boolean; // ignored if content in slot #activator
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;

    get top() {
        return this.placement === TooltipPositions.top;
    }

    get bottom() {
        return this.placement === TooltipPositions.bottom;
    }

    get right() {
        return this.placement === TooltipPositions.right;
    }

    get left() {
        return this.placement === TooltipPositions.left;
    }

    get tooltipClassAmp() {
        const ampMask = this.ampMask ? ' amp-mask' : '';
        return this.tooltipClass + ampMask;
    }

    get activatorClasses(): { [key: string]: boolean } {
        let activatorClassObj = {};
        if (typeof this.activatorClass === 'string') {
            activatorClassObj = { [this.activatorClass]: Boolean(this.activatorClass) };
        } else if (_.isArray(this.activatorClass)) {
            // @ts-ignore
            activatorClassObj = this.activatorClass.reduce((acc, next) => {
                acc[next] = true;
                return acc;
            }, {} as { [key: string]: boolean });
        } else if (_.isObject(this.activatorClass)) {
            activatorClassObj = this.activatorClass;
        }
        return {
            'activator-block': this.block,
            'activator-inline-flex': this.inlineFlex,
            'amp-mask': this.ampMask,
            ...activatorClassObj,
        };
    }

    get isHereTooltip() {
        return this.$slots.tooltip || this.$scopedSlots.tooltip || this.tooltip;
    }

    get localDisabled() {
        return this.disabled || !this.isHereTooltip;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.activator-block {
    display: block;
}

.activator-inline-flex {
    display: inline-flex;
}
</style>
