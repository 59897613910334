import { FilterSetEnum } from '@/services';
import { IssueFilterExpr } from '@/domain/issueFilter/constants/IssueFilterExpr';
import { IssueStatusEnum } from '@/domain/issue/constants/IssueStatus';
import { IssuePriorityEnum } from '@/domain/issue/constants/IssuePriority';

export class FromProtobuf {
    public static numberToStatus(statusNumberString: string) {
        return [
            IssueStatusEnum.open,
            IssueStatusEnum.in_progress,
            IssueStatusEnum.solved,
            IssueStatusEnum.closed,
            IssueStatusEnum.deleted,
        ][Number(statusNumberString)];
    }

    public static numberToPriority(priorityNumberString: string) {
        return [
            IssuePriorityEnum.none,
            IssuePriorityEnum.trivial,
            IssuePriorityEnum.minor,
            IssuePriorityEnum.major,
            IssuePriorityEnum.critical,
            IssuePriorityEnum.blocker,
        ][Number(priorityNumberString)];
    }

    public static convertExclusiveExpression(expression: number) {
        return [FilterSetEnum.All, FilterSetEnum.Any].includes(expression) ? IssueFilterExpr.IN : IssueFilterExpr.NOT_IN;
    }

    public static convertNonExclusiveExpression(expression: number) {
        return {
            [FilterSetEnum.All]: IssueFilterExpr.HAS_ALL_IN,
            [FilterSetEnum.Any]: IssueFilterExpr.HAS_ONE_IN,
            [FilterSetEnum.NoneOf]: IssueFilterExpr.HAS_NOT_ALL_IN,
        }[expression];
    }
}
