export const RESPONSE = {
    SUCCESS: 0, // Response successful
    INVALID_PARAMS: -1,
    INVALID_DATA: -2, // Parameters valid, but data is not
    AMAZON_ERROR: -3,
    DATABASE_ERROR: -4,
    EMS_ERROR: -5,
    PAYPAL_ERROR: -6,
    PROJECT_LOCKED: -7,
    PROJECT_BROKEN: -8,
    OBJECT_NOT_EXISTS: -10,
    USER_NOT_EXISTS: -11,
    PROJECT_NOT_EXISTS: -12,
    MARKER_NOT_EXISTS: -13,
    SCRN_NOT_EXISTS: -14,
    REVISION_NOT_EXISTS: -15,
    SESSION_NOT_EXISTS: -16,
    RELEASE_NOT_EXISTS: -17,
    SESSION_CLOSED: -18,
    USER_AUTH_BANNED: -19,
    SECURITY_ERROR: -20,
    INVALID_TOKEN: -21,
    EXPIRED_TOKEN: -22,
    TWO_FA_REQUIRED: -25,
    SECURITY_ERROR_BY_AUTH_METHOD: -27,
    MULTIPLE_CREDENTIALS_MISMATCH: -28,
    BANNED_PERMANENTLY: -29,
    SIGNATURE_REQUIRED: -30,
    WRONG_SIGNATURE: -31,
    WRONG_VERSION: -40,
    AUTH_METHOD_NOT_ALLOWED: -49,
    LICENCE_OVERFLOW: -50,
    LICENCE_EXPIRED: -51,
    NO_AVAILABLE_LICENSE: -52,
    NO_LICENSES_FOUND: -204,
    LICENCE_OVERFLOW_BY_USER: -53,
    LICENCE_OVERFLOW_BY_PROJECT: -54,
    LICENSE_EXPIRED: -55,
    LICENSE_BLOCKED: -56,
    OWNER_CHANGE_FORBIDDEN: -57,
    SUPER_ADMIN_CHANGE_FORBIDDEN: -58,
    AUTH_METHOD_CHANGE_FORBIDDEN: -59,
    EMPTY_DIFF_COMMENT: -60, // Be careful, empty diff code not mean what diff is really empty, you should check errors fields in response
    WRONG_CODE: -70,
    EXPIRED_CODE: -71,
    ALREADY_INVITED: -72,
    WEAK_PASSWORD: -73,
    REPEATING_PASSWORD: -74,
    TOO_EARLY: -75,
    EXCHANGED_LINK: -76,
    CONTACT_SUPPORT: -241,
    NOT_FOUND: 1,
    REDIRECT: 10,
    DEPRECATED: 20,
    MULTIPLE: 30,
    SERVER_ERROR: 500,
    LICENSE_ACCESS_ROLE_SAME_NAME_EXIST: -1101,
    PROJECT_ACCESS_ROLE_COMMON_ERROR: -1900,
    PROJECT_ACCESS_ROLE_CHANGE_ROLE_FOR_YOURSELF: -1901,
    PROJECT_ACCESS_ROLE_CHANGE_ROLE_FOR_OWNER: -1902,
    PROJECT_ACCESS_ROLE_CHANGE_ROLE_FOR_LICENSE_ADMIN: -1903,
    DUPLICATE_STAMP: -1201,
    DISABLED_CUSTOM_WORKFLOW_OPTION: -2011,
};

type ResponseKeys = keyof typeof RESPONSE;
export type RESPONSE_Type = typeof RESPONSE[ResponseKeys];
