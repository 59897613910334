<template>
    <div class="form-box">
        <v-form v-if="step === Step.email || step === Step.old" ref="emailForm">
            <WsSelect
                v-if="step === Step.old"
                v-model="service"
                :items="serviceList"
                :label="$t('Login.ssoService')"
                translation-key="Login.services"
                clearable
                select-data-test="sso-auth-service-select"
                items-list-data-test="sso-auth-services-list"
                selected-item-data-test="sso-auth-service-select-text"
                label-data-test="sso-auth-service-select-label"
                clear-button-data-test="sso-auth-service-clear-button"
            />
            <WsInput
                v-if="step === Step.old && isSAML"
                v-model="spEntityId"
                :label="$t('LicenseAuthMethodSettings.entityId')"
                input-data-test="sso-auth-entity-id-input"
                label-data-test="sso-auth-entity-id-label"
            />
            <WsInput
                v-if="step === Step.email"
                v-model="email"
                :label="$t('Form.email')"
                :rules="rules.email"
                validate-on-blur
                autofocus
                @keydown.native.enter.prevent.stop
                @keyup.enter.native="onNextClick"
            />
            <WsSelect
                v-if="!appSSO.region"
                v-model="regionId"
                :items="regionOptions"
                :rules="rules.regionId"
                :label="$t('Login.ssoRegion')"
                select-data-test="sso-region-select"
                items-list-data-test="sso-regions-list"
                selected-item-data-test="sso-region-select-text"
                label-data-test="sso-region-select-label"
                @change="changeRegion"
            />
            <WsButton
                :disabled="isNextButtonDisabled"
                :outlined="false"
                :plain="false"
                type="primary"
                data-test="sso-next-button"
                round
                @click="onNextClick"
            >
                {{ $t('Button.next') }}
            </WsButton>
        </v-form>

        <div v-else-if="step === Step.code">
            <p class="enter-code">{{ $t('TwoFA.securityCodeEmail') }}</p>
            <div class="code-form">
                <OTPInput
                    :key="otpTriggerKey"
                    @input="code = $event"
                    @ready="confirmCode"
                />
            </div>

            <div v-if="isInvalidCode" class="error">{{ $t('Login.invalidSSOCode') }}</div>

            <div :class="{ disabled: isDisabledResend }" class="resend-code">
                <button
                    :disabled="isDisabledResend"
                    @click.prevent="sendCode"
                >
                    {{ $t('TwoFA.resendCode') }}
                </button>
                <div v-show="isDisabledResend">{{ $t('TwoFA.resendTimer', { secondsLeft }) }}</div>
                <div class="email amp-mask">{{ email }}</div>
            </div>

            <div class="forgot-link no-email" @click="step = Step.old">
                {{ $t('Login.didntGetEmail') }}
            </div>
        </div>

        <div v-else-if="step === Step.licenses" class="amp-mask">
            <template v-if="loginLicenses.length">
                <div class="select-license">{{ $t('Login.selectLicense') }}</div>
                <div
                    v-for="license in loginLicenses"
                    :key="license.id"
                    :class="{ disabled: isAccessCode && !license.allowApiAccess }"
                    class="login-license"
                    @click="loginToLicense(license)"
                >
                    <div>
                        <WsTruncate :value="license.name" />
                        <div
                            v-if="isAccessCode && license.allowApiAccess"
                            class="login-license_api"
                        >
                            {{ $t('License.apiEnabled') }}
                        </div>
                    </div>
                    <span
                        v-if="!isAccessCode || license.allowApiAccess"
                        class="mdi mdi-chevron-right larger-icon"
                    />
                </div>
            </template>
            <div v-else-if="!loginLicenses.length" class="no-licenses">
                {{ $t('Login.noLicensesInRegion') }}
            </div>
        </div>

        <a
            v-if="step !== Step.email"
            class="navigate-back"
            @click.prevent="stepBack"
        >
            &#8592; {{ $t('Button.back') }}
        </a>

        <div class="flex-delimiter" />

        <div v-if="authError" class="error test-error">{{ authError }}</div>

        <div v-else-if="!authError && step === Step.email" class="row-buttons">
            <span class="not-member">{{ $t('support.notMemberYet') }}</span>
            <div class="flex-delimiter" />
            <a
                class="action-button"
                href="https://revizto.com/demo-request/"
                target="_blank"
                data-test="sso-request-trial-button"
            >
                {{ $t('support.requestTrial') }}
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Ref, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { TranslateResult } from 'vue-i18n';
import { TRulesObj } from '@/types/TRulesObj';
import { API, AuthMethodType, CookieName, LocalStorageSSOKey, RESPONSE, RouterNames, SAMLCookie } from '@/constants';
import { LoginLicense } from '@/models/SSO/LoginLicense';
import { Region, UnreachableError } from '@/models';
import { CookieHelper, FormValidator, startTimer } from '@/services';
import { normalizeRegionId } from '@/services/RegionService';
import { autofocusMixin } from '@/mixins';
import WsButton from '@/components/common/WsButton.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsSelect from '@/components/common/WsSelect.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import OTPInput from '@/components/common/OTPInput.vue';

enum Service {
    google = 'google',
    saml = 'saml',
}

enum Step {
    email,
    code,
    licenses,
    old,
}

enum Landing {
    appAuthorized = 'app-authorized',
    oauth = 'oauth',
}

@Component({
    components: {
        OTPInput,
        WsButton,
        WsInput,
        WsSelect,
        WsTruncate,
    },
})
export default class LoginSSOForm extends autofocusMixin {
    @Ref() public readonly emailForm!: HTMLFormElement;

    public readonly serviceList = [Service.google, Service.saml];
    public readonly Step = Step;

    public step = Step.email;
    public code = '';
    public service = '';
    public localError: TranslateResult = '';
    public regionId = this.$store.getters.regionId;
    public appSSO = this.$store.getters.appSSO;
    public email = '';
    public isDisabledResend = false;
    public secondsLeft = 0;
    public loginLicenses: LoginLicense[] = [];
    public spEntityId = '';
    public isInvalidCode = false;
    public otpTriggerKey = 1;

    get rules(): TRulesObj {
        return {
            email: [
                FormValidator.email,
            ],
            regionId: [
                (value: string) => (this.pureRegionsIds.includes(value) || this.$t('Simple_word.required')),
            ],
        };
    }

    get language() {
        return this.$store.getters.currentLanguage;
    }

    get pureRegionsIds() {
        return this.$store.getters.pureRegionsIds;
    }

    get isAppAuth(): boolean {
        return !this.appSSO.isEmpty;
    }

    get isSAML() {
        return this.service === Service.saml;
    }

    get isGoogle() {
        return this.service === Service.google;
    }

    get oauth() {
        return this.$store.getters.oauth;
    }

    get regionOptions() {
        return Region.makeOptions(this.$store.getters.pureRegions);
    }

    get authError() {
        return this.$store.getters.authError || this.localError;
    }

    get isNextButtonDisabled() {
        if (this.step !== Step.old) {
            return false;
        }
        return !this.service || (this.isSAML && !this.spEntityId);
    }

    get isAccessCode() {
        return this.$route.query.request === API.accessCode;
    }

    get presetRegionId() {
        return this.$route.params.region || this.$route.query.region;
    }

    get isPowerBILoginRequest() {
        return Boolean(this.$route.query.state && this.$route.query.client_id);
    }

    @Emit()
    public reviztoInternal() {
        return this.email;
    }

    @Emit()
    public showTabs(value: boolean) {
        return value;
    }

    @Watch('step', { immediate: true })
    public onStepChange(step: Step) {
        const isShowTabs = [Step.email, Step.old].includes(step);
        this.showTabs(isShowTabs);
    }

    @Watch('code')
    public onChangeCode(code: string) {
        if (code) {
            this.isInvalidCode = false;
        }
    }

    public created() {
        this.regionId = normalizeRegionId(this.presetRegionId || this.regionId);
        this.changeRegion();

        if (this.$route.query.oldSSO) {
            this.step = Step.old;
        }
    }

    public sendCode() {
        let secCodeDelay: number | undefined;
        this.$store.dispatch('getSendSSOCode', { email: this.email, language: this.language })
            .catch((error) => secCodeDelay = error.result === RESPONSE.TOO_EARLY ? Number(error.message) : undefined)
            .finally(() => this.startTimerResendCode(secCodeDelay));
    }

    public startTimerResendCode(secCodeDelay = 60) {
        startTimer(
            secCodeDelay,
            () => this.isDisabledResend = true,
            () => this.isDisabledResend = false,
            (secondsLeft) => this.secondsLeft = secondsLeft,
        );
    }

    public async onNextClick() {
        if (!this.emailForm.validate()) {
            return;
        }
        if (this.isAccessCode) {
            CookieHelper.set('isAccessCode', 'true');
        }

        if (this.step === Step.old) {
            this.changeRegion();
            if (this.isSAML) {
                this.onSAMLClick(this.spEntityId);
            } else if (this.isGoogle) {
                this.onOAuthClick();
            }
            return;
        }

        try {
            await this.trySSOKey();
            return;
        } catch { // there is no ssoKey or try sso licenses produced an error
            this.isInvalidCode = false;
            this.step = Step.code;
            this.sendCode();
        }
    }

    public async trySSOKey() {
        const loginLicenses = await this.$store.dispatch('getTrySSOLicenses', {
            email: this.email,
            language: this.language,
        });
        this.handleLoginLicenses(loginLicenses);
        return;
    }

    public handleLoginLicenses(loginLicenses: LoginLicense[]) {
        this.loginLicenses = this.isAppAuth
            ? _.reject(loginLicenses, { authMethodType: AuthMethodType.revizto_internal })
            : loginLicenses;

        if (this.loginLicenses.length === 1) {
            this.loginToLicense(this.loginLicenses[0]);
        } else {
            this.step = Step.licenses;
        }
    }

    public confirmCode() {
        this.$store.dispatch('getSSOLicenses', {
            email: this.email,
            code: this.code,
        })
        .then(this.handleLoginLicenses)
        .catch(() => {
            this.otpTriggerKey++;
            this.clearCode();
            this.isInvalidCode = true;
        });
    }

    public stepBack() {
        this.clearCode();
        this.clearEmail();
        this.step = Step.email;
    }

    public clearCode() {
        this.code = '';
    }

    public clearEmail() {
        this.email = '';
    }

    public loginToLicense(license: LoginLicense) {
        if (this.isAccessCode && !license.allowApiAccess) {
            return;
        }
        const resolved: Route = this.$router.resolve({
            name: RouterNames.MyLicenseProjects,
            params: { licenseId: String(license.id) },
        }).resolved;
        const json = JSON.stringify({ licenseId: license.id, language: this.language });
        CookieHelper.set(CookieName.switchLicenseAuth, json);
        this.$store.commit('setStartPathAfterLogin', resolved.path);
        switch (license.authMethodType) {
            case AuthMethodType.revizto_internal:
                this.reviztoInternal();
                break;
            case AuthMethodType.SAML:
                this.service = Service.saml;
                this.onSAMLClick(license.spEntityId);
                break;
            case AuthMethodType.google:
                this.service = Service.google;
                this.onOAuthClick();
                break;
            default: {
                const _never: never = license.authMethodType;
                throw new UnreachableError(license.authMethodType);
            }
        }
    }

    public async onSAMLClick(spEntityId: string) {
        const deviceId = this.$store.getters.deviceId;
        localStorage.setItem(LocalStorageSSOKey.spEntityId, spEntityId);

        const data = {
            spEntityId,
            device_id: deviceId,
        };

        const response = await this.$store.dispatch('postSAMLInitData', data);
        if (response.result !== RESPONSE.SUCCESS) {
            switch (response.result) {
                case RESPONSE.BANNED_PERMANENTLY:
                    this.localError = this.$t('errors.bannedPermanently');
                    break;
                default:
                    this.localError = this.$t('Collocation.internalError');
            }
            return;
        }
        const { url, authNRequestId, transitionalAuth } = response.data;
        CookieHelper.set(SAMLCookie.authNRequestId, authNRequestId);
        CookieHelper.set(SAMLCookie.transitionalAuth, transitionalAuth);
        location.href = url;
    }

    public async onOAuthClick() {
        const query = this.$route.query;
        const landing = this.isAppAuth ? Landing.appAuthorized : Landing.oauth;

        if (this.isPowerBILoginRequest) {
            CookieHelper.set(CookieName.loginAccessCodeData, JSON.stringify(query));
        }

        await this.$store.dispatch('loadOAuthServicesUrls', { service: this.service, landing });
        location.href = this.oauth[this.service].url;
    }

    public changeRegion() {
        this.$store.commit('setRegionId', this.regionId);
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/auth.scss';

.forgot-password {
    margin-top: 45px;
    color: $primary-blue;
    cursor: pointer;
    user-select: none;
}

.enter-code {
    margin-block: 20px;
    color: $default-black;
}

.select-license {
    margin: 15px 0 15px;
    color: $default-black;
    font-weight: bold;
}

.login-license {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-block: 10px;
    min-height: 62px;
    border-bottom: $border;
    color: $default-black;
    cursor: pointer;

    &:not(.disabled):hover {
        background-color: $extra-light;
    }

    ::v-deep button {
        flex: 0;
    }

    &_api {
        font-size: 12px;
        color: $primary-green;
    }

    &.disabled {
        cursor: not-allowed;
    }
}

.resend-code {
    margin-top: 15px;
    min-height: 110px;
    text-align: center;

    &.disabled {
        opacity: 0.6;

        * {
            cursor: default;
        }
    }

    > .email {
        margin-top: 4px;
    }

    button {
        display: inline-block;
        padding: 5px 5px 5px 30px;
        color: $primary-blue;
        border: none;
        outline: none;
        font-size: 14px;
        background: url(/images/refresh_icon.png) 5px center no-repeat;
        cursor: pointer;
    }

    > div {
        line-height: $line-height-large;
    }
}

.no-email {
    margin-top: 20px;
}

.navigate-back {
    margin-top: 20px;
    color: $medium-gray;
}

.no-licenses {
    margin-top: 20px;
    color: $default-black;
}

.code-form, .error {
    text-align: center;
    margin-bottom: 5px;
}

.larger-icon {
    font-size: 1.5em;
    font-weight: normal;
}
</style>
