<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :label="label"
        :multi-text="showFieldMultipleText"
    >
        <span
            v-for="(item, index) in currentValue"
            :key="index"
            class="filter-link amp-mask"
            @click="click(item)"
        >
            {{ item }}{{ getCommaForListItem(currentValue, index) }}
        </span>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsClashSimple extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;

    @Emit()
    public click(value: string) {
        return { value, type: this.valueKey };
    }

    public readonly getCommaForListItem = getCommaForListItem;
}
</script>
