<template>
    <v-btn
        class="clickable"
        fab
        @click="handleClick"
    >
        <slot name="icon">
            <MoreIcon @click="handleClick" />
        </slot>
    </v-btn>
</template>

<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';
import MoreIcon from '@/components/common/icon/MoreIcon.vue';

@Component({
    components: {
        MoreIcon,
    },
})
export default class WsIcon extends BaseIcon {
    @Prop({ type: Boolean, default: false }) public more!: boolean;

    @Emit()
    public click() {
        return;
    }

    public handleClick() {
        if (!this.disable) {
            this.click();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.clickable {
    border-radius: 100%;
    width: $default-icon-height;
    height: $default-icon-height;
    padding: 0;
    min-width: $default-icon-height;
    background-color: transparent;
    box-shadow: none;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background: transparent;

    &:hover {
        border: 1px solid $primary-blue;

        &:before {
            opacity: 0;
        }
    }
}
</style>
