<template>
    <div
        class="ws-edit"
    >
        <div class="ws-edit-header">
            <WsButton
                icon="arrow-left-big"
                new
                low-priority
                @click="action"
            >
                {{ $t('Project.workflow.common.buttonBack') }}
            </WsButton>
            <span class="header-title">{{ title }}</span>
        </div>
        <div class="ws-edit-body">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class WsEdit extends Vue {
    @Prop({ default: '' }) public title!: string;

    @Emit()
    public action() {
        return;
    }

}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/wsui/index.scss';

.ws-edit {
    width: 1024px;
    height: calc(100vh - 193px);
    margin: 24px auto;
    border: solid 1px $color-solid-10;
    display: grid;
    grid-template-rows: 56px auto;
    //overflow-y: scroll;
    //@include scrollbar;
}

.ws-edit-header {
    padding: 12px 24px;
    height: 56px;
    line-height: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    border-bottom: solid 1px $color-solid-10;

    .header-title {
        font-size: 24px;
        color: $color-solid-100;
        padding: 0 0 0 12px;
        line-height: 32px;
    }
}

.ws-edit-body {
    padding: 0;
    overflow: hidden;
}
</style>
