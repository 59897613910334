<template>
    <div
        :class="{
            danger,
            successful: success,
            warn: warning,
            small,
        }"
        class="ws-alert"
    >
        <div class="ws-alert-content">
            <div v-if="title" v-html="sanitizeHtml(title)" class="ws-alert-title" />

            <template v-if="text || slotPassed">
                <div v-if="text" class="ws-alert-description" v-html="sanitizeHtml(text)" />
                <div v-if="!text && slotPassed" class="ws-alert-description">
                    <slot />
                </div>
            </template>
        </div>

        <div class="ws-icon-wrapper">
            <IconSvg16
                :color="iconColor"
                icon-name="alert"
                stateless
                passive
            />
        </div>
    </div>
</template>

<script lang="ts">
import VueI18n from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import { sanitizeHtml } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsAlert extends Vue {
    @Prop({ required: false, type: Boolean }) public danger!: boolean;
    @Prop({ required: false, type: Boolean }) public success!: boolean;
    @Prop({ required: false, type: Boolean }) public warning!: boolean;
    @Prop({ required: false, type: Boolean }) public small!: boolean;
    @Prop({ required: false, type: String }) public title!: string;
    @Prop({ required: false, type: String }) public text!: string | VueI18n.TranslateResult;

    public readonly Color = Color;
    public readonly sanitizeHtml = sanitizeHtml;

    get slotPassed() {
        return this.$slots.default ? Boolean(this.$slots.default![0].text?.trim().length) : false;
    }

    get iconColor() {
        if (this.danger) {
            return Color.primaryRed;
        }

        if (this.warning) {
            return Color.primaryYellow;
        }

        if (this.success) {
            return Color.successLight;
        }

        return Color.accent100;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/wsui/index.scss';

</style>
