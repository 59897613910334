<template>
    <div
        class="ws-toggle"
        :class="{ active: value, disabled: disabled }"
        @click="toggle"
    >
        <div class="control" />
        <div class="label">{{ label }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class WsToggle extends Vue {
    @Model('modelValue',  { type: Boolean }) public value!: boolean;
    @Prop() public label!: any;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;

    public toggle() {
        if (!this.disabled) {
            this.value = !this.value;
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@/styles/wsui/index.scss';
.ws-toggle {
    display: grid;
    grid-template-columns: 48px auto;
    grid-gap: 16px;
    height: 24px;

    .control {
        width: 48px;
        border: solid 1px $color-solid-30;
        border-radius: 24px;
        height: 24px;
        position: relative;
        cursor: pointer;
        //transition: all 200ms ease-in-out;

        &:after {
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            border-radius: 24px;
            border: solid 1px $color-solid-30;
            position: absolute;
            top: 0px;
            left: -1px;
            //transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
    }

    &.active {
        .control {
            border-color: $color-accent-100;
            background: $color-accent-100;

            &:after {
                background: white;
                right: 0px;
                left: auto;
            }
        }
    }

    &.disabled {
        .control {
            border-color: $color-solid-30;
            background: $color-solid-30;
            cursor: default;

            &:after {
                background: white;
            }
        }
    }
}
</style>
