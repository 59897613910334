import { TranslateResult } from 'vue-i18n';
import _ from 'lodash';
import { INotificationAction } from '@/types/INotifier';
import { NotificationType } from '@/constants/Notifier/NotificationType';
import { ToastType } from '@/constants/Notifier/ToastType';
import { notify, toast } from '@/services/notifier';
import { getTranslationEndingByCount, i18n } from '@/services';

interface INotificationsPayload {
    text: TranslateResult,
    name?: string;
    title?: TranslateResult,
    duration?: number;
    actions?: INotificationAction[];
    onCloseAction?: () => void;
}

interface IToastPayload {
    duration?: number;
    name?: string;
    actions?: INotificationAction[];
    isReloadLink?: boolean;
    onCloseAction?: () => void;
}

// Here we pass full translated text instead of just key
export function toastSuccess(message: TranslateResult, payload: IToastPayload = {}) {
    return showToast(ToastType.SUCCESS, message, payload);
}

export function toastWarning(message: TranslateResult, payload: IToastPayload = {}) {
    return showToast(ToastType.WARNING, message, payload);
}

export function toastError(message: TranslateResult, payload: IToastPayload = {}) {
    return showToast(ToastType.ERROR, message, payload);
}

export function toastInfo(message: TranslateResult, payload: IToastPayload = {}) {
    return showToast(ToastType.INFO, message, payload);
}

// Notifications is mostly for legacy messages, therefore we pass translationKey for compatibility.
export function notificationSuccess(
    payload: string | INotificationsPayload,
    count: number = 1,
    params: object = {},
) {
    return showNotification(NotificationType.SUCCESS, getDataToShow(payload, count, params) as INotificationsPayload);
    
}

export function notificationWarning(
    payload: string | INotificationsPayload,
    count: number = 1,
    params: object = {},
    isReloadText: boolean = false,
) {
    return showNotification(NotificationType.WARNING, getDataToShow(payload, count, params) as INotificationsPayload, isReloadText);
}

export function notificationInfo(
    payload: string | INotificationsPayload,
    count: number = 1,
    params: object = {},
) {
    return showNotification(NotificationType.INFO, getDataToShow(payload, count, params) as INotificationsPayload);
}

export function notificationError(
    payload: string | INotificationsPayload,
    count: number = 1,
    params: object = {},
    isReloadText: boolean = false,
) {
    return showNotification(NotificationType.ERROR, getDataToShow(payload, count, params) as INotificationsPayload, isReloadText);
}

function getFullTranslation(translationKey: string, count: number = 1, params: object = {}) {
    return String(i18n.t(`userActionResultNotification.${translationKey}${getTranslationEndingByCount(count)}`, params));
}

function getDataToShow(payload: string | INotificationsPayload, count: number = 1, params: object = {}) {
    return _.isString(payload) ? { text: getFullTranslation(payload as string, count, params) } : payload;
}

function showNotification(
    type: NotificationType,
    payload: INotificationsPayload,
    isReloadLink: boolean = false,
) {
    if (!notify?.[type]) {
        return;
    }

    return notify[type](
        {
            title: payload?.title || i18n.t(`Notification.${type}`),
            text: payload.text,
            isReloadLink,
            ...(payload?.name && { name: payload?.name }),
            ...(payload?.duration && { duration: payload?.duration }),
            ...(payload?.actions && { actions: payload?.actions }),
            ...(payload?.onCloseAction && { onCloseAction: payload?.onCloseAction }),
        },
    );
}

function showToast(
    type: ToastType,
    message: TranslateResult,
    payload: IToastPayload,
) {
    if (!toast?.[type]) {
        return;
    }

    return toast[type](
        {
            text: message,
            ...(payload?.name && { name: payload?.name }),
            ...(payload?.isReloadLink && { actions: payload?.isReloadLink }),
            ...(payload?.duration && { duration: payload?.duration }),
            ...(payload?.actions && { actions: payload?.actions }),
            ...(payload?.onCloseAction && { onCloseAction: payload?.onCloseAction }),
        },
    );
}