export interface DiffCommentObject {
    type: string;
    uuid: string;
    reporter: any;
    created: string;
    diff: any;
}

export enum diffRows {
    oldValue = 'old',
    newValue = 'new',
    type = 'type',
    uuid = 'uuid',
    reporter = 'reporter',
    created = 'created',
    diff = 'diff',
}
