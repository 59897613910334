<template>
    <div class="skeleton">
        <component :is="getComponentByContent(content)" :options="options" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import SkeletonDefaultTable from '@/components/common/skeleton/SkeletonDefaultTable.vue';
import SkeletonFilterPanel from '@/components/common/skeleton/SkeletonFilterPanel.vue';
import SkeletonIssue from '@/components/common/skeleton/SkeletonIssue.vue';
import SkeletonMembersTable from '@/components/common/skeleton/SkeletonMembersTable.vue';
import SkeletonProjectsTable from '@/components/common/skeleton/SkeletonProjectsTable.vue';
import SkeletonTableHeader from '@/components/common/skeleton/SkeletonTableHeader.vue';
import SkeletonSimple from '@/components/common/skeleton/SkeletonSimple.vue';
import SkeletonTrackerPresets from '@/components/common/skeleton/SkeletonTrackerPresets.vue';
import SkeletonChatMessage from '@/components/common/skeleton/SkeletonChatMessage.vue';
import SkeletonIssueInfo from '@/components/common/skeleton/SkeletonIssueInfo.vue';
import SkeletonMarkupPreview from '@/components/common/skeleton/SkeletonMarkupPreview.vue';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

const componentByContent = {
    'default-table': 'SkeletonDefaultTable',
    'filter-panel': 'SkeletonFilterPanel',
    'issue': 'SkeletonIssue',
    'members-table': 'SkeletonMembersTable',
    'projects-table': 'SkeletonProjectsTable',
    'table-header': 'SkeletonTableHeader',
    'tracker-presets': 'SkeletonTrackerPresets',
    'chat-message': 'SkeletonChatMessage',
    'issue-info': 'SkeletonIssueInfo',
    'markup-preview': 'SkeletonMarkupPreview',
    'simple': 'SkeletonSimple',
} as { [key: string]: string };

@Component({
    components: {
        SkeletonDefaultTable,
        SkeletonFilterPanel,
        SkeletonIssue,
        SkeletonMembersTable,
        SkeletonProjectsTable,
        SkeletonTableHeader,
        SkeletonTrackerPresets,
        SkeletonChatMessage,
        SkeletonIssueInfo,
        SkeletonMarkupPreview,
        SkeletonSimple,
        SkeletonBone,
    },
})
export default class WsSkeletonLoader extends Vue {
    @Prop({ type: String }) public content!: 'issue' | 'projects-table' | 'members-table' | 'default-table' | 'table-header' | 'filter-panel' | 'tracker-presets';
    @Prop({ type: Object, default: () => ({}) }) public options!: { [key: string]: any };

    public getComponentByContent(contentKey: string): string {
        return componentByContent[contentKey] || 'SkeletonBone';
    }
}
</script>

<style lang="scss">
.skeleton {
    position: relative;
    overflow: hidden;
    cursor: wait;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
        );
        animation: shimmer 1.5s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
</style>
