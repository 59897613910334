<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9 16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15C8.55228 15 9 15.4477 9 16Z" fill="black" />
        <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" fill="black" />
        <path d="M17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16Z" fill="black" />
        <path d="M21 16C21 16.5523 20.5523 17 20 17C19.4477 17 19 16.5523 19 16C19 15.4477 19.4477 15 20 15C20.5523 15 21 15.4477 21 16Z" fill="black" />
        <path d="M25 16C25 16.5523 24.5523 17 24 17C23.4477 17 23 16.5523 23 16C23 15.4477 23.4477 15 24 15C24.5523 15 25 15.4477 25 16Z" fill="black" />
        <path d="M29 16C29 16.5523 28.5523 17 28 17C27.4477 17 27 16.5523 27 16C27 15.4477 27.4477 15 28 15C28.5523 15 29 15.4477 29 16Z" fill="black" />
        <path d="M33 16C33 16.5523 32.5523 17 32 17C31.4477 17 31 16.5523 31 16C31 15.4477 31.4477 15 32 15C32.5523 15 33 15.4477 33 16Z" fill="black" />
        <path d="M37 16C37 16.5523 36.5523 17 36 17C35.4477 17 35 16.5523 35 16C35 15.4477 35.4477 15 36 15C36.5523 15 37 15.4477 37 16Z" fill="black" />
        <path d="M41 16C41 16.5523 40.5523 17 40 17C39.4477 17 39 16.5523 39 16C39 15.4477 39.4477 15 40 15C40.5523 15 41 15.4477 41 16Z" fill="black" />
        <path d="M45 16C45 16.5523 44.5523 17 44 17C43.4477 17 43 16.5523 43 16C43 15.4477 43.4477 15 44 15C44.5523 15 45 15.4477 45 16Z" fill="black" />
        <path d="M49 16C49 16.5523 48.5523 17 48 17C47.4477 17 47 16.5523 47 16C47 15.4477 47.4477 15 48 15C48.5523 15 49 15.4477 49 16Z" fill="black" />
        <path d="M53 16C53 16.5523 52.5523 17 52 17C51.4477 17 51 16.5523 51 16C51 15.4477 51.4477 15 52 15C52.5523 15 53 15.4477 53 16Z" fill="black" />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType2 extends BaseIcon {}
</script>
