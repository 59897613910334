<template>
    <div
        v-if="isDropdownExist"
        class="header-select"
        ref="dropRef"
    >
        <v-menu
            v-model="open"
            :content-class="`header-select-menu ${dataTest}`"
            :close-on-content-click="false"
            offset-y
            offset-overflow
            @input="change"
        >
            <template #activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on">
                    <span
                        :class="{
                            'is-open': open,
                            'is-arrow': arrow,
                        }"
                        class="top-menu-item"
                    >
                        <slot name="activator" />
                        <template v-if="!isActivatorSlot">
                            <span class="menu-title">{{ menuTitle }}</span>
                            <span
                                class="active-item-name"
                                :class="{ 'amp-mask': ampHideActiveTitle }"
                                :style="{ maxWidth: sizeForLicense }"
                            >
                                {{ activeItemName }}
                            </span>
                        </template>
                        <template v-if="arrow">
                            <div
                                class="dropdown-arrow"
                                :class="{ 'up': open }"
                            >
                                <IconSvg16
                                    icon-name="arrow-down"
                                    stateless
                                />
                            </div>
                        </template>
                    </span>
                </button>
            </template>
            <v-list class="menu-list">
                <div class="header" @click="onHeaderClick">
                    <slot name="header" />
                </div>
                <div class="list" @click="onListClick">
                    <slot name="list" />
                </div>
            </v-list>
        </v-menu>
    </div>

    <div
        v-else
        class="top-menu-item"
        :class="{ hoverable: isDropdownExist }"
        ref="dropRef"
    >
        <span class="menu-title">{{ title }}</span>
        <span class="slot">
            <slot />
        </span>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { truncateString } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    methods: {
        truncateString,
    },
    components: {
        IconSvg16,
    },
})
export default class HeaderSelect extends Vue {
    @Prop({ default: true }) public arrow!: boolean;
    @Prop({ default: '' }) public dataTest!: string;
    @Prop() public title!: TranslateResult;
    @Prop({ default: '' }) public activatorText!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public ampHideActiveTitle!: boolean;
    @Prop({ type: Boolean, required: false, default: false }) public closeOnHeaderClick!: boolean;
    @Prop({ type: Boolean, required: false, default: false }) public closeOnListClick!: boolean;

    @Ref() public readonly dropRef!: HTMLElement;

    public open = false;

    get isDropdownExist() {
        return Boolean(this.$slots.list);
    }

    get isActivatorSlot() {
        return Boolean(this.$slots.activator);
    }

    get sizeForLicense() {
        return `${this.$store.getters.headerFreeSpace - 50}px`;
    }

    get menuTitle() {
        return this.title ? `${this.title}${this.$t('Punctuation.colon')}` : '';
    }

    get activeItemName() {
        return truncateString(this.activatorText as string, 30) || '';
    }

    @Emit()
    public size() {
        return this.dropRef.offsetWidth;
    }

    @Emit()
    public change(value: boolean) {
        this.open = value;
        return value;
    }

    public mounted() {
        this.size();
        // This is hack due to prop 'content-props' in v-menu is not working.
        const headerDropdown = document.querySelector(`.${this.dataTest}`);
        headerDropdown?.setAttribute('data-test', this.dataTest);
    }

    public onHeaderClick() {
        if (this.closeOnHeaderClick) {
            this.open = false;
        }
    }

    public onListClick() {
        if (this.closeOnListClick) {
            this.open = false;
        }
    }

    public updated() {
        this.size();
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/wsui/colors.scss';
@import '@/styles/mixins.scss';

$select-color: $primary-blue;

.header-select {
    display: inline-flex;
    position: relative;
    color: $dark-gray-variant;
    font-size: 14px;
}

.header-select-menu {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    .v-sheet.v-list:not(.v-sheet--outlined) {
        box-shadow: none;
    }
}

.header {
    margin: 4px 20px 6px;
}

.v-menu__content {
    padding: 2px 2px 2px 0;
    background: white;
}

.menu-list {
    max-height: 80vh;
    max-width: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1px;
    @include scrollbar;

    ::v-deep .v-list-item {
        font-size: $font-size;
        min-height: 32px;
        padding-inline: 20px;
        color: $menu;

        .submenu-title {
            font-size: 14px;
            font-weight: 700;
            color: $text-dark;
        }

        &.divided {
            padding-top: 6px;
            margin-top: 6px;
            border-top: 1px solid $disable-gray;
        }

        &:has(
            a.router-link-exact-active,
            a.router-link-active.router-not-exact,
            button.router-link-exact-active,
            button.router-link-active.router-not-exact
        ) {
            background-color: $color-accent-10;
        }

        a,
        button {
            display: flex;
            align-items: center;
            overflow-x: hidden;
            text-overflow: ellipsis;
            color: $text-dark;
            line-height: 1.5;
            min-height: 32px;
            padding-block: 4px;
        }

        &.theme--light {
            &:hover {
                background-color: $color-solid-5;
                color: $light-accent-secondary;
            }
        }

        &--disabled {
            color: $medium-gray;
        }

        > * {
            width: 100%;
        }

        &::before {
            margin: 0;
        }

        @media (min-width: $screen-md-min) {
            line-height: 32px;
        }
    }

}
.top-menu-item {
    display: flex;
    padding: 0 $container-padding;
    height: 32px;
    line-height: 32px;
    font-weight: 500;
    white-space: nowrap;
    color: $white;
    align-items: center;

    &.is-arrow {
        padding: 0 12px 0 $container-padding;
    }

    &:hover,
    &.is-open {
        color: $white !important;
        background-color: $select-color !important;

        .menu-title {
            color: $white !important;
        }
    }

    .menu-title {
        font-weight: normal;
        color: $white;
        margin-right: 5px;
    }
}

.top-menu-activator {
    display: flex;
    flex-direction: row;
    height: auto;
    line-height: normal;
    text-align: left;
}

.dropdown-arrow {
    display: flex;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    margin-left: 2px;

    &.up {
        transform: rotate(180deg);
    }

    ::v-deep .svg-icon svg {
        color: $white;
        width: 20px;
        height: 20px;
    }
}
</style>
