<template>
    <div style="display: contents;" class="amp-block">
        <picture class="project-preview amp-block">
            <template v-if="!isError">
                <source
                    v-if="webpOriginal"
                    :srcset="webpOriginal"
                    media="(min-resolution: 2dppx)"
                >
                <source v-if="webpSmall" :srcset="webpSmall">
            </template>
            <source
                v-if="jpegOriginal"
                :srcset="jpegOriginal"
                media="(min-resolution: 2dppx)"
            >
            <img
                v-if="jpegSmall"
                :src="jpegSmall"
                type="image/jpeg"
                alt=""
                fetchpriority="low"
                @error="isError = true"
            >
        </picture>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WsProjectPreview extends Vue {
    @Prop() public smallPreview!: string;
    @Prop() public previewImages!: any;

    public isError = false;

    get webpOriginal(): string {
        return this.previewImages?.original?.webp || '';
    }
    get webpSmall(): string {
        return this.previewImages?.small?.webp || '';
    }
    get jpegOriginal(): string {
        return this.previewImages?.original?.jpeg || this.smallPreview || '';
    }
    get jpegSmall(): string {
        return this.previewImages?.small?.jpeg || this.smallPreview || '';
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.project-preview {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $project-logo-width;
    min-height: $project-logo-height;
    opacity: 1;
    transition: all 0.2s ease;
    background: url(/images/default-project-preview.jpg) center center no-repeat;
    background-size: contain;

    &:hover {
        opacity: 0.8;
    }
}
</style>
