import UserApi from '@/api/user.api';

export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        checkInvite(context: any, params: { uid: string; token: string }) {
            return UserApi.postCheckInvite(params);
        },
        register(context: any, { params, token }: any) {
            return UserApi.postRegister(params, token);
        },
        renewLink(context: any, token: string) {
            return UserApi.postRenewLink(token);
        },
    },
};
