import { MemberRoleEnum, MemberStatusEnum } from '@/constants';
import { User } from '@/models';
import { compareAlphanumerically } from '@/services/Comparators';
import { i18n } from '@/services/i18n';

export interface ILicenseMemberGuestActivity {
    project: number;
    license: {
        id: number;
        uuid: string;
        name: string;
    };
    lastActive: string;
}

export class LicenseMember {
    public readonly id: number;
    public readonly uuid: string;
    public readonly email: string;
    public role: MemberRoleEnum;
    public firstname: string;
    public lastname: string;
    public fullname: string;
    public avatar: string;
    public search: string;
    public frozen: boolean;
    public activated: boolean;
    public deactivated: boolean;
    public deactivatedAt: string;
    public projects: string[];
    public guestProjectCount: number;
    public metaTags: string[];
    public authMethod: string;
    public license: any;
    public user: User;
    public active: boolean;
    public activeOutside: boolean;
    public invitedAt: string;
    public lastActive: string;
    public guestProjectsActivity: ILicenseMemberGuestActivity[];
    public company: string;
    public department: string;
    public location: string;
    public authMethodTitle: string;
    public authMethodType: string;
    public authMethodUuid: string;
    public authMethodPort: string;
    public authMethodIsActive: boolean;
    public hasValidAuth: boolean;
    public status: number;
    public suspendReason: number;

    constructor(licenseMember: any = {}) {
        licenseMember.user = licenseMember.user || {};

        this.id = licenseMember.id;
        this.uuid = licenseMember.uuid;
        this.email = licenseMember.user.email || '';
        this.role = licenseMember.role || MemberRoleEnum.none;
        this.firstname = licenseMember.user.firstname || '';
        this.lastname = licenseMember.user.lastname || '';
        this.fullname = `${this.firstname} ${this.lastname}`.trim();
        this.avatar = licenseMember.user.avatar;
        this.search = `${this.fullname} ${this.email}`;
        this.frozen = licenseMember.frozen || false;
        this.activated = licenseMember.user.activated;
        this.deactivated = licenseMember.deactivated;
        this.deactivatedAt = licenseMember.deactivated ? licenseMember.deactivatedAt : '';
        this.projects = licenseMember.projects || [];
        this.guestProjectCount = licenseMember.guestProjectCount || 0;
        this.metaTags = licenseMember.metaTags || [];
        this.authMethod = licenseMember.authMethod;
        this.license = licenseMember.license || {};
        this.user = new User(licenseMember.user);
        this.invitedAt = licenseMember.invitedAt || '';
        this.lastActive = licenseMember.lastActive || '';
        this.active = false; // присваивается извне, так как нужно выполнить дополнительный тяжелый запрос на получение данных по внешним проектам
        this.activeOutside = false; // присваивается извне, так как нужно выполнить дополнительный тяжелый запрос на получение данных по внешним проектам
        this.guestProjectsActivity = licenseMember.guestProjectsActivity || [];
        this.company = licenseMember.company;
        this.department = licenseMember.department;
        this.location = licenseMember.location;
        this.authMethodTitle = licenseMember.authMethod?.title || '';
        this.authMethodType = licenseMember.authMethod?.type;
        this.authMethodUuid = licenseMember.authMethod?.uuid;
        this.authMethodPort = licenseMember.authMethod?.port;
        this.authMethodIsActive = licenseMember.authMethod?.isActive;
        this.hasValidAuth = licenseMember.hasValidAuth;
        this.status = licenseMember.status;
        this.suspendReason = licenseMember.suspendReason;
    }

    public static instantiate(licenseMember: any = {}) {
        return new LicenseMember(licenseMember);
    }

    get searchValue() {
        return `${this.firstname} ${this.lastname} ${this.email}`.toLowerCase();
    }

    get pending() {
        return !this.activated;
    }

    get suspended() {
        return this.frozen;
    }

    get projectsCount() {
        return this.projects.length || 0;
    }

    get allLicensesProjectCount() { // used in LicenseMembersTable
        return (this.projectsCount + this.guestProjectCount) || 0;
    }

    get metaTagsString() {
        return [...this.metaTags]
            .sort(compareAlphanumerically)
            .join(String(i18n.t('Punctuation.comma')));
    }

    get isAdmin() {
        return this.role === MemberRoleEnum.admin;
    }

    get isAnyAdmin() {
        return [MemberRoleEnum.superAdmin, MemberRoleEnum.admin].includes(this.role);
    }

    get isSuperAdmin() {
        return this.role === MemberRoleEnum.superAdmin;
    }

    get isRegular() {
        return !this.isAdmin && !this.isSuperAdmin;
    }

    get fullNameWithEmail() {
        return this.activated ? `${this.fullname} (${this.email})` : this.email;
    }

    get isSuspended() { // Used in TableCell
        return this.status === MemberStatusEnum.frozen;
    }

    get isDeactivated() { // Used in TableCell
        return this.status === MemberStatusEnum.deactivated;
    }

    public getShortFullName(maxLength = 20) {
        if (this.fullname.length > maxLength) {
            const shortFirstName = this.firstname ? `${this.firstname[0]}.` : '';

            return `${shortFirstName} ${this.lastname}`;
        }

        return this.fullname;
    }

    private has(searchQuery: string) {
        return this.searchValue.includes(searchQuery.toLowerCase());
    }

    public static has(searchQuery: string) {
        return (member: LicenseMember) => member.has(searchQuery);
    }
}
