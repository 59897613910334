<template>
    <WsTooltip
        :tooltip="tooltipContent"
        :placement="placement"
        :disabled="!tooltipContent"
        :activator-class="activatorClasses"
        :max-width="tooltipMaxWidth"
        :block="block"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            :width="size + 8 * Number(!stateless)"
            :height="size + 8 * Number(!stateless)"
            :viewBox="!stateless ? '-4 -4 32 32' : '0 0 24 24'"
            :class="{ stateless, disabled, focused, passive, 'parent-hover': parentHover, filled }"
            :style="generatedStyle"
            class="svg"
            @click="handleClick"
        >
            <circle
                v-if="!stateless && !square"
                :class="['solo-border', { bordered }]"
                fill="none"
                cx="12"
                cy="12"
                r="15.4"
                stroke-width="1.2"
            />
            <rect
                v-if="!stateless && square"
                :class="['solo-border', { bordered }]"
                fill="none"
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="3.5"
            />
            <svg>
                <use :href="`${url}#icon`" />
            </svg>
        </svg>
    </WsTooltip>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import IconSvgBase from '@/components/common/icon/IconSvgBase.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
    },
})
export default class IconSvg24 extends IconSvgBase {}
</script>
