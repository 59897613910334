<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :editable="editable"
        :label="label"
        :multi-text="showFieldMultipleTextLocal"
        :multi-changed="isNewMultiValue"
        :collapsable="collapsable"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <template v-if="!inlineEdit">
            <div class="preline amp-mask" v-html="sanitizeHtml(currentValue)" />
        </template>

        <div
            v-if="inlineEdit"
            class="inline-edit"
        >
            <WsTextarea
                v-model="currentValue"
                :maxlength="DefaultStringMaxLength"
                class="input"
                hide-details
                autofocus
            />
        </div>

        <template #action>
            <IconSvg16
                v-if="!inlineEdit"
                icon-name="edit"
                stateless
                @click="startEdit"
            />

            <div v-if="inlineEdit">
                <WsButton
                    size="xsmall"
                    type="primary"
                    contained
                    @click="handleSave"
                >
                    {{ $t('Button.save') }}
                </WsButton>
                <WsButton
                    :outlined="false"
                    size="xsmall"
                    @click="stopEdit"
                >
                    {{ $t('Button.cancel') }}
                </WsButton>
            </div>
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { DefaultStringMaxLength } from '@/constants';
import { sanitizeHtml } from '@/services';
import { isFieldCollapsable } from '@/services/Common';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsInput from '@/components/common/WsInput.vue';
import { Issue } from '@/domain/issue/models/Issue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
        IconSvg16,
        WsButton,
        WsInput,
    },
    methods: {
        sanitizeHtml,
    },
})
export default class IssueDetailsTitle extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    public readonly DefaultStringMaxLength = DefaultStringMaxLength;
    public collapsable = false;

    public inlineEdit = false;
    public localValue = '';

    get currentValue() {
        if (this.multiEditNewValues) {
            return this.multiEditNewValues.value;
        }

        return this.showFieldMultipleText
            ? ''
            : this.currentIssue[this.valueKey as keyof Issue];
    }

    set currentValue(value: string) {
        this.localValue = value;
    }

    get showFieldMultipleTextLocal() {
        return this.inlineEdit ? false : this.showFieldMultipleText;
    }

    public created() {
        this.setCollapsable();
    }

    public updated() {
        this.setCollapsable();
    }

    public setCollapsable() {
        if (isFieldCollapsable(this.currentValue)) {
            this.collapsable = !this.inlineEdit;
        } else {
            this.collapsable = false;
        }
    }

    public handleSave() {
        if (this.localValue && this.localValue !== this.currentValue) {
            this.change(this.localValue);
        }
        this.stopEdit();
    }

    public startEdit() {
        this.collapsable = false;
        this.inlineEdit = true;
    }

    public stopEdit() {
        this.inlineEdit = false;
        this.setCollapsable();
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.inline-edit {
    padding-top: $container-padding;
    width: 100%;
}

.preline {
    white-space: pre-line
}
</style>
