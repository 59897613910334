import moment from 'moment';
import {
    DEADLINE_NOT_SET,
    MomentFormats,
} from '@/constants';
import {
    dateObjToMoment,
    getUtcMidday,
} from '@/services';
import { IIssueTrackerFilterValue } from '@/domain/issueFilter/types/ProjectIssuesFilters';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';

export const DateFilterRuleType = {
    NotSet: 0,
    BeforeNow: 1,
    MoreThanNDaysAgo: 2,
    WithinLastNDays: 3,
    InNDaysFromNow: 4,
    CustomEqual: 5,
    CustomLess: 6,
    CustomLessOrEqual: 7,
    CustomMore: 8,
    CustomMoreOrEqual: 9,
    CustomBetween: 10,
    CustomDuration: 11,
};

// Don't export by index.ts in services dir for avoid dependency of Store in all services imports
export class IssueTrackerFilterByDateBase implements IIssueTrackerFilterValue {
    public type: string;
    public dateFilterType: number;
    public date?: any;
    public date2?: any;
    public days?: number;
    public includeNow?: boolean;

    constructor(type: string, dateFilterType: number, date?: any, date2?: any, days?: number, includeNow?: boolean) {
        this.type = type;
        this.dateFilterType = dateFilterType;
        this.date = date;
        this.date2 = date2;
        this.days = days;
        this.includeNow = includeNow;
    }

    get isActive() {
        return this.dateFilterType >= 0;
    }
    get dateFilter() {
        return {};
    }
    public getApiParams() {
        return { type: this.type, ...this.dateFilter }; // dateFilter is overloaded in child classes
    }

    public filterIssues(issues: any[]): any[] {
        if (!this.isActive) {
            return issues;
        }

        return issues.filter((issue) => {
            const deadlineString = issue._deadline.value;
            const deadline = moment(deadlineString, MomentFormats.serverSide);
            const createdString = issue._created.value;
            const created = moment(createdString, MomentFormats.serverSide);
            const closedUnix = issue._status.timestamp;
            const closed = moment.unix(closedUnix);
            const isClosed = issue._status.value === 'closed';
            let isNotSet;
            let comparisonDate;

            switch (this.type) {
                case IssuesFilterType.deadline:
                    comparisonDate = deadline;
                    isNotSet = deadlineString === DEADLINE_NOT_SET;
                    break;
                case IssuesFilterType.created:
                    comparisonDate = created;
                    isNotSet = false;
                    break;
                case IssuesFilterType.closed:
                default:
                    comparisonDate = closed;
                    isNotSet = !isClosed;
            }

            switch (this.dateFilterType) {
                case DateFilterRuleType.NotSet:
                    return isNotSet;
                case DateFilterRuleType.BeforeNow:
                    return !isNotSet && comparisonDate.isBefore(getUtcMidday(), 'd');
                case DateFilterRuleType.MoreThanNDaysAgo:
                    return !isNotSet && getUtcMidday().diff(comparisonDate, 'd') > Number(this.days);
                case DateFilterRuleType.WithinLastNDays:
                    return !isNotSet
                        && comparisonDate.isBefore(getUtcMidday().add(1, 'd'), 'd')
                        && comparisonDate.isAfter(getUtcMidday().subtract(Number(this.days) + 1, 'd'), 'd');
                case DateFilterRuleType.InNDaysFromNow: {
                    const diff = comparisonDate.diff(getUtcMidday(), 'd');

                    if (this.includeNow) {
                        return !isNotSet && diff <= Number(this.days);
                    } else {
                        return diff > 0 && diff <= Number(this.days);
                    }
                }
                case DateFilterRuleType.CustomEqual:
                    return !isNotSet && comparisonDate.isSame(dateObjToMoment(this.date), 'd');
                case DateFilterRuleType.CustomLess:
                    return !isNotSet && comparisonDate.isBefore(dateObjToMoment(this.date), 'd');
                case DateFilterRuleType.CustomLessOrEqual:
                    return !isNotSet && comparisonDate.isBefore(dateObjToMoment(this.date).add(1, 'd'), 'd');
                case DateFilterRuleType.CustomMore:
                    return !isNotSet && comparisonDate.isAfter(dateObjToMoment(this.date), 'd');
                case DateFilterRuleType.CustomMoreOrEqual:
                    return !isNotSet && comparisonDate.isAfter(dateObjToMoment(this.date).subtract(1, 'd'), 'd');
                case DateFilterRuleType.CustomBetween: {
                    let dateMoment = dateObjToMoment(this.date);
                    let date2Moment = dateObjToMoment(this.date2);

                    if (dateMoment.isSame(date2Moment, 'd')) {
                        date2Moment = date2Moment.add(1, 'd');
                    }
                    if (dateMoment.unix() > date2Moment.unix()) {
                        [dateMoment, date2Moment] = [date2Moment, dateMoment];
                    }
                    return !isNotSet
                        && comparisonDate.isAfter(dateMoment.subtract(1, 'd'), 'd')
                        && comparisonDate.isBefore(date2Moment, 'd');
                }
            }

            return true;
        });
    }
}
