<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :label="label"
        :multi-text="showFieldMultipleText"
    >
        <div>
            {{ $t(`IssueTracker.filters.procore.status.${currentIssue.procore.status}`) }}
        </div>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsProcoreStatus extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
}
</script>
