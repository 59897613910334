<template>
    <div class="issue-details-body">
        <IssueDetailsCustomType
            v-if="field === rows.customType"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsProcore
            v-if="field === rows.procore"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsProcoreStatus
            v-if="field === rows.procoreStatus"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsStampColor
            v-if="field === rows.stampColor"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsStatus
            v-if="field === rows.status"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsCustomStatus
            v-if="field === rows.customStatus"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsPriority
            v-if="field === rows.priority"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsDate
            v-if="[rows.deadline, rows.created].includes(field)"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsStampAbbr
            v-if="field === rows.stampAbbr"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsTitle
            v-if="field === rows.title"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsReporter
            v-if="field === rows.reporter"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsReporter
            v-if="field === rows.assignee"
            v-bind="$props"
            show-recent-users
            @change="setValue"
        />
        <IssueDetailsWatchers
            v-if="field === rows.watchers"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsVisibility
            v-if="field === rows.visibility"
            v-bind="$props"
            @change="setValue"
        />
        <IssueDetailsTags
            v-if="field === rows.tags"
            v-bind="$props"
            @change="setValue"
            @click="setFilter"
        />
        <IssueDetailsClashSimple
            v-if="[rows.level, rows.room, rows.space, rows.area, rows.zone].includes(field)"
            v-bind="$props"
            @click="setFilter"
        />
        <IssueDetailsClashGrid
            v-if="field === rows.grid"
            v-bind="$props"
            @click="setFilter"
        />
        <IssueDetailsClashSourceFile
            v-if="field === rows.clashSourceFile"
            v-bind="$props"
        />
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { IMultiselectNewValueItem } from '@/types/issue-tracker';
import { IssueTrackerFields } from '@/constants/IssueTrackerFields';
import { prepareValueForChangeIssueField } from '@/services/issueTracker/base';
import { Issue } from '@/domain/issue/models/Issue';
import IssueDetailsCustomType from '@/domain/issue/components/details/IssueDetailsCustomType.vue';
import IssueDetailsCustomStatus from '@/domain/issue/components/details/IssueDetailsCustomStatus.vue';
import IssueDetailsProcore from '@/domain/issue/components/details/IssueDetailsProcore.vue';
import IssueDetailsProcoreStatus from '@/domain/issue/components/details/IssueDetailsProcoreStatus.vue';
import IssueDetailsStampColor from '@/domain/issue/components/details/IssueDetailsStampColor.vue';
import IssueDetailsStatus from '@/domain/issue/components/details/IssueDetailsStatus.vue';
import IssueDetailsPriority from '@/domain/issue/components/details/IssueDetailsPriority.vue';
import IssueDetailsStampAbbr from '@/domain/issue/components/details/IssueDetailsStampAbbr.vue';
import IssueDetailsReporter from '@/domain/issue/components/details/IssueDetailsReporter.vue';
import IssueDetailsWatchers from '@/domain/issue/components/details/IssueDetailsWatchers.vue';
import IssueDetailsTitle from '@/domain/issue/components/details/IssueDetailsTitle.vue';
import IssueDetailsDate from '@/domain/issue/components/details/IssueDetailsDeadline.vue';
import IssueDetailsVisibility from '@/domain/issue/components/details/IssueDetailsVisibility.vue';
import IssueDetailsTags from '@/domain/issue/components/details/IssueDetailsTags.vue';
import IssueDetailsClashSimple from '@/domain/issue/components/details/IssueDetailsClashSimple.vue';
import IssueDetailsPreview from '@/domain/issue/components/details/IssueDetailsPreview.vue';
import IssueDetailsId from '@/domain/issue/components/details/IssueDetailsId.vue';
import IssueDetailsClashGrid from '@/domain/issue/components/details/IssueDetailsClashGrid.vue';
import IssueDetailsClashSourceFile from '@/domain/issue/components/details/IssueDetailsClashSourceFile.vue';

@Component({
    components: {
        IssueDetailsCustomType,
        IssueDetailsCustomStatus,
        IssueDetailsProcore,
        IssueDetailsProcoreStatus,
        IssueDetailsStampColor,
        IssueDetailsStatus,
        IssueDetailsPriority,
        IssueDetailsStampAbbr,
        IssueDetailsReporter,
        IssueDetailsWatchers,
        IssueDetailsTitle,
        IssueDetailsDate,
        IssueDetailsVisibility,
        IssueDetailsTags,
        IssueDetailsClashSimple,
        IssueDetailsPreview,
        IssueDetailsId,
        IssueDetailsClashGrid,
        IssueDetailsClashSourceFile,
    },
})
export default class IssueDetailsBody extends Vue {
    @Prop() public sourceValue!: any;
    @Prop() public row!: string;
    @Prop() public type!: string;
    @Prop({ required: true }) public rows!: any;
    @Prop({ required: true }) public field!: IssueTrackerFields;

    @Prop({ type: Boolean }) public editable!: boolean;
    @Prop({ type: Boolean }) public allowCreateTag!: boolean;
    @Prop() public items!: any[];
    @Prop() public label!: TranslateResult;
    @Prop() public valueKey!: any;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentUserEmail() {
        return this.$store.getters.userData.email;
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    @Emit()
    public setFilter(filterValue: { value: string; type: string }) {
        return filterValue;
    }

    public async setValue(newValue: boolean) {
        const payload = {
            row: this.row,
            sourceValue: this.prepareValue(this.sourceValue),
            value: this.prepareValue(newValue),
            currentUserEmail: this.currentUserEmail,
            type: this.type,
        } as IMultiselectNewValueItem;

        this.$store.commit('addMultiEditValue', payload);

        if (this.isMultiselectEditModeActive) {
            return;
        }

        await this.$store.dispatch('applySingleEditValue', {
            projectId: this.projectId,
            issue: this.currentIssue,
            currentUserEmail: this.currentUserEmail,
            payload,
        });

        await this.$store.dispatch('loadProjectFieldVariants', {
            projectId: this.projectId,
            isForce: true,
        });
    }

    public prepareValue(newValue: any) {
        return prepareValueForChangeIssueField({
            newValue,
            row: this.row,
            type: this.type,
        });
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.issue-details-body:has(div) {
    border-bottom: $border;
}
</style>
