import Router from '@/router';
import store from '@/storage';
import { Issue } from '@/domain/issue/models/Issue';

// Don't export by index.ts in services dir for avoid dependency of Store in all services imports
export function getStampCategoryUuid(issue: Issue) {
    const projectId = Number(Router.currentRoute.params.projectId);
    const stampCategoryUuidByStampAbbrValue = store.getters.stampCategoryUuidByStampAbbr(projectId);
    return issue.stampAbbr ? stampCategoryUuidByStampAbbrValue[issue.stampAbbr] : null;
}
