<template>
    <svg
        :height="size"
        :viewBox="`0 0 ${size} ${size}`"
        :width="size"
        class="svg"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                :fill="color"
                d="M6 5V35C6 36.6569 7.34315 38 9 38H31C32.6569 38 34 36.6569 34 35V12.8946C34 12.0684 33.6592 11.2787 33.058 10.7119L24.6849 2.81723C24.1282 2.29234 23.392 2 22.6269 2H9C7.34315 2 6 3.34315 6 5Z"
            />
            <svg alignment-baseline="middle" x="12" y="8">
                <use :href="`${url}#icon`" />
            </svg>
            <text
                class="text"
                fill="white"
                text-anchor="middle"
                x="50%"
                y="34"
            >
                {{ extension }}
            </text>
        </g>
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import IconFileSvgBase from '@/components/common/icon/IconFileSvgBase.vue';

@Component
export default class IconFileSvg40 extends IconFileSvgBase {
    public size = 40;
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.text {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    color: white;
}
</style>
