<template>
    <WsTooltip
        :disabled="!truncated"
        :tooltip="full"
        :max-width="maxTooltipWidth"
        :amp-mask="ampMask"
        tooltip-class="tooltip"
    >
        <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" :class="cssClassAmp">{{ truncated || full }}</span>
        </template>
    </WsTooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import WsTooltip from '@/components/common/WsTooltip.vue';
import { NameFilterParams, truncateName } from '@/domain/issueFilter/services/FiltersService';

@Component({
    components: {
        WsTooltip,
    },
})
export default class WsTruncate extends Vue {
    @Prop({ required: true }) public value!: NameFilterParams;
    @Prop({ type: String, default: '' }) public cssClass!: string;
    @Prop({ type: Number, default: 40 }) public size!: number;
    @Prop({ type: Boolean, default: false }) public singleLine!: boolean;
    @Prop({ type: Number, default: 450 }) public maxTooltipWidth!: number;
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;

    get truncatedName() {
        return truncateName(this.value, this.size, !this.singleLine);
    }

    get truncated() {
        return this.truncatedName.truncated;
    }
    get full() {
        return this.truncatedName.full;
    }

    get cssClassAmp() {
        const ampClass = this.ampMask ? ' amp-mask' : '';
        return this.cssClass + ampClass;
    }
}
</script>

<style lang="scss" scoped>
.tooltip {
    max-width: 80%;
    word-wrap: break-word !important;
}
</style>
