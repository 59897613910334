<template>
    <v-textarea
        v-model="model"
        :label="label"
        :error-count="maxMessages"
        :messages="messages"
        :error-messages="errorMessages"
        :success-messages="successMessages"
        :error="error"
        :rules="rules"
        :hide-details="hideDetails"
        :outlined="outlined"
        :dense="dense"
        :required="required"
        :placeholder="placeholder"
        :clearable="clearable"
        :disabled="disabled"
        :auto-grow="autoGrow"
        :rows="rows"
        :class="{
            'ws-textarea': isNew,
            'disabled': (isNew && disabled),
        }"
        :autofocus="autofocus"
        @focus="focus"
    >
        <slot />
        <template #label v-if="this.required">
            <span class="required-star">*</span>{{ label }}
        </template>
        <template v-for="(_, slotName) of $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-textarea>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-textarea/#props

@Component
export default class WsTextarea extends VuetifyElement {
    @Prop({ default: 5 }) public rows!: number | string;
    @Prop({ type: Boolean, default: false }) public autoGrow!: boolean;
    @Prop({ type: Boolean, default: false }) public autofocus!: boolean;
    @Prop({ type: Boolean, default: false }) public new!: boolean;

    get isNew() {
        return this.new;
    }
}
</script>

<style lang="scss" scoped>
.v-input {
    ::v-deep .v-messages__message {
        line-height: 1.4;
    }
}
</style>
