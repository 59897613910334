<template>
    <div class="ws-input-datepicker">
        <date-range-picker
            v-model="model"
            v-click-outside="updatePicker"
            ref="picker"
            :linked-calendars="linkedCalendars"
            :locale-data="locale"
            :min-date="minDate"
            :max-date="maxDate"
            :ranges="ranges"
            :auto-apply="autoApply"
            :show-dropdowns="showDropdowns"
            :show-week-numbers="showWeekNumbers"
            :opens="opens"
            :single-date-picker="singleDatePicker"
            @update="update"
            @toggle="toggle"
        >
            <template #input="picker">
                <WsInput
                    v-model="modelPrivate"
                    :error="!isDateValid"
                    :id="`date-input-${inputUuid}`"
                    @enter="updatePicker"
                />
            </template>

            <template v-if="showReset" #header>
                <div class="date-picker-header">
                    <WsButton
                        size="xsmall"
                        contained
                        @click="reset"
                    >
                        {{ $t('Button.reset') }}
                    </WsButton>
                </div>
            </template>
        </date-range-picker>
    </div>
</template>

<script lang="ts">
import moment from 'moment';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { v4 as uuid } from 'uuid';
// @ts-ignore
import DateRangePicker from 'vue2-daterange-picker';
import { DateFormatByNumber } from '@/constants';
import { DatepickerDateRange, IDateRange } from '@/models';
import { parseDate } from '@/services';
import WsInput from '@/components/common/WsInput.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        WsButton,
        WsInput,
        DateRangePicker,
    },
})

export default class WsInputDatepicker extends Vue {
    @Prop({ required: true }) public value!: DatepickerDateRange | IDateRange;
    @Prop() public localeData!: any;
    @Prop({ type: Boolean, default: true }) public autoApply!: boolean;
    @Prop({ type: Boolean, default: true }) public showDropdowns!: boolean;
    @Prop({ type: Boolean, default: true }) public showWeekNumbers!: boolean;
    @Prop({ type: Boolean, default: false }) public linkedCalendars!: boolean;
    @Prop({ type: Boolean, default: true }) public singleDatePicker!: boolean;
    @Prop({ default: false }) public ranges!: { [name: string]: Date[] };
    @Prop({ default: null }) public minDate!: Date;
    @Prop({ default: () => new Date() }) public maxDate!: Date;
    @Prop({ default: 'right' }) public opens!: string;
    @Prop({ type: Boolean, default: false }) public showReset!: boolean;

    public modelPrivate = '';
    public isDateValid = true;
    public inputUuid = uuid();

    get model(): DatepickerDateRange | IDateRange {
        return this.value;
    }
    set model(value: DatepickerDateRange | IDateRange) {
        const { startDate, endDate } = value;
        const startDateTweaked = moment(startDate).startOf('day');
        const endDateTweaked = moment(endDate).endOf('day');
        const valueTweaked = { startDate: startDateTweaked, endDate: endDateTweaked };
        if (this.value instanceof DatepickerDateRange) {
            this.input(new DatepickerDateRange(valueTweaked));
            return;
        }
        this.input(valueTweaked);
    }

    get locale() {
        return Object.assign({
            format: 'mmm dd, yyyy',
        }, this.localeData);
    }

    get currentUser() {
        return this.$store.getters.userData;
    }

    @Watch('model', { immediate: true, deep: true })
    public dateChanged(value: any) {
        this.modelPrivate = value.startDate ? moment(value.startDate).format(DateFormatByNumber[this.currentUser.dateFormat]) : '';
    }

    @Watch('modelPrivate', { immediate: true, deep: true })
    public datePrivateChanged(value: any) {
        this.validateInputDate();
        if (!value) {
            this.input({
                startDate: null,
                endDate: null,
            });
        }
    }

    @Emit()
    public input(value: any) {
        return value;
    }

    @Emit()
    public update() {
        return;
    }

    @Emit()
    public error() {
        return;
    }

    @Emit()
    public success() {
        return;
    }

    @Emit()
    public toggle(value: boolean) {
        return value;
    }

    @Emit()
    public reset() {
        return;
    }

    public created() {
        this.modelPrivate = this.model.startDate ? moment(this.model.startDate).format(DateFormatByNumber[this.currentUser.dateFormat]) : '';
    }

    public mounted() {
        (document.querySelector(`#date-input-${this.inputUuid} input`) as any).focus();
        setTimeout(() => {
            (this.$refs.picker as any)?.togglePicker(true);
        });
    }

    public validateInputDate() {
        const parsedDate = parseDate(this.modelPrivate, DateFormatByNumber[this.currentUser.dateFormat]);
        const parsedDateValid = !this.modelPrivate || Boolean(parsedDate && parsedDate?.isValid());
        this.isDateValid = parsedDateValid;
        this.$emit(parsedDateValid ? 'success' : 'error');
        return parsedDate;
    }

    public updatePicker() {
        const parsedDated = this.validateInputDate();
        if (parsedDated) {
            this.model = {
                startDate: parsedDated,
                endDate: parsedDated,
            };
            (this.$refs.picker as any)?.togglePicker(false);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/wsui/index.scss';

::v-deep .daterangepicker {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
div.ws-dialog .dialog-body {
    overflow: visible;
}

::v-deep {
    .v-text-field--outlined fieldset {
        border-color: transparent !important;
    }
    .v-text-field input {
        padding-top: 10px;
    }
}

::v-deep {
    .vue-daterange-picker {
        width: 109px;
    }

    .reportrange-text {
        margin: 0;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid $color-solid-40 !important;
        width: 100%;
        overflow: hidden;

        .v-text-field__details {
            display: none;
        }
    }

    .daterangepicker.opensright {
        left: -12px;
    }

    .date-picker-header {
        display: flex;
        justify-content: flex-end;
        padding: 8px 8px 0;
    }
}
</style>
