<template>
    <span class="issue-status">
        <IssueStatusText
            :status="status"
            :is-auto="boolIsAuto && status === IssueStatusEnum.closed"
            :pointer="pointer"
        />
    </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BooleanNumber } from '@/constants';
import { IssueStatusEnum } from '../constants/IssueStatus';
import IssueStatusText from '@/domain/issue/components/IssueStatusText.vue';

@Component({
    components: {
        IssueStatusText,
    },
})
export default class IssueStatus extends Vue {
    @Prop() public status!: IssueStatusEnum;
    @Prop() public isAuto!: boolean | BooleanNumber;
    @Prop({ type: Boolean }) public pointer!: boolean;

    public IssueStatusEnum = IssueStatusEnum;

    get boolIsAuto(): boolean {
        return Boolean(this.isAuto);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.issue-status {
    line-height: 20px;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.004em;
}
</style>
