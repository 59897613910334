<template>
    <div>
        <slot :items="firstChunk" />
        <v-lazy
            v-model="vLazyModel[index]"
            v-for="(chunk, index) of lazyChunks"
            :key="index"
            :options="{ threshold }"
            :min-height="minHeight"
        >
            <div>
                <slot :items="chunk" />
            </div>
        </v-lazy>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WsLazyRender extends Vue {
    @Prop({ default: () => [] }) public items!: any[];
    @Prop({ default: 20 }) public chunkSize!: number;
    @Prop({ default: 0.3 }) public threshold!: number;
    /*
        Do not remove line with prop min height because it breaks lazy render
     */
    @Prop({ default: 200 }) public minHeight!: number;

    public vLazyModel: boolean[] = [];

    get chunks() {
        return _.chunk(this.items, this.chunkSize);
    }

    get firstChunk() {
        return this.chunks[0];
    }

    get lazyChunks() {
        return this.chunks.slice(1);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-lazy {
    &:last-child {
        // This is need for cases when last chunk is small
        // without this rule the gap in the end appears.
        min-height: initial !important;
    }
}
</style>
