<template>
    <v-dialog
        v-model="isVisible"
        :width="widthWindow"
        :disabled="disabled"
        persistent
        no-click-animation
        @input="v => v || close(v)"
        @click:outside="clickOutside"
    >
        <template #activator="{ on }">
            <div class="button-open" v-on="on" :class="{ minimal, disabled }">
                <span
                    :class="{ contrast: isNeedContrastBorder, rectangle, minimal }"
                    :style="{ color }"
                    class="indicator"
                />
                <IconSvg16 v-if="minimal" icon-name="edit" stateless />
                <template v-if="!minimal">{{ buttonTitle }}</template>
            </div>
        </template>

        <div class="dialog-header">
            <div>{{ $t('ColorPicker.colors') }}</div>
            <WsCloseButton round @click="close" />
        </div>

        <div class="dialog-body">
            <div class="collapsable-block">
                <div class="header" @click="isCollapsedPicker = !isCollapsedPicker">
                    <span>{{ $t('ColorPicker.custom') }}</span>
                    <IconSvg16 :icon-name="isCollapsedPicker ? 'arrow-up' : 'arrow-down'" stateless />
                </div>

                <v-color-picker
                    v-model="color"
                    :width="widthWindow"
                    :dot-size="11"
                    :class="{ collapsed: isCollapsedPicker }"
                />
            </div>

            <hr>

            <div>
                <div class="margin-bottom">{{ $t('ColorPicker.recent') }}</div>

                <div class="palette-grid">
                    <span
                        v-for="color in recentColors"
                        :key="color"
                        :class="{ selected: checkSelectColor(color) }"
                        class="color-item"
                    >
                        <span :style="{ backgroundColor: color }" @click="selectColor(color)" />
                    </span>
                </div>
            </div>

            <hr>

            <div>
                <WsSelect
                    v-model="selectedColors"
                    class="margin-bottom"
                    :items="palettes"
                    hide-details
                />

                <div class="palette-grid">
                    <span
                        v-for="color in selectedColors"
                        :key="color"
                        :class="{ selected: checkSelectColor(color) }"
                        class="color-item"
                    >
                        <span :style="{ backgroundColor: color }" @click="selectColor(color)" />
                    </span>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script lang="ts">
import VueI18n, { TranslateResult } from 'vue-i18n';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import { ColorService, i18n } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsSelect from '@/components/common/WsSelect.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';

@Component({
    components: {
        WsCloseButton,
        IconSvg16,
        WsSelect,
    },
})
export default class WsColorPicker extends Vue {
    @Prop({ required: true, default: '' }) public value!: string;
    @Prop({ default: () => [] }) public dashboardsColors!: string[];
    @Prop({ default: false, type: Boolean }) public minimal!: boolean;
    @Prop({ default: i18n.t('ColorPicker.buttonOpen') }) public buttonTitle!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public rectangle!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;

    public isVisible = false;
    public widthWindow = 258;
    public type = 'hex';
    public model = { [this.type]: '#000000' }; // такое объявление отключает режим выбора прозрачности
    public initialColor = '';
    public isCollapsedPicker: boolean = true;
    public selectedColors: string[] = [];
    public randomColors: string[] = ColorService.generateColorList(24);

    public readonly reviztoColors: string[] = [
        Color.richBlack, Color.selectBlack, Color.defaultBlack, Color.mediumGray, Color.disableGray, Color.light,
        Color.primaryRed, Color.paletteRed, Color.primaryOrange, Color.primaryYellow, Color.paletteViolet, Color.paletteRedLight,
        Color.paletteGreen, Color.miskOldGreen, Color.primaryGreen, Color.palettePurple, Color.paletteBlue, Color.primaryBlue,
        Color.miskGray, Color.primaryLightBlue, Color.extraLight,
    ];
    public readonly stampsColors: string[] = [
        Color.paletteRed, Color.paletteOrange, Color.paletteYellow, Color.paletteLime, Color.paletteGreen,
        Color.paletteSky, Color.paletteBlue, Color.palettePurple, Color.paletteViolet, Color.richBlack,
    ];
    public readonly statusColors: string[] = [
        Color.primaryRed, Color.primaryOrange, Color.primaryGreen, Color.mediumGray, Color.selectBlack,
    ];
    public readonly priorityColors: string[] = [
        '#660000', Color.primaryRed, Color.primaryOrange, Color.miskOldGreen, '#24B2B2',
    ];

    get recentColors(): string[] {
        return this.$store?.getters.recentColors || [];
    }
    get color() {
        return this.value.trim().toUpperCase() || this.model[this.type];
    }
    set color(color: string) {
        this.input(color);
        this.model[this.type] = color;
    }
    get palettes(): Array<{ text: VueI18n.TranslateResult, value: string[] }> {
        const palettes = [
            {
                text: this.$t('ColorPicker.palette.revizto'),
                value: this.reviztoColors,
            },
            {
                text: this.$t('ColorPicker.palette.stamps'),
                value: this.stampsColors,
            },
            {
                text: this.$t('ColorPicker.palette.status'),
                value: this.statusColors,
            },
            {
                text: this.$t('ColorPicker.palette.priority'),
                value: this.priorityColors,
            },
            {
                text: this.$t('ColorPicker.palette.random'),
                value: this.randomColors,
            },
        ];
        if (this.dashboardsColors.length) {
            palettes.push({
                text: this.$t('ColorPicker.palette.dashboard'),
                value: this.dashboardsColors,
            });
        }
        return palettes;
    }
    get isNeedContrastBorder(): boolean {
        return ColorService.contrastHexColor(this.color) === '#000000';
    }

    @Emit()
    public input(color: string) {
        return color;
    }

    @Emit()
    public change(color: string) {
        this.addColorToRecent(color);
        return color;
    }

    public created() {
        this.color = this.value.trim().toUpperCase() || this.randomColors[0];
        this.selectedColors = this.reviztoColors;
        this.input(this.color);
        this.initialColor = this.color;
    }

    public selectColor(color: string) {
        this.color = color;
        this.close(color);
    }

    public close(event: string | MouseEvent) {
        this.isVisible = false;
        const color = event instanceof MouseEvent ? this.color : event;
        this.input(color);
        this.change(color);
    }
    public clickOutside() {
        this.close(this.color);
    }

    public addColorToRecent(color: string) {
        const isChanged = this.initialColor !== color;
        if (!isChanged) {
            return;
        }

        if (this.$store) {
            this.$store.dispatch('addRecentColor', color);
        }

        this.initialColor = color;
    }

    public checkSelectColor(color: string) {
        return this.color === color;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

$cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE+SURBVHgBzZQ7boNAEIYHEySXrqGhBoqkc0sB10g4QZKahpwgKdPhG6TOEUJjbhAKoKGIKxoQmxlrkbBls2tsS/6l2Vnt49PMzmgVOEOO40TonmnOGFtpmvaWpulGhYmybXvFgXNuy67r5lVVfc9ggjjw8cDWEw0nQ0eApAUNdyCG/JLH93KbpolGgNt3lYLiQVAUxWzbdt1HcuRcWtf1K82F6ePju/ySCOhmWbaRgqqqGo3t7wOF0L4ohmFAHMdAXgQEERCN+b7PiqJgpCRJGK31ZlnW/aG7M5kIdV0HBEMYhjvnsIBfUtBjwCAIoCzLbcpoD2gZdcaklPM8Z57n9emuTdNcgKxuH4jf18dFgTzKP7p8MSAMqk+V3K/ySY09kIIRvaN/GS6eAySp2I8/9GPjfIlGkE8EBlOBV9M/JawfeRP9buUAAAAASUVORK5CYII=) 2 17, auto;

::v-deep .v-dialog {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-auto-flow: row;
    background-color: $white;
    font-size: $font-size;
    line-height: $line-height-small;

    .dialog-header {
        display: grid;
        grid-template-columns: 1fr auto;
        padding: $default-padding !important;
        align-items: center;
        font-size: $font-size;
        line-height: 20px;
        color: $select-black;
        border-bottom: $border;
    }

    .dialog-body {
        @include scrollbar;
        padding: 0;
        overflow-x: hidden;

        > div:not(.collapsable-block) {
            padding: $default-padding;
        }

        hr {
            margin: 0;
        }

        .v-color-picker__canvas {
            cursor: $cursor;
        }

        .v-slider__thumb {
            cursor: pointer;
        }

        .margin-bottom {
            margin-bottom: 20px;
        }
    }
}

.button-open {
    display: grid;
    grid-gap: $default-gap;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding: 4px;
    margin-bottom: 24px;
    border-radius: $border-radius-default;
    cursor: pointer;
    transition: all 0.15s ease-out;
    position: relative;

    &.minimal {
        margin-bottom: 0;
    }

    &:hover {
        background-color: $light;
    }

    .indicator {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        border: 1px solid transparent;
        background-color: currentColor;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        z-index: 5;

        &.contrast {
            border-color: $light;
        }

        &.rectangle {
            width: 80px;
            border-radius: 30px;
        }

        &.minimal {
            height: 24px;
            width: 24px;
            border-radius: 100%;

            &:hover {
                border-color: currentColor;
                background-color: transparent;
                span {
                    color: $select-black;
                }
            }

            span {
                padding-top: 2px;
                margin: 0 8px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    ::v-deep .svg-icon {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 4;
    }
}
.button-open.disabled {
    cursor: default;
}
.button-open.disabled .indicator.minimal:hover {
    background-color: currentColor !important;
}

.palette-grid {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(6, 1fr);
}

.color-item {
    display: inline-block;
    width: $default-icon-height;
    height: $default-icon-height;
    border-radius: 100%;
    position: relative;

    span {
        display: inline-block;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        border: 1px solid $divider-gray;
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        z-index: 1;
        cursor: pointer;
    }

    &.selected::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        border: 1px solid $primary-blue;
        border-radius: 100%;
    }
}

.collapsable-block {
    .header {
        display: grid;
        grid-template-columns: 1fr auto;
        padding: $default-padding;
        cursor: pointer;

        &:hover {
            background: $light;
        }
    }

    .collapsed {
        display: none;
    }
}
</style>
