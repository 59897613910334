<template>
    <div class="sidebar-header">
        <div class="left">
            <WsCloseButton :color="Color.primaryGrey" @click="close" />
            <h4>{{ title }}</h4>
        </div>
        <div class="right">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import WsSidebar from '@/components/common/WsSidebar.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';

@Component({
    components: {
        WsSidebar,
        WsCloseButton,
    },
})
export default class WsSidebarHeader extends Vue {
    @Prop({ default: () => [] }) public title!: string;

    public readonly Color = Color;

    @Emit()
    public close(value: boolean) {
        return value;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.sidebar-header {
    display: flex;
    justify-content: space-between;
    min-height: 32px;
}

::v-deep .ws-close-button {
    color: $text-dark;

    svg {
        width: 26px;
        height: 26px;
    }
}

.left {
    display: flex;
    align-items: center;
    gap: 14px;

    h4 {
        font-size: 18px;
        line-height: $line-height-large;
        font-weight: $font-weight-bold;
        color: $text-dark;
    }
}

.right {
    display: flex;
    align-items: center;
    gap: 16px;
}
</style>