import Vue from 'vue';
import LicenseApi from '@/api/license.api';
import { notificationSuccess } from '@/services';
import { ProjectDashboardPreset } from '@/domain/dashboard/models/ProjectDashboardPreset';
import DashboardApi from '@/domain/dashboard/api/DashboardApi';

interface IProjectDashboardPresetState {
    projectDashboardPresetsObj: { [id: number]: ProjectDashboardPreset[] };
    isLoadingProjectDashboardPresetsObj: { [id: number]: boolean };
    isDeletingProjectDashboardPresets: boolean;
    isSendingProjectDashboardPreset: boolean;
}

export default {
    state: {
        projectDashboardPresetsObj: {},
        isLoadingProjectDashboardPresetsObj: {},
        isDeletingProjectDashboardPresets: false,
        isSendingProjectDashboardPreset: false,
    },
    getters: {
        projectDashboardPresetsByLicenseId(state: IProjectDashboardPresetState): (licenseId: number) => ProjectDashboardPreset[] {
            return (licenseId) => state.projectDashboardPresetsObj[licenseId] || [];
        },
        isLoadingProjectDashboardPresets(state: IProjectDashboardPresetState): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingProjectDashboardPresetsObj[licenseId];
        },
        isDeletingProjectDashboardPresets(state: IProjectDashboardPresetState) {
            return state.isDeletingProjectDashboardPresets;
        },
        isSendingProjectDashboardPreset(state: IProjectDashboardPresetState) {
            return state.isSendingProjectDashboardPreset;
        },
    },
    mutations: {
        setProjectDashboardPresets(state: IProjectDashboardPresetState, { licenseId, dashboardPresets }: { licenseId: number, dashboardPresets: ProjectDashboardPreset[] }) {
            Vue.set(state.projectDashboardPresetsObj, licenseId, dashboardPresets);
        },
        setIsLoadingProjectDashboardPresets(state: IProjectDashboardPresetState, { licenseId, value }: { licenseId: number; value: boolean }) {
            Vue.set(state.isLoadingProjectDashboardPresetsObj, licenseId, value);
        },
        setIsDeletingProjectDashboardPresets(state: IProjectDashboardPresetState, value = true) {
            state.isDeletingProjectDashboardPresets = value;
        },
        setIsSendingProjectDashboardPreset(state: IProjectDashboardPresetState, value = true) {
            state.isSendingProjectDashboardPreset = value;
        },
        setRemovePresetsInLicense(state: IProjectDashboardPresetState, { licenseId, uuids }: { licenseId: number; uuids: string[] }) {
            const filteredPresets = state.projectDashboardPresetsObj[licenseId]
                .filter((preset: ProjectDashboardPreset) => uuids.findIndex((uuid: string) => uuid === preset.uuid) === -1);
            Vue.set(state.projectDashboardPresetsObj, licenseId, filteredPresets);
        },
    },
    actions: {
        loadProjectDashboardPresets(
            { state, commit }: any,
            { licenseId, projectId, isForce = false }: { licenseId: number, projectId?: number, isForce: boolean },
        ): Promise<ProjectDashboardPreset[]> {
            return new Promise((resolve, reject) => {
                if (!isForce && state.projectDashboardPresetsObj[licenseId]) {
                    resolve(state.projectDashboardPresetsObj[licenseId]);
                    return;
                }

                commit('setIsLoadingProjectDashboardPresets', { licenseId, value: true });

                // запрашивать по licenseId можно только из раздела лицензии, из проектов - по projectId,
                // там какие-то мутки с проверкой доступов, подробности у Паши
                let request;
                if (projectId) {
                    request = DashboardApi.getProjectDashboardPresets(projectId);
                } else {
                    request = LicenseApi.getProjectDashboardPresets(licenseId);
                }

                request.then((response) => {
                    const dashboardPresets = response.entities.map((projectDashboard: any) => new ProjectDashboardPreset(projectDashboard));
                    commit('setProjectDashboardPresets', { licenseId, dashboardPresets });
                    resolve(dashboardPresets);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingProjectDashboardPresets', { licenseId, value: false });
                });
            });
        },
        deleteProjectPresets(
            { commit }: any,
            { licenseId, uuids }: { licenseId: number, uuids: string[] },
        ) {
            commit('setIsDeletingProjectDashboardPresets');
            return LicenseApi.postRemovePreset(licenseId, { uuids })
                .then(() => {
                    notificationSuccess('presetsDeleted');
                    commit('setRemovePresetsInLicense', { licenseId, uuids });
                }).finally(() => {
                    commit('setIsDeletingProjectDashboardPresets', false);
                });
        },
        addProjectPreset(
            { commit }: any,
            { projectId, dashboardUuid, title }: { projectId: number, dashboardUuid: string, title: string },
        ) {
            commit('setIsSendingProjectDashboardPreset', true);
            return DashboardApi.postAddPreset(projectId, dashboardUuid, title).then(() => {
                notificationSuccess('presetCreated');
            }).finally(() => {
                commit('setIsSendingProjectDashboardPreset', false);
            });
        },
        updateProjectPreset(
            { commit }: any,
            { projectId, dashboardUuid, presetUuid }: { projectId: number, dashboardUuid: string, presetUuid: string },
        ) {
            commit('setIsSendingProjectDashboardPreset', true);
            return DashboardApi.postUpdatePreset(projectId, dashboardUuid, presetUuid).then(() => {
                notificationSuccess('allSaved');
            }).finally(() => {
                commit('setIsSendingProjectDashboardPreset', false);
            });
        },
    },
};
