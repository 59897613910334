<template>
    <div :class="{ 'active': popover }" class="filter-item">
        <WsDateRangePicker
            v-model="model"
            :min-date="minDateLocal"
            :ranges="ranges"
            dynamic-opens
            @toggle="togglePopover"
            @update="change"
        >
            <template #input="{ startDate, endDate }">
                <div class="filter-datepicker-trigger">
                    <div class="filter-label">{{ $t('ActivityChart.timeInterval') }}</div>
                    <span v-if="stringInterval">{{ stringInterval }}</span>
                    <span v-else>{{ dateFormatter(startDate) }} - {{ dateFormatter(endDate) }}</span>
                    <div
                        class="dropdown-arrow"
                        :class="{ 'up': popover }"
                    >
                        <IconSvg16
                            icon-name="arrow-down"
                            stateless
                        />
                    </div>
                </div>
            </template>
        </WsDateRangePicker>
    </div>
</template>

<script lang="ts">
import moment from 'moment';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { MS_IN_SEC } from '@/constants';
import { DatepickerDateRange, License } from '@/models';
import { dateFormatter } from '@/services/MomentFormats';
import WsDateRangePicker from '@/components/common/WsDateRangePicker.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        WsDateRangePicker,
        IconSvg16,
    },
    methods: {
        dateFormatter,
    },
})
export default class WsTableFilterDatepicker extends Vue {
    @Prop({ required: true, default: () => new DatepickerDateRange() }) public value!: DatepickerDateRange;
    @Prop() public ranges!: Dict<Date[]>;
    @Prop() public minDate!: Date;

    public popover = false;

    get model(): DatepickerDateRange {
        return this.value;
    }
    set model(value: DatepickerDateRange) {
        this.input(value);
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get minDateLocal(): Date {
        return this.minDate || new Date(moment(this.license.created).startOf('day').unix() * MS_IN_SEC);
    }

    get stringInterval() {
        const ranges = Object.entries(this.ranges).map(([key, [start, end]]) => {
            return { key, start, end };
        });
        return (ranges.find(({ start, end }) => {
            return moment(start).isSame(this.model.startDate, 'day')
                && moment(end).isSame(this.model.endDate, 'day');
        }) || {} as any).key;
    }

    @Emit()
    public input(value: DatepickerDateRange) {
        return value;
    }

    @Emit()
    public change() {
        return;
    }

    public togglePopover(value = false) {
        this.popover = value;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.dropdown-arrow {
    display: flex;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    margin-left: 5px;

    &.up {
        transform: rotate(180deg);
    }
}

::v-deep .form-control {
    padding: 0;
}

::v-deep .reportrange-text {
    border: none !important;
}

::v-deep .filter-datepicker-trigger {
    font-weight: bold;

    &:active {
        svg {
            color: $white;
        }
    }
}
</style>
