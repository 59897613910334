import http from '@/api/http';
import { StatusReplacementMap } from '@/domain/customStatus/types/statusReplacementMap';
import { IIssueTypeSettings } from '@/domain/issueType/types/issueType';

export default class IssueTypeApi {
    /**
     * add custom issue type
     */
    public static createIssueType(projectUuid: string, payload: IIssueTypeSettings): Promise<any> {
        const params = {
            entities: JSON.stringify([{
                name: payload.name,
                description: payload.description,
                icon: payload.icon,
                isDefault: payload.isDefault,
                workflowUuid: payload.workflowUuid,
                iconColor: payload.iconColor,
            }]),
        };

        return http.post(`project/${projectUuid}/issue-type/add`, params, true, true)
            .then((response) => response.data);
    }

    /**
     * restore custom issue type
     */
    public static restoreIssueType(projectUuid: string, typeUuid: string): Promise<any> {
        return http.post(`project/${projectUuid}/issue-type/${typeUuid}/restore`).then((response) => response.data);
    }

    /**
     * update custom issue type
     */
    public static updateIssueType(projectUuid: string, payload: IIssueTypeSettings[]): Promise<any> {
        return http.post(`project/${projectUuid}/issue-type/update`,  { entities: JSON.stringify(payload) }, true, true).then((response) => response.data);
    }

    public static deleteIssueType(projectUuid: string, payload: {
        typeUuid: string,
        newTypeUuid: string,
        statusReplacementMap: StatusReplacementMap[],
    }): Promise<any> {
        const params = {
            newTypeUuid:  payload.newTypeUuid,
            statusReplacementMap: payload.statusReplacementMap,
        };

        return http.post(`project/${projectUuid}/issue-type/${payload.typeUuid}/delete`, params, true, true);
    }

    public static editIssueType(projectUuid: string, issueTypeUuid: string, payload: {
        name: string;
        description: string;
        icon: string;
        isDefault: boolean;
        workflowUuid: string;
        iconColor: string;
        statusReplacementMap: StatusReplacementMap[];
        deletedAt: string | null;
    }): Promise<any> {
        const params = {
            entities: JSON.stringify([{
                uuid: issueTypeUuid,
                name: payload.name,
                description: payload.description,
                icon: payload.icon,
                isDefault: payload.isDefault,
                workflowUuid: payload.workflowUuid,
                iconColor: payload.iconColor,
                statusReplacementMap: payload.statusReplacementMap,
                deletedAt: payload.deletedAt,
            }]),
        };

        return http.post(`project/${projectUuid}/issue-type/update`, params, true, true)
            .then((result) => result.data);
    }
}