import _ from 'lodash';
import { DirectiveFunction } from 'vue';

type LoadingSize = 'xsmall' | 'small' | 'medium' | 'large';
const eligibleSizes = ['xsmall', 'small', 'medium', 'large'];
let size: LoadingSize = 'large';
const isLoadingClass = 'loading-directive';

const loaderID = 'loading-spinner-';
const preloaderAttr = 'preloader-id';

const createSpinner = () => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('class', 'preloader-circular');
    svg.setAttribute('viewBox', '25 25 50 50');

    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circle.setAttribute('class', 'preloader-path');
    circle.setAttribute('cx', '50');
    circle.setAttribute('cy', '50');
    circle.setAttribute('r', '20');
    circle.setAttribute('fill', 'none');
    circle.setAttribute('stroke-width', '4');
    circle.setAttribute('stroke-miterlimit', '10');

    svg.appendChild(circle);
    return svg;
};

const createText = (text: string) => {
    const textSpan = document.createElement('span');
    textSpan.classList.add('preloader-text');
    textSpan.textContent = text;
    return textSpan;
};

const LoadingDirectiveBind: DirectiveFunction = (el: HTMLElement, { value, oldValue, arg, modifiers }) => {
    if (oldValue === value) {
        return;
    }

    const typedArg = arg as LoadingSize;

    if (arg && eligibleSizes.includes(typedArg)) {
        size = typedArg;
    }

    // Show loader
    if (value) {
        const loaderWrapper = document.createElement('div');
        loaderWrapper.classList.add('preloader-wrapper');
        loaderWrapper.id = loaderID + _.random(0, 1000);

        if (modifiers.opaque) {
            loaderWrapper.classList.add('opaque');
        }

        const loader = document.createElement('div');
        loader.classList.add('preloader', size);

        const spinner = createSpinner();
        loader.appendChild(spinner);

        loaderWrapper.appendChild(loader);

        if (_.isString(value)) {
            const text = createText(value);
            loaderWrapper.appendChild(text);
        }

        el.classList.add(isLoadingClass);
        el.setAttribute(preloaderAttr, loaderWrapper.id);
        el.appendChild(loaderWrapper);

        return;
    }

    // Hide loader
    if (!value) {
        el.classList.remove(isLoadingClass);
        const loaderId = el.getAttribute(preloaderAttr);
        if (!loaderId) {
            return;
        }

        el.removeAttribute(preloaderAttr);
        const loader = document.getElementById(loaderId);
        if (loader) {
            loader.remove();
        }
    }
};

const LoadingDirectiveUnbind: DirectiveFunction = (el: HTMLElement) => {
    el.classList.remove(isLoadingClass);
    const loaderId = el.getAttribute(preloaderAttr);
    if (!loaderId) {
        return;
    }

    el.removeAttribute(preloaderAttr);
    const loader = document.getElementById(loaderId);
    if (loader) {
        loader.remove();
    }
};

const LoadingDirective = {
    bind: LoadingDirectiveBind,
    update: LoadingDirectiveBind,
    unbind: LoadingDirectiveUnbind,
};

export default LoadingDirective;
