import _ from 'lodash';
import { Dict } from '@/types/Dict';

/**
 * Inspired by lodash's _.set and _.get functions, but accepts array of strings to form path.
 * This addresses the case when keys contain dots (like emails), which would be split by lodash's _.set and _.get.
 */
export class Deep {
    public static setWithPathArray(obj: Dict, pathArray: string[], value: any) {
        if (!_.isObject(obj) || !_.isArray(pathArray) || !pathArray.length) {
            return obj;
        }
        obj = _.cloneDeep(obj);

        let current: any = obj;

        for (let index = 0; index < pathArray.length; index++) {
            const key = pathArray[index];
            if (index === pathArray.length - 1) {
                current[key] = value;
            } else {
                current[key] = current[key] || {};
                current = current[key];
            }
        }

        return obj;
    }

    public static getWithPathArray(obj: any, pathArray: string[]) {
        if (!_.isObject(obj) || !_.isArray(pathArray) || !pathArray.length) {
            return;
        }

        let current: any = obj;

        for (let index = 0; index < pathArray.length; index++) {
            const key = pathArray[index];
            if (!current[key]) {
                return;
            }
            current = current[key];
        }

        return current;
    }
}
