<template>
    <DialogBoarding @close="close">
        <template #navigation>
            <ol class="navigation">
                <li
                    v-for="(step, index) in steps"
                    :key="index"
                    :class="{ active: index === activeStepIndex}"
                    @click="changeStep(index)"
                >
                    <span>{{ step.title }}</span>
                </li>
            </ol>
        </template>
        <template #content>
            <DialogBoardingContent
                :title="activeStep.title"
                :media="activeStep.media"
                :description="activeStep.description"
                video
            />
        </template>
        <template #footer>
            <div class="footer">
                <div v-html="sanitizeHtml($t('Boarding.support'))" class="support" />
                <div class="footer-navigation">
                    <WsButton
                        v-if="activeStepIndex > 0"
                        class="nav-button previous"
                        type="text"
                        contained
                        @click="changeStep(activeStepIndex - 1)"
                    >
                        <IconSvg24
                            :color="Color.primaryBlue"
                            :size="16"
                            icon-name="chevron-left"
                            passive
                        />
                        {{ $t('Boarding.previous') }}
                    </WsButton>
                    <WsButton
                        v-if="activeStepIndex < steps.length - 1"
                        class="nav-button next"
                        type="primary"
                        contained
                        @click="changeStep(activeStepIndex + 1)"
                    >
                        {{ $t('Boarding.next') }}
                        <IconSvg24
                            :color="Color.white"
                            :size="16"
                            icon-name="chevron-right"
                            passive
                        />
                    </WsButton>
                </div>
            </div>
        </template>
    </DialogBoarding>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AmplitudeEvent, Color } from '@/constants';
import { amplitudeLog, sanitizeHtml } from '@/services';
import DialogBoarding from '@/components/user/boarding/DialogBoarding.vue';
import DialogBoardingContent from '@/components/user/boarding/DialogBoardingContent.vue';
import WsButton from '@/components/common/WsButton.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    methods: {
        sanitizeHtml,
    },
    components: {
        DialogBoarding,
        DialogBoardingContent,
        WsButton,
        IconSvg24,
    },
})
export default class StepsDialog extends Vue {
    public readonly Color = Color;
    public activeStepIndex = 0;
    public stepsShown = 1;

    get activeStep() {
        return this.steps[this.activeStepIndex];
    }

    get steps() {
        return [
            {
                name: 'project-team',
                label: this.$t('Boarding.steps.projectTeam.label'),
                title: this.$t('Boarding.steps.projectTeam.title'),
                media: '/video/welcome/project-team.mp4',
                description: this.$t('Boarding.steps.projectTeam.text'),
            },
            {
                name: 'dashboards',
                label: this.$t('Boarding.steps.dashboards.label'),
                title: this.$t('Boarding.steps.dashboards.title'),
                media: '/video/welcome/dashboards.mp4',
                description: this.$t('Boarding.steps.dashboards.text'),
            },
            {
                name: 'web-issue-tracker',
                label: this.$t('Boarding.steps.webIssueTracker.label'),
                title: this.$t('Boarding.steps.webIssueTracker.title'),
                media: '/video/welcome/web-issue-tracker.mp4',
                description: this.$t('Boarding.steps.webIssueTracker.text'),
            },
            {
                name: 'notifications',
                label: this.$t('Boarding.steps.notifications.label'),
                title: this.$t('Boarding.steps.notifications.title'),
                media: '/video/welcome/notifications.mp4',
                description: this.$t('Boarding.steps.notifications.text'),
            },
        ];
    }

    public created() {
        amplitudeLog(AmplitudeEvent.boardingStepsOpen);
    }

    public close() {
        this.$store.commit('setIsShowBoardingSteps', false);
        amplitudeLog(AmplitudeEvent.boardingStepsClose, { steps: this.stepsShown });
    }

    public changeStep(index: number) {
        this.activeStepIndex = index;

        if (this.stepsShown < this.steps.length) {
            this.stepsShown++;
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.navigation {
    padding: 0;
    list-style-position: inside;

    li {
        color: $dark-solid-60;
        padding: 8px 12px;
        border-radius: 4px;
        font-size: $font-size-small;
        list-style: inherit;
        transition: color 0.2s ease-in-out;
        cursor: pointer;

        &.active {
            color: $white;
            background-color: $dark-solid-20;
        }

        &:hover {
            color: $white;
        }
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $light-solid-10;
    padding: 15px 32px;

    .footer-navigation {
        flex-shrink: 0;
    }

    .support {
        font-size: $font-size-xsmall;
        line-height: $line-height-small;
    }

    .nav-button {
        font-size: $font-size-small;
        padding: 4px 10px 4px 16px;
        height: 32px;

        &.previous {
            padding: 4px 16px 4px 10px;
            &:active {
                ::v-deep .v-btn__content {
                    color: $white;
                }
            }

            ::v-deep .v-btn__content {
                color: $primary-blue;

            }
        }

        ::v-deep .v-btn__content {
            display: flex;
            align-items: center;
            gap: 8px;

            svg.svg {
                color: currentColor;
                width: 18px;
                height: 18px;
            }
        }
    }
}

</style>
