<template>
    <div class="collapsable-block" :class="{ collapsed: model, 'content-disabled': contentDisabled }">
        <div class="block-header" @click="toggle">
            <div>
                <IconSvg16 :icon-name="model ? 'arrow-down' : 'arrow-up'" stateless />
                <span>{{ title }}</span>
            </div>
            <slot name="right" />
        </div>
        <div class="block-content">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { Dict } from '@/types/Dict';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

const LOCAL_STORAGE_KEY = 'ws-collapsable-block-state';

const getCurrentBlockSavedState = (blockName: string) => {
    const lsValue = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!lsValue) {
        return;
    }

    try {
        const parsedValue = JSON.parse(lsValue);
        return parsedValue[blockName];
    } catch {
        return;
    }
};

const saveCurrentBlockStateInLS = (blockName: string, state: boolean) => {
    const lsValue = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!lsValue) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ [blockName]: state }));
        return;
    }

    let parsedValue: Dict<boolean>;

    try {
        parsedValue = JSON.parse(lsValue);
    } catch {
        parsedValue = {};
    }

    parsedValue[blockName] = state;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedValue));
};

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsCollapsableBlock extends Vue {
    @Prop({ type: Boolean, default: null }) public collapsed!: boolean;
    @Prop({ required: true }) public title!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public contentDisabled!: boolean;
    @Prop() public blockName!: string;
    @Prop({ type: Boolean, default: false }) public saveState!: boolean; // Save state to local storage, required blockName

    public localCollapsed: boolean = false;

    get model() {
        return this.collapsed ?? this.localCollapsed;
    }

    @Emit()
    public toggle() {
        this.localCollapsed = !this.model;

        if (this.saveState && this.blockName) {
            saveCurrentBlockStateInLS(this.blockName, this.localCollapsed);
        }

        return this.localCollapsed;
    }

    public created() {
        const savedState = getCurrentBlockSavedState(this.blockName);

        if (savedState !== undefined) {
            this.localCollapsed = savedState;
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.collapsable-block {
    &.collapsed {
        .block-content {
            height: 0;
            padding: 0;
            overflow: hidden;
        }
    }
}
.collapsable-block.content-disabled .block-content {
    background-color: $extra-light;
}
.collapsable-block.content-disabled .block-header {
    background-color: $extra-light;
}
.block-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2em;
    letter-spacing: 0.0015em;
    height: 48px;
    cursor: pointer;
    padding: 0 $container-padding;
    transition: .2s all;

    ::v-deep .svg-icon {
        margin-right: $default-padding;
    }

    &:hover {
        background: $light;
    }
}
.block-content {
    padding: 10px $container-padding 0;
}
</style>
