<template>
    <div class="collapsable-column" :class="{ collapsed, sticky: $slots.sticky }">
        <div class="column-header" @click="toggle">
            <div class="column-name">
                <IconSvg24 :icon-name="icon" passive />
                <span class="title-text">{{ title }}</span>
            </div>
            <IconSvg24
                :icon-name="collapsed ? 'arrow-double-left' : 'arrow-double-right'"
                :tooltip="$t(`Collocation.${collapsed ? 'show' : 'hide'}Something`, { entity })"
                stateless
            />
        </div>
        <div class="sticky-lines" v-if="$slots.sticky">
            <slot name="sticky" />
        </div>
        <div class="column-content">
            <slot />
        </div>
        <div class="main-action" v-if="$slots.action">
            <slot name="action" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
        IconSvg24,
    },
})
export default class WsCollapsableColumn extends Vue {
    @Prop({ required: true, type: Boolean }) public collapsed!: boolean;
    @Prop({ required: true }) public title!: TranslateResult;
    @Prop({ required: true }) public icon!: string; // IconSvg
    @Prop({ type: String, default: '' }) public entity!: string;

    @Emit()
    public toggle() {
        return !this.collapsed;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.collapsable-column {
    display: grid;
    grid-template-rows: 55px 1fr;

    &.sticky {
        grid-template-rows: 55px auto 1fr;
    }
    &.collapsed {
        grid-template-rows: 1fr;

        .column-header {
            flex-direction: column-reverse;
            justify-content: flex-end;
            padding: 15px 0 0;
            gap: 6px;

            .column-name {
                flex-direction: column;

                .title-text {
                    writing-mode: vertical-lr;
                }
            }
        }
        .column-content,
        .main-action,
        .sticky-lines {
            display: none;
        }
    }

    .column-header {
        border-bottom: $border;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: .2s background-color;
        font-weight: 500;
        font-size: $font-size;
        letter-spacing: 0.0015em;
        padding: 0 14px 0 10px;

        &:hover {
            background: $light;
        }

        .column-name {
            display: flex;
            gap: 12px;
            align-items: center;
        }
    }
    .column-content {
        @include scrollbar;
    }
}
.main-action {
    border-top: $border;
    padding: $container-padding;
}
.sticky-lines {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
