<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :editable="editable"
        :label="label"
        :multi-text="showFieldMultipleText"
        :multi-changed="isNewMultiValue"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <StampAbbr
            :abbr="currentValue"
            :color="currentIssue.stampColor"
        />

        <template #action>
            <IssueDetailsEditPopover
                :initial-value="currentValue"
                :items="itemsForSelect"
                :is-reset="false"
                searchable
                @change="handleSelect"
            >
                <template #item="{ value, fields, text }">
                    <div class="issue-stamp-item">
                        <div class="d-flex justify-end">
                            <StampAbbr
                                :abbr="fields.stampAbbr"
                                :color="fields.stampColor"
                            />
                        </div>
                        <div class="issue-stamp-text">{{ text }}</div>
                    </div>
                </template>
            </IssueDetailsEditPopover>
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import StampAbbr from '@/domain/stamp/components/StampAbbr.vue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover, { ListItem } from '@/domain/issue/components/details/IssueDetailsEditPopover.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';
import { StampColorsPaletteRGBEnum } from '@/domain/stamp/constants/StampColorsPaletteRGBEnum';

@Component({
    components: {
        IssueDetailsItem,
        IssueDetailsEditPopover,
        StampAbbr,
    },
})
export default class IssueDetailsStampAbbr extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean }) public editable!: boolean;
    @Prop({ required: true }) public items!: ListItem[];

    public readonly StampColorsPaletteRGBEnum = StampColorsPaletteRGBEnum;

    get itemsForSelect(): ListItem[] {
        return this.items;
    }

    public handleSelect(value: string) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
        }
    }
}
</script>

<style lang="scss" scoped>
.issue-stamp-item {
    display: grid;
    grid-template-columns: 70px auto;
    grid-gap: 12px;
    align-items: center;
}
</style>
