import { NotifierUserID } from '@/constants';
import http from '@/api/http';

export default class IssueApi  {
    /**
     * Read one issue
     */
    public static readIssue({ projectId, issueUuid }: { projectId: number, issueUuid: string }) {
        return http.post(`issue/${issueUuid}/read`, { project_id: projectId }).then((response) => response.data);
    }

    /**
     * Undelete issue
     */
    public static undeleteIssue(payload: { projectId: number, issueUuid: string, operationId: string, notifierUserId: number }) {
        const { projectId, issueUuid, operationId } = payload;

        return http.post(`issue/${issueUuid}/undelete`, {
            project_id: projectId,
            operationId,
            [NotifierUserID]: payload.notifierUserId,
        }).then((response) => response);
    }

    /**
     * Delete issue
     */
    public static postDeleteIssue(payload: { projectId: number, issueUuid: string, operationId: string, notifierUserID: number }) {
        const { projectId, issueUuid, operationId } = payload;
        return http.post(`issue/${issueUuid}/delete`, {
            project_id: projectId,
            operationId,
            [NotifierUserID]: payload.notifierUserID,
        }).then((response) => response);
    }

    /**
     * Send issues to receive pdf
     */
    public static postIssuesToPdf({ projectId, params }: { projectId: number, params: any }) {
        return http.post(`project/${projectId}/issues/export`, params);
    }

}