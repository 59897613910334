<template>
    <div class="suppressed">
        <div v-if="!isHasOld && isHasNew">
            <div v-if="newRfiLink">
                {{ $t('IssueTracker.diffComments.procore.elevatedIssueToProcoreRFI') }}
            </div>
            <div v-else>
                {{ $t('IssueTracker.diffComments.procore.connectedIssueToProcore') }}
            </div>
        </div>
        <div v-else-if="isHasOld && !isHasNew">
            {{ $t('IssueTracker.diffComments.procore.detachedFromProcore') }}
        </div>
        <div v-else-if="isChangedRFISubject">
            {{ $t('IssueTracker.diffComments.procore.changedRFISubject', { subject: diff.new.RFISubject }) }}
        </div>

        <div v-if="isStatusChanged || isUndraft">
            <IssueCommentDiffChanged entity="status" />
            <IssueStatusProcoreText
                v-if="diff.old.RFINumber || diff.new.RFINumber"
                :rfi-number="diff.old.RFINumber || diff.new.RFINumber"
                :status="status.old"
                tag-like
            />
            <IssueStatusText v-else :status="status.old" tag-like />
            ➝
            <IssueStatusProcoreText
                :rfi-number="diff.new.RFINumber"
                :status="status.new"
                tag-like
            />
        </div>

        <div v-if="isOfficialReplyChanged && isProcoreStatusChanged && diff.new.officialReply">
            {{ $t('IssueTracker.diffComments.procore.officialReply', { officialReply: diff.new.officialReply }) }}
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import IssueStatusText from '@/domain/issue/components/IssueStatusText.vue';
import IssueCommentDiffChanged from '@/domain/comment/components/IssueCommentDiffChanged.vue';
import IssueStatusProcoreText from '@/domain/issue/components/IssueStatusProcoreText.vue';
import { ProcoreRFILink } from '@/domain/issue/models/Issue';
import { IDiffItem } from '@/domain/comment/models/DiffComment';

type PossibleEmptyProcoreRFILink = ProcoreRFILink | string[];

@Component({
    components: {
        IssueStatusText,
        IssueCommentDiffChanged,
        IssueStatusProcoreText,
    },
})
export default class IssueCommentDiffProcore extends Vue {
    @Prop({ required: true }) public diff!: IDiffItem;
    @Prop({ required: true }) public statusDiff!: IDiffItem;

    get new() {
        return this.diff.new as PossibleEmptyProcoreRFILink;
    }

    get newRfiLink() {
        return Array.isArray(this.new) ? undefined :  this.new;
    }

    get old() {
        return this.diff.old as PossibleEmptyProcoreRFILink;
    }

    get oldRfiLink() {
        return Array.isArray(this.old) ? undefined : this.old;
    }

    get isHasOld() {
        return !Array.isArray(this.old);
    }

    get isHasNew() {
        return !Array.isArray(this.new) || this.new.length !== 0;
    }

    get isChangedRFISubject() {
        return this.isHasOld
            && this.isHasNew
            && this.oldRfiLink !== this.newRfiLink;
    }

    get isStatusChanged() {
        const oldStatus = this.statusDiff?.old;
        const newStatus = this.statusDiff?.new;

        return oldStatus
            && newStatus
            && oldStatus !== newStatus;
    }

    get isUndraft() {
        return this.isDraftOld && typeof this.isDraftNew === 'boolean' && !this.isDraftNew;
    }

    get isProcoreStatusChanged() {
        return this.oldRfiLink?.status !== this.newRfiLink?.status;
    }

    get isOfficialReplyChanged() {
        return this.oldRfiLink?.officialReply !== this.newRfiLink?.officialReply;
    }

    get isDraftOld() {
        return this.oldRfiLink?.RFIDraft;
    }

    get isDraftNew() {
        return this.newRfiLink?.RFIDraft;
    }

    get status() {
        if (this.isStatusChanged) {
            return {
                old: this.isDraftOld ? `draft-${this.statusDiff.old}` : this.statusDiff.old,
                new: this.isDraftNew ? `draft-${this.statusDiff.new}` : this.statusDiff.new,
            };
        }

        if (this.isUndraft) {
            return {
                old: this.oldRfiLink?.status,
                new: this.newRfiLink?.status,
            };
        }

        return {
            old: this.statusDiff.old,
            new: this.statusDiff.new,
        };
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
</style>
