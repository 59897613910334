<template>
    <div>
        <WsMultiSelect
            v-model="selected"
            :searchable="searchable && !colorSelect"
            :disabled="disabled"
            :input-placeholder="placeholder"
            :options="multiSelectOptions"
            :single="single"
        >
            <template #activator="{ isOpen }">
                <div
                    class="activator"
                    :class="{ 'is-opened': isOpen }"
                >
                    <span>{{ label }}</span>
                    <div class="selection">
                        {{ truncateString(selectedString, 40) }}
                        <IconSvg16
                            v-if="selected.length"
                            icon-name="close"
                            inline
                            parent-hover
                            stateless
                            font-like
                            @click.stop="clear"
                        />
                    </div>
                    <IconSvg16
                        icon-name="chevron-down"
                        inline
                        parent-hover
                        stateless
                        font-like
                    />
                </div>
            </template>
            <template
                v-for="item in items"
                #[item.value]
            >
                <div :key="item.value">
                    <span
                        v-if="colorSelect"
                        :style="{ background: item.value }"
                        class="color-circle"
                    />
                    <CustomIssueType
                        v-else-if="type === IssuesFilterType.customType && item.value"
                        :issue-type="item.value"
                    />
                    <CustomStatusItem
                        v-else-if="type === IssuesFilterType.customStatus && item.value"
                        :status="item.value"
                    />
                    <WsTruncateAuto
                        v-else
                        :value="item.text"
                        :key="refreshKey"
                    />
                </div>
            </template>
        </WsMultiSelect>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { ISelectedItem } from '@/types/ISelectedItem';
import { IComboboxOption } from '@/types/common';
import { truncateString } from '@/services';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';
import WsTableFilterElement from '@/components/common/WsTableFilterElement.vue';
import WsMultiSelect from '@/components/common/WsMultiSelect.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import CustomIssueType from '@/domain/issueType/components/CustomIssueType.vue';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import CustomStatusItem from '@/domain/customStatus/components/CustomStatusItem.vue';

@Component({
    components: {
        CustomStatusItem,
        CustomIssueType,
        WsTruncateAuto,
        WsTruncate,
        WsTableFilterElement,
        WsLazyRender,
        WsMultiSelect,
        WsCloseButton,
        IconSvg16,
    },
})
export default class WsTableFilterSelectV2 extends Vue {
    @VModel({ required: true }) public selected!: Array<string | number>;
    @Prop({ required: true }) public items!: ISelectedItem[];
    @Prop({ type: Boolean, default: true }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean }) public colorSelect!: boolean;
    @Prop({ type: String }) public type!: string;
    // @Prop({ default: 'bottom' }) public placement!: string; // TODO revive it in future
    @Prop() public placeholder!: TranslateResult;
    @Prop() public label!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public single!: boolean;
    @Prop() public selectedFn!: (value: any) => string;

    public readonly IssuesFilterType = IssuesFilterType;
    public readonly truncateString = truncateString;

    public refreshKey = 0;

    get multiSelectOptions(): IComboboxOption[] {
        return this.items.map(({ value, text, searchValue }) => ({
            label: String(text),
            value: String(value),
            searchValue: String(searchValue || value),
        }));
    }

    get selectedItems() {
        return this.multiSelectOptions.filter(({ value }) => this.selected.includes(value));
    }

    get selectedString() {
        if (!this.selected.length) {
            return this.$t('Simple_word.any');
        }

        if (this.selectedFn) {
            return this.selectedItems.map(({ value }) => this.selectedFn(value)).join(String(this.$t('Punctuation.comma')));
        }

        return this.selectedItems.map(({ label }) => label).join(String(this.$t('Punctuation.comma')));
    }

    @Emit()
    public input(value: Array<string | number>) {
        return value;
    }
    @Emit()
    public clear() {
        this.input([]);
        return;
    }

}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/colors/semantic.scss';

.activator {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;

    ::v-deep .svg-icon {
        transition: transform 0.2s ease;
        font-size: 0;
    }

    &.is-opened {
        ::v-deep > .svg-icon {
            transform: rotate(180deg);
        }

        .selection {
            background-color: $fill-accent-subtle-default;
        }
    }

    span {
        color: $text-secondary-default;
    }
}

.selection {
    display: flex;
    padding: 2px 8px 2px 8px;
    margin: 0 6px 0 8px;
    font-size: 14px;
    line-height: 21px;
    color: $text-primary-default;
    background-color: $fill-tertiary-subtle-default;
    border-radius: 15px;
    font-weight: 500;

    &:hover {
        background-color: $fill-accent-subtle-default;
    }

    ::v-deep .svg-icon {
        margin-right: -3px;
    }

}

.color-circle {
    display: inline-block;
    width: 38px;
    height: 38px;
    border-radius: 100%;

    &.small {
        width: 14px;
        height: 14px;
        border: 1px solid $fill-primary-subtle-default;
    }
}
</style>
