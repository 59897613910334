<template>
    <WsDialog
        :title="$t('Button.edit_watchers')"
        width="800px"
        @close="cancel"
    >
        <div class="form-grid">
            <div class="form-current-watchers">
                <div class="form-title">{{ $t('IssueTracker.info.watchers.currentWatchers') }}</div>
                <span
                    v-for="(entries, index) in watcherEntries"
                    :key="index"
                >
                    {{ getWatcherName(entries.watcher) }}
                    <span class="gray-text">
                        ({{ entries.count === issueCount ? $t('Simple_word.all') : entries.count }})
                    </span>
                    {{ getCommaForListItem(watcherEntries, index) }}
                </span>

                <hr>
            </div>

            <WsCombobox
                v-model="addWatchers"
                :items="items"
                :label="'+ ' + $t('IssueTracker.info.watchers.addWatchers')"
                :allow-create="false"
                searchable
                multiple
            />

            <WsCombobox
                v-model="removeWatchers"
                :items="items"
                :label="'- ' + $t('IssueTracker.info.watchers.removeWatchers')"
                :allow-create="false"
                searchable
                multiple
            />
        </div>

        <div class="actions-wrapper">
            <div>
                <WsButton round @click="save">{{ $t('Button.save') }}</WsButton>
                <WsButton round @click="cancel">{{ $t('Button.cancel') }}</WsButton>
            </div>
        </div>
    </WsDialog>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { MultiEditIssueFieldPayload } from '@/types/MultiEditIssueFieldPayload';
import { ProjectMember, ProjectMembersOption } from '@/models';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import WsButton from '@/components/common/WsButton.vue';
import WsCombobox from '@/components/common/WsCombobox.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    components: {
        WsButton,
        WsCombobox,
        WsDialog,
    },
})
export default class DialogMultiEditWatchers extends Vue {
    @Prop({ default: () => [] }) public watcherEntries!: Array<{
        count: number;
        watcher: string;
    }>;
    @Prop() public issueCount!: number;
    @Prop({ default: () => [] }) public items!: ProjectMembersOption[];

    public readonly getCommaForListItem = getCommaForListItem;

    public addWatchers: string[] = [];
    public removeWatchers: string[] = [];

    get projectId() {
        return Number(this.$route.params.projectId);
    }

    get projectMembers(): ProjectMember[] {
        return this.$store.getters.projectMembersByProjectId(this.projectId);
    }

    get projectMembersByEmail() {
        return _.keyBy(this.projectMembers, 'email');
    }

    @Emit()
    public save(): MultiEditIssueFieldPayload {
        return {
            add: this.addWatchers,
            remove: this.removeWatchers,
        };
    }

    @Emit()
    public cancel() {
        return;
    }

    @Emit()
    public input(value: string[]) {
        return value;
    }

    @Watch('localValue')
    public watchLocalValue(newValue: string[]) {
        this.input(newValue);
    }

    public getWatcherName(watcherEmail: string) {
        return this.projectMembersByEmail[watcherEmail]?.fullname || watcherEmail;
    }
}
</script>

<style lang="scss" scoped>
.form-grid {
    display: grid;
    grid-gap: 24px;
    grid-template-areas:
        "currentWatсhers currentWatсhers"
        "addWatсhers removeWatсhers";
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr 1fr;
}

.form-title {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 6px;
}

.form-current-watchers {
    grid-area: currentWatсhers;

    > hr {
        margin-top: 10px;
        margin-bottom: 0;

        &:after {
            left: 0;
            right: 0;
        }
    }
}

.actions-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
</style>
