import http from '@/api/http';
import { IChangeFavoriteDashboardParams } from '@/domain/dashboard/types/ChangeFavoriteDashboardParams';

export default class DashboardApi {
    /**
     * Получение списка пресетов дашбордов проекта
     */
    public static getProjectDashboardPresets(projectId: number): Promise<any> {
        return http.get(`project/${projectId}/dashboard-presets`).then((response) => response.data);
    }

    /**
     * Обновление данных периодической доставки графика
     */
    public static postUpdateChartDeliverySettings(projectId: number, dashboardUuid: string, chartUuid: string, params: any): Promise<any> {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/update-delivery-settings`, params).then((response) => response.data);
    }

    /**
     * Экспорт дашборда
     */
    public static postExportProjectDashboard({ projectId, dashboardUuid, params }: { projectId: string, dashboardUuid: string, params: any }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/export`, params).then((response) => response.data);
    }

    /**
     * Импорт дашборда
     */
    public static postImportProjectDashboard({ projectId, params }: { projectId: string, params: any }) {
        return http.post(`project/${projectId}/dashboard/import`, params).then((response) => response.data);
    }

    /**
     * Импорт графиков для дашборда
     */
    public static postImportProjectDashboardCharts({ projectId, dashboardUuid, params }: { projectId: string, dashboardUuid: string, params: any }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/import-graphs`, params).then((response) => response.data);
    }

    /**
     * Получение списка дашбордов
     */
    public static getProjectDashboards(projectId: number): Promise<{ entities: any[]; favorite: string[]; selected: string }> {
        return http.get(`project/${projectId}/dashboard/list`).then((response) => response.data);
    }

    /**
     * Создание дашборда
     */
    public static postCreateProjectDashboard(projectId: number, params: any) {
        return http.post(`project/${projectId}/dashboard/add`, params).then((response) => response.data);
    }

    /**
     * Создание дашборда
     */
    public static postCreateProjectDashboardWithPreset(projectId: number, presetUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${presetUuid}/use-preset`, params).then((response) => response.data);
    }

    /**
     * Сохранение параметров дашборда
     */
    public static postUpdateProjectDashboard(projectId: number, dashboardUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/edit`, params).then((response) => response.data);
    }

    /**
     * Удаление дашборда
     */
    public static postDeleteProjectDashboard(projectId: number, dashboardUuid: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/remove`).then((response) => response.data);
    }

    /**
     * Изменение сортировки графиков в дашборде
     */
    public static postChangeOrderProjectDashboardCharts(projectId: number, dashboardUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/reorder`, params).then((response) => response.data);
    }

    /**
     * Единоразовая отправка дашборда на любое количество email
     */
    public static postDashboardSendToEmail(projectId: number, dashboardUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/send-to-email`, params).then((response) => response.data);
    }

    /**
     * Настройки отправки дашборда
     */
    public static postDashboardSetDelivery(projectId: number, dashboardUuid: string, params: any) {
        params.includeCompanyLogo = Number(params.includeCompanyLogo); // так как в апишку нужны инты, а в модели булы
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/update-delivery-settings`, params).then((response) => response.data);
    }

    /**
     * Создание пресета дашборда
     */
    public static postAddPreset(projectId: number, dashboardUuid: string, title: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/create-preset`, {
            title,
        }).then((response) => response.data);
    }

    /**
     * Обновление пресета дашборда
     */
    public static postUpdatePreset(projectId: number, dashboardUuid: string, presetUuid: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/update-preset/${presetUuid}`)
            .then((response) => response.data);
    }

    /**
     * Добавление дашбордов в фаворитные
     */
    public static postAddFavoriteProjectDashboards({ projectId, params }: { projectId: string, params: IChangeFavoriteDashboardParams }) {
        return http.post(`project/${projectId}/favorite-dashboard/add`, params).then((response) => response.data);
    }

    /**
     * Удаление дашбордов в фаворитные
     */
    public static postRemoveFavoriteProjectDashboards({ projectId, params }: { projectId: string, params: IChangeFavoriteDashboardParams }) {
        return http.post(`project/${projectId}/favorite-dashboard/remove`, params).then((response) => response.data);
    }

    /**
     * Получение фаворитных дашбордов
     */
    public static getFavoriteProjectDashboards({ projectId }: { projectId: string }) {
        return http.get(`project/${projectId}/favorite-dashboard`).then((response) => response.data);
    }

    /**
     * Subscribe project dashboard
     */
    public static subscribeOnDashboard({ projectId, dashboardUuid }: { projectId: string, dashboardUuid: string }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/selfsubscribe`).then((response) => response.data);
    }

    /**
     * Unsubscribe project dashboard
     */
    public static unsubscribeFromDashboard({ projectId, dashboardUuid }: { projectId: string, dashboardUuid: string }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/selfunsubscribe`).then((response) => response.data);
    }

    /**
     * Subscribe license dashboard
     */
    public static subscribeOnLicenseDashboard({ licenseId, dashboardUuid }: { licenseId: string, dashboardUuid: string }) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/selfsubscribe`).then((response) => response.data);
    }

    /**
     * Unsubscribe license dashboard
     */
    public static unsubscribeFromLicenseDashboard({ licenseId, dashboardUuid }: { licenseId: string, dashboardUuid: string }) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/selfunsubscribe`).then((response) => response.data);
    }
}