<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonProjectsTable extends Vue {
    @Prop({ type: Object, default: () => ({}) }) public options!: any;

    public getRows() {
        const rowsCount = this.options?.rowsCount || 10;

        return _.range(rowsCount);
    }
}
</script>