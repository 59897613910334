<template>
    <div class="issue-list">
        <div
            v-if="loading"
            ref="items"
            class="items"
        >
            <WsSkeletonLoader
                v-for="index in 6"
                :key="index"
                content="issue"
            />
        </div>

        <template v-else>
            <div ref="items" class="items">
                <template v-for="(issue, index) in readyIssues">
                    <IssueItem
                        :key="issue.uuid"
                        :issue="issue"
                        :selected="selectedIssue && (issue.uuid === selectedIssue.uuid)"
                        :class="{
                            'issue-item__next_issue_same': nextIssueHasBackground(index),
                        }"
                        @click="click"
                        @mounted="nextIssueItemMounted"
                    />
                </template>
                <div v-if="!issues.length && !loading" class="no-issues">{{ $t('IssueTracker.noIssuesToDisplay') }}</div>
            </div>

            <div @click="onPaginationClick">
                <v-pagination
                    v-if="pageCount > 1"
                    ref="pagination"
                    v-model="currentPage"
                    :length="pageCount"
                    :total-visible="paginationTotalVisible"
                    @next="onPaginationChange('arrow-next')"
                    @previous="onPaginationChange('arrow-prev')"
                />
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog, isOverflownElementVertically } from '@/services';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';
import IssueItem from '@/domain/issue/components/IssueItem.vue';
import IssueSort from '@/domain/issue/components/IssueSort.vue';
import { Issue } from '@/domain/issue/models/Issue';
import { TrackerFilters } from '@/domain/issueFilter/types/ProjectIssuesFilters';

@Component({
    components: {
        IssueSort,
        IconSvg24,
        IssueItem,
        WsSkeletonLoader,
    },
})
export default class IssueList extends Vue {
    @Prop({ type: Boolean, default: false }) public isAllowLoadingContent!: boolean;
    @Prop({ required: true }) public issues!: Issue[];
    @Ref() public readonly pagination!: HTMLElement;

    public paginationTotalVisible = 3;
    public resizeObserver = new ResizeObserver(() => 1);

    private drawnIssues = 0;
    private allowedToDrawIssues = 10;

    get readyIssues(): Issue[] {
        return this.issues.slice(0, this.allowedToDrawIssues);
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get pageCount() {
        return this.$store.getters.issuePagesObjByProjectId(this.projectId);
    }

    get trackerFilters(): TrackerFilters {
        return this.$store.getters.trackerFiltersByProjectId(this.projectId);
    }

    get currentPage() {
        return this.$store.getters.currentPageObjByProjectId(this.projectId);
    }

    set currentPage(page: number) {
        this.$store.dispatch('updateTrackerPage', {
            projectId: this.projectId,
            page,
            filters: this.trackerFilters,
        });
    }

    get loading(): boolean {
        return !this.isAllowLoadingContent || this.$store.getters.isLoadingIssuesByProjectId(this.projectId);
    }

    get selectedIssue() {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get multiSelectedIssues(): Issue[] {
        return this.$store.getters.multiSelectedIssues;
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get issuesIds() {
        return this.issues.map((issue: Issue) => issue.id);
    }

    @Watch('issuesIds')
    public onIssuesChanged(newValue: number[], oldValue: number[]) {
        if (!_.isEqual(newValue, oldValue)) {
            this.drawnIssues = 0;
            this.allowedToDrawIssues = 10;
        }
    }

    get debouncedIssuesCheck() {
        return _.debounce(() => {
            amplitudeLog(AmplitudeEvent.itIssueCheck, { method: 'checkbox', count: this.multiSelectedIssues.length });
        }, 2000);
    }

    get debouncedIssuesUnCheck() {
        return _.debounce(() => {
            amplitudeLog(AmplitudeEvent.itIssueUnCheck, { method: 'checkbox' });
        }, 2000);
    }

    public nextIssueItemMounted() {
        setTimeout(() => {
            this.drawnIssues++;

            if (this.drawnIssues === this.allowedToDrawIssues) {
                this.allowedToDrawIssues += 10;
            }
        });
    }

    public hasScroll() {
        // used outside
        const itemsEl = this.$refs.items as HTMLElement;
        return isOverflownElementVertically(itemsEl);
    }

    public resizePagination() {
        const buttonSize = 46;
        const arrowSize = 52;
        const width = this.$el.clientWidth;

        this.paginationTotalVisible = Math.floor((width - arrowSize * 2) / buttonSize);
    }

    public mounted() {
        const debouncedResize = _.debounce(this.resizePagination, 200);
        this.resizeObserver = new ResizeObserver(debouncedResize);
        this.resizeObserver.observe(this.$el);
    }

    public beforeDestroy() {
        this.resizeObserver.disconnect();
    }

    public click({ issue, clickOnCheckbox, value }: { issue: Issue; clickOnCheckbox: boolean; value: boolean }) {
        // See if issue checking or unchecking according on presence issue in selected list.
        const isRemovedFromSelect = !value;

        // Multiselect logic.
        if (clickOnCheckbox) {
            if (value) {
                this.debouncedIssuesCheck();
            } else {
                this.debouncedIssuesUnCheck();
            }
            this.$store.dispatch('handleIssueMultiSelect', issue);
        } else if (this.multiSelectedIssues.length === 1) {
            this.$store.dispatch('clearMultiSelect');
        } else if (this.multiSelectedIssues.length > 1) {
            this.$store.dispatch('clearMultiSelect');
            amplitudeLog(AmplitudeEvent.itIssueCheck, { method: 'card', count: 1 });
            this.$store.dispatch('handleIssueMultiSelect', issue);
        }

        // Set selected issues AFTER multiselect logic worked, it's crucial.
        const seletedIssuesCount = this.multiSelectedIssues.length;

        // Focus logic.
        if (isRemovedFromSelect && seletedIssuesCount > 0) {
            this.focusIssue(this.multiSelectedIssues[seletedIssuesCount - 1]);
        } else {
            this.focusIssue(issue);
        }
    }

    public focusIssue(issue: Issue) {
        this.$store.commit('setSelectedIssue', { projectId: this.projectId, issue });
    }

    public nextIssueHasBackground(index: number) {
        const issue = this.readyIssues[index];
        const nextIssue = this.readyIssues[index + 1];

        if (!issue || !nextIssue) {
            return false;
        }

        const issueHasBackground = issue.isDeadlineExpired
            || issue.uuid === this.selectedIssue?.uuid
            || this.multiSelectedIssues.find((item) => item.uuid === issue.uuid);

        if (!issueHasBackground) {
            return false;
        }

        const nextIssueHasBackground = nextIssue.isDeadlineExpired
            || nextIssue.uuid === this.selectedIssue?.uuid
            || this.multiSelectedIssues.find((item) => item.uuid === nextIssue.uuid);

        return Boolean(nextIssueHasBackground);
    }

    public onPaginationChange(action: string) {
        amplitudeLog(AmplitudeEvent.ItPaginationUse, { action, pages: this.pageCount });
    }

    // In v-pagination is no way to check if click was on page number.
    public onPaginationClick(event: MouseEvent) {
        const element = event.target as HTMLElement;

        if (!element) {
            return;
        }

        if (element.classList.contains('v-pagination__item')) {
            amplitudeLog(AmplitudeEvent.ItPaginationUse, { action: 'page-number', pages: this.pageCount });
        }

    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins';

.issue-list {
    padding: 0;
    overflow: auto;
    display: grid;
    grid-template-rows: 1fr auto;
    overflow-x: hidden;

    @include scrollbar;
}

.items {
    border-bottom: $border;
    @include scrollbar;

    .issue-item {
        &:last-child {
            border-bottom: none;
        }
    }
}

.v-input--checkbox {
    margin: 5px 0 0 0;
}

.no-issues {
    display: grid;
    height: 35%;
    justify-content: center;
    align-items: end;
    font-weight: bold;
}
</style>
