<template>
    <WsTooltip
        :tooltip="tooltipContent"
        :placement="placement"
        :disabled="!tooltipContent"
        :activator-class="activatorClasses"
        :max-width="tooltipMaxWidth"
        :block="block"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            :width="size + 8 * Number(!stateless)"
            :height="size + 8 * Number(!stateless)"
            :viewBox="!stateless ? '-4 -4 24 24' : '0 0 16 16'"
            :class="{ stateless, disabled, focused, passive, 'parent-hover': parentHover, filled }"
            :style="generatedStyle"
            class="svg"
            @click="handleClick"
        >
            <circle
                v-if="!stateless"
                class="solo-border"
                fill="none"
                cx="8"
                cy="8"
                r="11.4"
                stroke-width="1.2"
            />
            <svg>
                <use :href="`${url}#icon`" />
            </svg>
        </svg>
    </WsTooltip>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import IconSvgBase from '@/components/common/icon/IconSvgBase.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
    },
})
export default class IconSvg16 extends IconSvgBase {
    // @ts-ignore
    @Prop({ default: 16 }) public size!: number;

    get url() {
        return require(`./svg/${this.iconName}_16.svg`);
    }
}
</script>

