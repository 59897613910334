<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47 8V24H45V17L14 17V15L45 15V8H47Z"
            fill="black"
        />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType12 extends BaseIcon {}
</script>
