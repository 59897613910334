<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :editable="editable"
        :label="label"
        :multi-text="showFieldMultipleText"
        :multi-changed="isNewMultiValue"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <IssuePriorityItem :value="currentValue" />

        <template #action>
            <IssueDetailsEditPopover
                :initial-value="currentValue"
                :items="itemsForSelect"
                :is-reset="false"
                @change="handleSelect"
            >
                <template #item="{ value }">
                    <IssuePriorityItem :value="value" />
                </template>
            </IssueDetailsEditPopover>
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import IssuePriorityItem from '@/domain/issue/components/IssuePriorityItem.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/domain/issue/components/details/IssueDetailsEditPopover.vue';

@Component({
    components: {
        IssueDetailsItem,
        IssueDetailsEditPopover,
        IssuePriorityItem,
    },
})
export default class IssueDetailsPriority extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    get itemsForSelect(): Array<{ text: string; value: string }> {
        return this.items.map((value) => ({ text: value, value }));
    }

    public handleSelect(value: string) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
        }
    }
}
</script>

<style lang="scss" scoped>
.color {
    height: 24px;
    width: 48px;
    display: block;
    border-radius: 15px;
}
</style>
