<template>
    <div
        :class="{ 'detail_multi-changed': multiChanged }"
        class="issue-details-item-component detail"
    >
        <div :class="{ 'detail-top-3-column' : collapsable && editable }" class="detail-top">
            <div v-if="label || $slots.label" class="detail-title">
                <slot name="label">
                    <span>{{ label }}</span>
                    <slot name="icon" />
                    <slot name="warning-icon">
                        <IconSvg16
                            v-if="isShowWarningIcon"
                            :color="Color.paletteYellow"
                            icon-name="notification-attention"
                            activator-class="warning-icon"
                            stateless
                            inline
                            @click="onClickOnWarningIcon"
                        />
                    </slot>
                </slot>
                <span v-if="counter" class="counter"> ({{ counter }})</span>
            </div>

            <IconSvg16
                v-if="collapsable && !multiText"
                :icon-name="collapsed ? 'arrow-down' : 'arrow-up'"
                :class="{ 'mr-4': editable }"
                stateless
                @click="toggleCollapsed"
            />

            <slot v-if="editable" name="action">
                <IconSvg16
                    :tooltip="$t('Simple_word.edit')"
                    icon-name="edit"
                    stateless
                    @click="edit"
                />
            </slot>
        </div>

        <div :class="{ 'hidden-collapsed': collapsed, 'collapsing-item': collapsable }">
            <slot :collapsed="collapsed" v-if="!multiText" />
            <div v-if="multiText" class="multiple-text">{{ $t('IssueTracker.multiple') }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { Color } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class IssueDetailsItem extends Vue {
    @Prop({ type: Number }) public counter!: number;
    @Prop() public label!: TranslateResult;
    @Prop() public collapsingKey!: string;
    @Prop({ type: Boolean, default: false }) public editable!: boolean;
    @Prop({ type: Boolean, default: false }) public collapsable!: boolean;
    @Prop({ type: Boolean, default: false }) public multiText!: boolean;
    @Prop({ type: Boolean, default: false }) public multiChanged!: boolean;
    @Prop({ type: Boolean, default: false }) public isShowWarningIcon!: boolean;

    public collapsed = (localStorage.getItem(this.collapsingKey) === 'true');

    public readonly Color = Color;

    @Emit()
    public edit() {
        dispatchEvent(new CustomEvent('closeEditSlots'));
        return;
    }

    @Emit()
    public onClickOnWarningIcon() {
        return;
    }

    public toggleCollapsed() {
        const value = this.collapsed;
        localStorage.setItem(this.collapsingKey, String(!value));
        this.collapsed = !value;
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/wsui/colors.scss";

.detail {
    padding: 10px $container-padding;
    border-bottom: $border;
    position: relative;
    word-break: break-word;

    &:last-child {
        border-bottom: 0;
    }

    &_multi-changed {
        background-color: $primary-light-blue;
    }
}

.detail-top {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: $default-gap;
}

.detail-top-3-column {
    grid-template-columns: 1fr auto auto;
}

.detail-title {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
    color: $color-solid-40;

    .svg-icon {
        margin: 0 0 0 6px;
    }

}

.detail-edit {
    span {
        &:hover {
            cursor: pointer;
        }
    }
}

.counter {
    font-weight: 700;
    font-size: 12px;
}

$line-height: 1.5em;
$line-multiplier: 2;
.collapsing-item {
    overflow: unset;
    display: block;
    -webkit-line-clamp: $line-multiplier;
    -webkit-box-orient: vertical;
    line-height: $line-height;

    &.hidden-collapsed {
        max-height: $line-height * $line-multiplier;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
    }
}

.multiple-text {
    font-weight: bold;
}

::v-deep {
    .detail-title {
        .svg-icon {
            margin: 0 0 0 6px;
        }
    }
}

::v-deep .warning-icon {
    position: absolute;
    top: 8px;
}
</style>
