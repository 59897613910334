<template>
    <div class="notifier-status" :class="notifierStatusCss" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotifierStatus extends Vue {
    get notifierStatusCss(): string {
        if (!this.$store.getters.isOnline) {
            return '';
        }
        if (this.$store.getters.isConnectedToNotifier) {
            return 'connected';
        }
        return 'warning';
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.notifier-status {
    width: 8px;
    background-color: $disable-gray;

    &.connected { background-color: $success; }
    &.warning { background-color: $warning; }
}
</style>
