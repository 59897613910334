import { TUuid } from '@/types/common';
import http from '@/api/http';

export default class SchedulerApi {
    /**
     * Getting task definition list
     */
    public static getSchedulerTasks({ projectUuid, append, filters, sort, limit, query, page = 1 }:
                                        { projectUuid: string, append?: string[], filters?: any, sort: any, limit: number, query: string, page: number },
    ) {
        return http.get('task-definition', { projectUuid, append, ...filters, ...sort, query, limit, page }).then((response) => response.data);
    }

    /**
     * Getting task definition
     */
    public static getSchedulerTask({ projectUuid, uuid }: { projectUuid: string, uuid: string }) {
        return http.get(`task-definition/${uuid}`, { projectUuid, uuid }).then((response) => response.data);
    }

    /**
     * Getting task run list
     */
    public static getSchedulerTaskHistory({ projectUuid, sort, limit, filters, query, page = 1, taskDefinitionUuids = [] }: { projectUuid: string, page: number, sort: any, query: string, limit: number, filters: any, taskDefinitionUuids: TUuid[] }) {
        return http.get('task-run', { projectUuid, page, query, limit, ...filters, ...sort, taskDefinitionUuids, append: ['appendMessages', 'appendDeviceData', 'appendLastOnline'] }).then((response) => response.data); //  appendLastOnline
    }

    /**
     * Getting futur tasks
     */
    public static getSchedulerFutureTasks({ projectUuid, page = 1 }: { projectUuid: string, page: number }) {
        return http.get('future-task-run', { projectUuid, page }).then((response) => response.data);
    }

    /**
     * Create task definition
     */
    public static addSchedulerTaskDefinition(params: any) {
        return http.postJson('task-definition', params).then((response) => response.data);
    }

    /**
     * Create task run
     */
    public static addSchedulerTaskHistory(params: any) {
        return http.postJson('task-run', params).then((response) => response.data);
    }

    /**
     * Create device
     */
    public static addDevice(params: any) {
        return http.postJson('device', params).then((response) => response.data);
    }

    /**
     * Delete task definition
     */
    public static deleteSchedulerTaskDefinition(uuid: string) {
        return http.postJson(`task-definition/${uuid}/delete`).then((response) => response);
    }
}