import http from './http';

export default class ReleaseNotesApi {
    public static getReleaseNotes() {
        return http.get('release-notes').then((response) => response.data);
    }
    public static getReleaseNoteContent(noteId: string) {
        return http.get(`release-notes/${noteId}`).then((response) => response.data);
    }
}
