<template>
    <div
        :class="modifier"
        class="skeleton-bone"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SkeletonBone extends Vue {
    @Prop({ type: Boolean }) public avatar!: boolean;
    @Prop({ type: Boolean }) public textFull!: boolean;
    @Prop({ type: Boolean }) public textShort!: boolean;
    @Prop({ type: Boolean }) public full!: boolean;

    get modifier() {
        const prop = Object.entries(this.$props).find(([, value]) => value);
        return [prop?.[0]];
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-bone {
    width: 115px;
    height: 14px;
    background: $divider-gray;
    border-radius: 24px;
    user-select: none;

    &.avatar {
        height: 24px;
        width: 24px;
    }
    &.textFull {
        width: 100%;
    }
    &.textShort {
        width: 45px;
    }
    &.full {
        width: 100%;
        height: 100%;
    }
}
</style>
