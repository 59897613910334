<template>
    <div
        :class="[{
            'issue-type_inline': inline,
            'issue-type_passive': passive,
        }]"
        class="project-issue-type issue-type"
    >
        <CustomIssueTypeIcon
            v-if="issueType"
            :icon="issueType.icon"
            :icon-color="issueType.iconColor"
            @click="click"
        />
        <span
            :class="[{
                'issue-type-name_hoverable': hoverable,
            }]"
            class="issue-type-name amp-mask"
            @click="click"
        >
            <WsTruncateAuto
                :value="issueType.name"
                :tooltip="tooltip"
                :max-width="350"
            />
        </span>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import CustomIssueTypeIcon from '@/domain/issueType/components/CustomIssueTypeIcon.vue';
import { getIssueTypeIconPath } from '@/domain/issue/constants/IssueTypeIcons';
import { IssueType } from '@/domain/issueType/models/IssueType';

@Component({
    methods: {
        getIssueTypeIconPath,
    },
    components: {
        CustomIssueTypeIcon,
        IconSvg24,
        WsTooltip,
        WsTruncateAuto,
    },
})
export default class CustomIssueType extends Vue {
    @Prop({ type: Object, required: true }) public issueType!: IssueType;
    @Prop({ type: Boolean, default: false }) public inline!: boolean;
    @Prop({ type: Boolean, default: false }) public hoverable!: boolean;
    @Prop({ type: Boolean, default: false }) public passive!: boolean;
    @Prop({ type: Boolean, default: false }) public hideDescription!: boolean;

    @Emit()
    public click() {
        return this.issueType;
    }

    get tooltip() {
        return this.hideDescription
            ? `<b>${this.issueType.name}</b>`
            : `<b>${this.issueType.name}</b><br />${this.issueType.description}`;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/wsui/colors';
@import '@/styles/wsui/const';
@import '@/styles/variables';
@import '@/styles/tables';
@import '@/styles/mixins';

.issue-type {
  display: flex;
  align-items: center;
  cursor: pointer;

  ::v-deep {
      .svg-icon {
          margin: 0 4px 0 0;
      }
  }
}
.issue-type_inline {
    display: inline-flex;
}
.issue-type_passive {
    cursor: default;
}
.issue-type_passive .issue-type-name {
    cursor: default;
}

.issue-type-name {
    font-size: 14px;
    font-weight: 700;
    color: $color-solid-100;
}
.issue-type-name_hoverable:hover {
    color: $color-accent-100;
}
</style>
