import { render, staticRenderFns } from "./IssueComment.vue?vue&type=template&id=af82dfda&scoped=true"
import script from "./IssueComment.vue?vue&type=script&lang=ts"
export * from "./IssueComment.vue?vue&type=script&lang=ts"
import style0 from "./IssueComment.vue?vue&type=style&index=0&id=af82dfda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af82dfda",
  null
  
)

export default component.exports