<template>
    <v-chip
        :color="color"
        :text-color="Color.white"
        :class="{ 'tag-like': tagLike, pointer }"
        small
    >
        <span v-if="isAuto" class="autostatus custom-icon-issue-autostatus" />
        <span class="highlighting amp-mask">{{ $t(`IssueTracker.status.${status}`) }}</span>
    </v-chip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import { ColorService } from '@/services';
import { IssueStatusEnum } from '@/domain/issue/constants/IssueStatus';

@Component
export default class IssueStatusText extends Vue {
    @Prop({ required: true }) public status!: IssueStatusEnum;
    @Prop() public isAuto!: boolean;
    @Prop({ type: Boolean, default: false }) public tagLike!: boolean;
    @Prop({ type: Boolean }) public pointer!: boolean;

    public readonly Color = Color;

    get color() {
        return ColorService.getColorForField(this.status);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.autostatus {
    width: 14px;
    height: 14px;
}

.v-chip {
    &.tag-like {
        margin-left: 5px;
        border-radius: $border-radius-default;
        padding: 0 8px;
        height: 18px;
    }

    &.pointer {
        cursor: pointer;
    }
}
</style>
