import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { Module } from 'vuex';
import { IssuesGrouping } from '@/constants';
import LicenseApi, { ILicenseDashboardChartViewParams, ISetChartColorsParams } from '@/api/license.api';
import {
    NotFoundError,
} from '@/models';
import { getOperationId, notificationSuccess, SequentialRequests } from '@/services';
import {
    CachedRawChartData,
    isRawChartDataGuard,
    RawChartDataLine,
    RawChartDataUnion,
} from '@/domain/chart/models/ChartView';
import { DashboardChart, DashboardChartParams } from '@/domain/dashboard/models/DashboardChart';
import { LicenseDashboard } from '@/domain/dashboard/models/LicenseDashboard';
import { IChangeFavoriteDashboardParams } from '@/domain/dashboard/types/ChangeFavoriteDashboardParams';
import { NormalizeStatus } from '@/domain/dashboard/services/NormalizeStatus';
import { ChartType } from '@/domain/chart/constants/ChartType';
import { LegacyIssueStatusToCustomStatusName } from '@/domain/issue/constants/IssueStatus';

interface ILicenseDashboardsStorage {
    licenseDashboardsObj: {
        [licenseId: number]: LicenseDashboard[],
    };
    licenseDashboardChartsObj: {
        [dashboardUuid: string]: DashboardChart[],
    };
    fullyDownloadedLicenseDashboardChartsObj: {
        [dashboardUuid: string]: {
            [chartUuid: string]: boolean,
        },
    };
    licenseDashboardChartViewsObj: {
        [dashboardUuid: string]: {
            [chartUuid: string]: {
                cachedAt: number;
                graph: RawChartDataUnion;
            },
        },
    };
    licenseDashboardChartPreviewsObj: {
        [dashboardUuid: string]: {
            cachedAt: number;
            graph: RawChartDataUnion;
        },
    };
    selectedLicenseDashboardObj: {
        [licenseId: number]: LicenseDashboard,
    };
    isLoadingLicenseDashboardsObj: {
        [licenseId: number]: boolean,
    };
    isLoadingLicenseDashboardChartsObj: {
        [dashboardUuid: string]: boolean,
    };
    isLoadingLicenseDashboardChartViewsObj: {
        [dashboardUuid: string]: {
            [chartUuid: string]: boolean,
        },
    };
    isLoadingLicenseDashboardChartPreviewsObj: {
        [dashboardUuid: string]: boolean,
    };
    isSendingLicenseDashboard: boolean;
    isLoadingLicenseDashboardChartSettings: boolean;
    isSendingLicenseDashboardChartSettings: boolean;
    isSendingReorderLicenseDashboardCharts: boolean;
}

const replaceLegacyChartData = (cachedRawChartData: CachedRawChartData): CachedRawChartData => {
    const rawChartData: any = cachedRawChartData.graph;
    if (!isRawChartDataGuard(rawChartData)) {
        return cachedRawChartData;
    }

    const indexOfLegacyStatusGrouping = rawChartData.groupBy.indexOf(IssuesGrouping.status);

    if (indexOfLegacyStatusGrouping !== -1) {
        rawChartData.groupBy.splice(indexOfLegacyStatusGrouping, 1, IssuesGrouping.customStatus);
        rawChartData.resultItems = rawChartData.resultItems.map((item: any) => {
            const statusFieldValue = item.fields[IssuesGrouping.status];
            delete item.fields[IssuesGrouping.status];
            item.fields[IssuesGrouping.customStatus] = LegacyIssueStatusToCustomStatusName[statusFieldValue];
            return item;
        });
    }

    cachedRawChartData.graph = rawChartData;

    return cachedRawChartData;
};

export default {
    state: {
        licenseDashboardsObj: {},
        licenseDashboardChartsObj: {},
        fullyDownloadedLicenseDashboardChartsObj: {},
        licenseDashboardChartViewsObj: {},
        licenseDashboardChartPreviewsObj: {},
        selectedLicenseDashboardObj: {},
        isLoadingLicenseDashboardsObj: {},
        isLoadingLicenseDashboardChartsObj: {},
        isLoadingLicenseDashboardChartViewsObj: {},
        isLoadingLicenseDashboardChartPreviewsObj: {},
        isSendingLicenseDashboard: false,
        isLoadingLicenseDashboardChartSettings: false,
        isSendingLicenseDashboardChartSettings: false,
        isSendingReorderLicenseDashboardCharts: false,
        errorCount: 0,
        countLoadLicenseDashboardChartPreview: 0,
    } as ILicenseDashboardsStorage,
    getters: {
        licenseDashboardsByLicenseId(state: ILicenseDashboardsStorage): (licenseId: number) => LicenseDashboard[] {
            return (licenseId) => state.licenseDashboardsObj[licenseId] || [];
        },
        licenseDashboardByDashboardUuidAndLicenseId(state: ILicenseDashboardsStorage): ({ licenseId, dashboardUuid }: { licenseId: number; dashboardUuid: string }) => LicenseDashboard | undefined {
            return ({ licenseId, dashboardUuid }) => state.licenseDashboardsObj[licenseId]?.find(({ uuid }) => uuid === dashboardUuid);
        },
        licenseDashboardChartsByDashboardUuid(state: ILicenseDashboardsStorage): (dashboardUuid: string) => DashboardChart[] {
            return (dashboardUuid) => state.licenseDashboardChartsObj[dashboardUuid] || [];
        },
        licenseDashboardChartView(state: ILicenseDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string }) => { cachedAt: number; graph: RawChartDataUnion; } {
            return ({ dashboardUuid, chartUuid }) => NormalizeStatus
                .ofCachedRawChartData(state.licenseDashboardChartViewsObj[dashboardUuid]?.[chartUuid] || {});
        },
        licenseDashboardChartData(state: ILicenseDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string }) => DashboardChart {
            return ({ dashboardUuid, chartUuid }) => {
                const chart = state.licenseDashboardChartsObj[dashboardUuid]?.find(({ uuid }) => uuid === chartUuid);
                return chart || new DashboardChart();
            };
        },
        licenseDashboardChartPreview(state: ILicenseDashboardsStorage): (dashboardUuid: string) => { cachedAt: number; graph: RawChartDataUnion; } {
            return (dashboardUuid) => NormalizeStatus
                .ofCachedRawChartData(state.licenseDashboardChartPreviewsObj[dashboardUuid] || {});
        },
        selectedLicenseDashboardByLicenseId(state: ILicenseDashboardsStorage): (licenseId: number) => LicenseDashboard {
            return (licenseId) => state.selectedLicenseDashboardObj[licenseId] || {};
        },
        isLoadingLicenseDashboardsByLicenseId(state: ILicenseDashboardsStorage): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingLicenseDashboardsObj[licenseId] || false;
        },
        isLoadingLicenseDashboardChartsByDashboardUuid(state: ILicenseDashboardsStorage): (dashboardUuid: string) => boolean {
            return (dashboardUuid) => state.isLoadingLicenseDashboardChartsObj[dashboardUuid] || false;
        },
        isLoadingLicenseDashboardChartViews(state: ILicenseDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string }) => boolean {
            return ({ dashboardUuid, chartUuid }) => state.isLoadingLicenseDashboardChartViewsObj[dashboardUuid]?.[chartUuid] || false;
        },
        isLoadingLicenseDashboardChartPreviews(state: ILicenseDashboardsStorage): (dashboardUuid: string) => boolean {
            return (dashboardUuid) => state.isLoadingLicenseDashboardChartPreviewsObj[dashboardUuid] || false;
        },
        isSendingLicenseDashboard(state: ILicenseDashboardsStorage): boolean {
            return state.isSendingLicenseDashboard;
        },
        isLoadingLicenseDashboardChartSettings(state: ILicenseDashboardsStorage): boolean {
            return state.isLoadingLicenseDashboardChartSettings;
        },
        isSendingLicenseDashboardChartSettings(state: ILicenseDashboardsStorage): boolean {
            return state.isSendingLicenseDashboardChartSettings;
        },
        isSendingReorderLicenseDashboardCharts(state: ILicenseDashboardsStorage): boolean {
            return state.isSendingReorderLicenseDashboardCharts;
        },
        fullyDownloadedLicenseDashboardChartsObj(state: ILicenseDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string}) => boolean {
            return ({ dashboardUuid, chartUuid }) => state.fullyDownloadedLicenseDashboardChartsObj[dashboardUuid] && state.fullyDownloadedLicenseDashboardChartsObj[dashboardUuid][chartUuid];
        },
    },
    mutations: {
        setLicenseDashboards(state: ILicenseDashboardsStorage, { licenseId, dashboards }: { licenseId: number, dashboards: LicenseDashboard[] }) {
            Vue.set(state.licenseDashboardsObj, licenseId, dashboards);
        },
        setLicenseDashboardCharts(state: ILicenseDashboardsStorage, { dashboardUuid, charts }: { dashboardUuid: string, charts: DashboardChart[] }) {
            Vue.set(state.licenseDashboardChartsObj, dashboardUuid, charts);
        },
        setLicenseDashboardChartView(state: ILicenseDashboardsStorage, { dashboardUuid, chartUuid, rawChartData }: { dashboardUuid: string, chartUuid: string, rawChartData: any }) {
            const replacedRawChartData = replaceLegacyChartData(rawChartData);
            const rawChartDataObj = Object.assign(
                {},
                state.licenseDashboardChartViewsObj[dashboardUuid],
                { [chartUuid]: replacedRawChartData },
            );

            Vue.set(state.licenseDashboardChartViewsObj, dashboardUuid, rawChartDataObj);
        },
        setLicenseDashboardChartViewByDots(state: ILicenseDashboardsStorage, { dashboardUuid, chartUuid, rawChartData }: { dashboardUuid: string; chartUuid: string; rawChartData: any[] }) {
            const rawChartDataObj = _.cloneDeep(state.licenseDashboardChartViewsObj[dashboardUuid] || {});
            const linesArray = _.cloneDeep(rawChartDataObj[chartUuid].graph || []) as RawChartDataLine[];
            for (const newLine of rawChartData) {
                const indexOfLine = linesArray.findIndex((existingLine) => existingLine.uuid === newLine.uuid);
                if (indexOfLine === -1) {
                    linesArray.push(newLine);
                } else {
                    linesArray[indexOfLine].dots = _.unionBy(newLine.dots, linesArray[indexOfLine].dots, 'date');
                }
            }
            rawChartDataObj[chartUuid].graph = linesArray;
            Vue.set(state.licenseDashboardChartViewsObj, dashboardUuid, rawChartDataObj);
        },
        setLicenseDashboardChartPreview(state: ILicenseDashboardsStorage, { dashboardUuid, cachedRawChartData }: { dashboardUuid: string, cachedRawChartData: any }) {
            Vue.set(
                state.licenseDashboardChartPreviewsObj,
                dashboardUuid,
                replaceLegacyChartData(cachedRawChartData),
            );
        },
        setLicenseDashboardChartPreviewByDots(state: ILicenseDashboardsStorage, { dashboardUuid, rawChartData }: { dashboardUuid: string, rawChartData: any[] }) {
            const linesArray: any[] = _.cloneDeep(state.licenseDashboardChartPreviewsObj[dashboardUuid]?.graph || []) as RawChartDataLine[];
            for (const newLine of rawChartData) {
                const indexOfLine = linesArray.findIndex((existingLine) => existingLine.uuid === newLine.uuid);
                if (indexOfLine === -1) {
                    linesArray.push(newLine);
                } else {
                    linesArray[indexOfLine].dots = _.unionBy(newLine.dots, linesArray[indexOfLine].dots, 'date');
                }
            }
            const cachedRawChartData = {
                cachedAt: moment().unix(),
                graph: linesArray,
            } as CachedRawChartData;
            Vue.set(state.licenseDashboardChartPreviewsObj, dashboardUuid, cachedRawChartData);
        },
        setSelectedLicenseDashboard(state: ILicenseDashboardsStorage, { licenseId, dashboard }: { licenseId: number, dashboard: LicenseDashboard }) {
            Vue.set(state.selectedLicenseDashboardObj, licenseId, dashboard);
        },
        setIsLoadingLicenseDashboards(state: ILicenseDashboardsStorage, { licenseId, value }: { licenseId: number; value: boolean }) {
            Vue.set(state.isLoadingLicenseDashboardsObj, licenseId, value);
        },
        setIsLoadingLicenseDashboardCharts(state: ILicenseDashboardsStorage, { dashboardUuid, value }: { dashboardUuid: string; value: boolean }) {
            Vue.set(state.isLoadingLicenseDashboardChartsObj, dashboardUuid, value);
        },
        setIsLoadingLicenseDashboardChartViews(state: ILicenseDashboardsStorage, { dashboardUuid, chartUuid, value }: { dashboardUuid: string; chartUuid: string, value: boolean }) {
            const isLoadingChartViewObj = Object.assign({}, state.isLoadingLicenseDashboardChartViewsObj[dashboardUuid], { [chartUuid]: value });
            Vue.set(state.isLoadingLicenseDashboardChartViewsObj, dashboardUuid, isLoadingChartViewObj);
        },
        setIsLoadingLicenseDashboardChartPreview(state: ILicenseDashboardsStorage, { dashboardUuid, value }: { dashboardUuid: string; value: boolean }) {
            Vue.set(state.isLoadingLicenseDashboardChartPreviewsObj, dashboardUuid, value);
        },
        setIsSendingLicenseDashboard(state: ILicenseDashboardsStorage, value: boolean) {
            state.isSendingLicenseDashboard = value;
        },
        setIsLoadingLicenseDashboardChartSettings(state: ILicenseDashboardsStorage, value: boolean) {
            state.isLoadingLicenseDashboardChartSettings = value;
        },
        setIsSendingLicenseDashboardChartSettings(state: ILicenseDashboardsStorage, value: boolean) {
            state.isSendingLicenseDashboardChartSettings = value;
        },
        setIsSendingReorderLicenseDashboardCharts(state: ILicenseDashboardsStorage, value: boolean) {
            state.isSendingReorderLicenseDashboardCharts = value;
        },
        setFullyDownloadedLicenseDashboardChartsObj(state: ILicenseDashboardsStorage, { dashboardUuid, chartUuid, status }: { dashboardUuid: string, chartUuid: string, status: boolean }) {
            if (!state.fullyDownloadedLicenseDashboardChartsObj[dashboardUuid]) {
                state.fullyDownloadedLicenseDashboardChartsObj[dashboardUuid] = {};
            }
            state.fullyDownloadedLicenseDashboardChartsObj[dashboardUuid][chartUuid] = status;
            state.fullyDownloadedLicenseDashboardChartsObj = _.cloneDeep(state.fullyDownloadedLicenseDashboardChartsObj);
        },
        updateLicenseDashboard(state: ILicenseDashboardsStorage, { licenseId, licenseDashboard }: { licenseId: number, licenseDashboard: LicenseDashboard }) {
            const dashboards = state.licenseDashboardsObj[licenseId];
            const index = dashboards.findIndex(({ uuid }) => uuid === licenseDashboard.uuid);
            if (index !== -1) {
                dashboards[index] = licenseDashboard;
            } else {
                dashboards.push(licenseDashboard);
            }

            Vue.set(state.licenseDashboardsObj, licenseId, dashboards);
        },
        updateLicenseDashboardChart(state: ILicenseDashboardsStorage, { dashboardUuid, chartUuid, dashboardChart }: {
            dashboardUuid: string;
            chartUuid: string;
            dashboardChart: DashboardChartParams;
        }) {
            const charts = state.licenseDashboardChartsObj[dashboardUuid];
            const index = charts.findIndex(({ uuid }) => uuid === chartUuid);
            if (index !== -1) {
                charts[index] = new DashboardChart(dashboardChart);
            } else {
                charts.push(new DashboardChart(dashboardChart));
            }
            Vue.set(state.licenseDashboardChartsObj, dashboardUuid, charts);
        },
    },
    actions: {
        async loadLicenseDashboards({ getters, commit, dispatch }: any, { licenseId, dashboardUuid, isForce = false }: { licenseId: number, dashboardUuid: string, isForce: boolean }): Promise<LicenseDashboard[]> {
            let licenseDashboards = getters.licenseDashboardsByLicenseId(licenseId);
            let selectedDashboardUuid = getters.selectedLicenseDashboardByLicenseId(licenseId).uuid;
            if (isForce || !licenseDashboards.length)  {
                commit('setIsLoadingLicenseDashboards', { licenseId, value: true });
                const response = await LicenseApi.getLicenseDashboards(licenseId)
                    .finally(() => commit('setIsLoadingLicenseDashboards', { licenseId, value: false }));
                licenseDashboards = response.entities.map((licenseDashboard: any) => {
                    if (response.favorite?.includes(licenseDashboard.uuid)) {
                        licenseDashboard.isFavorite = true;
                    }
                    return new LicenseDashboard(licenseDashboard);
                });
                commit('setLicenseDashboards', { licenseId, dashboards: licenseDashboards });
                selectedDashboardUuid = dashboardUuid || response.selected || selectedDashboardUuid;
            }

            if (dashboardUuid) {
                const dashboardFromUrl = licenseDashboards.find((licenseDashboard: LicenseDashboard) => licenseDashboard.uuid === dashboardUuid);
                if (!dashboardFromUrl) {
                    throw NotFoundError.dashboard();
                }
            }

            const dashboardSelected = licenseDashboards.find((licenseDashboard: LicenseDashboard) => licenseDashboard.uuid === selectedDashboardUuid);
            const dashboardUuidToSelect = dashboardSelected ? dashboardSelected.uuid : '';
            const defaultDashboardUuidToSelect = licenseDashboards.length ? licenseDashboards[0].uuid : '';
            selectedDashboardUuid = dashboardUuidToSelect || defaultDashboardUuidToSelect;
            await dispatch('selectLicenseDashboard', { licenseId, dashboardUuid: selectedDashboardUuid });
            return licenseDashboards;
        },
        selectLicenseDashboard({ getters, commit, dispatch }: any, { licenseId, dashboardUuid }: { licenseId: number, dashboardUuid?: string }): void | Promise<void> {
            const dashboards = getters.licenseDashboardsByLicenseId(licenseId);
            // если не передан uuid дашборда, то выберется первый в списке, если список не пустой
            if (!dashboardUuid && dashboards.length) {
                dashboardUuid = dashboards[0].uuid;
            }

            // мутация setSelectedProjectDashboard сделана вне callback'а запроса, чтобы не было задержки;
            // ничего критичного не произойдет, если при переключении дашборда не сохранится информация о выбранном дашборде, при неуспешном запросе
            const dashboard = dashboards.find((licenseDashboard: LicenseDashboard) => licenseDashboard.uuid === dashboardUuid);
            if (dashboardUuid && !dashboard) {
                throw NotFoundError.dashboard();
            }

            commit('setSelectedLicenseDashboard', { licenseId, dashboard });

            if (dashboardUuid) {
                return dispatch('loadLicenseDashboardCharts', { licenseId, dashboardUuid });
            }
        },
        saveLicenseDashboard({ commit, dispatch }: any, { licenseId, dashboard }: { licenseId: number, dashboard: LicenseDashboard }): Promise<LicenseDashboard> {
            return new Promise((resolve, reject) => {
                const request = dashboard.uuid
                    ? LicenseApi.postUpdateLicenseDashboard(licenseId, dashboard.uuid, dashboard.apiParams)
                    : LicenseApi.postCreateLicenseDashboard(licenseId, dashboard.apiParams);

                commit('setIsSendingLicenseDashboard', true);
                request.then((response) => {
                    notificationSuccess('allSaved');
                    const licenseDashboard = new LicenseDashboard(response.entity);
                    commit('updateLicenseDashboard', { licenseId, licenseDashboard });
                    dispatch('selectLicenseDashboard', { licenseId, dashboardUuid: licenseDashboard.uuid });
                    resolve(licenseDashboard);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingLicenseDashboard', false);
                });
            });
        },
        deleteLicenseDashboard({ state, commit, dispatch }: any, { licenseId, dashboard }: { licenseId: number, dashboard: LicenseDashboard }): Promise<void> {
            return new Promise((resolve, reject) => {
                commit('setIsSendingLicenseDashboard', true);
                LicenseApi.postDeleteLicenseDashboard(licenseId, dashboard.uuid).then(() => {
                    notificationSuccess('dashboardDeleted');
                    const dashboards = state.licenseDashboardsObj[licenseId];
                    const index = dashboards.findIndex((dashboard1: LicenseDashboard) => dashboard1.uuid === dashboard.uuid);
                    dashboards.splice(index, 1);
                    commit('setLicenseDashboards', { licenseId, dashboards });
                    dispatch('selectLicenseDashboard', { licenseId });
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingLicenseDashboard', false);
                });
            });
        },
        loadLicenseDashboardCharts({ state, commit }: any, { licenseId, dashboardUuid }: { licenseId: number, dashboardUuid: string }): Promise<DashboardChart[]> {
            return new Promise((resolve, reject) => {
                if (!dashboardUuid) {
                    resolve([]);
                    return;
                }
                if (state.licenseDashboardChartsObj[dashboardUuid]) {
                    resolve(state.licenseDashboardChartsObj[dashboardUuid]);
                    return;
                }
                if (state.isLoadingLicenseDashboardChartsObj[dashboardUuid]) {
                    resolve([]);
                    return;
                }

                commit('setIsLoadingLicenseDashboardCharts', { dashboardUuid, value: true });
                LicenseApi.getLicenseDashboardCharts(licenseId, dashboardUuid).then((response) => {
                    const charts = response.entities.map((chart: any) => new DashboardChart(chart));
                    commit('setLicenseDashboardCharts', { dashboardUuid, charts });
                    resolve(charts);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingLicenseDashboardCharts', { dashboardUuid, value: false });
                });
            });
        },
        loadLicenseDashboardChartSettings({ commit, getters }: any, { licenseId, dashboardUuid, chartUuid }: { licenseId: number, dashboardUuid: string, chartUuid: string }): Promise<any> {
            return new Promise((resolve, reject) => {
                if (getters.fullyDownloadedLicenseDashboardChartsObj({ dashboardUuid, chartUuid })) {
                    resolve(getters.licenseDashboardChartData({ dashboardUuid, chartUuid }));
                    return;
                }

                commit('setIsLoadingLicenseDashboardChartSettings', true);
                LicenseApi.getLicenseDashboardChartSettings(licenseId, dashboardUuid, chartUuid).then((response) => {
                    const dashboardChart = new DashboardChart(response.entity);

                    commit('updateLicenseDashboardChart', { dashboardUuid, chartUuid, dashboardChart });
                    commit('setFullyDownloadedLicenseDashboardChartsObj', { dashboardUuid, chartUuid, status: true });
                    resolve(dashboardChart);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingLicenseDashboardChartSettings', false);
                });
            });
        },
        saveLicenseDashboardChart({ state, commit, dispatch }: any, { licenseId, dashboardUuid, dashboardChart }: { licenseId: number, dashboardUuid: string, dashboardChart: DashboardChart }): Promise<DashboardChart> {
            return new Promise((resolve, reject) => {
                const request = dashboardChart.uuid
                    ? LicenseApi.postUpdateLicenseDashboardChart(licenseId, dashboardUuid, dashboardChart.uuid, dashboardChart.apiParams)
                    : LicenseApi.postCreateLicenseDashboardChart(licenseId, dashboardUuid, dashboardChart.apiParams);

                commit('setIsSendingLicenseDashboardChartSettings', true);
                request.then((response) => {
                    notificationSuccess('allSaved');
                    const chart = new DashboardChart(response.entity);
                    const charts = state.licenseDashboardChartsObj[dashboardUuid];
                    const index = charts.findIndex((chart1: DashboardChart) => chart1.uuid === chart.uuid);

                    if (index !== -1) {
                        dispatch('loadLicenseDashboardChartView', { licenseId, dashboardUuid, chartUuid: chart.uuid, isForce: true });
                        charts.splice(index, 1);
                    }
                    charts.push(chart);
                    commit('setLicenseDashboardCharts', { dashboardUuid, charts });
                    resolve(chart);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingLicenseDashboardChartSettings', false);
                });
            });
        },
        cloneLicenseDashboardChart({ state, commit }: any, { licenseId, dashboardUuid, chartUuid }: any): Promise<DashboardChart | void> {
            return new Promise((resolve, reject) => {
                LicenseApi.postCloneLicenseDashboardChart(licenseId, dashboardUuid, chartUuid).then((response) => {
                    const charts = [...state.licenseDashboardChartsObj[dashboardUuid], new DashboardChart(response.entity)];
                    commit('setLicenseDashboardCharts', { dashboardUuid, charts });
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        deleteLicenseDashboardChart({ state, commit }: any, { licenseId, dashboardUuid, chartUuid }: { licenseId: number, dashboardUuid: string, chartUuid: string }): Promise<void> {
            return new Promise((resolve, reject) => {
                commit('setIsSendingLicenseDashboardChartSettings', true);
                LicenseApi.postDeleteLicenseDashboardChart(licenseId, dashboardUuid, chartUuid).then(() => {
                    notificationSuccess('chartDeleted');
                    const charts = state.licenseDashboardChartsObj[dashboardUuid];
                    const index = charts.findIndex((chart: DashboardChart) => chart.uuid === chartUuid);
                    charts.splice(index, 1);
                    commit('setLicenseDashboardCharts', { dashboardUuid, charts });
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingLicenseDashboardChartSettings', false);
                });
            });
        },
        loadLicenseDashboardChartView({ state, commit, dispatch }: any, { licenseId, dashboardUuid, chartUuid, isCachedData = true, isForce = false }: ILicenseDashboardChartViewParams): Promise<CachedRawChartData | void> {
            return new Promise((resolve) => {
                // если данные уже загружены, то отдаем их без запроса
                if (!isForce && state.licenseDashboardChartViewsObj[dashboardUuid]?.[chartUuid]) {
                    resolve(state.licenseDashboardChartViewsObj[dashboardUuid][chartUuid]);
                    return;
                }
                // если произошел повторный запрос, до того как выполнился предыдущий, то не выполняем его
                if (state.isLoadingLicenseDashboardChartViewsObj[dashboardUuid]?.[chartUuid]) {
                    resolve();
                    return;
                }

                commit('setIsLoadingLicenseDashboardChartViews', { dashboardUuid, chartUuid, value: true });
                LicenseApi.getLicenseDashboardChartView({
                    licenseId, dashboardUuid, chartUuid, isCachedData,
                }).then((rawChartData) => {
                    commit('setLicenseDashboardChartView', { dashboardUuid, chartUuid, rawChartData });
                    resolve(rawChartData);
                }).catch(() => {
                    commit('setLicenseDashboardChartView', { dashboardUuid, chartUuid, rawChartData: [] });
                    return dispatch('loadLicenseDashboardChartViewByDots', { licenseId, dashboardUuid, chartUuid });
                }).finally(() => {
                    commit('setIsLoadingLicenseDashboardChartViews', { dashboardUuid, chartUuid, value: false });
                });
            });
        },
        async loadLicenseDashboardChartViewByDots({ commit, dispatch }: any, { licenseId, dashboardUuid, dashboardChart }: { licenseId: number, dashboardUuid: string, dashboardChart: DashboardChart }): Promise<any> {
            const chartUuid = dashboardChart.uuid;
            const toDateArray = await dispatch('loadLicenseDashboardTimelineDots', { licenseId, dashboardUuid, dashboardChart });
            const promises: Promise<any>[] = [];
            for (const toDate of toDateArray) {
                const makeApiRequest = () => LicenseApi.getLicenseDashboardChartView({ licenseId, dashboardUuid, chartUuid, toDate })
                    .then((rawChartData) => commit('setLicenseDashboardChartViewByDots', { dashboardUuid, chartUuid, rawChartData }));
                const promise = makeApiRequest().catch(() => setTimeout(makeApiRequest, 1000));
                promises.push(promise);
            }
            return Promise.all(promises);
        },
        loadLicenseDashboardChartPreview({ state, commit, dispatch }: any, { licenseId, dashboardUuid, dashboardChart }: { licenseId: number, dashboardUuid: string, dashboardChart: DashboardChart }): Promise<any> {
            commit('setIsLoadingLicenseDashboardChartPreview', { dashboardUuid, value: true });
            return SequentialRequests.tick(LicenseApi.getLicenseDashboardChartPreview, {
                licenseId,
                dashboardUuid,
                params: dashboardChart.apiParams,
            }).then((rawChartData) => {
                const cachedRawChartData = {
                    cachedAt: moment().unix(),
                    graph: rawChartData,
                } as CachedRawChartData;

                commit('setLicenseDashboardChartPreview', { dashboardUuid, cachedRawChartData });
                return rawChartData;
            }).catch(() => {
                if (dashboardChart.type === ChartType.timeLine) {
                    commit('setLicenseDashboardChartPreview', { dashboardUuid, cachedRawChartData: {} });
                    return dispatch('loadLicenseDashboardChartPreviewByDots', { licenseId, dashboardUuid, dashboardChart, errorCount: ++state.errorCount });
                }
            }).finally(() => {
                commit('setIsLoadingLicenseDashboardChartPreview', { dashboardUuid, value: false });
            });
        },
        async loadLicenseDashboardChartPreviewByDots({ state, commit, dispatch }: any, { licenseId, dashboardUuid, dashboardChart, errorCount }: { licenseId: number, dashboardUuid: string, dashboardChart: DashboardChart, errorCount: number }): Promise<any> {
            const toDateArray = await dispatch('loadLicenseDashboardTimelineDots', { licenseId, dashboardUuid, dashboardChart });
            const promises: Promise<any>[] = [];
            for (const toDate of toDateArray) {
                const makeApiRequest = () => LicenseApi.getLicenseDashboardChartPreview({
                    licenseId,
                    dashboardUuid,
                    params: { ...dashboardChart.apiParams, toDate },
                }).then((rawChartData) => {
                    if (errorCount === state.errorCount) {
                        commit('setLicenseDashboardChartPreviewByDots', { dashboardUuid, rawChartData });
                    }
                });
                const promise = makeApiRequest().catch(() => setTimeout(makeApiRequest, 1000));
                promises.push(promise);
            }
            return Promise.all(promises);
        },
        loadLicenseDashboardTimelineDots(context: any, { licenseId, dashboardUuid, dashboardChart }: { licenseId: number, dashboardUuid: string, dashboardChart: DashboardChart }): Promise<number[]> {
            return LicenseApi.getLicenseDashboardTimelineDots(licenseId, dashboardUuid, dashboardChart.apiParams);
        },
        changeOrderLicenseDashboardCharts({ state, commit }: any, { licenseId, dashboardUuid, params }: { licenseId: number, dashboardUuid: string, params: any }): Promise<any> {
            commit('setIsSendingReorderLicenseDashboardCharts', true);
            return LicenseApi.postChangeOrderLicenseDashboardCharts(licenseId, dashboardUuid, params).then((response) => {
                notificationSuccess('allSaved');
                const licenseDashboard = new LicenseDashboard(response);

                const dashboards = state.licenseDashboardsObj[licenseId];
                const index =  dashboards.findIndex((dashboard1: LicenseDashboard) => dashboard1.uuid === licenseDashboard.uuid);
                if (index !== -1) {
                    dashboards.splice(index, 1, licenseDashboard);
                } else {
                    dashboards.push(licenseDashboard);
                }

                commit('setLicenseDashboards', { licenseId, dashboards });
                commit('setSelectedLicenseDashboard', { licenseId, dashboard: licenseDashboard });
            }).finally(() => {
                commit('setIsSendingReorderLicenseDashboardCharts', false);
            });
        },
        sendLicenseDashboardChart(context: any, { licenseId, dashboardUuid, chartUuid, params }: any) {
            params = _.omit(params, ['biggerImages']);
            const operationId = getOperationId();
            return LicenseApi.postSendChartToEmail(licenseId, dashboardUuid, chartUuid, {
                ...params,
                operationId,
            }).then(() => {
                notificationSuccess('fileBeingProcessedChart');
            });
        },
        sendLicenseChartColors({ state, commit }: any, { licenseId, dashboardUuid, chartUuid, params }:
            { licenseId: number; dashboardUuid: string; chartUuid: string; params: ISetChartColorsParams}) {
            commit('setIsLoadingLicenseDashboardChartViews', { dashboardUuid, chartUuid, value: true });

            return LicenseApi.postLicenseChartColors(licenseId, dashboardUuid, chartUuid, params).then(() => {
                const charts = state.licenseDashboardChartsObj[dashboardUuid];
                const index = charts.findIndex((chart: DashboardChart) => chart.uuid === chartUuid);

                if (index !== -1) {
                    let [chart] = charts.splice(index, 1);
                    chart = Object.assign(chart, params);
                    charts.push(chart);
                    commit('setLicenseDashboardCharts', { dashboardUuid, charts });
                }
            }).finally(() => {
                commit('setIsLoadingLicenseDashboardChartViews', { dashboardUuid, chartUuid, value: false });
            });
        },
        saveLicenseChartSize(context: any, { licenseId, dashboardUuid, chartUuid, params }:
            { licenseId: number; dashboardUuid: string; chartUuid: string; params: { size: number } }) {
            return LicenseApi.postLicenseChartSize(licenseId, dashboardUuid, chartUuid, params);
        },
        sendLicenseDashboardChartDeliverySettings({ commit }: any, { licenseId, dashboardUuid, chartUuid, params }: any) {
            params = _.omit(params, ['biggerImages']);
            return LicenseApi.postUpdateChartDeliverySettings(licenseId, dashboardUuid, chartUuid, params).then((response) => {
                notificationSuccess('allSaved');
                if (response.entity) {
                    commit('updateLicenseDashboardChart', { dashboardUuid, chartUuid, dashboardChart: response.entity });
                }
            });
        },
        sendLicenseDashboard(context: any, { licenseId, dashboardUuid, params }: any) {
            const operationId = getOperationId();
            return LicenseApi.postDashboardSendToEmail(licenseId, dashboardUuid, { ...params, operationId }).then(() => {
                notificationSuccess('fileBeingProcessedDashboard');
            });
        },
        sendLicenseDashboardDeliverySettings({ commit, dispatch }: any, { licenseId, dashboardUuid, params }: any) {
            return LicenseApi.postDashboardSetDelivery(licenseId, dashboardUuid, params).then((response) => {
                notificationSuccess('allSaved');
                const licenseDashboard = new LicenseDashboard(response.entity);
                commit('updateLicenseDashboard', { licenseId, licenseDashboard });
                dispatch('selectLicenseDashboard', { licenseId, dashboardUuid: licenseDashboard.uuid });
            });
        },
        sendAddFavoriteLicenseDashboard(context: any, { licenseId, params }: { licenseId: string, params: IChangeFavoriteDashboardParams }) {
            return LicenseApi.postAddFavoriteProjectDashboards({ licenseId, params });
        },
        sendRemoveFavoriteLicenseDashboard(context: any, { licenseId, params }: { licenseId: string, params: IChangeFavoriteDashboardParams }) {
            return LicenseApi.postRemoveFavoriteProjectDashboards({ licenseId, params });
        },
    },
} as Module<ILicenseDashboardsStorage, any>;
