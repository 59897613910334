import { render, staticRenderFns } from "./WsDialog.vue?vue&type=template&id=805a9984&scoped=true"
import script from "./WsDialog.vue?vue&type=script&lang=ts"
export * from "./WsDialog.vue?vue&type=script&lang=ts"
import style0 from "./WsDialog.vue?vue&type=style&index=0&id=805a9984&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805a9984",
  null
  
)

export default component.exports