import { render, staticRenderFns } from "./WsSelectColorItem.vue?vue&type=template&id=635b1aeb&scoped=true"
import script from "./WsSelectColorItem.vue?vue&type=script&lang=ts"
export * from "./WsSelectColorItem.vue?vue&type=script&lang=ts"
import style0 from "./WsSelectColorItem.vue?vue&type=style&index=0&id=635b1aeb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635b1aeb",
  null
  
)

export default component.exports