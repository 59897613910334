<template>
    <div
        :style="{ width: width ?`${width}px` : null, height: height ?`${height}px` : null }"
        class="ws-image-component"
    >
        <template v-if="!isError">
            <WsSkeletonLoader
                v-if="isLoading"
                content="simple"
            />
            <img
                v-show="!isLoading"
                :src="src"
                :id="imageId"
                :ref="reference"
                class="ws-image amp-block"
                @load="load"
                @error="error"
                @click="click"
            >
        </template>
        <div v-if="isError" class="ws-image-error-wrapper">
            <div class="ws-image-error ws-image">
                <div class="error-background" />
            </div>
            <div class="error-button" @click="reload">{{ $t('Simple_word.reload') }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';

@Component({
    components: {
        WsSkeletonLoader,
    },
})
export default class WsImage extends Vue {
    @Prop({ type: String, required: false }) public src!: string;
    @Prop({ type: String, required: false }) public id!: string;
    @Prop({ type: String, required: false }) public reference !: string;
    @Prop({ type: Number }) public width !: number;
    @Prop({ type: Number }) public height !: number;

    public isLoading = true;
    public element: any = null;
    public isError = false;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get imageId(): string {
        return 'ws-image-' + (this.id ?? uuid());
    }

    get s3enabled(): boolean {
        return this.$store.getters.s3enabled;
    }

    @Emit()
    public load() {
        return;
    }

    @Emit()
    public error() {
        return;
    }

    @Emit()
    public click() {
        if (!this.isError) {
            return;
        }
    }

    @Watch('src')
    public onSourceChange(source: string) {
        this.isLoading = true;
        this.setImage(source);
    }

    public async mounted() {
        this.element = document.querySelector('#' + this.imageId) as HTMLImageElement;
        if (this.src) {
            await this.setImage(this.src);
        }
    }

    public async downloadImage(element: HTMLImageElement, source: string) {
        try {
            if (!element) {
                return;
            }

            const base64 = await this.$store.getters.s3InstanceByProjectId(this.projectId).s3loadImageInBase64(source);
            if (base64) {
                element.src = base64;
            } else {
                this.isError = true;
            }

            this.isLoading = false;
        } catch (_error) {
            this.isError = true;
        }
    }

    public async setImage(source: string) {
        if (this.s3enabled) {
            await this.downloadImage(this.element, source);
        } else {
            this.isLoading = false;
        }
    }

    public async reload() {
        this.isError = false;
        await this.setImage(this.src);
    }

}
</script>

<style lang="scss" scoped>
    @import '@/styles/variables.scss';

    .ws-image-component {
        height: 100%;
        width: 100%;

        .skeleton {
            height: 100%;
        }

        .ws-image {
            max-width: inherit;
        }
    }

    .hide {
        display: none;
    }

    .ws-image-error-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        position: relative;
        z-index: 1;
        cursor: default;

        &:hover {
            .ws-image {
                filter: brightness($image-hover-opacity);
                transition: all .2s ease;
            }

            .error-button {
                display: block;
            }
        }

    }

    .ws-image-error {
        border: solid 1px $divider-gray;
        background: $extra-light;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        padding: 12px;

        .error-background {
            width: 182px;
            height: 84px;
            background: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTAyIiBoZWlnaHQ9Ijg0IiB2aWV3Qm94PSIwIDAgMTAyIDg0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMjkgMzNMMzAuNDE0MiAzMS41ODU4QzI5LjYzMzIgMzAuODA0NyAyOC4zNjY4IDMwLjgwNDcgMjcuNTg1OCAzMS41ODU4TDI5IDMzWk02MiA2Nkw2MC41ODU4IDY3LjQxNDJDNjEuMzQ5MSA2OC4xNzc1IDYyLjU4MDQgNjguMTk3NCA2My4zNjc5IDY3LjQ1OTFMNjIgNjZaTTc4IDUxTDc5LjQ0NTMgNDkuNjE3NkM3OC42ODcyIDQ4LjgyNSA3Ny40MzIyIDQ4Ljc5MDggNzYuNjMyMSA0OS41NDA5TDc4IDUxWk0wIDNWODFINFYzSDBaTTMgODRIOTlWODBIM1Y4NFpNMTAyIDgxVjNIOThWODFIMTAyWk05OSAwSDNWNEg5OVYwWk0zLjQxNDIxIDYxLjQxNDJMMzAuNDE0MiAzNC40MTQyTDI3LjU4NTggMzEuNTg1OEwwLjU4NTc4NiA1OC41ODU4TDMuNDE0MjEgNjEuNDE0MlpNMjcuNTg1OCAzNC40MTQyTDYwLjU4NTggNjcuNDE0Mkw2My40MTQyIDY0LjU4NThMMzAuNDE0MiAzMS41ODU4TDI3LjU4NTggMzQuNDE0MlpNNjMuMzY3OSA2Ny40NTkxTDc5LjM2NzkgNTIuNDU5MUw3Ni42MzIxIDQ5LjU0MDlMNjAuNjMyMSA2NC41NDA5TDYzLjM2NzkgNjcuNDU5MVpNNzYuNTU0NyA1Mi4zODI0TDk4LjU1NDcgNzUuMzgyNEwxMDEuNDQ1IDcyLjYxNzZMNzkuNDQ1MyA0OS42MTc2TDc2LjU1NDcgNTIuMzgyNFpNNzYgMThDNzEuMDI5NCAxOCA2NyAyMi4wMjk0IDY3IDI3SDcxQzcxIDI0LjIzODYgNzMuMjM4NiAyMiA3NiAyMlYxOFpNNjcgMjdDNjcgMzEuOTcwNiA3MS4wMjk0IDM2IDc2IDM2VjMyQzczLjIzODYgMzIgNzEgMjkuNzYxNCA3MSAyN0g2N1pNNzYgMjJDNzguNzYxNCAyMiA4MSAyNC4yMzg2IDgxIDI3SDg1Qzg1IDIyLjAyOTQgODAuOTcwNiAxOCA3NiAxOFYyMlpNODEgMjdDODEgMjkuNzYxNCA3OC43NjE0IDMyIDc2IDMyVjM2QzgwLjk3MDYgMzYgODUgMzEuOTcwNiA4NSAyN0g4MVpNMTAyIDNDMTAyIDEuMzQzMTQgMTAwLjY1NyAwIDk5IDBWNEM5OC40NDc3IDQgOTggMy41NTIyOSA5OCAzSDEwMlpNOTkgODRDMTAwLjY1NyA4NCAxMDIgODIuNjU2OSAxMDIgODFIOThDOTggODAuNDQ3NyA5OC40NDc3IDgwIDk5IDgwVjg0Wk0wIDgxQzAgODIuNjU2OSAxLjM0MzE1IDg0IDMgODRWODBDMy41NTIyOCA4MCA0IDgwLjQ0NzcgNCA4MUgwWk00IDNDNCAzLjU1MjI4IDMuNTUyMjkgNCAzIDRWMEMxLjM0MzE0IDAgMCAxLjM0MzE1IDAgM0g0WiIgZmlsbD0iI0RFREVERSIvPgo8L3N2Zz4K') center center no-repeat;
        }

    }

    .error-button {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        margin-left: -37px;
        margin-top: -20px;
        border: solid 1px white;
        color: white;
        padding: 8px 16px;
        border-radius: 4px;
        line-height: 20px;
        font-size: 14px;
        z-index: 10;
        display: none;
        cursor: pointer;
    }
</style>
