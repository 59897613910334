import http from '@/api/http';
import { ISetChartColorsParams } from '@/api/license.api';
import { DashboardChartParams } from '@/domain/dashboard/models/DashboardChart';
import { IProjectDashboardChartViewParams } from '@/domain/chart/types/chart';
import { CachedRawChartData, RawChartData, RawChartDataLine } from '@/domain/chart/models/ChartView';

export default class ChartApi {
    /**
     * Получение списка графиков дашборда
     */
    public static getProjectDashboardCharts(projectId: number, dashboardUuid: string) {
        return http.get(`project/${projectId}/dashboard/${dashboardUuid}/graphs`).then((response) => response.data);
    }

    /**
     * Получение всех данных графика
     */
    public static getProjectDashboardChartSettings(projectId: number, dashboardUuid: string, chartUuid: string) {
        return http.get(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/view`).then((response) => response.data);
    }

    /**
     * Создание нового графика проектного дашборда
     */
    public static postCreateProjectDashboardChart(projectId: number, dashboardUuid: string, params: any): Promise<{ entity: DashboardChartParams }> {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/add-graph`, params).then((response) => response.data);
    }

    /**
     * Сохранение параметров графика проектного дашборда
     */
    public static postUpdateProjectDashboardChart(projectId: number, dashboardUuid: string, chartUuid: string, params: any): Promise<{ entity: DashboardChartParams }> {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/edit`, params).then((response) => response.data);
    }

    /**
     * Клонирование графика проектного дашборда
     */
    public static postCloneProjectDashboardChart(projectId: number, dashboardUuid: string, chartUuid: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/clone`).then((response) => response.data);
    }

    /**
     * Удаление графика проектного дашборда
     */
    public static postDeleteProjectDashboardChart(projectId: number, dashboardUuid: string, chartUuid: string) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/delete`).then((response) => response.data);
    }

    /**
     * Получение данных графика необходимых для его отрисовки
     */
    public static getProjectDashboardChartView(
        { projectId, dashboardUuid, chartUuid, isCachedData }: IProjectDashboardChartViewParams,
    ): Promise<CachedRawChartData> {
        const params = { force: Number(!isCachedData) };
        return http.get(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/data`, params).then((response) => response.data);
    }

    /**
     * Получение данных графика необходимых для его отрисовки на основе заданных параметров
     */
    public static getProjectDashboardChartPreview(
        { projectId, dashboardUuid, params }: { projectId: number, dashboardUuid: string, params: any },
    ): Promise<RawChartData | RawChartDataLine> {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/preview`, params).then((response) => response.data);
    }

    /**
     * Единоразовая отправка графика на любое количество email
     */
    public static postSendChartToEmail(projectId: number, dashboardUuid: string, chartUuid: string, params: any) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/send-to-email`, params).then((response) => response.data);
    }

    /** Сохранение цветов чарта */
    public static postProjectChartColors(projectId: number, dashboardUuid: string, chartUuid: string, params: ISetChartColorsParams) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/colors`, params).then((response) => response.data);
    }

    /** Сохранение размера чарта */
    public static postProjectChartSize(projectId: number, dashboardUuid: string, chartUuid: string, params: { size: number; }) {
        return http.post(`project/${projectId}/dashboard/${dashboardUuid}/graph/${chartUuid}/size`, params).then((response) => response.data);
    }
}
