import _ from 'lodash';
import axios from 'axios';
import Qs from 'qs';
// @ts-ignore
import sha1 from 'crypto-js/sha1'; // TODO найти либу на TS
import { IUserInfo } from '@/types/UserInfo';
import { Dict } from '@/types/Dict';
import BaseHttp from '@/api/baseHttp';
import { httpParams } from '@/api/httpParams';
import { getDefaultUserInfo } from '@/services/getDefaultUserInfo';

interface IParams extends Dict {
    version: string;
    device_id?: string;
    nonce?: string;
    signature?: string;
}

export default class Http extends BaseHttp {
    private static user: IUserInfo = getDefaultUserInfo();
    public static setUser(user: IUserInfo) {
        Http.user = user;
    }

    public static getUserInfo() {
        return Http.user;
    }

    public static get(url: string, rawParams?: object, withSignature = true, regionId?: string) {
        const params = this.getParams(url, rawParams, withSignature);
        return axios({
            method: 'get',
            baseURL: this.getBaseUrl(regionId),
            url: this.getUrlWithVersion(url, params.version),
            params,
            transformResponse: this.transformResponse,
        });
    }

    /**
     * Core-method for post request
     */
    public static post(url: string, rawParams?: object, withSignature = true, isMultipartType = false): Promise<any> {
        const params = this.getParams(url, rawParams, withSignature);

        // Формирование данных, если хотим отправить данные в виде multipart/form-data
        let formData;
        if (isMultipartType) {
            formData = new FormData();
            for (const i in params) {
                if (params.hasOwnProperty(i) && params[i] !== undefined) {
                    this.extendedAppend(formData, i, params[i]);
                }
            }
        }

        return axios({
            method: 'post',
            baseURL: this.getBaseUrl(),
            url: this.getUrlWithVersion(url, params.version),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: formData || Qs.stringify(params),
            transformResponse: this.transformResponse,
        });
    }

    /**
     * Core-method for post-json request
     */
    public static postJson(url: string, rawParams?: Dict, withSignature = true): Promise<any> {
        const params = this.getParams(url, rawParams, withSignature);

        const queryParams = _.pick(params, ['signature', 'nonce', 'device_id']) as Dict<string>;
        const query = withSignature ? `?${new URLSearchParams(queryParams)}` : '';

        return axios({
            method: 'post',
            baseURL: this.getBaseUrl(),
            url: this.getUrlWithVersion(url, params.version) + query,
            headers: {
                'Content-Type': 'text/plain', // 'application/json',
                // when using application/json, Chrome sends a preflight request, and the backend blocks it due to CORS.
                // However, when using text/plain, there is no OPTIONS request, and everything works fine.
            },
            data: rawParams,
            transformResponse: this.transformResponse,
        });
    }

    /**
     * Метод для post-запроса с разбивкой параметров с большими массивами
     */
    public static postSplit(url: string, rawParams?: object, withSignature = true, isMultipartType = false): Promise<any[]> {
        const requests = this.splitParams(rawParams).map((param: any) => this.post(url, param, withSignature, isMultipartType));
        return new Promise(((resolve, reject) => {
            Promise.all(requests)
                .then((responses) => resolve(responses))
                .catch((error) => reject(error));
        }));
    }

    /**
     * Получение полного пути для вызова
     * @param url
     * @param rawParams
     * @param withSignature
     */
    public static getFillPath(url: string, rawParams?: object) {
        const params = this.getParams(url, rawParams, true);
        return this.getBaseUrl() + this.getUrlWithVersion(url, params.version) + `?signature=${params.signature}&nonce=${params.nonce}&device_id=${params.device_id}`;
    }

    private static splitParams(rawParams?: object, partLength = 500) {
        const parsedParams = Object.entries(Object.assign({}, rawParams)).map(([key, value]) => {
            return {
                key,
                value,
                isArray: _.isArray(value),
            };
        });
        const okParams = parsedParams.filter((param: any) => !param.isArray || _.isArray(param.value) && param.value.length <= partLength)
            .reduce((accum: any, next) => {
                accum[next.key] = next.value;
                return accum;
            }, {});
        const longArrayParam = parsedParams.filter((param: any) => _.isArray(param.value) && param.value.length > partLength)
            .map((long: any) => {
                if (_.isArray(long.value)) {
                    long.value = this.divideArray(long.value, partLength);
                }
                return long;
            });

        // это не будет работать если параметров с большими массивами больше 1, это оказалось слишком громоздким и сомнительно, что потребуется.
        return longArrayParam.length ? longArrayParam.reduce((accum, next) => {
            if (_.isArray(next.value)) {
                next.value.forEach((arr: any) => {
                    accum.push(Object.assign({}, okParams, { [next.key]: arr }));
                });
            }
            return accum;
        }, [] as any[]) : [okParams];
    }

    private static divideArray(sourceArray: any[], partLength = 500): any[] {
        const resultArray: any[] = [];
        const arr = _.cloneDeep(sourceArray);
        while (arr.length) {
            resultArray.push(arr.splice(0, partLength));
        }
        return resultArray;
    }

    private static getParams(url: string, rawParams?: object, withSignature = true): IParams {
        const params: IParams = Object.assign({ version: httpParams.version }, rawParams);
        const urlWithVersion = this.getUrlWithVersion(url, params.version);

        if (withSignature) {
            params.device_id = this.user.device_id;
            params.nonce = httpParams.nonce;
            params.signature = this.calculateSignature(urlWithVersion, httpParams.nonce, this.user);
        }

        return params;
    }

    /**
     * Функция для получения url с версией
     */
    private static getUrlWithVersion(url: string, version: string): string {
        return `/${version}/${url}`;
    }

    /**
     * Функция генерации подписи
     */
    private static calculateSignature(url: string, nonce: string, user: any): string {
        return (sha1(sha1(nonce + user.key + url + user.device_id) + nonce)).toString().substring(0, 32);
    }

    private static extendedAppend(formData: FormData, key: any, value: any) {
        if (value instanceof File || !(value instanceof Object)) {
            formData.append(key, value);
            return;
        }

        const obj = value;
        for (const k in obj) {
            if (obj.hasOwnProperty(k)) {
                this.extendedAppend(formData, `${key}[${k}]`, obj[k]);
            }
        }
    }
}
