<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';

interface IconParams {
    icon: string;
    color: string;
}

const defaultColor = '#B3B3B3';

const textIcon: IconParams = {
    icon: 'txt',
    color: defaultColor,
};

const docIcon: IconParams = {
    ...textIcon,
    color: '#6D93CE',
};

const pdfIcon: IconParams = {
    icon: 'pdf',
    color: '#FD6C63',
};

const excelIcon: IconParams = {
    icon: 'excel',
    color: '#58BE88',
};

const imageIcon: IconParams = {
    icon: 'image',
    color: '#53BFBF',
};

const videoIcon: IconParams = {
    icon: 'video',
    color: '#B38BDB',
};

const d3Icon: IconParams = {
    icon: '3d',
    color: '#48BBEC',
};

function makeMap(list: string[], obj: IconParams) {
    return list.reduce((acc, cur) => {
        acc[cur] = obj;
        return acc;
    }, {} as { [key: string]: IconParams });
}

const textList = ['txt'];
const docList = ['doc', 'docx'];
const pdfList = ['pdf'];
const excelList = ['xls', 'xlsx'];
const imageList = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tif', 'tiff'];
const videoList = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'm4v', 'mts', 'm2ts', 'mkv', 'webm', 'ogv', '3gp', '3g2'];
const d3List = ['3d', 'dwfx', 'dfx', 'obj', 'dwf'];

export const extensionsMapping = {
    ...makeMap(textList, textIcon),
    ...makeMap(docList, docIcon),
    ...makeMap(pdfList, pdfIcon),
    ...makeMap(excelList, excelIcon),
    ...makeMap(imageList, imageIcon),
    ...makeMap(videoList, videoIcon),
    ...makeMap(d3List, d3Icon),
};

@Component
export default class IconFileSvgBase extends Vue {
    @Prop({ required: true }) public file!: string;

    public size = 32;

    get extension() {
        const ext = this.file?.split('.').pop();
        return ext ? ext.toLowerCase() : '';
    }

    get iconParams(): IconParams | undefined {
        return extensionsMapping[this.extension];
    }

    get color() {
        return this.iconParams?.color ?? defaultColor;
    }

    get url() {
        if (!this.iconParams) {
            amplitudeLog(AmplitudeEvent.issueChatUnknownFileType, { extension: this.extension });
            return false;
        }
        return require(`./svg/file/${this.iconParams.icon}_${this.size}.svg`);
    }

}
</script>
