import _ from 'lodash';
import http from '@/api/http';
import { BulkResult } from '@/models';

export default class ReportApi {
    /**
     * Метод для единоразовой отправки дашборда на любое количество email
     */
    public static postReportSendToEmail(projectId: number, reportUuid: string, params: any) {
        return http.post(`project/${projectId}/report/${reportUuid}/send`, params).then((response) => response.data);
    }

    /**
     * Метод для добавления нового репорта
     */
    public static postAddReport(projectId: number, params: any) {
        return http.post(`project/${projectId}/report/add`, params).then((response) => response.data);
    }

    /**
     * Метод для редактирования существующего репорта
     */
    public static postEditReport(projectId: number, reportUuid: string, params: any) {
        params = _.omit(params, ['biggerImages']);
        return http.post(`project/${projectId}/report/${reportUuid}/edit`, params).then((response) => response.data);
    }

    /**
     * Метод для добавления нового репорта
     */
    public static postDeleteReport(projectId: number, params: any): Promise<BulkResult> {
        return http.post(`project/${projectId}/report/remove`, params).then((response) => response.data);
    }

    /**
     * Получение списка репортов проекта
     */
    public static getProjectReports(projectId: number): Promise<any> {
        return http.get(`project/${projectId}/report/list`).then((response) => response.data);
    }

    /**
     * Получение репорта проекта
     */
    public static getProjectReport(projectId: number, reportUuid: string): Promise<any> {
        return http.get(`project/${projectId}/report/${reportUuid}/item`).then((response) => response.data);
    }
}