<template>
    <div v-if="!isSkipThisDiff" class="issue-comment-diff-status" @click="click">
        <IssueCommentDiffChanged entity="status" />
        <template v-if="isShowStandardStatus">
            <IssueStatusText :status="diff.status.old" tag-like />
            ➝
            <IssueStatusText :status="diff.status.new" tag-like />
        </template>
        <template v-else-if="customStatusDiff">
            <CustomStatusItem :status="customStatusDiff.oldStatus" />
            ➝
            <CustomStatusItem v-if="!hasDeletedStatus" :status="customStatusDiff.newStatus" />
            <IssueStatus v-else :status="IssueStatusDeleted" />
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { IssueTrackerFields } from '@/constants/IssueTrackerFields';
import { dateFormatter } from '@/services/MomentFormats';
import IssueStatusText from '@/domain/issue/components/IssueStatusText.vue';
import IssueCommentDiffChanged from '@/domain/comment/components/IssueCommentDiffChanged.vue';
import IssueStatus from '@/domain/issue/components/IssueStatus.vue';
import CustomStatusItem from '@/domain/customStatus/components/CustomStatusItem.vue';
import { IDiffItem } from '@/domain/comment/models/DiffComment';
import { IssueTrackerDiffInlinePropertiesType } from '@/domain/comment/models/IssueTrackerDiffProperties';
import { IssueStatusEnum } from '@/domain/issue/constants/IssueStatus';

@Component({
    components: {
        CustomStatusItem,
        IssueCommentDiffChanged,
        IssueStatusText,
        IssueStatus,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueCommentDiffStatus extends Vue {
    @Prop({ required: true }) public entity!: IssueTrackerDiffInlinePropertiesType;
    @Prop({ required: true }) public diff!: Dict<IDiffItem>;

    public readonly IssueStatusDeleted = IssueStatusEnum.deleted;

    get projectUuid(): string {
        const projectId = this.$route.params.projectId;
        return this.$store.getters.projectById(projectId)?.uuid || '';
    }

    get hasDeletedStatus() {
        return this.diff.status?.new === IssueStatusEnum.deleted;
    }

    get customStatusDiff() {
        if (this.diff.customStatus && this.entity == IssueTrackerFields.customStatus) {
            return {
                newStatus: this.$store.getters.customIssueStatusByUuid(this.projectUuid, this.diff.customStatus.new),
                oldStatus: this.$store.getters.customIssueStatusByUuid(this.projectUuid, this.diff.customStatus.old),
            };
        } else {
            return undefined;
        }
    }

    get isSkipThisDiff() {
        return Boolean(this.entity == IssueTrackerFields.status && this.diff.customStatus);
    }

    get isShowStandardStatus() {
        return this.entity == IssueTrackerFields.status && !this.diff.customStatus;
    }

    @Emit()
    public click() {
        return;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.diff:not(:last-child) {
    margin-bottom: 2em;
}

.issue-comment-diff-status {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 4px;
}

.issue-comment-diff-status ::v-deep .custom-status {
    margin: 2px 4px;
}
</style>
