import { IStatFiltersData } from '@/types/IStatFiltersData';
import { modifierAsStringParam } from '@/domain/issueFilter/services/IssueFilterService'; // sic!
import { IssueFilterExpr } from '@/domain/issueFilter/constants/IssueFilterExpr';

const defaultAction = modifierAsStringParam(IssueFilterExpr.HAS_ONE_IN);

export const DefaultActivityFiltersData = {
    get filtersData(): IStatFiltersData {
        return {
            startDate: NaN,
            endDate: NaN,
            tags: [],
            tagAction: defaultAction,
            companies: [],
            companiesAction: defaultAction,
            departments: [],
            departmentsAction: defaultAction,
            locations: [],
            locationsAction: defaultAction,
        };
    },
};
