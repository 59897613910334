<template>
    <div
        class="ws-checkbox"
        :class="{
            disabled,
            checked,
            indeterminate,
            label,
        }"
        @click="toggle"
    >
        <div class="control" />
        <div v-if="label" class="label">{{ label }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
@Component({})
export default class WsCheckbox2 extends Vue {
    @VModel({ type: Boolean }) checked!: boolean;
    @Prop() public label!: any;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public blocked!: boolean;
    @Prop({ type: Boolean, default: false }) public indeterminate!: boolean;

    @Emit()
    public change() {
        return !this.checked;
    }

    public toggle() {
        if (!this.disabled && !this.blocked) {
            this.checked = !this.checked;
            this.change();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/wsui/index.scss';
.ws-checkbox {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 8px;
    padding: 6px 0;
    cursor: pointer;

    &.label {
        grid-template-columns: 16px auto;
    }

    .control {
        width: 16px;
        height: 16px;
        border: solid 1px $color-solid-80;
        border-radius: 2px;
        position: relative;
    }

    .label {
        line-height: 18px;
        color: $color-solid-80;
        font-size: 14px;
    }

    &.checked {
        .control {
            background: $color-accent-100;
            border-color: $color-accent-100;

            &:after {
                content: "";
                display: block;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDMuNUw0IDYuNUw5IDEuNSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=');
                position: absolute;
                left: 2px;
                top: 3px;
                width: 10px;
                height: 8px;
            }
        }

        &:before {
            display: none;
        }
    }

    &.indeterminate {
        .control {
            &:after {
                content: "";
                display: block;
                background: $color-accent-100;
                position: absolute;
                left: 3px;
                top: 6px;
                width: 8px;
                height: 2px;
            }
        }
    }

    &:hover {
        .control {
            border-color: $color-accent-100;
        }
    }

    &.disabled {
        pointer-events: none;

        * {
            pointer-events: none;
        }

        .control {
            border-color: $color-solid-40;
        }

        .label {
            color: $color-solid-30;
        }

        &.checked {
            .control {
                background: $color-solid-40;
            }
        }
    }

    &.frozen {
        .label {
            color: $color-solid-80;
        }
    }
}
</style>