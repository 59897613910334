<template>
    <div class="component-notification-widget notification-widget">
        <v-menu
            v-model="isOpen"
            :close-on-content-click="false"
            offset-y
        >
            <template #activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                    class="bell-wrapper flex-align-center"
                    @click="logOnClickMenuActivator"
                >
                    <IconSvg24
                        :icon-name="`notification${countNewRecords ? '-dot' : ''}`"
                        :color="Color.white"
                    />
                </div>
            </template>

            <div v-if="isOpen" class="notification-panel">
                <div class="notification-header">
                    <div>{{ $t('Notification.notifications') }}</div>
                    <WsCloseButton :color="Color.selectBlack" @click="isOpen = false" />
                </div>
                <div class="notification-main">
                    <WsTabsHeader
                        v-model="currentTabIdx"
                        @change="onTabsChange"
                    >
                        <template #customTabs>
                            <v-tab
                                v-for="{ tab, translate, count } in tabs"
                                :key="tab"
                                :class="{ active: currentTab === tab }"
                                :ripple="false"
                                class="tab-item"
                                @click="currentTab = tab"
                            >
                                <span class="label">{{ $t(translate) }}</span>
                                <span class="counter">({{ count }})</span>
                            </v-tab>
                        </template>
                    </WsTabsHeader>
                    <NotificationFilter
                        v-if="records.length"
                        :records="recordsFilteredByUnread"
                        class="notification-filter"
                        @change="onFilterAuthorChange"
                    />
                    <div v-if="!records.length" class="no-notifications">
                        {{ $t('Notification.noNotifications') }}
                    </div>

                    <div v-else class="controls-wrapper">
                        <WsSwitch
                            v-model="isShowNewOnly"
                            :label="String($t('Notification.showUnreadOnly'))"
                            @change="logOnChangeIsShowOnly"
                        />
                        <WsTableButton
                            :disabled="!unreadRecords.length"
                            @click="markAllAsRead"
                        >
                            {{ $t('Notification.markAllAsRead') }}
                        </WsTableButton>
                    </div>
                    <div v-if="records.length && !recordsFilteredByUnread.length" class="no-notifications all-read">
                        {{ $t('Notification.allNotificationsRead') }}
                    </div>

                    <hr>
                    <WsLazyRender :items="currentRecordsWithDates" :key="refreshKey">
                        <template #default="{ items: lazyRecords }">
                            <div v-for="record in lazyRecords" :key="record.operationId">
                                <div v-if="record.date" class="notification-date">{{ record.date }}</div>
                                <NotificationItem
                                    v-else-if="record instanceof NotificationRecord"
                                    :record="record"
                                    @click.native="read(record)"
                                    @close="isOpen = false"
                                />
                            </div>
                        </template>
                    </WsLazyRender>
                </div>
            </div>
        </v-menu>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AmplitudeEvent, BusEvent, Color } from '@/constants';
import { EventTypesError, EventTypesGenerated } from '@/constants/EventTypeEnum';
import { NotificationRecord, UnreachableError } from '@/models';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, dateFullMonthFormatter, getOperationId, i18n, sortArrayOfObjects } from '@/services';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import NotificationItem from '@/components/header/NotificationItem.vue';
import WsSwitch from '@/components/common/WsSwitch.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';
import NotificationFilter from '@/components/header/NotificationFilter.vue';
import WsTableButton from '@/components/common/WsTableButton.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import WsTabsHeader from '@/components/common/WsTabsHeader.vue';

enum Tab {
    issues = 'issues',
    reports = 'reports',
    other = 'other',
}

function getRecordsWithDates(records: NotificationRecord[]) {
    const categories = _.groupBy(records, (record) => record.startOfDay);
    const array = _.entries(categories).map(([date, recordsChunk]) => {
        const numberDate = Number(date);
        const formattedDate = formatDate(numberDate);
        const sortedRecords = sortArrayOfObjects(recordsChunk, 'timestamp');
        return [
            {
                date: formattedDate,
                operationId: getOperationId(numberDate),
                timestamp: numberDate,
            },
            ...sortedRecords,
        ];
    });
    return _.orderBy(array, [(arr) => -arr[0].timestamp]).flat();
}

function formatDate(timestamp: number) {
    const timestampMoment = moment(timestamp);
    const isToday = timestampMoment.isSame(moment(), 'day');
    return isToday ? i18n.t('Simple_word.today') : dateFullMonthFormatter(timestampMoment);
}

@Component({
    components: {
        WsTooltip,
        IconSvg24,
        NotificationFilter,
        NotificationItem,
        WsLazyRender,
        WsSwitch,
        WsTableButton,
        WsCloseButton,
        WsTabsHeader,
    },
})
export default class NotificationWidget extends Vue {
    public readonly Color = Color;
    public readonly NotificationRecord = NotificationRecord;

    public currentTab: Tab = Tab.issues;
    public currentTabIdx = 0;

    public isOpen = false;
    public isShowNewOnly = false;

    public recordsFromFilters: NotificationRecord[] = [];

    get refreshKey() {
        return JSON.stringify(this.recordsFromFilters) + this.currentTab;
    }

    get licenseId(): number | null {
        return this.$store.getters.currentLicenseId;
    }

    get records(): NotificationRecord[] {
        return this.$store.getters.notificationRecords;
    }

    get unreadRecords() {
        return this.records.filter((record) => !record.isRead);
    }

    get recordsFilteredByUnread() {
        return this.isShowNewOnly ? this.unreadRecords : this.records;
    }

    get recordsFiltered() {
        if (!this.recordsFromFilters.length) {
            return this.recordsFilteredByUnread;
        }
        return _.intersectionBy(this.recordsFilteredByUnread, this.recordsFromFilters, 'operationId');
    }

    get issueRecords() {
        const records = _.filter(this.recordsFiltered, 'isIssue');
        return records.map((record) => {
            const issueGroups = _.groupBy(record.data, 'issueId');
            const normalizedIssueGroups = _.values(issueGroups).map((issueGroup) => {
                return _.maxBy(issueGroup, (group) => moment(group.comments[0].created).unix());
            });
            const newRecord = _.cloneDeep(record);
            newRecord.data = normalizedIssueGroups;
            return newRecord;
        });
    }

    get extendedReportRecords() {
        return _.filter(this.recordsFiltered, 'isExtendedReport');
    }

    get otherRecordsNotExtendedReport() {
        return _.filter(this.recordsFiltered, 'isOtherNormal');
    }

    get currentRecordsWithDates() {
        const currentRecords = (() => {
            switch (this.currentTab) {
                case Tab.issues:
                    return this.issueRecords;
                case Tab.reports:
                    return this.extendedReportRecords;
                case Tab.other:
                    return this.otherRecordsNotExtendedReport;
                default: {
                    const _never: never = this.currentTab;
                    throw new UnreachableError(this.currentTab);
                }
            }
        })();

        return getRecordsWithDates(currentRecords);
    }

    get countNewRecords() {
        return this.unreadRecords.length;
    }

    get countNewIssueRecords() {
        return _.intersectionBy(this.unreadRecords, this.issueRecords, 'operationId').length;
    }

    get countNewExtendedReportRecords() {
        return _.intersection(this.unreadRecords, this.extendedReportRecords).length;
    }

    get countNewOtherRecordsNotExtendedReport() {
        return _.intersection(this.unreadRecords, this.otherRecordsNotExtendedReport).length;
    }

    get countReportsSendToAmplitude() {
        return {
            processing: this.extendedReportRecords.filter((record) => record.isInProgress).length,
            generated:  this.extendedReportRecords.filter((record) => EventTypesGenerated.includes(record.eventType)).length,
            error:  this.extendedReportRecords.filter((record) => EventTypesError.includes(record.eventType)).length,
        };
    }

    get tabs() {
        return [
            {
                tab: Tab.issues,
                translate: 'Notification.issueUpdates',
                count: this.countNewIssueRecords,
            },
            {
                tab: Tab.reports,
                translate: 'Notification.reports',
                count: this.countNewExtendedReportRecords,
            },
            {
                tab: Tab.other,
                translate: 'Notification.other',
                count: this.countNewOtherRecordsNotExtendedReport,
            },
        ];
    }

    @Watch('isOpen')
    public onToggle(value: boolean) {
        this.recordsFromFilters = [];
        eventBus.$emit(BusEvent.isOpenNotifications, value);
    }

    public created() {
        this.$store.dispatch('getNotifications');
    }

    public onFilterAuthorChange(event: NotificationRecord[]) {
        // It is only author filter on this widget, so type is hardcoded here.
        amplitudeLog(AmplitudeEvent.ncFilterChange, { type: 'author' });
        this.recordsFromFilters = event;
    }

    public onTabsChange() {
        amplitudeLog(
            AmplitudeEvent.ncTabClick,
            {
                tabName: this.currentTab,
                ...(this.currentTab === Tab.reports ? this.countReportsSendToAmplitude : {}),
            },
        );
    }

    public markAllAsRead() {
        const unreadOnCurrentTab = this.tabs.find(({ tab }) => tab === this.currentTab)?.count;
        amplitudeLog(AmplitudeEvent.ncReadAll, { tabName: this.currentTab, count: unreadOnCurrentTab });

        this.$store.dispatch('readAllNotifications');

    }

    public read(record: NotificationRecord) {
        this.$store.dispatch('readNotification', record);
    }

    public logOnClickMenuActivator() {
        amplitudeLog(AmplitudeEvent.ncOpen,
            {
                count: this.countNewRecords,
                isShowUnreadOnly: this.isShowNewOnly,
                issuesUpdatesUnread: this.countNewIssueRecords,
                reportsUnread: this.countNewExtendedReportRecords,
                otherUnread: this.countNewOtherRecordsNotExtendedReport,
            },
        );
    }

    public logOnChangeIsShowOnly(isShowNewOnly: boolean) {
        if (isShowNewOnly) {
            amplitudeLog(AmplitudeEvent.ncToggleNew);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/variables.scss';
@import '@/styles/tables.scss';

.notification-widget {
    height: 100%;
    margin-right: 16px;
    margin-left: 8px;
    display: flex;
    align-items: center;
}
.notification-panel {
    @include scrollbar;
    display: flex;
    flex-direction: column;
    width: 560px;
    background: white;
    min-height: 533px;
    max-height: 90vh;
}
.bell-wrapper {
    height: 100%;
}
.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    color: $select-black;
    font-size: $font-size-huge;
    border-bottom: $border;

    ::v-deep .ws-close-button {
        width: 13px;
    }
}

.notification-main {
    flex-grow: 1;
    padding: 0 24px;
    display: flex;
    flex-direction: column;

    .no-notifications {
        flex-grow: 1;
        margin-top: 32%;
        display: flex;
        justify-content: center;
        &.all-read {
            margin-top: 22%;
        }
    }

    .notification-filter {
        margin-top: 10px;
    }

    hr {
        margin: 0 4px 4px;
        &:after {
            left: -5px;
            right: -5px;
        }
    }

    .controls-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 6px 0 6px;
        .v-input {
            margin-top: 0;
        }
    }
}

::v-deep .v-tabs {
    flex-grow: 0;
}

::v-deep .tab-item {

    &:hover {
        .counter {
            color: $primary-blue;
        }
    }
    .counter {
        margin-left: 3px;
        color: $primary-blue;
        font-size: smaller;
    }
}

.notification-date {
    margin-top: 8px;
    font-size: $font-size-small;
}
.notification-issue-item {
    margin: 4px 0 18px;
}

::v-deep .controls-wrapper .ws-button.v-btn--plain {
    color: $default-black;
    border-color: transparent;
    &:active, &:hover {
        border-color: transparent;
    }
    &:hover {
        color: $primary-blue;
    }
}

::v-deep .notification-issue-item .diff {
    margin-bottom: 10px;
}
</style>
