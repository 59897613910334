var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{staticClass:"projects-dropdown-item",class:{ 'router-link-exact-active': _vm.isActive },attrs:{"to":{
        name: _vm.RouterNames.ProjectSettings,
        params: {
            licenseId: _vm.licenseId,
            projectId: _vm.project.id,
            language: _vm.language,
        }
    }}},[_c('WsProjectPreview',{staticClass:"project-logo",attrs:{"preview-images":_vm.project.previewImages}}),_c('div',{staticClass:"description"},[_c('WsTruncate',{attrs:{"css-class":"amp-mask","value":_vm.project.title,"size":35}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }