import { render, staticRenderFns } from "./WsTableRowMenu.vue?vue&type=template&id=ae683fb4&scoped=true"
import script from "./WsTableRowMenu.vue?vue&type=script&lang=ts"
export * from "./WsTableRowMenu.vue?vue&type=script&lang=ts"
import style0 from "./WsTableRowMenu.vue?vue&type=style&index=0&id=ae683fb4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae683fb4",
  null
  
)

export default component.exports