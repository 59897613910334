<template>
    <div v-loading="isAuthorizing" class="component-login-options-form login-options-form">
        <div class="wrapper">
            <template v-if="!isTwoFAStep">
                <div v-if="hasTitle" class="title-text">{{ $t('Login.licenseLogin') }}</div>
                <div class="texts" v-html="sanitizeHtml(explanation)" />

                <div v-if="isSAML" class="texts">
                    {{ $t('Login.yourEntityId') }}
                    <div class="entity-id">{{ license.authMethodEntityId }}</div>
                </div>
                <div v-if="pleasePhrase" class="texts please-phrase">{{ pleasePhrase }}</div>
                <div v-if="license" class="form-wrapper">
                    <v-form ref="credentials" lazy-validation>
                        <div v-if="isCredentialsInputs" class="credentials-inputs">
                            <WsInput
                                v-model="credentials[loginFieldName]"
                                :label="$t(`Form.${loginFieldName}`)"
                                :rules="rules[loginFieldName]"
                                :name="loginFieldName"
                                @keyup.enter.native="continueLogin"
                            />
                            <WsInput
                                v-model="credentials.password"
                                :label="$t('Form.pass')"
                                :rules="rules.password"
                                type="password"
                                @keyup.enter.native="continueLogin"
                            />
                        </div>
                        <div v-else-if="isSAML" class="empty-saml" />
                        <div v-else class="g-suite-wrapper">
                            <simple-svg filepath="/images/google-logo.svg" width="126px" />
                        </div>

                        <WsButton
                            class="continue-button"
                            type="primary"
                            round
                            @click="continueLogin"
                        >
                            {{ $t('Button.continue') }}
                        </WsButton>
                    </v-form>
                </div>

                <div class="lower-block">
                    <div
                        v-if="isShowForgotPassword"
                        class="forgot-password"
                        :class="{ 'no-pointer': !isReviztoInternal }"
                        @click="onForgotPassword"
                    >
                        <WsTooltip
                            v-if="!isReviztoInternal"
                            :activator="$t('Button.forgot')"
                            :tooltip="$t('Login.forgotPasswordCorporate')"
                        />
                        <span v-else>{{ $t('Button.forgot') }}</span>
                    </div>
                    <div v-else />

                    <div v-if="authError" class="error">{{ authError }}</div>

                    <div
                        v-if="hasReturnArrow"
                        class="return-arrow"
                        @click="returnBack"
                    >
                        <span class="arrow-itself">← </span>
                        <span>{{ $t('Simple_word.return') }}</span>
                    </div>
                </div>
            </template>

            <template v-if="isTwoFAStep">
                <div class="auth-title-desc">
                    <template v-if="isEmailTwoFactorMethod">{{ $t('TwoFA.securityCodeEmail') }}</template>
                    <template v-if="isGoogleTwoFactorMethod">{{ $t('TwoFA.securityCodeGoogle') }}</template>
                </div>

                <div class="two-fa-code">
                    <OTPInput
                        :key="otpTriggerKey"
                        @input="twoFAData.code = $event"
                        @ready="loginTwoFA"
                    />
                    <div class="error" :class="{ show: isInvalidCode }">{{ $t('TwoFA.securityCodeError') }}</div>
                    <div class="resend-code" :class="{ disabled: isDisabledResend }" v-if="isEmailTwoFactorMethod">
                        <button @click.prevent="resendCode" :disabled="isDisabledResend">{{ $t('TwoFA.resendCode') }}</button>
                        <div v-show="isDisabledResend">{{ $t('TwoFA.resendTimer', { secondsLeft }) }}</div>
                    </div>
                </div>

                <div class="support">
                    <div v-if="isEmailTwoFactorMethod" v-html="sanitizeHtml($t('TwoFA.emailProblem'))" />
                    <a v-if="isGoogleTwoFactorMethod" @click.prevent="changeTwoFAMethodToEmailForLogin">
                        {{ $t('TwoFA.notAccessGA') }}
                    </a>
                </div>

                <div class="flex-delimiter" />

                <a class="navigate-back" @click.prevent="navigateToLoginForm">
                    &#8592; {{ $t('Button.back') }}
                </a>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TRulesObj } from '@/types/TRulesObj';
// @ts-ignore
import { Dict } from '@/types/Dict';
import { AuthMethodType, CookieName, RESPONSE, RouterNames, SAMLCookie, TwoFAMethod } from '@/constants';
import { License, ProtoLicense } from '@/models';
import { CookieHelper, sanitizeHtml, startTimer, truncateString } from '@/services';
import { autofocusMixin } from '@/mixins';
import OTPInput from '@/components/common/OTPInput.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

const Empty = {
    get twoFAData() {
        return {
            key: '',
            secret: '',
            twoFactorMethod: '',
            code: '',
        };
    },
    get credentials() {
        return {
            email: '',
            login: '',
            password: '',
        };
    },
};

@Component({
    components: {
        OTPInput,
        WsButton,
        WsInput,
        WsTooltip,
    },
})
export default class LoginMergeForm extends autofocusMixin {
    @Prop({ type: Boolean }) public hasTitle!: boolean;
    @Prop({ required: true }) public license!: License | ProtoLicense;
    @Prop({ type: Boolean, default: true }) public hasReturnArrow!: boolean;
    @Prop({ type: Boolean, default: true }) public hasForgotPassword!: boolean;
    @Prop() public pleasePhrase!: TranslateResult;
    @Prop({ type: String, default: '' }) public pathnameToRedirect!: string;
    // These two callbacks are intentionally passed into props, not executed on event, because
    // events aren't fired on components inside slots (LoginMergeForm is a slot inside WsDialog in parent components)
    @Prop({ default: () => () => null }) public close!: () => void;
    @Prop({ default: () => () => null }) public returnToPrevious!: () => void;

    public readonly sanitizeHtml = sanitizeHtml;

    public credentials = Empty.credentials;
    public isTwoFAStep = false;
    public isInvalidCode = false;
    public isDisabledResend = false;
    public secondsLeft: number = 0;
    public twoFAData = Empty.twoFAData;

    public authError: TranslateResult = '';
    public isAuthorizing = false;
    public appSSO = this.$store.getters.appSSO;
    public otpTriggerKey = 1;

    get isEmailTwoFactorMethod() {
        return this.twoFAData.twoFactorMethod === TwoFAMethod.email;
    }

    get isGoogleTwoFactorMethod() {
        return this.twoFAData.twoFactorMethod === TwoFAMethod.google;
    }

    get loginFieldName() {
        return this.isReviztoInternal ? 'email' : 'login';
    }

    get rules(): TRulesObj {
        return {
            email: [
                (value: string) => Boolean(value) || this.$t('errors.empty_email'),
            ],
            login: [
                (value: string) => Boolean(value) || this.$t('errors.empty_login'),
            ],
            password: [
                (value: string) => Boolean(value) || this.$t('errors.empty_pass'),
            ],
        };
    }

    get licenseName() {
        return truncateString(this.license.name);
    }

    get authType() {
        return this.license.authMethodType;
    }

    get isShowForgotPassword() {
        return this.hasForgotPassword && ![AuthMethodType.google, AuthMethodType.SAML].includes(this.authType);
    }

    get isCredentialsInputs() {
        return this.authType === AuthMethodType.revizto_internal;
    }

    get isSAML() {
        return this.authType === AuthMethodType.SAML;
    }

    get isAppAuth() {
        return Boolean(this.appSSO.key);
    }

    get isReviztoInternal() {
        return this.authType === AuthMethodType.revizto_internal;
    }

    get isGoogle() {
        return this.authType === AuthMethodType.google;
    }

    get explanation() {
        const licenseName = this.licenseName;
        if (this.isReviztoInternal) {
            return this.$t('Login.requiresAdditionalAuthReviztoAccount', { licenseName });
        }
        if (this.isGoogle) {
            return this.$t('Login.requiresAdditionalAuthCorporateGoogle', { licenseName });
        }
        return this.$t('Login.requiresAdditionalAuth', { licenseName });
    }

    public async continueLogin() {
        const form = this.$refs.credentials as HTMLFormElement;
        const isValid = form.validate();
        if (!isValid) {
            return;
        }

        try {
            if (this.isGoogle) {
                await this.continueWithGoogle();
            }
            if (this.isReviztoInternal) {
                await this.continueWithPassword();
            }
            if (this.isSAML) {
                await this.continueWithSAML();
            }
        } catch {
            return;
        }

        this.close();
    }

    public async continueWithGoogle() {
        this.memorizeLicenseIdAndLanguage();
        const service = AuthMethodType.google;
        const landing = this.isAppAuth ? 'app-authorized' : 'ws-authorized';
        this.isAuthorizing = true;
        try {
            await this.$store.dispatch('loadOAuthServicesUrls', { service, landing });
        } catch (error: any) {
            this.authError = error.message;
            this.isAuthorizing = false;
            return;
        }
        const oauth = this.$store.getters.oauth;
        location.href = oauth[service].url;
    }

    public async continueWithPassword() {
        const merge = 1;
        const { email, password } = this.credentials;
        const authPassword = this.appSSO.isEmpty ? this.authorizePassword : this.authorizeAppPassword;
        const params = this.appSSO.isEmpty
            ? { email, password, merge }
            : { email, password, merge, app_sso_key: this.appSSO.key, app_sso_region: this.appSSO.region };
        this.isAuthorizing = true;
        try {
            await authPassword(params);
        } catch (error: any) {
            this.handleAuthError(error);
        } finally {
            this.isAuthorizing = false;
        }
    }

    public handleAuthError(error: Dict | undefined) {
        switch (error?.result) {
            case RESPONSE.TWO_FA_REQUIRED:
                this.isTwoFAStep = true;
                this.twoFAData = { ...this.twoFAData, ...error?.data };
                if (this.isEmailTwoFactorMethod) {
                    this.startTimerResendCode();
                }
                throw error;
            case RESPONSE.BANNED_PERMANENTLY:
                this.authError = this.$t('errors.bannedPermanently');
                break;
            case RESPONSE.USER_AUTH_BANNED:
                this.authError = this.$t('errors.authorizationBanned');
                break;
            case RESPONSE.INVALID_DATA:
                this.authError = this.$t('errors.incorrectCredentials');
                break;
            case RESPONSE.INVALID_PARAMS:
                this.authError = this.$t('errors.invalidEmail');
                break;
            default:
                this.authError = error?.message || JSON.stringify(error);
        }
        throw error;
    }

    public async authorizePassword(params: any): Promise<void> {
        await this.$store.dispatch('authorize', params);
        this.switchLicenseInWs();
    }

    public async authorizeAppPassword(params: any): Promise<void> {
        await this.$store.dispatch('authorizeApp', params);
        this.$router.push({ name: RouterNames.AppAuthorized });
    }

    public async loginTwoFA() {
        this.isAuthorizing = true;
        try {
            if (this.appSSO.isEmpty) {
                await this.$store.dispatch('authorizeTwoFA', this.twoFAData).then(() => {
                    this.isInvalidCode = false;
                    this.switchLicenseInWs();
                });
            } else {
                await this.$store.dispatch('authorizeTwoFAApp', {
                    app_sso_key: this.appSSO.key,
                    ...this.twoFAData,
                }).then(() => {
                    this.isInvalidCode = false;
                    this.$router.push({ name: RouterNames.AppAuthorized });
                });
            }
        } catch {
            this.isInvalidCode = true;
            this.isAuthorizing = false;
            this.otpTriggerKey++;
        }
    }

    public resendCode() {
        this.startTimerResendCode();
        this.$store.dispatch('resendCodeTwoFA', { key: this.twoFAData.key });
    }

    public startTimerResendCode() {
        startTimer(
            60,
            () => this.isDisabledResend = true,
            () => this.isDisabledResend = false,
            (secondsLeft) => this.secondsLeft = secondsLeft,
        );
    }

    public changeTwoFAMethodToEmailForLogin() {
        this.$store.dispatch('changeTwoFAMethodToEmail', {
            login: this.credentials.email,
            password: this.credentials.password,
            merge: 1,
        }).then((response) => {
            this.twoFAData = Object.assign({}, this.twoFAData, response);
            this.startTimerResendCode();
        });
    }

    public navigateToLoginForm() {
        this.isTwoFAStep = false;
        this.isInvalidCode = false;
        this.twoFAData = Empty.twoFAData;
    }

    public async continueWithSAML() {
        const data = {
            spEntityId: this.license.authMethodEntityId,
            device_id: this.$store.getters.deviceId,
            merge: 1,
        };

        this.isAuthorizing = true;
        const response = await this.$store.dispatch('postSAMLInitData', data);
        if (response.result !== RESPONSE.SUCCESS) {
            this.authError = String(this.$t('Collocation.internalError'));
            return;
        }
        const { url, authNRequestId, transitionalAuth } = response.data;
        CookieHelper.set(SAMLCookie.authNRequestId, authNRequestId);
        CookieHelper.set(SAMLCookie.transitionalAuth, transitionalAuth);
        location.href = url;
    }

    public async switchLicenseInWs() {
        await this.$store.dispatch('loadLicenses', true);

        if (this.pathnameToRedirect) {
            this.$router.replace({ path: this.pathnameToRedirect });
            location.reload();
        } else {
            this.$router.replace({
                name: RouterNames.StartPage,
                params: { licenseId: String((this.license as License).id), language: this.$route.params.language },
            });
        }

        this.close();
    }

    public onForgotPassword() {
        if (this.authType === AuthMethodType.revizto_internal) {
            this.$router.replace({ name: RouterNames.ForgotPassword });
        }
    }

    public returnBack() {
        this.returnToPrevious();
        this.close();
    }

    public memorizeLicenseIdAndLanguage() {
        const licenseId = (this.license as License).id;
        const language = this.$store.getters.currentLanguage;
        const json = JSON.stringify({ licenseId, language });
        CookieHelper.set(CookieName.switchLicenseAuth, json);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/auth.scss';

.wrapper {
    padding-bottom: 20px;
}
.title-text {
    font-size: 32px;
    font-weight: 500;
    color: $rich-black;
    margin-bottom: 18px;
}
.texts {
    font-size: 18px;
    font-weight: 400;
    color: $select-black;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}
.please-phrase {
    margin-top: 10px;
}
.login-options-form {
    display: flex;
    justify-content: center;
}
.credentials-inputs {
    margin: 24px 0 8px;
}
.continue-button {
    width: 100%;
}
.g-suite-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 155px;
}
.return-arrow {
    justify-self: end;
    display: flex;
    font-size: $font-size;
    color: $default-black;
    cursor: pointer;
    user-select: none;
    .arrow-itself {
        margin-right: 2px;
        transform: translateY(-2px);
    }
}
.lower-block {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.forgot-password {
    margin-top: 45px;
    color: $primary-blue;
    cursor: pointer;
    user-select: none;
}
.no-pointer {
    cursor: default;
}
.empty-saml {
    height: 50px;
}

::v-deep .two-fa-code {
    margin: 10px 0 20px;

    .error {
        margin: 5px 0;
        text-align: left;
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    .resend-code {
        margin-top: 5px;
        min-height: 50px;
        text-align: center;

        &.disabled {
            opacity: 0.6;

            * {
                cursor: default;
            }
        }

        button {
            display: inline-block;
            padding: 5px 5px 5px 30px;
            color: $primary-blue;
            border: none;
            outline: none;
            font-size: 14px;
            background: url(/images/refresh_icon.png) 5px center no-repeat;
            cursor: pointer;
        }

        > div {
            line-height: $line-height-large;
        }
    }
}
::v-deep .ws-button {
    width: 100%;
}

.support {
    margin: 40px 0;
    color: $link;
    font-size: 14px;
    line-height: $line-height-small;
}
</style>
