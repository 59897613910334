<template>
    <div :class="{ url }" class="file-item" @click="handleClick">
        <IconFileSvg40 :file="filename" />
        <WsTruncate :value="filename" css-class="file-name highlighting amp-mask" />
        <IconSvg24
            v-if="provider"
            :icon-name="provider"
            class="provider"
            stateless
            passive
        />
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconFileSvg40 from '@/components/common/icon/IconFileSvg40.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg24,
        IconFileSvg40,
        WsTruncate,
    },
})
export default class IssueCommentFileItem extends Vue {
    @Prop({ required: true }) public filename!: string;
    @Prop() public provider!: string; // для cdeLinks
    @Prop() public url!: string; // для cdeLinks
    @Prop({ default: false, type: Boolean }) public isPinnedFormat!: boolean;

    @Emit()
    public click() {
        return;
    }

    public handleClick() {
        if (!this.isPinnedFormat) {
            if (this.url) {
                open(this.url, '_blank');
            }
        } else {
            this.click();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.file-item {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    align-items: center;
    grid-gap: 14px;
    padding: 4px 0;

    &.url {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: $light;
        }
    }
}
::v-deep .file-name {
    font-size: $font-size;
    word-break: break-word;
}
</style>
