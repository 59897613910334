<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :editable="editable"
        :label="label"
        :multi-text="showFieldMultipleTextLocal"
        :multi-changed="isNewMultiValue"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <template v-if="!inlineEdit">
            <span v-if="!isDeadlineNotSet" class="deadline">{{ dateFormatter(currentValue) }}</span>
            <span v-else class="deadline">{{ $t('IssueTracker.info.deadlineNotSet') }}</span>
        </template>

        <div
            v-if="inlineEdit"
            class="inline-edit"
        >
            <WsInputDatepicker
                v-model="localValue"
                :max-date="maxDate"
                :show-reset="!isDeadlineNotSet"
                autofocus
                single-date-picker
                @reset="resetDeadline"
            />
        </div>

        <template #action>
            <IconSvg16
                v-if="!inlineEdit"
                icon-name="edit"
                stateless
                @click="startEdit"
            />
            <div v-if="inlineEdit">
                <WsButton
                    size="xsmall"
                    type="primary"
                    contained
                    @click="handleSave"
                >
                    {{ $t('Button.save') }}
                </WsButton>
                <WsButton
                    :outlined="false"
                    size="xsmall"
                    @click="stopEdit"
                >
                    {{ $t('Button.cancel') }}
                </WsButton>
            </div>
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import moment from 'moment/moment';
import { Component, Prop } from 'vue-property-decorator';
import { DateFormatByNumber, DEADLINE_NOT_SET } from '@/constants';
import { DatepickerDateRange } from '@/models';
import { dateFormatter } from '@/services/MomentFormats';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsInputDatepicker from '@/components/common/WsInputDatepicker.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';

@Component({
    components: {
        IssueDetailsItem,
        IconSvg16,
        WsButton,
        WsInputDatepicker,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueDetailsDate extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    public maxDate = moment().add(30, 'years').toDate();

    public inlineEdit = false;

    public localValue: DatepickerDateRange = {} as DatepickerDateRange;

    get isDeadlineNotSet() {
        return this.currentValue === DEADLINE_NOT_SET;
    }

    get showFieldMultipleTextLocal() {
        return this.inlineEdit ? false : this.showFieldMultipleText;
    }

    public created() {
        this.localValue = new DatepickerDateRange({
            startDate: this.isDeadlineNotSet ? moment() : moment(this.currentValue),
            endDate: moment(),
        });
    }

    public handleSave() {
        const value = this.localValue?.startDate.format(`${DateFormatByNumber[6]} HH:mm:ss`);
        if (value && value !== this.currentValue) {
            this.change(value);
        }
        this.stopEdit();
    }

    public startEdit() {
        this.inlineEdit = true;
    }

    public stopEdit() {
        this.inlineEdit = false;
    }

    public resetDeadline() {
        this.localValue = new DatepickerDateRange({
            startDate: moment(),
            endDate: moment(),
        });
        this.change(DEADLINE_NOT_SET);
        this.stopEdit();
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.inline-edit {
    padding-top: $container-padding;
    width: 100%;
}

.deadline {
    font-weight: 500;
}

::v-deep {
    .vue-daterange-picker {
        width: 100%;

        > .form-control {
            background-color: transparent;
        }
    }
}
</style>
