<template>
    <IssueColumn
        :collapsed="collapsed"
        :component-name="$options.name"
        :resizable="false"
        :title="$t('IssueTracker.headers.presets')"
        :hide-title="hideTitle"
        :is-hide-actions="!isAllowLoadingContent"
        class="tracker-presets"
        icon="presets"
    >
        <PresetList
            :is-allow-loading-content="isAllowLoadingContent"
            :preset-action="presetAction"
            :key="triggerPresetListKey"
            @checked-presets="isCheckedPresetObj = $event"
            @order="newOrder = $event"
        />

        <template #actions>
            <div class="preset-list-controls">
                <div class="buttons">
                    <div class="buttons-left">
                        <IconSvg24
                            v-if="isShowAction(PresetAction.reorder)"
                            :filled="presetAction === PresetAction.reorder"
                            :tooltip="$t('IssueTracker.presets.reorder')"
                            :disabled="!userDataProjectMember"
                            :tooltip-if-disabled="$t('IssueTracker.presets.onlyMembersReorder')"
                            icon-name="reorder"
                            @click="clickAction(PresetAction.reorder)"
                        />
                        <IconSvg24
                            v-if="isShowAction(PresetAction.share)"
                            :filled="presetAction === PresetAction.share"
                            :tooltip="$t('IssueTracker.presets.share')"
                            icon-name="sharing-busts"
                            @click="clickAction(PresetAction.share)"
                        />
                        <IconSvg24
                            v-if="isShowAction(PresetAction.delete)"
                            :filled="presetAction === PresetAction.delete"
                            :tooltip="$t('IssueTracker.presets.delete')"
                            icon-name="trash"
                            @click="clickAction(PresetAction.delete)"
                        />
                    </div>
                </div>
                <div class="action-buttons" v-if="presetAction !== PresetAction.none">
                    <WsButton
                        :loading="isLoadingAction"
                        size="xsmall"
                        @click="applyAction"
                    >
                        {{ $t('Button.apply') }}
                    </WsButton>
                    <WsButton
                        size="xsmall"
                        @click="cancelAction"
                    >
                        {{ $t('Button.cancel') }}
                    </WsButton>
                </div>
            </div>
        </template>

        <WsDialog
            v-if="isShowDialogDeletePresets"
            :title="$t('IssueTracker.presets.deletePresets')"
            @close="isShowDialogDeletePresets = false"
        >
            <AffectedItems
                :items="presetsToDelete"
                :question="$t('IssueTracker.presets.wantDelete')"
                no-label
                amp-mask
            />
            <template #buttons>
                <WsButton
                    :loading="isDeletingPresets"
                    class="delete-presets-button"
                    round
                    plain
                    @click="doDeletePresets"
                >
                    {{ $t('Button.delete') }}
                </WsButton>
                <WsButton round plain @click="isShowDialogDeletePresets = false">{{ $t('Button.cancel') }}</WsButton>
            </template>
        </WsDialog>
    </IssueColumn>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Watch } from 'vue-property-decorator';
import {
    AmplitudeEvent,
} from '@/constants';
import { ProjectMember } from '@/models';
import { amplitudeLog } from '@/services';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';
import IssueColumn from '@/components/project/issueTracker/IssueColumn.vue';
import TrackerColumnComponentBase from '@/components/project/issueTracker/columns/TrackerColumnComponentBase.vue';
import WsDragContent from '@/components/common/dragZone/WsDragContent.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsDialog from '@/components/common/WsDialog.vue';
import AffectedItems from '@/components/common/AffectedItems.vue';
import PresetList from '@/domain/issuePreset/components/PresetList.vue';
import { PresetAction } from '@/domain/issuePreset/constants/PresetAction';
import {
    allIssuesPresetUuid,
    currentIssuesPresetUuid,
    deletedIssuesPresetUuid,
} from '@/domain/issuePreset/constants/FilterPreset';
import { IssueFilterPreset } from '@/domain/issuePreset/models/IssueFilterPreset';

@Component({
    name: 'TrackerPresets',
    components: {
        WsSkeletonLoader,
        AffectedItems,
        IconSvg24,
        IssueColumn,
        PresetList,
        WsButton,
        WsDialog,
        WsDragContent,
    },
})
export default class TrackerPresets extends TrackerColumnComponentBase {
    public readonly PresetAction = PresetAction;

    public presetAction = PresetAction.none;
    public isCheckedPresetObj: any = {};
    public isShowDialogDeletePresets = false;
    public isDeletingPresets = false;
    public isLoadingAction = false;
    public triggerPresetListKey = 0;
    public newOrder: any[] = [];

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get presets(): IssueFilterPreset[] {
        return this.$store.getters.issueFilterPresetsByProjectId(this.projectId);
    }

    get regularPresets() {
        return this.presets.filter(this.isRegularPreset);
    }

    get presetsToDelete() {
        return this.presets.filter(({ uuid }) => this.isCheckedPresetObj[uuid]);
    }

    get userData() {
        return this.$store.getters.userData;
    }

    get userDataProjectMember() {
        return this.$store.getters.projectMemberByEmail(this.userData.email, this.projectId);
    }

    get isAbleToChangeVisibility() {
        return Boolean(this.userDataProjectMember?.accessRole?.permissions.manage_issue_filter_set);
    }

    @Watch('userDataProjectMember', { immediate: true })
    public async onLoadedUserDataProjectMember(projectMember: ProjectMember | undefined) {
        if (!projectMember) {
            return;
        }
        const permissions = await this.$store.dispatch('loadAccessRolePermissions', {
            licenseId: this.licenseId,
            accessRoleId: projectMember.accessRole.id,
        });
        projectMember.accessRole.permissions = permissions.permissions;
    }

    @Watch('isAllowLoadingContent', { immediate: true })
    public onAllowLoadingContent(isAllowLoadingContent: boolean) {
        if (isAllowLoadingContent) {
            this.emitContentLoadedEvent();
        }
    }

    public isRegularPreset(issueFilterPreset: IssueFilterPreset) {
        return ![allIssuesPresetUuid, currentIssuesPresetUuid, deletedIssuesPresetUuid].includes(issueFilterPreset.uuid);
    }

    public clickAction(action: string) {
        switch (action) {
            case PresetAction.reorder:
                if (this.presetAction === action) {
                    amplitudeLog(AmplitudeEvent.itReorderPresetCancel, { button: 'icon' });
                } else {
                    amplitudeLog(AmplitudeEvent.itReorderPresetOpen);
                }
                this.triggerPresetListKey++;
                break;
            case PresetAction.share:
                if (this.presetAction === action) {
                    amplitudeLog(AmplitudeEvent.itSharePresetCancel, { button: 'icon' });
                } else {
                    amplitudeLog(AmplitudeEvent.itSharePresetOpen);
                }
                break;
            case PresetAction.delete:
                if (this.presetAction === action) {
                    amplitudeLog(AmplitudeEvent.itDeletePresetCancel, { button: 'icon' });
                } else {
                    amplitudeLog(AmplitudeEvent.itDeletePresetOpen);
                }
                break;
        }

        this.presetAction = this.presetAction === action ? PresetAction.none : action;
        if (this.presetAction === PresetAction.none) {
            this.isLoadingAction = false;
        }
    }

    public isShowAction(action: string) {
        if (this.presetAction === PresetAction.none && action === PresetAction.share) {
            if (!this.userDataProjectMember) {
                return true;
            }
            if (!this.isAbleToChangeVisibility) {
                return false;
            }
        }
        return this.presetAction === PresetAction.none || this.presetAction === action;
    }

    public async applyAction() {
        if (_.isEmpty(this.isCheckedPresetObj) && !this.newOrder.length) {
            this.presetAction = PresetAction.none;
            return;
        }
        switch (this.presetAction) {
            case PresetAction.share:
                this.isLoadingAction = true;
                await this.changeVisibility();
                await this.loadPresets();
                this.isLoadingAction = false;
                this.presetAction = PresetAction.none;
                break;
            case PresetAction.delete:
                if (_.values(this.isCheckedPresetObj).every((val) => !val)) {
                    this.presetAction = PresetAction.none;
                    return;
                }
                this.isShowDialogDeletePresets = true;
                break;
            case PresetAction.reorder:
                this.isLoadingAction = true;
                await this.changeReorder();
                await this.loadPresets();
                this.isLoadingAction = false;
                this.presetAction = PresetAction.none;
        }
    }

    public cancelAction() {
        switch (this.presetAction) {
            case PresetAction.reorder:
                amplitudeLog(AmplitudeEvent.itReorderPresetCancel, { button: 'cancel' });
                this.triggerPresetListKey++;
                this.newOrder = [];
                break;
            case PresetAction.share:
                amplitudeLog(AmplitudeEvent.itSharePresetCancel, { button: 'cancel' });
                break;
            case PresetAction.delete:
                amplitudeLog(AmplitudeEvent.itDeletePresetCancel, { button: 'cancel' });
                break;
        }

        this.presetAction = PresetAction.none;
        this.isLoadingAction = false;
    }

    public loadPresets() {
        return this.$store.dispatch('loadIssueFilterPresetsByProjectId', {
            projectId: this.projectId,
            isForce: true,
        });
    }

    public async changeReorder() {
        const newOrderUuids = this.newOrder.map((i) => i.guid);
        const oldOrderUuids = this.regularPresets.map((i) => i.uuid);
        amplitudeLog(AmplitudeEvent.itReorderPresetApply, {
            countChangedOrder: newOrderUuids.filter((el, i) => el !== oldOrderUuids[i]).length,
        });
        await this.$store.dispatch('reorderPresets', {
            projectId: this.projectId,
            orderArr: this.newOrder,
        });
    }

    public changeVisibility() {
        const allEntities = _.entries(this.isCheckedPresetObj).map(([uuid, visibility]) => ({
            uuid,
            visibility: Number(visibility),
        }));
        const entities = _.differenceWith(allEntities, this.presets, (entity, preset) => {
            return entity.uuid === preset.uuid && entity.visibility === preset.visibility;
        });
        amplitudeLog(AmplitudeEvent.itSharePresetApply, {
            countShared: _.filter(entities, (i) => i.visibility === 1).length,
            countNotShared: _.filter(entities, (i) => i.visibility === 0).length,
        });
        return this.$store.dispatch('changePresetsVisibility', { projectId: this.projectId, entities });
    }

    public async doDeletePresets() {
        const entities = _.entries(this.isCheckedPresetObj)
            .filter(([, isChecked]) => isChecked)
            .map(([uuid]) => ({ uuid }));
        amplitudeLog(AmplitudeEvent.itDeletePresetApply, { countDelete: entities.length });
        this.isDeletingPresets = true;
        await this.$store.dispatch('removePresets', { projectId: this.projectId, entities });
        await this.loadPresets();
        this.isDeletingPresets = false;
        this.isShowDialogDeletePresets = false;
        this.presetAction = PresetAction.none;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.tracker-presets {
    width: 270px;
}

.preset-list-controls {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .buttons {
        display: flex;
        justify-content: space-between;
    }

    .buttons-left {
        display: flex;
        gap: $default-gap;
    }
}

.action-buttons {
    display: flex;
    flex-direction: row;
}

.delete-presets-button {
    margin-right: 15px;
}
</style>
