import { render, staticRenderFns } from "./RegisterForm.vue?vue&type=template&id=b3a70e8c&scoped=true"
import script from "./RegisterForm.vue?vue&type=script&lang=ts"
export * from "./RegisterForm.vue?vue&type=script&lang=ts"
import style0 from "./RegisterForm.vue?vue&type=style&index=0&id=b3a70e8c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3a70e8c",
  null
  
)

export default component.exports