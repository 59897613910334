import _ from 'lodash';
import { BusEvent, RegionEnum, RESPONSE } from '@/constants';
import { httpParams } from '@/api/httpParams';
import { Region } from '@/models';
import { eventBus } from '@/services/eventBus';
import { CookieHelper } from '@/services/CookieHelper';

const responsesRequiringLogout = [
    RESPONSE.WRONG_SIGNATURE,
    RESPONSE.INVALID_TOKEN,
    RESPONSE.SECURITY_ERROR_BY_AUTH_METHOD,
];

export default abstract class BaseHttp {
    private static regionId: string = CookieHelper.get('region') || RegionEnum.auto;
    private static regionsList: Region[] = [];
    private static logoutCallback: () => void = _.noop;

    public static setLogoutCallback(callback: () => void) {
        BaseHttp.logoutCallback = callback;
    }

    public static setRegionId(regionId: string) {
        BaseHttp.regionId = regionId;
    }

    public static setRegionsList(regionsList: Region[]) {
        BaseHttp.regionsList = regionsList;
    }

    public static getBaseUrl(userRegionId = BaseHttp.regionId) {
        const userRegion = _.find(BaseHttp.regionsList, { id: userRegionId });
        return userRegion?.baseUrl || `${httpParams.protocol}://${httpParams.apiHost}`;
    }

    /**
     * Query interceptor
     */
    protected static transformResponse(response: string): any {
        if (!response) {
            return;
        }
        const responseObj = JSON.parse(response);

        if (responsesRequiringLogout.includes(responseObj.result)) {
            BaseHttp.logoutCallback();
        }
        if (responseObj.result === RESPONSE.TWO_FA_REQUIRED && !responseObj.data?.twoFactorMethod) {
            eventBus.$emit(BusEvent.faRequired);
            return;
        }
        if (responseObj.result !== RESPONSE.SUCCESS && responseObj.result !== RESPONSE.MULTIPLE) {
            throw responseObj;
        }
        return responseObj.data;
    }
}
