<template>
    <div class="component-login-form form-box">
        <template v-if="!isTwoFAStep">
            <div v-if="email" class="enter-password">{{ $t('Login.pleaseEnterPassword') }}</div>

            <v-form ref="credentials" lazy-validation>
                <WsInput
                    v-model="credentials.email"
                    :label="$t('Form.email')"
                    :rules="rules.email"
                    :disabled="Boolean(email)"
                    name="email"
                    input-data-test="rl-email-input"
                    label-data-test="rl-email-label"
                    error-data-test="rl-email-error-text"
                    validate-on-blur
                    autofocus
                    @keyup.enter.native="login"
                />

                <WsInput
                    v-model="credentials.password"
                    :label="$t('Form.pass')"
                    :rules="rules.password"
                    type="password"
                    input-data-test="rl-password-input"
                    label-data-test="rl-password-label"
                    error-data-test="rl-password-error-text"
                    validate-on-blur
                    @keyup.enter.native="login"
                />

                <WsSelect
                    v-if="!email"
                    v-model="credentials.regionId"
                    :items="regionsOptions"
                    :label="$t('Form.region')"
                    :rules="rules.regionId"
                    select-data-test="rl-region-select"
                    items-list-data-test="rl-regions-list"
                    selected-item-data-test="rl-region-select-text"
                    label-data-test="rl-region-select-label"
                    @change="changeRegion"
                />

                <WsButton
                    :loading="isAuthorizing"
                    class="test-login-button"
                    data-test="rl-sign-in-button"
                    type="primary"
                    contained
                    round
                    @click="login"
                >
                    {{ $t('Button.sign_in') }}
                </WsButton>
            </v-form>

            <a
                v-if="email"
                class="navigate-back"
                @click.prevent="returnToLogin"
            >
                &#8592; {{ $t('Login.return') }}
            </a>

            <template v-if="!email">
                <a class="forgot-link" @click.prevent="navigateForgotPassword()">{{ $t('Button.forgot') }}</a>
            </template>

            <div class="flex-delimiter" />
            <div v-if="authError" class="error test-error">{{ authError }}</div>

            <div v-if="!email" class="row-buttons">
                <span class="not-member">{{ $t('support.notMemberYet') }}</span>
                <div class="flex-delimiter" />
                <a
                    class="action-button"
                    href="https://revizto.com/demo-request/"
                    target="_blank"
                    data-test="rl-request-trial-button"
                >
                    {{ $t('support.requestTrial') }}
                </a>
            </div>
        </template>

        <template v-else-if="isTwoFAStep">
            <h1 class="auth-title">{{ $t('TwoFA.label') }}</h1>
            <div class="auth-title-desc">
                <template v-if="isEmailTwoFactorMethod">{{ $t('TwoFA.securityCodeEmail') }}</template>
                <template v-else-if="isGoogleTwoFactorMethod">{{ $t('TwoFA.securityCodeGoogle') }}</template>
            </div>

            <div class="code-form two-fa-code">
                <div
                    v-loading="isAuthorizing"
                    class="loader"
                    :key="`${otpTriggerKey}-${isAuthorizing}`"
                />
                <OTPInput
                    :key="otpTriggerKey"
                    @input="twoFAData.code = $event"
                    @ready="loginTwoFA"
                />

                <div :class="{ show: isInvalidCode }" class="error">
                    {{ hasBeenUsedCode ? $t('TwoFA.hasBeenUsedCodeError') : $t('TwoFA.securityCodeError') }}
                </div>
                <div v-if="isEmailTwoFactorMethod" :class="{ disabled: isDisabledResend }" class="resend-code">
                    <WsButton
                        :disabled="isDisabledResend"
                        @click.prevent="resendCode"
                    >
                        {{ $t('TwoFA.resendCode') }}
                    </WsButton>
                    <div v-show="isDisabledResend">{{ $t('TwoFA.resendTimer', { secondsLeft }) }}</div>
                </div>
            </div>

            <div class="support">
                <div v-if="isEmailTwoFactorMethod" v-html="sanitizeHtml($t('TwoFA.emailProblem'))" />
                <a v-if="isGoogleTwoFactorMethod" @click.prevent="changeTwoFAMethodToEmailForLogin">
                    {{ $t('TwoFA.notAccessGA') }}
                </a>
            </div>

            <div class="flex-delimiter" />

            <a class="navigate-back" @click.prevent="navigateToLoginForm">
                &#8592; {{ $t('Button.return') }}
            </a>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TRulesObj } from '@/types/TRulesObj';
import { AmplitudeEvent, API, CookieName, NoAccessCode, RESPONSE, RouterNames } from '@/constants';
import { License, Region } from '@/models';
import { CookieHelper, FormValidator, RandomService, sanitizeHtml, startTimer } from '@/services';
import { normalizeRegionId } from '@/services/RegionService';
import { amplitudeMixin, autofocusMixin } from '@/mixins';
import OTPInput from '@/components/common/OTPInput.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsSelect from '@/components/common/WsSelect.vue';

enum TwoFactorMethod {
    initial = '',
    email = 'email',
    google = 'google',
}

const Empty = {
    get twoFA() {
        return {
            key: '',
            secret: '',
            twoFactorMethod: TwoFactorMethod.initial,
            code: '',
        };
    },
    get credentials() {
        return {
            email: '',
            password: '',
            regionId: '',
        };
    },
};

@Component({
    components: {
        OTPInput,
        WsButton,
        WsInput,
        WsSelect,
    },
})

export default class LoginForm extends Mixins(autofocusMixin, amplitudeMixin) {
    @Prop({ type: Boolean }) public isOAuth2Redirect!: boolean;
    @Prop({ type: String }) public email!: string;

    public readonly sanitizeHtml = sanitizeHtml;

    public isAuthorizing = false;
    public localAuthError: TranslateResult = '';
    public credentials = Empty.credentials;
    public isTwoFAStep = false;
    public isInvalidCode = false;
    public hasBeenUsedCode = false;
    public isDisabledResend = false;
    public secondsLeft = 0;
    public twoFAData = Empty.twoFA;
    public previousSessionRegionId = this.$store.getters.regionId;
    public isTwoFAAccessCode = false;
    public otpTriggerKey = 1;

    get isEmailTwoFactorMethod() {
        return this.twoFAData.twoFactorMethod === TwoFactorMethod.email;
    }

    get isGoogleTwoFactorMethod() {
        return this.twoFAData.twoFactorMethod === TwoFactorMethod.google;
    }

    get isRequestAccessCode() {
        return this.$route.query.request === API.accessCode;
    }

    get form() {
        return this.$refs.credentials as HTMLFormElement;
    }

    get regionsOptions() {
        const regions = this.isRequestAccessCode ? this.$store.getters.pureRegions : this.$store.getters.regionsList;
        return Region.makeOptions(regions);
    }

    get rules(): TRulesObj {
        return {
            email: [
                FormValidator.email,
            ],
            password: [
                (value: string) => Boolean(value) || this.$t('errors.empty_pass'),
            ],
            ...(this.isRequestAccessCode && {
                regionId: [
                    (value: string) => (this.pureRegionsIds.includes(value) || this.$t('Simple_word.required')),
                ],
            }),
        };
    }

    get pureRegionsIds(): string[] {
        return this.$store.getters.pureRegionsIds;
    }

    get availableLicenses(): License[] {
        return this.$store.getters.licensesWithValidAuth;
    }

    get authError(): TranslateResult {
        return this.localAuthError || this.$store.getters.authError;
    }

    get presetRegionId() {
        return this.$route.params.region || this.$route.query.region;
    }

    get isLoginAccessCode() {
        return this.$route.name === RouterNames.LoginAccessCode;
    }

    get regionName() {
        const regionId = this.$store.getters.regionId;
        return String(this.$t(`Region.${regionId}`));
    }

    @Emit()
    public authorized() {
        return;
    }

    @Emit()
    public returnToLogin() {
        return;
    }

    @Emit()
    public showWelcome(value = true) {
        return value;
    }

    @Emit()
    public showTabs(value: boolean) {
        return value;
    }

    @Watch('email', { immediate: true })
    public onEmailChange() {
        this.credentials.email = this.email;
        this.credentials.password = '';
        this.clearAuthError();
    }

    @Watch('isTwoFAStep')
    public onTwoFAStepChange() {
        this.showTabs(!this.isTwoFAStep);
    }

    public created() {
        this.credentials.regionId = normalizeRegionId(this.presetRegionId || this.previousSessionRegionId);
        if (this.presetRegionId) {
            this.changeRegion(this.credentials.regionId);
        }
    }

    public login() {
        if (this.isAuthorizing) {
            return;
        }
        this.clearAuthError();
        if (this.form.validate()) {
            this.isAuthorizing = true;
            const { email, password } = this.credentials;
            const query = this.$route.query;

            if (this.isRequestAccessCode) {
                // RandomService.deviceId needs to create new independet session
                this.$store.dispatch('authorizeOAuth2', { email, password, deviceId: RandomService.deviceId() })
                    .then((response) => {
                        return response.code
                            ? this.goToAccessCode(response)
                            : Promise.reject(response);
                    }).catch((error) => {
                        this.isTwoFAAccessCode = error.result === RESPONSE.TWO_FA_REQUIRED;
                        this.handleLoginError(error);
                    });
                return;
            }
            if (this.isOAuth2Redirect) {
                this.authOauth2();
                return;
            }

            if (this.isLoginAccessCode) {
                this.$store.dispatch('authorize', { email, password }).then(async () => {
                    await this.$store.dispatch('loadLicenses', true);

                    // In case of no licenses available we need to switch selectLicense component
                    // But we need to pass query data
                    if (!this.availableLicenses.length) {
                        this.$router.push({ name: RouterNames.SelectLicense, query });
                    } else {
                        this.authOauth2();
                    }
                }).catch(this.handleLoginError);
                return;
            }

            this.$store.dispatch('authorize', { email, password }).then(() => {
                if (this.credentials.regionId !== this.previousSessionRegionId) {
                    this.$store.commit('setStartPathAfterLogin', '/');
                }
                this.authorized();
                this.amplitudeLog(AmplitudeEvent.loginSuccessfulAuth);
            }).catch(this.handleLoginError);
        }
    }

    public authOauth2() {
        const { email, password } = this.credentials;
        const query = this.$route.query;

        this.$store.dispatch('authorizeOAuth2', {
            email,
            password,
            authType: 'oauth2redirect',
            isLoginAccessCode: this.isLoginAccessCode,
            query,
        })
        .catch((error) => {
            this.handleLoginError(error);
        });
    }

    public clearAuthError() {
        this.$store.commit('setAuthError', '');
        this.localAuthError = '';
    }

    public handleLoginError(error: any) {
        this.isAuthorizing = false;
        this.checkTwoFA(error);
        this.checkAuthErrors(error);
    }

    public checkTwoFA(error: any) {
        if (error?.result === RESPONSE.TWO_FA_REQUIRED) {
            this.isTwoFAStep = true;
            this.showWelcome(false);
            this.twoFAData = { ...this.twoFAData, ...error.data };
            if (this.isEmailTwoFactorMethod) {
                this.startTimerResendCode();
            }
        }
    }

    public checkAuthErrors(error: any) {
        switch (error.result) {
            case RESPONSE.BANNED_PERMANENTLY:
                this.localAuthError = this.$t('errors.bannedPermanently');
                break;
            case RESPONSE.USER_AUTH_BANNED:
                this.localAuthError = this.$t('errors.authorizationBanned');
                break;
            case RESPONSE.INVALID_DATA:
                this.localAuthError = this.$t('errors.incorrectCredentials');
                break;
            case RESPONSE.INVALID_PARAMS:
                this.localAuthError = this.$t('errors.invalidEmail');
                break;
            case RESPONSE.NO_LICENSES_FOUND:
                if (this.isLoginAccessCode) {
                    this.localAuthError = this.$t('Login.accessCodeNoLicenses', { region: this.regionName });
                } else {
                    this.goToAccessCode({ code: NoAccessCode, licenses: [] });
                }
                break;
            default:
                this.localAuthError = error.message || JSON.stringify(error);
        }

        this.amplitudeLog(AmplitudeEvent.loginUnsuccessfulAuth, {
            localAuthError: this.localAuthError,
        });
    }

    public goToAccessCode(response: any) {
        const { code: accessCode, licenses } = response;
        const shortLicenses = licenses?.map((license: any) => {
            return {
                id: license.id,
                name: license.name,
            };
        });

        const props = this.$router.resolve({
            name: RouterNames.AccessCode,
            query: { accessCode, licenses: JSON.stringify(shortLicenses) },
        });

        if (this.isLoginAccessCode) {
            CookieHelper.setTrue(CookieName.isNeedJsonAccessCode);
        } else {
            CookieHelper.remove(CookieName.isNeedJsonAccessCode);
        }

        location.href = props.href;
    }

    public loginTwoFA() {
        if (this.isAuthorizing) {
            return;
        }
        this.isAuthorizing = true;

        let action;
        switch (true) {
            case (this.isTwoFAAccessCode):
                action = this.$store.dispatch('authorizeTwoFA', { ...this.twoFAData, isAccessCode: true })
                    .then(this.goToAccessCode)
                    .catch((error) => {
                        if (error.result === RESPONSE.NO_LICENSES_FOUND) {
                            this.goToAccessCode({ data: { code: NoAccessCode, licenses: [] } });
                        } else {
                            throw error;
                        }
                    });
                break;
            case (this.isOAuth2Redirect || this.isLoginAccessCode):
                action = this.$store.dispatch('authorizeTwoFA', {
                    ...this.twoFAData,
                    authType: 'oauth2redirect',
                    query: this.$route.query,
                    isLoginAccessCode: this.isLoginAccessCode,
                });
                break;
            default:
                action = this.$store.dispatch('authorizeTwoFA', this.twoFAData).then(this.authorized);
        }

        action.then(() => {
            this.isInvalidCode = false;
            this.hasBeenUsedCode = false;
        }).catch((error) => {
            this.isInvalidCode = true;
            if (error?.message === 'The security code has alredy been used.') {
                this.hasBeenUsedCode = true;
            }
            this.isAuthorizing = false;
            this.otpTriggerKey++;
        });
    }

    public resendCode() {
        this.startTimerResendCode();
        this.$store.dispatch('resendCodeTwoFA', { key: this.twoFAData.key });
    }

    public startTimerResendCode() {
        startTimer(
            60,
            () => this.isDisabledResend = true,
            () => this.isDisabledResend = false,
            (secondsLeft: number) => this.secondsLeft = secondsLeft,
        );
    }

    public navigateToLoginForm() {
        this.isTwoFAStep = false;
        this.showWelcome();
        this.clearAuthError();
        this.isInvalidCode = false;
        this.twoFAData = Empty.twoFA;
    }

    public changeTwoFAMethodToEmailForLogin() {
        this.$store.dispatch('changeTwoFAMethodToEmail', {
            login: this.credentials.email,
            password: this.credentials.password,
            merge: 0,
        }).then((response) => {
            this.twoFAData = Object.assign({}, this.twoFAData, response);
            this.startTimerResendCode();
        });
    }

    public changeRegion(selectedRegionId: string) {
        this.$store.commit('setRegionId', selectedRegionId);
    }

    public navigateForgotPassword() {
        this.$router.replace({ name: RouterNames.ForgotPassword });
    }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.component-login-form .two-fa-code {
    position: relative;
    margin: 10px 0 20px;
    padding-right: 30px;
    text-align: center;

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 30px);
        height: 100%;
        pointer-events: none;
    }

    .error {
        margin: 5px 0;
        text-align: center;
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    .resend-code {
        margin-top: 5px;
        min-height: 50px;
        text-align: center;

        &.disabled {
            opacity: 0.6;

            &, * {
                cursor: default !important;
            }
        }

        button.ws-button {
            display: inline-block;
            padding: 5px 5px 5px 30px;
            color: $primary-blue;
            border: none;
            outline: none;
            font-size: 14px;
            background: url(/images/refresh_icon.png) 5px center no-repeat;
            cursor: pointer;
            width: auto;
        }

        > div {
            line-height: $line-height-large;
        }
    }
}

.component-login-form {
    .support {
        margin: 40px 0;
        color: $link;
        font-size: 14px;
        line-height: $line-height-small;
    }

    .navigate-back {
        margin-top: 20px;
        color: $medium-gray;
    }

    .enter-password {
        margin-bottom: 20px;
        color: $default-black;
    }
}
</style>
