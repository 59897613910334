<template>
    <div class="wrapper">
        <SkeletonBone v-if="options.hasSelect" class="select-square" />
        <SkeletonBone class="main-bone" />
        <SkeletonBone class="second-bone" />
        <SkeletonBone class="second-bone" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class WsTableHeaderSkeleton extends Vue {
    @Prop({ type: Object, default: () => ({}) }) public options!: any;
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.wrapper {
    display: flex;
    width: 100%;
    align-items: center;
}

.select-square {
    width: 20px;
    height: 20px;
    border-radius: $border-radius-small;
    margin-right: 18px;
}

.main-bone {
    width: 87px;
    margin-right: 343px;
}

.second-bone {
    width: 54px;
    margin-right: 90px;
}
</style>
