var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-btn',{class:{
        'v-btn--disabled': (!_vm.isNew && _vm.disabled),
        'ws-button': !_vm.isNew,
        'ws-button-2': _vm.isNew,
        ['spacing-' + _vm.spacing]: true,
        ['type-' + _vm.buttonType]: true,
        'disabled': (_vm.isNew && _vm.disabled),
    },attrs:{"id":_vm.id,"loading":_vm.loading,"rounded":!_vm.isNew && _vm.round,"color":_vm.isNew ? null : _vm.type,"x-small":!_vm.isNew && _vm.isXSmallSize,"small":!_vm.isNew && _vm.isSmallSize,"large":!_vm.isNew && _vm.isLargeSize,"x-large":!_vm.isNew && _vm.isXLargeSize,"fab":_vm.circle,"outlined":_vm.contained ? false : !_vm.isNew ? _vm.outlined : null,"plain":_vm.contained ? false : !_vm.isNew ? _vm.plain : null,"text":!_vm.isNew && _vm.text,"ripple":false,"depressed":""},on:{"click":_vm.onClick}},[(_vm.icon)?_vm._t("icon",function(){return [(_vm.iconSize === '16')?_c('IconSvg16',{attrs:{"color":_vm.iconColor || _vm.iconColorByType,"disabled":_vm.disabled,"icon-name":_vm.icon,"color-active":_vm.iconActionColor || _vm.Color.white,"inline":!_vm.isNew,"parent-hover":"","stateless":"","font-like":""}}):_vm._e(),(_vm.iconSize === '24')?_c('IconSvg24',{attrs:{"color":_vm.iconColor || _vm.iconColorByType,"disabled":_vm.disabled,"icon-name":_vm.icon,"color-active":_vm.iconActionColor || _vm.Color.white,"inline":!_vm.isNew,"parent-hover":"","stateless":"","font-like":""}}):_vm._e()]}):_vm._e(),[_vm._t("default"),(_vm.tooltipContent)?_c('WsTooltip',{attrs:{"tooltip":_vm.tooltipContent,"activator-selector":`#${_vm.id}`,"placement":_vm.placement}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }