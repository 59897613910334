import { TUuid } from '@/types/common';
import { BooleanNumber } from '@/types/BooleanNumber';
import { Dict } from '@/types/Dict';
import { PublicEntity } from '@/constants/public/PublicEntity';
import { MemberRoleEnum } from '@/constants';
import {
    AccessRole,
    BulkResult,
    IBulkResult,
    ILicenseDeletedMemberProp,
    IProjectBulkResult,
    LicenseMember,
} from '@/models';
import { PendingStatus } from '@/storage/licenseMembers.storage';
import http from './http';
import { ISharingInfoResponse } from '@/domain/dashboard/models/Public/SharingInfoResponse';
import { IChangeFavoriteDashboardParams } from '@/domain/dashboard/types/ChangeFavoriteDashboardParams';
import { CachedRawChartData } from '@/domain/chart/models/ChartView';

export interface IMemberActivationParams {
    licenseId: number;
    params: {
        uuids: string[];
    };
}

export interface IProjectRolePair {
    projectUuid: TUuid;
    roleUuid: TUuid;
}
export interface ILicenseDashboardChartViewParams {
    licenseId: number;
    dashboardUuid: string;
    chartUuid: string;
    isCachedData?: boolean;
    isForce?: boolean;
    toDate?: number;
}

export interface ILicenseMembersInviteError {
    code: any;
    email: string;
    member: LicenseMember;
    message: string;
    role: AccessRole[];
}

export interface ILicenseMembersInviteResponse {
    activated: ILicenseMembersInviteError[];
    added: ILicenseMembersInviteError[];
    changed: ILicenseMembersInviteError[];
    guests: ILicenseMembersInviteError[];
    invited: ILicenseMembersInviteError[];
    pending: ILicenseMembersInviteError[];
    slots: {
        busy: number;
        total: number;
    }
}

export interface IMemberUpdateParams {
    data: Array<{
        uuid?: TUuid;
        company?: string;
        department?: string;
        location?: string;
        tagsToAdd?: string[];
        tagsToRemove?: string[];
        authMethodUuid?: string;
        invitationsToProjects?: IProjectRolePair[];
        updateProjectRoles?: IProjectRolePair[];
        removeFromProjects?: TUuid[];
        role?: number;
    }>;
}
export interface IMemberUpdateResponse {
    ok: string[] | null;
    error: Dict<number> | null;
}

export interface IMemberDeactivationParams {
    uuids: string[];
    message?: string;
    operationId?: string;
}

export interface IMemberChangeAuthMethod {
    licenseUuid: string;
    authMethod?: string; // если отсутствует, то отвязывает мемберов от текущего метода
    members: Array<{
        uuid: string;
    }>;
}

export interface IInviteMemberFormData {
    email: string;
    authMethod: string;
    role: MemberRoleEnum;
    firstName?: string;
    lastName?: string;
    tags?: string[];
    company?: string;
    department?: string;
    location?: string;
    invitationsToProjects?: IProjectRolePair[];
}

export interface IInviteLicenseMembersParams {
    data: IInviteMemberFormData[];
    preserveRoles: BooleanNumber;
    makeGuests: BooleanNumber;
    deactivate: BooleanNumber;
    operationId?: string;
}

export interface IChangeLicenseOwnerParams {
    licenseId: number;
    authMethod: string;
    email: string;
    role: number;
    operationId?: string;
}

export interface ISetChartColorsParams {
    colors?: { [key: string]: string; };
    color?: string; // цвет для single color
}

export interface ILicenseMemberStatusResponse {
    count: number;
    entities: Array<{
        email: string;
        status: PendingStatus;
    }>
}

interface IProjectAdministrator {
    uuid: string;
    user: {
        id: number;
        email: string;
        fullname: string;
        uuid: string;
    };
}
interface IProjectAdministrators {
    data: {
        count: number;
        entities: IProjectAdministrator[];
    };
    message: string;
    result: number;
}

interface ILicenseOptions {
    data: {
        id: number;
        uuid: string;
        name: string;
        expires: string;
        region: string;
        licenseOptions: {
            clashAutomation: boolean;
            allowBeExternalGuest: boolean;
            domainsForAllowBeExternalGuest: string[];
            allowBCFExport: boolean;
            allowApiAccess: boolean;
            extraFieldsManagers: string[];
            allowedExtraFieldsManagement: string[];
        };
    };
    message: string;
    result: number;
}

interface ISharingInfoSettings {
    hasLinkToWs: boolean
    password: string
    isProtected: boolean
    isShared: boolean
}

export interface ICreateAdditionalFieldsParams {
    licenseUuid: string;
    fieldType: string;
    fieldValue: string;
    memberUuids: string[];
}

export interface IUpdateAdditionalFieldsParams {
    licenseUuid: string;
    fieldType: string;
    oldFieldValue: string;
    newFieldValue: string;
    addMemberUuids: string[];
    deleteMemberUuids: string[];
}

export interface IDeleteAdditionalFieldsParams {
    licenseUuid: string;
    fieldType: string;
    oldFieldValue: string;
    newFieldValue: string;
}

export default class LicenseApi {
    /**  Получение списка лицензий */
    public static getLicenses(): Promise<{ count: number; entities: any[] }> {
        return http.get('user/licenses').then((response) => response.data);
    }

    /** Получение лицензии */
    public static getLicenseById(licenseId: number): Promise<any> {
        return http.get(`license/${licenseId}`, { withHighLights: 1 }).then((response) => response.data);
    }

    /** Изменение лицензии */
    public static postLicenseSettings(licenseId: number, params: any) {
        return http.postJson(`license/${licenseId}/edit`, params).then((response) => response.data);
    }

    /** Изменение логотипа лицензии */
    public static postLicenseLogo(licenseId: number, file: File) {
        return http.post(`license/${licenseId}/edit`, { logo: file }, true, true).then((response) => response.data);
    }

    /** Удаление логотипа лицензии */
    public static deleteLicenseLogo(licenseId: number) {
        return http.post(`license/${licenseId}/remove-logo`).then((response) => response.data);
    }

    /** Изменение видимости дополнительных полей лицензии */
    public static postVisibilityLicenseExtraFields(licenseId: number, params: any) {
        return http.post(`license/${licenseId}/visible-member-extra-fields`, params).then((response) => response.data);
    }

    /** Получение списка участников лицезии */
    public static getLicenseMembers(licenseId: number, params?: any): Promise<{ count: number; entities: any[] }> {
        return http.get(`license/${licenseId}/team`, params).then((response) => response.data);
    }

    /** Getting members pending statuses */
    public static getLicenseMembersInvitationStatus(licenseId: number, params?: any): Promise<ILicenseMemberStatusResponse> {
        return http.get(`license/${licenseId}/invitation-status`, params).then((response) => response.data);
    }

    /** Получение списка удаленных участников лицезии */
    public static getDeletedLicenseMembers(licenseId: number, params?: any): Promise<ILicenseDeletedMemberProp[]> {
        return http.get(`license/${licenseId}/deleted-members`, params).then((response) => response.data);
    }

    /** Получение списка проектов лицезии */
    public static getLicenseProjects(licenseId: number, params?: any): Promise<{ count: number; entities: any[] }> {
        if (isNaN(licenseId)) {
            return Promise.reject(new Error(`licenseId is not a number: ${licenseId}. Current URL: ${document.URL}`));
        }

        return http.get(`license/${licenseId}/projects`, params).then((response) => response.data);
    }

    /** Получение информации об участнике лицензии */
    public static getLicenseMember(licenseId: number, memberId: number): Promise<any> {
        return http.get(`license/${licenseId}/profile/${memberId}`).then((response) => response.data);
    }

    /** Получение списка проектов конкретного участника лицензии */
    public static getLicenseMemberProjects(licenseMemberUuid: string): Promise<any> {
        return http.get(`member/${licenseMemberUuid}/projects`).then((response) => response.data);
    }

    /** Массовое удаление проектов лицензии */
    public static postGroupDeleteLicenseProjects(params: any): Promise<IProjectBulkResult> {
        return http.post('project/bulk/delete', params).then((response) => response.data);
    }

    /** Массовая архивация проектов лицензии */
    public static postGroupArchiveLicenseProjects(params: any): Promise<IProjectBulkResult> {
        return http.post('project/bulk/archive', params).then((response) => response.data);
    }

    /** Массовая разархивация проектов лицензии */
    public static postGroupRestoreLicenseProjects(params: any): Promise<IProjectBulkResult> {
        return http.post('project/bulk/unarchive', params).then((response) => response.data);
    }

    /** Массовое добавление тегов для проекта лицензии */
    public static postGroupAddLicenseProjectTags(params: any): Promise<any[]> {
        return http.post('project/metatags/add', params).then((response) => response.data);
    }

    /** Массовое добавление тегов для проекта лицензии */
    public static postGroupDeleteLicenseProjectsTags(params: any): Promise<any[]> {
        return http.post('project/metatags/remove', params).then((response) => response.data);
    }

    /** Массовое добавление тегов для участника лицензии */
    public static postGroupAddLicenseMemberTags(params: any): Promise<any[]> {
        return http.post('member/metatags/add', params).then((response) => response.data);
    }

    /** Массовое удаление тегов для участника лицензии */
    public static postGroupDeleteLicenseMemberTags(params: any): Promise<any[]> {
        return http.post('member/metatags/remove', params).then((response) => response.data);
    }

    /** Массовое изменение роли участников в лицензии */
    public static postGroupChangeRole(licenseId: number, params: any): Promise<IBulkResult> {
        return http.post(`license/${licenseId}/edit/role/bulk`, params).then((response) => response.data);
    }

    /** Массовая отправка email */
    public static postGroupSendEmail(licenseId: number, params: any): Promise<IBulkResult> {
        return http.post(`license/${licenseId}/bulk-send-email`, params, true, true).then((response) => response.data);
    }

    /** Массовая активация участников лицензии */
    public static postGroupActivation(licenseId: number, params: { uuids: string[], operationId: string }): Promise<IBulkResult> {
        return http.postSplit(`license/${licenseId}/activate`, params).then((responses) => activationResponsesGrouping(responses));
    }

    /** Массовая деактивация участников лицензии */
    public static postGroupDeactivation(licenseId: number, params: IMemberDeactivationParams): Promise<IBulkResult> {
        return http.postSplit(`license/${licenseId}/deactivate`, params).then((responses) => activationResponsesGrouping(responses));
    }

    /** Массовое удаление участников лицензии */
    public static postGroupDeletion(licenseId: number, params: IMemberDeactivationParams): Promise<IBulkResult> {
        return http.postSplit(`license/${licenseId}/remove-member/bulk`, params).then((responses) => activationResponsesGrouping(responses));
    }

    /** Приглашение юзеров в лицензию */
    public static postGroupInvitation(licenseId: number, params: any) {
        return http.postSplit(`license/${licenseId}/invite`, params);
    }

    /** Изменение дополнительных полей мемберов */
    public static postGroupUpdateAdditionalFields(licenseId: number, params: IMemberUpdateParams): Promise<IMemberUpdateResponse> {
        const data = JSON.stringify(params.data);
        return http.post(`license/${licenseId}/update-members`, { data }).then((response) => response.data);
    }

    public static postInviteLicenseMembers(licenseId: number, params: IInviteLicenseMembersParams): Promise<ILicenseMembersInviteResponse> {
        const data = JSON.stringify(params.data);
        return http.post(`license/${licenseId}/invite/bulk`, { ...params, data }).then((response) => response.data);
    }

    public static postCheckPaid(licenseId: number, params: any) {
        params.emails = JSON.stringify(params.emails);
        return http.post(`license/${licenseId}/check-guests`, params).then((response) => response.data);
    }

    /** Получение информации об активности участников лицензии */
    public static postLicenseMembersActivity(licenseId: number, params: any): Promise<any> {
        return http.post(`events/${licenseId}/full-license-stats`, params).then((response) => response.data);
    }

    /** Получение информации об активности конкретного участника лицензии */
    public static postLicenseMemberActivity(licenseId: number, memberId: number, params: any): Promise<any> {
        return http.post(`events/${licenseId}/${memberId}/stat`, params).then((response) => response.data);
    }

    /** Отправка на почту информации об участниках лицензии */
    public static postExportLicenseMembers(licenseId: number, params: any): Promise<any> {
        return http.post(`license/${licenseId}/send-team-to-email`, params);
    }

    /** Отправка на почту информации об активности участников лицензии */
    public static postExportLicenseMemberActivity(params: any): Promise<any> {
        return http.post('events/for-license-to-email', params);
    }

    /** Отправка на почту информации об активности в проектах лицензии */
    public static postExportLicenseProjectsActivity(licenseId: number, params: any): Promise<any> {
        return http.post(`license/${licenseId}/send-projects-to-email`, params);
    }

    /** Получение списка дашбордов */
    public static getLicenseDashboards(licenseId: number) {
        return http.get(`license/${licenseId}/dashboard/list`).then((response) => response.data);
    }

    /** Создание дашборда */
    public static postCreateLicenseDashboard(licenseId: number, params: any) {
        return http.post(`license/${licenseId}/dashboard/add`, params).then((response) => response.data);
    }

    /** Сохранение параметров дашборда */
    public static postUpdateLicenseDashboard(licenseId: number, dashboardUuid: string, params: any) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/edit`, params).then((response) => response.data);
    }

    /** Удаление дашборда */
    public static postDeleteLicenseDashboard(licenseId: number, dashboardUuid: string) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/remove`).then((response) => response.data);
    }

    /**  Изменение сортировки графиков в дашборде */
    public static postChangeOrderLicenseDashboardCharts(licenseId: number, dashboardUuid: string, params: any) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/reorder`, params).then((response) => response.data);
    }

    /** Получение списка графиков дашборда */
    public static getLicenseDashboardCharts(licenseId: number, dashboardUuid: string) {
        return http.get(`license/${licenseId}/dashboard/${dashboardUuid}/graphs`).then((response) => response.data);
    }

    /** Получение всех данных графика */
    public static getLicenseDashboardChartSettings(licenseId: number, dashboardUuid: string, chartUuid: string) {
        return http.get(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/view`).then((response) => response.data);
    }

    /** Создание нового графика лицензионного дашборда */
    public static postCreateLicenseDashboardChart(licenseId: number, dashboardUuid: string, params: any) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/add-graph`, params).then((response) => response.data);
    }

    /** Сохранение параметров графика лицензионного дашборда */
    public static postUpdateLicenseDashboardChart(licenseId: number, dashboardUuid: string, chartUuid: string, params: any) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/edit`, params).then((response) => response.data);
    }

    /**
     * Клонирование графика лицензионного дашборда
     */
    public static postCloneLicenseDashboardChart(licenseId: number, dashboardUuid: string, chartUuid: string) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/clone`).then((response) => response.data);
    }

    /** Удаление графика лицензионного дашборда */
    public static postDeleteLicenseDashboardChart(licenseId: number, dashboardUuid: string, chartUuid: string) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/delete`).then((response) => response.data);
    }

    /** Получение данных графика необходимых для его отрисовки */
    public static getLicenseDashboardChartView({ licenseId, dashboardUuid, chartUuid, isCachedData, toDate = 0 }: ILicenseDashboardChartViewParams): Promise<CachedRawChartData> {
        const params = { force: Number(!isCachedData) } as any;
        if (toDate) {
            params.toDate = toDate;
        }
        return http.get(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/data`, params).then((response) => response.data);
    }

    /** Сохранение цветов чарта */
    public static postLicenseChartColors(licenseId: number, dashboardUuid: string, chartUuid: string, params: ISetChartColorsParams) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/colors`, params).then((response) => response.data);
    }

    /** Сохранение размеров чарта */
    public static postLicenseChartSize(licenseId: number, dashboardUuid: string, chartUuid: string, params: { size: number; }) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/size`, params).then((response) => response.data);
    }

    /** Получение данных графика необходимых для его отрисовки на основе заданных параметров */
    public static getLicenseDashboardChartPreview({ licenseId, dashboardUuid, params }: { licenseId: number, dashboardUuid: string, params: any }) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/preview`, params).then((response) => response.data);
    }

    /** Получение точек для таймлайна */
    public static getLicenseDashboardTimelineDots(licenseId: number, dashboardUuid: string, params: any): Promise<number[]> {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/timeline-dots`, params).then((response) => response.data);
    }

    /** Получение списка ролей для проектов в лицензии */
    public static getProjectRoles(licenseId: number) {
        return http.get(`license/${licenseId}/role/list`).then((response) => response.data);
    }

    /**
     * Получение списков email'ов assignees и reporters, каждый из которых содержит полный список assignees и reporters,
     * как участников проекта, так и тех кто участниками проекта не является
     */
    public static getFieldVariants(licenseId: number) {
        return http.get(`license/${licenseId}/issue/field-variants`).then((response) => response.data);
    }

    /** Метод для единоразовой отправки графика на любое количество email */
    public static postSendChartToEmail(licenseId: number, dashboardUuid: string, chartUuid: string, params: any) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/send-to-email`, params).then((response) => response.data);
    }

    /** Обновление данных периодической доставки графика */
    public static postUpdateChartDeliverySettings(licenseId: number, dashboardUuid: string, chartUuid: string, params: any): Promise<any> {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/graph/${chartUuid}/update-delivery-settings`, params).then((response) => response.data);
    }

    /** Единоразовая отправка дашборда на любое количество email */
    public static postDashboardSendToEmail(licenseId: number, dashboardUuid: string, params: any) {
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/send-to-email`, params).then((response) => response.data);
    }

    /** Настройки периодической отправки дашборда */
    public static postDashboardSetDelivery(licenseId: number, dashboardUuid: string, params: any) {
        params.includeCompanyLogo = Number(params.includeCompanyLogo); // так как в апишку нужны инты, а в модели булы
        return http.post(`license/${licenseId}/dashboard/${dashboardUuid}/update-delivery-settings`, params).then((response) => response.data);
    }

    /** Получение списка пресетов проектных дашбордов лицензии */
    public static getProjectDashboardPresets(licenseId: number): Promise<any> {
        return http.get(`license/${licenseId}/dashboard-presets`).then((response) => response.data);
    }

    /** Удаление проектных пресетов */
    public static postRemovePreset(licenseId: number, params: any) {
        return http.post(`license/${licenseId}/dashboard-presets/remove`, params).then((response) => response.data);
    }

    /** Получение всех уровней доступа с правами в лицензии */
    public static postAccessRolesPermissions(licenseId: number, params: any): Promise<any> {
        return http.postSplit(`license/${licenseId}/roles`, params);
    }

    /** Получение данных о правах для конкретного уровня доступа в лицензии */
    public static getAccessRolePermissions(licenseId: number, accessRoleId: number): Promise<any> {
        return http.get(`license/${licenseId}/role`, { id: accessRoleId }).then((response) => response.data);
    }

    /** Создание уровня доступа в лицензии */
    public static postCreateAccessRole(licenseId: number, accessRole: AccessRole): Promise<any> {
        return http.post(`license/${licenseId}/role/create`, {
            name: accessRole.name,
            permissions: JSON.stringify(accessRole.permissions),
        }).then((response) => response.data);
    }

    /** Изменение уровня доступа в лицензии */
    public static postUpdateAccessRole(licenseId: number, accessRole: AccessRole): Promise<any> {
        return http.post(`license/${licenseId}/role/edit`, {
            id: accessRole.id,
            name: accessRole.name,
            permissions: JSON.stringify(accessRole.permissions),
        });
    }

    /** Удаление уровня доступа в лицензии */
    public static postDeleteAccessRole(licenseId: number, accessRole: AccessRole, migrateId?: number): Promise<any> {
        return http.post(`license/${licenseId}/role/delete`, {
            id: accessRole.id,
            migrateId,
        });
    }

    /** Изменение владельца лицензии */
    public static postChangeLicenseOwner({ licenseId, authMethod, email, role, operationId }: IChangeLicenseOwnerParams): Promise<any> {
        return http.post(`license/${licenseId}/owner/change`, { authMethod, email, role, operationId }).then((response) => response.data);
    }

    /** Получение списка отправленных юзеру писем */
    public static getSentEmailByLicenseUuid(licenseUuid: string, page: number): Promise<any> {
        return http.get(`departure/license/${licenseUuid}`, { page }).then((response) => response.data);
    }

    /**
     * Метод для resend приглашения в лицензию
     */
    public static postResendLicenseInvitations(licenseId: number, params: any) {
        return http.post(`license/${licenseId}/invite/resend`, params).then((response) => response.data);
    }

    /** Получение списка методов аутентификации мемберов лицензии */
    public static getAuthMethods(licenseUuid: string): Promise<any> {
        return http.get(`auth-method/list/${licenseUuid}`).then((response) => response.data);
    }

    /** Создание метода аутентификации */
    public static postCreateAuthMethod(params: any): Promise<any> {
        return http.post('auth-method/create', params).then((response) => response.data);
    }

    /** Изменение метода аутентификации */
    public static postUpdateAuthMethod(params: any): Promise<any> {
        return http.postJson('auth-method/update', params).then((response) => response.data);
    }

    /** Переключение активности метода аутентификации */
    public static postChangeActivityAuthMethod(params: any): Promise<any> {
        return http.post('auth-method/toggle', params).then((response) => response.data);
    }

    /** Удаление метода аутентификации */
    public static postDeleteAuthMethod(params: { uuid: string, switchUuid: string }): Promise<any> {
        return http.post('auth-method/delete', params).then((response) => response.data);
    }

    public static getPendingMembers(licenseId: number): Promise<any> {
        return http.get(`license/${licenseId}/pending`).then((response) => response.data);
    }

    public static rejectPendingMembers({ licenseId, projectUuid, emails, message }: { licenseId: number, projectUuid: string, emails: string[], message: string }) {
        return http.post(`license/${licenseId}/reject-pending`, { emails, message, projectUuid });
    }

    /**
     * Смена аутентификационного метода
     * по всем параметрам шлем uuid-ы
     */
    public static postChangeAuthMethod({ licenseUuid, authMethod, members }: IMemberChangeAuthMethod): Promise<IBulkResult> {
        return http.post(`license/${licenseUuid}/change-members-auth-method`, {
            authMethod,
            members: JSON.stringify(members),
        }).then((response) => response.data);
    }

    /**
     * Добавление дашбордов в фаворитные
     */
    public static postAddFavoriteProjectDashboards({ licenseId, params }: { licenseId: string, params: IChangeFavoriteDashboardParams }) {
        return http.post(`license/${licenseId}/favorite-dashboard/add`, params).then((response) => response.data);
    }

    /**
     * Удаление дашбордов из фаворитных
     */
    public static postRemoveFavoriteProjectDashboards({ licenseId, params }: { licenseId: string, params: IChangeFavoriteDashboardParams }) {
        return http.post(`license/${licenseId}/favorite-dashboard/remove`, params).then((response) => response.data);
    }

    public static getProjectAdministrators(licenseUuid: string): Promise<IProjectAdministrators> {
        return http.get(`license/${licenseUuid}/project-administrators`).then((response) => response.data);
    }

    public static postSetLicenseOptions(licenseUuid: string, options: any): Promise<ILicenseOptions> {
        return http.postJson(`license/${licenseUuid}/options`, options).then((response) => response.data);
    }

    /**
     * Dashboard sharing
     */
    public static getDashboardSharingInfo(licenseUuid: string, options: {
        entityType: PublicEntity,
        entityUuid: TUuid,
    }): Promise<ISharingInfoResponse> {
        return http.get(`license/${licenseUuid}/sharing-info/${options.entityType}/${options.entityUuid}`).then((response) => response.data);
    }

    public static postChangeItemSharingSettings(licenseUuid: string, options: {
        entityType: PublicEntity,
        entityUuid: TUuid,
        settings: ISharingInfoSettings,
    }): Promise<ISharingInfoResponse> {
        return http.postJson(`license/${licenseUuid}/sharing-info/${options.entityType}/${options.entityUuid}`, options.settings).then((response) => response.data);
    }

    /**
     * Delete a license
     */
    public static deleteLicense(licenseUuid: string) {
        return http.post(`license/${licenseUuid}/delete`).then((response) => response.data);
    }

    /** Create new additional field */
    public static createAdditionalField(params: ICreateAdditionalFieldsParams): Promise<any[]> {
        return http.post('member/additionalField/create', params).then((response) => response.data);
    }

    /** Update additional field */
    public static updateAdditionalField(params: IUpdateAdditionalFieldsParams): Promise<any[]> {
        return http.post('member/additionalField/update', params).then((response) => response.data);
    }

    /** Delete additional field */
    public static deleteAdditionalField(params: IDeleteAdditionalFieldsParams): Promise<any[]> {
        return http.post('member/additionalField/delete', params).then((response) => response.data);
    }
}

function activationResponsesGrouping(array: Array<{ data: IBulkResult }>): BulkResult {
    const params = array.reduce((accum, next) => {
        if (next.data.ok) {
            accum.ok = {
                ...accum.ok,
                ...next.data.ok,
            };
        }
        if (next.data.error) {
            accum.error = {
                ...accum.error,
                ...next.data.error,
            };
        }
        return accum;
    }, { ok: {}, error: {} });
    return new BulkResult(params);
}
