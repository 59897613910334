<template>
    <v-navigation-drawer
        v-model="visible"
        :temporary="temporary"
        :absolute="absolute"
        :right="right"
        :hide-overlay="hideOverlay"
        :width="width"
        :stateless="stateless"
        @transitionend="open"
    >
        <div class="ws-sidebar">
            <div class="header">
                <div class="slot-header">
                    <slot name="header" />
                </div>
            </div>
            <div class="body">
                <div class="slot-body">
                    <slot name="body" />
                </div>
            </div>
            <div class="footer">
                <div class="slot-footer">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { SIDEBAR_DEFAULT_WIDTH } from '@/constants';

@Component
export default class WsSidebar extends Vue {
    @Prop({ default: false, type: Boolean }) public show!: boolean;
    @Prop({ default: true, type: Boolean }) public temporary!: boolean;
    @Prop({ default: true, type: Boolean }) public absolute!: boolean;
    @Prop({ default: false, type: Boolean }) public fixed!: boolean;
    @Prop({ default: true, type: Boolean }) public right!: boolean;
    @Prop({ default: true, type: Boolean }) public stateless!: boolean;
    @Prop({ default: true, type: Boolean }) public hideOverlay!: boolean;
    @Prop({ default: SIDEBAR_DEFAULT_WIDTH, type: Number }) public width!: number;
    @Prop({ default: 0, type: Number }) public topOffset!: number;

    @Emit()
    public open() {}

    @Emit()
    public close(value: boolean) {
        return value;
    }

    get visible() {
        return this.show;
    }

    set visible(value: boolean) {
        if (!value) {
            this.close(value);
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
$fixedSize: 56px;

.ws-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    height: $fixedSize;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 24px;
}

.body {
    flex-grow: 1;
    @include scrollbarNew;
}

.footer {
    height: $fixedSize;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 24px;
}

.slot-header {
    padding: 12px 0 11px;
    border-bottom: 1px solid $light-solid-10;
    height: $fixedSize;
}

.slot-footer {
    padding: 12px 0 18px;
    border-top: 1px solid $light-solid-10;
}

.slot-body {
    padding: 16px 16px 18px 24px;
}
</style>
