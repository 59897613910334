<template>
    <WsDialog
        :title="$t('Collocation.printToPdf')"
        fullscreen
        separated-buttons
        fixed-buttons
        @close="emitClose"
    >
        <div class="print-dialog">
            <div class="preview" v-loading="isLoadingPdf">
                <embed
                    v-if="issuesPdfUrl && !isPdfPrintError"
                    :src="`${issuesPdfUrl}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`"
                    width="100%"
                    height="100%"
                    class="amp-block"
                >
                <div v-if="isPdfPrintError" class="pdf-print-error">
                    {{ $t('IssueTracker.print.documentTooLarge') }}
                    <router-link :to="{ name: RouterNames.ProjectReports, params: $route.params }" class="link">
                        {{ $t('IssueTracker.print.generateReport') }}
                    </router-link>
                </div>
            </div>
            <div class="pdf-options">
                <h3>{{ $t('SendNow.pdfTitle') }}</h3>
                <WsCheckbox
                    v-for="(setting, idx) in pdfPrintSettings"
                    :value="setting.value"
                    :key="setting.name"
                    :label="$t(setting.translationCode)"
                    :hide-details="idx !== pdfPrintSettings.length - 1"
                    @input="printPdfForm[setting.name] = $event"
                />
                <WsSelect
                    v-model="printPdfForm.previewSize"
                    :label="$t('DeliveryOptions.previewSize.label')"
                    :items="PreviewSizeArray"
                    translation-key="DeliveryOptions.previewSize"
                />
                <WsSelect
                    v-model="printPdfForm.customFields"
                    :multiple="customFields.multiple"
                    :items="customFields.options"
                    :label="$t(customFields.label)"
                    :translation-key="customFields.translationCode"
                    clearable
                    searchable
                    allow-select-all
                />

                <template v-if="issuesUuids.length > 1">
                    <h3>{{ $t('DeliveryOptions.orderByLabel') }}</h3>

                    <div class="order-by-form">
                        <template v-for="(sort, sortIdx) in printPdfForm.pdfSort">
                            <div :key="sort.field" class="sorting-block">
                                <WsSelect
                                    v-model="printPdfForm.pdfSort[sortIdx].field"
                                    :items="availableSortVariants(sortIdx)"
                                    item-value="name"
                                    class="order-what"
                                    searchable
                                    hide-details
                                />
                                <WsSelect
                                    v-model="printPdfForm.pdfSort[sortIdx].direction"
                                    :items="Object.keys(pdfSort.directions)"
                                    class="order-how"
                                    hide-details
                                />
                                <WsButton
                                    v-if="buttonTypesArr[sortIdx] === ButtonTypes.close"
                                    :outlined="false"
                                    size="xsmall"
                                    icon="x"
                                    class="field-action-button"
                                    circle
                                    @click="removeOrder(sortIdx)"
                                />
                            </div>
                            <WsSelect
                                v-if="additionalOrderFields[sortIdx]"
                                v-model="printPdfForm.pdfSort[sortIdx].values"
                                :key="`select${sort.field}`"
                                :items="additionalOrderFields[sortIdx]"
                                :placeholder="$t('Placeholder.select')"
                                class="tags-select"
                                multiple
                                hide-details
                            />
                        </template>
                        <div v-if="printPdfForm.pdfSort.length < MaxCountSorts" class="adding-button">
                            <ChartSettingsInlineButton icon="plus-bold" @click="addOrder">
                                {{ $t('DeliveryOptions.addSort') }}
                            </ChartSettingsInlineButton>
                        </div>
                    </div>
                </template>

                <WsSelect
                    v-for="setting in dateTimeSettings"
                    :value="setting.value"
                    :label="$t(setting.label)"
                    :key="setting.name"
                    :items="Object.values(setting.options)"
                    :translation-key="setting.translationCode"
                    @input="printPdfForm[setting.name] = $event"
                />
            </div>
        </div>
        <template #buttons>
            <WsButton
                :loading="isLoadingSaveToPdf && !isPdfPrintError"
                :disabled="isPdfPrintError"
                round
                @click="saveIssuesToPdf"
            >
                {{ $t('Button.save') }}
            </WsButton>
            <WsButton round @click="emitClose">{{ $t('Button.cancel') }}</WsButton>
        </template>
    </WsDialog>
</template>

<script lang="ts">
import _ from 'lodash';
import axios from 'axios';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { DateFormat, PreviewSize, PreviewSizeArray, RouterNames, SortDirections, TimeFormat } from '@/constants';
import { FormCheckbox, FormSelect, FormSort, FormSortField, FormSortItem, Project } from '@/models';
import { saveFile, shallowDeboolifyObject } from '@/services';
import WsDialog from '@/components/common/WsDialog.vue';
import WsSelect from '@/components/common/WsSelect.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import ChartSettingsInlineButton from '@/domain/chart/components/ChartSettingsInlineButton.vue';

const customFieldsList = [
    'type',
    'tags',
    'watchers',
    'clash_level',
    'public',
    'sheet',
    'clash_test',
    'clash_discipline',
    'clash_distance',
    'clash_distance_m',
    'clash_distance_mm',
    'clash_category',
    'clash_source_file',
    'clashGrid',
    'clash_grid_x',
    'clash_grid_y',
    'clash_room',
    'clash_space',
    'clash_area',
    'clash_zone',
];

const ButtonTypes = Object.freeze({
    plus: 'plus',
    close: 'close',
});

@Component({
    components: {
        ChartSettingsInlineButton,
        WsButton,
        WsCheckbox,
        WsDialog,
        WsSelect,
    },
})
export default class IssuesPrintToPdfModal extends Vue {
    @Prop({ default: () => [] }) public issuesUuids!: string[];

    public readonly RouterNames = RouterNames;
    public readonly ButtonTypes = ButtonTypes;
    public readonly MaxCountSorts = 3;
    public readonly PreviewSizeArray = PreviewSizeArray;

    public readonly debouncedLoadPdf = _.debounce(this.loadIssuesPdf, 500);

    public printPdfForm: any = {
        title: 'A title',
        uuids: this.issuesUuids,
        format: 2, // only value 2 is possible, meaning pdf — https://revizto.atlassian.net/browse/WEB-8711
        includeUserComments: false,
        includeAttachments: false,
        includeFieldChanges: false,
        includeMarkupChanges: false,
        previewSize: PreviewSize.SMALL_IMAGES,
        dateFormat: DateFormat['DD/MM/YYYY'],
        timeFormat: TimeFormat['24h'],
        pdfSort: [{ direction: SortDirections.asc, field: 'id' }],
    };
    public isLoadingPdf: boolean = false;
    public loadIssuesPdfCount = 0;
    public isPdfPrintError: boolean = false;
    public issuesPdfUrl: string = '';
    public isLoadingSaveToPdf: boolean = false;
    public issuesPdfName: string = '';

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get sortFieldsList(): FormSortField[] {
        return [
            { name: 'id' },
            { name: 'tags', additionalField: this.project.issueTags },
            { name: 'title' },
            { name: 'public' },
            { name: 'sheet' },
            { name: 'clash_test' },
            { name: 'customStatus' },
            { name: 'customType' },
            { name: 'assignee' },
            { name: 'reporter' },
            { name: 'priority' },
            { name: 'stamp' },
            { name: 'stamp_color' },
            { name: 'created' },
            { name: 'deadline' },
            { name: 'clash_level' },
            { name: 'clash_room' },
            { name: 'clash_space' },
            { name: 'clash_area' },
            { name: 'clash_zone' },
        ].map(FormSortField.instantiate);
    }

    get allPdfPrintSettings() {
        return {
            includeUserComments: new FormCheckbox({
                name: 'includeUserComments',
                translationCode: 'IssueTracker.print.includeUserComments',
                value: this.printPdfForm.includeUserComments,
            }),
            includeAttachments: new FormCheckbox({
                name: 'includeAttachments',
                translationCode: 'IssueTracker.print.includeAttachments',
                value: this.printPdfForm.includeAttachments,
            }),
            includeFieldChanges: new FormCheckbox({
                name: 'includeFieldChanges',
                translationCode: 'IssueTracker.print.includeFieldChanges',
                value: this.printPdfForm.includeFieldChanges,
            }),
            includeMarkupChanges: new FormCheckbox({
                name: 'includeMarkupChanges',
                translationCode: 'IssueTracker.print.includeMarkupChanges',
                value: this.printPdfForm.includeMarkupChanges,
            }),
            customFields: new FormSelect({
                name: 'customFields',
                label: 'IssueTracker.print.additionalFields',
                translationCode: 'DeliveryOptions.fields',
                options: customFieldsList,
                multiple: true,
                value: this.printPdfForm.customFields,
            }),
            pdfSort: new FormSort({
                name: 'pdfSort',
                label: 'DeliveryOptions.orderByLabel',
                options: this.sortFieldsList,
                directions: SortDirections,
                sorts: Object.values(this.sortFieldsList.reduce((acc, next) => {
                    if (this.printPdfForm.pdfSort.find((sort: any) => {
                        if (next.name === sort.field) {
                            next.directionValue = sort.direction;
                            next.values = sort.values;
                            return next;
                        }
                    })) {
                        acc[next.name] = new FormSortItem({ field: next, direction: next.directionValue });
                    }
                    return acc;
                }, { [this.sortFieldsList[0].name]: new FormSortItem({ field: this.sortFieldsList[0] }) })),
            }),
            timeFormat: new FormSelect({
                name: 'timeFormat',
                label: 'TimeFormat.label',
                translationCode: 'TimeFormat',
                options: TimeFormat,
                value: this.printPdfForm.timeFormat,
            }),
            dateFormat: new FormSelect({
                name: 'dateFormat',
                label: 'DateFormat.label',
                translationCode: 'DateFormat',
                options: DateFormat,
                value: this.printPdfForm.dateFormat,
            }),
        };
    }

    get pdfPrintSettings() {
        return [
            this.allPdfPrintSettings.includeUserComments,
            this.allPdfPrintSettings.includeAttachments,
            this.allPdfPrintSettings.includeFieldChanges,
            this.allPdfPrintSettings.includeMarkupChanges,
        ];
    }

    get customFields() {
        const customFields = _.cloneDeep(this.allPdfPrintSettings.customFields);
        const options = Object.keys(customFields.options);
        return { ...customFields, options };
    }

    get pdfSort() {
        return this.allPdfPrintSettings.pdfSort;
    }

    get buttonTypesArr() { // type of button(s) (close vs plus) to the right of the selects
        const max = 3;
        const numSorts = this.printPdfForm.pdfSort.length;
        switch (numSorts) {
            case max:
                return Array(max).fill(ButtonTypes.close);
            case 1:
                return [ButtonTypes.plus];
            default:
                return Array(numSorts).fill(ButtonTypes.close).concat(ButtonTypes.plus);
        }
    }

    get freeOptions() {
        return this.pdfSort.options.filter((option) => !this.isSelectedOption(option.name));
    }

    get additionalOrderFields() {
        const optionsWithAdditionalFields = this.pdfSort.options.filter((option) => option.additionalField);
        return (this.printPdfForm.pdfSort || []).map(({ field }: any) => {
            const option = optionsWithAdditionalFields.find((el) => el.name === field);
            if (!option) {
                return null;
            }
            return option.additionalField;
        });
    }

    get dateTimeSettings() {
        return [
            this.allPdfPrintSettings.timeFormat,
            this.allPdfPrintSettings.dateFormat,
        ];
    }

    @Watch('printPdfForm', { immediate: true, deep: true })
    public printPdfFormChanged() {
        this.debouncedLoadPdf();
    }

    @Emit('close')
    public emitClose() {
        return;
    }

    public async loadIssuesPdf() {
        const localCount = ++this.loadIssuesPdfCount;
        this.isLoadingPdf = true;

        let url;
        try {
            url = await this.getIssuesPdfUrl();
        } catch {
            this.isPdfPrintError = true;
            this.issuesPdfUrl = '';
            this.isLoadingPdf = false;
            this.isLoadingSaveToPdf = false;
            return;
        }
        if (localCount !== this.loadIssuesPdfCount) {
            return;
        }

        try {
            const { data }: { data: Blob } = await axios({
                url,
                method: 'GET',
                responseType: 'blob',
            });
            this.issuesPdfUrl = URL.createObjectURL(data);
        } catch {
            this.isPdfPrintError = true;
            this.issuesPdfUrl = '';
        } finally {
            this.isLoadingSaveToPdf = false;
            this.isLoadingPdf = false;
        }
    }

    public async getIssuesPdfUrl() {
        const form = shallowDeboolifyObject(this.printPdfForm);
        const { url } = await this.$store.dispatch('postIssuesToPdf', {
            projectId: this.projectId,
            params: {
                format: 2,
                ..._.omit(form, 'pdfSort'),
                reportSort: form.pdfSort,
            },
        });
        this.issuesPdfName = url.replace(/^.*[\\/]/, '');
        return url;
    }

    public availableSortVariants(idx: number) {
        const occupiedItemsNames = this.printPdfForm.pdfSort
            .filter((el: any, i: number) => i !== idx)
            .map((el: any) => el.field);

        return this.pdfSort.options.filter((option) => !occupiedItemsNames.includes(option.name)).map((item) => {
            return {
                ...item,
                text: this.$t('DeliveryOptions.fields.' + item.name),
            };
        });
    }

    public addOrder() {
        const newSort = this.freeOptions[0];
        this.printPdfForm.pdfSort.push({
            field: newSort.name,
            direction: SortDirections.asc,
        });
    }

    public isSelectedOption(optionName: string) {
        return this.printPdfForm.pdfSort.map((option: any) => option.field).includes(optionName);
    }

    public removeOrder(idx: number) {
        this.printPdfForm.pdfSort = this.printPdfForm.pdfSort.filter((el: any, i: number) => i !== idx);
    }

    public saveIssuesToPdf() {
        this.isLoadingSaveToPdf = true;

        const interval = setInterval(() => {
            if (this.isPdfPrintError) {
                clearInterval(interval);
                return;
            }
            if (this.isLoadingPdf) {
                return;
            }

            clearInterval(interval);
            saveFile(this.issuesPdfUrl, this.issuesPdfName);
            this.isLoadingSaveToPdf = false;
            this.emitClose();
        }, 200);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

$vuetify-border-color: rgba(0, 0, 0, 0.42);

.print-dialog {
    display: grid;
    grid-template-columns: 1fr 500px;
    height: 100%;
    .preview {
        height: 100%;
        text-align: center;
    }
}
.pdf-options {
    margin-left: 30px;
    div.v-text-field {
        margin-bottom: 15px;
    }
    h3 {
        text-align: left;
    }
    div.v-input--checkbox {
        margin-bottom: 20px;
    }
    .sorting-block {
        margin-bottom: 10px;
    }
}
.pdf-print-error {
    margin-top: 40%;
}
.order-by {
    display: flex;
    justify-content: space-between;
    & > *:first-child {
        margin-top: 6px;
        margin-right: 10px;
    }
    .order-by-form {
        flex: 1;
        padding: 20px 10px 0;
        margin-bottom: 30px;
        border: $border;
    }
}

.sorting-block {
    width: 100%;
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 30px;
    grid-gap: 10px;

    > div {
        width: 100%;
    }

    & > *:not(:last-child) {
        margin-right: 8px;
    }
}

::v-deep .ws-button.v-btn--fab.v-btn--plain {
    border-color: $vuetify-border-color;
    &:hover {
        border-color: $primary-blue;
    }
    .order-by & {
        margin-top: 2px;
    }
}
.order-what {
    width: 70%;
}
.order-how {
    width: 30%;
}
.tags-select {
    margin-bottom: 25px;
}
</style>
