<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M43.5858 15.0001L37.2929 8.70718L38.7071 7.29297L47.4142 16.0001L38.7071 24.7072L37.2929 23.293L43.5858 17.0001H14V15.0001H43.5858Z"
            fill="black"
        />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType9 extends BaseIcon {}
</script>
