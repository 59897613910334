<template>
    <router-link
        v-if="isHasLink"
        class="user-avatar amp-block"
        :to="{ name: RouterNames.LicenseMemberSettings, params: { licenseId, memberId, language } }"
    >
        <img :src="avatarLink" alt="" fetchpriority="low">
    </router-link>
    <span v-else class="user-avatar amp-block">
        <img :src="avatarLink" alt="" fetchpriority="low">
    </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MemberStatusEnum, RouterNames } from '@/constants';
import { License } from '@/models';

@Component
export default class MemberAvatar extends Vue {
    @Prop({ required: true }) public memberId!: number;
    @Prop({ required: true }) public avatarSrc!: string;
    @Prop() public status!: MemberStatusEnum;
    @Prop({ type: Boolean, default: false }) public noLink!: boolean;

    public RouterNames = RouterNames;
    public MemberStatusEnum = MemberStatusEnum;

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }
    get license(): License {
        return this.$store.getters.currentLicense;
    }
    get language(): string {
        return this.$store.getters.currentLanguage;
    }

    get avatarLink() {
        return this.avatarSrc ?? '/images/undefined-user.png';
    }

    get isHasLink() {
        return !this.noLink
            && this.license.isAdminRights
            && this.memberId && this.status !== MemberStatusEnum.pendingApproval;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.user-avatar {
    flex-shrink: 0;
    display: inline-block;
    height: $user-avatar-size;
    width: $user-avatar-size;

    img {
        width: $user-avatar-size;
        height: $user-avatar-size;
        border-radius: $user-avatar-size;
        border: 1px solid $light;
    }
}
</style>
