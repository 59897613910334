import { LanguageKey } from './LanguageKey';

export const Languages = [
    {
        key: LanguageKey.en,
        value: 'English',
    },
    {
        key: LanguageKey.es,
        value: 'Español',
    },
    {
        key: LanguageKey.de,
        value: 'Deutsch',
    },
    {
        key: LanguageKey.fr,
        value: 'Français',
    },
    {
        key: LanguageKey.ru,
        value: 'Русский',
    },
    {
        key: LanguageKey.cn,
        value: '中文',
    },
    {
        key: LanguageKey.pt,
        value: 'Português',
    },
    {
        key: LanguageKey.jp,
        value: '日本語',
    },
];

// There is all languages, but only 2 templates exist for now.
// So in future to add language to templates list we need just add link to it below.
export const TemplatesLanguages = [
    {
        key: LanguageKey.en,
        value: 'English <span>(US imperial)</span>',
        link: 'https://static-us-east-1-prod-revizto.s3.amazonaws.com/Codifying_Coordination_Templates/NA+Templates.zip',
    },
    {
        key: LanguageKey.en,
        value: 'English <span>(Metric)</span>',
        link: 'https://static-us-east-1-prod-revizto.s3.amazonaws.com/Codifying_Coordination_Templates/Metric+Templates.zip',
    },
    {
        key: LanguageKey.es,
        value: 'Español',
    },
    {
        key: LanguageKey.de,
        value: 'Deutsch',
    },
    {
        key: LanguageKey.fr,
        value: 'Français',
    },
    {
        key: LanguageKey.ru,
        value: 'Русский',
    },
    {
        key: LanguageKey.cn,
        value: '中文',
    },
    {
        key: LanguageKey.pt,
        value: 'Português',
    },
    {
        key: LanguageKey.jp,
        value: '日本語',
    },
];
