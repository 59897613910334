<template>
    <router-link
        :to="{
            name: RouterNames.ProjectSettings,
            params: {
                licenseId,
                projectId: project.id,
                language,
            }
        }"
        :class="{ 'router-link-exact-active': isActive }"
        class="projects-dropdown-item"
    >
        <WsProjectPreview
            :preview-images="project.previewImages"
            class="project-logo"
        />
        <div class="description">
            <WsTruncate css-class="amp-mask" :value="project.title" :size="35" />
        </div>
    </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';
import { Project } from '@/models';
import WsTruncate from '@/components/common/WsTruncate.vue';
import WsProjectPreview from '@/components/common/WsProjectPreview.vue';

@Component({
    components: {
        WsTruncate,
        WsProjectPreview,
    },
})
export default class ProjectsDropdownItem extends Vue {
    @Prop({ required: true }) public project!: Project;
    @Prop({ type: Boolean }) public isActive!: boolean;

    public readonly RouterNames = RouterNames;

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
a.projects-dropdown-item {
    width: 100%;
    gap: 16px;
    grid-template-columns: 100px 1fr;
    .v-list-item > & {
        padding: 8px 20px;
        display: grid;
    }
}

.project-logo {
    border: 1px solid $light;
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid $light;
    font-weight: 700;
    white-space: nowrap;
}

</style>