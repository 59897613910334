<template>
    <WsDialog
        :title="$t('Button.edit_tags')"
        width="800px"
        no-body-scroll
        @close="cancel"
    >
        <div class="form-grid">
            <div class="form-current-tags">
                <div class="form-title">{{ $t('IssueTracker.info.tags.currentTags') }}</div>
                <span
                    v-for="(entries, index) in tagsEntries"
                    :key="index"
                    class="form-current-tags__tag-wrapper"
                >
                    <span class="form-current-tags__tag-value">{{ entries.tag }}</span>
                    <span class="gray-text">({{ entries.count === issueCount ? $t('Simple_word.all') : entries.count }})</span>
                    {{ getCommaForListItem(tagsEntries, index) }}
                </span>
                <span v-if="!tagsEntries.length">-</span>
                <hr>
            </div>

            <WsCombobox
                v-model="addTags"
                :items="items"
                :label="'+ ' + $t('IssueTracker.info.tags.addTags')"
                :allow-create="allowCreateTag"
                :rules="allowCreateTag ? rules : []"
                :maxlength="TAGS_MAX_LENGTH"
                class="form-combobox"
                is-limit
                searchable
                multiple
            />
            <WsCombobox
                v-model="removeTags"
                :items="items"
                :label="'- ' + $t('IssueTracker.info.tags.removeTags')"
                :allow-create="false"
                :maxlength="TAGS_MAX_LENGTH"
                class="form-combobox"
                is-limit
                searchable
                multiple
            />
        </div>

        <div class="actions-wrapper">
            <div>
                <WsButton
                    v-show="isShowManageTags && project.isCanManageTags"
                    contained
                    size="xsmall"
                    @click="goToManageTags"
                >
                    {{ $t('IssueSettings.tags.linkFromIssueTracker') }}
                </WsButton>
            </div>
            <div>
                <WsButton
                    :disabled="isDisableSave"
                    round
                    @click="save"
                >
                    {{ $t('Button.save') }}
                </WsButton>
                <WsButton round @click="cancel">{{ $t('Button.cancel') }}</WsButton>
            </div>
        </div>
    </WsDialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { MultiEditIssueFieldPayload } from '@/types/MultiEditIssueFieldPayload';
import { RouterNames, TAGS_MAX_LENGTH } from '@/constants';
import { Project } from '@/models';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import { DialogEditTagsMixin } from '@/mixins';
import WsButton from '@/components/common/WsButton.vue';
import WsCombobox from '@/components/common/WsCombobox.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    components: {
        WsButton,
        WsCombobox,
        WsDialog,
    },
})
export default class DialogMultiEditTags extends DialogEditTagsMixin {
    @Prop({ default: () => [] }) public tagsEntries!: Array<{
        count: number;
        tag: string;
    }>;
    @Prop() public issueCount!: number;
    @Prop({ default: () => [] }) public items!: string[];
    @Prop({ type: Boolean, default: false }) public allowCreateTag!: boolean;
    @Prop({ type: Boolean, default: false }) public isShowManageTags!: boolean;

    public addTags: string[] = [];
    public removeTags: string[] = [];
    public readonly getCommaForListItem = getCommaForListItem;
    public readonly TAGS_MAX_LENGTH = TAGS_MAX_LENGTH;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }
    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get isDisableSave() {
        return this.isCheckDisableSave(this.addTags);
    }

    @Emit('save')
    public save(): MultiEditIssueFieldPayload {
        return {
            add: this.addTags,
            remove: this.removeTags,
        };
    }

    @Emit()
    public cancel() {
        return;
    }

    @Emit()
    public input(value: string[]) {
        return value;
    }

    @Watch('localValue')
    public watchLocalValue(newValue: string[]) {
        this.input(newValue);
    }

    public goToManageTags() {
        this.$router.push({
            name: RouterNames.IssueSettings,
            params: {
                language: this.$route.params.language,
                projectId: this.$route.params.projectId,
                licenseId: this.$route.params.licenseId,
            },
            hash: '#tags',
        });
    }
}
</script>

<style lang="scss" scoped>
$grid-column: 364px;

::v-deep .v-input__append-outer .text-limit {
    display: none;
}

.form-grid {
    display: grid;
    grid-gap: 24px;
    grid-template-areas:
        "currentTags currentTags"
        "addTags removeTags";
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: $grid-column $grid-column;
}

.form-title {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 6px;
}

.form-current-tags {
    grid-area: currentTags;

    > hr {
        margin-top: 10px;
        margin-bottom: 0;

        &:after {
            left: 0;
            right: 0;
        }
    }
}

.form-current-tags__tag-wrapper {
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    padding-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-current-tags__tag-value {
    margin-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
</style>
