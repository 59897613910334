import http from '@/api/http';
import { IssueFilterPreset } from '@/domain/issuePreset/models/IssueFilterPreset';
import { IFilterSendValue } from '@/domain/issueFilter/types/IFilterSendValue';

export default class IssueFilterApi {
    /**
     * Получение списка ишьюсов по пресету
     * Временный метод
     */
    public static postGetIssues(projectUuid: string, params: any): Promise<any> {
        return http.post(`project/${projectUuid}/issue-filter/filter`, params).then((response) => response.data);
    }

    /**
     * Получение списка удаленных ишьюсов по проекту
     */
    public static getDeletedIssues(projectId: number, params: any): Promise<any> {
        return http.post(`project/${projectId}/issue-filter/filter_deleted`, params).then((response) => response.data);
    }

    /**
     * Получение данных пресетов
     */
    public static getProjectPresetUnread({ projectUuid, filters }: { projectUuid: string, filters: any }) {
        return http.post(`project/${projectUuid}/issue-filter/unread`, { filters }).then((response) => response.data);
    }

    /**
     * Read All Unseen
     */
    public static readAllUnseenIssues({ projectUuid, filters, alwaysFiltersDTO }: { projectUuid: string, filters: IssueFilterPreset[], alwaysFiltersDTO: IFilterSendValue[] }) {
        return http.post(`project/${projectUuid}/issue-filter/mark-read`, { filters, alwaysFiltersDTO }).then((response) => response.data);
    }

    /**
     * Send filters and sorting (and dummy deleted), receive short hash
     */
    public static postAddTemporary({ projectId, filters, sorting }: { projectId: number, filters: string, sorting: string }) {
        return http.post(`project/${projectId}/issue-filter-set/add-temporary`, { projectId, filters, sorting, deleted: 0 })
            .then((response) => response.data);
    }

    /**
     * Send hash, get filters and sorting
     */
    public static getTemporary({ projectId, hash }: { projectId: number, hash: string }) {
        return http.get(`project/${projectId}/issue-filter-set/get-temporary/${hash}`).then((response) => response.data);
    }

    /**
     * Получение списка ишьюсов по пресету
     * Временный метод
     */
    public static postGetIssueOrder(projectId: number, params: any): Promise<any> {
        return http.post(`project/${projectId}/issue-filter/filter_all`, params).then((response) => response.data);
    }
}