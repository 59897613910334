<template>
    <div class="issue-sort">
        <WsCheckbox v-model="isUnread" :label="$t('IssueTracker.sort.unreadFirst')" class="unread" />

        <div class="two-columns">
            <div class="column">
                <div class="column-top">
                    <div class="sort-title">{{ $t('IssueTracker.sort.primary') }}</div>
                    <v-radio-group v-model="primaryDirection" :label="$t('IssueTracker.sort.order')">
                        <v-radio value="asc" :label="$t('IssueTracker.sort.ascending')" />
                        <v-radio value="desc" :label="$t('IssueTracker.sort.descending')" />
                    </v-radio-group>
                </div>
                <div class="column-bottom">
                    <v-radio-group v-model="primaryField">
                        <v-radio :value="IssueSortBy.clashes" :label="$t('IssueTracker.sort.clashes')" />
                        <v-radio :value="IssueSortBy.assignee" :label="$t('IssueTracker.sort.assignee')" />
                        <v-radio :value="IssueSortBy.created" :label="$t('IssueTracker.sort.dateCreated')" />
                        <v-radio :value="IssueSortBy.deadline" :label="$t('IssueTracker.sort.deadline')" />
                        <v-radio :value="IssueSortBy.comment" :label="$t('IssueTracker.sort.commentDate')" />
                        <v-radio :value="IssueSortBy.update" :label="$t('IssueTracker.sort.updateDate')" />
                        <v-radio :value="IssueSortBy.priority" :label="$t('IssueTracker.sort.priority')" />
                        <v-radio :value="IssueSortBy.status" :label="$t('IssueTracker.sort.status')" />
                    </v-radio-group>
                </div>
            </div>
            <div class="column">
                <div class="column-top">
                    <div class="sort-title">{{ $t('IssueTracker.sort.secondary') }}</div>
                    <v-radio-group v-model="secondaryDirection" :label="$t('IssueTracker.sort.order')">
                        <v-radio value="asc" :label="$t('IssueTracker.sort.ascending')" />
                        <v-radio value="desc" :label="$t('IssueTracker.sort.descending')" />
                    </v-radio-group>
                </div>
                <div class="column-bottom">
                    <v-radio-group v-model="secondaryField">
                        <v-radio :value="IssueSortBy.clashes" :label="$t('IssueTracker.sort.clashes')" />
                        <v-radio :value="IssueSortBy.assignee" :label="$t('IssueTracker.sort.assignee')" />
                        <v-radio :value="IssueSortBy.created" :label="$t('IssueTracker.sort.created')" />
                        <v-radio :value="IssueSortBy.deadline" :label="$t('IssueTracker.sort.deadline')" />
                        <v-radio :value="IssueSortBy.comment" :label="$t('IssueTracker.sort.comment')" />
                        <v-radio :value="IssueSortBy.update" :label="$t('IssueTracker.sort.update')" />
                        <v-radio :value="IssueSortBy.priority" :label="$t('IssueTracker.sort.priority')" />
                        <v-radio :value="IssueSortBy.status" :label="$t('IssueTracker.sort.status')" />
                    </v-radio-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Project } from '@/models';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import { IssueSort, IssueSortBy, IssueSorts } from '../constants/IssueSort';
import { ISorting } from '@/domain/issue/services/IssueOrderParse';
import { TrackerFilters } from '@/domain/issueFilter/types/ProjectIssuesFilters';

@Component({
    components: {
        WsCheckbox,
    },
})
export default class IssueSortComponent extends Vue {
    public readonly IssueSortBy = IssueSortBy;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get trackerColumns() {
        return this.$store.getters.trackerColumns;
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get issuesSort(): ISorting {
        return this.$store.getters.selectedIssueFilterPresetByProjectId(this.projectId)?.sorting
            || this.$store.getters.issuesSortByProjectId(this.projectId);
    }

    get isUnread() {
        return Boolean(this.issuesSort[IssueSorts.unread]);
    }
    set isUnread(value: boolean) {
        const sort = { ...this.issuesSort, [IssueSorts.unread]: value };
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get primaryDirection() {
        return this.issuesSort[IssueSorts.primary][IssueSort.direction];
    }
    set primaryDirection(value: any) {
        const sort = { ...this.issuesSort };
        sort[IssueSorts.primary][IssueSort.direction] = value;
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get secondaryDirection() {
        return this.issuesSort[IssueSorts.secondary][IssueSort.direction];
    }
    set secondaryDirection(value: any) {
        const sort = { ...this.issuesSort };
        sort[IssueSorts.secondary][IssueSort.direction] = value;
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get primaryField() {
        return this.issuesSort[IssueSorts.primary][IssueSort.field];
    }
    set primaryField(value: any) {
        const sort = { ...this.issuesSort };
        sort[IssueSorts.primary][IssueSort.field] = value;
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get secondaryField() {
        return this.issuesSort[IssueSorts.secondary][IssueSort.field];
    }
    set secondaryField(value: any) {
        const sort = { ...this.issuesSort };
        sort[IssueSorts.secondary][IssueSort.field] = value;
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get trackerFilters(): TrackerFilters {
        return this.$store.getters.trackerFiltersByProjectId(this.projectId);
    }

    @Watch('issuesSort', { immediate: true, deep: true })
    public issuesSortChanged(value: any, oldValue: any) {
        if (oldValue !== undefined) {
            this.$store.dispatch('updateTrackerFilters', { projectId: this.projectId, filters: this.trackerFilters });
            this.$store.commit('setCurrentPage', { projectId: this.projectId, page: 1 });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.issue-sort {
    display: grid;
    grid-template-rows: auto;
    background: $white;
    border: solid 1px $primary-blue;
}

::v-deep .unread {
    border-bottom: $border;
    margin: 0;
    padding: 0 12px;

    .v-input__slot {
        margin: 0;
        height: 40px;
        line-height: 40px;
    }
}

.two-columns {
    display: grid;
    grid-template-columns: auto auto;

    .column {
        display: grid;
        grid-template-rows: auto;

        &:not(:last-child) {
            border-right: $border;
        }
    }
}

::v-deep .column-top {
    padding: 10px 15px;
    border-bottom: $border;

    .sort-title {
        font-weight: bold;
        margin-bottom: 15px;
    }

    .v-input--radio-group__input {
        display: grid;
        grid-gap: 15px;
        grid-auto-flow: column;

        .v-label {
            font-size: $font-size;
            line-height: $line-height-large;
            padding: 0;
        }
    }

    .v-input--selection-controls {
        margin: 0;
        padding: 0;
    }
}

::v-deep .column-bottom {
    padding: 10px 15px;

    .v-radio {
        padding: 6px 0;
    }

    .v-input--selection-controls {
        margin: 0;
        padding: 0;
    }
}

::v-deep .v-messages {
    display: none;
}

::v-deep .v-input__slot {
    margin: 0;
}

::v-deep .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin: 0;
}
</style>
