import type { TUuid } from '@/types/common';
import { IIssueType } from '@/domain/issueType/types/issueType';
import { NAVISWORKS_TYPE_UUID } from '@/domain/issueType/constants/customIssueType';
import { IssueTypeIcon } from '@/domain/issue/constants/IssueTypeIcons';

export class IssueType {
    public uuid: TUuid;
    public name: string;
    public description: string;
    public icon: IssueTypeIcon;
    public iconColor: string;
    public isDefault: boolean;
    public isStandard: boolean;
    public workflowUuid: TUuid;
    public deletedAt: string;

    constructor(issueType: IIssueType) {
        this.uuid = issueType.uuid;
        this.name = issueType.name;
        this.description = issueType.description;
        this.icon = issueType.icon;
        this.iconColor = issueType.iconColor;
        this.isDefault = issueType.isDefault;
        this.isStandard = issueType.isStandard;
        this.workflowUuid = issueType.workflowUuid;
        this.deletedAt = issueType.deletedAt;
    }

    get isNavisworks() {
        return this.uuid == NAVISWORKS_TYPE_UUID;
    }

    get isDeleted() {
        return Boolean(this.deletedAt);
    }

    public export() {
        return {
            uuid: this.uuid,
            name: this.name,
            description: this.description,
            icon: this.icon,
            iconColor: this.iconColor,
            workflowUuid: this.workflowUuid,
            isDefault: this.isDefault,
        };
    }
}
