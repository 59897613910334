<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M6 15H14V17H6V15Z" fill="black" />
        <path d="M18 15H22V17H18V15Z" fill="black" />
        <path d="M26 15H34V17H26V15Z" fill="black" />
        <path d="M38 15H42V17H38V15Z" fill="black" />
        <path d="M46 15H54V17H46V15Z" fill="black" />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType5 extends BaseIcon {}
</script>
