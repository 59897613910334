import _ from 'lodash';
import {
    VisibilityEnum,
} from '@/constants';
import { License, Project } from '@/models';
import { compareObjsAlphanumerically, i18n } from '@/services';
import { ANY_STAMP_UUID, NO_CATEGORY, NO_STAMP } from '@/domain/stamp/constants/Stamps';
import { CustomStatusCategoryList } from '@/domain/customStatus/constants/CustomStatusCategory';
import { IssueClashDiscipline } from '@/domain/chart/constants/IssueClashDiscipline';
import { ProcoreRFIType } from '@/domain/issue/constants/ProcoreRFIType';
import { ProcoreRFIStatus } from '@/domain/issue/constants/ProcoreRFIStatus';
import { IssueCreatedFrom } from '@/domain/issue/constants/IssueCreatedFrom';
import { IssuePriorityEnum } from '@/domain/issue/constants/IssuePriority';
import { IssueTypes } from '@/domain/issue/constants/IssueTypes';

export function fieldVariants(rawFieldVariants: any, license: License, parentEntity: License | Project) {
    const clashDiscipline = [
        IssueClashDiscipline.None,
        IssueClashDiscipline.Architectural,
        IssueClashDiscipline.Structural,
        IssueClashDiscipline.Mechanical,
        IssueClashDiscipline.Electrical,
        IssueClashDiscipline.Pipes,
        IssueClashDiscipline.Coordination,
    ].map((value) => ({
        value,
        text: i18n.t(`Dashboard.chart.settings.filter.clashDisciplineType.${value}`),
    })).sort(compareObjsAlphanumerically);

    const procoreType = {} as any;
    _.forEach(ProcoreRFIType, (key) => {
        procoreType[key] = i18n.t(`Dashboard.chart.settings.filter.procoreType.${key}`);
    });
    const procoreStatus = {} as any;
    _.forEach(ProcoreRFIStatus, (key) => {
        procoreStatus[key] = i18n.t(`Dashboard.chart.settings.filter.procoreStatus.${key}`);
    });

    const stampAbbr = [] as any;
    if (rawFieldVariants.stamps && (rawFieldVariants.stamps.length > 0)) {
        rawFieldVariants.stamps
            .map((value: any) => value.abbreviation)
            .forEach((value: string) => {
                stampAbbr.push({ title: value, uuid: value });
            });
    }

    const stampCategories = rawFieldVariants.stampCategories ? rawFieldVariants.stampCategories : [];

    const createdFrom = [
        IssueCreatedFrom['3D'],
        IssueCreatedFrom['2D'],
        IssueCreatedFrom['None'],
    ].map((value) => ({
        value,
        text: i18n.t(`Dashboard.chart.settings.filter.createdFrom.${value}`),
    }));

    const priority = [
        IssuePriorityEnum.blocker,
        IssuePriorityEnum.critical,
        IssuePriorityEnum.major,
        IssuePriorityEnum.minor,
        IssuePriorityEnum.trivial,
        IssuePriorityEnum.none,
    ].map((value) => ({
        value,
        text: i18n.t(`Dashboard.chart.settings.filter.priority.${value}`),
    }));

    const statusCategory = CustomStatusCategoryList
        .map((value) => ({
            value,
            text: i18n.t(`Dashboard.chart.settings.filter.statusCategory.${value.toLowerCase()}`),
        }));

    const type = [
        IssueTypes.regular,
        IssueTypes.clash,
    ].map((value) => ({
        value,
        text: i18n.t(`Dashboard.chart.settings.filter.type.${value}`),
    }));

    const commonFieldVariants = {
        assignee: rawFieldVariants.assignees,
        createdFrom,
        reporter: rawFieldVariants.reporters,
        priority,
        customStatus: rawFieldVariants.statuses || [],
        customType: rawFieldVariants.types || [],
        statusCategory,
        type,
        // The following two properties are absent from the app, so they
        // should be probably removed from here and from the code above
        // procoreType,
        // procoreStatus,
        assigneeCompany: license.memberCompanies,
        reporterCompany: license.memberCompanies,
        assigneeDepartment: license.memberDepartments,
        reporterDepartment: license.memberDepartments,
        assigneeLocation: license.memberLocations,
        reporterLocation: license.memberLocations,
    };

    const visibility = [
        VisibilityEnum.public,
        VisibilityEnum.private,
    ].map((value) => ({
        value,
        text: i18n.t(`Dashboard.chart.settings.filter.visibility.${value}`),
    }));

    if (parentEntity instanceof Project) {
        return {
            ...commonFieldVariants,
            tags: parentEntity.issueTags,
            clashCategory: rawFieldVariants.clashCategories,
            clashDiscipline,
            clashGridX: rawFieldVariants.clashGridXs,
            clashGridY: rawFieldVariants.clashGridYs,
            clashLevel: rawFieldVariants.clashLevels,
            clashArea: rawFieldVariants.clashAreas,
            clashRoom: rawFieldVariants.clashRooms,
            clashSpace: rawFieldVariants.clashSpaces,
            clashZone: rawFieldVariants.clashZones,
            clashSourceFile: rawFieldVariants.clashSourceFiles,
            clashTest: rawFieldVariants.clashTests,
            mainSheet: rawFieldVariants.mainSheets || [],
            mainSheetTags: rawFieldVariants.mainSheetTags || [],
            stampAbbr: [
                { title: i18n.t('Collocation.noStamp'), uuid: NO_STAMP },
                { title: i18n.t('Collocation.anyStamp'), uuid: ANY_STAMP_UUID },
            ].concat(stampAbbr),
            stampCategory: [{ title: i18n.t('Collocation.noCategory'), uuid: NO_CATEGORY }].concat(stampCategories),
            visibility,
        };
    }

    return {
        ...commonFieldVariants,
        tags: rawFieldVariants.tags,
        project: rawFieldVariants.projects,
    };
}
