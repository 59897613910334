<template>
    <div class="filter-controls">
        <button
            class="collapse-button"
            :class="{ 'is-open': isShowFilters }"
            data-test="f-show-filters-button"
            @click="toggle"
        >
            <IconSvg16
                icon-name="arrow-down"
                inline
                parent-hover
                stateless
                font-like
            />
            <span>{{ $t('Button.filters') }}</span>
        </button>

        <WsButton
            class="action-button"
            data-test="f-reset-filters-button"
            tertiary
            new
            xs
            @click="resetAll"
        >
            {{ $t('Button.reset') }}
        </WsButton>

        <div v-show="$slots.default && isShowFilters">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import WsButton from '@/components/common/WsButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        WsButton,
        IconSvg16,
    },
})
export default class WsFilterControls extends Vue {
    @Prop({ type: String, required: true }) public location!: string;

    get isShowFilters() {
        return !this.$store.getters.isFilterCollapsed(this.location);
    }

    @Emit()
    public resetAll() {
        return;
    }

    public toggle() {
        this.$store.dispatch('toggleFiltersCollapsed', this.location);
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.filter-controls {
    display: inline-flex;
    align-items: flex-start;
}

.collapse-button {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 700;
    color: $light-solid-60;

    ::v-deep .svg-icon {
        font-size: 0;
        transform: rotate(180deg);
        transition: transform 0.2s ease;
    }

    span {
        padding: 0 8px 0 7px;
        line-height: 21px;
        color: $light-solid-60;
    }

    &.is-open,
    &:hover {
        color: $light-solid-80
    }

    &.is-open {
        ::v-deep .svg-icon {
            transform: rotate(0deg);

        }
    }
}

::v-deep button.v-btn.ws-button-2.spacing-xs {
    margin-top: 2px;
    padding: 0px 7px;
    letter-spacing: 0;
    line-height: 17px;
}
</style>
