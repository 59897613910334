import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { Module } from 'vuex';
import { IIssueTrackerLink } from '@/types/project/IIssueTrackerLink';
import { ISetChartColorsParams } from '@/api/license.api';
import {
    NotFoundError,
} from '@/models';
import { getOperationId, notificationSuccess, SequentialRequests } from '@/services';
import { IFilterSendValue } from '@/domain/issueFilter/types/IFilterSendValue';
import ReportApi from '@/domain/report/api/report';
import { DashboardChart, DashboardChartParams } from '@/domain/dashboard/models/DashboardChart';
import { ProjectDashboard } from '@/domain/dashboard/models/ProjectDashboard';
import { IChangeFavoriteDashboardParams } from '@/domain/dashboard/types/ChangeFavoriteDashboardParams';
import { NormalizeStatus } from '@/domain/dashboard/services/NormalizeStatus';
import DashboardApi from '@/domain/dashboard/api/DashboardApi';
import ChartApi from '@/domain/chart/api/ChartApi';
import { IProjectDashboardChartViewParams } from '@/domain/chart/types/chart';
import { CachedRawChartData } from '@/domain/chart/models/ChartView';

interface IProjectDashboardsStorage {
    projectDashboardsObj: {
        [projectId: number]: ProjectDashboard[],
    };
    projectDashboardChartsObj: {
        [dashboardUuid: string]: DashboardChart[],
    };
    projectDashboardChartViewsObj: {
        [dashboardUuid: string]: {
            [chartUuid: string]: CachedRawChartData,
        },
    };
    projectDashboardChartPreviewsObj: {
        [dashboardUuid: string]: CachedRawChartData,
    };
    fullyDownloadedProjectDashboardChartsObj: {
        [dashboardUuid: string]: {
            [chartUuid: string]: boolean,
        },
    };
    selectedProjectDashboardObj: {
        [projectId: number]: ProjectDashboard,
    };
    isLoadingProjectDashboardsObj: {
        [projectId: number]: boolean,
    };
    isLoadingProjectDashboardChartsObj: {
        [dashboardUuid: string]: boolean,
    };
    isLoadingProjectDashboardChartViewsObj: {
        [dashboardUuid: string]: {
            [chartUuid: string]: boolean,
        },
    };
    isLoadingProjectDashboardChartPreviewsObj: {
        [dashboardUuid: string]: boolean,
    };
    isSendingProjectDashboard: boolean;
    isLoadingProjectDashboardChartSettings: boolean;
    isSendingProjectDashboardChartSettings: boolean;
    isSendingReorderProjectDashboardCharts: boolean;
    projectDashboardChartsLastLoad: {
        [dashboardUuid: string]: {
            [chartUuid: string]: boolean,
        },
    };
    isProjectDashboardsListColumnCollapsed: boolean;
    issueTrackerLink: IIssueTrackerLink;
}

export default {
    state: {
        projectDashboardsObj: {},
        projectDashboardChartsObj: {},
        projectDashboardChartViewsObj: {},
        projectDashboardChartPreviewsObj: {},
        fullyDownloadedProjectDashboardChartsObj: {},
        selectedProjectDashboardObj: {},
        isLoadingProjectDashboardsObj: {},
        isLoadingProjectDashboardChartsObj: {},
        isLoadingProjectDashboardChartViewsObj: {},
        isLoadingProjectDashboardChartPreviewsObj: {},
        isSendingProjectDashboard: false,
        isLoadingProjectDashboardChartSettings: false,
        isSendingProjectDashboardChartSettings: false,
        isSendingReorderProjectDashboardCharts: false,
        projectDashboardChartsLastLoad: {},
        isProjectDashboardsListColumnCollapsed: false,
        issueTrackerLink: {
            graphUuid: null,
            groupBy: null,
            dashboardChart: null,
            dashboardUuid: null,
            lineUuid: null,
            dotTimestamp: null,
            alwaysFiltersDTO: {},
        },
    } as IProjectDashboardsStorage,
    getters: {
        projectDashboardsByProjectId(state: IProjectDashboardsStorage): (projectId: number) => ProjectDashboard[] {
            return (projectId) => state.projectDashboardsObj[projectId] || [];
        },
        projectDashboardByDashboardUuidAndProjectId(state: IProjectDashboardsStorage): ({ projectId, dashboardUuid }: { projectId: number; dashboardUuid: string }) => ProjectDashboard | undefined {
            return ({ projectId, dashboardUuid }) => state.projectDashboardsObj[projectId]?.find(({ uuid }) => uuid === dashboardUuid);
        },
        projectDashboardChartsByDashboardUuid(state: IProjectDashboardsStorage): (dashboardUuid: string) => DashboardChart[] {
            return (dashboardUuid) => state.projectDashboardChartsObj[dashboardUuid] || [];
        },
        projectDashboardChartView(state: IProjectDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string }) => CachedRawChartData {
            return ({ dashboardUuid, chartUuid }) => NormalizeStatus
                .ofCachedRawChartData(state.projectDashboardChartViewsObj[dashboardUuid]?.[chartUuid] || {});

        },
        projectDashboardChartPreview(state: IProjectDashboardsStorage): (dashboardUuid: string) => CachedRawChartData {
            return (dashboardUuid) => NormalizeStatus
                .ofCachedRawChartData(state.projectDashboardChartPreviewsObj[dashboardUuid] || {});
        },
        projectDashboardChartSettings(state: IProjectDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string }) => DashboardChart {
            return ({ dashboardUuid, chartUuid }) => {
                const charts = state.projectDashboardChartsObj[dashboardUuid] || [];
                const dashboardChart = charts.find((chart: DashboardChart) => chartUuid === chart.uuid);
                return dashboardChart || new DashboardChart();
            };
        },
        selectedProjectDashboardByProjectId(state: IProjectDashboardsStorage): (projectId: number) => ProjectDashboard {
            return (projectId) => state.selectedProjectDashboardObj[projectId] || {};
        },
        isLoadingProjectDashboardsByProjectId(state: IProjectDashboardsStorage): (projectId: number) => boolean {
            return (projectId) => state.isLoadingProjectDashboardsObj[projectId] || false;
        },
        isLoadingProjectDashboardChartsByDashboardUuid(state: IProjectDashboardsStorage): (dashboardUuid: string) => boolean {
            return (dashboardUuid) => state.isLoadingProjectDashboardChartsObj[dashboardUuid] || false;
        },
        isLoadingProjectDashboardChartViews(state: IProjectDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string }) => boolean {
            return ({ dashboardUuid, chartUuid }) => state.isLoadingProjectDashboardChartViewsObj[dashboardUuid]?.[chartUuid] || false;
        },
        isLoadingProjectDashboardChartPreviews(state: IProjectDashboardsStorage): (dashboardUuid: string) => boolean {
            return (dashboardUuid) => state.isLoadingProjectDashboardChartPreviewsObj[dashboardUuid] || false;
        },
        isSendingProjectDashboard(state: IProjectDashboardsStorage): boolean {
            return state.isSendingProjectDashboard;
        },
        isLoadingProjectDashboardChartSettings(state: IProjectDashboardsStorage): boolean {
            return state.isLoadingProjectDashboardChartSettings;
        },
        isSendingProjectDashboardChartSettings(state: IProjectDashboardsStorage): boolean {
            return state.isSendingProjectDashboardChartSettings;
        },
        isSendingReorderProjectDashboardCharts(state: IProjectDashboardsStorage): boolean {
            return state.isSendingReorderProjectDashboardCharts;
        },
        fullyDownloadedProjectDashboardChartsObj(state: IProjectDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string}) => boolean {
            return ({ dashboardUuid, chartUuid }) => state.fullyDownloadedProjectDashboardChartsObj[dashboardUuid]?.[chartUuid];
        },
        projectDashboardChartLastLoad(state: IProjectDashboardsStorage): ({ dashboardUuid, chartUuid }: { dashboardUuid: string, chartUuid: string }) => boolean {
            return ({ dashboardUuid, chartUuid }) => state.projectDashboardChartsLastLoad[dashboardUuid]?.[chartUuid];
        },
        isProjectDashboardsListColumnCollapsed(state: IProjectDashboardsStorage): boolean {
            return state.isProjectDashboardsListColumnCollapsed;
        },
        issueTrackerLink(state: IProjectDashboardsStorage): IIssueTrackerLink {
            return state.issueTrackerLink;
        },
        isTrackerLink(state: IProjectDashboardsStorage): boolean {
            return Boolean(state.issueTrackerLink.graphUuid || state.issueTrackerLink.lineUuid);
        },
    },
    mutations: {
        setProjectDashboards(state: IProjectDashboardsStorage, { projectId, dashboards }: { projectId: number, dashboards: ProjectDashboard[] }) {
            Vue.set(state.projectDashboardsObj, projectId, dashboards);
        },
        setProjectDashboardCharts(state: IProjectDashboardsStorage, { dashboardUuid, charts }: { dashboardUuid: string, charts: DashboardChart[] }) {
            Vue.set(state.projectDashboardChartsObj, dashboardUuid, charts);
        },
        setProjectDashboardChartView(state: IProjectDashboardsStorage, { dashboardUuid, chartUuid, cachedRawChartData }: { dashboardUuid: string, chartUuid: string, cachedRawChartData: CachedRawChartData }) {
            const rawChartDataObj = Object.assign({}, state.projectDashboardChartViewsObj[dashboardUuid], { [chartUuid]: cachedRawChartData });
            Vue.set(state.projectDashboardChartViewsObj, dashboardUuid, rawChartDataObj);
        },
        setProjectDashboardChartPreview(state: IProjectDashboardsStorage, { dashboardUuid, cachedRawChartData }: { dashboardUuid: string, cachedRawChartData: any }) {
            Vue.set(state.projectDashboardChartPreviewsObj, dashboardUuid, cachedRawChartData);
        },
        setSelectedProjectDashboard(state: IProjectDashboardsStorage, { projectId, dashboard }: { projectId: number, dashboard: ProjectDashboard }) {
            Vue.set(state.selectedProjectDashboardObj, projectId, dashboard);
        },
        setIsLoadingProjectDashboards(state: IProjectDashboardsStorage, { projectId, value }: { projectId: number; value: boolean }) {
            Vue.set(state.isLoadingProjectDashboardsObj, projectId, value);
        },
        setIsLoadingProjectDashboardCharts(state: IProjectDashboardsStorage, { dashboardUuid, value }: { dashboardUuid: string; value: boolean }) {
            Vue.set(state.isLoadingProjectDashboardChartsObj, dashboardUuid, value);
        },
        setIsLoadingProjectDashboardChartViews(state: IProjectDashboardsStorage, { dashboardUuid, chartUuid, value }: { dashboardUuid: string; chartUuid: string, value: boolean }) {
            const isLoadingChartViewObj = Object.assign({}, state.isLoadingProjectDashboardChartViewsObj[dashboardUuid], { [chartUuid]: value });
            Vue.set(state.isLoadingProjectDashboardChartViewsObj, dashboardUuid, isLoadingChartViewObj);
        },
        setIsLoadingProjectDashboardChartPreview(state: IProjectDashboardsStorage, { dashboardUuid, value }: { dashboardUuid: string; value: boolean }) {
            Vue.set(state.isLoadingProjectDashboardChartPreviewsObj, dashboardUuid, value);
        },
        setIsSendingProjectDashboard(state: IProjectDashboardsStorage, value: boolean) {
            state.isSendingProjectDashboard = value;
        },
        setIsLoadingProjectDashboardChartSettings(state: IProjectDashboardsStorage, value: boolean) {
            state.isLoadingProjectDashboardChartSettings = value;
        },
        setIsSendingProjectDashboardChartSettings(state: IProjectDashboardsStorage, value: boolean) {
            state.isSendingProjectDashboardChartSettings = value;
        },
        setIsSendingReorderProjectDashboardCharts(state: IProjectDashboardsStorage, value: boolean) {
            state.isSendingReorderProjectDashboardCharts = value;
        },
        setFullyDownloadedProjectDashboardChartsObj(state: IProjectDashboardsStorage, { dashboardUuid, chartUuid, status }: { dashboardUuid: string, chartUuid: string, status: boolean }) {
            if (!state.fullyDownloadedProjectDashboardChartsObj[dashboardUuid]) {
                state.fullyDownloadedProjectDashboardChartsObj[dashboardUuid] = {};
            }
            state.fullyDownloadedProjectDashboardChartsObj[dashboardUuid][chartUuid] = status;
            state.fullyDownloadedProjectDashboardChartsObj = _.cloneDeep(state.fullyDownloadedProjectDashboardChartsObj);
        },
        updateProjectDashboard(state: IProjectDashboardsStorage, { projectId, projectDashboard }: { projectId: number, projectDashboard: ProjectDashboard }) {
            const dashboards = state.projectDashboardsObj[projectId];
            const index = dashboards.findIndex(({ uuid }) => uuid === projectDashboard.uuid);
            if (index !== -1) {
                dashboards[index] = projectDashboard;
            } else {
                dashboards.push(projectDashboard);
            }
            Vue.set(state.projectDashboardsObj, projectId, dashboards);
        },
        updateProjectDashboardChart(state: IProjectDashboardsStorage, { dashboardUuid, chartUuid, dashboardChart }: {
            dashboardUuid: string;
            chartUuid: string;
            dashboardChart: DashboardChartParams;
        }) {
            const charts = state.projectDashboardChartsObj[dashboardUuid] || [];
            const index = charts.findIndex(({ uuid }) => uuid === chartUuid);
            if (index !== -1) {
                charts[index] = new DashboardChart(dashboardChart);
            } else {
                charts.push(new DashboardChart(dashboardChart));
            }
            Vue.set(state.projectDashboardChartsObj, dashboardUuid, charts);
        },
        setProjectDashboardChartLoadTime(state: IProjectDashboardsStorage, { dashboardUuid, chartUuid, chartLastLoad }: { dashboardUuid: string; chartUuid: string, chartLastLoad: any }) {
            const projectDashboardChartsLastLoad = Object.assign({}, state.projectDashboardChartsLastLoad[dashboardUuid], { [chartUuid]: chartLastLoad });
            Vue.set(state.projectDashboardChartsLastLoad, dashboardUuid, projectDashboardChartsLastLoad);
        },
        setIsProjectDashboardsListColumnCollapsed(state: IProjectDashboardsStorage, value: boolean) {
            state.isProjectDashboardsListColumnCollapsed = value;
        },
        setIssueTrackerLink(state: IProjectDashboardsStorage, issueTrackerLink: IIssueTrackerLink) {
            if (issueTrackerLink.alwaysFiltersDTO) {
                issueTrackerLink.alwaysFiltersDTO = issueTrackerLink.alwaysFiltersDTO.map((filter: IFilterSendValue) => {
                    if (filter.type === 'createdFrom') {
                        filter.type = 'binding';
                    }
                    if (['status', 'customStatus'].includes(filter.type) && _.isArray(filter.value)) {
                        // @ts-ignore
                        filter.value = filter.value.map((value: string | number) => NormalizeStatus.reverse(value));
                    }
                    return filter;
                });
            }

            if (issueTrackerLink.statuses) {
                issueTrackerLink.statuses = issueTrackerLink.statuses.map((value: string[]) => {
                    return value.map(NormalizeStatus.reverse);
                });
            }

            state.issueTrackerLink = issueTrackerLink;
        },
    },
    actions: {
        async loadProjectDashboards(
            { getters, commit, dispatch }: any,
            { projectId, dashboardUuid, isForce = false }
                : { projectId: number, dashboardUuid: string, isForce: boolean },
        ): Promise<ProjectDashboard[]> {
            let projectDashboards = getters.projectDashboardsByProjectId(projectId);
            let selectedDashboardUuid = getters.selectedProjectDashboardByProjectId(projectId).uuid;
            if (isForce || !projectDashboards.length) {
                commit('setIsLoadingProjectDashboards', { projectId, value: true });
                const response = await DashboardApi.getProjectDashboards(projectId)
                    .finally(() =>  commit('setIsLoadingProjectDashboards', { projectId, value: false }));
                projectDashboards = response.entities.map((projectDashboard: any) => {
                    if (response.favorite?.includes(projectDashboard.uuid)) {
                        projectDashboard.isFavorite = true;
                    }
                    return new ProjectDashboard(projectDashboard);
                });
                commit('setProjectDashboards', { projectId, dashboards: projectDashboards });
                selectedDashboardUuid = dashboardUuid || response.selected || selectedDashboardUuid;
            }

            if (dashboardUuid) {
                const dashboardFromUrl = _.find(projectDashboards, { uuid: dashboardUuid });
                if (!dashboardFromUrl) {
                    throw NotFoundError.dashboard();
                }
            }

            const dashboardSelected = _.find(projectDashboards, { uuid: selectedDashboardUuid });
            const dashboardUuidToSelect = dashboardSelected ? dashboardSelected.uuid : '';
            const defaultDashboardUuidToSelect = projectDashboards.length ? projectDashboards[0].uuid : '';
            selectedDashboardUuid = dashboardUuidToSelect || defaultDashboardUuidToSelect;
            await dispatch('selectProjectDashboard', { projectId, dashboardUuid: selectedDashboardUuid });
            return projectDashboards;
        },
        selectProjectDashboard(
            { getters, commit, dispatch }: any,
            { projectId, dashboardUuid }: { projectId: number, dashboardUuid?: string },
        ): void | Promise<void> {
            const dashboards = getters.projectDashboardsByProjectId(projectId);
            // если не передан uuid дашборда, то выберется первый в списке, если список не пустой
            if (!dashboardUuid && dashboards.length) {
                dashboardUuid = dashboards[0].uuid;
            }

            // мутация setSelectedProjectDashboard сделана вне callback'а запроса, чтобы не было задержки;
            // ничего критичного не произойдет, если при переключении дашборда не сохранится информация о выбранном дашборде, при неуспешном запросе
            const dashboard = _.find(dashboards, { uuid: dashboardUuid });
            if (dashboardUuid && !dashboard) {
                throw NotFoundError.dashboard();
            }

            commit('setSelectedProjectDashboard', { projectId, dashboard });

            if (dashboardUuid) {
                return dispatch('loadProjectDashboardCharts', { projectId, dashboardUuid });
            }
        },
        saveProjectDashboard({ commit, dispatch }: any, { projectId, dashboard }: { projectId: number, dashboard: ProjectDashboard }): Promise<ProjectDashboard> {
            return new Promise((resolve, reject) => {
                let request;

                if (dashboard.uuid) {
                    request = DashboardApi.postUpdateProjectDashboard(projectId, dashboard.uuid, dashboard.apiParams);
                } else {
                    if (dashboard.preset) {
                        request = DashboardApi.postCreateProjectDashboardWithPreset(projectId, dashboard.preset, dashboard.apiParams);
                    } else {
                        request = DashboardApi.postCreateProjectDashboard(projectId, dashboard.apiParams);
                    }
                }

                commit('setIsSendingProjectDashboard', true);
                request.then((response) => {
                    notificationSuccess('allSaved');
                    const projectDashboard = new ProjectDashboard(response.entity);
                    commit('updateProjectDashboard', { projectId, projectDashboard });
                    dispatch('selectProjectDashboard', { projectId, dashboardUuid: projectDashboard.uuid });
                    resolve(projectDashboard);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingProjectDashboard', false);
                });
            });
        },
        deleteProjectDashboard({ state, commit, dispatch }: any, { projectId, dashboard }: { projectId: number, dashboard: ProjectDashboard }): Promise<void> {
            return new Promise((resolve, reject) => {
                commit('setIsSendingProjectDashboard', true);
                DashboardApi.postDeleteProjectDashboard(projectId, dashboard.uuid).then(() => {
                    notificationSuccess('dashboardDeleted');
                    const dashboards = state.projectDashboardsObj[projectId];
                    const index = dashboards.findIndex((dashboard1: ProjectDashboard) => dashboard1.uuid === dashboard.uuid);
                    dashboards.splice(index, 1);
                    commit('setProjectDashboards', { projectId, dashboards });
                    dispatch('selectProjectDashboard', { projectId });
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingProjectDashboard', false);
                });
            });
        },
        loadProjectDashboardCharts({ state, commit }: any, { projectId, dashboardUuid, isForce }: { projectId: number, dashboardUuid: string, isForce: boolean }): Promise<DashboardChart[]> {
            return new Promise((resolve, reject) => {
                if (!isForce && state.projectDashboardChartsObj[dashboardUuid]) {
                    resolve(state.projectDashboardChartsObj[dashboardUuid]);
                    return;
                }
                if (state.isLoadingProjectDashboardChartsObj[dashboardUuid]) {
                    resolve([]);
                    return;
                }

                commit('setIsLoadingProjectDashboardCharts', { dashboardUuid, value: true });
                ChartApi.getProjectDashboardCharts(projectId, dashboardUuid).then((response) => {
                    const oldCharts = state.projectDashboardChartsObj[dashboardUuid] || [];
                    const charts = response.entities.map((chart: any) => {
                        // это подпорка для отсутствующих данных, которые должны приходить с бека, но не приходят
                        // *** в лицензионных дашбордах графики приходят в таком же неполном виде,
                        // но из-за разнице в логике на уровнях выше, это не приводит к ошибке связанной с полем delivery,
                        // несмотря на то что используются одни и те же компоненты, поэтому там подпорка не нужна ***
                        const oldChart = oldCharts.find((dChart: DashboardChart) => dChart.uuid === chart.uuid);
                        if (oldChart) {
                            chart = { ...oldChart, ...chart };
                        }
                        return new DashboardChart(chart);
                    });

                    commit('setProjectDashboardCharts', { dashboardUuid, charts });
                    resolve(charts);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingProjectDashboardCharts', { dashboardUuid, value: false });
                });
            });
        },
        loadProjectDashboardChartSettings(
            { commit, getters }: any,
            { projectId, dashboardUuid, chartUuid, isForce }
                : { projectId: number, dashboardUuid: string, chartUuid: string, isForce: boolean },
        ): Promise<any> {
            return new Promise((resolve, reject) => {
                if (!isForce && getters.fullyDownloadedProjectDashboardChartsObj({ dashboardUuid, chartUuid })) {
                    resolve(getters.projectDashboardChartSettings({ dashboardUuid, chartUuid }));
                    return;
                }

                commit('setIsLoadingProjectDashboardChartSettings', true);
                ChartApi.getProjectDashboardChartSettings(projectId, dashboardUuid, chartUuid).then((response) => {
                    const dashboardChart = new DashboardChart(response.entity);

                    commit('updateProjectDashboardChart', { dashboardUuid, chartUuid, dashboardChart });
                    commit('setFullyDownloadedProjectDashboardChartsObj', { dashboardUuid, chartUuid, status: true });
                    resolve(dashboardChart);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingProjectDashboardChartSettings', false);
                });
            });
        },
        saveProjectDashboardChart({ state, commit, dispatch }: any, { projectId, dashboardUuid, dashboardChart }: { projectId: number, dashboardUuid: string, dashboardChart: DashboardChart }): Promise<DashboardChart> {
            return new Promise((resolve, reject) => {
                const request = dashboardChart.uuid
                    ? ChartApi.postUpdateProjectDashboardChart(projectId, dashboardUuid, dashboardChart.uuid, dashboardChart.apiParams)
                    : ChartApi.postCreateProjectDashboardChart(projectId, dashboardUuid, dashboardChart.apiParams);

                commit('setIsSendingProjectDashboardChartSettings', true);

                request.then((response) => {
                    notificationSuccess('allSaved');
                    const chart = new DashboardChart(response.entity);
                    const charts = state.projectDashboardChartsObj[dashboardUuid];
                    const index = charts.findIndex((chart1: DashboardChart) => chart1.uuid === chart.uuid);
                    if (index !== -1) {
                        dispatch('loadProjectDashboardChartView', { projectId, dashboardUuid, chartUuid: chart.uuid, isForce: true });
                        charts.splice(index, 1);
                    }
                    charts.push(chart);
                    commit('setProjectDashboardCharts', { dashboardUuid, charts });
                    resolve(chart);
                }).catch((e) => {
                    reject(e);
                }).finally(() => {
                    commit('setIsSendingProjectDashboardChartSettings', false);
                });
            });
        },
        cloneProjectDashboardChart({ state, commit }: any, { projectId, dashboardUuid, chartUuid }: any): Promise<void> {
            return new Promise((resolve, reject) => {
                ChartApi.postCloneProjectDashboardChart(projectId, dashboardUuid, chartUuid).then((response) => {
                    const charts = [...state.projectDashboardChartsObj[dashboardUuid], new DashboardChart(response.entity)];
                    commit('setProjectDashboardCharts', { dashboardUuid, charts });
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        deleteProjectDashboardChart({ state, commit }: any, { projectId, dashboardUuid, chartUuid }: { projectId: number, dashboardUuid: string, chartUuid: string }): Promise<void> {
            return new Promise((resolve, reject) => {
                commit('setIsSendingProjectDashboardChartSettings', true);
                ChartApi.postDeleteProjectDashboardChart(projectId, dashboardUuid, chartUuid).then(() => {
                    notificationSuccess('chartDeleted');
                    const charts = state.projectDashboardChartsObj[dashboardUuid];
                    const index = charts.findIndex((chart: DashboardChart) => chart.uuid === chartUuid);
                    charts.splice(index, 1);
                    commit('setProjectDashboardCharts', { dashboardUuid, charts });
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingProjectDashboardChartSettings', false);
                });
            });
        },
        loadProjectDashboardChartView(
            { state, commit }: any,
            { projectId, dashboardUuid, chartUuid, isCachedData = true, isForce = false }: IProjectDashboardChartViewParams,
        ): Promise<CachedRawChartData | void> {
            return new Promise((resolve, reject) => {
                // если данные уже загружены, то отдаем их без запроса
                if (!isForce && state.projectDashboardChartViewsObj[dashboardUuid]?.[chartUuid]) {
                    resolve(state.projectDashboardChartViewsObj[dashboardUuid][chartUuid]);
                    return;
                }
                // если произошел повторный запрос, до того как выполнился предыдущий, то не выполняем его
                if (state.isLoadingProjectDashboardChartViewsObj[dashboardUuid]?.[chartUuid]) {
                    resolve();
                    return;
                }

                commit('setIsLoadingProjectDashboardChartViews', { dashboardUuid, chartUuid, value: true });
                ChartApi.getProjectDashboardChartView({
                    projectId, dashboardUuid, chartUuid, isCachedData,
                }).then((cachedRawChartData: CachedRawChartData) => {
                    const chartLastLoad = {
                        loadTime: moment().unix(),
                        isChanged: false,
                    };
                    commit('setProjectDashboardChartLoadTime', { dashboardUuid, chartUuid, chartLastLoad });
                    commit('setProjectDashboardChartView', { dashboardUuid, chartUuid, cachedRawChartData });
                    resolve(cachedRawChartData);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingProjectDashboardChartViews', { dashboardUuid, chartUuid, value: false });
                });
            });
        },
        loadProjectDashboardChartPreview({ commit }: any, { projectId, dashboardUuid, dashboardChart }: any): Promise<CachedRawChartData> {
            commit('setIsLoadingProjectDashboardChartPreview', { dashboardUuid, value: true });
            return SequentialRequests.tick(ChartApi.getProjectDashboardChartPreview, {
                projectId,
                dashboardUuid,
                params: dashboardChart.apiParams,
            }).then((rawChartData) => {
                const cachedRawChartData = {
                    cachedAt: moment().unix(),
                    graph: rawChartData,
                } as CachedRawChartData;
                commit('setProjectDashboardChartPreview', { dashboardUuid, cachedRawChartData });
                return cachedRawChartData;
            }).finally(() => {
                commit('setIsLoadingProjectDashboardChartPreview', { dashboardUuid, value: false });
            });
        },
        changeOrderProjectDashboardCharts(
            { state, commit }: any,
            { projectId, dashboardUuid, params }
                : { projectId: number, dashboardUuid: string, params: any },
        ): Promise<any> {
            commit('setIsSendingReorderProjectDashboardCharts', true);
            return DashboardApi.postChangeOrderProjectDashboardCharts(projectId, dashboardUuid, params).then((response) => {
                notificationSuccess('allSaved');
                const dashboard = new ProjectDashboard(response);
                const dashboards = state.projectDashboardsObj[projectId];
                const index =  dashboards.findIndex((dashboard1: ProjectDashboard) => dashboard1.uuid === dashboard.uuid);
                if (index !== -1) {
                    dashboards.splice(index, 1, dashboard);
                } else {
                    dashboards.push(dashboard);
                }

                commit('setProjectDashboards', { projectId, dashboards });
                commit('setSelectedProjectDashboard', { projectId, dashboard });
            }).finally(() => {
                commit('setIsSendingReorderProjectDashboardCharts', false);
            });
        },
        sendProjectDashboardChart(_context: any, { projectId, dashboardUuid, chartUuid, params }: any) {
            params = _.omit(params, ['biggerImages']);
            const operationId = getOperationId();
            return ChartApi.postSendChartToEmail(projectId, dashboardUuid, chartUuid, { ...params, operationId }).then(() => {
                notificationSuccess('fileBeingProcessedChart');
            });
        },
        sendProjectDashboardChartDeliverySettings({ commit }: any, { projectId, dashboardUuid, chartUuid, params }: {
            projectId: number;
            dashboardUuid: string;
            chartUuid: string;
            params: any;
        }) {
            params = _.omit(params, ['biggerImages']);
            return DashboardApi.postUpdateChartDeliverySettings(projectId, dashboardUuid, chartUuid, params).then((response) => {
                notificationSuccess('allSaved');
                if (response.entity) {
                    commit('updateProjectDashboardChart', { dashboardUuid, chartUuid, dashboardChart: response.entity });
                }
            });
        },
        sendProjectDashboard(_context: any, { projectId, dashboardUuid, params }: any) {
            const operationId = getOperationId();
            return DashboardApi.postDashboardSendToEmail(projectId, dashboardUuid, { ...params, operationId }).then(() => {
                notificationSuccess('fileBeingProcessedDashboard');
            });
        },
        sendProjectChartColors({ state, commit }: any, { projectId, dashboardUuid, chartUuid, params }:
            { projectId: number; dashboardUuid: string; chartUuid: string; params: ISetChartColorsParams },
        ) {
            commit('setIsLoadingProjectDashboardChartViews', { dashboardUuid, chartUuid, value: true });

            return ChartApi.postProjectChartColors(projectId, dashboardUuid, chartUuid, params).then(({ entity }) => {
                const charts = state.projectDashboardChartsObj[dashboardUuid];
                const chart = charts.find(({ uuid }: DashboardChart) => uuid === chartUuid);
                chart.color = entity.color;
                chart.colors = entity.colors;

                commit('setProjectDashboardCharts', { dashboardUuid, charts });
                return entity;
            }).finally(() => {
                commit('setIsLoadingProjectDashboardChartViews', { dashboardUuid, chartUuid, value: false });
            });
        },
        saveProjectChartSize(
            _context: any,
            { projectId, dashboardUuid, chartUuid, params }:
                { projectId: number; dashboardUuid: string; chartUuid: string; params: { size: number } },
        ) {
            return ChartApi.postProjectChartSize(projectId, dashboardUuid, chartUuid, params);
        },
        sendDeliveryProjectDashboard({ commit, dispatch }: any, { projectId, dashboardUuid, params }: any) {
            return DashboardApi.postDashboardSetDelivery(projectId, dashboardUuid, params).then((response) => {
                notificationSuccess('allSaved');
                const projectDashboard = new ProjectDashboard(response.entity);
                commit('updateProjectDashboard', { projectId, projectDashboard });
                dispatch('selectProjectDashboard', { projectId, dashboardUuid: projectDashboard.uuid });
            });
        },
        sendProjectReport(_context: any, { projectId, reportUuid, params }: any) {
            const operationId = getOperationId();
            return ReportApi.postReportSendToEmail(projectId, reportUuid, { ...params, operationId })
                .then(() => notificationSuccess('fileBeingProcessedReport'));
        },
        sendExportProjectDashboard(_context: any, { projectId, dashboardUuid, params }: any) {
            return DashboardApi.postExportProjectDashboard({ projectId, dashboardUuid, params });
        },
        sendImportProjectDashboard(_context: any, { projectId, params }: any) {
            return DashboardApi.postImportProjectDashboard({ projectId, params });
        },
        sendImportProjectDashboardCharts(_context: any, { projectId, dashboardUuid, params }: any) {
            return DashboardApi.postImportProjectDashboardCharts({ projectId, dashboardUuid, params });
        },
        sendAddFavoriteProjectDashboard(_context: any, { projectId, params }: { projectId: string, params: IChangeFavoriteDashboardParams }) {
            return DashboardApi.postAddFavoriteProjectDashboards({ projectId, params });
        },
        sendRemoveFavoriteProjectDashboard(_context: any, { projectId, params }: { projectId: string, params: IChangeFavoriteDashboardParams }) {
            return DashboardApi.postRemoveFavoriteProjectDashboards({ projectId, params });
        },
        subscribeOnDashboard(_context: any, { projectId, dashboardUuid }: { projectId: string, dashboardUuid: string }) {
            return DashboardApi.subscribeOnDashboard({ projectId, dashboardUuid });
        },
        unsubscribeFromDashboard(_context: any, { projectId, dashboardUuid }: { projectId: string, dashboardUuid: string }) {
            return DashboardApi.unsubscribeFromDashboard({ projectId, dashboardUuid });
        },
        subscribeOnLicenseDashboard(_context: any, { licenseId, dashboardUuid }: { licenseId: string, dashboardUuid: string }) {
            return DashboardApi.subscribeOnLicenseDashboard({ licenseId, dashboardUuid });
        },
        unsubscribeFromLicenseDashboard(_context: any, { licenseId, dashboardUuid }: { licenseId: string, dashboardUuid: string }) {
            return DashboardApi.unsubscribeFromLicenseDashboard({ licenseId, dashboardUuid });
        },
    },
} as Module<IProjectDashboardsStorage, any>;
