import { TUuid } from '@/types/common';
import http from '@/api/http';
import { StatusReplacementMap } from '@/domain/customStatus/types/statusReplacementMap';
import { IWorkflowSettings } from '@/domain/workflow/types/workflow';

export default class WorkflowApi {
    /**
     * create custom status
     */
    public static removeCustomStatusFromWorkflow(projectUuid: TUuid, workflowUuid: TUuid, statusReplacementMap: StatusReplacementMap[]): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/${workflowUuid}/delete_status`,
            { statusReplacementMap },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * Get workflow data
     */
    public static getWorkflowSettings(projectUuid: string): Promise<any> {
        return http.get(`project/${projectUuid}/issue-workflow/settings`).then((response) => response.data);
    }

    /**
     * save workflow data
     */
    public static setWorkflowSettings(projectUuid: string, workflowUuid: string, params: IWorkflowSettings): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/update`,
            { entities: JSON.stringify([{ uuid: workflowUuid, ...params }]) },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * add workflow
     */
    public static createWorkflow(projectUuid: string, params: IWorkflowSettings): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/add`,
            { entities: JSON.stringify([params]), statusesUuids: '[]' },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * Remove workflow
     */
    public static removeWorkflow(
        projectUuid: TUuid,
        workflowUuid: TUuid,
        params: {
            newWorkflowUuid: TUuid,
            statusReplacementMap: StatusReplacementMap[]
        }): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/${workflowUuid}/delete`,
            params,
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * add status to workflow
     */
    public static addStatusToWorkflow(projectUuid: string, workflowUuid: string, statusesUuids: TUuid[]): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/${workflowUuid}/add_statuses`,
            { statusesUuids },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * reorder statuses
     */
    public static reorderStatusesInWorkflow(projectUuid: TUuid, workflowUuid: TUuid, statusesUuids: TUuid[]): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-workflow/${workflowUuid}/reorder_statuses`,
            { statusesUuids },
            true,
            true,
        ).then((response) => response.data);
    }
}