<template>
    <v-data-table
        :class="{ 'ws-table': isNew }"
        :value="value"
        :items="items"
        :headers="headers"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :no-data-text="noDataText"
        :sort-desc="sortDesc"
        :sort-by="sortBy"
        :custom-sort="customSort"
        :loading="loading"
        :item-key="itemKey"
        :item-class="rowClasses"
        :show-select="showSelect"
        :single-select="singleSelect"
        :disable-pagination="disablePagination"
        :hide-default-header="(loading && !items.length) || hideDefaultHeader"
        :fixed-header="fixedHeader"
        :header-props="headerProps"
        hide-default-footer
        ref="tableRef"
        @input="input"
        @update:sort-desc="handleUpdateSortDesc"
        @update:sort-by="handleUpdateSortBy"
        @page-count="handlePageCount"
        @item-selected="itemSelected"
        @click:row="onRowClick"
    >
        <template #loading>
            <WsSkeletonLoader :content="`${loaderContent}-table`" :options="{ hasSelect: showSelect }" />
        </template>

        <template #progress>
            <span v-if="loading && !items.length" />
        </template>

        <template v-if="loading && !items.length" #header="{ props: { headers } }">
            <thead class="v-data-table-header">
                <tr>
                    <th :colspan="headers.length" scope="col">
                        <WsSkeletonLoader content="table-header" :options="{ hasSelect: showSelect }" />
                    </th>
                </tr>
            </thead>
        </template>

        <template v-for="(_, name) in $scopedSlots" #[name]="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
        <template #no-data>
            <slot name="no-data" />
        </template>
    </v-data-table>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { TranslateResult } from 'vue-i18n';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';

@Component({
    components: {
        WsSkeletonLoader,
    },
})
export default class WsTable extends Vue {
    @Prop() public value!: any;
    @Prop({ type: Array }) public items!: any[];
    @Prop({ type: Array }) public headers!: DataTableHeader[];
    @Prop({ type: String }) public itemKey!: string;
    @Prop() public rowClasses!: string;
    @Prop({ type: Number }) public itemsPerPage!: number;
    @Prop() public noDataText!: TranslateResult;
    @Prop({ type: Boolean }) public loading!: boolean;
    @Prop({ type: Boolean }) public showSelect!: boolean;
    @Prop({ type: Boolean }) public disablePagination!: boolean;
    @Prop({ type: Boolean }) public hideDefaultHeader!: boolean;
    @Prop({ type: String, default: 'default' }) public loaderContent!: string;
    @Prop({ type: String, default: 'default' }) public sortIcon!: string;
    @Prop() public page!: any;
    @Prop() public sortDesc!: any;
    @Prop() public sortBy!: any;
    @Prop() public customSort!: any;
    @Prop({ type: Boolean, default: false }) public fixedHeader!: boolean;
    @Prop({ type: Boolean, default: false }) public new!: boolean;
    @Prop({ type: Boolean, default: false }) public singleSelect!: boolean;

    @Ref() public readonly tableRef!: any;

    get isNew() {
        return this.new;
    }

    get headerProps() {
        return this.isNew ? {
            'sort-icon': 'mdi-chevron-up',
        } : undefined;
    }

    @Emit()
    public input(value: any) {
        return value;
    }

    @Emit('update:sort-desc')
    public handleUpdateSortDesc(value: boolean | undefined) {
        return value;
    }

    @Emit('update:sort-by')
    public handleUpdateSortBy(value: string | undefined) {
        return value;
    }

    @Emit('page-count')
    public handlePageCount(value: number) {
        return value;
    }

    @Emit('table-ref')
    public emitRef() {
        return this.tableRef;
    }

    @Emit()
    public onRowClick(args: any) {
        return args;
    }
    @Emit()
    public itemSelected(args: any) {
        return args;
    }

    public mounted() {
        this.emitRef();
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/wsui/table.scss';
</style>
