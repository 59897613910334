import { BaseDashboard } from './BaseDashboard';
import { DashboardVisibility } from '@/domain/dashboard/constants/DashboardVisibility';

export class LicenseDashboard extends BaseDashboard {
    constructor(licenseDashboard: any = {}) {
        super(licenseDashboard);
        this.visibility = licenseDashboard.visibility || DashboardVisibility.publicIssues;
    }

    get apiParams() {
        const baseParams = super.apiParams;
        return {
            ...baseParams,
            visibility: this.visibility,
        };
    }
}
