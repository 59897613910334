import axios from 'axios';
import Qs from 'qs';
import { Dict } from '@/types/Dict';
import BaseHttp from '@/api/baseHttp';

export interface ICredentials {
    login: string;
    password: string;
    merge?: number;
    app_sso_key?: string;
    authType?: string;
    query?: object;
    isLoginAccessCode?: boolean;
}
export interface IAuthResponse {
    data: {
        token: any;
        uid: string;
    };
}
export interface IGetTokenResponse {
    data: {
        code: string;
        licenses: any[];
    };
}

export default class AuthHttp extends BaseHttp {
    /**
     * Метод проверки авторизации юзера с помощью сервера
     */
    public static auth({ login, password, merge = 0 }: ICredentials, deviceId: string, baseURL: string): Promise<any> {
        const data = {
            login,
            password,
            merge,
            device_id: deviceId,
            source: 'web',
        };
        return axios({
            method: 'post',
            baseURL,
            url: '/auth/issue',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
        }).then((response) => response.data);
    }

    public static authOAuth2(
        { login, password, merge = 0, authType = 'oauth2code', query = {}, isLoginAccessCode = false }: ICredentials,
        deviceId: string,
        baseURL: string,
    ): Promise<IGetTokenResponse> {
        if (isLoginAccessCode) {
            authType = 'oauth2code';
        }
        const data = {
            login,
            password,
            merge,
            device_id: deviceId,
            source: 'oauth2',
            authType,
            ...query,
        };

        return axios({
            method: 'post',
            baseURL,
            url: '/auth/issue',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
            transformResponse: this.transformResponse,
        }).then(({ data }) => {
            const link = data?.link;
            if (link && isLoginAccessCode) {
                location.href = link;
            }
            return data;
        });
    }

    public static authApp({ login, password, merge, app_sso_key }: ICredentials, deviceId: string, baseURL: string): Promise<any> {
        const data = {
            login,
            password,
            merge,
            app_sso_key,
            device_id: deviceId,
            source: 'web',
        };
        return axios({
            method: 'post',
            baseURL,
            url: '/auth/issue/save/code',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
        }).then((response) => response.data);
    }

    public static authTwoFA(
        { key, code, twoFactorMethod, baseURL, authType, query = {}, isLoginAccessCode = false }:
            { key: string; code: string; twoFactorMethod: string; baseURL: string; authType?: string; query?: Dict; isLoginAccessCode?: boolean },
    ): Promise<IAuthResponse> {
        const accessCodePart =
            (authType === 'oauth2code' || authType === 'oauth2redirect') && { source: 'oauth2', authType };
        const params = {
            key,
            code,
            twoFactorMethod,
            source: 'web',
            ...accessCodePart,
            ...query,
        };
        return axios({
            method: 'post',
            baseURL,
            url: '/auth/2fa',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(params),
            transformResponse: this.transformResponse,
        }).then(({ data }) => {
            const link = data?.link;
            if (link && isLoginAccessCode) {
                location.href = link;
            }
            return data;
        });
    }

    public static authTwoFAApp(
        { app_sso_key, key, code, twoFactorMethod, baseURL }:
            { app_sso_key: string; key: string; code: string; twoFactorMethod: string; baseURL: string },
    ): Promise<IAuthResponse> {
        const params = {
            app_sso_key,
            key,
            code,
            twoFactorMethod,
            source: 'web',
        };
        return axios({
            method: 'post',
            baseURL,
            url: '/auth/2fa/save/code',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(params),
            transformResponse: this.transformResponse,
        }).then((response) => response.data);
    }

    public static oauthGetServiceUrl(data: { redirectUri: string; service: string; source: string }, baseURL: string) {
        return axios({
            method: 'POST',
            baseURL,
            url: '/auth/oauth/auth-url',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
        }).then((response) => response.data);
    }

    public static checkCode(data: { redirectUri: string; service: string; deviceId: string; source: string }, baseURL: string, code: string): Promise<IAuthResponse> {
        return axios({
            method: 'POST',
            baseURL,
            url: `/auth/oauth/check?code=${code}`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
            transformResponse: this.transformResponse,
        }).then((response) => response.data);
    }

    public static checkCodeAppSSO(data: { redirectUri: string; service: string; source: string }, baseURL: string, code: string): Promise<IAuthResponse> {
        return axios({
            method: 'POST',
            baseURL,
            url: `/auth/oauth/save/code?code=${code}`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
        }).then((response) => response.data);
    }

    /**
     * Получение лицензий при доавторизации приложения
     */
    public static getLicensesForAppAuth(data: any, baseURL: string) {
        return axios({
            method: 'POST',
            baseURL,
            url: 'user/license_by_hash',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
            transformResponse: this.transformResponse,
        }).then((response) => response.data);
    }

    public static postSAMLInitData({ baseURL, data }: any) {
        return axios({
            method: 'POST',
            baseURL,
            url: 'auth/saml',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
        }).then((response) => response.data);
    }

    public static postSAMLConfirmAuth({ baseURL, data }: any) {
        return axios({
            method: 'POST',
            baseURL,
            url: 'auth/saml/confirm',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
        }).then((response) => response.data);
    }

    public static postSAMLConfirmAuthAppSSO({ baseURL, data }: any) {
        return axios({
            method: 'POST',
            baseURL,
            url: 'auth/saml/confirm', // костыльный урл для обхода самловой проверки урла
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: Qs.stringify(data),
            transformResponse: this.transformResponse,
        });
    }
}
