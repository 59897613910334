<template>
    <div class="skeleton-members-table">
        <div
            v-for="index in getRows()"
            :key="index"
            class="skeleton-members-row"
        >
            <SkeletonBone v-if="options.hasSelect" class="skeleton-members-td-select" />
            <div class="skeleton-members-fullname-td">
                <div class="skeleton-members-fullname-td--grid">
                    <SkeletonBone class="skeleton-members-fullname-td--avatar" />
                    <SkeletonBone class="skeleton-members-fullname-td--first-stroke" />
                    <SkeletonBone class="skeleton-members-fullname-td--second-stroke" />
                </div>
            </div>

            <div class="skeleton-members-td"><SkeletonBone /></div>
            <div class="skeleton-members-td"><SkeletonBone /></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import SkeletonBaseTable from '@/components/common/skeleton/SkeletionBaseTable.vue';

@Component
export default class SkeletonMembersTable extends SkeletonBaseTable {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-members-row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    padding-left: $default-padding;
    border-bottom: 1px solid $divider-gray;
}

.skeleton-members-td {
    width: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skeleton-members-td ::v-deep .skeleton-bone {
    width: 72px;
}

.skeleton-members-td-select {
    width: 20px;
    height: 20px;
    border-radius: $border-radius-small;
    margin-right: 18px;
}

.skeleton-members-fullname-td {
    width: 395px;
}

.skeleton-members-fullname-td--grid {
    display: grid;
    grid-template-areas:
        "image first-stroke first-stroke"
        "image second-stroke .";
    grid-template-columns: $user-avatar-size 1fr 1fr;
    grid-gap: 8px 13px;
    min-height: 43px;
    padding-right: 30px;
}

.skeleton-members-fullname-td--avatar {
    grid-area: image;
    width: $user-avatar-size;
    min-height: $user-avatar-size;
    border-radius: 50%;
}

.skeleton-members-fullname-td--first-stroke {
    grid-area: first-stroke;
    align-self: end;
    width: 100%;
}

.skeleton-members-fullname-td--second-stroke {
    grid-area: second-stroke;
    align-self: start;
}
</style>
