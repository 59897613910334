<template>
    <div class="ws-tags">
        <div class="ws-tags-block">
            <div class="tag-title">{{ $t('Tags.currentTags') }}</div>
            <div class="tags-list">{{ tagString }}</div>
        </div>
        <div class="ws-tags-block">
            <div class="tag-title">{{ $t('Tags.addTags') }}</div>
            <div class="tags-list">{{ tagsToAddString }}</div>
            <WsMultiSelect
                v-model="tagsToAdd"
                :options="tagsOptions"
                :create-action-text="$t('Tags.newTag')"
                creatable
                @change="change"
            >
                <template #activator>
                    <button class="tag-action-button" type="button">
                        <IconSvg16
                            :color="Color.accent100"
                            icon-name="plus"
                            class="icon"
                            stateless
                            passive
                        />
                        {{ $t('Tags.addTags') }}
                    </button>
                </template>
            </WsMultiSelect>
        </div>

        <div class="ws-tags-block">
            <div class="tag-title">{{ $t('Tags.removeTags') }}</div>
            <div class="tags-list">{{ tagsToRemoveString }}</div>
            <WsMultiSelect
                v-model="tagsToRemove"
                :options="tagsOptions"
                :key="tagsOptions.length"
                @change="change"
            >
                <template #activator>
                    <button class="tag-action-button" type="button">
                        <IconSvg16
                            :color="Color.accent100"
                            icon-name="minus"
                            class="icon"
                            stateless
                            passive
                        />
                        {{ $t('Tags.removeTags') }}
                    </button>
                </template>
            </WsMultiSelect>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { IComboboxOption } from '@/types/common';
import { Color } from '@/constants';
import { COMMA_SPACER } from '@/constants/Common';
import { truncateString } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsTags extends Vue {
    @VModel() currentTags!: string[];
    @Prop() public tags!: string[];
    @Prop() public tagsToAdd!: string[];
    @Prop() public tagsToRemove!: string[];

    public readonly Color = Color;

    public tagsToAddModel: string[] = this.tagsToAdd ?? [];

    public tagsToRemoveModel: string[] = this.tagsToRemove ?? [];

    public localTags: string[] = _.cloneDeep(this.tags);

    get tagsOptions(): IComboboxOption[] {
        return this.localTags.map(this.stringToOption);
    }

    get tagString() {
        return this.currentTags.map((tag) => truncateString(tag, 20)).join(COMMA_SPACER);
    }

    get tagsToAddString() {
        return this.tagsToAddModel.map((tag) => truncateString(tag, 20)).join(COMMA_SPACER);
    }

    get tagsToRemoveString() {
        return this.tagsToRemoveModel.map((tag) => truncateString(tag, 20)).join(COMMA_SPACER);
    }

    @Emit()
    public change() {
        return [this.tagsToAddModel, this.tagsToRemoveModel];
    }

    @Watch('tagsToAdd')
    public onTagstoAddChange() {
        this.tagsToAddModel = this.tagsToAdd;
    }

    @Watch('tagsToRemove')
    public onTagstoRemoveChange() {
        this.tagsToRemoveModel = this.tagsToRemove;
    }

    public stringToOption(tag: string): IComboboxOption {
       return {
            value: tag,
            label: tag,
            searchValue: tag,
        };
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.ws-tags-block:not(:last-child) {
    margin: 0 0 20px;
}

.tag-title {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.048px;
    color: $dark-solid-60;
    margin: 0 0 8px;
}

.tags-list {
    color: $text-dark;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.056px;
}

.tag-action-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 4px 0 0;
    font-size: 14px;
    line-height: 20px;
    color: $light-accent;
}
</style>