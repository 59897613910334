<template>
    <div class="component-header" :class="{ 'border-none': !isAuthorized }">
        <div class="support-line">
            <div class="copy">{{ $t('Header.copy', { currentYear }) }}</div>
            <div class="another">
                <router-link
                    v-if="isAuthorized"
                    :to="{ name: RouterNames.ReleaseNotes, params: { language } }"
                    class="support-line__item release-notes"
                >
                    {{ $t('ReleaseNotes.routerLink') }}
                </router-link>
                <router-link
                    v-if="isAuthorized"
                    :to="{ name: RouterNames.ContactUs, params: { language } }"
                    class="support-line__item"
                >
                    {{ $t('contact_us') }}
                </router-link>
                <router-link
                    v-if="isAuthorized"
                    :to="{ name: RouterNames.Download, params: { language } }"
                    class="support-line__item download"
                >
                    <span class="custom-icon-download no-margin" />
                    <span>{{ $t('Download.menu') }}</span>
                </router-link>
                <HelpMenu class="support-line__item" />
                <LocaleSwitcher :current-language="currentLanguage" @select="onSelectLanguage" />
                <NotifierStatus v-if="isAuthorized" />
            </div>
        </div>

        <div v-if="isAuthorized && hasUserData" class="top-menu">
            <router-link
                v-if="currentLicense.uuid"
                :to="{ name: RouterNames.MyLicenseProjects, params: { licenseId, language } }"
            >
                <ReviztoLogo white />
            </router-link>

            <ReviztoLogo v-if="!currentLicense.uuid" white />

            <NavMenu v-if="!isMobile" mode="horizontal" />

            <div class="actions">
                <RegionWidget />
                <UserWidget />
                <WsTooltip
                    v-if="hasLicenses"
                    :tooltip="$t('Notification.bellTooltip')"
                    :disabled="isOpenNotifications"
                    :placement="TooltipPositions.left"
                >
                    <NotificationWidget />
                </WsTooltip>
            </div>
        </div>

        <div v-if="project.id" class="project-bar">
            <router-link :to="{ name: RouterNames.MyLicenseProjects, params: { licenseId, language } }">
                <WsTooltip :tooltip="$t('Menu.projects')">
                    <button
                        class="project-bar-button"
                        @click="onMyProjectsClick"
                    >
                        <IconSvg16
                            :color-active="Color.white"
                            :color="Color.white"
                            :color-focus="Color.white"
                            icon-name="home"
                            inline
                            parent-hover
                            stateless
                            font-like
                        />
                    </button>
                </WsTooltip>
            </router-link>

            <WsTooltip :tooltip="$t('Menu.selectAnotherProject')">
                <ProjectsDropdown :active-project="project" />
            </WsTooltip>
        </div>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component } from 'vue-property-decorator';
import { AmplitudeEvent, BusEvent, Color, RouterNames, TooltipPositions } from '@/constants';
import { License, Project } from '@/models';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog } from '@/services';
import { ResizeMixin } from '@/mixins';
import NavMenu from '@/components/header/NavMenu.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import UserWidget from '@/components/header/UserWidget.vue';
import LocaleSwitcher from '@/components/header/LocaleSwitcher.vue';
import HelpMenu from '@/components/header/HelpMenu.vue';
import NotifierStatus from '@/components/common/NotifierStatus.vue';
import ReviztoLogo from '@/components/svg/ReviztoLogo.vue';
import RegionWidget from '@/components/header/RegionWidget.vue';
import NotificationWidget from '@/components/header/NotificationWidget.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import ProjectsDropdown from '@/components/header/ProjectsDropdown.vue';

@Component({
    components: {
        NavMenu,
        UserWidget,
        LocaleSwitcher,
        HelpMenu,
        NotifierStatus,
        ReviztoLogo,
        RegionWidget,
        NotificationWidget,
        WsTooltip,
        ProjectsDropdown,
        IconSvg16,
    },
})
export default class Header extends ResizeMixin {
    public readonly TooltipPositions = TooltipPositions;
    public readonly Color = Color;
    public readonly RouterNames = RouterNames;

    public isMobile = false;
    public readonly currentYear = new Date().getFullYear();
    public isOpenNotifications = false;

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get currentLicense(): License {
        return this.$store.getters.currentLicense;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get hasUserData() {
        return this.$store.getters.userData.id;
    }

    get isAuthorized() {
        return !this.$route.meta?.allowedWithoutAuth;
    }

    get isAuthenticate(): boolean  {
        return this.$store.getters.isAuthenticate;
    }

    get hasLicenses(): boolean {
        return this.$store.getters.hasLicenses;
    }

    get myProjectsCount(): number {
        switch (this.$route.name) {
            case RouterNames.MyLicenseProjectsArchived:
                return this.$store.getters.myProjectsArchived(this.currentLicense).length;
            case RouterNames.MyLicenseProjects:
                return this.currentLicense.isFrozen
                    ? this.$store.getters.myProjectsSuspended(this.currentLicense).length
                    : this.$store.getters.myProjectsOperational(this.currentLicense).length;
            default:
                return this.$store.getters.myProjectsOperational(this.currentLicense).length;
        }
    }

    get currentLanguage() {
        return this.$store.getters.currentLanguage;
    }

    public created() {
        eventBus.$on(BusEvent.isOpenNotifications, (isOpen: boolean) => {
            this.isOpenNotifications = isOpen;
        });
    }

    public onMyProjectsClick() {
        amplitudeLog(AmplitudeEvent.toolbarChangeProject, { userProjects: this.myProjectsCount, action: 'my projects' });
    }

    public beforeDestroy() {
        eventBus.$off(BusEvent.isOpenNotifications);
    }

    public handleResize() {
        this.$store.commit('setScreenWidth', innerWidth);
    }

    public onSelectLanguage(language: string) {
        this.$router.replace({
            params: {
                ..._.cloneDeep(this.$route.params),
                language,
            },
        });

        this.$store.commit('setCurrentLanguage', language);

        if (this.isAuthenticate) {
            this.$store.dispatch('uploadUserData', { language });
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.component-header {
    display: block;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 200;

    &.border-none {
        border: none;
    }
}

::v-deep .top-menu {
    display: flex;
    overflow: auto;
    justify-content: flex-end;
    align-items: center;
    height: 32px;
    transition: all .2s linear;
    background: $dark-background;

    &.affix {
        @media (min-width: $screen-sm) {
            height: 60px;
        }
    }

    .revizto-logo {
        padding: 0 16px;
    }

    .component-nav-menu {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
    }

    .actions {
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.support-line {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .copy {
        align-self: center;
        margin-left: 48px;
        color: $medium-gray;
        font-size: 12px;
    }

    .another {
        display: flex;
        font-size: 14px;
    }

    .support-line__item {
        align-self: center;
        display: flex;
        align-items: center;
        margin-right: 16px;
    }

    .download {
        background-color: $primary-blue;
        color: $white;
        padding: 0 16px 0 12px;
        height: 100%;
        font-weight: 500;

        &:hover {
            background-color: $hover-blue;
        }
    }

    .release-notes {
        margin-right: 32px;
    }
}

.project-bar {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $dark-background;
    color: $white;
    height: 32px;
    font-size: 14px;
    padding-left: $default-padding;
    border-top: 1px solid $default-black;
    ::v-deep .top-menu-item {
        padding: 8px;
    }
}

.project-bar-button {
    padding: 5px;

    &:hover {
        background-color: $light-accent;
    }
}

</style>
