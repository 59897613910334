enum OSList {
    Windows = 'Windows',
    Android = 'Android',
    iPhone = 'iPhone',
    iPad = 'iPad',
    Mac = 'Mac',
    Linux = 'Linux',
    Unknown = 'Unknown',
}

class PlatformService {
    public currentOS: OSList = OSList.Unknown;

    constructor() {
        this.currentOS = this.getOS();
    }

    get undoCommand() {
        return this.isMac ? '(⌘+Z)' : '(Ctrl+Z)';
    }
    get redoCommand() {
        return this.isMac ? '(⌘+Shift+Z)' : '(Ctrl+Shift+Z)';
    }
    get isMac() {
        return this.currentOS === OSList.Mac;
    }
    get isWindows() {
        return this.currentOS === OSList.Windows;
    }
    get isAndroid() {
        return this.currentOS === OSList.Android;
    }
    get isIPhone() {
        return this.currentOS === OSList.iPhone;
    }
    get isIPad() {
        return this.currentOS === OSList.iPad;
    }
    get isLinux() {
        return this.currentOS === OSList.Linux;
    }
    get isUnknown() {
        return this.currentOS === OSList.Unknown;
    }

    public getOS(): OSList {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/Windows/i.test(userAgent)) {
            return OSList.Windows;
        }

        if (/Android/i.test(userAgent)) {
            return OSList.Android;
        }

        if (/Linux/i.test(userAgent)) {
            return OSList.Linux;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        // @ts-ignore
        if (!window.MSStream) {
            if (/iPhone/.test(userAgent)) {
                return OSList.iPhone;
            }
            if (/iPad/.test(userAgent)) {
                return OSList.iPad;
            }
            if (/Mac/.test(userAgent)) {
                return OSList.Mac;
            }
        }

        return OSList.Unknown;
    }
}

export const Platform = new PlatformService();
