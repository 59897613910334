<template>
    <v-chip
        :color="color"
        :text-color="textColor"
        :class="{ 'tag-like': tagLike }"
        small
    >
        <span class="highlighting">{{ $t(`Dashboard.chart.settings.filter.priority.${value}`) }}</span>
    </v-chip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColorService } from '@/services';
import { IssuePriorityEnum } from '@/domain/issue/constants/IssuePriority';

@Component
export default class IssuePriorityText extends Vue {
    @Prop({ required: true }) public value!: IssuePriorityEnum;
    @Prop({ type: Boolean, default: false }) public tagLike!: boolean;

    get color() {
        return ColorService.getColorForField(this.value);
    }
    get textColor() {
        return ColorService.contrastHexColor(this.color);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/styles/variables';

    .v-chip.tag-like {
        margin-left: 5px;
        border-radius: $border-radius-default;
        padding: 0 8px;
        height: 18px;
    }
</style>
