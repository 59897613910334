<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <g id="icon" fill="#CC2929">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1712 4.10115C10.9683 2.63295 13.0317 2.63295 13.8288 4.10115L20.7337 16.8195C21.5084 18.2464 20.5001 20 18.9049 20H5.0951C3.49991 20 2.49159 18.2464 3.26628 16.8195L10.1712 4.10115ZM11 7H13V14H11V7ZM13 16H11V18H13V16Z" />
        </g>
    </svg>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class IconAttention extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
