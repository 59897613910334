import { TUuid } from '@/types/common';
import http from '@/api/http';
import { ICustomStatus } from '@/domain/customStatus/types/customStatus';

export default class CustomStatusApi {
    /**
     * create custom status
     */
    public static addCustomStatus(projectUuid: TUuid, status: ICustomStatus): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-status/add`,
            { entities: JSON.stringify([status]) },
            true,
            true,
        ).then((response) => response.data);
    }

    /**
     * edit custom status
     */
    public static editCustomStatus(projectUuid: TUuid, status: ICustomStatus): Promise<any> {
        return http.post(
            `project/${projectUuid}/issue-status/update`,
            { entities: JSON.stringify([status]) },
            true,
            true,
        ).then((response) => response.data);
    }
}