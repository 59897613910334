import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { BooleanNumber } from '@/constants';

export function deboolify<K>(value: K | boolean) {
    return _.isBoolean(value) ? Number(value) as BooleanNumber : value;
}

/**
 * Casts all the Boolean values in the object to 0s ans 1s
 * @param obj
 */
export function shallowDeboolifyObject(obj: Dict) {
    return _.mapValues(obj, deboolify);
}
