<template>
    <div class="component-help-menu">
        <a
            :href="helpHref"
            target="_blank"
            @click="onHelpClick"
        >
            {{ $t('Header.helpCenter') }}
        </a>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';

@Component
export default class HelpMenu extends Vue {
    get isLanguageJapan() {
        const ja = 'ja';
        const jp = 'jp';
        const languageFromRoute = String(this.$route.params.language).toLowerCase();
        const languageFromStore = this.$store.getters.currentLanguage;
        const languageFromNavigator = String(navigator.language).toLowerCase();
        return languageFromRoute.startsWith(jp)
            || languageFromStore.startsWith(jp)
            || languageFromNavigator.startsWith(ja);
    }

    get helpHref() {
        return this.isLanguageJapan ? 'https://const-tech.zendesk.com/hc/ja' : 'https://help.revizto.com/';
    }

    public onHelpClick() {
        amplitudeLog(AmplitudeEvent.helpCenterClick, { page: this.$route.name });
    }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

</style>
