<template>
    <HeaderSelect
        :title="$t('Form.region')"
        :activator-text="activatorText"
        class="region"
        data-test="mnb-regions-list"
        close-on-list-click
        @size="setSize"
    >
        <template #list>
            <v-list-item disabled>
                <span class="submenu-title">{{ $t('Simple_word.regions') }}</span>
            </v-list-item>
            <v-list-item v-for="region in regionOptions" :key="region.value">
                <router-link
                    :to="{ name: RouterNames.Login, params: { language, region: region.value } }"
                    :class="{ 'router-link-exact-active': region.value === currentRegionId }"
                >
                    {{ region.text }}
                </router-link>
            </v-list-item>
        </template>
    </HeaderSelect>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { RouterNames } from '@/constants';
import { Region } from '@/models';
import HeaderSelect from '@/components/header/HeaderSelect.vue';

@Component({
    components: {
        HeaderSelect,
    },
})
export default class RegionWidget extends Vue {
    public readonly RouterNames = RouterNames;

    get language() {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get currentRegionId() {
        return this.$store.getters.regionId;
    }

    get regionOptions() {
        return Region.makeOptions(this.$store.getters.pureRegions);
    }

    get activatorText(): TranslateResult {
        return this.$store.getters.regionName;
    }

    public setSize(size: number) {
        this.$store.commit('setHeaderComponentSize', { name: 'region', size });
    }
}
</script>
