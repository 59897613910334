<template>
    <div class="component-register-form form-box">
        <h1 class="auth-title">{{ $t('Text.registration') }}</h1>
        <div v-if="isLoading" v-loading="isLoading" />
        <v-form
            v-if="registerForm.email"
            v-model="valid"
            ref="registerForm"
            lazy-validation
        >
            <WsInput
                v-model="registerForm.firstName"
                :label="$t('Form.first_name')"
                :rules="rules.firstName"
                clearable
                required
                @keypress.enter.native="register"
                @input="onChangeFirstName"
            />
            <WsInput
                v-model="registerForm.lastName"
                :label="$t('Form.last_name')"
                :rules="rules.lastName"
                clearable
                required
                @keypress.enter.native="register"
                @input="onChangeLastName"
            />
            <WsInput
                v-model="registerForm.email"
                :label="$t('Form.email')"
                :rules="rules.email"
                clearable
                disabled
                required
            />
            <WsInput
                v-model="registerForm.password"
                :label="$t('Form.pass')"
                :rules="rules.password"
                type="password"
                clearable
                required
                show-password
                hide-details
                validate-on-blur
                @input="secondaryValidate"
                @keypress.enter.native="register"
            />
            <PasswordMustContain
                :password="registerForm.password"
                @is-valid-password="passwordValidation"
            />
            <WsInput
                :label="$t('Form.confirm_pass')"
                v-model="registerForm.passwordConfirm"
                :rules="rules.passwordConfirm"
                type="password"
                clearable
                required
                show-password
                validate-on-blur
                @keypress.enter.native="register"
            />
            <WsCheckbox
                v-model="registerForm.acceptEula"
                :rules="rules.acceptEula"
                required
            >
                <template #label>
                    <div v-html="sanitizeHtml($t('Form.acceptEula'))" @click.stop />
                </template>
            </WsCheckbox>

            <WsButton
                :loading="isSending"
                :disabled="!valid"
                type="primary"
                round
                @click="register"
            >
                {{ $t('Button.register') }}
            </WsButton>
        </v-form>

        <div class="error" v-if="error">{{ error }}</div>
        <WsButton
            v-if="isLinkExpired"
            :loading="isRenewingLink"
            type="primary"
            contained
            @click="renewLink"
        >
            {{ $t('Form.requestNew') }}
        </WsButton>
        <div class="success" v-if="successEmail">
            <WsButton round>
                <router-link :to="{ name: 'Login' }">{{ $t('Button.sign_in') }}</router-link>
            </WsButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TRulesObj } from '@/types/TRulesObj';
import { AmplitudeEvent, RESPONSE, RouterNames } from '@/constants';
import { amplitudeLog, FormValidator, isMobile, sanitizeHtml } from '@/services';
import ModalHeader from '@/components/common/ModalHeader.vue';
import PasswordMustContain from '@/components/user/PasswordMustContain.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        ModalHeader,
        PasswordMustContain,
        WsInput,
        WsCheckbox,
        WsButton,
    },
})
export default class RegisterForm extends Vue {
    public readonly sanitizeHtml = sanitizeHtml;

    public valid = true;
    public isLoading = false;
    public isSending = false;
    public registerForm = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        acceptEula: false,
    };
    public error: TranslateResult = '';
    public successEmail = '';

    public isValidPassword = false;

    public isValidFirstName = true;
    public isValidLastName = true;

    public isLinkExpired = false;
    public message: TranslateResult = '';
    public isRenewingLink = false;

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }
    get language() {
        return this.$route.params.language;
    }
    get registerData() {
        return {
            uid: this.$route.params.uid,
            token: this.$route.params.token,
        };
    }
    get rules(): TRulesObj {
        return {
            firstName: [
                FormValidator.required,
                () => this.isValidFirstName || this.$t('errors.invalid_first_name'),
            ],
            lastName: [
                FormValidator.required,
                () => this.isValidLastName || this.$t('errors.invalid_last_name'),
            ],
            email: [
                FormValidator.required,
                FormValidator.email,
            ],
            password: [
                () => this.isValidPassword,
            ],
            passwordConfirm: [
                FormValidator.required,
                (value: string) => this.registerForm.password.trim() === value.trim() || this.$t('errors.pass_dont_match'),
            ],
            acceptEula: [
                FormValidator.required,
            ],
        };
    }

    public created() {
        amplitudeLog(AmplitudeEvent.startRegistration);

        if (this.registerData.uid && this.registerData.token) {
            this.isLoading = true;
            this.$store.dispatch('checkInvite', this.registerData).then((response) => {
                this.registerForm.email = response.email;
                this.registerForm.firstName = response.firstname;
                this.registerForm.lastName = response.lastname;
            }).catch((error) => {
                switch (error.result) {
                    case RESPONSE.EXPIRED_CODE:
                        this.error = this.$t('errors.registrationLinkExpired');
                        this.isLinkExpired = true;
                        break;
                    case RESPONSE.ALREADY_INVITED:
                        this.error = this.$t('UserExists', { email: error.data.email });
                        this.successEmail = error.data.email;
                        break;
                    case RESPONSE.EXCHANGED_LINK:
                        this.error = this.$t('errors.exchanged_link');
                        break;
                    case RESPONSE.USER_NOT_EXISTS:
                    case RESPONSE.WRONG_CODE:
                    case RESPONSE.INVALID_DATA:
                        this.error = this.$t('errors.invalid_url');
                        break;
                    default:
                        this.error = this.$t('errors.unexpected');
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }

    public async renewLink() {
        this.isRenewingLink = true;
        try {
            await this.$store.dispatch('renewLink', this.$route.params.token);
            this.error = '';
            this.message = this.$t('License.resendInvitation.success');
        } catch (error: any) {
            switch (error.result) {
                case RESPONSE.SUCCESS:
                    this.error = '';
                    this.message = this.$t('License.resendInvitation.success');
                    break;
                case RESPONSE.EXCHANGED_LINK: // technically, this case should never happen
                    this.error = this.$t('errors.exchanged_link');
                    break;
                case RESPONSE.USER_NOT_EXISTS:
                    this.error = this.$t('errors.invalid_url');
                    break;
                default:
                    this.error = this.$t('errors.unexpected');
            }
        } finally {
            this.isRenewingLink = false;
            this.isLinkExpired = false;
        }
    }

    public secondaryValidate() {
        if (this.registerForm.password && this.registerForm.password === this.registerForm.passwordConfirm) {
            const form = this.$refs.registerForm as HTMLFormElement;
            form.validate();
        }
    }

    public async register(): Promise<void> {
        const form = this.$refs.registerForm as HTMLFormElement;
        if (!form.validate()) {
            return;
        }

        amplitudeLog(AmplitudeEvent.submitRegistration);

        this.isSending = true;
        const params = {
            email: this.registerForm.email,
            password: this.registerForm.password.trim(),
            firstname: this.registerForm.firstName,
            lastname: this.registerForm.lastName,
        };

        let postRegisterResponse;
        try {
            postRegisterResponse = await this.$store.dispatch('register', {
                params,
                token: this.registerData.token,
            });
        } catch (error: any) {
            if (error.result === RESPONSE.ALREADY_INVITED) {
                this.error = this.$t('UserExists', { email: error?.data?.email || '' });
                this.successEmail = error?.data?.email;
            }
            const invalidFields = error.message.split(/'/).filter((_chunk: string, idx: number) => idx % 2);
            this.isSending = false;
            this.isValidFirstName = !invalidFields.includes('firstname');
            this.isValidLastName = !invalidFields.includes('lastname');
            form.validate();
            return;
        }

        this.$store.commit('setRegionId', postRegisterResponse.region);
        await this.$store.dispatch('authorize', { email: params.email, password: params.password });
        await this.$store.dispatch('downloadRegionServiceData');
        await this.$store.dispatch('loadLicenses');
        if (!this.$store.getters.licensesWithValidAuth.length) {
            this.$router.push({
                name: RouterNames.SelectLicense,
                params: {
                    language: this.language,
                },
            });
        } else {
            this.$store.commit('setIsShowBoardingWelcome', !isMobile());
            this.$store.dispatch('connectNotifier');
            this.$router.push({
                name: RouterNames.MyLicenseProjects,
                params: {
                    licenseId: String(this.licenseId),
                    language: this.language,
                },
            });
        }
    }

    public passwordValidation(value: boolean) {
        this.isValidPassword = value;
    }

    public onChangeFirstName() {
        this.isValidFirstName = true;
    }
    public onChangeLastName() {
        this.isValidLastName = true;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/auth.scss';

.form-box .v-btn {
    width: fit-content;
}
</style>
