<template>
    <div class="component-notification-issue-item notification-issue-item">
        <div class="top-line">
            <div>
                <span v-if="record.projectTitle" class="project-title amp-mask">{{ record.projectTitle }}</span>
                <span class="time">{{ time }}</span>
            </div>
            <div v-if="!record.isRead">
                <div class="new-badge">{{ $t('Simple_word.new') }}</div>
            </div>
        </div>

        <div v-if="isShowAuthor" class="flex-align-center author">
            <MemberAvatar
                :member-id="authorMember.id"
                :avatar-src="authorMember.avatar"
                class="amp-block"
                no-link
            />
            <div class="author-name amp-mask">{{ authorName }}</div>
        </div>

        <template v-if="record.type === NotificationRecordType.issue">
            <p class="total-issues">{{ $t('Notification.totalIssues', { count: record.data.length }) }}</p>
            <div v-if="[EventTypeEnum.ISSUE_COMMENT_ADDED, EventTypeEnum.ISSUE_EDITED].includes(record.eventType)">
                <template v-if="record.isSameIssueChanges">
                    <template v-if="record.isDiff">
                        <IssueCommentDiff
                            v-for="(comment, idx) in comments"
                            class="amp-mask"
                            :comment="comment"
                            :key="'diff_' + idx"
                        />
                    </template>
                    <template v-if="record.isText">
                        <WsMarkdownText
                            v-for="({ text }, idx) in comments"
                            class="amp-mask"
                            :value="text"
                            :key="'text_' + idx"
                        />
                    </template>
                </template>
                <template v-else>
                    <template v-if="record.countsWithComments.length < 10">
                        <div v-for="({ count, comments }, index) in record.countsWithComments" :key="'separate_' + index">
                            <div class="suppressed">{{ $t('Notification.issues' + getTEndByCount(count), { count }) }}</div>
                            <template v-for="({ text }, idx) in comments">
                                <WsMarkdownText
                                    v-if="text"
                                    class="amp-mask"
                                    :value="text"
                                    :key="'text_' + idx"
                                />
                            </template>
                            <IssueCommentDiff
                                v-for="(comment, idx) in comments"
                                class="amp-mask"
                                :comment="comment"
                                :key="'diff_' + idx"
                            />
                        </div>
                    </template>
                </template>
            </div>
        </template>

        <template v-if="record.isReport">
            <div class="amp-mask">
                {{ $t('Notification.report') }} {{ reportTitle }}
            </div>
        </template>

        <template v-if="record.isProjectDashboard">
            <div class="amp-mask">
                {{ $t('Notification.dashboard') }} {{ dashboardTitle }}
            </div>
        </template>

        <template v-if="record.isProjectGraph">
            <div class="amp-mask">{{ $t('Notification.dashboard') }} {{ graphDashboardTitle }}</div>
            <div class="amp-mask">{{ $t('Notification.chart') }} {{ graphTitle }}</div>
        </template>

        <template v-if="record.isLicenseDashboard">
            <div class="amp-mask">
                {{ $t('Notification.licenseDashboard') }} {{ dashboardTitle }}
            </div>
        </template>

        <template v-if="record.isLicenseGraph">
            <div class="amp-mask">{{ $t('Notification.licenseDashboard') }} {{ graphDashboardTitle }}</div>
            <div class="amp-mask">{{ $t('Notification.licenseChart') }} {{ graphTitle }}</div>
        </template>

        <div class="results">
            <div v-if="isInProgress" class="progress">
                <v-progress-circular
                    :size="30"
                    color="gray"
                    indeterminate
                />
                <span class="processing">{{ $t('Notification.processing') }}</span>
            </div>

            <div
                v-else-if="isRecordOneOf(
                    EventTypeEnum.PROJECT_MEMBER_ADDED,
                    EventTypeEnum.PROJECT_MEMBER_DELETED,
                )"
            >
                <div class="notification-other">
                    <IconSvg16 :color="Color.primaryGreen" icon-name="check" passive />
                    <div class="notification-success-count">
                        <template v-if="record.eventType === EventTypeEnum.PROJECT_MEMBER_ADDED">
                            {{ $t(`Notification.projectMembersAdded${record.data.length > 1 ? '_pl' : ''}`, { count: record.data.length }) }}
                        </template>
                        <template v-if="record.eventType === EventTypeEnum.PROJECT_MEMBER_DELETED">
                            {{ $t(`Notification.projectMembersDeleted${record.data.length > 1 ? '_pl' : ''}`, { count: record.data.length }) }}
                        </template>
                    </div>
                </div>
                <div class="notification-data amp-mask">
                    <div v-for="{ userIdentifier } in record.data" :key="userIdentifier">{{ userIdentifier }}</div>
                </div>
            </div>

            <div
                v-else-if="isRecordOneOf(
                    EventTypeEnum.LICENSE_MEMBER_ADDED,
                    EventTypeEnum.LICENSE_MEMBER_DELETED,
                    EventTypeEnum.LICENSE_MEMBER_ADDED_PENDING,
                )"
            >
                <div class="notification-other">
                    <IconSvg16 :color="Color.primaryGreen" icon-name="check" passive />
                    <div class="notification-success-count">
                        <template v-if="record.eventType === EventTypeEnum.LICENSE_MEMBER_ADDED">
                            {{ $t(`Notification.licenseMembersAdded${record.data.length > 1 ? '_pl' : ''}`, { count: record.data.length }) }}
                        </template>
                        <template v-if="record.eventType === EventTypeEnum.LICENSE_MEMBER_DELETED">
                            {{ $t(`Notification.licenseMembersDeleted${record.data.length > 1 ? '_pl' : ''}`, { count: record.data.length }) }}
                        </template>
                        <template v-if="record.eventType === EventTypeEnum.LICENSE_MEMBER_ADDED_PENDING">
                            {{ $t(`Notification.licenseMembersAddedPending${record.data.length > 1 ? '_pl' : ''}`, { count: record.data.length }) }}
                        </template>
                    </div>
                </div>
                <div class="notification-data amp-mask">
                    <div v-for="{ userIdentifier } in record.data" :key="userIdentifier">{{ userIdentifier }}</div>
                </div>
                <div
                    v-if="record.eventType === EventTypeEnum.LICENSE_MEMBER_ADDED_PENDING"
                    class="link approval-link"
                    @click="viewApprovalList"
                >
                    {{ $t('Notification.viewApprovalList') }}
                </div>
            </div>

            <div v-else-if="record.eventType === EventTypeEnum.PROJECT_REVISION_ADDED" class="view-something-wrapper">
                <div>{{ $t('Notification.revisionAdded') }}</div>
                <div class="link view-link" @click="viewRevisions">{{ $t('Notification.viewVersions') }}</div>
            </div>

            <div v-else-if="record.isReport" class="view-something-wrapper">
                <template
                    v-if="isRecordOneOf(EventTypeEnum.REPORT_SENT, EventTypeEnum.REPORT_SENT_WITH_DEGRADATION)"
                >
                    <div class="notification-other">
                        <IconSvg16 :color="Color.primaryGreen" icon-name="check" passive />
                        <div class="notification-other-result">
                            {{ $t('Notification.done') }}
                        </div>
                    </div>
                    <div class="link view-link" @click="downloadReport">
                        {{ $t('Notification.downloadFile') }}
                    </div>
                </template>
                <div v-else-if="isRecordOneOf(EventTypeEnum.REPORT_ERROR)" class="notification-other">
                    <IconSvg16 :color="Color.primaryRed" icon-name="x" passive />
                    <div class="notification-other-result">
                        {{ $t('Notification.reportError') }}
                    </div>
                </div>
                <div v-else-if="isRecordOneOf(EventTypeEnum.REPORT_SENT_EMPTY)" class="notification-other">
                    <IconSvg16 :color="Color.primaryRed" icon-name="x" passive />
                    <div class="notification-other-result">
                        {{ $t('Notification.reportCanceled') }}
                    </div>
                </div>
            </div>

            <div v-else-if="record.isProjectDashboard" class="view-something-wrapper">
                <template
                    v-if="isRecordOneOf(
                        EventTypeEnum.PROJECT_DASHBOARD_SENT,
                        EventTypeEnum.PROJECT_DASHBOARD_SENT_WITH_DEGRADATION,
                    )"
                >
                    <div class="notification-other">
                        <IconSvg16 :color="Color.primaryGreen" icon-name="check" passive />
                        <div class="notification-other-result">
                            {{ $t('Notification.done') }}
                        </div>
                    </div>
                    <div class="link view-link" @click="downloadDashboard">
                        {{ $t('Notification.downloadFile') }}
                    </div>
                </template>
                <div
                    v-else-if="isRecordOneOf(
                        EventTypeEnum.PROJECT_DASHBOARD_ERROR,
                        EventTypeEnum.PROJECT_DASHBOARD_CANCELED_NO_DATA,
                    )"
                    class="notification-other"
                >
                    <IconSvg16 :color="Color.primaryRed" icon-name="x" passive />
                    <div class="notification-other-result">
                        <template v-if="record.eventType === EventTypeEnum.PROJECT_DASHBOARD_ERROR">
                            {{ $t('Notification.dashboardError') }}
                        </template>
                        <template v-if="record.eventType === EventTypeEnum.PROJECT_DASHBOARD_CANCELED_NO_DATA">
                            {{ $t('Notification.dashboardCanceled') }}
                        </template>
                    </div>
                </div>
            </div>

            <div v-else-if="record.isProjectGraph" class="view-something-wrapper">
                <template
                    v-if="isRecordOneOf(
                        EventTypeEnum.PROJECT_GRAPH_SENT,
                        EventTypeEnum.PROJECT_GRAPH_SENT_WITH_DEGRADATION,
                    )"
                >
                    <div class="notification-other">
                        <IconSvg16 :color="Color.primaryGreen" icon-name="check" passive />
                        <div class="notification-other-result">
                            {{ $t('Notification.done') }}
                        </div>
                    </div>
                    <div class="link view-link" @click="downloadGraph">
                        {{ $t('Notification.downloadFile') }}
                    </div>
                </template>
                <div
                    v-else-if="isRecordOneOf(
                        EventTypeEnum.PROJECT_GRAPH_ERROR,
                        EventTypeEnum.PROJECT_GRAPH_CANCELED_NO_DATA,
                    )"
                    class="notification-other"
                >
                    <IconSvg16 :color="Color.primaryRed" icon-name="x" passive />
                    <div class="notification-other-result">
                        <template v-if="record.eventType === EventTypeEnum.PROJECT_GRAPH_ERROR">
                            {{ $t('Notification.chartError') }}
                        </template>
                        <template v-if="record.eventType === EventTypeEnum.PROJECT_GRAPH_CANCELED_NO_DATA">
                            {{ $t('Notification.chartCanceled') }}
                        </template>
                    </div>
                </div>
            </div>

            <div v-else-if="record.isLicenseDashboard" class="view-something-wrapper">
                <template
                    v-if="isRecordOneOf(
                        EventTypeEnum.LICENSE_DASHBOARD_SENT,
                        EventTypeEnum.LICENSE_DASHBOARD_SENT_WITH_DEGRADATION,
                    )"
                >
                    <div class="notification-other">
                        <IconSvg16 :color="Color.primaryGreen" icon-name="check" passive />
                        <div class="notification-other-result">
                            {{ $t('Notification.done') }}
                        </div>
                    </div>
                    <div class="link view-link" @click="downloadDashboard">
                        {{ $t('Notification.downloadFile') }}
                    </div>
                </template>
                <div
                    v-else-if="isRecordOneOf(
                        EventTypeEnum.LICENSE_DASHBOARD_ERROR,
                        EventTypeEnum.LICENSE_DASHBOARD_CANCELED_NO_DATA,
                    )"
                    class="notification-other"
                >
                    <IconSvg16 :color="Color.primaryRed" icon-name="x" passive />
                    <div class="notification-other-result">
                        <template v-if="record.eventType === EventTypeEnum.LICENSE_DASHBOARD_ERROR">
                            {{ $t('Notification.dashboardError') }}
                        </template>
                        <template v-if="record.eventType === EventTypeEnum.LICENSE_DASHBOARD_CANCELED_NO_DATA">
                            {{ $t('Notification.dashboardCanceled') }}
                        </template>
                    </div>
                </div>
            </div>

            <div v-else-if="record.isLicenseGraph" class="view-something-wrapper">
                <template
                    v-if="isRecordOneOf(
                        EventTypeEnum.LICENSE_GRAPH_SENT,
                        EventTypeEnum.LICENSE_GRAPH_SENT_WITH_DEGRADATION,
                    )"
                >
                    <div class="notification-other">
                        <IconSvg16 :color="Color.primaryGreen" icon-name="check" passive />
                        <div class="notification-other-result">
                            {{ $t('Notification.done') }}
                        </div>
                    </div>
                    <div class="link view-link" @click="downloadGraph">
                        {{ $t('Notification.downloadFile') }}
                    </div>
                </template>
                <div
                    v-else-if="isRecordOneOf(
                        EventTypeEnum.LICENSE_GRAPH_ERROR,
                        EventTypeEnum.LICENSE_GRAPH_CANCELED_NO_DATA,
                    )"
                    class="notification-other"
                >
                    <IconSvg16 :color="Color.primaryRed" icon-name="x" passive />
                    <div class="notification-other-result">
                        <template v-if="record.eventType === EventTypeEnum.LICENSE_GRAPH_ERROR">
                            {{ $t('Notification.chartError') }}
                        </template>
                        <template v-if="record.eventType === EventTypeEnum.LICENSE_GRAPH_CANCELED_NO_DATA">
                            {{ $t('Notification.chartCanceled') }}
                        </template>
                    </div>
                </div>
            </div>

            <div v-else-if="record.type === NotificationRecordType.issue">
                <div v-if="countSuccess" class="notification-success">
                    <IconSvg16 :color="Color.primaryGreen" icon-name="check" passive />
                    <div class="notification-success-count">{{ issueSuccessString }}</div>
                    <div
                        v-if="record.eventType !== EventTypeEnum.ISSUE_DELETED"
                        class="link view-link"
                        @click="openSuccessIssues"
                    >
                        {{ $t('Notification.openIssues') }}
                    </div>
                </div>
                <div v-if="countError" class="notification-error">
                    <IconSvg16 icon-name="none" :color="Color.primaryRed" passive />
                    <div class="notification-error-count">{{ $t('Notification.nErrors', { count: countError }) }}</div>
                </div>
                <div
                    v-for="errorType in errorItemsByErrorType"
                    :key="errorType.errorCode"
                    class="notification-error-item"
                >
                    <div class="error-message">{{ errorType.errorMsg }}</div>
                    <div class="link view-link" @click="openIssuesInIssueTracker(errorType.ids)">
                        {{ $t('Notification.openIssues') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import moment from 'moment';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
    AmplitudeEvent,
    Color,
    EventClass,
    EventTypeEnum,
    RouterNames,
} from '@/constants';
import {
    LicenseMember,
    NotificationRecord,
    NotificationRecordStatus,
    NotificationRecordType,
    ProjectMember,
} from '@/models';
import { amplitudeLog, getTranslationEndingByCount, timeFormatter } from '@/services';
import WsMarkdownText from '@/components/common/WsMarkdownText.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import MemberAvatar from '@/components/common/MemberAvatar.vue';
import IssueCommentDiff from '@/domain/comment/components/IssueCommentDiff.vue';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import { IssueFilterExpr } from '@/domain/issueFilter/constants/IssueFilterExpr';
import { IssueTrackerFilterValue } from '@/domain/issueFilter/services/IssueTrackerFilterValue';

enum DownloadFileType {
    Report = 'report',
    Dashboard = 'dashboard',
    Graph = 'graph',
}

@Component({
    components: {
        IconSvg16,
        IssueCommentDiff,
        MemberAvatar,
        WsMarkdownText,
    },
})
export default class NotificationItem extends Vue {
    @Prop() public record!: NotificationRecord;

    public readonly Color = Color;
    public readonly NotificationRecordType = NotificationRecordType;
    public readonly EventTypeEnum = EventTypeEnum;
    public readonly getTEndByCount = getTranslationEndingByCount;

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): string {
        return String(this.$store.getters.licenseByUuid(this.record.licenseUuid).id);
    }

    get projectId(): string {
        return String(this.record.projectId);
    }

    get isShowAuthor() {
        return this.record.authorEmail && this.record.authorEmail !== this.$store.getters.userData.email;
    }

    get issueSuccessString() {
        const ending = getTranslationEndingByCount(this.countSuccess);
        const params = { count: this.countSuccess };
        switch (this.record.eventType) {
            case EventTypeEnum.ISSUE_CREATED:
                return this.$t(`Notification.issuesAdded${ending}`, params);
            case EventTypeEnum.ISSUE_DELETED:
                return this.$t(`Notification.issuesDeleted${ending}`, params);
            case EventTypeEnum.ISSUE_RESTORED:
                return this.$t(`Notification.issuesRestored${ending}`, params);
            case EventTypeEnum.ISSUE_COMMENT_ADDED:
            case EventTypeEnum.ISSUE_EDITED:
                return this.$t(`Notification.issuesUpdated${ending}`, params);
            default:
                return '';
        }
    }

    get time(): string {
        const time = moment.utc(this.record.timestamp).local();
        return timeFormatter(time);
    }

    get isInProgress() {
        return this.record.isInProgress;
    }

    get isIssue() {
        return Boolean(EventClass.issues[this.record.eventType]);
    }

    get comments() {
        if (this.record.isSameIssueChanges) {
            return this.record.data?.[0]?.comments || [];
        }
        return [];
    }

    get reportTitle() {
        return this.record.data?.reportData?.reportTitle || '';
    }

    get dashboardTitle() {
        return this.record.data?.dashboardData?.dashboardTitle || '';
    }

    get graphDashboardTitle() {
        return this.record.data?.graphData?.dashboardTitle || '';
    }

    get graphTitle() {
        return this.record.data?.graphData?.graphTitle || '';
    }

    get successIssuesIds() {
        return this.isIssue
            ? this.record.data
                .filter((item: any) => item.status === NotificationRecordStatus.success)
                .map(({ issueId }: any) => String(issueId))
            : [];
    }

    get countSuccess() {
        return this.successIssuesIds.length;
    }

    get errorItems() {
        return this.isIssue
            ? this.record.data.filter((item: any) => item.status === NotificationRecordStatus.error)
            : [];
    }

    get errorItemsByErrorType() {
        const groups = _.groupBy(this.errorItems, 'errorCode');
        return _.entries(groups).map(([errorCode, arr]) => ({
            errorCode,
            errorMsg: arr[0].errorMsg,
            ids: arr.map((item) => String(item.issueId)),
        }));
    }

    get countError() {
        return this.errorItems.length;
    }

    get authorMember() {
        const projectMembers = this.$store.getters.projectMembersByProjectId(this.projectId);
        const licenseMembers = this.$store.getters.licenseMembersByLicenseId(this.licenseId);
        return projectMembers?.find((member: ProjectMember) => member.email === this.record.authorEmail)
            || licenseMembers.find((member: LicenseMember) => member.email === this.record.authorEmail)
            || {};
    }
    get authorName() {
        return this.authorMember.fullname || this.record.authorEmail;
    }

    @Emit()
    public close() {
        return;
    }

    public isRecordOneOf(...eventTypes: EventTypeEnum[]) {
        return eventTypes.includes(this.record.eventType);
    }

    public openSuccessIssues() {
        this.openIssuesInIssueTracker(this.successIssuesIds);
    }

    public async openIssuesInIssueTracker(ids: string[]) {
        const filters = {
            [IssuesFilterType.id]: new IssueTrackerFilterValue(IssuesFilterType.id, IssueFilterExpr.IN, ids),
        };

        await Promise.all([
            this.$store.dispatch('updateTrackerFilters', {
                projectId: this.projectId,
                filters,
            }),
            this.$router.push({
                name: RouterNames.ProjectIssueTracker,
                params: {
                    language: this.language,
                    licenseId: this.licenseId,
                    projectId: this.projectId,
                },
            }),
        ]);
        amplitudeLog(AmplitudeEvent.ncOpenIssue, { issuesCount: ids.length });

        this.close();
    }

    public async viewRevisions() {
        await this.$router.push({
            name: RouterNames.ProjectRevisions,
            params: {
                language: this.language,
                licenseId: this.licenseId,
                projectId: this.projectId,
            },
        });
        this.close();
        amplitudeLog(AmplitudeEvent.ncViewVersionsClick);
    }

    public downloadReport() {
        this.downloadEntity(this.record.data?.reportData?.url, DownloadFileType.Report);
    }

    public downloadDashboard() {
        this.downloadEntity(this.record.data.dashboardData.url, DownloadFileType.Dashboard);
    }

    public downloadGraph() {
        this.downloadEntity(this.record.data.graphData.url, DownloadFileType.Graph);
    }

    public downloadEntity(url: string, fileType: DownloadFileType) {
        if (url) {
            open(url);
            amplitudeLog(AmplitudeEvent.ncDownloadClick, { fileType });
        }
        this.close();
    }

    public async viewApprovalList() {
        await this.$router.push({
            name: RouterNames.LicenseMembersApproval,
            params: {
                language: this.language,
                licenseId: this.licenseId,
            },
        });
        this.close();
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.notification-issue-item {
    border: $border;
    padding: 15px 17px;
    cursor: pointer;
    font-size: $font-size-small;
    &:hover {
        border-color: $primary-blue;
    }
    .markdown-text ::v-deep p {
        margin-bottom: 6px;
    }
}
.top-line {
    display: flex;
    justify-content: space-between;
    font-size: $font-size;
    margin-bottom: 7px;
}
.project-title {
    color: $select-black;
    font-weight: 500;
    margin-right: 5px;
}
.time {
    color: $default-black;
    font-weight: $font-weight;
}
.new-badge {
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 500;
    color: white;
    background-color: $primary-blue;
    padding: 2px 3px 1px;
    border-radius: $border-radius-small;
    display: flex;
}
.author {
    margin-bottom: 8px;
}
.author-name {
    margin-left: 9px;
    font-size: $font-size;
    color: $select-black;
    font-weight: 500;
}
.results {
    margin-top: 10px;
}
.progress {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.processing {
    margin-left: 20px;
    font-size: $font-size;
    color: $primary-orange;
}
.view-something-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notification-success,
.notification-error,
.notification-other {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr 100px;
    margin-top: 10px;
    &-count, &-result {
        font-size: $font-size;
        font-weight: 500;
        color: $select-black;
    }
}
.notification-other {
    margin-top: 0;
}
.notification-data {
    margin-top: 10px;
}
.notification-error-count {
    color: $primary-red;
}
.notification-error-item {
    display: grid;
    grid-template-columns: 1fr 100px;
}
.view-link {
    display: flex;
    justify-content: flex-end;
}
.approval-link {
    margin-top: 13px;
}
.total-issues {
    margin-bottom: 9px;
}
</style>
