<template>
    <div :class="{ active: isActive }" class="ws-filter">
        <div class="label" @click="open">{{ label }}</div>
        <div class="selected-wrapper" @click="open">
            <div class="selected">
                {{ selected.text }}
                <IconSvg16 v-if="clearable" icon-name="close" stateless />
            </div>
        </div>
        <div class="icon-wrapper" @click="open">
            <IconSvg16
                :color="Color.solid60"
                icon-name="arrow-down"
                stateless
            />
        </div>
        <WsContextMenu
            v-if="isActive"
            :options="contextMenuOptions"
            :active-index="activeIndex"
            @close="isActive = false"
        />
    </div>
</template>

<script lang="ts">
import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { ISelectItem } from '@/types/common';
import { Color } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsContextMenu from '@/components/common/WsContextMenu.vue';

@Component({
    components: {
        WsContextMenu,
        IconSvg16,
    },
})
export default class WsFilter extends Vue {
    @Model('change') public readonly value!: boolean | number;
    @Prop({ required: true }) public label!: string | TranslateResult;
    @Prop({ required: true }) public options!: ISelectItem[];
    @Prop({ default: false }) public clearable!: boolean;

    public readonly Color = Color;
    public isActive = false;

    get selected() {
        return this.options.find((option: any) => option.value === this.value);
    }

    get activeIndex() {
        return this.options.findIndex((option: any) => option.value === this.value);
    }

    get contextMenuOptions() {
        return this.options.map((option) => {
            return {
                ...option,
                callback: (option: any) => {
                    this.change(option.value);
                },
            };
        });
    }

    @Emit()
    public change(value: any) {
        this.close();
        return value;
    }

    public open() {
        this.isActive = true;
    }

    public close() {
        this.isActive = false;
    }
}
</script>
