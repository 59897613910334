import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Dict } from '@/types/Dict';
import { DateFormatByNumber, MomentFormats } from '@/constants';
import { i18n } from '@/services/i18n';
import { dateFormatter, dateFullMonthFormatter, getMomentFormatsSettings } from '@/services/MomentFormats';
import { AbstractComment } from '@/domain/comment/models/AbstractComment';
import { DiffComment, IDiffItem } from '@/domain/comment/models/DiffComment';
import { DiffCommentObject, diffRows } from '@/domain/comment/types/comment';
import { MarkupComment } from '@/domain/comment/models/MarkupComment';
import { TextComment } from '@/domain/comment/models/TextComment';
import { FileComment } from '@/domain/comment/models/FileComment';

export const grouping = ((acc: { [key: string]: AbstractComment[] }, next: AbstractComment) => {
    const dateExist = acc[dateFormatter(next.created)];
    if (!dateExist) {
        acc[dateFormatter(next.created)] = [];
    }
    acc[dateFormatter(next.created)].push(next);
    return acc;
});

export const sortGroupByDate = (groupedObject: { [key: string]: AbstractComment[] }) => {
    return Object.entries(groupedObject).map(([date, comments]) => {
        const momentDate = moment(date, DateFormatByNumber[getMomentFormatsSettings().dateFormat]);
        return {
            date: Number(momentDate),
            comments,
        };
    }).sort((a, b) => {
        return a.date - b.date;
    });
};

export const sortByDate = (comments: AbstractComment[]) => {
    return comments.map((comment: AbstractComment) => {
        comment.dateNumber = Number(moment.utc(comment.created, MomentFormats.serverSide));
        return comment;
    }).sort((a: AbstractComment, b: AbstractComment) => {
        return a.dateNumber - b.dateNumber;
    });
};

export const groupsByDate = (allComments: AbstractComment[]) => {
    const isFileComment = (comment: any) => comment.mimetype && !comment.mimetype?.startsWith('image');
    if (allComments.length) {
        const fileComments: AbstractComment[] = allComments.filter(isFileComment);
        const collapsedIds: string[] = [];

        fileComments.forEach((comment: any, index: number) => {
            if (index > 0) {
                const prev: any = fileComments[index - 1];
                const prevIdentifier: string = `${prev.created}_${prev.author.uuid}`;
                const currentIdentifier: string = `${comment.created}_${comment.author.uuid}`;
                if (prevIdentifier === currentIdentifier) {
                    comment.files = [
                        ...(prev.files || []),
                        prev.files ? null : prev.filename,
                        comment.filename,
                    ].filter(Boolean);
                    collapsedIds.push(prev.uuid);
                }
            }
        });

        return sortGroupByDate(sortByDate([
            ...allComments.filter((comment: AbstractComment) => !isFileComment(comment)),
            ...fileComments.filter((comment: AbstractComment) => !collapsedIds.includes(comment.uuid)),
        ]).reduce(grouping, {}));
    }

    return [];
};

export const issueDateFormatter = (date: any) => {
    const dateMoment = moment(date);
    const todayMoment = moment().utc().startOf('day');
    const yesterdayMoment = moment().utc().startOf('day').subtract(1, 'd');

    if (dateMoment.isSame(todayMoment, 'd')) {
        return i18n.t('Simple_word.today');
    }
    if (dateMoment.isSame(yesterdayMoment, 'd')) {
        return i18n.t('Simple_word.yesterday');
    }
    return dateFullMonthFormatter(date);
};

export const mappedDiff = (diff: IDiffItem): Array<{ deleted: boolean; added: boolean; value: string }> => {
    const oldDiff = diff.old as string[];
    const newDiff = diff.new as string[];
    const all = [...new Set([...oldDiff, ...newDiff])];
    return all.map((item: string) => ({
        deleted: oldDiff.includes(item) && !newDiff.includes(item),
        added: !oldDiff.includes(item) && newDiff.includes(item),
        value: item,
    }));
};

export const createDiffCommentObject = ({ diff, currentUserEmail, time }: any) => {

    const diffUuid = uuidv4();
    const diffObject: Dict<DiffCommentObject> = {};

    diffObject[diffUuid] = {
        [diffRows.type]: 'diff',
        [diffRows.uuid]: diffUuid,
        [diffRows.reporter]: currentUserEmail,
        [diffRows.created]: time || moment().utc().format(MomentFormats.serverSide),
        [diffRows.diff]: diff,
    };

    return diffObject;
};

export const makeComment = (comment: any = {}) => {
    if (comment.type === 'diff') {
        return new DiffComment(comment);
    } else if (comment.type === 'markup') {
        return new MarkupComment(comment);
    } else if (comment.type === 'text') {
        return new TextComment(comment);
    } else if (comment.type === 'file') {
        return new FileComment(comment);
    }
    throw new Error(`Unknown comment type: [${comment.type}]`);
};
