import { render, staticRenderFns } from "./WsMultiSelectItem.vue?vue&type=template&id=ab64ee38&scoped=true"
import script from "./WsMultiSelectItem.vue?vue&type=script&lang=ts"
export * from "./WsMultiSelectItem.vue?vue&type=script&lang=ts"
import style0 from "./WsMultiSelectItem.vue?vue&type=style&index=0&id=ab64ee38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab64ee38",
  null
  
)

export default component.exports