export class IssueStamp {
    public readonly uuid!: string;
    public readonly title: string;
    public readonly fields: any;
    public readonly parentUuid: string;
    public readonly nodeRole: number;
    public readonly created: number;
    public readonly updated: number;

    constructor(stamp: any = {}) {
        this.uuid = stamp.uuid;
        this.title = stamp.title;
        this.parentUuid = stamp.parentUuid;
        this.nodeRole = stamp.nodeRole;
        this.created = stamp.created;
        this.updated = stamp.updated;
        this.fields = (typeof stamp.fields === 'string') ? JSON.parse(stamp.fields) : stamp.fields;

    }

    get isDirectory(): boolean {
        return this.parentUuid === null;
    }

}
