<template>
    <v-combobox
        v-model="model"
        :class="{
            'w-label': !isNew && label,
            'ws-combobox': isNew,
        }"
        :id="uuid"
        :outlined="outlined"
        :dense="dense"
        :error="error"
        :required="required"
        :clearable="clearable"
        :disabled="disabled"
        :hide-details="hideDetails"
        :label="label"
        :placeholder="placeholder"
        :messages="messages"
        :error-messages="errorMessages"
        :success-messages="successMessages"
        :rules="rules"
        :items="items"
        :attach="attach"
        :hide-selected="hideSelected"
        :auto-select-first="autoSelectFirst"
        :open-on-clear="openOnClear"
        :search-input.sync="search"
        :multiple="multiple"
        :append-icon="icon"
        :no-data-text="noDataText"
        :hide-no-data="hideNoData"
        :autofocus="autofocus"
        :item-text="itemText"
        ref="combobox"
        @change="change"
        @update:search-input="updateSearchInput"
        @focusout="focusout"
        @focus="onFocus"
        @click:clear="clear"
    >
        <template v-if="isLimit" #append-outer>
            <div class="text-limit">
                <b>{{ valueLength }}</b>/{{ maxlength }}
            </div>
        </template>

        <template v-if="$scopedSlots.selection" #selection="{ item }">
            <slot :item="item" name="selection" :class="{ 'amp-block': ampMask }" />
        </template>

        <template v-else #selection="{ attrs, selected, select, item }">
            <v-chip
                v-if="multiple"
                v-bind="attrs"
                :input-value="selected"
                :class="{
                    disabled,
                    ...itemClassesFormatter(item),
                }"
                close-icon="mdi-close"
                close
                outlined
                label
                small
                @click="select"
                @click:close="remove(item)"
            >
                <span
                    class="text"
                    :class="{ 'amp-mask': ampMask }"
                    :style="{ color: colorFormatter(item) }"
                >
                    {{ selectFormatter(item) }}
                </span>
            </v-chip>
            <div v-else-if="!multiple" :class="{ 'amp-mask': ampMask }" class="ws-combobox-selection">
                {{ selectFormatter(item) }}
            </div>
        </template>

        <template v-if="isShowAdditionalCreateNewSlot" #prepend-item>
            <v-list-item
                v-if="allowCreate && !prohibitToCreateNew && trimmedSearch"
                @click="add"
            >
                <div class="create-text-wrapper" :class="{ 'amp-mask': ampMask }">
                    <span class="new-value">{{ trimmedSearch }}</span>
                    <span class="create-text">{{ creationText }} </span>
                </div>
            </v-list-item>
        </template>

        <template #no-data>
            <template v-if="allowCreate && !prohibitToCreateNew">
                <template v-if="!showAdditionalCreateNew">
                    <v-list-item v-if="isRestrictedNewItem && search">
                        {{ restrictionText }}
                    </v-list-item>
                    <v-list-item
                        v-else-if="trimmedSearch"
                        @click="add"
                    >
                        <div class="create-text-wrapper">
                            <span class="new-value">{{ trimmedSearch }}</span>
                            <span class="create-text">{{ creationText }} </span>
                        </div>
                    </v-list-item>
                </template>
                <div v-else-if="isShowCreateNewPrependItem" class="invisible" />
            </template>

            <template v-else>
                <v-list-item v-if="isRestrictedNewItem">
                    {{ $t('Collocation.noDataAvailable') }}
                </v-list-item>
                <v-list-item v-else>
                    {{ prohibitToCreateNew ? restrictionText : noDataText }}
                </v-list-item>
            </template>
        </template>

        <template v-if="required" #label>
            <span class="required-star">*</span>{{ label }}
        </template>

        <template v-else-if="$scopedSlots.item" #item="{ item }">
            <div :class="{ 'amp-mask': ampMask }" class="ws-combobox-item-amp-mask">
                <slot :item="item" name="item" />
            </div>
        </template>

        <template v-else-if="ampMask" #item="{ item }">
            <span class="amp-mask">
                {{ itemFormatter(item) }}
            </span>
        </template>
    </v-combobox>
</template>

<script lang="ts">
import _ from 'lodash';
import { TranslateResult } from 'vue-i18n';
import { v4 as uuid } from 'uuid';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { COMBO_BOX_MIN_ITEMS_IN_LIST } from '@/constants';
import { i18n } from '@/services';
import EventListenersBase from '@/components/common/EventListenersBase.vue';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-combobox/#props

const defaultFormatter = (item: any) => item;
const defaultItemClassesFormatter = (_item: any) => ({});

@Component
export default class WsCombobox extends Mixins(VuetifyElement, EventListenersBase) {
    @Prop({ required: true, default: () => [] }) public items!: any[];
    @Prop({ required: false, default: () => [] }) public allItems!: any[];
    @Prop({ default: () => [] }) public restrictedItems!: any[];
    @Prop({ default: () => i18n.t('Collocation.alreadyExist') }) public restrictionText!: TranslateResult;
    @Prop({ default: () => i18n.t('TagEditor.createNew') }) public creationText!: TranslateResult;
    @Prop({ default: () => i18n.t('Collocation.noDataAvailable') }) public noDataText!: TranslateResult;
    @Prop({ type: Boolean, default: true }) public hideSelected!: boolean;
    @Prop({ type: Boolean, default: true }) public autoSelectFirst!: boolean;
    @Prop({ type: Boolean, default: true }) public allowCreate!: boolean;
    @Prop({ type: Boolean, default: false }) public multiple!: boolean;
    @Prop({ type: Boolean, default: false }) public openOnClear!: boolean;
    @Prop({ type: Boolean, default: false }) public clearOnClick!: boolean;
    @Prop({ type: Boolean, default: false }) public autofocus!: boolean;
    @Prop({ default: () => defaultFormatter }) public selectFormatter!: (item: any) => any;
    @Prop({ default: () => defaultFormatter }) public colorFormatter!: (item: any) => any;
    @Prop({ type: Boolean, default: false }) public small!: boolean;
    @Prop() public appendIcon!: string;
    @Prop() public attach!: string;
    @Prop({ default: () => defaultItemClassesFormatter }) public itemClassesFormatter!: (item: any) => Dict;
    @Prop({ type: Boolean, default: false }) public new!: boolean;
    @Prop({ type: String, default: '' }) public valueKey!: string;
    @Prop({ type: Boolean, default: false }) public isLimit!: boolean;
    @Prop({ type: Number, default: 16 }) public maxlength!: number;
    @Prop({ type: Boolean, default: false }) public showAdditionalCreateNew!: boolean;
    @Prop({ type: String }) public itemText!: string;
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;
    @Prop({ type: Boolean, default: false }) public hideNoData!: boolean;

    public search = '';
    public uuid = uuid();
    public hideLimit = false;
    public prohibitToCreateNew = false;

    get valueLength() {
        return this.search?.length || (_.isString(this.value) && this.value.length) || 0;
    }

    get isNew() {
        return this.new;
    }

    get icon() {
        if (this.appendIcon) {
            return this.appendIcon;
        }
        
        return 'mdi-menu-down';
    }

    get model() {
        if (this.multiple && !Array.isArray(this.value)) {
            return [this.value];
        }

        return this.value;
    }
    set model(value: any) {
        if (value) {
            setTimeout(() => {
                this.hideLimit = true;
                this.prohibitToCreateNew = false;
            });
        }

        if (this.valueKey && value && value[this.valueKey] !== undefined) {
            value = value[this.valueKey];
        }

        if (_.isNil(value)) {
            value = this.multiple ? [] : '';
        }

        this.search = '';

        if (this.multiple) {
            const primitiveValues = value.map((item: string | number | { text: string; value: string; }) => {
                return typeof item === 'string' || typeof item === 'number' ? item : item.value;
            });

            if (!this.allowCreate) {
                const possibleValues = this.items.map((item) => item.value ?? item);
                const withoutCreatedValues = primitiveValues.filter((item: string) => possibleValues.includes(item));
                this.input(withoutCreatedValues);

                return;
            }
            this.input(primitiveValues);

            return;
        }

        this.input(value);
    }
    get isRestrictedNewItem() {
        if (!this.search?.length) {
            return false;
        }
        const modelArray = this.multiple ? this.model : [this.model];
        return [...modelArray, ...this.restrictedItems].includes(this.search);
    }
    get trimmedSearch(): string {
        return this.search ? this.search?.trim() : this.search;
    }

    get isShowCreateNewPrependItem() {
        return Boolean(this.allowCreate && !this.prohibitToCreateNew && this.trimmedSearch);
    }

    get isShowAdditionalCreateNewSlot() {
        if (!this.showAdditionalCreateNew) {
            return false;
        }

        if (this.$scopedSlots.prependItem) {
            return true;
        }

        return this.isShowCreateNewPrependItem;
    }

    @Watch('search')
    public onSearch(value: string) {
        if (value) {
            this.hideLimit = false;
            if (value && this.allItems.length) {
                this.prohibitToCreateNew = this.allItems.some((item: any) => item.text === value);
            }
        }
        if (this.isLimit && value?.length >= this.maxlength) {
            this.search = this.search?.substr(0, this.maxlength);
        }
    }

    @Emit('update:search-input')
    public updateSearchInput(value: string) {
        return value;
    }

    @Emit()
    public change(value: any) {
        this.changeMaxItemsInList(value);
        return value;
    }

    @Emit()
    public clear() {
        return;
    }

    @Emit()
    public focusout() {
        return;
    }

    @Emit()
    public focus() {
        return;
    }

    @Emit()
    public input(value: any) {
        return value;
    }

    public mounted() {
        this.changeMaxItemsInList(this.model);

        const comboboxEl = document.getElementById(this.uuid);

        if (!comboboxEl) {
            return;
        }

        if (this.isLimit) {
            comboboxEl.setAttribute('maxLength', String(this.maxlength));
        }
    }

    public itemFormatter(item: any) {
        if (_.isString(item)) {
            return item;
        }
        return item[this.itemText ?? 'text'];
    }

    public onFocus() {
        this.focus();

        if (this.clearOnClick) {
            this.setEmpty();
        }
    }

    public setEmpty() {
        this.model = this.multiple ? [] : '';
    }

    public add() {
        if (!this.trimmedSearch) {
            return;
        }
        const newModel = this.multiple ? [...this.model, this.trimmedSearch] : this.trimmedSearch;
        this.model = newModel;

        // When adding new value in single combobox we call blur function, which fires change by itself
        // But if we adding new value in multiple combobox, we doesn't call blur, so we have to call change function.
        if (this.multiple) {
            this.change(newModel);
        } else {
            // @ts-ignore
            this.$refs.combobox.blur();
        }
    }
    public remove(itemToDelete: string) {
        if (this.disabled) { return; }
        const newModel = this.model.filter((item: string) => item !== itemToDelete);
        this.model = newModel;
        this.change(newModel);
    }
    /**
     * When you click on the V-Combobox, a V-Menu opens with a choice of items,
     * but if more than 20 items are selected, the list will contain only one option, even if there are more.
     * This checks how many items are selected and increases the limit of displayed options.
     *
     * See VCombobox.ts and property lastItem for more details.
     */
    public changeMaxItemsInList(selectedValue: any) {
        if (
            Boolean(this.$refs.combobox) &&
            Array.isArray(selectedValue) &&
            ((this.$refs.combobox as any).lastItem - selectedValue.length) < COMBO_BOX_MIN_ITEMS_IN_LIST
        ) {
            (this.$refs.combobox as any).lastItem += COMBO_BOX_MIN_ITEMS_IN_LIST;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-text-field--outlined.v-input--dense.v-text-field--outlined {
    ::v-deep {
        > .v-input__control > .v-input__slot {
            min-height: 36px;
        }
    }
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense {
    ::v-deep {
        .v-select__selections {
            gap: 4px;
            padding: 4px 0;
        }
        .v-chip {
            margin: 0;
        }
        input {
            max-height: 24px;
        }
    }
    &.w-label ::v-deep {
        .v-select__selections {
            padding-top: 8px;
        }
    }
}

.v-input {
    ::v-deep .v-messages__message {
        line-height: 1.4;
    }
}

::v-deep .v-chip.v-chip--outlined.v-chip.v-chip {
    background-color: $light !important;

    &.disabled {
        opacity: 0.5;
    }

    .v-chip__close {
        font-size: 14px !important;
    }

    .text {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ws-combobox-selection {
    text-overflow: ellipsis;
    overflow: hidden;
}

::v-deep .v-input__append-outer {
    position: absolute;
    margin: 0 !important;
    top: -17px;
    right: 0;
    
    .text-limit {
        font-size: 12px;
        font-weight: 700;
        color: $light-solid-50;

        b {
            color: $light-solid-100;
        }
    }
}

::v-deep {
    .invisible {
        height: 0;
    }
}

::v-deep .new-value {
    word-break: break-word;
}

::v-deep .create-text-wrapper {
    gap: 24px;
    flex-wrap: nowrap;
    align-items: flex-start;
}

::v-deep .create-text {
    flex-shrink: 0;
    padding-top: 5px;
}

::v-deep .v-list-item .ws-combobox-item-amp-mask {
    display: block;
    width: 100%;
}
</style>

