import { render, staticRenderFns } from "./WsCheckbox2.vue?vue&type=template&id=332da36e&scoped=true"
import script from "./WsCheckbox2.vue?vue&type=script&lang=ts"
export * from "./WsCheckbox2.vue?vue&type=script&lang=ts"
import style0 from "./WsCheckbox2.vue?vue&type=style&index=0&id=332da36e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "332da36e",
  null
  
)

export default component.exports