<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

// deprecated

@Component
export default class BaseIcon extends Vue {
    @Prop({ type: Boolean, default: false }) public suspend!: boolean;
    @Prop({ type: Boolean, default: false }) public disable!: boolean;
    @Prop({ type: String, default: '#616161' }) public fill!: string;

    @Emit()
    public click() {
        return;
    }

    public handleClick() {
        if (!this.disable) {
            this.click();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.svg-icon {
    cursor: pointer;

    path {
        fill: $default-black;

        &.suspend, &.disable {
            fill: $disable-gray;
        }
    }

    &:hover {
        path {
            fill: $primary-blue;

            &.disable {
                fill: $disable-gray;
            }
        }
    }
}
</style>
