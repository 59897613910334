<template>
    <div class="project-workflows-action-bar actionbar">
        <template v-for="(item, index) in items">
            <div
                v-if="!item.disabled"
                :key="index"
                class="actionbar-item"
                @click="item.callback"
            >
                <IconSvg24 :icon-name="item.icon" stateless />
                <span>{{ item.text }}</span>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IActionBarItem } from '@/types/ui';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
  components: {
    IconSvg24,
  },
})
export default class ActionBar extends Vue {
  @Prop({ default: () => [] }) public items!: IActionBarItem[];
}
</script>

<style scoped lang="scss">
@import '@/styles/wsui/colors.scss';
@import '@/styles/wsui/const.scss';
@import '@/styles/variables.scss';
@import '@/styles/tables.scss';
@import '@/styles/mixins.scss';

.project-workflows-action-bar {
    background: white;
    margin: 24px 0 0 0;
}

.actionbar {
  height: 32px;
  display: flex;
  padding: 0 16px 6px 0;
}

::v-deep {
    .actionbar-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        margin: 0 20px 0 0;
        padding: 0 6px 0 6px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background: $color-solid-5;
        }

        &:active {
            background: $color-accent-20;

            span {
                color: $color-accent-100;
            }

            svg {
                color: $color-accent-100 !important;
            }
        }

        span {
            color: $color-solid-80;
            font-size: $ws-font-size;
        }
    }
}
</style>
