<template>
    <div
        :class="['ws-badge', {
            'ws-badge_rounded': rounded,
        }]"
        :style="[{
            'background-color': background,
            'color': color
        }]"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants/Color';

// https://www.figma.com/design/MsZu8VFHobdy7yxGpZml9s/Atoms---DS---Revizto?node-id=1400-18272&t=hMz57H4gZugd7IMm-0
@Component
export default class WsBadge extends Vue {
    @Prop({ default: Color.solid60 }) readonly color!: string;
    @Prop({ default: Color.solid5 }) readonly background!: string;
    @Prop({ type: Boolean, default: false }) readonly rounded!: string;
}
</script>

<style scoped lang="scss">
.ws-badge {
    display: inline-block;
    padding: 0 6px;
    border-radius: 4px;

    &_rounded {
        border-radius: 16px;
    }
}
</style>
