<template>
    <v-menu
        v-model="isOpenPopover"
        :close-on-content-click="closeOnContentClick"
        :content-class="autoWidth ? 'auto-width' : ''"
        offset-y
    >
        <template #activator="{ on, attrs }">
            <div @click="edit">
                <IconSvg16
                    :tooltip="$t('Simple_word.edit')"
                    icon-name="edit"
                    stateless
                />
            </div>
        </template>

        <v-card
            :class="{
                searchable,
                'recent-items-block': recentUsedItems.length && !search,
                'after-search-block': !!$scopedSlots.afterSearch,
            }"
        >
            <div v-if="searchable" class="search-box">
                <slot name="search">
                    <WsInputSearch
                        v-model="search"
                        small
                        @input="onSearchInput"
                    />
                </slot>
            </div>

            <slot name="afterSearch" :select="(value) => selectItem(value)" />

            <v-list v-if="recentUsedItems.length && !search" class="recent-used-items" dense>
                <v-list-item class="recent-used-items-title">{{ $t('Simple_word.recent') }}</v-list-item>
                <v-list-item
                    v-for="item in recentUsedItems"
                    :key="item.key || item.value"
                    :class="{ active: isActive(item.value) }"
                    @click="selectRecentItem(item.value)"
                >
                    <v-list-item-action v-if="multiselect">
                        <v-checkbox
                            :input-value="isActive(item.value)"
                            color="primary"
                        />
                    </v-list-item-action>
                    <v-list-item-content class="list-item-content">
                        <slot
                            :fields="item.fields"
                            :item="item"
                            :text="item.text"
                            :value="item.value"
                            name="item"
                        >
                            <v-list-item-title class="amp-mask">{{ item.text }}</v-list-item-title>
                        </slot>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list ref="itemsList" dense>
                <template v-if="multiselect">
                    <v-list-item @click="toggleAll">
                        <v-list-item-action v-if="multiselect">
                            <v-checkbox
                                :input-value="allSelected"
                                :indeterminate="filteredItems.length !== localModel.length && Boolean(localModel.length)"
                            />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title><span class="select-all">{{ $t('Text.selectAll') }}</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                </template>

                <v-list-item
                    v-for="item in visibleItems"
                    :key="item.key || item.value"
                    :class="{ active: isActive(item.value) }"
                    @click="selectItem(item.value)"
                >
                    <v-list-item-action v-if="multiselect">
                        <v-checkbox
                            :input-value="isActive(item.value)"
                            color="primary"
                        />
                    </v-list-item-action>
                    <v-list-item-content class="list-item-content">
                        <slot
                            :fields="item.fields"
                            :item="item"
                            :text="item.text"
                            :value="item.value"
                            name="item"
                        >
                            <v-list-item-title class="amp-mask">{{ item.text }}</v-list-item-title>
                        </slot>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <div v-if="shouldConfirm" class="actions-box">
                <WsButton
                    size="xsmall"
                    type="primary"
                    contained
                    @click="confirm"
                >
                    {{ $t('Button.save') }}
                </WsButton>
                <WsButton
                    v-if="isReset"
                    size="xsmall"
                    @click="setEmpty"
                >
                    {{ $t('Button.reset') }}
                </WsButton>
                <WsButton size="xsmall" @click="reset">{{ $t('Button.cancel') }}</WsButton>
            </div>
            <div v-if="isReset" class="reset">
                <WsButton @click="setEmpty">{{ $t('Button.reset') }}</WsButton>
            </div>
        </v-card>
    </v-menu>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsInputSearch from '@/components/common/WsInputSearch.vue';
import WsButton from '@/components/common/WsButton.vue';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

export interface ListItem {
    text: string;
    value: any;
    key?: string | number;
    fields?: object;
    search?: string;
}

const DefaultMaxVisibleItems = 20;

@Component({
    components: {
        IconSvg16,
        WsInputSearch,
        WsButton,
    },
})
export default class IssueDetailsEditPopover extends EventListenersBase {
    @Prop({ required: true }) public items!: Array<ListItem | string>;
    @Prop({ default: () => [] }) public recentUsedItems!: Array<ListItem | string>;
    @Prop({ default: '' }) public initialValue!: string[] | string;
    @Prop({ default: 'value' }) public searchField!: string;
    @Prop({ type: Boolean, default: false }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public confirmation!: boolean;
    @Prop({ type: Boolean, default: false }) public multiselect!: boolean;
    @Prop({ type: Boolean, default: false }) public autoWidth!: boolean;
    @Prop({ type: Boolean, default: true }) public isReset!: boolean;

    public isOpenPopover: boolean = false;

    public search: string = '';
    public localModel: string[] = [];
    public countOfVisibleItems: number = DefaultMaxVisibleItems;
    public handleItemsListScroll: any;

    get shouldConfirm() {
        return [this.multiselect, this.confirmation].includes(true);
    }

    get closeOnContentClick() {
        return ![this.searchable, this.shouldConfirm].includes(true);
    }

    get normalizedItems(): ListItem[] {
        return _.uniqBy(this.items.map((item: ListItem | string) => {
            if (typeof item === 'string') {
                return {
                    text: item,
                    value: item,
                };
            }

            return item;
        }), this.searchField);
    }

    get filteredItems(): ListItem[] {
        function searchIn(where: any | any[], what: string) {
            const searchString = _.isArray(where) ? where.join(' ') : where;
            const lowerCaseWhat = what.toLowerCase();

            return String(searchString).toLowerCase().includes(lowerCaseWhat);
        }

        return this.normalizedItems
            .filter(({ value, text, search }) => searchIn([value, text, search], this.search));
    }

    get visibleItems(): ListItem[] {
        return this.filteredItems.slice(0, this.countOfVisibleItems);
    }

    get allSelected() {
        return this.filteredItems.length === this.localModel.length;
    }

    @Emit()
    public change(value: string | string[]) {
        return value;
    }

    public created() {
        this.setInitial();

        this.handleItemsListScroll = _.debounce((e: Event) => {
            if (!e.target) {
                return;
            }

            const el = e.target as HTMLElement;

            if (el.scrollTop + el.clientHeight === el.scrollHeight) {
                this.countOfVisibleItems += DefaultMaxVisibleItems;
            }
        }, 100);
    }

    public edit() {
        dispatchEvent(new CustomEvent('closeEditSlots'));
        this.countOfVisibleItems = DefaultMaxVisibleItems;
        this.setInitial();
        this.isOpenPopover = true;

        this.$nextTick(() => {
            this.addScrollListener();
        });
    }

    public selectItem(value: string) {
        if (!this.shouldConfirm) {
            if (!this.isActive(value)) {
                this.change(value);
            }
            this.close();
        }

        if (this.multiselect) {
            this.toggleValue(value);
        }
    }

    public selectRecentItem(value: string) {
        amplitudeLog(AmplitudeEvent.itUsedRecentUsers, { assigneeToMe: false });
        this.selectItem(value);
    }

    public confirm() {
        const isEqual = _.isEqual(this.localModel, this.initialValue);
        if (!isEqual) {
            this.change(this.localModel);
        }
        this.close();
    }

    public close() {
        this.isOpenPopover = false;
    }

    public reset() {
        this.setInitial();
        this.close();
    }

    public toggleValue(value: string) {
        const index = this.localModel.indexOf(value);

        if (index > -1) {
            this.localModel.splice(index, 1);
        } else {
            this.localModel.push(value);
        }
    }

    public isActive(item: string) {
        return this.localModel.includes(item);
    }

    public setInitial() {
        this.localModel = _.castArray(this.initialValue).slice();
    }

    public toggleAll() {
        if (this.allSelected) {
            this.localModel = [];
        } else {
            this.localModel = this.filteredItems.map(({ value }) => value);
        }
    }

    public addScrollListener() {
        const itemsList = (this.$refs.itemsList as Vue | undefined)?.$el as HTMLElement | undefined;

        if (!itemsList) {
            return;
        }

        this.eventListeners.add({ node: itemsList, event: 'scroll', handler: this.handleItemsListScroll });
    }

    public onSearchInput() {
        this.countOfVisibleItems = DefaultMaxVisibleItems;
    }

    public setEmpty() {
        this.change('');
        this.close();
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.search-box {
    padding: 21px 16px 16px;
    border-bottom: $border;
}

.actions-box {
    padding: 10px;
    border-top: $border;
    text-align: right;
}

.list-item-content {
    max-width: 250px;
}

.v-menu__content:not(.auto-width) .list-item-content {
    min-width: 200px;
}

.v-text-field--outlined {
    &.v-input--dense {
        &.small {
            ::v-deep > .v-input__control > .v-input__slot {
                min-height: 34px;

                .v-input__prepend-inner, .v-input__append-inner {
                    margin-top: 5px;
                }
            }
        }
    }
}

.v-card {
    max-height: 50vh;
    display: grid;
    grid-template-rows: auto 1fr;
    @include scrollbar;

    &.searchable:not(.recent-items-block):not(.after-search-block) {
        grid-template-rows: auto 1fr;
    }
    &.searchable.after-search-block:not(.recent-items-block) {
        grid-template-rows: auto 1fr auto;
    }
    &.searchable.after-search-block.recent-items-block {
        grid-template-rows: auto 1fr min-content auto;
    }
    &.searchable.recent-items-block:not(.after-search-block) {
        grid-template-rows: auto min-content 1fr;
    }
    &.after-search-block:not(.recent-items-block) {
        grid-template-rows: auto 1fr;
    }
    &.after-search-block.recent-items-block {
        grid-template-rows: 1fr min-content auto;
    }
    &.recent-items-block:not(.after-search-block) {
        grid-template-rows: min-content 1fr;
    }

    .v-list {
        width: max-content;
    }
}

.v-list {
    @include scrollbar;
}

.v-list-item {
    &.active {
        background: $primary-light-blue;

        &:hover {
            background: $primary-light-blue-hover;
        }
    }
}

.select-all {
    font-size: 13px;
    line-height: $line-height-small;
    font-weight: 500;
}

.reset {
    border-top: solid 1px $divider-gray;
    padding: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;
}

.v-card .recent-used-items {
    border-bottom: $border;
}
.v-card .recent-used-items-title {
    font-size: $font-size-medium;
    font-weight: $h6-font-weight;
}
</style>
