import moment, { Moment } from 'moment';
import { DateFormatByNumber, GetTimeFormat, MomentFormats } from '@/constants';

interface IMomentFormat {
    date?: number | string | Moment;
    format: string;
    fromUTC?: boolean;
}

const Settings = {
    dateFormat: 1,
    timeFormat: 1,
};

export function setDateFormatterSettings(
    dateFormat: number = 1,
    timeFormat: number = 1,
) {
    Settings.dateFormat = dateFormat;
    Settings.timeFormat = timeFormat;
}

export function dateFormatter(
    date: number | string | Moment,
    fromUTC = true,
): string {
    const format = getMomentDateFormat({ dateFormatIndex: Settings.dateFormat });
    return momentFormat({ format, date, fromUTC });
}

export function timeFormatter(date: number | string | Moment, fromUTC = true): string {
    const format = getMomentTimeFormat({ timeFormatIndex: Settings.timeFormat });
    return momentFormat({ format, date, fromUTC });
}

export function dateTimeFormatter(
        date: number | string | Moment,
        fromUTC: boolean = false,
        dateFormatIndex: number = Settings.dateFormat,
        timeFormatIndex: number = Settings.timeFormat,
    ): string {
    const format = getMomentDateTimeFormat({ dateFormatIndex, timeFormatIndex });
    return momentFormat({ format, date, fromUTC });
}

export function dateFullMonthFormatter(date?: number | string | Moment, fromUTC = true): string {
    return momentFormat({ format: MomentFormats.fullMonth, date, fromUTC });
}

export function getMomentDateTimeFormat({ dateFormatIndex, timeFormatIndex }: { dateFormatIndex: number, timeFormatIndex: number }): string {
    const momentDateFormat = getMomentDateFormat({ dateFormatIndex });
    const momentTimeFormat = getMomentTimeFormat({ timeFormatIndex });
    return `${momentDateFormat}, ${momentTimeFormat}`;
}

export function getMomentFormatsSettings() {
    return {
        ...Settings,
    };
}

export function momentFormat({ date, format, fromUTC }: IMomentFormat) {
    if (date && fromUTC) {
        return moment.utc(date).local().format(format);
    }

    if (Number.isInteger(date)) {
        return moment(date).format(format);
    }

    if (date) {
        return moment(date, MomentFormats.serverSide).format(format);
    }

    return '-';
}

function getMomentDateFormat({ dateFormatIndex }: { dateFormatIndex: number }): string {
    return DateFormatByNumber[dateFormatIndex] || DateFormatByNumber[1];
}

function getMomentTimeFormat({ timeFormatIndex }: { timeFormatIndex: number }): string {
    const format = GetTimeFormat[timeFormatIndex];
    switch (format) {
        case '12h':
            return 'h:mm A';
        case '24h':
            return 'H:mm';
        default:
            return 'H:mm';
    }
}
