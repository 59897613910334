<template>
    <div>
        <div v-if="addedArray.length" class="items-row highlighting">
            {{ $t('IssueTracker.diffComments.added') }}
            <v-chip
                v-for="{ deleted, added, value } in addedArray"
                :key="value"
                :class="{ deleted, added }"
                small
            >
                <WsMemberName v-if="entity === 'watchers'" :email="value" tooltip />
                <span v-else class="highlighting">
                    <WsTooltip :tooltip="value" :activator="truncateString(value, 15)" block />
                </span>
            </v-chip>
        </div>
        <div v-if="deletedArray.length" class="items-row highlighting">
            {{ $t('IssueTracker.diffComments.deleted') }}
            <v-chip
                v-for="{ deleted, added, value } in deletedArray"
                :key="value"
                :class="{ deleted, added }"
                small
            >
                <WsMemberName v-if="entity === 'watchers'" :email="value" tooltip />
                <span v-else class="highlighting">
                    <WsTooltip :tooltip="value" :activator="truncateString(value, 15)" block />
                </span>
            </v-chip>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { truncateString } from '@/services';
import WsMemberName from '@/components/common/WsMemberName.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import { IDiffItem } from '@/domain/comment/models/DiffComment';
import { IssueTrackerDiffMultilinePropertiesType } from '@/domain/comment/models/IssueTrackerDiffProperties';
import { mappedDiff } from '@/domain/comment/services/comments';

@Component({
    components: {
        WsTooltip,
        WsMemberName,
    },
})
export default class IssueCommentDiffMultipleItems extends Vue {
    @Prop({ required: true }) public diff!: IDiffItem;
    @Prop({ required: true }) public entity!: IssueTrackerDiffMultilinePropertiesType;

    public readonly truncateString = truncateString;

    get mappedDiff(): Array<{ deleted: boolean; added: boolean; value: string }> {
        return mappedDiff(this.diff);
    }

    get addedArray() {
        return this.mappedDiff.filter(({ added }) => added);
    }

    get deletedArray() {
        return this.mappedDiff.filter(({ deleted }) => deleted);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.deleted {
    color: $primary-red;
}

.added {
    color: $primary-green;
}

.items-row:not(:last-child) {
    margin-bottom: 5px;
}

::v-deep .v-chip.v-size--small {
    margin-left: 5px;
    border-radius: $border-radius-default;
    padding: 0 8px;
    height: 18px;

    &.deleted {
        text-decoration: line-through;
    }
}
</style>
