import Vue from 'vue';
import { ProjectCategory } from '@/constants';
import { License, Project } from '@/models';
import ProjectApi from '../api/project.api';

interface IMyProjectsStorage {
    searchQuery: string;
    licenseMyProjectsObj: any;
    isLoadingMyProjects: boolean;
    isLoadedProjectsWithNotifications: boolean;
}

export default {
    state: {
        licenseMyProjectsObj: {},
        searchQuery: '',
        loadProjectPromise: {},
        isLoadingMyProjects: false,
        isLoadedProjectsWithNotifications: false,
    },
    getters: {
        myProjectSearchQuery(state: IMyProjectsStorage): string {
            return state.searchQuery;
        },
        myProjects(state: IMyProjectsStorage): (license: License) => Project[] {
            return (license) => state.licenseMyProjectsObj[license.id] || [];
        },
        myProjectsOperational(state: IMyProjectsStorage, getters: any): (license: License) => Project[] {
            return (license) => getters.myProjects(license).filter((project: Project) => !project.archived && !project.frozen);
        },
        myProjectsSuspended(state: IMyProjectsStorage, getters: any): (license: License) => Project[] {
            return (license) => getters.myProjects(license).filter((project: Project) => project.frozen);
        },
        myProjectsArchived(state: IMyProjectsStorage, getters: any): (license: License) => Project[] {
            return (license) => getters.myProjects(license).filter((project: Project) => project.archived);
        },
        isLoadingMyProjects(state: IMyProjectsStorage): boolean {
            return state.isLoadingMyProjects;
        },
    },
    mutations: {
        setMyProjectSearchQuery(state: IMyProjectsStorage, searchQuery: string) {
            state.searchQuery = searchQuery;
        },
        setMyProjectsList(state: IMyProjectsStorage, { license, projects }: { license: License; projects: Project[] }) {
            Vue.set(state.licenseMyProjectsObj, license.id, projects);
        },
        setIsLoadingMyProjects(state: IMyProjectsStorage, value: boolean) {
            state.isLoadingMyProjects = value;
        },
    },
    actions: {
        loadLicenseMyProjects(
            { state, commit }: any,
            { license, isForce, isOnlyActive = false, withNotifications = true, withIssueCounters = false }: any,
        ): Promise<Project[]> {
            if (!license) {
                throw new Error('Try upload projects without license');
            }

            if (!state.loadProjectPromise[license.id] || isForce) {
                commit('setIsLoadingMyProjects', true);
                state.loadProjectPromise[license.id] = new Promise((resolve, reject) => {
                    let currentProjectCategory: any = ProjectCategory;
                    if (isOnlyActive) {
                        currentProjectCategory = { my: ProjectCategory.my, shared: ProjectCategory.shared };
                    }
                    const requests = Object.keys(currentProjectCategory).map((projectCategory) => {
                        return loadProjectsByCategory(license.uuid, projectCategory, withNotifications, withIssueCounters);
                    });

                    Promise.all(requests).then((responses) => {
                        const projects = [].concat.apply([], responses);
                        commit('setMyProjectsList', { license, projects });
                        resolve(projects);
                    }).catch((error) => {
                        state.loadProjectPromise[license.id] = null;
                        reject(error);
                    }).finally(() => {
                        commit('setIsLoadingMyProjects', false);
                    });
                });
            }

            return state.loadProjectPromise[license.id];

            function loadProjectsByCategory(licenseUuid: string, category: string, notifications: boolean, issueCounters: boolean): Promise<any> {
                return new Promise((resolve, reject) => {
                    ProjectApi.getProjects({ licenseUuid, category, notifications, issueCounters }).then((response: any) => {
                        if (!response) {
                            response = { data: [], pages: 1 };
                        }
                        let projects = response.data;

                        const requests: Promise<any>[] = [];
                        for (let page = 1; page < response.pages; page++) {
                            requests.push(ProjectApi.getProjects({ licenseUuid, category, page, notifications, issueCounters }));
                        }

                        Promise.all(requests).then((responses) => {
                            responses.forEach((responseItem) => {
                                if (!responseItem?.data) {
                                    return;
                                }

                                projects = projects.concat(responseItem.data);
                            });
                            projects = projects.map((project: any) => new Project(project));
                            resolve(projects);
                        }).catch((error) => {
                            reject(error);
                        });
                    }).catch((error) => {
                        reject(error);
                    });
                });
            }
        },
    },
};
