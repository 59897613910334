<template>
    <TransitionGroup
        :class="`notifier-${position}`"
        class="notifier"
        name="notifier-item"
        tag="div"
    >
        <component
            v-for="item in items"
            :is="getComponent(item.view)"
            :key="item.id"
            v-bind="item"
            :style="{
                width: getWidth(item.view)
            }"
            class="notifier-item"
            role="alert"
            @close="close(item.id)"
        />
    </TransitionGroup>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NotifierPosition } from '@/constants/Notifier/NotifierPosition';
import { NotifierView } from '@/constants/Notifier/NotifierView';
import { NOTIFIER_DURATION_DEFAULT } from '@/constants/Notifier/NotifierDurationDefault';
import { BusEvent } from '@/constants';
import { eventBus } from '@/services/eventBus';
import WsNotification from '@/components/common/Notifier/WsNotification.vue';
import WsToast from '@/components/common/Notifier/WsToast.vue';

const notifierComponent = {
    [NotifierView.NOTIFICATION]: WsNotification,
    [NotifierView.TOAST]: WsToast,
};

const notifierWidth = {
    [NotifierView.NOTIFICATION]: '460px',
    [NotifierView.TOAST]: '300px',
};

@Component({
    components: {
        WsNotification,
        WsToast,
    },
})
export default class WsNotifier extends Vue {
    @Prop({ type: String, default: NotifierPosition.TOP_RIGHT }) position!: NotifierPosition;
    @Prop({ type: Number, default: NOTIFIER_DURATION_DEFAULT }) duration!: number;
    @Prop({ type: Number, default: Infinity }) maxNotifications!: number;

    public items: any[] = [];
    public queue: any[] = [];

    @Watch('items')
    public updateItems(value: any) {
        if (!Number.isFinite(this.maxNotifications) || value.length >= this.maxNotifications || !this.queue.length) {
            return;
        }

        const item = this.queue.shift();
        this.add(item);
    }

    public mounted() {
        eventBus.$on(BusEvent.addNotifier, this.add);
        eventBus.$on(BusEvent.clearNotifier, this.close);
        eventBus.$on(BusEvent.clearAllNotifier, this.closeAll);
    }
    
    public beforeUnmount() {
        eventBus.$off(BusEvent.addNotifier);
        eventBus.$off(BusEvent.clearNotifier);
        eventBus.$off(BusEvent.clearAllNotifier);
    }

    public getComponent(view: NotifierView) {
        return notifierComponent[view];
    }

    public getWidth(view: NotifierView) {
        return notifierWidth[view];
    }

    public setItemLocalStorage(item: any) {
        if (item?.name && item?.id) {
            localStorage.setItem(item.name, item.id);
        }
    }

    public removeItemLocalStorage(item: any) {
        if (item?.name) {
            localStorage.removeItem(item.name);
        }
    }

    public add(item: any) {
        if (!Number.isFinite(this.maxNotifications) || this.items.length < this.maxNotifications) {
            this.items.push(item);

            // If tost has name, we set localstorage with it, to be able close it programmatically.
            this.setItemLocalStorage(item);

            const durationInternal = item.duration || this.duration;

            if (Number.isFinite(durationInternal)) {
                setTimeout(() => this.close(item.id), durationInternal);
            }
        } else {
            this.queue.push(item);
        }
    }

    public close(id: number | string) {
        const itemToClose = this.items.find((item) => item.id === id);

        // If item has name, we need to remove its id from localstorage.
        this.removeItemLocalStorage(itemToClose);
        this.items = this.items.filter((item) => item.id !== id);
    }

    public closeAll(view?: NotifierView) {
        this.items.forEach((item) => {
            this.removeItemLocalStorage(item);
        });
        
        if (view) {
            this.queue = this.queue.filter((item) => item.view !== view);
            this.items = this.items.filter((item) => item.view !== view);
        } else {
            this.queue = [];
            this.items = [];
        }
    }
}
</script>

<style lang="scss">
.notifier {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 88px 24px 24px;
    overflow: hidden;
    z-index: 9999;
    pointer-events: none;

    &-top,
    &-top-right,
    &-top-left {
        flex-direction: column-reverse;
        justify-content: start;

        .notifier-item + .notifier-item {
            margin-bottom: 16px;
        }
    }

    &-bottom,
    &-bottom-right,
    &-bottom-left {
        flex-direction: column;
        justify-content: end;

        .notifier-item + .notifier-item {
            margin-top: 16px;
        }
    }

    &-bottom,
    &-top {
        align-items: center;
    }

    &-top-right,
    &-bottom-right {
        align-items: flex-end;
    }

    &-top-left,
    &-bottom-left {
        align-items: flex-start;
    }

    &-item {
        pointer-events: all;
        flex-shrink: 0;
        z-index: 1;
    }

    &-item-move,
    &-item-enter-active,
    &-item-leave-active {
        transition: all 0.5s ease;
    }

    &-item-enter-from,
    &-item-leave-to {
        transform: translateX(100%);
    }

    &-item-leave-to {
        opacity: 0;
    }

    &-item-leave-active {
        position: absolute !important;
    }
}
</style>
