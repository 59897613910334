<template>
    <div class="skeleton-chat-message-wrapper">
        <div v-for="index in 4" :key="index" class="skeleton-chat-message">
            <div class="skeleton-chat-message-header">
                <div class="skeleton-chat-message-avatar">
                    <SkeletonBone avatar />
                </div>
                <div class="skeleton-chat-message-header-info">
                    <SkeletonBone />
                    <SkeletonBone text-short />
                    <SkeletonBone text-short />
                </div>
            </div>

            <div class="skeleton-chat-message-content">
                <SkeletonBone text-full />
                <SkeletonBone />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonChatMessage extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-chat-message-wrapper {
    display: flex;
    flex-direction: column;
}
.skeleton-chat-message {
    display: flex;
    flex-direction: column;
    padding: $container-padding;
    grid-gap: $default-gap;
}
.skeleton-chat-message-header {
    display: grid;
    grid-template-columns: 40px auto;
    grid-gap: $default-gap;
}
.skeleton-chat-message-avatar .skeleton-bone.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.skeleton-chat-message-header-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $small-padding $default-gap ;
}
.skeleton-chat-message-content {
    display: flex;
    flex-direction: column;
    gap: $small-padding;
}
</style>
