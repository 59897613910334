<template>
    <div class="chat-filter-actions">
        <IconSvg24
            :filled="isOpenChatSearch"
            icon-name="lupa"
            @click="toggleSearch"
        />

        <IconSvg24
            :filled="isOpenChatFilters"
            :icon-name="commentsFiltersActive ? 'filter-filled' : 'filter-def'"
            @click="toggleFilters"
        />

        <WsTooltip
            :tooltip="$t('IssueTracker.chat.pinnedMessages')"
            activator-class="pin-icon"
            block
        >
            <IconSvg24
                :filled="isPinned"
                icon-name="pin"
                @click="togglePinnedComments"
            />
            {{ pinnedComments ? pinnedComments.length : 0 }}
        </WsTooltip>

        <PinnedComments v-if="isPinned" @close="isPinned = false" />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import PinnedComments from '@/domain/comment/components/PinnedComments.vue';
import { Issue } from '@/domain/issue/models/Issue';

@Component({
    components: {
        WsTooltip,
        PinnedComments,
        WsInput,
        IconSvg24,
    },
})
export default class ChatFilterActions extends Vue {
    public isPinned = false;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get pinnedComments() {
        return this.currentIssue ? this.$store.getters.pinnedCommentsByIssue(this.projectId, this.currentIssue.uuid) : [];
    }

    get isOpenChatFilters(): boolean {
        return this.$store.getters.isChatFiltersOpen;
    }

    get isOpenChatSearch(): boolean {
        return this.$store.getters.isChatSearchOpen;
    }

    get commentsFiltersActive(): any {
        return this.$store.getters.commentsFiltersActive;
    }

    public toggleFilters() {
        this.$store.dispatch('toggleChatFilters');

        if (this.isOpenChatFilters) {
            amplitudeLog(AmplitudeEvent.isOpenChatFilters);
        }
    }

    public toggleSearch() {
        this.$store.dispatch('toggleChatSearch');

        if (this.isOpenChatSearch) {
            amplitudeLog(AmplitudeEvent.isOpenChatSearch);
        }
    }

    public togglePinnedComments() {
        this.isPinned = !this.isPinned;

        if (this.isPinned) {
            amplitudeLog(AmplitudeEvent.isOpenPinnedComments, { count: this.pinnedComments.length });
        }
    }
}
</script>

<style lang="scss" scoped>
.chat-filter-actions {
    display: flex;
    gap: 10px;
}

::v-deep .pin-icon {
    margin: 2px 0 0 0;
    display: flex;
    line-height: 31px;

    svg {
        margin: 0 4px 0 0;
    }
}
</style>
