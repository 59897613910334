<template>
    <div
        class="ws-radio"
        :class="{ disabled: disabled, frozen: frozen }"
    >
        <div
            v-for="(item, index) in items"
            :key="index"
            class="ws-radio-item"
            :class="{ checked: item.value === value }"
            @click="toggle(item.value)"
        >
            <div class="column">
                <div class="control" />
                <div v-html="sanitizeHtml(item.text)" class="label" />
            </div>
            <div class="column">
                <div />
                <div v-if="item.subtext" class="sublabel">{{ item.subtext }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { sanitizeHtml } from '@/services';

@Component({})
export default class WsRadio extends Vue {
    @Prop({ required: true }) public value!: number | string;
    @Prop({ type: String }) public label!: string;
    @Prop({ type: Array }) public items!: Array<{ value: number, text: string, subtext?: string }>;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public frozen!: boolean;

    get model() {
        return this.value;
    }
    set model(value: any) {
        this.input(value);
    }

    @Emit()
    public input(value: any) {
        return value;
    }

    public readonly sanitizeHtml = sanitizeHtml;

    public toggle(value: number) {
        if (!this.disabled) {
            this.input(value);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/wsui/index.scss';
.ws-radio {
    .ws-radio-item {
        display: grid;
        grid-template-rows: auto auto;
        padding: 10px 0;
        cursor: pointer;

        .column {
            display: grid;
            grid-template-columns: 16px auto;
            grid-gap: 8px;
        }

        .control {
            width: 16px;
            height: 16px;
            border: solid 1px $color-solid-60;
            border-radius: 16px;
            position: relative;
            margin: 3px 0 0 0;

            &:after {
                width: 8px;
                height: 8px;
                border-radius: 12px;
                display: none;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                background: $color-accent-100;
                transform: translate(-50%, -50%);
            }
        }

        .label {
            line-height: 24px;
            color: $color-solid-80;
            font-size: 14px;

            span {
                display: block;
            }
        }

        .sublabel {
            padding: 6px 0 0 0;
            font-size: 12px;
        }

        &.checked {
            .control {
                &:after {
                    display: block;
                    background: $color-accent-100;
                }
            }
        }
    }

    &.disabled {
        cursor: default;

        .ws-radio-item {
            cursor: default;
        }

        .label {
            color: $color-solid-30;
            cursor: default;
        }

        .control {
            border: solid 1px $color-solid-40;
            cursor: default;

            &:after {
                background: $color-solid-40 !important;
            }
        }
    }

    &.frozen {
        .label {
            color: $color-solid-80;
        }
    }
}
</style>
