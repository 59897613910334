export enum AppSSOKeyName {
    app_sso_key = 'app_sso_key',
    app_sso_region = 'app_sso_region',
    app_sso_merge = 'app_sso_merge',
    app_sso_license_uuid = 'app_sso_license_uuid',
}

export const appSSOKeyNamesList = [
    AppSSOKeyName.app_sso_key,
    AppSSOKeyName.app_sso_region,
    AppSSOKeyName.app_sso_merge,
    AppSSOKeyName.app_sso_license_uuid,
];
