<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8 15H16V17H8V15Z" fill="black" />
        <path d="M20 15H28V17H20V15Z" fill="black" />
        <path d="M32 15H40V17H32V15Z" fill="black" />
        <path d="M44 15H52V17H44V15Z" fill="black" />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType4 extends BaseIcon {}
</script>
