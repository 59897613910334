<template>
    <WsTableFilterElement
        v-model="popover"
        :label="label"
        :width="570"
        :id="id"
        :stop-click-outside-class="StopClickOutsideFilterClass"
        @clear="clear"
    >
        <div class="filter-date-grid">
            <div class="reset">
                <div class="link" @click="onReset">{{ $t('IssueTracker.timeFilters.reset') }}</div>
            </div>
            <div class="value">
                <template v-if="dateFilterType === DateFilterOptions.moreThan && +form.moreThanDays">
                    {{ $t('IssueTracker.timeFilters.moreThanDaysAgo', { days: form.moreThanDays }) }}
                </template>
                <template v-else-if="dateFilterType === DateFilterOptions.withinLast">
                    {{ withinLastDates }}
                </template>
                <template v-else-if="dateFilterType === DateFilterOptions.custom">
                    {{ customDates }}
                </template>
            </div>
            <div class="choose-mode">
                <v-radio-group v-model="dateFilterType">
                    <div class="radios">
                        <v-radio :value="DateFilterOptions.moreThan" :label="$t('Dashboard.chart.settings.filter.expr.date.moreThan')" />
                        <v-radio :value="DateFilterOptions.withinLast" :label="$t('Dashboard.chart.settings.filter.expr.date.withinLast')" />
                        <v-radio :value="DateFilterOptions.custom" :label="$t('Dashboard.chart.settings.filter.expr.date.custom')" />
                    </div>
                </v-radio-group>
            </div>
            <div class="main">
                <div v-if="dateFilterType === DateFilterOptions.moreThan" class="more-than">
                    <WsInputNumber
                        v-model="form.moreThanDays"
                        :maxlength="5"
                        :min="1"
                        outlined
                        dense
                        hide-details
                        type="number"
                        class="days-number"
                    />
                    <div class="input-label">{{ $t('IssueTracker.timeFilters.daysAgo') }}</div>
                </div>

                <div v-if="dateFilterType === DateFilterOptions.withinLast" class="within-last">
                    <WsInputNumber
                        v-model="form.withinLastDays"
                        :maxlength="5"
                        :min="0"
                        outlined
                        dense
                        hide-details
                        type="number"
                        class="days-number"
                    />
                    <div class="input-label">{{ $t('IssueTracker.timeFilters.days') }}</div>
                </div>

                <div v-if="dateFilterType === DateFilterOptions.custom" class="custom">
                    <WsSelect
                        v-model="form.comparisonOption"
                        :items="comparisonOptions"
                        :attach="`.ws-popover-node-${id} .comparison`"
                        class="comparison"
                    />
                    <div v-if="form.isFromRelative" class="from">
                        <v-btn-toggle v-model="form.isMinusFromRelative">
                            <v-btn><v-icon small>mdi-plus</v-icon></v-btn>
                            <v-btn><v-icon small>mdi-minus</v-icon></v-btn>
                        </v-btn-toggle>
                        <WsInputNumber
                            v-model="form.fromRelativeNumber"
                            :maxlength="5"
                            :min="0"
                            outlined
                            dense
                            hide-details
                            type="number"
                            class="days-number"
                        />
                        <WsSelect
                            v-model="form.fromRelativeTimeUnit"
                            :items="timeUnits"
                            :attach="`.ws-popover-node-${id} .time-limit-unit`"
                            class="time-limit-unit"
                        />
                    </div>
                    <div v-else class="from">
                        <WsDateRangePicker :value="datePickedFromValue" single-date-picker @input="onInputDateFrom" />
                    </div>
                    <v-checkbox
                        v-model="form.isFromRelative"
                        :label="$t('IssueTracker.timeFilters.relative')"
                        class="checkbox"
                        hide-details
                    />
                    <template v-if="form.comparisonOption === ComparisonOptions.between">
                        <div class="and">
                            <span class="line" />
                            <span>{{ $t('IssueTracker.timeFilters.and') }}</span>
                            <span class="line" />
                        </div>
                        <div v-if="form.isToRelative" class="to">
                            <v-btn-toggle v-model="form.isMinusToRelative">
                                <v-btn><v-icon small>mdi-plus</v-icon></v-btn>
                                <v-btn><v-icon small>mdi-minus</v-icon></v-btn>
                            </v-btn-toggle>
                            <WsInputNumber
                                v-model="form.toRelativeNumber"
                                :maxlength="5"
                                :min="0"
                                outlined
                                dense
                                hide-details
                                type="number"
                                class="days-number"
                            />
                            <WsSelect
                                v-model="form.toRelativeTimeUnit"
                                :items="timeUnits"
                                :attach="`.ws-popover-node-${id} .time-limit-unit`"
                                class="time-limit-unit"
                            />
                        </div>
                        <div v-else class="to">
                            <WsDateRangePicker :value="datePickedToValue" single-date-picker @input="onInputDateTo" />
                        </div>
                        <v-checkbox
                            v-model="form.isToRelative"
                            :label="$t('IssueTracker.timeFilters.relative')"
                            class="checkbox"
                        />
                    </template>
                </div>
            </div>
        </div>

        <slot />

        <template #button>
            <template v-if="dateFilterType === DateFilterOptions.moreThan">
                {{ $t('IssueTracker.timeFilters.moreThanDaysAgo', { days: form.moreThanDays }) }}
            </template>
            <template v-else-if="dateFilterType === DateFilterOptions.withinLast">
                {{ withinLastDates }}
            </template>
            <template v-else-if="dateFilterType === DateFilterOptions.custom">
                {{ customDates }}
            </template>
        </template>
    </WsTableFilterElement>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import WsDateRangePicker from '@/components/common/WsDateRangePicker.vue';
import WsTableFilterDatepicker from '@/components/common/WsTableFilterDatepicker.vue';
import FilterByDateBase from '@/components/common/FilterByDateBase.vue';

@Component({
    components: {
        WsDateRangePicker,
        WsTableFilterDatepicker,
    },
})
export default class WsIssueTrackerFilterByDate extends FilterByDateBase {
    public id = uuid();
}
</script>

<style scoped lang="scss">
@import '@/styles/itDateFilters.scss';
.time-limit-unit {
    margin: 0 0 -26px 0;
}

.filter-date-grid .from ::v-deep .vue-daterange-picker,
.filter-date-grid .to ::v-deep .vue-daterange-picker {
    width: 220px;
}
</style>
