import { render, staticRenderFns } from "./SkeletonChatMessage.vue?vue&type=template&id=daa2ff72&scoped=true"
import script from "./SkeletonChatMessage.vue?vue&type=script&lang=ts"
export * from "./SkeletonChatMessage.vue?vue&type=script&lang=ts"
import style0 from "./SkeletonChatMessage.vue?vue&type=style&index=0&id=daa2ff72&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daa2ff72",
  null
  
)

export default component.exports