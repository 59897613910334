<template>
    <div class="chat-filter">
        <div v-if="isOpenChatFilters" class="chat-filter-dropdown">
            <span
                class="reset"
                :class="{ 'active': commentsFiltersActive }"
                @click="reset"
            >
                {{ $t('Button.reset') }}
            </span>
            <span class="divider" />

            <WsCheckbox
                :value="filters.isComments"
                :label="$t('IssueTracker.chat.comments')"
                @change="(value) => onChangeFilter(IssueCommentsFilter.isComments, value)"
            />
            <WsCheckbox
                :value="filters.isAttachments"
                :label="$t('IssueTracker.chat.attachments')"
                @change="(value) => onChangeFilter(IssueCommentsFilter.isAttachments, value)"
            />
            <WsCheckbox
                :value="filters.isFieldChanges"
                :label="$t('IssueTracker.chat.fieldChanges')"
                @change="(value) => onChangeFilter(IssueCommentsFilter.isFieldChanges, value)"
            />
            <WsCheckbox
                :value="filters.isMarkupChanges"
                :label="$t('IssueTracker.chat.markupChanges')"
                @change="(value) => onChangeFilter(IssueCommentsFilter.isMarkupChanges, value)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsInput from '@/components/common/WsInput.vue';
import { IssueCommentsFilter } from '@/domain/issue/constants/IssueCommentsFilter';

@Component({
    components: {
        WsInput,
        IconSvg24,
    },
})
export default class ChatFilter extends Vue {
    public IssueCommentsFilter = IssueCommentsFilter;

    get filters(): any {
        return this.$store.getters.commentsFilters;
    }

    get isOpenChatFilters(): boolean {
        return this.$store.getters.isChatFiltersOpen;
    }

    get commentsFiltersActive(): any {
        return this.$store.getters.commentsFiltersActive;
    }

    public onChangeFilter(filter: IssueCommentsFilter, value: boolean) {
        this.$store.commit('setCommentsFilterValue', {
            filter,
            value,
        });

        if (value) {
            amplitudeLog(AmplitudeEvent.setChatFilter, {
                filter,
                value,
            });
        }
    }

    public reset() {
        this.$store.commit('resetChatFilters');
        amplitudeLog(AmplitudeEvent.resetChatFilters);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.chat-filter {
    width: 100%;
}

::v-deep {
    fieldset {
        border: none !important;
    }

    .v-input--checkbox {
        padding: 0 24px;

        .v-input__slot {
            margin-bottom: 0 !important;
        }

        .v-input__control {
            flex-direction: row !important;
            flex-wrap: inherit !important;
            padding: 12px 0;
        }

    }

    .v-input--checkbox.v-input--is-label-active {
        background: $primary-light-blue;
    }
}

.chat-filter-dropdown {
    border-bottom: $border;

    .v-input {
        margin: 0;
    }
}

.divider {
    margin: 12px 24px 24px 24px;
    border-bottom: $border;
    display: block;

}

.reset {
    font-size: $font-size;
    cursor: pointer;
    display: block;
    margin: 12px 24px 0 24px;

    &.active {
        color: $primary-blue;
    }
}
</style>
