<template>
    <div class="component-forgot-password-form form-box">
        <template v-if="isSent">
            <h1 class="auth-title">{{ $t('Popup.check_email.title') }}</h1>
            <div class="auth-title-desc">{{ $t('Popup.check_email.text2') }}</div>

            <div class="flex-delimiter" />
            <ReturnToLoginButton />
        </template>

        <template v-else>
            <h1 class="auth-title">{{ $t('Button.forgot') }}</h1>

            <v-form ref="forgotForm">
                <WsInput
                    v-model="forgotForm.email"
                    :label="$t('Form.email')"
                    :rules="emailRules"
                    validate-on-blur
                    clearable
                    @input="clearError"
                    @keypress.enter.native.prevent="resetPassword"
                />

                <WsButton
                    :loading="isSending"
                    type="primary"
                    round
                    @click="resetPassword"
                >
                    {{ $t('Collocation.resetPassword') }}
                </WsButton>
            </v-form>

            <div class="flex-delimiter" />
            <div v-if="forgotFormError" class="error">{{ $t('Collocation.userNotFound') }}</div>
            <ReturnToLoginButton />
        </template>
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { TRules } from '@/types/TRulesObj';
import { AmplitudeEvent } from '@/constants';
import { FormValidator } from '@/services';
import { amplitudeMixin } from '@/mixins';
import ReturnToLoginButton from '@/components/user/ReturnToLoginButton.vue';
import WsInput from '@/components/common/WsInput.vue';

@Component({
    components: {
        ReturnToLoginButton,
        WsInput,
    },
})
export default class ForgotPasswordForm extends amplitudeMixin {

    private forgotForm = {
        email: '',
    };

    get form(): HTMLFormElement {
        return this.$refs.forgotForm as HTMLFormElement;
    }
    get isSending() {
        return this.$store.getters.forgotIsSending;
    }
    get isSent() {
        return this.$store.getters.forgotIsSent;
    }
    get forgotFormError() {
        return this.$store.getters.forgotFormError;
    }
    get emailRules(): TRules {
        return [
            FormValidator.email,
        ];
    }

    public created() {
        this.amplitudeLog(AmplitudeEvent.loginRestorePasswordFormOpened);
    }

    public resetPassword() {
        if (this.form.validate()) {
            this.$store.dispatch('forgotPassword', this.forgotForm.email);
        }
    }
    public clearError() {
        this.$store.commit('setForgotPasswordFormError');
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/auth.scss';
</style>
