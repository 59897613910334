<template>
    <IconSvg24
        :icon-name="getIssueTypeIconPath(Number(icon))"
        :color="iconColor"
        class="mr-1"
        passive
        @click="click"
    />
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import { getIssueTypeIconPath, IssueTypeIcon } from '@/domain/issue/constants/IssueTypeIcons';

@Component({
    methods: {
        getIssueTypeIconPath,
    },
    components: {
        IconSvg24,
    },
})
export default class CustomIssueTypeIcon extends Vue {
    @Prop() icon!: IssueTypeIcon;
    @Prop({ type: String, required: true }) public iconColor!: string;

    @Emit()
    public click() {
        return;
    }
}
</script>
