<template>
    <div @click="click">
        <IssueCommentDiffChanged :entity="entity" />
        <template v-if="entity === IssueTrackerFields.status">
            <IssueStatusText :status="diff.old" tag-like />
            ➝
            <IssueStatusText :status="diff.new" tag-like />
        </template>
        <template v-else-if="entity === IssueTrackerFields.priority">
            <IssuePriorityText :value="diff.old" tag-like />
            ➝
            <IssuePriorityText :value="diff.new" tag-like />
        </template>
        <template v-else-if="[IssueTrackerFields.assignee, IssueTrackerFields.reporter].includes(entity)">
            <WsMemberName :email="diff.old" tooltip />
            ➝
            <WsMemberName :email="diff.new" tooltip />
        </template>
        <template v-else-if="entity === IssueTrackerFields.visibility">
            {{ $t(`Dashboard.chart.settings.filter.visibility.${diff.old}`) }}
            ➝
            {{ $t(`Dashboard.chart.settings.filter.visibility.${diff.new}`) }}
        </template>
        <template v-else-if="entity === IssueTrackerFields.deadline">
            {{ localDateFormatter(diff.old) }}
            ➝
            {{ localDateFormatter(diff.new) }}
        </template>
        <template v-else-if="entity === IssueTrackerFields.stampColor">
            &nbsp;<WsSelectColorItem
                :color="StampColorsPaletteRGBEnum[diff.old]"
                rgb
                inline
                size="mini"
            />
            ➝
            <WsSelectColorItem
                :color="StampColorsPaletteRGBEnum[diff.new]"
                rgb
                inline
                size="mini"
            />
        </template>
        <template v-else>
            <span class="highlighting" :class="{ 'amp-mask': entity == 'title' }">{{ diff.old || $t('Text.empty') }}</span>&nbsp;➝&nbsp;
            <span class="highlighting" :class="{ 'amp-mask': entity == 'title' }">{{ diff.new || $t('Text.empty') }}</span>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { DEADLINE_NOT_SET } from '@/constants';
import { IssueTrackerFields } from '@/constants/IssueTrackerFields';
import { dateFormatter } from '@/services/MomentFormats';
import WsMemberName from '@/components/common/WsMemberName.vue';
import WsSelectColorItem from '@/components/common/WsSelectColorItem.vue';
import IssueStatusText from '@/domain/issue/components/IssueStatusText.vue';
import IssueCommentDiffChanged from '@/domain/comment/components/IssueCommentDiffChanged.vue';
import IssuePriorityText from '@/domain/issue/components/IssuePriorityText.vue';
import { IssueTrackerDiffInlinePropertiesType } from '@/domain/comment/models/IssueTrackerDiffProperties';
import { IDiffItem } from '@/domain/comment/models/DiffComment';
import { StampColorsPaletteRGBEnum } from '@/domain/stamp/constants/StampColorsPaletteRGBEnum';

@Component({
    components: {
        IssueCommentDiffChanged,
        IssueStatusText,
        IssuePriorityText,
        WsMemberName,
        WsSelectColorItem,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueCommentDiffInline extends Vue {
    @Prop({ required: true }) public entity!: IssueTrackerDiffInlinePropertiesType;
    @Prop({ required: true }) public diff!: IDiffItem;

    public readonly StampColorsPaletteRGBEnum = StampColorsPaletteRGBEnum;
    public readonly IssueTrackerFields = IssueTrackerFields;

    @Emit()
    public click() {
        return;
    }

    public localDateFormatter(date: string) {
        return date === DEADLINE_NOT_SET ? this.$t('Simple_word.none') : dateFormatter(date);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.diff:not(:last-child) {
    margin-bottom: 2em;
}
</style>
