<template>
    <v-dialog
        v-model="isVisible"
        width="904px"
        content-class="dialog-boarding"
        overlay-opacity="0.5"
        ref="vdialog"
        @click:outside="close"
    >
        <v-card class="card">
            <aside class="aside">
                <div class="welcome-title">
                    <IconSvg16
                        :color="Color.miskRed"
                        :size="40"
                        icon-name="revizto-icon-bigger"
                        passive
                    />
                    <p>{{ $t('Boarding.welcomeTitle') }}</p>
                </div>
                <slot name="navigation" />
            </aside>
            <div class="content">
                <button class="close-button" @click="close">×</button>
                <div
                    :class="{ 'has-footer': isHasFooter }"
                    class="content-body"
                >
                    <slot name="content" />
                </div>
                <div
                    v-if="isHasFooter"
                    class="footer"
                >
                    <slot name="footer" />
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import { Component, Emit, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

const SMALL_SCREEN_HEIGHT = 900;

@Component({
    components: {
        IconSvg16,
    },
})
export default class DialogBoarding extends Vue {
    public readonly Color = Color;
    public isVisible = true;

    get isHasFooter() {
        return Boolean(this.$slots.footer);
    }

    @Emit()
    public close() {
        return;
    }

    public mounted() {
        const vdialogComponent = this.$refs.vdialog as Vue | undefined;
        const topOffset = innerHeight > SMALL_SCREEN_HEIGHT ? '15vh' : '5vh';

        if (!vdialogComponent?.$refs.dialog) {
            return;
        }

        (vdialogComponent.$refs.dialog as HTMLElement).style.marginTop = topOffset;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';
.card {
    display: flex;
}

.content-body {
    padding: 48px 32px 12px;
}

::v-deep .dialog-boarding{
    box-shadow: none;
    overflow-y: hidden;
    height: 100%;
    
}

.v-card {
    height: 100%;
    max-height: 700px;
    
}

.v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
    border-top-right-radius: 0;
}

aside {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 280px;
    flex-shrink: 0;
    padding: 48px 36px 88px 24px;
    background-color: $dark-solid-5;
    color: $white;
    height: 100%;
}

.content {
    flex-grow: 1;
    height: 100%;
}

.content-body {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @include scrollbar;

    &.has-footer {
        height: calc(100% - 67px);
    }
}

.welcome-title {
    padding: 0 0 0 8px;
    ::v-deep .svg-icon {
        margin-left: -8px;
    }

    p {
        font-size: $font-size-huge;
        font-weight: $font-weight-bold;
        line-height: 32px;
    }
}

.close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-solid-60;
    border-radius: 50%;
    overflow: hidden;
    background-color: $light-solid-5;
    transition: 0.2s background-color ease-out, 0.2s color ease-out;
    z-index: 10;

    &:hover {
        background-color: $hover-light-blue;
        color: $light-solid-80;
    }
}
</style>