import { render, staticRenderFns } from "./SkeletonDefaultTable.vue?vue&type=template&id=ec7986f8&scoped=true"
import script from "./SkeletonDefaultTable.vue?vue&type=script&lang=ts"
export * from "./SkeletonDefaultTable.vue?vue&type=script&lang=ts"
import style0 from "./SkeletonDefaultTable.vue?vue&type=style&index=0&id=ec7986f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec7986f8",
  null
  
)

export default component.exports