export enum AmplitudeEvent {
    wsOpen = 'WS_OPEN', // попадание в авторизованную зону
    projectTagging = 'PROJECT_TAGGING', // Тегирование проекта (source - страница откуда тегировано)
    projectUntagging = 'PROJECT_UNTAGGING', // удаление тегов
    projectUnsubscribe = 'PROJECT_UNSUBSCRIBE', // Отписка от проекта
    projectDelete = 'PROJECT_DELETE', // Удаление от проекта
    projectArchive = 'PROJECT_ARCHIVE', // Архивация проекта
    projectRestore = 'PROJECT_RESTORE', // Разархивация проекта

    projectMembersOpen = 'PROJECT_MEMBERS_OPEN', // Открытие проекта шаринг / оно же project members
    projectMembersAdd = 'PROJECT_MEMBERS_ADD', // Добавление новых людей не входящих в лицензию
    projectMembersEmailSent = 'PROJECT_MEMBERS_EMAIL_SENT', // Отправка email

    projectMemberEmailCopied = 'PROJECT_MEMBERS_EMAIL_COPIED',
    projectMembersFilter = 'PROJECT_MEMBERS_FILTER', // { type }
    projectMembersSearch = 'PROJECT_MEMBERS_SEARCH',
    projectMembersResendInvitation = 'PROJECT_MEMBERS_RESEND_INVITATION',
    projectMembersRemoveFromProject = 'PROJECT_MEMBERS_REMOVE_FROM_PROJECT',
    projectMembersActualRemoveFromProject = 'PROJECT_MEMBERS_ACTUAL_REMOVE_FROM_PROJECT',
    projectMembersInvitationError = 'PROJECT_MEMBERS_INVITATION_ERROR',
    projectMembersActivityToEmail = 'PROJECT_MEMBERS_ACTIVITY_TO_EMAIL',
    projectChangeRole = 'PROJECT_CHANGE_ROLE',

    projectDashboardsOpen = 'PROJECT_DASHBOARDS_OPEN', // Open project dashboards page
    projectDashboardOpenItem = 'PROJECT_DASHBOARD_OPEN_ITEM', // Open project dashboard
    projectDashboardConfigure = 'PROJECT_DASHBOARD_CONFIGURE', // Open configure dashboard
    projectDashboardImportChartStart = 'PROJECT_DASHBOARD_IMPORT_CHART_START', // Import charts button pressed
    projectDashboardImportChartClose = 'PROJECT_DASHBOARD_IMPORT_CHART_CLOSE', // Close import charts
    projectDashboardImportChartComplete = 'PROJECT_DASHBOARD_IMPORT_CHART_COMPLETE', // Complete import charts
    projectDashboardImportChartResult = 'PROJECT_DASHBOARD_IMPORT_CHART_RESULT', // Result of import charts
    projectDashboardCreate = 'PROJECT_DASHBOARD_CREATE', // Create new project dashboard
    projectDashboardCreateWithPreset = 'PROJECT_DASHBOARD_CREATE_WITH_PRESET', // Create new project dashboard from preset
    projectDashboardSendNow = 'PROJECT_DASHBOARD_SEND_NOW', // Send now project dashboard
    projectDashboardChartSendNow = 'PROJECT_DASHBOARD_CHART_SEND_NOW', // Send now chart
    projectDashboardChartDelivery = 'PROJECT_DASHBOARD_CHART_DELIVERY', // Save periodic chart delivery
    projectDashboardChange = 'PROJECT_DASHBOARD_CHANGE', // Switch between project dashboards
    projectDashboardDelete = 'PROJECT_DASHBOARD_DELETE', // Delete project dashboard
    projectDashboardFilterChange = 'PROJECT_DASHBOARD_FILTER_CHANGE', // Change filter in project dashboards main page
    projectImportDashboardStart = 'PROJECT_IMPORT_DASHBOARD_START', // Press import dashboard button
    projectImportDashboardClose = 'PROJECT_IMPORT_DASHBOARD_CLOSE', // Close import dashboard
    projectImportDashboardComplete = 'PROJECT_IMPORT_DASHBOARD_COMPLETE', // Import dashboard complete
    projectImportDashboardResult = 'PROJECT_IMPORT_DASHBOARD_RESULT', // Result of import dashboard
    projectDashboardFavorite = 'PROJECT_DASHBOARD_FAVORITE', // Add or remove dashboard from favorites
    projectDashboardFavoriteError = 'PROJECT_DASHBOARD_FAVORITE_ERROR', // Error while adding or removing dashboard from favorites
    projectDashboardExportStart = 'PROJECT_DASHBOARD_EXPORT_START', // Press export dashboard button
    projectDashboardExportClose = 'PROJECT_DASHBOARD_EXPORT_CLOSE', // Close export dashboard
    projectDashboardExportComplete = 'PROJECT_DASHBOARD_EXPORT_COMPLETE', // Export dashboard complete
    projectDashboardExportResult = 'PROJECT_DASHBOARD_EXPORT_RESULT', // Export dashboard result
    projectDashboardRefreshAllCharts = 'PROJECT_DASHBOARD_REFRESH_ALL_CHARTS', // Refresh all charts
    projectDashboardRefreshChart = 'PROJECT_DASHBOARD_REFRESH_CHART', // Refresh a chart
    projectDashboardOpenChartMenu = 'PROJECT_DASHBOARD_OPEN_CHART_MENU', // Chart open context menu
    projectDashboardSelectChartMenu = 'PROJECT_DASHBOARD_SELECT_CHART_MENU', // Chart context menu click any option
    projectDashboardNewChart = 'PROJECT_DASHBOARD_NEW_CHART', // New chart
    projectDashboardOpenChart = 'PROJECT_DASHBOARD_OPEN_CHART', // Open chart
    projectDashboardSaveChart = 'PROJECT_DASHBOARD_SAVE_CHART', // Save chart
    projectDashboardCloseChart = 'PROJECT_DASHBOARD_CLOSE_CHART', // Close chart
    projectDashboardChartToggleSettings = 'PROJECT_DASHBOARD_CHART_TOGGLE_SETTINGS', // Collapse / show chart settings
    projectDashboardDeleteChart = 'PROJECT_DASHBOARD_DELETE_CHART', // Delete chart by button

    projectReportsOpen = 'PROJECT_REPORTS_OPEN', // Open project reports page
    projectReportSendNow = 'PROJECT_REPORT_SEND_NOW', // Instantly send report

    projectActivityOpen = 'PROJECT_ACTIVITY_OPEN', // Open activity page
    projectRevisionOpen = 'PROJECT_REVISION_OPEN', // Open revision page

    projectNotificationOpen = 'PROJECT_NOTIFICATION_OPEN', // Открытие нотификаций внутри проекта
    projectNotificationChange = 'PROJECT_NOTIFICATION_CHANGE', // Переключение на кастом / дефолт (state - текущее состояние)

    projectInfoOpen = 'PROJECT_INFO_OPEN', // Открытие страницы информации проекта
    projectFieldChangeOpen = 'PROJECT_FIELD_CHANGE_OPEN', // { field } - имя поля
    projectFieldChangeSuccess = 'PROJECT_FIELD_CHANGE_SUCCESS', // { field } - имя поля
    projectFieldChangeFail = 'PROJECT_FIELD_CHANGE_FAIL', // { field } - имя поля

    columnControlsActivate = 'COLUMN_CONTROLS_ACTIVATE',
    columnVisibilityToggle = 'COLUMN_VISIBILITY_TOGGLE',
    columnsOrderChanged = 'COLUMN_ORDER_CHANGED',

    userProfileOpen = 'USER_PROFILE_OPEN', // Открытие страницы профиля
    userNotificationOpen = 'USER_NOTIFICATION_OPEN', // Редактирование дефолтных нотификаций

    recentProjectOpen = 'RECENT_PROJECT_OPEN', // Переключение на другой недавний проект

    myProjectsOpen = 'MY_PROJECTS_OPEN', // Открытие страницы my projects
    myProjectsFilterChange = 'MY_PROJECTS_FILTER_CHANGE', // использование фильтров, { type } - тип фильтра
    // (filterType - тип фильтра, values - имена фильтров)

    loginSuccessfulAuth = 'LOGIN_SUCCESSFUL_AUTH',
    loginUnsuccessfulAuth = 'LOGIN_UNSUCCESSFUL_AUTH',
    loginIntoWrongRegion = 'LOGIN_INTO_WRONG_REGION',
    loginRestorePasswordFormOpened = 'LOGIN_RESTORE_PASSWORD_FORM_OPENED',
    loginRestoreAccessPageFromEmail = 'LOGIN_RESTORE_ACCESS_PAGE_FROM_EMAIL',
    loginRestorePasswordSuccess = 'LOGIN_RESTORE_PASSWORD_SUCCESS',
    loginRestorePasswordError = 'LOGIN_RESTORE_PASSWORD_ERROR',

    logoutClicked = 'LOGOUT_CLICKED',

    licenseActivityOpen = 'LICENSE_ACTIVITY_OPEN', // Open license activity page
    licenseDashboardConfigure = 'LICENSE_DASHBOARD_CONFIGURE', // Open configure dashboard
    licenseDashboardDelete = 'LICENSE_DASHBOARD_DELETE', // Delete dashboard
    licenseInfoOpen = 'LICENSE_INFO_OPEN', // Open license info
    licenseDashboardsOpen = 'LICENSE_DASHBOARDS_OPEN', // Open cross-project dashboards
    licenseDashboardOpenItem = 'LICENSE_DASHBOARD_OPEN_ITEM', // Open a cross-project dashboard
    licenseDashboardCreate = 'LICENSE_DASHBOARD_CREATE', // Create a cross-project dashboard
    licenseDashboardChange = 'LICENSE_DASHBOARD_CHANGE', // Switch between cross-project dashboards
    licenseDashboardSendNow = 'LICENSE_DASHBOARD_SEND_NOW', // Momentary send cross-project dashboards
    licenseDashboardChartSendNow = 'LICENSE_DASHBOARD_CHART_SEND_NOW', // Momentary send a cross-project dashboard chart
    licenseDashboardChartDelivery = 'LICENSE_DASHBOARD_CHART_DELIVERY', // Save regular send a cross-project dashboard chart
    licenseDashboardFilterChange = 'LICENSE_DASHBOARD_FILTER_CHANGE', // Use cross-project dashboards page filter
    licenseDashboardFavorite = 'LICENSE_DASHBOARD_FAVORITE', // Add or remove a cross-project dashboard from favorites
    licenseDashboardFavoriteError = 'LICENSE_DASHBOARD_FAVORITE_ERROR', // Error adding or removing a cross-project dashboard from favorites
    licenseDashboardRefreshChart = 'LICENSE_DASHBOARD_REFRESH_CHART', // Refresh chart
    licenseDashboardRefreshAllCharts = 'LICENSE_DASHBOARD_REFRESH_ALL_CHARTS', // Refresh all charts
    licenseDashboardOpenChartMenu = 'LICENSE_DASHBOARD_OPEN_CHART_MENU', // Open context menu on a chart
    licenseDashboardSelectChartMenu = 'LICENSE_DASHBOARD_SELECT_CHART_MENU', // Chart context menu click any option
    licenseDashboardNewChart = 'LICENSE_DASHBOARD_NEW_CHART', // New chart
    licenseDashboardOpenChart = 'LICENSE_DASHBOARD_OPEN_CHART', // Open chart
    licenseDashboardSaveChart = 'LICENSE_DASHBOARD_SAVE_CHART', // Save chart
    licenseDashboardCloseChart = 'LICENSE_DASHBOARD_CLOSE_CHART', // Close chart
    licenseDashboardChartToggleSettings = 'LICENSE_DASHBOARD_CHART_TOGGLE_SETTINGS', // Collapse / show chart settings
    licenseDashboardDeleteChart = 'LICENSE_DASHBOARD_DELETE_CHART', // Delete chart by button

    licenseMemberInfoOpen = 'LICENSE_MEMBER_INFO_OPEN',
    LicenseMemberInfoSearchProject = 'LICENSE_MEMBER_INFO_SEARCH_PROJECT',
    licenseMemberFieldChangeOpen = 'LICENSE_MEMBER_FIELD_CHANGE_OPEN', // { field } - имя поля
    licenseMemberFieldChangeSuccess = 'LICENSE_MEMBER_FIELD_CHANGE_SUCCESS', // { field } - имя поля
    licenseMemberFieldChangeFail = 'LICENSE_MEMBER_FIELD_CHANGE_FAIL', // { field } - имя поля

    licenseMembersOpen = 'LICENSE_MEMBERS_OPEN', // Открытие страницы участников лицензии
    licenseMembersExportToExcel = 'LICENSE_MEMBERS_EXPORT_TO_EXCEL', // Экспорт в эксель участников лицензии
    licenseMembersStatisticsExportToExcel = 'LICENSE_MEMBERS_STATISTICS_EXPORT_TO_EXCEL', // Экспорт в эксель статистики участников лицензии
    licenseMembersChooseDate = 'LICENSE_MEMBERS_CHOOSE_DATE', // Переключение времени
    // licenseMembersSideFilter = 'LICENSE_MEMBERS_SIDE_FILTER', // Использование бокового фильтра
    licenseMembersShowActivity = 'LICENSE_MEMBERS_SHOW_ACTIVITY', // Показ графика
    licenseMembersEmailSent = 'LICENSE_MEMBERS_EMAIL_SENT', // Отправка email

    licenseMembersResendInvitation = 'LICENSE_MEMBERS_RESEND_INVITATION',
    licenseMembersDeactivation = 'LICENSE_MEMBERS_DEACTIVATION',
    licenseMembersReactivation = 'LICENSE_MEMBERS_REACTIVATION',

    licenseMembersTableOpenSelectMenu = 'LICENSE_MEMBERS_TABLE_OPEN_SELECT_MENU',
    licenseMembersTableMenuSelectCurrentPage = 'LICENSE_MEMBERS_TABLE_SELECT_CURRENT_PAGE',
    licenseMembersTableMenuSelectAllPages = 'LICENSE_MEMBERS_TABLE_SELECT_ALL_PAGES',
    licenseMembersTableMenuUnselectCurrentPage = 'LICENSE_MEMBERS_TABLE_UNSELECT_CURRENT_PAGE',
    licenseMembersTableMenuUnselectAllPages = 'LICENSE_MEMBERS_TABLE_UNSELECT_ALL_PAGES',
    licenseMembersTableCheckboxSelected ='LICENSE_MEMBERS_TABLE_CHECKBOX_SELECTED',

    licenseMemberEditSidebarOpen ='LICENSE_MEMBERS_EDIT_SIDEBAR_OPEN', // { method ='button' | 'row-click', count, mainAction ='mailNew' | 'activate' | 'mailResend'  } - how sidebar was opened and how many users was selected
    licenseMemberEditSidebarAction ='LICENSE_MEMBERS_EDIT_SIDEBAR_ACTION', // { action =LicenseMemberActionNames }
    licenseMemberInviteSibebarOpen ='LICENSE_MEMBERS_INVITE_SIDEBAR_OPEN',

    licenseMemberWhileEditPopupOpen ='LICENSE_MEMBERS_WHILE_EDIT_POPUP_OPEN',
    licenseMemberWhileEditPopupAdd ='LICENSE_MEMBERS_WHILE_EDIT_POPUP_ADD',
    licenseMemberWhileEditPopupSelectOne ='LICENSE_MEMBERS_WHILE_EDIT_POPUP_SELECT_ONE',
    licenseMemberWhileEditPopupClose ='LICENSE_MEMBERS_WHILE_EDIT_POPUP_CLOSE', // { button ='close-button' | 'overlay-click' | 'cancel' | 'ok' }

    licenseMemberFormReset ='LICENSE_MEMBER_FORM_RESET',
    licenseMemberFormImportFromUser ='LICENSE_MEMBER_FORM_IMPORT_FROM_USER',
    licenseMemberFormChangeRole ='LICENSE_MEMBER_FORM_CHANGE_ROLE',
    licenseMemberFormChangeAuthMethod ='LICENSE_MEMBER_FORM_CHANGE_AUTH_METHOD',
    licenseMemberFormChangeTags ='LICENSE_MEMBER_FORM_CHANGE_TAGS', // { added, removed, created }
    licenseMemberFormChangeCompany ='LICENSE_MEMBER_FORM_CHANGE_COMPANY', // { action ='changed' | 'created' | 'removed' }
    licenseMemberFormChangeDepartment ='LICENSE_MEMBER_FORM_CHANGE_DEPARTMENT', // { action ='changed' | 'created' | 'removed' }
    licenseMemberFormChangeLocation ='LICENSE_MEMBER_FORM_CHANGE_LOCATION', // { action ='changed' | 'created' | 'removed' }

    licenseMemberProjectsChangeRoleToAdd ='LICENSE_MEMBER_PROJECTS_CHANGE_ROLE_TO_ADD',
    licenseMemberProjectsChangeProjects ='LICENSE_MEMBER_PROJECTS_CHANGE_PROJECTS', // { count } how many projects are chosen
    licenseMemberProjectsAddToList ='LICENSE_MEMBER_PROJECTS_ADD_TO_LIST',
    licenseMemberProjectsUpdateListedProjectRole ='LICENSE_MEMBER_UPDATE_LISTED_PROJECT_ROLE',
    licenseMemberProjectsRemoveListedProject ='LICENSE_MEMBER_REMOVE_LISTED_PROJECT',
    licenseMemberRemoveFromProjectsPopupOpen ='LICENSE_MEMBER_REMOVE_FROM_PROJECTS_POPUP_OPEN', // { method ='button' | 'multi-edit-alert' }

    licenseMemberAddUserButtonClick ='LICENSE_MEMBER_ADD_USER_BUTTON_CLICK', // { isRememberDetailsChecked =boolean }
    licenseMemberUpdate ='LICENSE_MEMBER_UPDATE', // { count, successCount, errorCount }
    licenseMemberCreateNewWithDetails ='LICENSE_MEMBER_CREATE_NEW_WITH_DETAILS',
    licenseMembersBulkImportClick ='LICENSE_MEMBER_BULK_IMPORT_CLICK',

    licenseMemberWhileInvitePopupOpen ='LICENSE_MEMBERS_WHILE_INVITE_POPUP_OPEN',
    licenseMemberWhileInvitePopupCopy ='LICENSE_MEMBERS_WHILE_INVITE_POPUP_COPY',
    licenseMemberWhileInvitePopupDiscard ='LICENSE_MEMBERS_WHILE_INVITE_POPUP_DISCARD',
    licenseMemberWhileInvitePopupClose ='LICENSE_MEMBERS_WHILE_INVITE_POPUP_CLOSE', // { button ='close-button' | 'overlay-click' | 'cancel' | 'ok' }

    licenseProjectsOpen = 'LICENSE_PROJECTS_OPEN', // Открытие страницы проектов лицензии
    licenseProjectsExportToExcel = 'LICENSE_PROJECTS_EXPORT_TO_EXCEL', // Экспорт в эксель проектов лицензии
    licenseProjectsChooseDate = 'LICENSE_PROJECTS_CHOOSE_DATE', // Переключение времени
    // Использование бокового фильтра
    licenseProjectsShowActivity = 'LICENSE_PROJECTS_SHOW_ACTIVITY', // Показ графика

    // скачивание версий
    downloadOpen = 'DOWNLOAD_OPEN',
    downloadSelectVersion = 'DOWNLOAD_SELECT_VERSION', // { version } указывается только мажор версия (4 или 5)
    downloadSelectPlatform = 'DOWNLOAD_SELECT_PLATFORM', // { platform }

    // во все download эвенты ниже передается версия { version } для латест указывается только мажор версия (4 или 5)
    downloadLatest = 'DOWNLOAD_LATEST', // последняя версия для винды
    downloadMac = 'DOWNLOAD_MAC',
    downloadIphone = 'DOWNLOAD_IPHONE',
    downloadIpad = 'DOWNLOAD_IPAD',
    downloadAndroidPhone = 'DOWNLOAD_ANDROID_PHONE',
    downloadAndroidTablet = 'DOWNLOAD_ANDROID_TABLET',
    downloadVer = 'DOWNLOAD_VER', // version содержит полную версию для винды (например 5.3.2.62521)

    boardingOpen = 'BOARDING_OPEN',
    boardingClose = 'BOARDING_CLOSE', // { action } 1 - click on download button, 0 - just close
    boardingStepsOpen = 'BOARDING_STEPS_OPEN',
    boardingStepsClose = 'BOARDING_STEPS_CLOSE', // { steps } count of steps seen before close dialog

    notFound = 'NOT_FOUND', // 404 page

    reportingHistory = 'REPORTING_HISTORY_PAGE_OPEN',  // { pages, files, allEmails } pages - pagination pages, files - reports in status "DONE", allEmails - count of every item
    reportingHistoryDownload = 'REPORTING_HISTORY_DOWNLOAD', // { item, daysPassed } daysPassed - how many days passed since file was formed to download date, item - stringified json

    navbarOpenToolsMenu = 'NAVBAR_OPEN_TOOLS_MENU',
    toolbarChangeProject = 'TOOLBAR_CHANGE_PROJECT', // { userProjects, action } userProjects - quantity of user's project, action - click on my projects or change project ('my projects' | 'change project')
    toolbarChangeView = 'TOOLBAR_CHANGE_VIEW', // { current, new } 'both' | 'icons' | 'text'

    changeFiltersVisibility = 'CHANGE_FILTERS_VISIBILITY',
    issueChatUnknownFileType = 'ISSUE_CHAT_UNKNOWN_FILE_TYPE',

    // Web Issue-Tracker
    itOpen = 'IT_OPEN', // it page opened
    dashboardTransitionToIt = 'DASHBOARD_TRANSITION_TO_IT', // going from dashboard to it
    itSwitchToFilters = 'IT_SWITCH_TO_FILTERS', // switching to filters panel after coming from dashboard
    itTransitionToDashboard = 'IT_TRANSITION_TO_DASHBOARD', // returning from it to dashboard
    itFiltersChange = 'IT_FILTERS_CHANGE', // filter changed by hand, { type } - filter type
    itPresetSelected = 'IT_PRESET_SELECTED', // preset selected by click, not on page load, { presetTitle }
    itPanelsVisibilityChange = 'IT_PANELS_VISIBILITY_CHANGE', // collapse or show panel { action = collapse/show, panel = presets, preview, chat, info }
    itIssueCopyLink = 'IT_ISSUE_COPY_LINK', // issue link copied to clipboard
    itCommentCopyLink = 'IT_COMMENT_COPY_LINK', // comment link copied to clipboard
    itMarkAllAsRead = 'IT_MARK_ALL_AS_READ', // button mark all as read clicked
    itOpenMulticommentFromList = 'IT_OPEN_MULTICOMMENT_FROM_LIST', // open multicomment by icon in issues list column, { count } - count of selected issues
    itOpenMulticommentFromChat = 'IT_OPEN_MULTICOMMENT_FROM_CHAT', // open multicomment by button in chat, { count } - count of selected issues
    itCloseMulticomment = 'IT_CLOSE_MULTICOMMENT', // close multicomment
    itChatSendMessage = 'IT_CHAT_SEND_MESSAGE', // sending message in chat { type = text/file/photo/multi, filesCount, filesTypes (joined string), hasText (for multicomment) }
    itOpenInApp = 'IT_OPEN_IN_APP', // click to transfer to app
    itIssueEdit = 'IT_ISSUE_EDIT', // any field of issue edited, { fieldName, issuesEdited } - name of the field or fields and count of edited fields.
    itUsedRecentUsers = 'IT_USED_RECENT_USERS', // used "recent users" in users select
    itPrintOneIssue = 'IT_PRINT_ONE_ISSUE', // print one issue
    itPrintMultipleIssues = 'IT_PRINT_MULTIPLE_ISSUES', // print multiple issues
    isOpenChatFilters = 'IS_OPEN_CHAT_FILTERS', // open chat filters
    isOpenChatSearch = 'IS_OPEN_CHAT_SEARCH', // open chat search
    isOpenPinnedComments = 'IS_OPEN_PINNED_COMMENTS', // open pinned comments, count - count of pinned comments
    pinComment = 'PIN_COMMENT', // pin comment
    unpinComment = 'UNPIN_COMMENT', // unpin comment
    searchComments = 'SEARCH_COMMENTS', // search comments, value - search value
    setChatFilter = 'SET_CHAT_FILTER', // filter - filter name, value - filter value
    resetChatFilters = 'RESET_CHAT_FILTERS', // reset chat filters

    itIssueCheck = 'IT_ISSUE_CHECK', // { method, count } // method - 'card' | 'checkbox' | 'header-checkbox' | 'header-menu'
    itIssueUnCheck = 'IT_ISSUE_UNCHECK', // { method } // method - 'card' | 'checkbox' | 'header-checkbox' | 'header-menu'
    ItPaginationUse = 'IT_PAGINATION_USE', // { pages, action } // pages - how many pages on pagination, action - 'arrow-next', 'arrow-prev', 'page-number'

    itPresetSettingsOpen = 'IT_PRESET_SETTINGS_OPEN',
    itPresetSettingsSave = 'IT_PRESET_SETTINGS_SAVE',
    itReorderPresetOpen = 'IT_REORDER_PRESET_OPEN',
    itReorderPresetApply = 'IT_REORDER_PRESET_APPLY',
    itReorderPresetCancel = 'IT_REORDER_PRESET_CANCEL',
    itSharePresetOpen = 'IT_SHARE_PRESET_OPEN',
    itSharePresetApply = 'IT_SHARE_PRESET_APPLY',
    itSharePresetCancel = 'IT_SHARE_PRESET_CANCEL',
    itDeletePresetOpen = 'IT_DELETE_PRESET_OPEN',
    itDeletePresetApply = 'IT_DELETE_PRESET_APPLY',
    itDeletePresetCancel = 'IT_DELETE_PRESET_CANCEL',

    // Notification Center
    ncOpen = 'NC_OPEN', // open notification center, { count, isShowUnreadOnly, issuesUpdatesUnread, reportsUnread, otherUnread  } - count of notification, isShowUnread flag activated, and count of unreads by tabs
    ncReadAll = 'NC_READ_ALL', // click "Read all" { tabName, count } tabName - tab which was read, count - how many messages was read
    ncToggleNew = 'NC_TOGGLE_NEW', // click "Show unread only"
    ncOpenIssue = 'NC_OPEN_ISSUE', // click "View issues",{ issuesCount } issuesCount - count of shown issues
    ncFilterChange = 'NC_FILTER_CHANGE', // filter changed by hand, { type } - filter type
    ncTabClick = 'NC_TAB_CLICK', // { tabName, processing, generated, error  } - tab which was clicked, processing/generated/error (only for reports tab) - how many reports is in every status by itself
    ncDownloadClick = 'NC_DOWNLOAD_CLICK', // { fileType } type of downloaded file 'report', 'dashboard', 'graph'
    ncViewVersionsClick = 'NC_VIEW_VERSIONS_CLICK',

    addLicenseMembers = 'ADD_LICENSE_MEMBERS',

    startRegistration = 'START_REGISTRATION',
    submitRegistration = 'SUBMIT_REGISTRATION',

    feedbackProjectDashboards = 'FEEDBACK_PROJECT_DASHBOARDS',
    feedbackIssueTracker = 'FEEDBACK_ISSUE_TRACKER',

    markupOpen = 'MARKUP_OPEN',
    markupDrawFigure = 'MARKUP_DRAW_FIGURE',
    markupChangeBackground = 'MARKUP_CHANGE_BACKGROUND',
    markupUndoRedo = 'MARKUP_UNDO_REDO',
    markupClose = 'MARKUP_CLOSE',

    manageUsersOpen = 'MANAGE_USERS_OPEN',
    approvalListOpen = 'APPROVAL_LIST_OPEN',
    approvalListUserProjectsViewing = 'APPROVAL_LIST_USER_PROJECTS_VIEWING',
    approveUsersConfirmation = 'APPROVE_USERS_CONFIRMATION',
    rejectUsersConfirmation = 'REJECT_USERS_CONFIRMATION',
    authMethodsOpen = 'AUTH_METHODS_OPEN',
    authMethodsSwitchActivity = 'AUTH_METHODS_SWITCH_ACTIVITY',
    authMethodDeletion = 'AUTH_METHOD_DELETION',
    authMethodSetDefault = 'AUTH_METHOD_SET_DEFAULT',
    authMethodEdit = 'AUTH_METHOD_EDIT',
    authMethodSave = 'AUTH_METHOD_SAVE',

    showSelectedUsers = 'SHOW_SELECTED_USERS',

    // Access Roles page
    accessRolesOpen = 'ACCESS_ROLES_OPEN', // { rolesCount } quantity of roles on open.
    accessRolesFiltersChange = 'ACCESS_ROLES_FILTERS_CHANGE', // filter changed, { type } - filter type.
    accessRolesNewRole = 'ACCESS_ROLES_NEW_ROLE', // { action } 'open' - open popup, 'save' - save new role.
    accessRolesDeleteRole = 'ACCESS_ROLES_DELETE_ROLE', // { projects, users } how many projects and how many users will involved.
    accessRolesCompareRolesOpen = 'ACCESS_ROLES_COMPARE_ROLES_OPEN', // { rolesCount } how many roles are whosen to compare.
    accessRolesCompareRolesChange = 'ACCESS_ROLES_COMPARE_ROLES_CHANGE', // { action } 'add' | 'change' | 'remove'.
    accessRolesCompareRolesChangeFlag = 'ACCESS_ROLES_COMPARE_ROLES_CHANGE_FLAG', // { value } true - on, false - off.
    accessRolesCompareRolesEdit = 'ACCESS_ROLES_COMPARE_ROLES_EDIT',

    // Access Roles common
    accessRoleInvolvedUsersOpen = 'ACCESS_ROLE_INVOLVED_USERS_OPEN',
    accessRolesInvolvedProjectsOpen = 'ACCESS_ROLE_INVOLVED_PROJECTS_OPEN',
    accessRolesInvolvedProjectsGoToProject = 'ACCESS_ROLE_INVOLVED_PROJECTS_GO_TO_PROJECT',

    // Access Role detail page
    accessRoleOpen = 'ACCESS_ROLE_OPEN', // Open access role detail page
    accessRoleChange = 'ACCESS_ROLE_CHANGE', // Edit access role { action } 'rename', 'change flags'
    accessRoleSave = 'ACCESS_ROLE_SAVE',
    accessRoleCreate = 'ACCESS_ROLE_CREATE', // Create new access role

    stampOpen = 'STAMP_OPEN',
    stampDemo = 'STAMP_ADD_DEMO',
    stampAddOpen = 'STAMP_ADD_WINDOW_OPEN',
    stampAdd = 'STAMP_ADD',
    stampAddCategory = 'STAMP_ADD_CATEGORY',
    stampCloneCategory = 'STAMP_CLONE_CATEGORY',
    stampCloneCategoryOpen = 'STAMP_CLONE_CATEGORY_WINDOW_OPEN',
    stampRemoveCategory = 'STAMP_REMOVE_CATEGORY',
    stampClone = 'STAMP_CLONE',
    stampCloneOpen = 'STAMP_CLONE_OPEN',
    stampRemove = 'STAMP_REMOVE',
    stampSelect = 'STAMP_SELECT',
    stampUnselect = 'STAMP_UNSELECT',
    stampUpdate = 'STAMP_UPDATE',
    stampUpdateCategory = 'STAMP_UPDATE_CATEGORY',
    stampUpdateRegularIssues = 'STAMP_UPDATE_REGULAR_ISSUES',
    tagsOpen = 'TAG_OPEN',
    tagsAdd = 'TAG_ADD',
    tagsSelect = 'TAG_SELECT',
    tagsUnselect = 'TAG_UNSELECT',
    tagsRemove = 'TAG_REMOVE',
    tagsRenameStart = 'TAG_RENAME_START',
    tagsRenameEnd = 'TAG_RENAME_END',

    helpCenterClick = 'HELP_CENTER_CLICK', // click on help center link in header, { page } - page click was triggered from.

    openLicenseLogs = 'OPEN_LICENSE_LOGS',
    openProjectLogs = 'OPEN_PROJECT_LOGS',

    openPopupDownloadTemplate = 'OPEN_POPUP_DOWNLOAD_TEMPLATE',
    closePopupDownloadTemplate = 'CLOSE_POPUP_DOWNLOAD_TEMPLATE',
    downloadTemplate = 'DOWNLOAD_TEMPLATE',
    closeConfirmPopupDownloadTemplate = 'CLOSE_CONFIRM_POPUP_DOWNLOAD_TEMPLATE',
    forceLinkConfirmPopupDownloadTemplate = 'FORCE_LINK_CONFIRM_POPUP_DOWNLOAD_TEMPLATE',

    sessionReplayInitialized = 'SESSION_REPLAY_INITIALIZED',
}
