import { enumToObject } from '@/services';
import { IssueDirections, IssueSort, IssueSorts, IssueSortsApi } from '@/domain/issue/constants/IssueSort';
import { FilterType } from '@/domain/issue/constants/FilterType';

interface ISortingApi {
    [IssueSortsApi.Primary]: {
        Type: number,
        IsAscending: boolean,
    };
    [IssueSortsApi.Secondary]: {
        Type: number,
        IsAscending: boolean,
    };
    [IssueSortsApi.UnreadFirst]?: boolean;
}

export interface ISorting {
    [IssueSorts.primary]: {
        direction: string,
        field: string,
    };
    [IssueSorts.secondary]: {
        direction: string,
        field: string,
    };
    [IssueSorts.unread]?: boolean;
}

export const IssueOrderParse = (sort: ISortingApi): ISorting => {
    const result: ISorting = {} as any;

    if (sort[IssueSortsApi.Primary]) {
        result[IssueSorts.primary] = {
            [IssueSort.field]: FilterType[sort[IssueSortsApi.Primary].Type].toLowerCase(),
            [IssueSort.direction]: sort[IssueSortsApi.Primary].IsAscending ? IssueDirections.ASC : IssueDirections.DESC,
        };
    }

    if (sort[IssueSortsApi.Secondary]) {
        result[IssueSorts.secondary] = {
            [IssueSort.field]: FilterType[sort[IssueSortsApi.Secondary].Type].toLowerCase(),
            [IssueSort.direction]: sort[IssueSortsApi.Secondary].IsAscending ? IssueDirections.ASC : IssueDirections.DESC,
        };
    }

    if (sort[IssueSortsApi.UnreadFirst]) {
        result[IssueSorts.unread] = true;
    }

    return result;
};

const FilterTypeObj = enumToObject(FilterType);

export const IssueOrderEncode = (sorting: ISorting): ISortingApi => ({
    [IssueSortsApi.Primary]: {
        Type: FilterTypeObj[sorting[IssueSorts.primary][IssueSort.field]],
        IsAscending: sorting[IssueSorts.primary][IssueSort.direction] === IssueDirections.ASC,
    },
    [IssueSortsApi.Secondary]: {
        Type: FilterTypeObj[sorting[IssueSorts.secondary][IssueSort.field]],
        IsAscending: sorting[IssueSorts.secondary][IssueSort.direction] === IssueDirections.ASC,
    },
    [IssueSortsApi.UnreadFirst]: sorting[IssueSorts.unread],
});
