<template>
    <span>
        <WsTooltip :tooltip="tooltipContent" :disabled="!tooltipContent">
            <WsButton
                :disabled="disabled"
                :icon="btnIcon"
                :round="round"
                :size="size"
                :type="type"
                :outlined="false"
                :variant="variant"
                :new="newButton"
                @click="click"
            >
                <slot name="icon">
                    <IconSvg16
                        v-if="icon"
                        :color="iconColor"
                        :color-active="iconColorActive"
                        :disabled="disabled"
                        :icon-name="icon"
                        inline
                        parent-hover
                        stateless
                    />
                </slot>
                <slot />
            </WsButton>
        </WsTooltip>
    </span>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import { ButtonVariant } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        WsTooltip,
        WsButton,
        IconSvg16,
    },
})
export default class WsTableButton extends Vue {
    @Prop() public text!: VueI18n.TranslateResult;
    @Prop() public tooltip!: VueI18n.TranslateResult; // appear if button enabled
    @Prop() public tooltipIfDisabled!: VueI18n.TranslateResult; // appear if button disabled
    @Prop({ type: Boolean }) public tooltipStable!: boolean; // this.tooltip should appear every time, ignore tooltipIfDisabled
    @Prop() public icon!: string; // IconSvg16
    @Prop() public iconColor!: string; // IconSvg16 color
    @Prop({ default: '#FFFFFF' }) public iconColorActive!: string; // IconSvg16 color active
    @Prop() public btnIcon!: string;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ default: 400 }) public openDelay!: number;
    @Prop({ default: false, type: Boolean }) public round!: boolean;
    @Prop({ default: 'default' }) public type!: string;
    @Prop({ default: 'default' }) public size!: 'large' | 'small' | 'default';
    @Prop({ default: ButtonVariant.SECONDARY }) public variant!: ButtonVariant;
    @Prop({ default: false }) public newButton!: boolean;

    @Emit()
    public click() {
        return;
    }

    get tooltipContent() {
        if (this.tooltipStable) {
            return this.tooltip;
        }

        return this.disabled ? this.tooltipIfDisabled : this.tooltip;
    }
}
</script>

<style lang="scss" scoped>

::v-deep button.ws-button {
    display: flex;
    align-items: center;

    & > span {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
</style>
