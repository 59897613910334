<template>
    <div>
        <div v-if="isImage">
            <WsImage
                :id="`src${comment.uuid}`"
                :src="comment.preview.original"
                :width="263"
                :height="174"
                class="issue-tracker-preview"
                @load="onLoadImage"
                @error="onLoadImage"
                @click="handleImageClick"
            />
            <div v-if="isMarkup" class="suppressed">
                <template v-if="comment.isFromClashSync">
                    {{ $t((commentIndex > 0) ? 'User.notifications.events.markup_changed_clashSync': 'User.notifications.events.markup_created_clashSync') }}
                </template>
                <template v-else>
                    {{ $t((commentIndex > 0) ? 'User.notifications.events.markup_changed': 'User.notifications.events.markup_created') }}
                </template>
            </div>
        </div>

        <template v-else>
            <template v-if="comment.files">
                <IssueCommentFileItem
                    v-for="(filename, index) in comment.files"
                    :filename="filename"
                    :is-pinned-format="isPinnedFormat"
                    :url="getFileUrl(comment.uuid)"
                    :key="index"
                    @click="click"
                />
            </template>
            <template v-else>
                <IssueCommentFileItem
                    v-if="comment.filename"
                    :filename="comment.filename"
                    :is-pinned-format="isPinnedFormat"
                    :url="getFileUrl(comment.uuid)"
                    @click="click"
                />
            </template>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IssueCommentFileItem from '@/domain/comment/components/IssueCommentFileItem.vue';
import { FileComment } from '@/domain/comment/models/FileComment';
import { MarkupComment } from '@/domain/comment/models/MarkupComment';
import { Issue } from '@/domain/issue/models/Issue';
import { AbstractComment } from '@/domain/comment/models/AbstractComment';

@Component({
    components: {
        IssueCommentFileItem,
    },
})
export default class IssueCommentFile extends Vue {
    @Prop({ required: true }) public comment!: FileComment | MarkupComment;
    @Prop({ required: true }) public commentIndex!: number;
    @Prop({ default: false, type: Boolean }) public isPinnedFormat!: boolean;

    get isMarkup() {
        return this.comment instanceof MarkupComment;
    }

    get isImage() {
        return this.comment?.mimetype?.startsWith('image/');
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get currentIssueComments(): AbstractComment[] {
        return this.currentIssue
            ? this.$store.getters.commentsByIssue(this.projectId, this.currentIssue.uuid)
            : [];
    }

    @Emit()
    public showImage(comment: FileComment | MarkupComment) {
        return comment;
    }

    @Emit()
    public click() {
        return;
    }

    @Emit('img-loaded')
    public imageLoaded() {
        return;
    }

    public getFileUrl(commentUuid: string) {
        const commentElement = this.currentIssueComments.find((comment: AbstractComment | FileComment) => comment.uuid === commentUuid);
        return (commentElement as FileComment).preview?.original;
    }

    public handleImageClick() {
        this.showImage(this.comment);
    }

    public onLoadImage() {
        this.imageLoaded();
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.issue-tracker-preview {
    overflow: hidden;

    ::v-deep .ws-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    &:hover {
        cursor: zoom-in;
    }
}
</style>
