<template>
    <v-switch
        v-model="model"
        :label="label"
        :dense="dense"
        :flat="flat"
        :disabled="disabled"
        :hide-details="hideDetails"
        :ripple="false"
        :class="size"
    />
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

// https://vuetifyjs.com/en/api/v-switch/

type acceptableValue = boolean | 0 | 1 | string[];
type ISwitchSize = 'medium' | 'small';

@Component
export default class WsSwitch extends Vue {
    @Prop({ required: true }) public value!: acceptableValue;
    @Prop({ type: Boolean, default: false }) public dense!: boolean;
    @Prop({ type: Boolean, default: true }) public flat!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop() public hideDetails!: boolean | string;
    @Prop({ default: 'small' }) public size!: ISwitchSize;
    @Prop() public label!: TranslateResult;

    @Emit()
    public input(value: acceptableValue) {
        return value;
    }
    @Emit()
    public change(value: acceptableValue) {
        return value;
    }

    get model() {
        return this.value;
    }
    set model(value: acceptableValue) {
        this.input(value);
        this.change(value);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-input--selection-controls {
    margin: 0;
}
.v-input--switch {
    
    --width: 48px;
    --height: 24px;
    --offset: -12px;

    &.small {
        --width: 32px;
        --height: 16px;
        --offset: -8px;
    }

    ::v-deep .v-input__slot  {
        // I'm not using our a11y mixin because it isn't working with variables
        position: relative;
        z-index: 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            cursor: inherit;
            margin: var(--offset) 0;
        }
    }

    ::v-deep .v-input--selection-controls__input {
        height: var(--height);
        width: var(--width);
        margin-right: 0;
    }

    ::v-deep .v-label {
        color: $black-secondary;
        margin-left: 10px;
    }

    ::v-deep .v-input--switch__track {
        height: var(--height);
        width: var(--width);
        border-radius: 16px;
        border: 1px solid $light-solid-80;
        background-color: $white;
        top: 0;
        left: 0;
        opacity: 1;
    }

    ::v-deep .v-input--switch__thumb {
        width: var(--height);
        height: var(--height);
        background-color: $white;
        top: 0;
        left: 0;
        border: 1px solid $light-solid-80;
    }

    &.v-input--is-label-active {
        ::v-deep .v-input--switch__track {
            border: 1px solid $light-accent;
            background-color: $light-accent;
        }

        ::v-deep .v-input--switch__thumb {
            border-color: $light-accent;
            transform: translate(var(--height), 0) !important;
        }
    }

    &.v-input--is-disabled {
        &.v-input--is-label-active {
            ::v-deep .v-input--switch__track {
                background-color: $light-solid-40;
            }

        }
        ::v-deep .v-input--switch__track {
            border: 1px solid $light-solid-40;
        }

        ::v-deep .v-input--switch__thumb {
            border-color: $light-solid-40;
        }
    }
}
</style>
