<template>
    <WsTooltip :disabled="isTooltipDisabled" :tooltip="email">
        <span class="activator amp-mask">
            {{ fullname }}
        </span>
    </WsTooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LicenseMember, ProjectMember } from '@/models';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
    },
})
export default class WsMemberName extends Vue {
    @Prop({ required: true }) public email!: string;
    @Prop({ type: Boolean, default: false }) public tooltip!: boolean;

    get isTooltipDisabled(): boolean {
        return !this.tooltip || this.email === this.fullname;
    }

    get projectId(): string {
        return this.$route.params.projectId;
    }

    get licenseId(): string {
        return this.$route.params.licenseId;
    }

    get licenseMember(): LicenseMember | undefined {
        return this.$store.getters.licenseMemberByEmail(this.email, this.licenseId);
    }

    get projectMember(): ProjectMember | undefined {
        return this.$store.getters.projectMemberByEmail(this.email, this.projectId);
    }

    get member(): LicenseMember | ProjectMember | undefined {
        return this.licenseMember ?? this.projectMember;
    }

    get fullname() {
        return this.member?.fullname.trim() || this.email;
    }
}
</script>

<style lang="scss" scoped>
.activator {
    cursor: pointer;
}
</style>
