<template>
    <div v-if="status" class="project-custom-status custom-status" :style="{ background: status.backgroundColor, color: status.textColor }">
        <WsTooltip :tooltip="status.description" amp-mask>
            {{ status.name }}
        </WsTooltip>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import WsTooltip from '@/components/common/WsTooltip.vue';
import { CustomStatus } from '@/domain/customStatus/models/CustomStatus';

@Component({
    components: {
        WsTooltip,
    },
})
export default class CustomStatusItem extends Vue {
    @Prop({ type: Object, required: true }) public status!: CustomStatus;

}
</script>

<style scoped lang="scss">
@import '@/styles/wsui/colors';
@import '@/styles/wsui/const';
@import '@/styles/variables';
@import '@/styles/tables';
@import '@/styles/mixins';

.custom-status {
  height: 24px;
  display: inline-block;
  padding: 0 10px;
  border-radius: 15px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  color: white;
  overflow: hidden;
}

</style>
