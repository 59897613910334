<template>
    <v-app>
        <template v-if="!isLoadingRegions">
            <Header class="header-area" />
            <div class="content-area">
                <router-view class="content-wrapper" />
            </div>
        </template>

        <WsDialog
            v-if="isLoginToLicenseNeeded && !license.isBlocked"
            :title="$t('Login.licenseLogin')"
            width="400px"
            @close="isLoginToLicenseNeeded = false"
        >
            <LoginMergeForm
                :license="license"
                :pathname-to-redirect="pathnameToRedirect"
                :close="() => isLoginToLicenseNeeded = false"
            />
        </WsDialog>

        <StepsDialog v-if="isShowBoardingSteps" />
        <WelcomeDialog v-if="isShowBoardingWelcome" />
        <WsNotifier />
    </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { User } from '@/types/User';
import Router from '@/router';
import { BusEvent, ButtonVariant, RELEASE_NOTES_TOAST_ID, RouterNames, TimezoneLocalStorageKey } from '@/constants';
import UserApi from '@/api/user.api';
import authHttp from '@/api/authHttp';
import http from '@/api/http';
import { License } from '@/models';
import { toastInfo } from '@/services';
import { eventBus } from '@/services/eventBus';
import { getTimezoneOffset, notificationInfo, toastError, transformTimezoneToTranslation } from '@/services';
import { IBrowserTimezone } from '@/storage/auth.storage';
import Header from '@/components/header/Header.vue';
import LoginMergeForm from '@/components/user/LoginMergeForm.vue';
import WsDialog from '@/components/common/WsDialog.vue';
import StepsDialog from '@/components/user/boarding/StepsDialog.vue';
import WelcomeDialog from '@/components/user/boarding/WelcomeDialog.vue';
import WsNotifier from '@/components/common/Notifier/WsNotifier.vue';

@Component({
    components: {
        Header,
        LoginMergeForm,
        WsDialog,
        WelcomeDialog,
        StepsDialog,
        WsNotifier,
    },
})
export default class App extends Vue {
    public isLoginToLicenseNeeded = false;
    public pathnameToRedirect = '';

    get userData(): User {
        return this.$store.getters.userData;
    }

    get isLoadingRegions() {
        return this.$store.getters.isLoadingRegions;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get licenses(): License[] {
        return this.$store.getters.licenses;
    }

    get isShowBoardingWelcome(): boolean {
        return this.$store.getters.isShowBoardingWelcome;
    }

    get isShowBoardingSteps(): boolean {
        return this.$store.getters.isShowBoardingSteps;
    }

    get lastReleaseNotesTimeStamp(): number {
        return this.$store.getters.lastReleaseNotesTimeStamp;
    }

    get lastViewedUpdateAtTimeStamp() {
        return new Date(this.userData?.features?.lastViewedUpdateAt).getTime() || 0;
    }

    get isReleaseNotesRoute() {
        return this.$route.name === RouterNames.ReleaseNotes;
    }

    get browserTimeZone(): IBrowserTimezone {
        return this.$store.getters.browserTimezone;
    }

    @Watch('license')
    public onNewLicense(license: License, oldLicense: License) {
        if (oldLicense.id && license.id !== oldLicense.id) {
            this.$store.commit('setPreviousLicenseId', oldLicense.id);
        }
        if (!license.isValidAuth && license.id) {
            this.pathnameToRedirect = location.pathname;
            VueCookies.set('pathnameToRedirect', this.pathnameToRedirect);
            VueCookies.set('pathnameLicenseId', String(license.id));
            this.isLoginToLicenseNeeded = true;
        } else {
            this.isLoginToLicenseNeeded = false;
        }
    }

    @Watch('userData')
    public onUserDataChange(user: User, oldUser: User) {
        if (user.uuid !== oldUser.uuid && !this.userData.autoUpdateTimezone) {
            this.checkTimeZone();
        }
    }

    public created() {
        http.setLogoutCallback(() => {
            this.$store.dispatch('logout', { isGoToLogin: true });
        });
        authHttp.setLogoutCallback(() => {
            this.$store.dispatch('logout', { isGoToLogin: true });
        });
        
        this.handle2FaRequired();

        eventBus.$on(BusEvent.requestCopyToBuffer, this.copyToBuffer);
        this.setBrowserTimezone();

        eventBus.$on(BusEvent.showReleaseNotesNotification, this.checkReleaseNotes);

        // Release notes notifications logic
        this.$router.onReady(() => {
            eventBus.$emit(BusEvent.showReleaseNotesNotification);
        });
    }

    public checkReleaseNotes() {
        if (!this.userData) {
            return;
        }

        if (this.lastReleaseNotesTimeStamp > this.lastViewedUpdateAtTimeStamp) {
            if (this.isReleaseNotesRoute) {
                UserApi.markViewedIntro();
                return;
            }
            toastInfo(
                this.$t('ReleaseNotes.toastMessage'),
                {
                    duration: Infinity,
                    name: RELEASE_NOTES_TOAST_ID,
                    onCloseAction: UserApi.markViewedIntro,
                },
            );
        }
    }

    public checkTimeZone() {
        const userTimezone =  this.userData.timezone.timezone;
        const localStorageTime = localStorage.getItem(TimezoneLocalStorageKey);

        const browserTimezoneTranslation = transformTimezoneToTranslation(this.browserTimeZone.timezone, this.browserTimeZone.offset);

        if (this.browserTimeZone.timezone !== userTimezone && this.browserTimeZone.timezone !== localStorageTime) {
            notificationInfo({
                title: this.$t('Timezone.dialog.title'),
                text: this.$t('Timezone.dialog.content', { region: browserTimezoneTranslation }),
                duration: Infinity,
                actions: [
                    {
                        text: this.$t('Timezone.dialog.correct'),
                        callback: () => {
                            const payload = {
                                firstname: this.userData.firstname,
                                lastname: this.userData.lastname,
                                email: this.userData.email,
                                timeFormat: this.userData.timeFormat,
                                dateFormat: this.userData.dateFormat,
                                timezone: this.browserTimeZone.timezone,
                            };

                            this.$store.dispatch('uploadUserData', payload).then(() => {
                                this.$store.dispatch('downloadUserData', true);
                            }).catch((error) => {
                               toastError(error || this.$t('errors.server_error'));
                            });
                        },
                    },
                    {
                        text: this.$t('Timezone.dialog.changeIt'),
                        variant: ButtonVariant.SECONDARY,
                        callback: () => {
                            this.$router.push({ name: RouterNames.UserSettings });
                        },
                    },
                ],
                onCloseAction: () => {
                    localStorage.setItem(TimezoneLocalStorageKey, this.browserTimeZone.timezone);
                },
            });
        }
    }

    public setBrowserTimezone() {
        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const browserTimeZoneOffset = getTimezoneOffset();
        
        this.$store.commit('setBrowserTimezone', {
            timezone: browserTimeZone,
            offset: browserTimeZoneOffset,
        });
    }

    public handle2FaRequired() {
        eventBus.$on(BusEvent.faRequired, () => {
            Router.push({
                name: RouterNames.UserSecurity,
                params: {
                    show2FAPopup: 'true',
                },
            });
        });
    }

    public copyToBuffer(content: string) {
        navigator.clipboard.writeText(content);
    }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/icons.scss';
@import '@/styles/mixins.scss';
@import '@/styles/fonts.scss';
@import '@/styles/styleFixes.scss';
@import '@/styles/main.scss';
@import '@/styles/vuetify-overrides.scss';
@import '@/styles/auth.scss';
@import '@/styles/loading.scss';

.login-merge-dialog {
    z-index: 2022 !important;
}

</style>
