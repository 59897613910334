<template>
    <div class="component-password-must-contain password-must-contain">
        <div :class="{ active: requirements.areMet }">
            {{ $t(`Login.${requirements.topLineTranslationKey}`) }}
        </div>
        <ul>
            <li
                v-for="({ text, classObject, key }) in requirements.list"
                :key="key"
                :class="classObject"
                class="requirement"
            >
                <div class="bullet">{{ bullet }}</div>
                <div>{{ text }}</div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { PasswordSpec } from '@/constants';
import { getPasswordRequirements } from '@/models';
import { PasswordContains } from '@/services';

@Component
export default class PasswordMustContain extends Vue {
    @Prop({ type: String, default: '' }) public password!: string;

    public readonly bullet = String.fromCharCode(0x2B24);

    public isPasswordChanged = false;

    get requirements() {
        const passwordContains = new PasswordContains(this.password);
        const PasswordRequirements = getPasswordRequirements(this.isPasswordChanged);

        return new PasswordRequirements([
            [
                this.$t('Login.atLeastMCharsNotMoreThanNChars', {
                    m: PasswordSpec.MinimumLength,
                    n: PasswordSpec.MaximumLength,
                }),
                passwordContains.atLeastMCharsNotMoreThanNChars,
            ],
            [
                this.$t('Login.oneLowercase'),
                passwordContains.atLeastOneLowercaseLetter,
            ],
            [
                this.$t('Login.oneUppercase'),
                passwordContains.atLeastOneUppercaseLetter,
            ],
            [
                this.$t('Login.aNumber'),
                passwordContains.atLeastOneNumber,
            ],
        ]);
    }

    @Emit('is-valid-password')
    @Watch('password')
    public onPasswordChange() {
        this.isPasswordChanged = true;
        return this.requirements.areMet;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.password-must-contain {
    color: $default-black;
    margin: 15px 0 40px 10px;
    .requirement {
        margin-top: 3px;
    }
}
.active {
    color: $primary-green;
}
.failed {
    color: $primary-red;
}
ul {
    padding-left: 7px;

    li {
        display: flex;
        align-items: center;

        .bullet {
            font-size: 10px;
            margin-right: 6px;
        }
    }
}
</style>
