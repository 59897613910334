<template>
    <v-checkbox
        v-model="model"
        :indeterminate="indeterminate"
        :value="value"
        :label="label"
        :error-count="maxMessages"
        :messages="messages"
        :error-messages="errorMessages"
        :success-messages="successMessages"
        :error="error"
        :rules="rules"
        :hide-details="hideDetails"
        :dense="dense"
        :required="required"
        :placeholder="placeholder"
        :clearable="clearable"
        :disabled="disabled"
        :false-value="numbers ? 0 : false"
        :true-value="numbers ? 1 : true"
        :class="customClasses"
        @change="change"
        @input="input"
    >
        <template #label>
            <slot name="label" />
        </template>
    </v-checkbox>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class WsCheckbox extends VuetifyElement {
    // @ts-ignore
    @Prop({ type: Boolean, default: false }) public dense!: boolean;
    // @ts-ignore
    @Prop({ default: 0 }) public maxMessages!: number;
    @Prop({ type: Boolean, default: false }) public numbers!: boolean;
    @Prop() public indeterminate!: boolean;
    @Prop({ default: () => [] }) public customClasses!: string[];
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-input {
    ::v-deep .v-messages__message {
        line-height: 1.4;
    }

    ::v-deep .v-input__control {
        width: auto;
        flex-grow: 0;
    }
}
.v-input.v-input--is-disabled {
    ::v-deep .v-input__control .v-input__slot .v-input--selection-controls__input i {
        color: $disable-gray !important;
    }
}
</style>
