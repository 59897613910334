<template>
    <div class="project-dropdown">
        <HeaderSelect
            :title="$t('Simple_word.project')"
            :activator-text="activeProject.title || $t('Menu.projects')"
            data-test="mnb-projects-list"
            close-on-list-click
            amp-hide-active-title
        >
            <template #header>
                <div class="project-dropdown-header">
                    <WsInputSearch v-model="searchQuery" />
                </div>
            </template>
            <template #list>
                <WsLazyRender :items="projectsToShow">
                    <template #default="{ items: projects }">
                        <v-list-item
                            v-for="project in projects"
                            :key="project.id"
                            :value="project.id"
                            @click="onProjectItemClick"
                        >
                            <ProjectsDropdownItem
                                :project="project"
                                :is-active="project.uuid === activeProject.uuid"
                            />
                        </v-list-item>
                    </template>
                </WsLazyRender>

                <v-list-item v-if="isEmptySearch" disabled>
                    <div class="project-dropdown-empty">
                        {{ $t('errors.search_no_matches') }}
                    </div>
                </v-list-item>
            </template>
        </HeaderSelect>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AmplitudeEvent, RouterNames } from '@/constants';
import { License, Project } from '@/models';
import { amplitudeLog, selectProjectsSort, truncateString } from '@/services';
import HeaderSelect from '@/components/header/HeaderSelect.vue';
import ProjectsDropdownItem from '@/components/header/ProjectsDropdownItem.vue';
import WsInputSearch from '@/components/common/WsInputSearch.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';

@Component({
    components: {
        HeaderSelect,
        ProjectsDropdownItem,
        WsInputSearch,
        WsLazyRender,
    },
    methods: {
        truncateString,
    },
})
export default class ProjectsDropdown extends Vue {
    @Prop({ required: true }) public activeProject!: Project;

    public readonly RouterNames = RouterNames;
    public searchQuery = '';

    get language() {
        return this.$route.params.language;
    }

    get isNoOtherProjects() {
        return !this.projectsToShow.length && !this.searchQuery.length;
    }

    get isEmptySearch() {
        return !this.projectsToShow.length && this.searchQuery.length;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get licenseId(): number | null {
        return this.$store.getters.currentLicenseId;
    }

    get projectsToShow() {
        // Set same sorting as "my projects" table has by default, and active project goes to top.
        return selectProjectsSort(this.myProjects, this.activeProject.id).filter(Project.has(this.searchQuery));
    }

    get myProjects(): Project[] {
        return this.license.isFrozen
            ? this.$store.getters.myProjectsSuspended(this.license)
            : this.$store.getters.myProjectsOperational(this.license);
    }

    public onProjectItemClick() {
        amplitudeLog(AmplitudeEvent.toolbarChangeProject, { userProjects: this.myProjects.length, action: 'change project' });
    }

    public created() {
        this.$store.dispatch('loadLicenseMyProjects', { license: this.license, withIssueCounters: true });
    }

}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.project-dropdown-header {
    padding: 0 20px 10px;
}

.project-dropdown-empty {
    padding: 0 20px;
}

::v-deep {
    .project-preview {
        max-height: 60px;
        overflow-y: hidden;
    }
}

</style>
