<template>
    <div ref="presetList" class="preset-list">
        <WsSkeletonLoader v-if="isLoadingIssueFilterPresets || !isAllowLoadingContent" content="tracker-presets" />
        <Draggable
            v-else
            v-model="issueFilterPresetsUuids"
            :disabled="presetAction !== PresetAction.reorder"
            :move="checkMove"
            handle=".handle"
            @start="onDragStart"
            @end="onDragEnd"
        >
            <div
                v-for="(filterPreset, index) in issueFilterPresets"
                :key="filterPreset.uuid"
                :id="getFilterPresetIdByUuid(filterPreset.uuid)"
                :class="{ handle: isPresetRegular(filterPreset.uuid) && presetAction === PresetAction.reorder }"
                class="filter-preset"
            >
                <a
                    class="filter-preset-item"
                    :class="{
                        'filter-preset-item-last': isPresetDeleted(index),
                        'filter-preset-item-second': index === 1,
                        'selected-preset': isSelected(filterPreset),
                        'dragged-preset': index === draggedIndex,
                        grab: presetAction === PresetAction.reorder
                    }"
                    :href="getResolvedUrlForPreset(filterPreset)"
                    @click="onClickPresetItem($event, filterPreset)"
                >
                    <span
                        v-if="isPresetDraggable(filterPreset)"
                        class="drag-icon-wrapper"
                    >
                        <v-icon size="medium">menu</v-icon>
                    </span>

                    <div class="title-wrapper">
                        <WsTooltip
                            :disabled="!isTitleTruncatedArr[index]"
                            :tooltip="filterPreset.title"
                            :amp-mask="isPresetRegular(filterPreset)"
                        >
                            <template #activator="{ on }">
                                <div
                                    v-on="on"
                                    :class="{ 'amp-mask': isPresetRegular(filterPreset) }"
                                    class="filter-preset-title"
                                >
                                    {{ filterPreset.title }}
                                </div>
                            </template>
                        </WsTooltip>
                    </div>

                    <div class="preset-icons">
                        <WsTooltip>
                            <template #activator="{ on }">
                                <span
                                    v-if="[BrokenPresetEnum.brokenOnFrontend, BrokenPresetEnum.brokenInApp].includes(filterPreset.broken)"
                                    v-on="on"
                                    class="filter-preset-icon"
                                >
                                    <IconSvg24
                                        icon-name="notification-attention"
                                        :color="Color.primaryYellow"
                                        stateless
                                        passive
                                    />
                                </span>
                            </template>

                            <template #tooltip>
                                <span v-if="filterPreset.broken === BrokenPresetEnum.brokenOnFrontend">
                                    {{ $t('IssueTracker.presetBrokenOnFrontend') }}
                                </span>
                                <span v-else-if="filterPreset.broken === BrokenPresetEnum.brokenInApp">
                                    {{ $t('IssueTracker.presetBrokenInApp') }}
                                </span>
                            </template>
                        </WsTooltip>

                        <WsTooltip v-if="presetsNotifications[filterPreset.uuid] && presetsNotifications[filterPreset.uuid].hasDeletedStatus">
                            <template #activator="{ on }">
                                <span
                                    v-on="on"
                                    class="filter-preset-icon"
                                >
                                    <IconSvg24
                                        icon-name="notification-attention"
                                        :color="Color.primaryYellow"
                                        stateless
                                        passive
                                    />
                                </span>
                            </template>

                            <template #tooltip>
                                <span>
                                    {{ $t('IssueTracker.presetHasDeletedStatus') }}
                                </span>
                            </template>
                        </WsTooltip>

                        <span v-if="filterPreset.visibility" class="filter-preset-icon">
                            <IconSvg24
                                icon-name="sharing-busts"
                                :color="Color.mediumGray"
                                stateless
                                passive
                            />
                        </span>
                    </div>

                    <div class="preset-icons">
                        <span v-if="allUnreadIssues && filterPreset.isAllowShowCounts" class="filter-preset-icon">
                            <span class="count">{{ allUnreadIssues }}</span>
                        </span>
                        <template v-if="isAllowShowUnreadMarker(filterPreset)">
                            <span class="filter-preset-icon" v-if="presetsNotifications[filterPreset.uuid] && presetsNotifications[filterPreset.uuid].hasUnread">
                                <span class="green-icon" />
                            </span>
                        </template>
                    </div>
                    <WsTooltip
                        v-if="isPresetWithCheckbox(filterPreset)"
                        :disabled="!presetCheckbox(filterPreset).tooltip"
                        :tooltip="presetCheckbox(filterPreset).tooltip"
                    >
                        <WsCheckbox
                            :value="isCheckedPresetObj[filterPreset.uuid]"
                            :disabled="presetCheckbox(filterPreset).isDisabled"
                            @click.native.stop.prevent
                            @change="clickCheck(filterPreset)"
                        />
                    </WsTooltip>
                </a>

                <template v-if="index === 0 && draftCount">
                    <div class="filter-preset filter-preset-item-first">
                        <div
                            class="filter-preset-item"
                            :class="{ 'selected-preset': isDraftSelected }"
                            @click="showDrafts"
                        >
                            <span class="filter-preset-title">{{ $t('IssueTracker.presets.drafts') }}</span>
                            <div />
                            <div class="filter-preset-icon">
                                <span class="count">{{ draftCount }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </Draggable>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { Dict } from '@/types/Dict';
import { TUuid } from '@/types/common';
import Router from '@/router';
import {
    AmplitudeEvent,
    BusEvent,
    Color,
    DRAFT_KEY,
} from '@/constants';
import { Project } from '@/models';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, isOverflownElementVertically } from '@/services';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import { filterIssues } from '@/domain/issue/storage/projectIssues.storage';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import { IIssueFilterPresetUnreadStat } from '@/domain/issuePreset/storage/projectIssueFilterPreset.storage';
import { IssueTrackerFilterValue } from '@/domain/issueFilter/services/IssueTrackerFilterValue';
import { BrokenPresetEnum } from '@/domain/issuePreset/constants/BrokenPresetEnum';
import {
    allIssuesPresetUuid,
    currentIssuesPresetUuid,
    deletedIssuesPresetUuid,
} from '@/domain/issuePreset/constants/FilterPreset';
import { PresetAction } from '@/domain/issuePreset/constants/PresetAction';
import { getEmptyFilters } from '@/domain/issueFilter/services/getEmptyFilters';
import { IssueFilterPreset } from '@/domain/issuePreset/models/IssueFilterPreset';
import { CustomStatus } from '@/domain/customStatus/models/CustomStatus';
import { Issue } from '@/domain/issue/models/Issue';

@Component({
    components: {
        WsSkeletonLoader,
        WsTooltip,
        Draggable,
        IconSvg24,
        WsCheckbox,
    },
})
export default class PresetList extends Vue {
    @Prop({ type: Boolean, default: false }) public isAllowLoadingContent!: boolean;
    @Prop() public presetAction!: string;

    public readonly Color = Color;
    public readonly PresetAction = PresetAction;
    public readonly BrokenPresetEnum = BrokenPresetEnum;
    public resizeObserver = new ResizeObserver(() => 1);
    public presetList!: HTMLElement;
    public width: number = 0;
    public hasNewPresetEvent = false;
    public isTitleTruncatedArr: boolean[] = [];

    public isCheckedPresetObj: any = {};

    public draggedIndex = -1;
    public updatedTime = 0;

    get isDraftSelected() {
        return this.$route.query.filter === DRAFT_KEY;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get isAdminRights() {
        return this.project.isAdminRights;
    }

    get isLoadingIssueFilterPresets(): boolean {
        return this.$store.getters.isLoadingIssueFilterPresets(this.projectId);
    }

    get allProjectStatuses(): CustomStatus[] {
        return this.$store.getters.customStatusesByProjectUuid(this.project?.uuid);
    }

    get issueFilterPresets() {
        const issueFilterPresets: IssueFilterPreset[] = this.$store.getters.issueFilterPresetsByProjectId(this.projectId);
        return this.isAdminRights
            ? issueFilterPresets
            : issueFilterPresets.filter(({ uuid }) => uuid !== deletedIssuesPresetUuid);
    }

    get issueFilterPresetsUuids() {
        const issueFilterPresets: TUuid[] = this.$store.getters.issueFilterPresetsUuidsByProjectId(this.projectId);
        return this.isAdminRights
            ? issueFilterPresets
            : issueFilterPresets.filter((uuid) => uuid !== deletedIssuesPresetUuid);
    }

    set issueFilterPresetsUuids(newValue: TUuid[]) {
        this.$store.dispatch('setIssueFilterPresetUuids', { projectId: this.projectId, presetsUuids: newValue });
        if (!_.isEqual(newValue, this.issueFilterPresets)) {
            const orderArr = this.issueFilterPresetsUuids
                .filter(this.isPresetRegular)
                .map((presetUuid: TUuid, index: number) => ({ guid: presetUuid, Order: index }));

            this.order(orderArr);
        }
    }

    get regularIssueFilterPresets() {
        return this.issueFilterPresets.filter((preset: IssueFilterPreset) => this.isPresetRegular(preset.uuid));
    }

    get selectedIssueFilterPreset(): IssueFilterPreset {
        return this.$store.getters.selectedIssueFilterPresetByProjectId(this.projectId);
    }

    get presetStats(): Dict<IIssueFilterPresetUnreadStat> {
        return this.$store.getters.issueFilterPresetsUnreadByProjectId(this.projectId);
    }

    get allUnreadIssues() {
        return this.presetStats[allIssuesPresetUuid] ? this.presetStats[allIssuesPresetUuid].unread : 0;
    }

    get presetsNotifications() {
        return this.issueFilterPresets.reduce((presetsNotifications, preset) => {
            const presetStats = this.presetStats[preset.uuid];

            if (!presetStats) {
                presetsNotifications[preset.uuid] = {
                    hasUnread: false,
                    hasDeletedStatus: false,
                };
                return presetsNotifications;
            }

            let hasUnread = false;
            if (presetStats.unread && presetStats.unread > 0) {
                hasUnread = true;
            } else {
                const filteredIssues = filterIssues(this.allProjectIssues, preset.trackerFilters);
                hasUnread = filteredIssues.some((issue: Issue) => issue.read.issue || (issue.read.comments > 0));
            }

            let hasDeletedStatus = false;
            const statusFilter = preset.trackerFilters[IssuesFilterType.customStatus] as IssueTrackerFilterValue;
            if (presetStats.total === 0 && statusFilter) {
                for (const statusName of statusFilter.selections as string[]) {
                    if (this.customStatusesByName[statusName]?.isDeleted) {
                        hasDeletedStatus = true;
                        break;
                    }
                }
            }

            presetsNotifications[preset.uuid] = {
                hasUnread,
                hasDeletedStatus,
            };

            return presetsNotifications;
        }, {} as Dict<{ hasUnread: boolean; hasDeletedStatus: boolean }>);
    }

    get allProjectIssues() {
        return this.$store.getters.allIssuesByProjectId(this.projectId);
    }

    get draftCount() {
        let result: number = this.$store.getters.draftIssues(this.projectId).length;
        const allIssuesIds = this.$store.getters.allIssuesOrderIds(this.projectId);
        this.$store.getters.draftIssues(this.projectId).forEach((id: number) => {
            if (!allIssuesIds.includes(Number(id))) {
                result--;
            }
        });

        return result;
    }

    get userData() {
        return this.$store.getters.userData;
    }

    get userDataProjectMember() {
        return this.$store.getters.projectMemberByEmail(this.userData.email, this.projectId);
    }

    get isAbleToChangeVisibility() {
        return Boolean(this.userDataProjectMember?.accessRole?.permissions.manage_issue_filter_set);
    }

    get customStatusesByName() {
        return _.keyBy(this.$store.getters.customStatusesByProjectUuid(this.project.uuid, false) as CustomStatus[], 'name');
    }

    @Watch('width', { immediate: true })
    public widthChanged() {
        this.calculateTitleTruncated();
    }

    @Watch('presetAction', { immediate: true })
    public onPresetAction(presetAction: string) {
        if (presetAction === PresetAction.share) {
            this.regularIssueFilterPresets.forEach((preset) => {
                this.isCheckedPresetObj[preset.uuid] = Boolean(preset.visibility);
            });
        } else if (presetAction === PresetAction.delete) {
            this.isCheckedPresetObj = {};
        }
    }

    @Watch('issueFilterPresetsUuids', { immediate: true })
    public async onPresetsChange(newPresetsState: TUuid[], oldPresetsState: TUuid[]) {
        const newPresets = _.differenceBy(newPresetsState, oldPresetsState);

        if (newPresets.length && this.hasNewPresetEvent) {
            this.hasNewPresetEvent = false;
            this.$nextTick(() => {
                this.highlightNewPreset(newPresets[0]);
            });
        }

        const leftPresets = _.differenceBy(oldPresetsState, newPresetsState);
        if (!leftPresets.length) {
            return;
        }

        // If selected preset was deleted
        const currentPresetLeft = leftPresets.find((preset) => this.selectedIssueFilterPreset?.uuid === preset);
        if (!currentPresetLeft) {
            return;
        }

        const foundSamePreset = this.$store.getters.findPresetByFilters(this.projectId, this.selectedIssueFilterPreset.trackerFilters);
        if (foundSamePreset) {
            this.selectFilterPreset(foundSamePreset);
            return;
        }

        // We should select first preset if selected preset was deleted and set url "filter" param for current filters
        const trackerFilters = this.selectedIssueFilterPreset.trackerFilters;
        const trackerSorting = this.$store.getters.selectedIssueFilterPresetByProjectId(this.projectId)?.sorting
            || this.$store.getters.issuesSortByProjectId(this.projectId);

        const activeFilters = _.sortBy(_.values(trackerFilters).filter(({ isActive }) => isActive), 'type');
        const payload = {
            projectId: this.projectId,
            filters: JSON.stringify(activeFilters),
            sorting: trackerSorting ? JSON.stringify(trackerSorting) : undefined,
        };
        const response = await this.$store.dispatch('sendFiltersForHash', payload);

        if (response.hash !== this.$route.query.filter) {
            this.$router.push({ query: { filter: response.hash } });
        }
        this.$store.commit('setSelectedIssueFilterPreset', {
            projectId: this.projectId,
            issueFilterPreset: this.issueFilterPresets[0],
        });
    }

    @Emit()
    public checkedPresets(isCheckedObj: any) {
        return isCheckedObj;
    }

    @Emit()
    public order(orderArr: Array<{ guid: string, Order: number }>) {
        return orderArr;
    }

    public mounted() {
        this.presetList = document.querySelector('.preset-list') as HTMLElement;
        this.width = this.presetList.clientWidth;
        const resizeCallback = _.debounce(() => this.width = this.presetList.clientWidth, 200);
        this.resizeObserver = new ResizeObserver(resizeCallback);
        this.resizeObserver.observe(this.presetList);
        eventBus.$on(BusEvent.newPresetCreated, this.onNewPresetCreated);
    }

    public updated() {
        const currentTime = new Date().getTime();
        if (currentTime - this.updatedTime > 200) {
            this.updatedTime = currentTime;
            this.calculateTitleTruncated();
        }
    }

    public beforeDestroy() {
        this.resizeObserver.unobserve(this.presetList);
        eventBus.$off(BusEvent.newPresetCreated, this.onNewPresetCreated);
    }

    public calculateTitleTruncated() {
        const filterPresetTitles: HTMLElement[] = Array.from(document.querySelectorAll('.filter-preset-title'));
        this.isTitleTruncatedArr = filterPresetTitles.map(isOverflownElementVertically); // https://stackoverflow.com/a/68130373/7730888
    }

    public onDragStart({ oldIndex }: any) {
        this.draggedIndex = oldIndex;
    }

    public onDragEnd() {
        this.draggedIndex = -1;
    }

    // https://stackoverflow.com/a/68442012/7730888
    public checkMove({ draggedContext }: any) {
        const index = draggedContext.futureIndex;
        const preset = this.issueFilterPresets[index];
        return this.isPresetRegular(preset.uuid);
    }

    public isPresetRegular(issueFilterPresetUuid: TUuid) {
        return ![allIssuesPresetUuid, currentIssuesPresetUuid, deletedIssuesPresetUuid].includes(issueFilterPresetUuid);
    }

    public isPresetDeleted(index: number) {
        return this.isAdminRights && index === this.issueFilterPresets.length - 1 && !this.isDraftSelected;
    }

    public isPresetDraggable(issueFilterPreset: IssueFilterPreset) {
        return this.presetAction === PresetAction.reorder && this.isPresetRegular(issueFilterPreset.uuid);
    }

    public isPresetWithCheckbox(issueFilterPreset: IssueFilterPreset) {
        return [PresetAction.share, PresetAction.delete].includes(this.presetAction)
            && this.isPresetRegular(issueFilterPreset.uuid);
    }

    public presetCheckbox(issueFilterPreset: IssueFilterPreset) {
        let isDisabled;
        let tooltip;
        if (this.isAbleToChangeVisibility || !issueFilterPreset.visibility) {
            isDisabled = false;
        } else if (!this.userDataProjectMember) {
            isDisabled = true;
            tooltip = this.presetAction === PresetAction.share
                ? this.$t('IssueTracker.presets.onlyMembersUnshare')
                : this.$t('IssueTracker.presets.onlyMembersDelete');
        } else if (!this.isAbleToChangeVisibility) {
            isDisabled = true;
            tooltip = this.presetAction === PresetAction.share
                ? this.$t('IssueTracker.presets.dontHavePermissionToUnshare')
                : this.$t('IssueTracker.presets.dontHavePermissionToDelete');
        }
        return { isDisabled, tooltip };
    }

    public clickCheck(issueFilterPreset: IssueFilterPreset) {
        this.isCheckedPresetObj = {
            ...this.isCheckedPresetObj,
            [issueFilterPreset.uuid]: !this.isCheckedPresetObj[issueFilterPreset.uuid],
        };
        this.checkedPresets(this.isCheckedPresetObj);
    }

    public isSelected(issueFilterPreset: IssueFilterPreset) {
        return !this.isDraftSelected && issueFilterPreset.uuid === this.selectedIssueFilterPreset.uuid;
    }
    public isAllowShowUnreadMarker(issueFilterPreset: IssueFilterPreset) {
        if (deletedIssuesPresetUuid === issueFilterPreset.uuid) {
            return false;
        }
        if (allIssuesPresetUuid === issueFilterPreset.uuid) {
            return !issueFilterPreset.isAllowShowCounts;
        }

        return true;
    }

    public onClickPresetItem(event: MouseEvent, issueFilterPreset: IssueFilterPreset) {
        if (event.ctrlKey || event.metaKey) {
            return event;
        } else {
            event.preventDefault();
            event.stopPropagation();
        }

        this.selectFilterPreset(issueFilterPreset.uuid);
    }

    public selectFilterPreset(preset: TUuid) {
        const issueFilterPreset = this.issueFilterPresets.find((item: IssueFilterPreset) => item.uuid === preset);
        if (!issueFilterPreset) {
            return;
        }
        amplitudeLog(AmplitudeEvent.itPresetSelected, { presetTitle: issueFilterPreset.title });

        if (this.$route.query?.filter === DRAFT_KEY) {
            this.$router.push({ query: undefined });
        }

        this.$store.commit('setIssueTrackerLink', {});
        this.$store.commit('cleanMultiEditSelectedIssues');
        this.$store.commit('setCurrentPage', { projectId: this.projectId, page: 1 });
        this.$store.commit('setSelectedIssue', { projectId: this.projectId, issue: null });
        this.$store.commit('setSelectedIssueFilterPreset', {
            projectId: this.projectId,
            issueFilterPreset: new IssueFilterPreset(issueFilterPreset),
        });
        this.$store.dispatch('updatePresetFilters', {
            projectId: this.projectId,
            presetFilters: issueFilterPreset.trackerFilters,
        });
    }

    public showDrafts() {
        this.$store.commit('setIssueTrackerLink', {});
        this.$store.commit('cleanMultiEditSelectedIssues');
        this.$store.commit('clearSelectedIssueFilterPreset', { projectId: this.projectId });
        this.$store.commit('setTrackerFilters', { projectId: this.projectId, filters: getEmptyFilters() });
        this.$store.commit('setCurrentPage', { projectId: this.projectId, page: 1 });
        if (Router.currentRoute.query?.filter !== DRAFT_KEY) {
            this.$router.replace({ query: { filter: DRAFT_KEY } });
        }
        this.$store.dispatch('loadIssuesOrderByProjectId', { projectId: this.projectId }).then(() => {
            this.$store.dispatch('loadIssuesByProjectId', { projectId: this.projectId });
        });
    }

    private onNewPresetCreated(newPreset: IssueFilterPreset) {
        this.hasNewPresetEvent = true;

        this.$store.commit('setSelectedIssueFilterPreset', {
            projectId: this.projectId,
            issueFilterPreset: newPreset,
        });

        if (this.$route.query.preset === newPreset.uuid) {
            return;
        }

        this.$router.replace({ query: { preset: newPreset.uuid } });
    }

    public getFilterPresetIdByUuid(uuid: string) {
        return `filter-preset-${uuid}`;
    }

    public getResolvedUrlForPreset(preset: IssueFilterPreset) {
        return this.$router.resolve({ query: { preset: preset.uuid } })?.href || '';
    }

    public highlightNewPreset(presetUuid: string) {
        const presetElement = document.getElementById(this.getFilterPresetIdByUuid(presetUuid));

        if (!presetElement || !this.$refs.presetList) {
            return;
        }

        const presetList = this.$refs.presetList as HTMLElement;
        if (presetList.scrollHeight > presetList.clientHeight) {
            presetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        setTimeout(() => presetElement?.classList.add('highlight-animation'), 300);
        setTimeout(() => presetElement?.classList.remove('highlight-animation'), 2400);
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables';
@import '@/styles/mixins';
@import '@/styles/animations';

.preset-list {
    @include scrollbar;
}

.filter-preset {
    cursor: pointer;
    font-weight: bold;

    &.handle {
        cursor: grab;
    }
}

.filter-preset-item {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: 1fr;
    gap: 2px;
    align-items: center;
    align-content: center;
    justify-items: self-end;
    padding: 14px $container-padding;
    overflow: auto;

    &:not(.grab):hover {
        .filter-preset-title {
            color: $primary-blue;
            cursor: pointer;
        }
    }

    .title-wrapper {
        overflow: auto;
        margin-right: 5px;
        flex: 1;
        min-width: 0;
    }

    .preset-icons {
        display: flex;
    }

    &-second {
        border-bottom: $border;
    }
    &-last {
        border-top: $border;
    }
}

.filter-preset-icon {
    margin: 0 4px 0 0;
    display: flex;
    align-items: center;
}

// https://codepen.io/evb0110/pen/gOzoeBE
.filter-preset-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    user-select: none;
}

.selected-preset {
    background-color: $primary-light-blue;
}

.green-icon {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 12px;
    background: $primary-green;
}

.count {
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: $primary-green;
    margin: 0;
    font-size: 12px;
    padding: 0 8px;
    color: white;
}

.drag-icon-wrapper {
    margin-right: 7px;
    margin-left: -5px;
}

.dragged-preset {
    background-color: $primary-light-blue;
    border: 1px solid $primary-blue;
}

::v-deep {
    .v-input__slot {
        margin: 0;
        .v-input--selection-controls__input {
            margin-right: 0;
        }
    }
    .v-input__control .v-messages {
        display: none;
    }
    .v-input--selection-controls {
        margin: 0;
        padding: 0;
    }
}
</style>
