import { TranslateResult } from 'vue-i18n';
import _ from 'lodash';
import { i18n } from '@/services';

// Timzeone to Input example: ["Africa/Dar_es_Salaam", "+03"]
// Timezone to Output example: Africa - Tanzania, Dar es Salaam
export function transformTimezoneToTranslation(name: string, offset: string): TranslateResult {
    const arrayString = name.split('/').flatMap((splittedString) => splittedString.split(/_|-/));
    const [region, ...rest] = arrayString;
    const translationKey = [region.toLowerCase(), ...rest.map((string) => _.upperFirst(string))].join('');
    return i18n.t(`Timezone.list.${translationKey}`, { offset });
}

export function getTimezoneOffset() {
    const timezoneOffset = new Date().getTimezoneOffset();
    const offset = Math.abs(timezoneOffset);
    const offsetOperator = timezoneOffset < 0 ? '+' : '-';
    const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
  
    return `${offsetOperator}${offsetHours}`;
}