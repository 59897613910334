<template>
    <a class="navigate-back" @click.prevent="navigateToLogin()">
        &#8592; {{ text || $t('Collocation.returnToLogin') }}
    </a>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';

@Component
export default class ReturnToLoginButton extends Vue {
    @Prop() public text!: string;

    public navigateToLogin() {
        this.$router.replace({ name: RouterNames.Login });
    }
}
</script>
