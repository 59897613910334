<template>
    <WsDialog
        :title="$t('userActionResultNotification.deleteIssue')"
        @close="close"
    >
        <div class="body">
            <p class="red-text">{{ $t('userActionResultNotification.deleteIssueConfirmTitle', { count }) }}</p>
            <p>{{ $t('userActionResultNotification.deleteIssueConfirmText') }}</p>
            <p>{{ $t('userActionResultNotification.deleteIssuePermissionsText') }}</p>
        </div>

        <template #buttons>
            <WsButton round @click="remove">{{ $t('Button.delete') }}</WsButton>
            <WsButton round @click="close">{{ $t('Button.cancel') }}</WsButton>
        </template>
    </WsDialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import WsButton from '@/components/common/WsButton.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    components: {
        WsButton,
        WsDialog,
    },
})
export default class DialogDeleteIssues extends Vue {
    @Prop({ required: true }) public count!: number;

    @Emit()
    public close() {
        return;
    }

    @Emit()
    public remove() {
        return;
    }
}
</script>
