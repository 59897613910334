<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M42.3011 14.9995L33.4961 9.8633L34.5039 8.13574L47.9846 15.9995L34.5039 23.8633L33.4961 22.1357L42.3011 16.9995L14 16.9995V14.9995L42.3011 14.9995Z"
            fill="black"
        />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType11 extends BaseIcon {}
</script>
