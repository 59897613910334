<template>
    <div v-if="addedArray.length">
        <IssueCommentFileItem
            v-for="({ value, link }, index) in addedArray"
            :key="index"
            :filename="value.name"
            :provider="value.provider"
            :url="link"
            class="items-row"
        />
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import IssueCommentFileItem from '@/domain/comment/components/IssueCommentFileItem.vue';
import { CdeLink } from '@/domain/issue/models/Issue';
import { IDiffItem } from '@/domain/comment/models/DiffComment';

interface IMappedCdeItem {
    added: boolean;
    value: CdeLink;
    link: string;
}
function getLink({ provider, externalFileId, externalFileUrl }: CdeLink): string {
    if (externalFileUrl) { // это поле есть только у прокора
        return externalFileUrl;
    }
    if (provider === 'box') {
        return `https://app.box.com/file/${externalFileId}`;
    }
    // фордж пока оказывается без ссылки. не можем получить без доп запросов к их апишке
    return '';
}
function mapDiffCde(diff: IDiffItem): IMappedCdeItem[] {
    const oldDiff = diff.old as CdeLink[];
    const newDiff = diff.new as CdeLink[];
    const all = _.uniqWith([...oldDiff, ...newDiff], _.isEqual);
    return all.map((item: CdeLink) => ({
        added: !oldDiff.includes(item) && newDiff.includes(item),
        value: item,
        link: getLink(item),
    }));
}

@Component({
    components: {
        IssueCommentFileItem,
    },
})
export default class IssueCommentDiffCde extends Vue {
    @Prop({ required: true }) public diff!: IDiffItem;

    get mappedDiff(): IMappedCdeItem[] {
        return mapDiffCde(this.diff);
    }

    get addedArray() {
        return (this.mappedDiff || []).filter(({ added }) => added);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.items-row:not(:last-child) {
    margin-bottom: 4px;
}
</style>
