interface IValidateAccessRoleArgument {
    initialValue?: string;
    roleName: string;
    existingNames: string[];
    restrictedNames: string[];
    maxLength: number;
}

export interface IValidateAccessRoleResult {
    isInitial: boolean;
    isEmpty: boolean;
    isExistSame: boolean;
    isRestricted: boolean;
    isTooLong: boolean;
}

function normalize(phrase: string) {
    return String(phrase)
        .trim()
        .toLowerCase()
        .replaceAll(/\s+/g, ' ');
}

export function validateAccessRole({
    initialValue: rawInitialValue,
    roleName: rawRoleName,
    existingNames: rawExistingNames,
    restrictedNames: rawRestrictedNames,
    maxLength,
}: IValidateAccessRoleArgument) {
    if (rawRoleName === rawInitialValue) {
        return { isInitial: true, isEmpty: false, isExistSame: false, isRestricted: false, isTooLong: false };
    }
    const roleName = normalize(rawRoleName);
    const existingNames = rawExistingNames.map(normalize);
    const restrictedNames = rawRestrictedNames.map(normalize);

    const isEmpty = !roleName;
    const isExistSame = existingNames.includes(roleName);
    const isRestricted = restrictedNames.includes(roleName);
    const isTooLong = roleName.length > maxLength;
    return { isInitial: false, isEmpty, isExistSame, isRestricted, isTooLong };
}
