import { render, staticRenderFns } from "./WsInputNumberExtended.vue?vue&type=template&id=86d7cdec&scoped=true"
import script from "./WsInputNumberExtended.vue?vue&type=script&lang=ts"
export * from "./WsInputNumberExtended.vue?vue&type=script&lang=ts"
import style0 from "./WsInputNumberExtended.vue?vue&type=style&index=0&id=86d7cdec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86d7cdec",
  null
  
)

export default component.exports