import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { Dict } from '@/types/Dict';
import { STAMPS_PER_PAGE } from '@/constants';
import ProjectApi from '@/api/project.api';
import { FieldVariants, Project, ProjectRevision } from '@/models';
import { getOperationId, notificationSuccess } from '@/services';
import { IProjectStamp } from '@/domain/stamp/types/ProjectStamp';
import StampApi from '@/domain/stamp/api/stamp';
import { NodeRole } from '@/domain/stamp/constants/Stamps';
import { IssueStamp } from '@/domain/stamp/models/IssueStamp';

interface ProjectStoreState {
    projectsObj: { [projectID: number]: Project };
    projectsFieldVariantObj: { [projectID: number]: FieldVariants };
    projectRevisionsObj: { [projectID: number]: ProjectRevision[] };
    isLoadingProjectsObj: { [projectID: number]: boolean };
    isLoadingFieldVariantsObj: { [projectID: number]: boolean };
    isLoadingProjectRevisionsObj: { [projectID: number]: boolean };
    isSendingProjectSettings: boolean;
    isSendingProjectPreview: boolean;
    isSendingProjectOwner: boolean;
    isSendingProjectLicense: boolean;
    isSendingArchiveProject: boolean;
    isSendingDeleteProject: boolean;
    isSendingGroupEmailToProjectMembers: boolean;
    currentStampCategory: string | null;
    currentStamp: string | null;
    selectedStamps: { [key: string]: boolean };
    isLoadingStampListObj: { [projectID: number]: boolean };
    stampListObj: { [projectID: number]: IProjectStamp[] };
}

interface ProjectStore {
    state: ProjectStoreState;
    getters: any;
    rootGetters: any;
    commit: any;
    dispatch: any;
}

const promise = {
    projectById: {} as any,
    projectFieldVariants: {} as any,
};

export default {
    state: {
        projectsObj: {},
        projectsFieldVariantObj: {},
        projectRevisionsObj: {},
        isLoadingProjectsObj: {},
        isLoadingFieldVariantsObj: {},
        isLoadingProjectRevisionsObj: {},
        isSendingProjectSettings: false,
        isSendingProjectPreview: false,
        isSendingProjectOwner: false,
        isSendingProjectLicense: false,
        isSendingArchiveProject: false,
        isSendingDeleteProject: false,
        isSendingGroupEmailToProjectMembers: false,
        currentStampCategory: null,
        currentStamp: null,
        selectedStamps: {},
        isLoadingStampListObj: {},
        stampListObj: {},
    } as ProjectStoreState,
    getters: {
        projectById(state: ProjectStoreState): (projectId: number) => Project {
            return (projectId) => state.projectsObj[projectId] || new Project();
        },
        projectRevisionsByProjectId(state: ProjectStoreState): (projectId: number) => ProjectRevision[] {
            return (projectId) => state.projectRevisionsObj[projectId] || [];
        },
        isLoadingProjectById(state: ProjectStoreState): (projectId: number) => boolean {
            return (projectId) => state.isLoadingProjectsObj[projectId];
        },
        isLoadingProjectFieldVariantsById(state: ProjectStoreState): (projectId: number) => boolean {
            return (projectId) => state.isLoadingFieldVariantsObj[projectId];
        },
        isLoadingProjectRevisionsByProjectId(state: ProjectStoreState): (projectId: number) => boolean {
            return (projectId) => state.isLoadingProjectRevisionsObj[projectId];
        },
        isLoadingStampListByProjectId(state: ProjectStoreState): (projectId: number) => boolean {
            return (projectId) => state.isLoadingStampListObj[projectId];
        },
        isSendingProjectSettings(state: ProjectStoreState): boolean {
            return state.isSendingProjectSettings;
        },
        isSendingProjectPreview(state: ProjectStoreState): boolean {
            return state.isSendingProjectPreview;
        },
        isSendingProjectOwner(state: ProjectStoreState): boolean {
            return state.isSendingProjectOwner;
        },
        isSendingProjectLicense(state: ProjectStoreState): boolean {
            return state.isSendingProjectLicense;
        },
        isSendingArchiveProject(state: ProjectStoreState): boolean {
            return state.isSendingArchiveProject;
        },
        isSendingDeleteProject(state: ProjectStoreState): boolean {
            return state.isSendingDeleteProject;
        },
        isSendingGroupEmailToProjectMembers(state: ProjectStoreState): boolean {
            return state.isSendingGroupEmailToProjectMembers;
        },
        fieldVariantsByProjectId(state: ProjectStoreState): (projectId: number) => FieldVariants {
            return (projectId) => state.projectsFieldVariantObj?.[projectId] || {};
        },
        stampListByProjectId(state: ProjectStoreState): (projectId: number) => IProjectStamp[] {
            return (projectId) => state.stampListObj?.[projectId] || [];
        },
        stampCategoriesByProjectId(state: ProjectStoreState): (projectId: number) => string[] {
            return (projectId) => (state.stampListObj?.[projectId] || [])
                .filter((stamp: IProjectStamp) => stamp.nodeRole === NodeRole.Category)
                .map((stamp: IProjectStamp) => stamp.title)
                .filter(Boolean);
        },
        stampCategoryUuidByStampAbbr(state: ProjectStoreState, getters: any): (projectId: number) => Dict<string> {
            return (projectId) => {
                const stampList = getters.stampListByProjectId(projectId);
                const stampListByUuid = _.keyBy(stampList, 'uuid');

                return stampList.reduce((acc: any, stamp: any) => {
                    const stampAbbr = stamp.fields.stampAbbr;
                    const parentUuid = stamp.parentUuid;
                    const category = stampListByUuid[parentUuid];
                    const value = category ? category.uuid : 'noCategory';
                    return { ...acc, [stampAbbr]: value };
                }, {});
            };
        },
        stampFormattedByProjectId(state: ProjectStoreState): (projectId: number) => any {
            return (projectId) => {
                const result: any[] = [];

                (state.stampListObj?.[projectId] || []).forEach((stamp) => {
                    const isDirectory = stamp.nodeRole === NodeRole.Category;
                    if (!isDirectory) {
                       let parentFields: any = {};
                       const parent = state.stampListObj?.[projectId].filter((currentStamp) => currentStamp.uuid === stamp.parentUuid);
                       if (parent.length > 0) {
                           parentFields = parent[0].fields;
                       }

                       result.push({
                           text: stamp.title,
                           uuid: stamp.uuid,
                           fields: {
                               ...parentFields,
                               ...stamp.fields,
                           },
                       });
                    }
                });

                return result;
            };
        },
        currentStampCategory(state: ProjectStoreState): string | null {
            return state.currentStampCategory;
        },
        currentStamp(state: ProjectStoreState): string | null {
            return state.currentStamp;
        },
        selectedStampsObject(state: ProjectStoreState) {
            return state.selectedStamps;
        },
        selectedStamps(state: ProjectStoreState): string[] {
            const result: string[] = [];
            Object.keys(state.selectedStamps).forEach((key: string) => {
                if (state.selectedStamps[key]) {
                    result.push(key);
                }
            });

            return result;
        },
    },
    mutations: {
        setProject(state: ProjectStoreState, project: Project) {
            Vue.set(state.projectsObj, project.id, project);
        },
        setProjectRevisions(state: ProjectStoreState, { projectId, revisions }: { projectId: number, revisions: any[] }) {
            Vue.set(state.projectRevisionsObj, projectId, revisions);
        },
        setIsLoadingProject(state: ProjectStoreState, { projectId, value }: { projectId: number; value: boolean }) {
            Vue.set(state.isLoadingProjectsObj, projectId, value);
        },
        setIsLoadingFieldVariants(state: ProjectStoreState, { projectId, value }: { projectId: number; value: boolean }) {
            Vue.set(state.isLoadingFieldVariantsObj, projectId, value);
        },
        setIsLoadingProjectRevisions(state: ProjectStoreState, { projectId, value }: { projectId: number; value: boolean }) {
            Vue.set(state.isLoadingProjectRevisionsObj, projectId, value);
        },
        setIsLoadingStampList(state: ProjectStoreState, { projectId, value }: { projectId: number; value: boolean }) {
            Vue.set(state.isLoadingStampListObj, projectId, value);
        },
        setStampList(state: ProjectStoreState, { projectId, list }: { projectId: number; list: any[] }) {
            Vue.set(state.stampListObj, projectId, _.uniqBy(list, 'uuid').map((stamp: any) => new IssueStamp(stamp)));
        },
        setIsSendingProjectSettings(state: ProjectStoreState, value: boolean) {
            state.isSendingProjectSettings = value;
        },
        setIsSendingProjectPreview(state: ProjectStoreState, value: boolean) {
            state.isSendingProjectPreview = value;
        },
        setIsSendingProjectOwner(state: ProjectStoreState, value: boolean) {
            state.isSendingProjectOwner = value;
        },
        setIsSendingProjectLicense(state: ProjectStoreState, value: boolean) {
            state.isSendingProjectLicense = value;
        },
        setIsSendingArchiveProject(state: ProjectStoreState, value: boolean) {
            state.isSendingArchiveProject = value;
        },
        setIsSendingDeleteProject(state: ProjectStoreState, value: boolean) {
            state.isSendingDeleteProject = value;
        },
        setIsSendingGroupEmailToProjectMembers(state: ProjectStoreState, value: boolean) {
            state.isSendingGroupEmailToProjectMembers = value;
        },
        setProjectFieldVariants(state: ProjectStoreState, { projectId, fieldVariants }: { projectId: number; fieldVariants: FieldVariants }) {
            Vue.set(state.projectsFieldVariantObj, projectId, fieldVariants);
        },
        setProjectIssuesTags(state: ProjectStoreState, { projectId, tags }: { projectId: number; tags: any[] }) {
            Vue.set(state.projectsObj[projectId], 'issueTags', tags);
        },
        setCurrentStampCategory(state: ProjectStoreState, uuid: string) {
            state.currentStampCategory = uuid;
        },
        setCurrentStamp(state: ProjectStoreState, uuid: string | null) {
            state.currentStamp = uuid;
        },
        setSelectedStamps(state: ProjectStoreState, value: { [key: string]: boolean }) {
            state.selectedStamps = value;
        },
        toggleSelectedStamps(state: ProjectStoreState, uuid: string) {
            Vue.set(state.selectedStamps, uuid, !state.selectedStamps[uuid]);
        },
    },
    actions: {
        loadProjectById({ state, commit, dispatch }: ProjectStore, { projectId, isForce = false }: { projectId: number, isForce: boolean }): Promise<any> {
            if (promise.projectById[projectId]) {
                return promise.projectById[projectId];
            }

            if (!isForce && state.projectsObj[projectId]) {
                return Promise.resolve(state.projectsObj[projectId]);
            }

            promise.projectById[projectId] = new Promise((resolve, reject) => {
                commit('setIsLoadingProject', { projectId, value: true });

                ProjectApi.getProjectById(projectId).then((response) => {
                    const project = new Project(response);
                    commit('setProject', project);
                    dispatch('setS3Instance', project.id);
                    resolve(project);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    promise.projectById[projectId] = null;
                    commit('setIsLoadingProject', { projectId, value: false });
                });
            });

            return promise.projectById[projectId];
        },
        sendProjectsSettings({ commit }: ProjectStore, project: Project) {
            commit('setIsSendingProjectSettings', true);
            return ProjectApi.postProjectSettings(project.id, project.apiParams).then(() => {
                notificationSuccess('allSaved');
            }).finally(() => {
                commit('setIsSendingProjectSettings', false);
            });
        },
        sendArchiveProject({ state, commit, rootGetters }: any, projectId: number) {
            commit('setIsSendingArchiveProject', true);
            return ProjectApi.postArchiveProject(projectId).then(() => {
                const project = state.projectsObj[projectId];
                project.archived = true;
                project.lastArchived = moment().format('YYYY-MM-DD');
                commit('setProject', project);

                // для обновления списка myProjects
                const license = rootGetters.currentLicense;
                const projects = rootGetters.myProjects(license);
                const myProject = projects.find((project1: Project) => project1.uuid === project.uuid);
                if (myProject) {
                    myProject.archived = true;
                    myProject.lastArchived = moment().format('YYYY-MM-DD');
                    commit('setMyProjectsList', { license, projects });
                }
                notificationSuccess('projectArchived');
            }).finally(() => {
                commit('setIsSendingArchiveProject', false);
            });
        },
        sendRestoreProject({ state, commit, rootGetters }: ProjectStore, projectId: number) {
            return ProjectApi.postRestoreProject(projectId).then(() => {
                const project = state.projectsObj[projectId];
                project.archived = false;
                commit('setProject', project);

                // для обновления списка myProjects
                const license = rootGetters.currentLicense;
                const projects = rootGetters.myProjects(license);
                const myProject = projects.find((project1: Project) => project1.uuid === project.uuid);
                if (myProject) {
                    myProject.archived = false;
                    commit('setMyProjectsList', { license, projects });
                }
                notificationSuccess('projectRestored');
            });
        },
        sendUnsubscribeProject({ commit }: ProjectStore, projectId: number) {
            commit('setIsSendingDeleteProject', true);
            const operationId = getOperationId();
            return ProjectApi.postUnsubscribeProject(projectId, operationId).finally(() => {
                commit('setIsSendingDeleteProject', false);
            });
        },
        sendDeleteProject({ commit }: ProjectStore, projectId: number) {
            commit('setIsSendingDeleteProject', true);
            return ProjectApi.postDeleteProject(projectId).finally(() => {
                commit('setIsSendingDeleteProject', false);
            });
        },
        sendProjectPreview({ commit }: ProjectStore, { projectId, image }: { projectId: number, image: File }) {
            commit('setIsSendingProjectPreview', true);
            return ProjectApi.postProjectPreview(projectId, image).finally(() => {
                commit('setIsSendingProjectPreview', false);
            });
        },
        sendProjectOwner({ commit }: ProjectStore, { projectId, newOwnerUuid, newRoleUuid }: { projectId: number, newOwnerUuid: string, newRoleUuid: string }) {
            commit('setIsSendingProjectOwner', true);
            const operationId = getOperationId();
            return ProjectApi.postProjectOwner(projectId, newOwnerUuid, newRoleUuid, operationId).finally(() => {
                commit('setIsSendingProjectOwner', false);
            });
        },
        sendProjectLicense({ commit }: ProjectStore, { projectId, licenseId }: { projectId: number, licenseId: number }) {
            commit('setIsSendingProjectLicense', true);
            return ProjectApi.postProjectLicense(projectId, licenseId).finally(() => {
                commit('setIsSendingProjectLicense', false);
            });
        },
        groupSendEmailToProjectMembers({ commit }: ProjectStore, { projectId, params }: { projectId: number, params: any }) {
            commit('setIsSendingGroupEmailToProjectMembers', true);
            return ProjectApi.postGroupSendEmail(projectId, params).finally(() => {
                commit('setIsSendingGroupEmailToProjectMembers', false);
            });
        },
        loadProjectRevisions({ state, commit }: ProjectStore, { projectId, isForce = false }: { projectId: number, isForce: boolean }) {
            return new Promise((resolve, reject) => {
                if (!isForce && state.projectRevisionsObj[projectId]) {
                    resolve(state.projectRevisionsObj[projectId]);
                    return;
                }
                if (!isForce && state.isLoadingProjectRevisionsObj[projectId]) {
                    return;
                }

                let revisions: any[] = [];

                commit('setIsLoadingProjectRevisions', { projectId, value: true });
                loadRevisions(0);

                function loadRevisions(page: number) {
                    ProjectApi.getProjectRevisions(projectId, {
                        from: 0,
                        to: 10000000, // тут мы передаём максимальный номер ревизии которую мы запрашиваем, так что нужно какое-то большое число
                        page: page || 0,
                    }).then((response) => {
                        revisions = _.union(revisions, response.data);
                        if (page + 1 < response.pages) {
                            loadRevisions(page + 1);
                        } else {
                            revisions = revisions.map((revision) => new ProjectRevision(revision));
                            commit('setProjectRevisions', { projectId, revisions });
                            commit('setIsLoadingProjectRevisions', { projectId, value: false });
                            resolve(revisions);
                        }
                    }).catch(() => {
                        reject();
                    });
                }
            });
        },
        loadProjectFieldVariants({ state, commit }: ProjectStore, { projectId, isForce = false }: { projectId: number, isForce: boolean }) {
            if (promise.projectFieldVariants[projectId]) {
                return promise.projectFieldVariants[projectId];
            }

            if (!isForce && state.projectsFieldVariantObj[projectId]) {
                return Promise.resolve(state.projectsFieldVariantObj[projectId]);
            }

            promise.projectFieldVariants[projectId] = new Promise((resolve, reject) => {
                commit('setIsLoadingFieldVariants', { projectId, value: true });
                ProjectApi.getFieldVariants(projectId).then((response) => {
                    const fieldVariants = new FieldVariants(response);
                    commit('setProjectFieldVariants', { projectId, fieldVariants });
                    resolve(fieldVariants);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    promise.projectFieldVariants[projectId] = null;
                    commit('setIsLoadingFieldVariants', { projectId, value: false });
                });
            });

            return promise.projectFieldVariants[projectId];
        },
        loadStampList({ commit }: ProjectStore, { projectId }: { projectId: number}) {
            commit('setIsLoadingStampList', { projectId, value: true });
            StampApi.getStampList({ projectId, page: 0 }).then((response) => {
                let entities = response.entities;
                const count = response.count;
                if (count > STAMPS_PER_PAGE) {
                    const requests: any[] = [];
                    const requestCount = Math.round(count / STAMPS_PER_PAGE) - 1;
                    for (let i = 1; i <= requestCount; i++) {
                        requests.push(StampApi.getStampList({ projectId, page: i }));
                    }

                    Promise.all(requests).then((responses: any[]) => {
                        responses.forEach((currentResponse: any) => {
                            entities = [...entities, ...currentResponse.entities];
                        });
                        commit('setStampList', { list: entities, projectId });
                        commit('setIsLoadingStampList', { projectId, value: false });
                    });
                } else {
                    commit('setStampList', { list: entities, projectId });
                    commit('setIsLoadingStampList', { projectId, value: false });
                }

            });
        },
        async createProject({ dispatch }: ProjectStore, { version, params, newOwnerUuid, image, tags, transferSettings, donorProjectId  }:
                { version: string, params: any, newOwnerUuid?: string, image?: any, tags?: string[], transferSettings?: any, donorProjectId?: number }) {
            const newProject = await ProjectApi.createProject(params);
            const projectId = newProject.id;
            const changeOwnerPromise = newOwnerUuid ? dispatch('sendProjectOwner', { projectId, newOwnerUuid }) : null;
            const sendProjectLogoPromise = image ? dispatch('sendProjectPreview', { projectId, image }) : null;
            const addProjectTagsPromise = tags && tags.length ? dispatch('groupAddLicenseProjectsTags', { projects: [projectId], tags }) : null;
            await Promise.all([
                changeOwnerPromise,
                sendProjectLogoPromise,
                addProjectTagsPromise,
            ]);
            const revision = makeRevision(version);
            await ProjectApi.addProjectRevision({ projectId, revision });
            await ProjectApi.commitProjectRevision(revision.uuid);
            if (donorProjectId) {
                await dispatch('saveProjectTransferSettings', {
                    acceptorProjectUuid: newProject.uuid,
                    donorProjectId,
                    settings: transferSettings,
                });
            }
            return { project: newProject };
        },
        loadProjectTransferPermissions(store: ProjectStore, { projectId, licenseUuid }: { projectId: number, licenseUuid: string}) {
            return ProjectApi.postProjectTransferPermissions({ projectId, licenseUuid });
        },
        saveProjectTransferSettings(store: any, { acceptorProjectUuid, donorProjectId, settings }: any) {
            const requests = [
                {
                    projectUuid: acceptorProjectUuid,
                    ...settings,
                },
            ];
            return ProjectApi.postRequestProjectTransferSettings({ projectId: donorProjectId, requests });
        },
        sendEventOpenProject(store: ProjectStore, { projectId, params }: { projectId: number, params: any }) {
            return ProjectApi.postEventOpenProject(projectId, params);
        },
        createIssuesTags({ commit, rootGetters, getters }: ProjectStore, { projectId, tags }: { projectId: number, tags: string[] }) {
            return ProjectApi.createIssuesTags({
                projectId,
                tags,
                notifierUserID: rootGetters.notifierActorId,
            }).then((response) => {
                if (response?.data?.entities) {
                    commit('setProjectIssuesTags', { projectId, tags: response.data.entities });
                    commit('setProjectFieldVariants', {
                        projectId,
                        fieldVariants: {
                            ...getters.fieldVariantsByProjectId(projectId),
                            tags: response.data.entities,
                        },
                    });
                }
            });
        },
        editIssuesTag({ commit, getters, rootGetters }: ProjectStore, params: { projectId: number, oldTag: string, newTag: string }) {
            return ProjectApi.editIssuesTag({
                projectId: params.projectId,
                oldTag: params.oldTag,
                newTag: params.newTag,
                notifierUserID: rootGetters.notifierActorId,
            }).then(() => {
                const tags = [...getters.projectById(params.projectId).issueTags];
                const oldTagIndex = tags.indexOf(params.oldTag);

                if (oldTagIndex === -1) {
                    return;
                }

                const sameTagIndex = tags.findIndex((tag: string, i) => i !== oldTagIndex && tag === params.newTag);
                if (sameTagIndex === -1) {
                    tags[oldTagIndex] = params.newTag;
                } else {
                    tags.splice(oldTagIndex, 1);
                }

                commit('setProjectIssuesTags', { projectId: params.projectId, tags });

                commit('setProjectFieldVariants', {
                    projectId: params.projectId,
                    fieldVariants: {
                        ...getters.fieldVariantsByProjectId(params.projectId),
                        tags: tags,
                    },
                });
            });
        },
        deleteIssuesTags({ commit, getters, rootGetters }: ProjectStore, params: { projectId: number, oldTags: string[], newTag?: string }) {
            return ProjectApi.deleteIssuesTags({
                projectId: params.projectId,
                oldTags: params.oldTags,
                newTag: params.newTag,
                notifierUserID: rootGetters.notifierActorId,
            }).then(() => {
                const remainingTags = getters.projectById(params.projectId).issueTags
                    .filter((tag: string) => !params.oldTags.includes(tag));

                commit('setProjectIssuesTags', { projectId: params.projectId, tags: remainingTags });

                commit('setProjectFieldVariants', {
                    projectId: params.projectId,
                    fieldVariants: {
                        ...getters.fieldVariantsByProjectId(params.projectId),
                        tags: remainingTags,
                    },
                });
            });
        },
        addNewStamp(context: ProjectStore, { projectId, entities }: { projectId: number, entities: IProjectStamp[] }) {
            return StampApi.addStamp({ projectId, entities });
        },
        editStamp(context: ProjectStore, { projectId, entities }: { projectId: number, entities: IProjectStamp[] }) {
            return StampApi.editStamp({ projectId, entities });
        },
        removeStamp({ commit, getters }: ProjectStore, { projectId, uuids }: { projectId: number, uuids: string[] }) {
            return StampApi.removeStamp({ projectId, uuids }).then(() => {
                commit('setStampList', {
                    list: getters.stampListByProjectId(projectId).filter((stamp: IssueStamp) => !uuids.includes(stamp.uuid)),
                    projectId,
                });
            });
        },
    },
};

function makeRevision(version: string) {
    switch (version) {
        case 'v5':
            return new ProjectRevision({
                project_version: 'v5.00',
                description: '*{"productVersion":"5.4.0.00000(web)","sceneStats":null}',
            });
        case 'v4':
            return new ProjectRevision({
                project_version: 'v4.00',
                description: '*{"productVersion":"4.16.0.00000(web)","sceneStats":null}',
            });
        default:
            throw { message: `unknown version for revision: ${JSON.stringify(version)}` };
    }
}
