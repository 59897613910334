<template>
    <WsTableFilterSelect
        v-model="selections"
        :items="items"
        :label="modifiedLabel"
        :color-select="colorSelect"
        :disabled="disabled"
        :append-to-body="appendToBody"
        :type="type"
        :amp-mask="ampMask"
        searchable
        @clear="clear"
    >
        <template v-if="!hideModifiers">
            <v-btn-toggle
                v-model="modifier"
                class="table-filter-select-modifiers-buttons"
                mandatory
                dense
            >
                <v-btn
                    small
                    v-for="(mod, i) in modifiers"
                    :key="i"
                    :value="mod"
                >
                    <template v-if="mod === IssueFilterExpr.HAS_ALL_IN">
                        {{ $t('License.filter.tag_action.union') }}
                    </template>
                    <template v-if="mod === IssueFilterExpr.IN || mod === IssueFilterExpr.HAS_ONE_IN">
                        {{ $t('License.filter.tag_action.intersection') }}
                    </template>
                    <template v-if="mod === IssueFilterExpr.NOT_IN || mod === IssueFilterExpr.HAS_NOT_ALL_IN">
                        {{ $t('License.filter.tag_action.exclusion') }}
                    </template>
                </v-btn>
            </v-btn-toggle>
            <hr>
        </template>
    </WsTableFilterSelect>
</template>

<script lang="ts">
import _ from 'lodash';
import VueI18n from 'vue-i18n';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ISelectedItem } from '@/types/ISelectedItem';
import { AmplitudeEvent } from '@/constants';
import { ITableFilter } from '@/models';
import { amplitudeLog } from '@/services';
import WsTableFilterSelect from '@/components/common/WsTableFilterSelect.vue';
import { IssueFilterExpr, IssueFilterExprT } from '@/domain/issueFilter/constants/IssueFilterExpr';
import { IssueTrackerFilterValue } from '@/domain/issueFilter/services/IssueTrackerFilterValue';

const defaultModifiers: IssueFilterExprT[] = [
    IssueFilterExpr.HAS_ONE_IN,
    IssueFilterExpr.HAS_ALL_IN,
    IssueFilterExpr.HAS_NOT_ALL_IN,
];

const defaultValue = {
    selections: [],
    modifier: IssueFilterExpr.HAS_ONE_IN,
} as ITableFilter;

@Component({
    components: {
        WsTableFilterSelect,
    },
})
export default class WsTableFilterSelectWithModifiers extends Vue {
    @Prop() public eventSource!: string;
    @Prop({ required: true, default: () => defaultValue }) public value!: ITableFilter;
    @Prop({ required: true }) public items!: ISelectedItem[];
    @Prop({ default: () => defaultModifiers }) public modifiers!: IssueFilterExprT[];
    @Prop() public label!: VueI18n.TranslateResult;
    @Prop() public labelNegative!: VueI18n.TranslateResult;
    @Prop({ type: Boolean }) public colorSelect!: boolean;
    @Prop({ type: Boolean, default: false }) public hideModifiers!: boolean;
    @Prop({ default: true }) public appendToBody!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: String }) public type!: string;
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;

    public IssueFilterExpr = IssueFilterExpr;

    get model() {
        return this.value;
    }
    get selections() {
        return this.value.selections;
    }
    set selections(selections: Array<string | number>) {
        this.input({
            selections,
            modifier: this.value.modifier,
        });
    }
    get modifier() {
        return this.value.modifier;
    }
    set modifier(modifier: IssueFilterExprT | undefined) {
        this.input({
            selections: this.value.selections,
            modifier,
        });
    }

    get modifiedLabel() {
        return ([IssueFilterExpr.NOT_IN, IssueFilterExpr.HAS_NOT_ALL_IN].includes(Number(this.value.modifier)) && !_.isEmpty(this.selections))
            ? this.labelNegative || `${this.label}${this.$t('Simple_word.not')}`
            : this.label;
    }

    @Emit()
    public input(value: ITableFilter) {
        this.logChange();
        return new IssueTrackerFilterValue(this.type, value.modifier as any, value.selections);
    }

    @Emit()
    public clear() {
        return;
    }

    public logChange() {
        const eventNames: any = {
            project: AmplitudeEvent.projectDashboardFilterChange,
            license: AmplitudeEvent.licenseDashboardFilterChange,
        };
        const name = eventNames[this.eventSource];
        if (name) {
            amplitudeLog(name, { filter: this.label });
        }
    }
}
</script>

<style scoped lang="scss">
.table-filter-select-modifiers-buttons.v-item-group.v-btn-toggle {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
}
hr {
    margin: 10px 0;
}
</style>
