import { NotifierUserID } from '@/constants';
import { IMemberUpdateParams, IMemberUpdateResponse } from '@/api/license.api';
import {
    BulkResult,
    ProjectPendingApproval,
    ProjectRevision,
} from '@/models';
import http from './http';

export default class ProjectApi {
    /**
     * Получение проектов по заданной категории
     * @param options - параметры запрашиваемых проектов
     * @param options.category - (обязательно) категория запрашиваемых проектов
     * @param options.page - (опционально) страница запрашиваемых проектов
     * @param options.notifications - (опционально) флаг, нужна ли информация о поле notifications
     */
    public static getProjects(options: { licenseUuid: string, category: string, page?: number, notifications?: boolean, issueCounters?: boolean }): Promise<any> {
        const params = {
            type: options.category,
            page: options.page || 0,
            notifications: options.notifications || false,
            screenshots: false,
            avatars: true,
        } as any;

        if (options.issueCounters) {
            params.issueCounters = {
                all: true,
                assignee: true,
                reporter: true,
                closed: true,
                unread: true,
            };
        }

        return http.get(`project/list/${options.licenseUuid}/paged`, params).then((response) => response?.data);
    }

    /**
     * Получение данных конкретного проекта
     */
    public static getProjectById(projectId: number): Promise<any> {
        return http.get(`project/${projectId}`).then((response) => response.data);
    }

    /**
     * Изменение данных проекта
     */
    public static postProjectSettings(projectId: number, params: any): Promise<any> {
        return http.post(`project/${projectId}/edit`, Object.assign(params, {
            createRevision: 1,
        })).then((response) => response.data);
    }

    /**
     * Изменение превью проекта
     */
    public static postProjectPreview(projectId: number, image: File): Promise<any> {
        return http.post(`screenshot/upload/${projectId}/preview`, {
            pic: image,
            title: image.name,
            createRevision: 1,
        }, true, true).then((response) => response.data);
    }

    /**
     * Изменение владельца проекта
     */
    public static postProjectOwner(projectId: number, newOwnerUuid: string, newRoleUuid: string, operationId: string): Promise<any> {
        return http.post(`project/${projectId}/change-owner`, {
            team_member_uuid: newOwnerUuid,
            newOwnerRole: newRoleUuid,
            operationId,
        }).then((response) => response.data);
    }

    /**
     * Архивация проекта
     */
    public static postArchiveProject(projectId: number): Promise<any> {
        return http.post(`project/${projectId}/archive`).then((response) => response.data);
    }

    /**
     * Разархивация проекта
     */
    public static postRestoreProject(projectId: number): Promise<any> {
        return http.post(`project/${projectId}/unarchive`).then((response) => response.data);
    }

    /**
     * Отписка от проекта
     */
    public static postUnsubscribeProject(projectId: number, operationId: string): Promise<any> {
        return http.post(`project/${projectId}/unsubscribe`, { operationId }).then((response) => response.data);
    }

    /**
     * Удаление проекта
     */
    public static postDeleteProject(projectId: number): Promise<any> {
        return http.post(`project/${projectId}/remove`).then((response) => response.data);
    }

    /**
     * Перемещение проекта в другую лицензию
     */
    public static postProjectLicense(projectId: number, licenseId: number): Promise<any> {
        return http.post(`project/${projectId}/move-to/${licenseId}`).then((response) => response.data);
    }

    /**
     * Получение списка участников проекта
     */
    public static getProjectMembers(projectId: number): Promise<{count: number; entities: any[]}> {
        return http.get(`project/${projectId}/team`).then((response) => response.data);
    }

    /**
     * Получение информации об активности участников проекта
     */
    public static postProjectMembersActivity(licenseId: number, projectId: number, params: any): Promise<any> {
        return http.post(`events/${licenseId}/project-events/${projectId}`, params).then((response) => response.data);
    }

    /**
     * Отправка на почту информации об активности в проекте
     */
    public static postExportProjectMembersActivity(params: any): Promise<any> {
        return http.post('events/by-project-to-email', params);
    }

    /**
     * Получение списка ревизий проекта
     */
    public static getProjectRevisions(projectId: number, params: any): Promise<any> {
        return http.get(`project/${projectId}/revisions`, params).then((response) => response.data);
    }

    /**
     * Удаление участника проекта
     */
    public static postProjectMemberDelete(projectId: number, memberId: number, operationId: string): Promise<any> {
        return http.post(`project/${projectId}/role/delete`, {
            member_id: memberId,
            operationId,
        });
    }

    /**
     * Удаление участников проекта
     */
    public static postProjectMembersDelete(projectId: number, memberUuids: string[], operationId: string): Promise<IMemberUpdateResponse> {
        return http.post(`project/${projectId}/role/bulk-delete`, {
            member_uuids: memberUuids,
            operationId,
        }).then((response) => response.data);
    }

    /**
     * Изменение роли участника проекта
     */
    public static postUpdateProjectMembersAccessRole(projectId: number, memberUuids: string[], roleId: number): Promise<any> {
        return http.post(`project/${projectId}/role/bulk-edit`, {
            member_uuids: memberUuids,
            role_id: roleId,
        }).then((response) => response.data);
    }

    /**
     * Приглашение нового участника проекта
     */
    public static postProjectMemberInvite({ projectId, invitations, roleId, operationId }: { projectId: number, invitations: string[], roleId: number, operationId: string }): Promise<any> {
        return http.post(`project/${projectId}/role/invite`, {
            invitations,
            role_id: roleId,
            operationId,
        });
    }

    /** Массовая отправка email участникам проекта */
    public static postGroupSendEmail(projectId: number, params: any): Promise<BulkResult> {
        return http.post(`project/${projectId}/bulk-send-email`, params, true, true).then((response) => response.data);
    }

    /**
     * Получение списков email'ов assignees и reporters, каждый из которых содержит полный список assignees и reporters,
     * как участников проекта, так и тех кто участниками проекта не является
     */
    public static getFieldVariants(projectId: number) {
        return http.get(`project/${projectId}/issue/field-variants`).then((response) => response.data);
    }

    /**
     * Метод для resend приглашения в проект
     */
    public static postResendProjectInvitations(projectId: number, params: any) {
        return http.post(`project/${projectId}/role/invite/resend`, params).then((response) => response.data);
    }

    /**
     * Метод для получения списка ждунов в проекте
     */
    public static getPendingMembers(projectId: number): Promise<ProjectPendingApproval[]> {
        return http.get(`project/${projectId}/pending`).then((response) => response.data);
    }

    /**
     * Создание проекта из веба
     */
    public static createProject(params: any): Promise<any> {
        return http.post('project/create', params).then((response) => response.data);
    }

    /**
     * создаём ревизию проекта
     */
    public static addProjectRevision({ projectId, revision }: { projectId: number, revision: ProjectRevision }) {
        return http.post(`project/${projectId}/revision/add`, revision.apiParams).then((response) => response.data);
    }

    /**
     * коммитим ревизию проекта
     */
    public static commitProjectRevision(uuid: string) {
        return http.post(`project/revision/${uuid}/commit`).then((response) => response.data);
    }

    /**
     * Узнаём список возможных пермишнов при трансфере настроек из проекта:
     * проект-донор, лицензия-акцептор
     */
    public static postProjectTransferPermissions({ projectId, licenseUuid }: { projectId: number, licenseUuid: string }) {
        return http.post(`project/${projectId}/transfer/settings/new_project`, {
            license_uuid: licenseUuid,
        }).then((response) => response.data);
    }

    /**
     * Переносит настройки из проекта-донора projectId в проекты-акцепторы, uuid-шники которых зашиты
     * в массив объектов requests
     */
    public static postRequestProjectTransferSettings({ projectId, requests }: { projectId: string, requests: any }) {
        return http.post(`project/${projectId}/transfer/settings/request`, {
            projects: JSON.stringify(requests),
        });
    }

    /**
     * Create issues tags
     */
    public static createIssuesTags(params: { projectId: number, tags: string[], notifierUserID: number }) {
        return http.post(`project/${params.projectId}/tags/add`, {
            tags: JSON.stringify(params.tags),
            notifierUserID: params.notifierUserID,
        });
    }

    /**
     * Edit issues tag
     */
    public static editIssuesTag(params: {
        projectId: number,
        oldTag: string,
        newTag: string,
        notifierUserID: number,
    }) {
        return http.post(`project/${params.projectId}/tags/edit`, {
            old: params.oldTag,
            new: params.newTag,
            [NotifierUserID]: params.notifierUserID,
        });
    }

    /**
     * Delete issues tags and create new a tag instead of deleted
     */
    public static deleteIssuesTags(params: {
        projectId: number,
        oldTags: string[],
        newTag?: string,
        notifierUserID: number,
    }) {
        return http.post(`project/${params.projectId}/tags/delete`, {
            old: params.oldTags,
            new: params.newTag,
            [NotifierUserID]: params.notifierUserID,
        });
    }

    /**
     * Send event open project
     */
    public static postEventOpenProject(projectId: number, params: any): Promise<any> {
        return http.post(`events/${projectId}`, params).then((response) => response.data);
    }

    /**
     * Save markup stage 1
     */
    public static postMarkupSync(issueId: string, params: any): Promise<any> {
        return http.post(`issue/${issueId}/sync`, params, true, true).then((response) => response.data);
    }

    public static postGroupUpdateAdditionalFields(
        projectUuid: string,
        params: IMemberUpdateParams,
    ): Promise<IMemberUpdateResponse> {
        const data = JSON.stringify(params.data);
        return http.post(`project/${projectUuid}/update-license-members`, { data })
            .then((response) => response.data);
    }

    /**
     * Get project access keys
     */
    public static getAccessKeys({ projectId }: { projectId: number }) {
        return http.get(`project/${projectId}/access`).then((response) => response.data);
    }

    /**
     * get device
     */
    public static getDevices() {
        return http.get('device').then((response) => response.data);
    }

    /**
     * schedule filter params
     */

    public static getSchedulerFilterParams({ projectUuid }: { projectUuid: string }) {
        return http.get(`scheduler-filters/${projectUuid}/scheduler-filter-params`).then((response) => response.data);
    }

    /**
     * scheduled (upcoming) tasks
     */
    public static getScheduledTasks(options: {
        projectUuid: string,
        types?: string[],
        userEmails?: string[],
        deviceIds?: string[],
        orderBy?: string[],
        orderDirection?: string[],
        page?: number,
        limit?: number,
        dateStart?: number,
        dateEnd?: number,
        query?: string,
        append: string[]
    }) {
        return http.get('scheduled-task-run', options).then((response) => response.data);
    }
}
