import _ from 'lodash';
import { THexColor } from '@/types/common';
import { Color } from '@/constants';

export class ColorService {
    // in app this values is 0.7 but we are using rgb 0...255 instead of 0...1 in app
    public static readonly brightnessLimit = 0.7 * 255;

    public static getColorForField = _.memoize((field: any) => {
        if (!_.isString(field)) {
            window.console.error('Color service -> Can\'t select color for non-string field', field);
            return Color.richBlack;
        }

        if (!field) {
            window.console.error('Color service -> Can\'t select color for empty field', field);
            return Color.richBlack;
        }

        const predefinedColor = Color.predefined[field];
        if (predefinedColor) {
            return predefinedColor;
        }

        return '#' + ColorService.intToRGB(ColorService.hashCode(field));
    });

    public static rgbToHexColor(r: number, g: number, b: number): string {
        return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    }

    public static getBrightness(hex: THexColor): number {
        const rgb = this.hexColorToRgbObj(hex);
        return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
    }

    public static normalizeColor(color: THexColor): THexColor {
        return ColorService.getBrightness(color) > ColorService.brightnessLimit ? Color.darkGrey : color;
    }

    public static hexColorToRgbObj(hex: string): any {
        return {
            r: parseInt(hex.substring(1, 3), 16),
            g: parseInt(hex.substring(3, 5), 16),
            b: parseInt(hex.substring(5, 7), 16),
        };
    }

    public static contrastHexColor(hexColor: string): string {
        if (!hexColor) {
            return '#000000';
        }

        const rgbObj = this.hexColorToRgbObj(hexColor);
        const a = 1 - (0.299 * rgbObj.r + 0.587 * rgbObj.g + 0.114 * rgbObj.b) / 255;

        return (a < 0.5) ? '#000000' : '#ffffff';
    }

    public static generateColorList(count: number = 10, rgbStep: number = 33) {
        const availableColors: string[] = [];
        const resultColorList: string[] = [];

        // Генерация палитры цветов
        for (let g = 255; g > 0; g -= rgbStep) {
            for (let r = 255; r > 0; r -= rgbStep) {
                for (let b = 255; b > 0; b -= rgbStep) {
                    availableColors.push(this.rgbToHexColor(r, g, b));
                }
            }
        }

        // Выбор нескольких случайных цветов
        while (resultColorList.length < count) {
            const randomColor = availableColors[Math.floor(Math.random() * availableColors.length)];
            if (resultColorList.indexOf(randomColor) === -1) {
                resultColorList.push(randomColor);
            }
        }

        return resultColorList;
    }

    public static hashCode(str: string) { // java String#hashCode
        let hash = 0;
        for (let i = 0; i < str?.length; i++) {
            hash = ((1125 * str.charCodeAt(i)) % 65535) + ((hash << 5) - hash);
        }
        return hash;
    }

    public static intToRGB(hash: number) {
        const channelsArrayDecimal: number[] = ((hash & 0x00FFFFFF)
            .toString(16)
            .padStart(6, '0')
            .match(/.{2}/g) as string[])
            .map((channel) => parseInt(channel, 16));

        return ColorService
            .eliminateWhiteAndBlack(channelsArrayDecimal)
            .map((channel: number) => channel.toString(16))
            .join('')
            .toUpperCase()
            .padStart(6, '0');
    }

    public static eliminateWhiteAndBlack(channelsArrayDecimal: number[]): number[] {
        let [r, g, b] = channelsArrayDecimal;
        while (true) {
            const distanceToBlack = Math.hypot(r, g, b);
            const distanceToWhite = Math.hypot(255 - r, 255 - g, 255 - b);
            const distanceLimit = 50;
            if (distanceToBlack > distanceLimit && distanceToWhite > distanceLimit) {
                return [r, g, b];
            }
            r = (r + 13) % 255;
            g = (g + 19) % 255;
            b = (b + 17) % 255;
        }
    }
}
