<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <template v-if="icon === 'edit'">
            <path
                :fill="fill"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.54048 2.54014C10.3466 1.73402 11.6536 1.73402 12.4597 2.54014C13.2658 3.34627 13.2658 4.65325 12.4597 5.45938L6.95972 10.9594C6.88836 11.0307 6.80138 11.0845 6.70565 11.1164L3.70565 12.1164C3.47208 12.1943 3.21457 12.1335 3.04048 11.9594C2.86639 11.7853 2.8056 11.5278 2.88345 11.2942L3.88345 8.29421C3.91536 8.19848 3.96912 8.1115 4.04048 8.04014L9.54048 2.54014ZM11.5405 3.45938C11.242 3.16094 10.7582 3.16094 10.4597 3.45938L9.91934 3.99976L11.0001 5.08052L11.5405 4.54014C11.8389 4.2417 11.8389 3.75782 11.5405 3.45938ZM10.0809 5.99976L9.0001 4.919L5.06822 8.85088L4.52784 10.472L6.14898 9.93164L10.0809 5.99976ZM1.8501 13.4998C1.8501 13.1408 2.14111 12.8498 2.5001 12.8498H12.5001C12.8591 12.8498 13.1501 13.1408 13.1501 13.4998C13.1501 13.8587 12.8591 14.1498 12.5001 14.1498H2.5001C2.14111 14.1498 1.8501 13.8587 1.8501 13.4998Z"
            />
        </template>

        <template v-else-if="icon === 'info'">
            <path
                :fill="fill"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.31338 6.27822H6.71338L6.71338 4.67822H8.31338L8.31338 6.27822ZM6.71338 6.97803H8.31338V12.2782H6.71338V6.97803ZM14.3134 8.47822C14.3134 12.2338 11.2689 15.2782 7.51338 15.2782C3.75784 15.2782 0.713379 12.2338 0.713379 8.47822C0.713379 4.72269 3.75784 1.67822 7.51338 1.67822C11.2689 1.67822 14.3134 4.72269 14.3134 8.47822ZM7.51338 3.27822C4.6415 3.27822 2.31338 5.60634 2.31338 8.47822C2.31338 11.3501 4.6415 13.6782 7.51338 13.6782C10.3853 13.6782 12.7134 11.3501 12.7134 8.47822C12.7134 5.60634 10.3853 3.27822 7.51338 3.27822Z"
            />
        </template>

        <template v-else-if="icon === 'trash'">
            <path
                :fill="fill"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 2L10 2V4H13V5H12V14H4V5H3V4H6V2ZM7 4H9V3H7V4ZM5 5V13H11V5H5ZM6 12.0001V6.0001H7V12.0001H6ZM9 12.0001V6.0001H10V12.0001H9Z"
            />
        </template>

        <template v-else-if="icon === 'refresh'">
            <path
                :fill="fill"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.7087 3.85059H9.00918V5.15059H13.1592V1.00059H11.8592V3.20455C10.7324 1.76696 8.97912 0.841797 7.00912 0.841797C3.60772 0.841797 0.850346 3.59917 0.850346 7.00057C0.850346 10.402 3.60772 13.1593 7.00912 13.1593C10.0152 13.1593 12.517 11.0063 13.0591 8.15849L11.782 7.91538C11.3545 10.1615 9.37937 11.8593 7.00912 11.8593C4.32569 11.8593 2.15034 9.684 2.15034 7.00057C2.15034 4.31714 4.32569 2.1418 7.00912 2.1418C8.49011 2.1418 9.81694 2.8042 10.7087 3.85059Z"
            />
        </template>

        <template v-else-if="icon === 'revision'">
            <path
                :fill="fill"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.30057 4.8501H6.0001V6.1501H1.8501V2.0001H3.1501V4.20407C4.27685 2.76647 6.03016 1.84131 8.00016 1.84131C11.4016 1.84131 14.1589 4.59869 14.1589 8.00008C14.1589 11.4015 11.4016 14.1589 8.00016 14.1589C4.9941 14.1589 2.49231 12.0059 1.95019 9.158L3.22725 8.9149C3.65482 11.161 5.62991 12.8589 8.00016 12.8589C10.6836 12.8589 12.8589 10.6835 12.8589 8.00008C12.8589 5.31666 10.6836 3.14131 8.00016 3.14131C6.51917 3.14131 5.19233 3.80371 4.30057 4.8501Z"
            />
        </template>
    </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgSource16 extends Vue {
    @Prop({ default: 'edit' }) public icon!: string;
    @Prop({ default: '#616161' }) public fill!: string;
}
</script>
