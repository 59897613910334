<template>
    <HeaderSelect
        :arrow="false"
        class="user-widget"
        data-test="mnb-user-widget-options-list"
        close-on-list-click
        @size="setSize"
    >
        <template #activator>
            <div class="user-data">
                <img class="user-avatar amp-block" :src="userAvatarSrc" alt="">
                <div class="user-name amp-mask">
                    {{ userData.firstname || userData.email }}
                    <template v-if="userData.lastname">&nbsp;{{ userData.lastname }}</template>
                </div>
            </div>
        </template>

        <template #list>
            <v-list-item>
                <router-link :to="{ name: 'UserSettings', params: { language } }">{{ $t('User.widget.settings') }}</router-link>
            </v-list-item>
            <v-list-item>
                <a :href="eulaHref" target="_blank">{{ $t('Footer.legal') }}</a>
            </v-list-item>
            <v-list-item class="divided">
                <a @click="handleLogout">{{ $t('Button.logout') }}</a>
            </v-list-item>
        </template>
    </HeaderSelect>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { AmplitudeEvent, LanguageKey } from '@/constants';
import { amplitudeMixin } from '@/mixins';
import HeaderSelect from '@/components/header/HeaderSelect.vue';

@Component({
    components: {
        HeaderSelect,
    },
})
export default class UserWidget extends amplitudeMixin {
    get language() {
        return this.$route.params.language;
    }
    get userData() {
        return this.$store.getters.userData;
    }
    get userAvatarSrc() {
        return this.userData.avatar || '/images/undefined-user.png';
    }
    get eulaHref() {
        let lang = this.language;
        if (lang === LanguageKey.cn) {
            lang = 'zh';
        } else if (lang === LanguageKey.jp) {
            lang = 'ja';
        }
        return `https://revizto.com/${lang}/eula/`;
    }

    public handleLogout() {
        this.amplitudeLog(AmplitudeEvent.logoutClicked);
        this.$store.dispatch('logout', { isGoToLogin: true });
    }
    public setSize(size: number) {
        this.$store.commit('setHeaderComponentSize', { name: 'user', size });
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

$user-widget-max: 200px;
$user-widget-avatar: 32px;
$user-widget-name: $user-widget-max - $user-widget-avatar - $user-widget-avatar/2 - $container-padding*2;

.user-widget {
    max-width: $user-widget-max;
    text-overflow: ellipsis;
    overflow: hidden;
}
.user-data {
    display: flex;
    align-items: center;
    font-weight: normal;
}
.user-avatar {
    display: inline-block;
    width: $user-widget-avatar/1.5;
    height: $user-widget-avatar/1.5;
    margin-right: $user-widget-avatar/2;
    border-radius: $user-widget-avatar;
    cursor: pointer;
}
.user-name {
    display: none;
    line-height: $line-height-small;
    max-width: $user-widget-name;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $screen-sm) {
        display: block;
    }
}
</style>
