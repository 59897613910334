import { Dict } from '@/types/Dict';

export class AppSSO {
    public static fromJSON(ssoString: string) {
        return new AppSSO(JSON.parse(ssoString));
    }

    public key: string | undefined;
    public region: string | undefined;
    public merge: number;
    public licenseUuid: string | undefined;

    constructor({ key, region, merge, licenseUuid }: Dict<string>) {
        this.key = valueOrUndefined(key);
        this.region = valueOrUndefined(region);
        this.merge = valueOrUndefined(merge) ? Number(valueOrUndefined(merge)) : 0;
        this.licenseUuid = valueOrUndefined(licenseUuid);
    }

    get isEmpty() {
        return !this.key;
    }

    get jsonString() {
        return JSON.stringify(this);
    }
}

function valueOrUndefined(value: string) {
    return value === 'undefined' ? undefined : value;
}

