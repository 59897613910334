import _ from 'lodash';
import Vue from 'vue';
// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { Dict } from '@/types/Dict';
import Router from '@/router';
import { RESPONSE } from '@/constants';
import LicenseApi, {
    IChangeLicenseOwnerParams,
    IMemberActivationParams,
    IMemberDeactivationParams,
    IMemberUpdateParams,
    IMemberUpdateResponse,
} from '@/api/license.api';
import { AccessRole, IBulkResult, License, LicenseFieldVariants, LicenseOwner } from '@/models';
import { setLicenseUuidForOperationId } from '@/services/OperationID';
import { CookieHelper, getOperationId, notificationSuccess } from '@/services';

const promise = {
    licenses: null as null | Promise<any>,
    licenseById: {} as any,
    licenseFieldVariants: {} as any,
    accessRolesPermissions: {} as any,
};

interface LicenseStorageState {
    currentLicenseId: number | null;
    previousLicenseId: number | null;
    licensesObj: Dict<License>;
    licenseFieldVariantObj: Dict<LicenseFieldVariants>;
    lastDeletedLicenseName?: string;
    isLoadingLicenses: boolean;
    isLoadedLicenses: boolean;
    isLoadingLicense: boolean;
    isSendingGroupChangeRole: boolean;
    isSendingGroupEmail: boolean;
    isSendingGroupActivation: boolean;
    isSendingGroupDeactivation: boolean;
    isSendingGroupDeletion: boolean;
    isSendingAdditionalFields: boolean;
    isSendingVisibilityAdditionalFields: boolean;
    isSendingAccessRole: boolean;
    accessRolesObj: Dict<AccessRole[]>;
    isLoadingAccessRolesObj: Dict<boolean>;
    isLoadingFieldVariantsObj: Dict<boolean>;
    isLoadingAccessRolesPermissionsObj: Dict<boolean>;
    isLoadingAccessRolePermissionsObj: Dict<boolean>;
}

interface LicenseStorageContext {
    commit: any;
    dispatch: any;
    getters: any;
    rootGetters: any;
    state: LicenseStorageState;
}

export default {
    state: {
        currentLicenseId: null,
        previousLicenseId: null,
        licensesObj: {},
        licenseFieldVariantObj: {},
        lastDeletedLicenseName: '',
        isLoadingLicenses: false,
        isLoadedLicenses: false,
        isLoadingLicense: false,
        isSendingGroupChangeRole: false,
        isSendingGroupEmail: false,
        isSendingGroupActivation: false,
        isSendingGroupDeactivation: false,
        isSendingGroupDeletion: false,
        isSendingAdditionalFields: false,
        isSendingVisibilityAdditionalFields: false,
        isSendingAccessRole: false,
        accessRolesObj: {},
        isLoadingAccessRolesObj: {},
        isLoadingFieldVariantsObj: {},
        isLoadingAccessRolesPermissionsObj: {},
        isLoadingAccessRolePermissionsObj: {},
    } as LicenseStorageState,
    getters: {
        currentLicenseId(state: LicenseStorageState) {
            return state.currentLicenseId;
        },
        previousLicenseId(state: LicenseStorageState) {
            return state.previousLicenseId;
        },
        currentLicense(state: LicenseStorageState, getters: any): License {
            return getters.licenseById(state.currentLicenseId);
        },
        licenses(state: LicenseStorageState): License[] {
            return _.map(state.licensesObj, (license) => license);
        },
        licensesWithValidAuth(state: LicenseStorageState, getters: any): License[] {
            return getters.licenses.filter(({ isValidAuth }: License) => isValidAuth);
        },
        licenseById(state: LicenseStorageState): (licenseId: number) => License {
            return (licenseId) => state.licensesObj[licenseId] || new License();
        },
        hasLicenseById(state: LicenseStorageState, getters: any): (licenseId: number) => boolean {
            return (licenseId) => Boolean(getters.licenseById(licenseId).id);
        },
        licenseByUuid(state: LicenseStorageState, getters: any): (uuid: string) => License {
            const licenseByUuidObj = _.keyBy(getters.licenses, 'uuid');
            return (uuid) => licenseByUuidObj[uuid];
        },
        lastDeletedLicenseName(state: LicenseStorageState) {
            return state.lastDeletedLicenseName;
        },
        fieldVariantsByLicenseId(state: LicenseStorageState): (licenseId: number) => LicenseFieldVariants | Dict {
            return (licenseId) => state.licenseFieldVariantObj[licenseId] || {};
        },
        isLoadingLicenseFieldVariantsById(state: LicenseStorageState): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingFieldVariantsObj[licenseId];
        },
        licensesCanView(_state: LicenseStorageState, getters: any): License[] {
            return getters.licenses.filter((license: License) => license.isGuestRights);
        },
        isLoadingLicenses(state: LicenseStorageState) {
            return state.isLoadingLicenses;
        },
        isLoadedLicenses(state: LicenseStorageState) {
            return state.isLoadedLicenses;
        },
        hasLicenses(state: LicenseStorageState, getters: any) {
            return getters.isLoadedLicenses && Boolean(getters.licenses.length);
        },
        noLicenses(state: LicenseStorageState, getters: any) {
            return getters.isLoadedLicenses && !getters.licenses.length;
        },
        licenseError(state: LicenseStorageState, { currentLicense, noLicenses }: any) {
            if (noLicenses) {
                return 'License.noLicenses';
            }
            if (currentLicense.isExpired) {
                return 'License.expired';
            }
            if (currentLicense.isTeamExceeded) {
                return 'License.teamExceeded';
            }
            if (currentLicense.isSpaceExceeded) {
                return 'License.spaceExceeded';
            }
            if (currentLicense.frozen) {
                return currentLicense.isBlocked ? 'License.blocked' : 'License.userFrozen';
            }
            return '';
        },
        isLoadingLicense(state: LicenseStorageState) {
            return state.isLoadingLicense;
        },
        isSendingGroupChangeRole(state: LicenseStorageState) {
            return state.isSendingGroupChangeRole;
        },
        isSendingGroupEmail(state: LicenseStorageState) {
            return state.isSendingGroupEmail;
        },
        isSendingGroupActivation(state: LicenseStorageState) {
            return state.isSendingGroupActivation;
        },
        isSendingGroupDeactivation(state: LicenseStorageState) {
            return state.isSendingGroupDeactivation;
        },
        isSendingGroupDeletion(state: LicenseStorageState) {
            return state.isSendingGroupDeletion;
        },
        isSendingAdditionalFields(state: LicenseStorageState) {
            return state.isSendingAdditionalFields;
        },
        isSendingVisibilityAdditionalFields(state: LicenseStorageState) {
            return state.isSendingVisibilityAdditionalFields;
        },
        isSendingAccessRole(state: LicenseStorageState) {
            return state.isSendingAccessRole;
        },
        accessRolesByLicenseId(state: LicenseStorageState): (licenseId: number) => AccessRole[] {
            return (licenseId) => state.accessRolesObj[licenseId] || [];
        },
        nonSystemAccessRolesByLicenseId(state: LicenseStorageState, getters: any): (licenseId: number) => AccessRole[] {
            return (licenseId) => getters.accessRolesByLicenseId(licenseId)
                .filter((accessRole: AccessRole) => accessRole.system === 0)
                .sort((a: AccessRole, b: AccessRole) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        },
        isLoadingAccessRolesByLicenseId(state: LicenseStorageState): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingAccessRolesObj[licenseId];
        },
        isLoadingAccessRolesPermissionsByLicenseId(state: LicenseStorageState): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingAccessRolesPermissionsObj[licenseId];
        },
        isLoadingAccessRolePermissions(state: LicenseStorageState): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingAccessRolePermissionsObj[licenseId];
        },
        cookieKeyRegionLicenseId(state: LicenseStorageState, rootGetters: any): string {
            return `currentLicenseId_${rootGetters.regionId}`;
        },
        hasLicenseError(state: LicenseStorageState, getters: any) {
            return Boolean(getters.licenseError);
        },
        customStatusesStubs(state: LicenseStorageState, getters: any) {
            return getters.fieldVariantsByLicenseId(getters.currentLicenseId).statuses;
        },
    },
    mutations: {
        setCurrentLicenseId(state: LicenseStorageState, licenseId: number) {
            state.currentLicenseId = licenseId;
        },
        setPreviousLicenseId(state: LicenseStorageState, licenseId: number) {
            state.previousLicenseId = licenseId;
        },
        setLicenses(state: LicenseStorageState, licenses: License[]) {
            state.licensesObj = _.keyBy(licenses, 'id') as Dict<License>;
        },
        setLicense(state: LicenseStorageState, license: License) {
            Vue.set(state.licensesObj, license.id, license);
            setLicenseUuidForOperationId(license.uuid);
        },
        setLicenseFieldVariants(
            state: LicenseStorageState,
            { licenseId, fieldVariants }: { licenseId: number; fieldVariants: LicenseFieldVariants },
        ) {
            Vue.set(state.licenseFieldVariantObj, licenseId, fieldVariants);
        },
        setIsLoadingLicenses(state: LicenseStorageState, value: boolean) {
            state.isLoadingLicenses = value;
        },
        setIsLoadedLicenses(state: LicenseStorageState, value: boolean) {
            state.isLoadedLicenses = value;
        },
        setIsLoadingLicense(state: LicenseStorageState, value: boolean) {
            state.isLoadingLicense = value;
        },
        setIsLoadingFieldVariants(
            state: LicenseStorageState,
            { licenseId, value }: { licenseId: number; value: boolean },
        ) {
            Vue.set(state.isLoadingFieldVariantsObj, licenseId, value);
        },
        setIsSendingGroupChangeRole(state: LicenseStorageState, value: boolean) {
            state.isSendingGroupChangeRole = value;
        },
        setIsSendingGroupEmail(state: LicenseStorageState, value: boolean) {
            state.isSendingGroupEmail = value;
        },
        setIsSendingGroupActivation(state: LicenseStorageState, value: boolean) {
            state.isSendingGroupActivation = value;
        },
        setIsSendingGroupDeactivation(state: LicenseStorageState, value: boolean) {
            state.isSendingGroupDeactivation = value;
        },
        setIsSendingGroupDeletion(state: LicenseStorageState, value: boolean) {
            state.isSendingGroupDeletion = value;
        },
        setIsSendingAdditionalFields(state: LicenseStorageState, value: boolean) {
            state.isSendingAdditionalFields = value;
        },
        setIsSendingVisibilityAdditionalFields(state: LicenseStorageState, value: boolean) {
            state.isSendingVisibilityAdditionalFields = value;
        },
        setIsSendingAccessRole(state: LicenseStorageState, value: boolean) {
            state.isSendingAccessRole = value;
        },
        setAccessRoles(state: LicenseStorageState, { licenseId, roles }: { licenseId: number; roles: any[] }) {
            Vue.set(state.accessRolesObj, licenseId, roles);
        },
        setIsLoadingAccessRoles(state: LicenseStorageState, { licenseId, value }: { licenseId: number; value: boolean }) {
            Vue.set(state.isLoadingAccessRolesObj, licenseId, value);
        },
        setIsLoadingAccessRolesPermissions(
            state: LicenseStorageState,
            { licenseId, value }: { licenseId: number; value: boolean },
        ) {
            Vue.set(state.isLoadingAccessRolesPermissionsObj, licenseId, value);
        },
        setIsLoadingAccessRolePermissions(
            state: LicenseStorageState,
            { licenseId, value }: { licenseId: number; value: boolean },
        ) {
            Vue.set(state.isLoadingAccessRolePermissionsObj, licenseId, value);
        },
        setDeletedLicenseName(state: LicenseStorageState, name: string) {
            Vue.set(state, 'lastDeletedLicenseName', name);
            VueCookies.set('lastDeletedLicenseName', name);
        },
    },
    actions: {
        changeCurrentLicenseId({ commit, getters }: LicenseStorageContext, licenseId: number) {
            CookieHelper.set(getters.cookieKeyRegionLicenseId, String(licenseId));
            commit('setCurrentLicenseId', licenseId);
        },
        loadLicenses({ state, getters, commit, dispatch }: LicenseStorageContext, isForce = false) {
            if (!promise.licenses || isForce) {
                promise.licenses = new Promise((resolve, reject) => {
                    commit('setIsLoadingLicenses', true);
                    LicenseApi.getLicenses().then(async (response) => {
                        // highlights only come in request getLicenseById, so when downloading all licenses
                        // we need to preserve those highlights which have already been received
                        const highlightsObj = _.mapValues(state.licensesObj, 'highLights');

                        const licenses = response.entities.map(License.instantiate);

                        licenses.forEach((license) => {
                            license.setHighlights(highlightsObj[license.id]);
                        });

                        commit('setLicenses', licenses);

                        if (getters.licensesWithValidAuth.length) {
                            await dispatch('switchLicenseId');
                        }

                        resolve(licenses);
                    }).catch((error) => {
                        promise.licenses = null;
                        reject(error);
                    }).finally(() => {
                        commit('setIsLoadingLicenses', false);
                        commit('setIsLoadedLicenses', true);
                    });
                });
            }

            return promise.licenses;
        },
        switchLicenseId({ getters, dispatch }: LicenseStorageContext) {
            /**
             * Initialization of currentLicenseId
             *
             * first check for licenseId in url,
             * if not in the url, then we try to take from the cookies,
             * then, we check the presence of this license in the list of available ones,
             * if not in the url and cookies or the license is not found, then select the first of the available licenses
             *
             * !!! It is important that after rewriting the code to await and async, this method is called before the actual transition,
             * so here you need to look at the route that will be, if it is not there, then at the current
             */
            let routeLicenseId = Number(Router.app.$route.params.licenseId);
            const history = (Router as any).history;
            const { previousLicenseId } = getters;
            if (getters.hasLicenseById(previousLicenseId)) {
                routeLicenseId = previousLicenseId;
            } else if (!routeLicenseId && history?.pending?.params) {
                routeLicenseId = Number(history.pending.params.licenseId);
            }

            if (routeLicenseId) {
                return  dispatch('changeCurrentLicenseId', routeLicenseId);
            } else {
                const availableLicenses: License[] = getters.licensesWithValidAuth;
                let licenseId = Number(VueCookies.get(getters.cookieKeyRegionLicenseId));
                const isAvailableLicense = _.some(availableLicenses, { id: licenseId });
                if (!isAvailableLicense) {
                    const orderedLicenses = _.sortBy(availableLicenses, ['frozen', 'name']);
                    licenseId = orderedLicenses[0]?.id;
                }
                return  dispatch('changeCurrentLicenseId', licenseId);
            }
        },
        loadLicenseById({ state, commit }: LicenseStorageContext, { licenseId, isForce = false }: { licenseId: number, isForce: boolean }) {
            if (promise.licenseById[licenseId]) {
                return promise.licenseById[licenseId];
            }

            if (!isForce && state.licensesObj[licenseId]) {
                return Promise.resolve(state.licensesObj[licenseId]);
            }

            promise.licenseById[licenseId] = new Promise((resolve, reject) => {
                commit('setIsLoadingLicense', true);
                LicenseApi.getLicenseById(licenseId).then((response) => {
                    const license = new License(response);
                    commit('setLicense', license);
                    resolve(license);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    promise.licenseById[licenseId] = null;
                    commit('setIsLoadingLicense', false);
                });
            });

            return promise.licenseById[licenseId];
        },
        loadLicenseFieldVariants({ state, commit }: LicenseStorageContext, { licenseId, isForce = false }: { licenseId: number, isForce: boolean }) {
            if (promise.licenseFieldVariants[licenseId]) {
                return promise.licenseFieldVariants[licenseId];
            }

            if (!isForce && state.licenseFieldVariantObj[licenseId]) {
                return Promise.resolve(state.licenseFieldVariantObj[licenseId]);
            }

            promise.licenseFieldVariants[licenseId] = new Promise((resolve, reject) => {
                commit('setIsLoadingFieldVariants', { licenseId, value: true });
                LicenseApi.getFieldVariants(licenseId).then((response) => {
                    const fieldVariants = new LicenseFieldVariants(response);

                    commit('setLicenseFieldVariants', {
                        licenseId,
                        fieldVariants,
                    });

                    resolve(fieldVariants);
                }).finally(() => {
                    promise.licenseFieldVariants[licenseId] = null;
                    commit('setIsLoadingFieldVariants', { licenseId, value: false });
                }).catch((error) => {
                    reject(error);
                });
            });

            return promise.licenseFieldVariants[licenseId];
        },
        sendLicenseSettings(context: LicenseStorageContext, { licenseId, params }: { licenseId: number, params: any }) {
            return LicenseApi.postLicenseSettings(licenseId, params).then((response) => {
                notificationSuccess('allSaved');
                return response.name;
            });
        },
        sendVisibilityLicenseExtraFields({ state, commit }: LicenseStorageContext, { licenseId, params }: { licenseId: number, params: any }) {
            commit('setIsSendingVisibilityAdditionalFields', true);
            return LicenseApi.postVisibilityLicenseExtraFields(licenseId, params).then((response) => {
                const responseLicense = new License(response);
                const license = state.licensesObj[licenseId];
                license.visibleCompany = responseLicense.visibleCompany;
                license.visibleDepartment = responseLicense.visibleDepartment;
                license.visibleLocation = responseLicense.visibleLocation;
                commit('setLicense', license);
                notificationSuccess('allSaved');
            }).finally(() => {
                commit('setIsSendingVisibilityAdditionalFields', false);
            });
        },
        sendLicenseLogo(context: LicenseStorageContext, { licenseId, file }: { licenseId: number, file: File }) {
            return LicenseApi.postLicenseLogo(licenseId, file).then(() => {
                notificationSuccess('allSaved');
            });
        },
        removeLicenseLogo({ state, commit }: LicenseStorageContext, licenseId: number) {
            return LicenseApi.deleteLicenseLogo(licenseId).then(() => {
                const license = state.licensesObj[licenseId];
                license.logo = '';
                commit('setLicense', license);
                notificationSuccess('logoDeleted');
            });
        },
        groupChangeRole({ commit }: LicenseStorageContext, { licenseId, params }: { licenseId: number, params: any }) {
            commit('setIsSendingGroupChangeRole', true);
            const operationId = getOperationId();
            return LicenseApi.postGroupChangeRole(licenseId, { ...params, operationId }).finally(() => {
                commit('setIsSendingGroupChangeRole', false);
            });
        },
        groupSendEmail({ commit }: LicenseStorageContext, { licenseId, params }: { licenseId: number, params: any }) {
            commit('setIsSendingGroupEmail', true);
            return LicenseApi.postGroupSendEmail(licenseId, params).finally(() => {
                commit('setIsSendingGroupEmail', false);
            });
        },
        groupActivateMembers({ commit }: LicenseStorageContext, { licenseId, params }: IMemberActivationParams): Promise<IBulkResult> {
            commit('setIsSendingGroupActivation', true);
            const operationId = getOperationId();
            return LicenseApi.postGroupActivation(licenseId, {
                ...params,
                operationId,
            }).finally(() => commit('setIsSendingGroupActivation', false));
        },
        groupDeactivateMembers(
            { commit }: LicenseStorageContext,
            { licenseId, params }: { licenseId: number; params: IMemberDeactivationParams },
        ): Promise<IBulkResult> {
            commit('setIsSendingGroupDeactivation', true);
            const operationId = getOperationId();
            return LicenseApi.postGroupDeactivation(licenseId, {
                ...params,
                operationId,
            }).finally(() => commit('setIsSendingGroupDeactivation', false));
        },
        groupDeleteMembers(
            { commit }: LicenseStorageContext,
            { licenseId, params }: { licenseId: number; params: IMemberDeactivationParams },
        ): Promise<IBulkResult> {
            commit('setIsSendingGroupDeletion', true);
            const operationId = getOperationId();
            return LicenseApi.postGroupDeletion(licenseId, {
                ...params,
                operationId,
            }).finally(() => commit('setIsSendingGroupDeletion', false));
        },
        groupUpdateMemberAdditionalFields(
            { commit }: any,
            { licenseId, params }: { licenseId: number; params: IMemberUpdateParams },
        ): Promise<IMemberUpdateResponse> {
            commit('setIsSendingAdditionalFields', true);
            return LicenseApi.postGroupUpdateAdditionalFields(licenseId, params).finally(() => {
                commit('setIsSendingAdditionalFields', false);
            });
        },
        loadAccessRoles(
            { commit }: LicenseStorageContext,
            { licenseId }: { licenseId: number },
        ) {
            return new Promise((resolve, reject) => {
                commit('setIsLoadingAccessRoles', { licenseId, value: true });
                LicenseApi.getProjectRoles(licenseId).then((response) => {
                    const roles = _.map(response, (role: any) => new AccessRole(role));
                    commit('setAccessRoles', { licenseId, roles });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingAccessRoles', { licenseId, value: false });
                });
            });
        },
        sendCreateAccessRole(
            { state, commit }: LicenseStorageContext,
            { licenseId, accessRole }: { licenseId: number, accessRole: AccessRole },
        ) {
            return new Promise((resolve, reject) => {
                commit('setIsSendingAccessRole', true);

                LicenseApi.postCreateAccessRole(licenseId, accessRole).then((response) => {
                    // imitation of receiving information about a new access role from the backend; you need to fly from the back
                    const newAccessRoleData = Object.values(response)[0] as any;

                    accessRole.id = newAccessRoleData.id;
                    accessRole.uuid = newAccessRoleData.uuid;
                    accessRole = new AccessRole(accessRole);

                    const roles = state.accessRolesObj[licenseId];
                    roles.push(accessRole);
                    commit('setAccessRoles', { licenseId, roles });

                    notificationSuccess('allSaved');
                    resolve(accessRole);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingAccessRole', false);
                });
            });
        },
        sendUpdateAccessRole(
            { state, commit }: LicenseStorageContext,
            { licenseId, accessRole }: { licenseId: number, accessRole: AccessRole },
        ) {
            return new Promise((resolve, reject) => {
                commit('setIsSendingAccessRole', true);
                LicenseApi.postUpdateAccessRole(licenseId, accessRole).then(() => {
                    accessRole = new AccessRole(accessRole);
                    const roles = state.accessRolesObj[licenseId];
                    const index = roles.findIndex((role: AccessRole) => role.uuid === accessRole.uuid);
                    roles.splice(index, 1, accessRole);
                    commit('setAccessRoles', { licenseId, roles });
                    notificationSuccess('allSaved');
                    resolve(accessRole);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingAccessRole', false);
                });
            });
        },
        sendDeleteManyAccessRoles(
            { state, commit }: LicenseStorageContext,
            { licenseId, accessRoles, migrateId }: { licenseId: number, accessRoles: AccessRole[], migrateId: number },
        ) {
            commit('setIsSendingAccessRole', true);
            const promises = accessRoles.map((accessRole) => LicenseApi.postDeleteAccessRole(licenseId, accessRole, migrateId));
            return Promise.all(promises).then(() => {
                const deletedIds = accessRoles.map(({ id }) => id);
                const roles = state.accessRolesObj[licenseId].filter(({ id }: AccessRole) => !deletedIds.includes(id));
                commit('setAccessRoles', { licenseId, roles });
                notificationSuccess('allSaved');
            }).finally(() => {
                commit('setIsSendingAccessRole', false);
            });
        },
        async loadAccessRolePermissions(
            { commit }: LicenseStorageContext,
            { licenseId, accessRoleId }: { licenseId: number, accessRoleId: number },
        ) {
            commit('setIsLoadingAccessRolePermissions', { licenseId, value: true });
            const result = await LicenseApi.getAccessRolePermissions(licenseId, accessRoleId);
            commit('setIsLoadingAccessRolePermissions', { licenseId, value: false });
            return result;
        },
        loadAccessRolesPermissions(
            { state, commit }: LicenseStorageContext,
            { licenseId, uuids }: { licenseId: number, uuids: string[] },
        ) {
            if (promise.accessRolesPermissions[licenseId]) {
                return promise.accessRolesPermissions[licenseId];
            }

            promise.accessRolesPermissions[licenseId] = new Promise((resolve, reject) => {
                commit('setIsLoadingAccessRolesPermissions', { licenseId, value: true });
                LicenseApi.postAccessRolesPermissions(licenseId, { uuids: _.uniq(uuids) }).then((response: any) => {
                    const rawPermissions: { [uuid: string]: any } = response.reduce((accum: { [uuid: string]: any }, next: { [uuid: string]: any }) => {
                        return { ...accum, ...next.data };
                    }, {});
                    const permissions = Object.entries(rawPermissions).reduce((accum: any, [key, value]) => {
                        if (value.result === RESPONSE.SUCCESS) {
                            accum[key] = value.data;
                        } else {
                            throw new Error(value.result);
                        }
                        return accum;
                    }, {});
                    const roles = state.accessRolesObj[licenseId].map((accessRole: AccessRole) => {
                        if (permissions[accessRole.uuid]) {
                            accessRole.permissions = permissions[accessRole.uuid].permissions;
                        }

                        return accessRole;
                    });

                    commit('setAccessRoles', { licenseId, roles });
                    resolve(permissions);
                }).catch((error: any) => {
                    reject(error);
                }).finally(() => {
                    promise.accessRolesPermissions[licenseId] = null;
                    commit('setIsLoadingAccessRolesPermissions', { licenseId, value: false });
                });
            });

            return promise.accessRolesPermissions[licenseId];
        },
        sendChangeLicenseOwner(
            { state, commit }: LicenseStorageContext,
            { licenseId, authMethod, email, role }: IChangeLicenseOwnerParams,
        ) {
            const operationId = getOperationId();
            return LicenseApi.postChangeLicenseOwner({
                licenseId,
                authMethod,
                email,
                role,
                operationId,
            }).then((response) => {
                const license = state.licensesObj[licenseId];
                license.owner = new LicenseOwner(response);
                license.role = role;
                commit('setLicense', license);
            });
        },
        getProjectAdministrators(context: LicenseStorageContext, { licenseUuid }: { licenseUuid: string }) {
            return LicenseApi.getProjectAdministrators(licenseUuid);
        },
        setLicenseOptions(context: LicenseStorageContext, { licenseUuid, options }: { licenseUuid: string, options: any }) {
            return LicenseApi.postSetLicenseOptions(licenseUuid, options);
        },
        deleteLicense({ commit }: LicenseStorageContext, { uuid, name }: License) {
            return LicenseApi.deleteLicense(uuid).then(() => {
                commit('setDeletedLicenseName', name);
            });
        },
    },
};
