<template>
    <div>
        <div class="label" v-if="label">
            {{ label }}
        </div>
        <v-input
            v-if="messages"
            :error-count="maxMessages"
            :messages="messages"
            :error-messages="errorMessages"
            :success-messages="successMessages"
            :error="error"
            :rules="rules"
            :value="value"
        >
            <slot />
        </v-input>
        <slot v-if="!messages" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

@Component
export default class WsFieldLabel extends Vue {
    @Prop() public label!: VueI18n.TranslateResult;
    @Prop() public messages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public errorMessages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public successMessages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public rules!: any[];
    @Prop() public value!: any;
    @Prop({ default: 2 }) public maxMessages!: number;
    @Prop({ default: false }) public error!: boolean;
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.label {
    color: $medium-gray;
    margin-left: 16px;
    font-size: 12px;
    line-height: 18px;
}

::v-deep .v-messages__wrapper {
    margin-left: 16px;
}
</style>
