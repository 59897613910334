/* tslint:disable no-var-requires */
import { TranslateResult } from 'vue-i18n';
const sanitize = require('sanitize-html');

const allowedTagsNoLinks = [
    'br',
    'p',
    'span',
    'b',
    'strong',
    'kbd',
    'ul',
    'li',
];

const allowedTagsWithLinks = [
    ...allowedTagsNoLinks,
    'a',
];

const allowedTagsExtended = [
    ...allowedTagsWithLinks,
    'img',
    'video',
    'h2',
    'h3',
    'h4',
    'table',
    'thead',
    'tbody',
    'tr',
    'td',
];

const allowedAttributes = {
    a: [
        'href',
        'style',
        'class',
        'target',
    ],
    span: [
        'class',
    ],
};

const config = {
    allowedTagsWithLinks,
    allowedAttributes,
};

export function sanitizeHtml(source: TranslateResult): string {
    return sanitize(source, config);
}

export function sanitizeHtmlExtended(source: TranslateResult): string {
    return sanitize(String(source), {
        allowedTags: allowedTagsExtended,
        allowedAttributes: {
            'a': ['href', 'style', 'class', 'target'],
            'video': ['src', 'controls', 'width', 'height', 'class'],
            'img': ['src', 'width', 'height', 'class'],
            'td': ['style', 'rowspan', 'colspan'],
        },
        allowedSchemes: ['data'],
    });
}
export function sanitizeHtmlNoLinks(source: TranslateResult): string {
    return sanitize(String(source), {
        allowedTags: allowedTagsNoLinks,
    });
}

export function sanitizeHtmlOnlyText(source: TranslateResult): string {
    return sanitize(String(source), {
        allowedTags: [],
    });
}
