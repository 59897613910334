/*./protobuf/ColorGroup.proto*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const ExternalServiceOperation = $root.ExternalServiceOperation = (() => {

    function ExternalServiceOperation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ExternalServiceOperation.prototype.code = 0;
    ExternalServiceOperation.prototype.service = "";
    ExternalServiceOperation.prototype.status = 0;
    ExternalServiceOperation.prototype.name = "";
    ExternalServiceOperation.prototype.login = "";

    ExternalServiceOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.service != null && Object.hasOwnProperty.call(message, "service"))
            writer.uint32(18).string(message.service);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(24).int32(message.status);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(34).string(message.name);
        if (message.login != null && Object.hasOwnProperty.call(message, "login"))
            writer.uint32(42).string(message.login);
        return writer;
    };

    ExternalServiceOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ExternalServiceOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExternalServiceOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.service = reader.string();
                    break;
                }
            case 3: {
                    message.status = reader.int32();
                    break;
                }
            case 4: {
                    message.name = reader.string();
                    break;
                }
            case 5: {
                    message.login = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ExternalServiceOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ExternalServiceOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isInteger(message.code))
                return "code: integer expected";
        if (message.service != null && message.hasOwnProperty("service"))
            if (!$util.isString(message.service))
                return "service: string expected";
        if (message.status != null && message.hasOwnProperty("status"))
            if (!$util.isInteger(message.status))
                return "status: integer expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.login != null && message.hasOwnProperty("login"))
            if (!$util.isString(message.login))
                return "login: string expected";
        return null;
    };

    ExternalServiceOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ExternalServiceOperation";
    };

    ExternalServiceOperation.Code = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UndefinedCode"] = 0;
        values[valuesById[1] = "CreateConnection"] = 1;
        values[valuesById[2] = "UpdateStatus"] = 2;
        values[valuesById[3] = "DeleteConnection"] = 3;
        return values;
    })();

    ExternalServiceOperation.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UndefinedStatus"] = 0;
        values[valuesById[1] = "BROKEN"] = 1;
        values[valuesById[2] = "INITIALIZE"] = 2;
        values[valuesById[3] = "ACTIVE"] = 3;
        values[valuesById[4] = "DEACTIVATED"] = 4;
        return values;
    })();

    return ExternalServiceOperation;
})();

export const Issue = $root.Issue = (() => {

    function Issue(properties) {
        this.lines = [];
        this.watchers = [];
        this.tags = [];
        this.comments = [];
        this.selections = [];
        this.sectioncuts = [];
        this.markups = [];
        this.selectionNames = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Issue.prototype.id = 0;
    Issue.prototype.author = "";
    Issue.prototype.guid = "";
    Issue.prototype.created = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.camera = null;
    Issue.prototype._tCamera = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.snapshot = null;
    Issue.prototype._tSnapshot = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.unread = false;
    Issue.prototype.unreadComments = 0;
    Issue.prototype.reporter = "";
    Issue.prototype.tReporter = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.lines = $util.emptyArray;
    Issue.prototype.tLines = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.title = "";
    Issue.prototype.tTitle = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.status = 0;
    Issue.prototype.tStatus = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.assigned = "";
    Issue.prototype.tAssigned = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.watchers = $util.emptyArray;
    Issue.prototype.tWatchers = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.visibility = 0;
    Issue.prototype.tVisibility = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.tags = $util.emptyArray;
    Issue.prototype.tTags = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.deadline = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.tDeadline = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.comments = $util.emptyArray;
    Issue.prototype.lastChanges = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.lastOrderComments = 0;
    Issue.prototype.lastRequestOrderComments = 0;
    Issue.prototype.selections = $util.emptyArray;
    Issue.prototype._tSelections = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.sectioncuts = $util.emptyArray;
    Issue.prototype._tSectioncuts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.sheet = null;
    Issue.prototype._tSheet = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.clash = null;
    Issue.prototype._tClash = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.version = 0;
    Issue.prototype.markups = $util.emptyArray;
    Issue.prototype.tMarkups = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.markupsCommentGuid = "";
    Issue.prototype.visSelect = null;
    Issue.prototype._tVisSelect = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.colorSelect = null;
    Issue.prototype._tColorSelect = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.phaseSelect = null;
    Issue.prototype._tPhaseSelect = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.selectionStorage = null;
    Issue.prototype.clashStorage = null;
    Issue.prototype.priority = 0;
    Issue.prototype.tPriority = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.bind3d = null;
    Issue.prototype._tBind3d = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.internalProperties = null;
    Issue.prototype.tInternalProperties = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.stampColor = 0;
    Issue.prototype.tStampColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.stampAbbr = "";
    Issue.prototype.tStampAbbr = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.stampScale = 0;
    Issue.prototype.tStampScale = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.procore = null;
    Issue.prototype.tProcore = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.pin = null;
    Issue.prototype.tPin = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.cdeLinks = null;
    Issue.prototype.tCdeLinks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.compareSheet = null;
    Issue.prototype.tCompareSheet = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.visualOperations = null;
    Issue.prototype._tVisualOperations = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.selectionNames = $util.emptyArray;
    Issue.prototype._tSelectionNames = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.selectionNameStorage = null;
    Issue.prototype.rclash = null;
    Issue.prototype._tRclash = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.rClashStorage = null;
    Issue.prototype.statusAuto = false;
    Issue.prototype.positionProperties = null;
    Issue.prototype._tProp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.defaultSheet = null;
    Issue.prototype._tDefaultSheet = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.customStatus = "";
    Issue.prototype.customType = "";
    Issue.prototype._tCustomType = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.tDeletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.updated = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    Issue.prototype.commented = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    Issue.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(8).int32(message.id);
        if (message.author != null && Object.hasOwnProperty.call(message, "author"))
            writer.uint32(18).string(message.author);
        if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
            writer.uint32(26).string(message.guid);
        if (message.created != null && Object.hasOwnProperty.call(message, "created"))
            writer.uint32(32).int64(message.created);
        if (message.camera != null && Object.hasOwnProperty.call(message, "camera"))
            $root.Issue.CameraTransform.encode(message.camera, writer.uint32(42).fork()).ldelim();
        if (message.snapshot != null && Object.hasOwnProperty.call(message, "snapshot"))
            $root.Issue.Snapshot.encode(message.snapshot, writer.uint32(50).fork()).ldelim();
        if (message.unread != null && Object.hasOwnProperty.call(message, "unread"))
            writer.uint32(56).bool(message.unread);
        if (message.unreadComments != null && Object.hasOwnProperty.call(message, "unreadComments"))
            writer.uint32(64).int32(message.unreadComments);
        if (message.reporter != null && Object.hasOwnProperty.call(message, "reporter"))
            writer.uint32(74).string(message.reporter);
        if (message.tReporter != null && Object.hasOwnProperty.call(message, "tReporter"))
            writer.uint32(80).int64(message.tReporter);
        if (message.lines != null && message.lines.length)
            for (let i = 0; i < message.lines.length; ++i)
                $root.Issue.Line.encode(message.lines[i], writer.uint32(90).fork()).ldelim();
        if (message.tLines != null && Object.hasOwnProperty.call(message, "tLines"))
            writer.uint32(96).int64(message.tLines);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(106).string(message.title);
        if (message.tTitle != null && Object.hasOwnProperty.call(message, "tTitle"))
            writer.uint32(112).int64(message.tTitle);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(120).int32(message.status);
        if (message.tStatus != null && Object.hasOwnProperty.call(message, "tStatus"))
            writer.uint32(128).int64(message.tStatus);
        if (message.assigned != null && Object.hasOwnProperty.call(message, "assigned"))
            writer.uint32(138).string(message.assigned);
        if (message.tAssigned != null && Object.hasOwnProperty.call(message, "tAssigned"))
            writer.uint32(144).int64(message.tAssigned);
        if (message.watchers != null && message.watchers.length)
            for (let i = 0; i < message.watchers.length; ++i)
                writer.uint32(154).string(message.watchers[i]);
        if (message.tWatchers != null && Object.hasOwnProperty.call(message, "tWatchers"))
            writer.uint32(160).int64(message.tWatchers);
        if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
            writer.uint32(168).int32(message.visibility);
        if (message.tVisibility != null && Object.hasOwnProperty.call(message, "tVisibility"))
            writer.uint32(176).int64(message.tVisibility);
        if (message.tags != null && message.tags.length)
            for (let i = 0; i < message.tags.length; ++i)
                writer.uint32(186).string(message.tags[i]);
        if (message.tTags != null && Object.hasOwnProperty.call(message, "tTags"))
            writer.uint32(192).int64(message.tTags);
        if (message.deadline != null && Object.hasOwnProperty.call(message, "deadline"))
            writer.uint32(200).int64(message.deadline);
        if (message.tDeadline != null && Object.hasOwnProperty.call(message, "tDeadline"))
            writer.uint32(208).int64(message.tDeadline);
        if (message.comments != null && message.comments.length)
            for (let i = 0; i < message.comments.length; ++i)
                $root.Issue.Comment.encode(message.comments[i], writer.uint32(218).fork()).ldelim();
        if (message.lastChanges != null && Object.hasOwnProperty.call(message, "lastChanges"))
            writer.uint32(224).int64(message.lastChanges);
        if (message.lastOrderComments != null && Object.hasOwnProperty.call(message, "lastOrderComments"))
            writer.uint32(232).int32(message.lastOrderComments);
        if (message.lastRequestOrderComments != null && Object.hasOwnProperty.call(message, "lastRequestOrderComments"))
            writer.uint32(240).int32(message.lastRequestOrderComments);
        if (message.selections != null && message.selections.length)
            for (let i = 0; i < message.selections.length; ++i)
                $root.Issue.Selection.encode(message.selections[i], writer.uint32(250).fork()).ldelim();
        if (message.sectioncuts != null && message.sectioncuts.length)
            for (let i = 0; i < message.sectioncuts.length; ++i)
                $root.Issue.Plane.encode(message.sectioncuts[i], writer.uint32(258).fork()).ldelim();
        if (message.sheet != null && Object.hasOwnProperty.call(message, "sheet"))
            $root.Issue.Sheet.encode(message.sheet, writer.uint32(266).fork()).ldelim();
        if (message.clash != null && Object.hasOwnProperty.call(message, "clash"))
            $root.Issue.Clash.encode(message.clash, writer.uint32(274).fork()).ldelim();
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(280).int32(message.version);
        if (message.markups != null && message.markups.length)
            for (let i = 0; i < message.markups.length; ++i)
                $root.Issue.Markup.encode(message.markups[i], writer.uint32(290).fork()).ldelim();
        if (message.tMarkups != null && Object.hasOwnProperty.call(message, "tMarkups"))
            writer.uint32(296).int64(message.tMarkups);
        if (message.markupsCommentGuid != null && Object.hasOwnProperty.call(message, "markupsCommentGuid"))
            writer.uint32(306).string(message.markupsCommentGuid);
        if (message.visSelect != null && Object.hasOwnProperty.call(message, "visSelect"))
            $root.Issue.VisSelect.encode(message.visSelect, writer.uint32(314).fork()).ldelim();
        if (message.colorSelect != null && Object.hasOwnProperty.call(message, "colorSelect"))
            $root.Issue.ColorSelect.encode(message.colorSelect, writer.uint32(322).fork()).ldelim();
        if (message.phaseSelect != null && Object.hasOwnProperty.call(message, "phaseSelect"))
            $root.Issue.PhaseSelect.encode(message.phaseSelect, writer.uint32(330).fork()).ldelim();
        if (message._tCamera != null && Object.hasOwnProperty.call(message, "_tCamera"))
            writer.uint32(336).int64(message._tCamera);
        if (message._tSnapshot != null && Object.hasOwnProperty.call(message, "_tSnapshot"))
            writer.uint32(344).int64(message._tSnapshot);
        if (message._tSelections != null && Object.hasOwnProperty.call(message, "_tSelections"))
            writer.uint32(352).int64(message._tSelections);
        if (message._tSectioncuts != null && Object.hasOwnProperty.call(message, "_tSectioncuts"))
            writer.uint32(360).int64(message._tSectioncuts);
        if (message._tSheet != null && Object.hasOwnProperty.call(message, "_tSheet"))
            writer.uint32(368).int64(message._tSheet);
        if (message._tClash != null && Object.hasOwnProperty.call(message, "_tClash"))
            writer.uint32(376).int64(message._tClash);
        if (message._tVisSelect != null && Object.hasOwnProperty.call(message, "_tVisSelect"))
            writer.uint32(384).int64(message._tVisSelect);
        if (message._tColorSelect != null && Object.hasOwnProperty.call(message, "_tColorSelect"))
            writer.uint32(392).int64(message._tColorSelect);
        if (message._tPhaseSelect != null && Object.hasOwnProperty.call(message, "_tPhaseSelect"))
            writer.uint32(400).int64(message._tPhaseSelect);
        if (message.selectionStorage != null && Object.hasOwnProperty.call(message, "selectionStorage"))
            $root.Issue.SelectionStorage.encode(message.selectionStorage, writer.uint32(410).fork()).ldelim();
        if (message.clashStorage != null && Object.hasOwnProperty.call(message, "clashStorage"))
            $root.Issue.ClashStorage.encode(message.clashStorage, writer.uint32(418).fork()).ldelim();
        if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
            writer.uint32(424).int32(message.priority);
        if (message.tPriority != null && Object.hasOwnProperty.call(message, "tPriority"))
            writer.uint32(432).int64(message.tPriority);
        if (message.bind3d != null && Object.hasOwnProperty.call(message, "bind3d"))
            $root.Issue.Bind3d.encode(message.bind3d, writer.uint32(442).fork()).ldelim();
        if (message._tBind3d != null && Object.hasOwnProperty.call(message, "_tBind3d"))
            writer.uint32(448).int64(message._tBind3d);
        if (message.internalProperties != null && Object.hasOwnProperty.call(message, "internalProperties"))
            $root.Issue.InternalProperties.encode(message.internalProperties, writer.uint32(458).fork()).ldelim();
        if (message.tInternalProperties != null && Object.hasOwnProperty.call(message, "tInternalProperties"))
            writer.uint32(464).int64(message.tInternalProperties);
        if (message.stampColor != null && Object.hasOwnProperty.call(message, "stampColor"))
            writer.uint32(472).int32(message.stampColor);
        if (message.tStampColor != null && Object.hasOwnProperty.call(message, "tStampColor"))
            writer.uint32(480).int64(message.tStampColor);
        if (message.stampAbbr != null && Object.hasOwnProperty.call(message, "stampAbbr"))
            writer.uint32(490).string(message.stampAbbr);
        if (message.tStampAbbr != null && Object.hasOwnProperty.call(message, "tStampAbbr"))
            writer.uint32(496).int64(message.tStampAbbr);
        if (message.stampScale != null && Object.hasOwnProperty.call(message, "stampScale"))
            writer.uint32(509).float(message.stampScale);
        if (message.tStampScale != null && Object.hasOwnProperty.call(message, "tStampScale"))
            writer.uint32(512).int64(message.tStampScale);
        if (message.procore != null && Object.hasOwnProperty.call(message, "procore"))
            $root.Issue.Procore.encode(message.procore, writer.uint32(522).fork()).ldelim();
        if (message.tProcore != null && Object.hasOwnProperty.call(message, "tProcore"))
            writer.uint32(528).int64(message.tProcore);
        if (message.pin != null && Object.hasOwnProperty.call(message, "pin"))
            $root.Issue.Pin.encode(message.pin, writer.uint32(538).fork()).ldelim();
        if (message.tPin != null && Object.hasOwnProperty.call(message, "tPin"))
            writer.uint32(544).int64(message.tPin);
        if (message.cdeLinks != null && Object.hasOwnProperty.call(message, "cdeLinks"))
            $root.Issue.CDELinks.encode(message.cdeLinks, writer.uint32(554).fork()).ldelim();
        if (message.tCdeLinks != null && Object.hasOwnProperty.call(message, "tCdeLinks"))
            writer.uint32(560).int64(message.tCdeLinks);
        if (message.compareSheet != null && Object.hasOwnProperty.call(message, "compareSheet"))
            $root.Issue.CompareSheet.encode(message.compareSheet, writer.uint32(570).fork()).ldelim();
        if (message.tCompareSheet != null && Object.hasOwnProperty.call(message, "tCompareSheet"))
            writer.uint32(576).int64(message.tCompareSheet);
        if (message.visualOperations != null && Object.hasOwnProperty.call(message, "visualOperations"))
            $root.Issue.VisualOperations.encode(message.visualOperations, writer.uint32(586).fork()).ldelim();
        if (message._tVisualOperations != null && Object.hasOwnProperty.call(message, "_tVisualOperations"))
            writer.uint32(592).int64(message._tVisualOperations);
        if (message.selectionNames != null && message.selectionNames.length)
            for (let i = 0; i < message.selectionNames.length; ++i)
                $root.Issue.SelectionName.encode(message.selectionNames[i], writer.uint32(602).fork()).ldelim();
        if (message._tSelectionNames != null && Object.hasOwnProperty.call(message, "_tSelectionNames"))
            writer.uint32(608).int64(message._tSelectionNames);
        if (message.selectionNameStorage != null && Object.hasOwnProperty.call(message, "selectionNameStorage"))
            $root.Issue.SelectionNameStorage.encode(message.selectionNameStorage, writer.uint32(618).fork()).ldelim();
        if (message.rclash != null && Object.hasOwnProperty.call(message, "rclash"))
            $root.Issue.RClash.encode(message.rclash, writer.uint32(626).fork()).ldelim();
        if (message._tRclash != null && Object.hasOwnProperty.call(message, "_tRclash"))
            writer.uint32(632).int64(message._tRclash);
        if (message.rClashStorage != null && Object.hasOwnProperty.call(message, "rClashStorage"))
            $root.Issue.RClashStorage.encode(message.rClashStorage, writer.uint32(642).fork()).ldelim();
        if (message.statusAuto != null && Object.hasOwnProperty.call(message, "statusAuto"))
            writer.uint32(648).bool(message.statusAuto);
        if (message.positionProperties != null && Object.hasOwnProperty.call(message, "positionProperties"))
            $root.Issue.Prop.encode(message.positionProperties, writer.uint32(658).fork()).ldelim();
        if (message._tProp != null && Object.hasOwnProperty.call(message, "_tProp"))
            writer.uint32(664).int64(message._tProp);
        if (message.defaultSheet != null && Object.hasOwnProperty.call(message, "defaultSheet"))
            $root.Issue.DefaultSheet.encode(message.defaultSheet, writer.uint32(674).fork()).ldelim();
        if (message._tDefaultSheet != null && Object.hasOwnProperty.call(message, "_tDefaultSheet"))
            writer.uint32(680).int64(message._tDefaultSheet);
        if (message.customStatus != null && Object.hasOwnProperty.call(message, "customStatus"))
            writer.uint32(690).string(message.customStatus);
        if (message.customType != null && Object.hasOwnProperty.call(message, "customType"))
            writer.uint32(698).string(message.customType);
        if (message._tCustomType != null && Object.hasOwnProperty.call(message, "_tCustomType"))
            writer.uint32(704).int64(message._tCustomType);
        if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
            writer.uint32(712).int64(message.deletedAt);
        if (message.tDeletedAt != null && Object.hasOwnProperty.call(message, "tDeletedAt"))
            writer.uint32(720).int64(message.tDeletedAt);
        if (message.updated != null && Object.hasOwnProperty.call(message, "updated"))
            writer.uint32(728).int64(message.updated);
        if (message.commented != null && Object.hasOwnProperty.call(message, "commented"))
            writer.uint32(736).int64(message.commented);
        return writer;
    };

    Issue.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Issue.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.int32();
                    break;
                }
            case 2: {
                    message.author = reader.string();
                    break;
                }
            case 3: {
                    message.guid = reader.string();
                    break;
                }
            case 4: {
                    message.created = reader.int64();
                    break;
                }
            case 5: {
                    message.camera = $root.Issue.CameraTransform.decode(reader, reader.uint32());
                    break;
                }
            case 42: {
                    message._tCamera = reader.int64();
                    break;
                }
            case 6: {
                    message.snapshot = $root.Issue.Snapshot.decode(reader, reader.uint32());
                    break;
                }
            case 43: {
                    message._tSnapshot = reader.int64();
                    break;
                }
            case 7: {
                    message.unread = reader.bool();
                    break;
                }
            case 8: {
                    message.unreadComments = reader.int32();
                    break;
                }
            case 9: {
                    message.reporter = reader.string();
                    break;
                }
            case 10: {
                    message.tReporter = reader.int64();
                    break;
                }
            case 11: {
                    if (!(message.lines && message.lines.length))
                        message.lines = [];
                    message.lines.push($root.Issue.Line.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    message.tLines = reader.int64();
                    break;
                }
            case 13: {
                    message.title = reader.string();
                    break;
                }
            case 14: {
                    message.tTitle = reader.int64();
                    break;
                }
            case 15: {
                    message.status = reader.int32();
                    break;
                }
            case 16: {
                    message.tStatus = reader.int64();
                    break;
                }
            case 17: {
                    message.assigned = reader.string();
                    break;
                }
            case 18: {
                    message.tAssigned = reader.int64();
                    break;
                }
            case 19: {
                    if (!(message.watchers && message.watchers.length))
                        message.watchers = [];
                    message.watchers.push(reader.string());
                    break;
                }
            case 20: {
                    message.tWatchers = reader.int64();
                    break;
                }
            case 21: {
                    message.visibility = reader.int32();
                    break;
                }
            case 22: {
                    message.tVisibility = reader.int64();
                    break;
                }
            case 23: {
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push(reader.string());
                    break;
                }
            case 24: {
                    message.tTags = reader.int64();
                    break;
                }
            case 25: {
                    message.deadline = reader.int64();
                    break;
                }
            case 26: {
                    message.tDeadline = reader.int64();
                    break;
                }
            case 27: {
                    if (!(message.comments && message.comments.length))
                        message.comments = [];
                    message.comments.push($root.Issue.Comment.decode(reader, reader.uint32()));
                    break;
                }
            case 28: {
                    message.lastChanges = reader.int64();
                    break;
                }
            case 29: {
                    message.lastOrderComments = reader.int32();
                    break;
                }
            case 30: {
                    message.lastRequestOrderComments = reader.int32();
                    break;
                }
            case 31: {
                    if (!(message.selections && message.selections.length))
                        message.selections = [];
                    message.selections.push($root.Issue.Selection.decode(reader, reader.uint32()));
                    break;
                }
            case 44: {
                    message._tSelections = reader.int64();
                    break;
                }
            case 32: {
                    if (!(message.sectioncuts && message.sectioncuts.length))
                        message.sectioncuts = [];
                    message.sectioncuts.push($root.Issue.Plane.decode(reader, reader.uint32()));
                    break;
                }
            case 45: {
                    message._tSectioncuts = reader.int64();
                    break;
                }
            case 33: {
                    message.sheet = $root.Issue.Sheet.decode(reader, reader.uint32());
                    break;
                }
            case 46: {
                    message._tSheet = reader.int64();
                    break;
                }
            case 34: {
                    message.clash = $root.Issue.Clash.decode(reader, reader.uint32());
                    break;
                }
            case 47: {
                    message._tClash = reader.int64();
                    break;
                }
            case 35: {
                    message.version = reader.int32();
                    break;
                }
            case 36: {
                    if (!(message.markups && message.markups.length))
                        message.markups = [];
                    message.markups.push($root.Issue.Markup.decode(reader, reader.uint32()));
                    break;
                }
            case 37: {
                    message.tMarkups = reader.int64();
                    break;
                }
            case 38: {
                    message.markupsCommentGuid = reader.string();
                    break;
                }
            case 39: {
                    message.visSelect = $root.Issue.VisSelect.decode(reader, reader.uint32());
                    break;
                }
            case 48: {
                    message._tVisSelect = reader.int64();
                    break;
                }
            case 40: {
                    message.colorSelect = $root.Issue.ColorSelect.decode(reader, reader.uint32());
                    break;
                }
            case 49: {
                    message._tColorSelect = reader.int64();
                    break;
                }
            case 41: {
                    message.phaseSelect = $root.Issue.PhaseSelect.decode(reader, reader.uint32());
                    break;
                }
            case 50: {
                    message._tPhaseSelect = reader.int64();
                    break;
                }
            case 51: {
                    message.selectionStorage = $root.Issue.SelectionStorage.decode(reader, reader.uint32());
                    break;
                }
            case 52: {
                    message.clashStorage = $root.Issue.ClashStorage.decode(reader, reader.uint32());
                    break;
                }
            case 53: {
                    message.priority = reader.int32();
                    break;
                }
            case 54: {
                    message.tPriority = reader.int64();
                    break;
                }
            case 55: {
                    message.bind3d = $root.Issue.Bind3d.decode(reader, reader.uint32());
                    break;
                }
            case 56: {
                    message._tBind3d = reader.int64();
                    break;
                }
            case 57: {
                    message.internalProperties = $root.Issue.InternalProperties.decode(reader, reader.uint32());
                    break;
                }
            case 58: {
                    message.tInternalProperties = reader.int64();
                    break;
                }
            case 59: {
                    message.stampColor = reader.int32();
                    break;
                }
            case 60: {
                    message.tStampColor = reader.int64();
                    break;
                }
            case 61: {
                    message.stampAbbr = reader.string();
                    break;
                }
            case 62: {
                    message.tStampAbbr = reader.int64();
                    break;
                }
            case 63: {
                    message.stampScale = reader.float();
                    break;
                }
            case 64: {
                    message.tStampScale = reader.int64();
                    break;
                }
            case 65: {
                    message.procore = $root.Issue.Procore.decode(reader, reader.uint32());
                    break;
                }
            case 66: {
                    message.tProcore = reader.int64();
                    break;
                }
            case 67: {
                    message.pin = $root.Issue.Pin.decode(reader, reader.uint32());
                    break;
                }
            case 68: {
                    message.tPin = reader.int64();
                    break;
                }
            case 69: {
                    message.cdeLinks = $root.Issue.CDELinks.decode(reader, reader.uint32());
                    break;
                }
            case 70: {
                    message.tCdeLinks = reader.int64();
                    break;
                }
            case 71: {
                    message.compareSheet = $root.Issue.CompareSheet.decode(reader, reader.uint32());
                    break;
                }
            case 72: {
                    message.tCompareSheet = reader.int64();
                    break;
                }
            case 73: {
                    message.visualOperations = $root.Issue.VisualOperations.decode(reader, reader.uint32());
                    break;
                }
            case 74: {
                    message._tVisualOperations = reader.int64();
                    break;
                }
            case 75: {
                    if (!(message.selectionNames && message.selectionNames.length))
                        message.selectionNames = [];
                    message.selectionNames.push($root.Issue.SelectionName.decode(reader, reader.uint32()));
                    break;
                }
            case 76: {
                    message._tSelectionNames = reader.int64();
                    break;
                }
            case 77: {
                    message.selectionNameStorage = $root.Issue.SelectionNameStorage.decode(reader, reader.uint32());
                    break;
                }
            case 78: {
                    message.rclash = $root.Issue.RClash.decode(reader, reader.uint32());
                    break;
                }
            case 79: {
                    message._tRclash = reader.int64();
                    break;
                }
            case 80: {
                    message.rClashStorage = $root.Issue.RClashStorage.decode(reader, reader.uint32());
                    break;
                }
            case 81: {
                    message.statusAuto = reader.bool();
                    break;
                }
            case 82: {
                    message.positionProperties = $root.Issue.Prop.decode(reader, reader.uint32());
                    break;
                }
            case 83: {
                    message._tProp = reader.int64();
                    break;
                }
            case 84: {
                    message.defaultSheet = $root.Issue.DefaultSheet.decode(reader, reader.uint32());
                    break;
                }
            case 85: {
                    message._tDefaultSheet = reader.int64();
                    break;
                }
            case 86: {
                    message.customStatus = reader.string();
                    break;
                }
            case 87: {
                    message.customType = reader.string();
                    break;
                }
            case 88: {
                    message._tCustomType = reader.int64();
                    break;
                }
            case 89: {
                    message.deletedAt = reader.int64();
                    break;
                }
            case 90: {
                    message.tDeletedAt = reader.int64();
                    break;
                }
            case 91: {
                    message.updated = reader.int64();
                    break;
                }
            case 92: {
                    message.commented = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Issue.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Issue.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id))
                return "id: integer expected";
        if (message.author != null && message.hasOwnProperty("author"))
            if (!$util.isString(message.author))
                return "author: string expected";
        if (message.guid != null && message.hasOwnProperty("guid"))
            if (!$util.isString(message.guid))
                return "guid: string expected";
        if (message.created != null && message.hasOwnProperty("created"))
            if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                return "created: integer|Long expected";
        if (message.camera != null && message.hasOwnProperty("camera")) {
            let error = $root.Issue.CameraTransform.verify(message.camera);
            if (error)
                return "camera." + error;
        }
        if (message._tCamera != null && message.hasOwnProperty("_tCamera"))
            if (!$util.isInteger(message._tCamera) && !(message._tCamera && $util.isInteger(message._tCamera.low) && $util.isInteger(message._tCamera.high)))
                return "_tCamera: integer|Long expected";
        if (message.snapshot != null && message.hasOwnProperty("snapshot")) {
            let error = $root.Issue.Snapshot.verify(message.snapshot);
            if (error)
                return "snapshot." + error;
        }
        if (message._tSnapshot != null && message.hasOwnProperty("_tSnapshot"))
            if (!$util.isInteger(message._tSnapshot) && !(message._tSnapshot && $util.isInteger(message._tSnapshot.low) && $util.isInteger(message._tSnapshot.high)))
                return "_tSnapshot: integer|Long expected";
        if (message.unread != null && message.hasOwnProperty("unread"))
            if (typeof message.unread !== "boolean")
                return "unread: boolean expected";
        if (message.unreadComments != null && message.hasOwnProperty("unreadComments"))
            if (!$util.isInteger(message.unreadComments))
                return "unreadComments: integer expected";
        if (message.reporter != null && message.hasOwnProperty("reporter"))
            if (!$util.isString(message.reporter))
                return "reporter: string expected";
        if (message.tReporter != null && message.hasOwnProperty("tReporter"))
            if (!$util.isInteger(message.tReporter) && !(message.tReporter && $util.isInteger(message.tReporter.low) && $util.isInteger(message.tReporter.high)))
                return "tReporter: integer|Long expected";
        if (message.lines != null && message.hasOwnProperty("lines")) {
            if (!Array.isArray(message.lines))
                return "lines: array expected";
            for (let i = 0; i < message.lines.length; ++i) {
                let error = $root.Issue.Line.verify(message.lines[i]);
                if (error)
                    return "lines." + error;
            }
        }
        if (message.tLines != null && message.hasOwnProperty("tLines"))
            if (!$util.isInteger(message.tLines) && !(message.tLines && $util.isInteger(message.tLines.low) && $util.isInteger(message.tLines.high)))
                return "tLines: integer|Long expected";
        if (message.title != null && message.hasOwnProperty("title"))
            if (!$util.isString(message.title))
                return "title: string expected";
        if (message.tTitle != null && message.hasOwnProperty("tTitle"))
            if (!$util.isInteger(message.tTitle) && !(message.tTitle && $util.isInteger(message.tTitle.low) && $util.isInteger(message.tTitle.high)))
                return "tTitle: integer|Long expected";
        if (message.status != null && message.hasOwnProperty("status"))
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.tStatus != null && message.hasOwnProperty("tStatus"))
            if (!$util.isInteger(message.tStatus) && !(message.tStatus && $util.isInteger(message.tStatus.low) && $util.isInteger(message.tStatus.high)))
                return "tStatus: integer|Long expected";
        if (message.assigned != null && message.hasOwnProperty("assigned"))
            if (!$util.isString(message.assigned))
                return "assigned: string expected";
        if (message.tAssigned != null && message.hasOwnProperty("tAssigned"))
            if (!$util.isInteger(message.tAssigned) && !(message.tAssigned && $util.isInteger(message.tAssigned.low) && $util.isInteger(message.tAssigned.high)))
                return "tAssigned: integer|Long expected";
        if (message.watchers != null && message.hasOwnProperty("watchers")) {
            if (!Array.isArray(message.watchers))
                return "watchers: array expected";
            for (let i = 0; i < message.watchers.length; ++i)
                if (!$util.isString(message.watchers[i]))
                    return "watchers: string[] expected";
        }
        if (message.tWatchers != null && message.hasOwnProperty("tWatchers"))
            if (!$util.isInteger(message.tWatchers) && !(message.tWatchers && $util.isInteger(message.tWatchers.low) && $util.isInteger(message.tWatchers.high)))
                return "tWatchers: integer|Long expected";
        if (message.visibility != null && message.hasOwnProperty("visibility"))
            switch (message.visibility) {
            default:
                return "visibility: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.tVisibility != null && message.hasOwnProperty("tVisibility"))
            if (!$util.isInteger(message.tVisibility) && !(message.tVisibility && $util.isInteger(message.tVisibility.low) && $util.isInteger(message.tVisibility.high)))
                return "tVisibility: integer|Long expected";
        if (message.tags != null && message.hasOwnProperty("tags")) {
            if (!Array.isArray(message.tags))
                return "tags: array expected";
            for (let i = 0; i < message.tags.length; ++i)
                if (!$util.isString(message.tags[i]))
                    return "tags: string[] expected";
        }
        if (message.tTags != null && message.hasOwnProperty("tTags"))
            if (!$util.isInteger(message.tTags) && !(message.tTags && $util.isInteger(message.tTags.low) && $util.isInteger(message.tTags.high)))
                return "tTags: integer|Long expected";
        if (message.deadline != null && message.hasOwnProperty("deadline"))
            if (!$util.isInteger(message.deadline) && !(message.deadline && $util.isInteger(message.deadline.low) && $util.isInteger(message.deadline.high)))
                return "deadline: integer|Long expected";
        if (message.tDeadline != null && message.hasOwnProperty("tDeadline"))
            if (!$util.isInteger(message.tDeadline) && !(message.tDeadline && $util.isInteger(message.tDeadline.low) && $util.isInteger(message.tDeadline.high)))
                return "tDeadline: integer|Long expected";
        if (message.comments != null && message.hasOwnProperty("comments")) {
            if (!Array.isArray(message.comments))
                return "comments: array expected";
            for (let i = 0; i < message.comments.length; ++i) {
                let error = $root.Issue.Comment.verify(message.comments[i]);
                if (error)
                    return "comments." + error;
            }
        }
        if (message.lastChanges != null && message.hasOwnProperty("lastChanges"))
            if (!$util.isInteger(message.lastChanges) && !(message.lastChanges && $util.isInteger(message.lastChanges.low) && $util.isInteger(message.lastChanges.high)))
                return "lastChanges: integer|Long expected";
        if (message.lastOrderComments != null && message.hasOwnProperty("lastOrderComments"))
            if (!$util.isInteger(message.lastOrderComments))
                return "lastOrderComments: integer expected";
        if (message.lastRequestOrderComments != null && message.hasOwnProperty("lastRequestOrderComments"))
            if (!$util.isInteger(message.lastRequestOrderComments))
                return "lastRequestOrderComments: integer expected";
        if (message.selections != null && message.hasOwnProperty("selections")) {
            if (!Array.isArray(message.selections))
                return "selections: array expected";
            for (let i = 0; i < message.selections.length; ++i) {
                let error = $root.Issue.Selection.verify(message.selections[i]);
                if (error)
                    return "selections." + error;
            }
        }
        if (message._tSelections != null && message.hasOwnProperty("_tSelections"))
            if (!$util.isInteger(message._tSelections) && !(message._tSelections && $util.isInteger(message._tSelections.low) && $util.isInteger(message._tSelections.high)))
                return "_tSelections: integer|Long expected";
        if (message.sectioncuts != null && message.hasOwnProperty("sectioncuts")) {
            if (!Array.isArray(message.sectioncuts))
                return "sectioncuts: array expected";
            for (let i = 0; i < message.sectioncuts.length; ++i) {
                let error = $root.Issue.Plane.verify(message.sectioncuts[i]);
                if (error)
                    return "sectioncuts." + error;
            }
        }
        if (message._tSectioncuts != null && message.hasOwnProperty("_tSectioncuts"))
            if (!$util.isInteger(message._tSectioncuts) && !(message._tSectioncuts && $util.isInteger(message._tSectioncuts.low) && $util.isInteger(message._tSectioncuts.high)))
                return "_tSectioncuts: integer|Long expected";
        if (message.sheet != null && message.hasOwnProperty("sheet")) {
            let error = $root.Issue.Sheet.verify(message.sheet);
            if (error)
                return "sheet." + error;
        }
        if (message._tSheet != null && message.hasOwnProperty("_tSheet"))
            if (!$util.isInteger(message._tSheet) && !(message._tSheet && $util.isInteger(message._tSheet.low) && $util.isInteger(message._tSheet.high)))
                return "_tSheet: integer|Long expected";
        if (message.clash != null && message.hasOwnProperty("clash")) {
            let error = $root.Issue.Clash.verify(message.clash);
            if (error)
                return "clash." + error;
        }
        if (message._tClash != null && message.hasOwnProperty("_tClash"))
            if (!$util.isInteger(message._tClash) && !(message._tClash && $util.isInteger(message._tClash.low) && $util.isInteger(message._tClash.high)))
                return "_tClash: integer|Long expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isInteger(message.version))
                return "version: integer expected";
        if (message.markups != null && message.hasOwnProperty("markups")) {
            if (!Array.isArray(message.markups))
                return "markups: array expected";
            for (let i = 0; i < message.markups.length; ++i) {
                let error = $root.Issue.Markup.verify(message.markups[i]);
                if (error)
                    return "markups." + error;
            }
        }
        if (message.tMarkups != null && message.hasOwnProperty("tMarkups"))
            if (!$util.isInteger(message.tMarkups) && !(message.tMarkups && $util.isInteger(message.tMarkups.low) && $util.isInteger(message.tMarkups.high)))
                return "tMarkups: integer|Long expected";
        if (message.markupsCommentGuid != null && message.hasOwnProperty("markupsCommentGuid"))
            if (!$util.isString(message.markupsCommentGuid))
                return "markupsCommentGuid: string expected";
        if (message.visSelect != null && message.hasOwnProperty("visSelect")) {
            let error = $root.Issue.VisSelect.verify(message.visSelect);
            if (error)
                return "visSelect." + error;
        }
        if (message._tVisSelect != null && message.hasOwnProperty("_tVisSelect"))
            if (!$util.isInteger(message._tVisSelect) && !(message._tVisSelect && $util.isInteger(message._tVisSelect.low) && $util.isInteger(message._tVisSelect.high)))
                return "_tVisSelect: integer|Long expected";
        if (message.colorSelect != null && message.hasOwnProperty("colorSelect")) {
            let error = $root.Issue.ColorSelect.verify(message.colorSelect);
            if (error)
                return "colorSelect." + error;
        }
        if (message._tColorSelect != null && message.hasOwnProperty("_tColorSelect"))
            if (!$util.isInteger(message._tColorSelect) && !(message._tColorSelect && $util.isInteger(message._tColorSelect.low) && $util.isInteger(message._tColorSelect.high)))
                return "_tColorSelect: integer|Long expected";
        if (message.phaseSelect != null && message.hasOwnProperty("phaseSelect")) {
            let error = $root.Issue.PhaseSelect.verify(message.phaseSelect);
            if (error)
                return "phaseSelect." + error;
        }
        if (message._tPhaseSelect != null && message.hasOwnProperty("_tPhaseSelect"))
            if (!$util.isInteger(message._tPhaseSelect) && !(message._tPhaseSelect && $util.isInteger(message._tPhaseSelect.low) && $util.isInteger(message._tPhaseSelect.high)))
                return "_tPhaseSelect: integer|Long expected";
        if (message.selectionStorage != null && message.hasOwnProperty("selectionStorage")) {
            let error = $root.Issue.SelectionStorage.verify(message.selectionStorage);
            if (error)
                return "selectionStorage." + error;
        }
        if (message.clashStorage != null && message.hasOwnProperty("clashStorage")) {
            let error = $root.Issue.ClashStorage.verify(message.clashStorage);
            if (error)
                return "clashStorage." + error;
        }
        if (message.priority != null && message.hasOwnProperty("priority"))
            switch (message.priority) {
            default:
                return "priority: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.tPriority != null && message.hasOwnProperty("tPriority"))
            if (!$util.isInteger(message.tPriority) && !(message.tPriority && $util.isInteger(message.tPriority.low) && $util.isInteger(message.tPriority.high)))
                return "tPriority: integer|Long expected";
        if (message.bind3d != null && message.hasOwnProperty("bind3d")) {
            let error = $root.Issue.Bind3d.verify(message.bind3d);
            if (error)
                return "bind3d." + error;
        }
        if (message._tBind3d != null && message.hasOwnProperty("_tBind3d"))
            if (!$util.isInteger(message._tBind3d) && !(message._tBind3d && $util.isInteger(message._tBind3d.low) && $util.isInteger(message._tBind3d.high)))
                return "_tBind3d: integer|Long expected";
        if (message.internalProperties != null && message.hasOwnProperty("internalProperties")) {
            let error = $root.Issue.InternalProperties.verify(message.internalProperties);
            if (error)
                return "internalProperties." + error;
        }
        if (message.tInternalProperties != null && message.hasOwnProperty("tInternalProperties"))
            if (!$util.isInteger(message.tInternalProperties) && !(message.tInternalProperties && $util.isInteger(message.tInternalProperties.low) && $util.isInteger(message.tInternalProperties.high)))
                return "tInternalProperties: integer|Long expected";
        if (message.stampColor != null && message.hasOwnProperty("stampColor"))
            if (!$util.isInteger(message.stampColor))
                return "stampColor: integer expected";
        if (message.tStampColor != null && message.hasOwnProperty("tStampColor"))
            if (!$util.isInteger(message.tStampColor) && !(message.tStampColor && $util.isInteger(message.tStampColor.low) && $util.isInteger(message.tStampColor.high)))
                return "tStampColor: integer|Long expected";
        if (message.stampAbbr != null && message.hasOwnProperty("stampAbbr"))
            if (!$util.isString(message.stampAbbr))
                return "stampAbbr: string expected";
        if (message.tStampAbbr != null && message.hasOwnProperty("tStampAbbr"))
            if (!$util.isInteger(message.tStampAbbr) && !(message.tStampAbbr && $util.isInteger(message.tStampAbbr.low) && $util.isInteger(message.tStampAbbr.high)))
                return "tStampAbbr: integer|Long expected";
        if (message.stampScale != null && message.hasOwnProperty("stampScale"))
            if (typeof message.stampScale !== "number")
                return "stampScale: number expected";
        if (message.tStampScale != null && message.hasOwnProperty("tStampScale"))
            if (!$util.isInteger(message.tStampScale) && !(message.tStampScale && $util.isInteger(message.tStampScale.low) && $util.isInteger(message.tStampScale.high)))
                return "tStampScale: integer|Long expected";
        if (message.procore != null && message.hasOwnProperty("procore")) {
            let error = $root.Issue.Procore.verify(message.procore);
            if (error)
                return "procore." + error;
        }
        if (message.tProcore != null && message.hasOwnProperty("tProcore"))
            if (!$util.isInteger(message.tProcore) && !(message.tProcore && $util.isInteger(message.tProcore.low) && $util.isInteger(message.tProcore.high)))
                return "tProcore: integer|Long expected";
        if (message.pin != null && message.hasOwnProperty("pin")) {
            let error = $root.Issue.Pin.verify(message.pin);
            if (error)
                return "pin." + error;
        }
        if (message.tPin != null && message.hasOwnProperty("tPin"))
            if (!$util.isInteger(message.tPin) && !(message.tPin && $util.isInteger(message.tPin.low) && $util.isInteger(message.tPin.high)))
                return "tPin: integer|Long expected";
        if (message.cdeLinks != null && message.hasOwnProperty("cdeLinks")) {
            let error = $root.Issue.CDELinks.verify(message.cdeLinks);
            if (error)
                return "cdeLinks." + error;
        }
        if (message.tCdeLinks != null && message.hasOwnProperty("tCdeLinks"))
            if (!$util.isInteger(message.tCdeLinks) && !(message.tCdeLinks && $util.isInteger(message.tCdeLinks.low) && $util.isInteger(message.tCdeLinks.high)))
                return "tCdeLinks: integer|Long expected";
        if (message.compareSheet != null && message.hasOwnProperty("compareSheet")) {
            let error = $root.Issue.CompareSheet.verify(message.compareSheet);
            if (error)
                return "compareSheet." + error;
        }
        if (message.tCompareSheet != null && message.hasOwnProperty("tCompareSheet"))
            if (!$util.isInteger(message.tCompareSheet) && !(message.tCompareSheet && $util.isInteger(message.tCompareSheet.low) && $util.isInteger(message.tCompareSheet.high)))
                return "tCompareSheet: integer|Long expected";
        if (message.visualOperations != null && message.hasOwnProperty("visualOperations")) {
            let error = $root.Issue.VisualOperations.verify(message.visualOperations);
            if (error)
                return "visualOperations." + error;
        }
        if (message._tVisualOperations != null && message.hasOwnProperty("_tVisualOperations"))
            if (!$util.isInteger(message._tVisualOperations) && !(message._tVisualOperations && $util.isInteger(message._tVisualOperations.low) && $util.isInteger(message._tVisualOperations.high)))
                return "_tVisualOperations: integer|Long expected";
        if (message.selectionNames != null && message.hasOwnProperty("selectionNames")) {
            if (!Array.isArray(message.selectionNames))
                return "selectionNames: array expected";
            for (let i = 0; i < message.selectionNames.length; ++i) {
                let error = $root.Issue.SelectionName.verify(message.selectionNames[i]);
                if (error)
                    return "selectionNames." + error;
            }
        }
        if (message._tSelectionNames != null && message.hasOwnProperty("_tSelectionNames"))
            if (!$util.isInteger(message._tSelectionNames) && !(message._tSelectionNames && $util.isInteger(message._tSelectionNames.low) && $util.isInteger(message._tSelectionNames.high)))
                return "_tSelectionNames: integer|Long expected";
        if (message.selectionNameStorage != null && message.hasOwnProperty("selectionNameStorage")) {
            let error = $root.Issue.SelectionNameStorage.verify(message.selectionNameStorage);
            if (error)
                return "selectionNameStorage." + error;
        }
        if (message.rclash != null && message.hasOwnProperty("rclash")) {
            let error = $root.Issue.RClash.verify(message.rclash);
            if (error)
                return "rclash." + error;
        }
        if (message._tRclash != null && message.hasOwnProperty("_tRclash"))
            if (!$util.isInteger(message._tRclash) && !(message._tRclash && $util.isInteger(message._tRclash.low) && $util.isInteger(message._tRclash.high)))
                return "_tRclash: integer|Long expected";
        if (message.rClashStorage != null && message.hasOwnProperty("rClashStorage")) {
            let error = $root.Issue.RClashStorage.verify(message.rClashStorage);
            if (error)
                return "rClashStorage." + error;
        }
        if (message.statusAuto != null && message.hasOwnProperty("statusAuto"))
            if (typeof message.statusAuto !== "boolean")
                return "statusAuto: boolean expected";
        if (message.positionProperties != null && message.hasOwnProperty("positionProperties")) {
            let error = $root.Issue.Prop.verify(message.positionProperties);
            if (error)
                return "positionProperties." + error;
        }
        if (message._tProp != null && message.hasOwnProperty("_tProp"))
            if (!$util.isInteger(message._tProp) && !(message._tProp && $util.isInteger(message._tProp.low) && $util.isInteger(message._tProp.high)))
                return "_tProp: integer|Long expected";
        if (message.defaultSheet != null && message.hasOwnProperty("defaultSheet")) {
            let error = $root.Issue.DefaultSheet.verify(message.defaultSheet);
            if (error)
                return "defaultSheet." + error;
        }
        if (message._tDefaultSheet != null && message.hasOwnProperty("_tDefaultSheet"))
            if (!$util.isInteger(message._tDefaultSheet) && !(message._tDefaultSheet && $util.isInteger(message._tDefaultSheet.low) && $util.isInteger(message._tDefaultSheet.high)))
                return "_tDefaultSheet: integer|Long expected";
        if (message.customStatus != null && message.hasOwnProperty("customStatus"))
            if (!$util.isString(message.customStatus))
                return "customStatus: string expected";
        if (message.customType != null && message.hasOwnProperty("customType"))
            if (!$util.isString(message.customType))
                return "customType: string expected";
        if (message._tCustomType != null && message.hasOwnProperty("_tCustomType"))
            if (!$util.isInteger(message._tCustomType) && !(message._tCustomType && $util.isInteger(message._tCustomType.low) && $util.isInteger(message._tCustomType.high)))
                return "_tCustomType: integer|Long expected";
        if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
            if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                return "deletedAt: integer|Long expected";
        if (message.tDeletedAt != null && message.hasOwnProperty("tDeletedAt"))
            if (!$util.isInteger(message.tDeletedAt) && !(message.tDeletedAt && $util.isInteger(message.tDeletedAt.low) && $util.isInteger(message.tDeletedAt.high)))
                return "tDeletedAt: integer|Long expected";
        if (message.updated != null && message.hasOwnProperty("updated"))
            if (!$util.isInteger(message.updated) && !(message.updated && $util.isInteger(message.updated.low) && $util.isInteger(message.updated.high)))
                return "updated: integer|Long expected";
        if (message.commented != null && message.hasOwnProperty("commented"))
            if (!$util.isInteger(message.commented) && !(message.commented && $util.isInteger(message.commented.low) && $util.isInteger(message.commented.high)))
                return "commented: integer|Long expected";
        return null;
    };

    Issue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Issue";
    };

    Issue.StatusEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Open"] = 0;
        values[valuesById[1] = "InProgress"] = 1;
        values[valuesById[2] = "Solved"] = 2;
        values[valuesById[3] = "Closed"] = 3;
        values[valuesById[4] = "Deleted"] = 4;
        return values;
    })();

    Issue.PriorityEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PriorityEnumNone"] = 0;
        values[valuesById[1] = "Trivial"] = 1;
        values[valuesById[2] = "Minor"] = 2;
        values[valuesById[3] = "Major"] = 3;
        values[valuesById[4] = "Critical"] = 4;
        values[valuesById[5] = "Blocker"] = 5;
        return values;
    })();

    Issue.VisibilityEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "All"] = 0;
        values[valuesById[1] = "Watchers"] = 1;
        return values;
    })();

    Issue.Vector2 = (function() {

        function Vector2(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Vector2.prototype.x = 0;
        Vector2.prototype.y = 0;

        Vector2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(13).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(21).float(message.y);
            return writer;
        };

        Vector2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Vector2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Vector2();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.x = reader.float();
                        break;
                    }
                case 2: {
                        message.y = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Vector2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Vector2.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (typeof message.y !== "number")
                    return "y: number expected";
            return null;
        };

        Vector2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Vector2";
        };

        return Vector2;
    })();

    Issue.Plane = (function() {

        function Plane(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Plane.prototype.normal = null;
        Plane.prototype.dist = 0;
        Plane.prototype.existBind = false;
        Plane.prototype.normalBind = null;
        Plane.prototype.distBind = 0;

        Plane.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.normal != null && Object.hasOwnProperty.call(message, "normal"))
                $root.Vector3.encode(message.normal, writer.uint32(10).fork()).ldelim();
            if (message.dist != null && Object.hasOwnProperty.call(message, "dist"))
                writer.uint32(21).float(message.dist);
            if (message.existBind != null && Object.hasOwnProperty.call(message, "existBind"))
                writer.uint32(24).bool(message.existBind);
            if (message.normalBind != null && Object.hasOwnProperty.call(message, "normalBind"))
                $root.Vector3.encode(message.normalBind, writer.uint32(34).fork()).ldelim();
            if (message.distBind != null && Object.hasOwnProperty.call(message, "distBind"))
                writer.uint32(41).double(message.distBind);
            return writer;
        };

        Plane.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Plane.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Plane();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.normal = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.dist = reader.float();
                        break;
                    }
                case 3: {
                        message.existBind = reader.bool();
                        break;
                    }
                case 4: {
                        message.normalBind = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.distBind = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Plane.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Plane.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.normal != null && message.hasOwnProperty("normal")) {
                let error = $root.Vector3.verify(message.normal);
                if (error)
                    return "normal." + error;
            }
            if (message.dist != null && message.hasOwnProperty("dist"))
                if (typeof message.dist !== "number")
                    return "dist: number expected";
            if (message.existBind != null && message.hasOwnProperty("existBind"))
                if (typeof message.existBind !== "boolean")
                    return "existBind: boolean expected";
            if (message.normalBind != null && message.hasOwnProperty("normalBind")) {
                let error = $root.Vector3.verify(message.normalBind);
                if (error)
                    return "normalBind." + error;
            }
            if (message.distBind != null && message.hasOwnProperty("distBind"))
                if (typeof message.distBind !== "number")
                    return "distBind: number expected";
            return null;
        };

        Plane.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Plane";
        };

        return Plane;
    })();

    Issue.CameraTransform = (function() {

        function CameraTransform(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CameraTransform.prototype.position = null;
        CameraTransform.prototype.forward = null;
        CameraTransform.prototype.up = null;
        CameraTransform.prototype.fov = 0;
        CameraTransform.prototype.viewToWorldScale = 0;
        CameraTransform.prototype.type = 0;
        CameraTransform.prototype.existTarget = false;
        CameraTransform.prototype.target = null;
        CameraTransform.prototype.existBind = false;
        CameraTransform.prototype.positionBind = null;
        CameraTransform.prototype.forwardBind = null;
        CameraTransform.prototype.upBind = null;
        CameraTransform.prototype.targetBind = null;

        CameraTransform.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                $root.Vector3.encode(message.position, writer.uint32(10).fork()).ldelim();
            if (message.forward != null && Object.hasOwnProperty.call(message, "forward"))
                $root.Vector3.encode(message.forward, writer.uint32(18).fork()).ldelim();
            if (message.up != null && Object.hasOwnProperty.call(message, "up"))
                $root.Vector3.encode(message.up, writer.uint32(26).fork()).ldelim();
            if (message.fov != null && Object.hasOwnProperty.call(message, "fov"))
                writer.uint32(37).float(message.fov);
            if (message.viewToWorldScale != null && Object.hasOwnProperty.call(message, "viewToWorldScale"))
                writer.uint32(45).float(message.viewToWorldScale);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(48).int32(message.type);
            if (message.existTarget != null && Object.hasOwnProperty.call(message, "existTarget"))
                writer.uint32(56).bool(message.existTarget);
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                $root.Vector3.encode(message.target, writer.uint32(66).fork()).ldelim();
            if (message.existBind != null && Object.hasOwnProperty.call(message, "existBind"))
                writer.uint32(72).bool(message.existBind);
            if (message.positionBind != null && Object.hasOwnProperty.call(message, "positionBind"))
                $root.Vector3d.encode(message.positionBind, writer.uint32(82).fork()).ldelim();
            if (message.forwardBind != null && Object.hasOwnProperty.call(message, "forwardBind"))
                $root.Vector3.encode(message.forwardBind, writer.uint32(90).fork()).ldelim();
            if (message.upBind != null && Object.hasOwnProperty.call(message, "upBind"))
                $root.Vector3.encode(message.upBind, writer.uint32(98).fork()).ldelim();
            if (message.targetBind != null && Object.hasOwnProperty.call(message, "targetBind"))
                $root.Vector3d.encode(message.targetBind, writer.uint32(106).fork()).ldelim();
            return writer;
        };

        CameraTransform.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        CameraTransform.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.CameraTransform();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.position = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.forward = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.up = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.fov = reader.float();
                        break;
                    }
                case 5: {
                        message.viewToWorldScale = reader.float();
                        break;
                    }
                case 6: {
                        message.type = reader.int32();
                        break;
                    }
                case 7: {
                        message.existTarget = reader.bool();
                        break;
                    }
                case 8: {
                        message.target = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.existBind = reader.bool();
                        break;
                    }
                case 10: {
                        message.positionBind = $root.Vector3d.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.forwardBind = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.upBind = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.targetBind = $root.Vector3d.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CameraTransform.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        CameraTransform.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.position != null && message.hasOwnProperty("position")) {
                let error = $root.Vector3.verify(message.position);
                if (error)
                    return "position." + error;
            }
            if (message.forward != null && message.hasOwnProperty("forward")) {
                let error = $root.Vector3.verify(message.forward);
                if (error)
                    return "forward." + error;
            }
            if (message.up != null && message.hasOwnProperty("up")) {
                let error = $root.Vector3.verify(message.up);
                if (error)
                    return "up." + error;
            }
            if (message.fov != null && message.hasOwnProperty("fov"))
                if (typeof message.fov !== "number")
                    return "fov: number expected";
            if (message.viewToWorldScale != null && message.hasOwnProperty("viewToWorldScale"))
                if (typeof message.viewToWorldScale !== "number")
                    return "viewToWorldScale: number expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.existTarget != null && message.hasOwnProperty("existTarget"))
                if (typeof message.existTarget !== "boolean")
                    return "existTarget: boolean expected";
            if (message.target != null && message.hasOwnProperty("target")) {
                let error = $root.Vector3.verify(message.target);
                if (error)
                    return "target." + error;
            }
            if (message.existBind != null && message.hasOwnProperty("existBind"))
                if (typeof message.existBind !== "boolean")
                    return "existBind: boolean expected";
            if (message.positionBind != null && message.hasOwnProperty("positionBind")) {
                let error = $root.Vector3d.verify(message.positionBind);
                if (error)
                    return "positionBind." + error;
            }
            if (message.forwardBind != null && message.hasOwnProperty("forwardBind")) {
                let error = $root.Vector3.verify(message.forwardBind);
                if (error)
                    return "forwardBind." + error;
            }
            if (message.upBind != null && message.hasOwnProperty("upBind")) {
                let error = $root.Vector3.verify(message.upBind);
                if (error)
                    return "upBind." + error;
            }
            if (message.targetBind != null && message.hasOwnProperty("targetBind")) {
                let error = $root.Vector3d.verify(message.targetBind);
                if (error)
                    return "targetBind." + error;
            }
            return null;
        };

        CameraTransform.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.CameraTransform";
        };

        CameraTransform.CameraType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Perspective"] = 0;
            values[valuesById[1] = "Orthogonal"] = 1;
            return values;
        })();

        return CameraTransform;
    })();

    Issue.Line = (function() {

        function Line(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Line.prototype.color = 0;
        Line.prototype.start = null;
        Line.prototype.end = null;

        Line.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(8).int32(message.color);
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.Vector3.encode(message.start, writer.uint32(18).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.Vector3.encode(message.end, writer.uint32(26).fork()).ldelim();
            return writer;
        };

        Line.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Line.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Line();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.color = reader.int32();
                        break;
                    }
                case 2: {
                        message.start = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.end = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Line.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Line.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isInteger(message.color))
                    return "color: integer expected";
            if (message.start != null && message.hasOwnProperty("start")) {
                let error = $root.Vector3.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.Vector3.verify(message.end);
                if (error)
                    return "end." + error;
            }
            return null;
        };

        Line.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Line";
        };

        return Line;
    })();

    Issue.Snapshot = (function() {

        function Snapshot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Snapshot.prototype.links = null;
        Snapshot.prototype.filename = "";
        Snapshot.prototype.bufferOriginal = $util.newBuffer([]);
        Snapshot.prototype.bufferSmall = $util.newBuffer([]);
        Snapshot.prototype.subMarkupsType = 0;

        Snapshot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.links != null && Object.hasOwnProperty.call(message, "links"))
                $root.Issue.Snapshot.LinksStruct.encode(message.links, writer.uint32(10).fork()).ldelim();
            if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                writer.uint32(18).string(message.filename);
            if (message.bufferOriginal != null && Object.hasOwnProperty.call(message, "bufferOriginal"))
                writer.uint32(26).bytes(message.bufferOriginal);
            if (message.bufferSmall != null && Object.hasOwnProperty.call(message, "bufferSmall"))
                writer.uint32(34).bytes(message.bufferSmall);
            if (message.subMarkupsType != null && Object.hasOwnProperty.call(message, "subMarkupsType"))
                writer.uint32(40).int32(message.subMarkupsType);
            return writer;
        };

        Snapshot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Snapshot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Snapshot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.links = $root.Issue.Snapshot.LinksStruct.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.filename = reader.string();
                        break;
                    }
                case 3: {
                        message.bufferOriginal = reader.bytes();
                        break;
                    }
                case 4: {
                        message.bufferSmall = reader.bytes();
                        break;
                    }
                case 5: {
                        message.subMarkupsType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Snapshot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Snapshot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.links != null && message.hasOwnProperty("links")) {
                let error = $root.Issue.Snapshot.LinksStruct.verify(message.links);
                if (error)
                    return "links." + error;
            }
            if (message.filename != null && message.hasOwnProperty("filename"))
                if (!$util.isString(message.filename))
                    return "filename: string expected";
            if (message.bufferOriginal != null && message.hasOwnProperty("bufferOriginal"))
                if (!(message.bufferOriginal && typeof message.bufferOriginal.length === "number" || $util.isString(message.bufferOriginal)))
                    return "bufferOriginal: buffer expected";
            if (message.bufferSmall != null && message.hasOwnProperty("bufferSmall"))
                if (!(message.bufferSmall && typeof message.bufferSmall.length === "number" || $util.isString(message.bufferSmall)))
                    return "bufferSmall: buffer expected";
            if (message.subMarkupsType != null && message.hasOwnProperty("subMarkupsType"))
                if (!$util.isInteger(message.subMarkupsType))
                    return "subMarkupsType: integer expected";
            return null;
        };

        Snapshot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Snapshot";
        };

        Snapshot.LinksStruct = (function() {

            function LinksStruct(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            LinksStruct.prototype.original = "";
            LinksStruct.prototype.small = "";

            LinksStruct.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                    writer.uint32(10).string(message.original);
                if (message.small != null && Object.hasOwnProperty.call(message, "small"))
                    writer.uint32(18).string(message.small);
                return writer;
            };

            LinksStruct.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            LinksStruct.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Snapshot.LinksStruct();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.original = reader.string();
                            break;
                        }
                    case 2: {
                            message.small = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            LinksStruct.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            LinksStruct.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.original != null && message.hasOwnProperty("original"))
                    if (!$util.isString(message.original))
                        return "original: string expected";
                if (message.small != null && message.hasOwnProperty("small"))
                    if (!$util.isString(message.small))
                        return "small: string expected";
                return null;
            };

            LinksStruct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.Snapshot.LinksStruct";
            };

            return LinksStruct;
        })();

        return Snapshot;
    })();

    Issue.Sheet = (function() {

        function Sheet(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Sheet.prototype.name = "";
        Sheet.prototype.number = "";
        Sheet.prototype.id = 0;
        Sheet.prototype.guid = "";
        Sheet.prototype.viewCenter = null;
        Sheet.prototype.viewScale = 0;
        Sheet.prototype.viewMarker = null;
        Sheet.prototype.portId = "";
        Sheet.prototype.position3d = null;
        Sheet.prototype.is3d = false;
        Sheet.prototype.aspect = 0;
        Sheet.prototype.uuid = "";
        Sheet.prototype.existBind = false;
        Sheet.prototype.position3dBind = null;
        Sheet.prototype.revision = null;

        Sheet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(10).string(message.name);
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(18).string(message.number);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(24).int32(message.id);
            if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                writer.uint32(34).string(message.guid);
            if (message.viewCenter != null && Object.hasOwnProperty.call(message, "viewCenter"))
                $root.Issue.Vector2.encode(message.viewCenter, writer.uint32(42).fork()).ldelim();
            if (message.viewScale != null && Object.hasOwnProperty.call(message, "viewScale"))
                writer.uint32(53).float(message.viewScale);
            if (message.viewMarker != null && Object.hasOwnProperty.call(message, "viewMarker"))
                $root.Issue.Vector2.encode(message.viewMarker, writer.uint32(58).fork()).ldelim();
            if (message.portId != null && Object.hasOwnProperty.call(message, "portId"))
                writer.uint32(66).string(message.portId);
            if (message.position3d != null && Object.hasOwnProperty.call(message, "position3d"))
                $root.Vector3.encode(message.position3d, writer.uint32(74).fork()).ldelim();
            if (message.is3d != null && Object.hasOwnProperty.call(message, "is3d"))
                writer.uint32(80).bool(message.is3d);
            if (message.aspect != null && Object.hasOwnProperty.call(message, "aspect"))
                writer.uint32(93).float(message.aspect);
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(98).string(message.uuid);
            if (message.existBind != null && Object.hasOwnProperty.call(message, "existBind"))
                writer.uint32(104).bool(message.existBind);
            if (message.position3dBind != null && Object.hasOwnProperty.call(message, "position3dBind"))
                $root.Vector3d.encode(message.position3dBind, writer.uint32(114).fork()).ldelim();
            if (message.revision != null && Object.hasOwnProperty.call(message, "revision"))
                $root.Issue.SheetRevision.encode(message.revision, writer.uint32(122).fork()).ldelim();
            return writer;
        };

        Sheet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Sheet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Sheet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.number = reader.string();
                        break;
                    }
                case 3: {
                        message.id = reader.int32();
                        break;
                    }
                case 4: {
                        message.guid = reader.string();
                        break;
                    }
                case 5: {
                        message.viewCenter = $root.Issue.Vector2.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.viewScale = reader.float();
                        break;
                    }
                case 7: {
                        message.viewMarker = $root.Issue.Vector2.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.portId = reader.string();
                        break;
                    }
                case 9: {
                        message.position3d = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.is3d = reader.bool();
                        break;
                    }
                case 11: {
                        message.aspect = reader.float();
                        break;
                    }
                case 12: {
                        message.uuid = reader.string();
                        break;
                    }
                case 13: {
                        message.existBind = reader.bool();
                        break;
                    }
                case 14: {
                        message.position3dBind = $root.Vector3d.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.revision = $root.Issue.SheetRevision.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Sheet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Sheet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.number != null && message.hasOwnProperty("number"))
                if (!$util.isString(message.number))
                    return "number: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.guid != null && message.hasOwnProperty("guid"))
                if (!$util.isString(message.guid))
                    return "guid: string expected";
            if (message.viewCenter != null && message.hasOwnProperty("viewCenter")) {
                let error = $root.Issue.Vector2.verify(message.viewCenter);
                if (error)
                    return "viewCenter." + error;
            }
            if (message.viewScale != null && message.hasOwnProperty("viewScale"))
                if (typeof message.viewScale !== "number")
                    return "viewScale: number expected";
            if (message.viewMarker != null && message.hasOwnProperty("viewMarker")) {
                let error = $root.Issue.Vector2.verify(message.viewMarker);
                if (error)
                    return "viewMarker." + error;
            }
            if (message.portId != null && message.hasOwnProperty("portId"))
                if (!$util.isString(message.portId))
                    return "portId: string expected";
            if (message.position3d != null && message.hasOwnProperty("position3d")) {
                let error = $root.Vector3.verify(message.position3d);
                if (error)
                    return "position3d." + error;
            }
            if (message.is3d != null && message.hasOwnProperty("is3d"))
                if (typeof message.is3d !== "boolean")
                    return "is3d: boolean expected";
            if (message.aspect != null && message.hasOwnProperty("aspect"))
                if (typeof message.aspect !== "number")
                    return "aspect: number expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.existBind != null && message.hasOwnProperty("existBind"))
                if (typeof message.existBind !== "boolean")
                    return "existBind: boolean expected";
            if (message.position3dBind != null && message.hasOwnProperty("position3dBind")) {
                let error = $root.Vector3d.verify(message.position3dBind);
                if (error)
                    return "position3dBind." + error;
            }
            if (message.revision != null && message.hasOwnProperty("revision")) {
                let error = $root.Issue.SheetRevision.verify(message.revision);
                if (error)
                    return "revision." + error;
            }
            return null;
        };

        Sheet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Sheet";
        };

        return Sheet;
    })();

    Issue.SheetRevision = (function() {

        function SheetRevision(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        SheetRevision.prototype.uuid = "";
        SheetRevision.prototype.projectRevisionUuid = "";
        SheetRevision.prototype.cdeLink = null;

        SheetRevision.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(10).string(message.uuid);
            if (message.projectRevisionUuid != null && Object.hasOwnProperty.call(message, "projectRevisionUuid"))
                writer.uint32(18).string(message.projectRevisionUuid);
            if (message.cdeLink != null && Object.hasOwnProperty.call(message, "cdeLink"))
                $root.Issue.CDELink.encode(message.cdeLink, writer.uint32(26).fork()).ldelim();
            return writer;
        };

        SheetRevision.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        SheetRevision.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SheetRevision();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uuid = reader.string();
                        break;
                    }
                case 2: {
                        message.projectRevisionUuid = reader.string();
                        break;
                    }
                case 3: {
                        message.cdeLink = $root.Issue.CDELink.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SheetRevision.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        SheetRevision.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.projectRevisionUuid != null && message.hasOwnProperty("projectRevisionUuid"))
                if (!$util.isString(message.projectRevisionUuid))
                    return "projectRevisionUuid: string expected";
            if (message.cdeLink != null && message.hasOwnProperty("cdeLink")) {
                let error = $root.Issue.CDELink.verify(message.cdeLink);
                if (error)
                    return "cdeLink." + error;
            }
            return null;
        };

        SheetRevision.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.SheetRevision";
        };

        return SheetRevision;
    })();

    Issue.CompareSheet = (function() {

        function CompareSheet(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CompareSheet.prototype.revision = null;
        CompareSheet.prototype.mode = 0;
        CompareSheet.prototype.modeParam1 = 0;
        CompareSheet.prototype.offset = null;

        CompareSheet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.revision != null && Object.hasOwnProperty.call(message, "revision"))
                $root.Issue.SheetRevision.encode(message.revision, writer.uint32(10).fork()).ldelim();
            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                writer.uint32(16).int32(message.mode);
            if (message.modeParam1 != null && Object.hasOwnProperty.call(message, "modeParam1"))
                writer.uint32(24).int32(message.modeParam1);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                $root.Issue.Vector2.encode(message.offset, writer.uint32(42).fork()).ldelim();
            return writer;
        };

        CompareSheet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        CompareSheet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.CompareSheet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.revision = $root.Issue.SheetRevision.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.mode = reader.int32();
                        break;
                    }
                case 3: {
                        message.modeParam1 = reader.int32();
                        break;
                    }
                case 5: {
                        message.offset = $root.Issue.Vector2.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CompareSheet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        CompareSheet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.revision != null && message.hasOwnProperty("revision")) {
                let error = $root.Issue.SheetRevision.verify(message.revision);
                if (error)
                    return "revision." + error;
            }
            if (message.mode != null && message.hasOwnProperty("mode"))
                if (!$util.isInteger(message.mode))
                    return "mode: integer expected";
            if (message.modeParam1 != null && message.hasOwnProperty("modeParam1"))
                if (!$util.isInteger(message.modeParam1))
                    return "modeParam1: integer expected";
            if (message.offset != null && message.hasOwnProperty("offset")) {
                let error = $root.Issue.Vector2.verify(message.offset);
                if (error)
                    return "offset." + error;
            }
            return null;
        };

        CompareSheet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.CompareSheet";
        };

        return CompareSheet;
    })();

    Issue.VisualOperations = (function() {

        function VisualOperations(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        VisualOperations.prototype.storageFormat = 0;
        VisualOperations.prototype.storageHash = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VisualOperations.prototype.storage = $util.newBuffer([]);
        VisualOperations.prototype.fileName = "";

        VisualOperations.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.storageFormat != null && Object.hasOwnProperty.call(message, "storageFormat"))
                writer.uint32(8).int32(message.storageFormat);
            if (message.storageHash != null && Object.hasOwnProperty.call(message, "storageHash"))
                writer.uint32(17).fixed64(message.storageHash);
            if (message.storage != null && Object.hasOwnProperty.call(message, "storage"))
                writer.uint32(26).bytes(message.storage);
            if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                writer.uint32(34).string(message.fileName);
            return writer;
        };

        VisualOperations.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        VisualOperations.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.VisualOperations();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.storageFormat = reader.int32();
                        break;
                    }
                case 2: {
                        message.storageHash = reader.fixed64();
                        break;
                    }
                case 3: {
                        message.storage = reader.bytes();
                        break;
                    }
                case 4: {
                        message.fileName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        VisualOperations.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        VisualOperations.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.storageFormat != null && message.hasOwnProperty("storageFormat"))
                if (!$util.isInteger(message.storageFormat))
                    return "storageFormat: integer expected";
            if (message.storageHash != null && message.hasOwnProperty("storageHash"))
                if (!$util.isInteger(message.storageHash) && !(message.storageHash && $util.isInteger(message.storageHash.low) && $util.isInteger(message.storageHash.high)))
                    return "storageHash: integer|Long expected";
            if (message.storage != null && message.hasOwnProperty("storage"))
                if (!(message.storage && typeof message.storage.length === "number" || $util.isString(message.storage)))
                    return "storage: buffer expected";
            if (message.fileName != null && message.hasOwnProperty("fileName"))
                if (!$util.isString(message.fileName))
                    return "fileName: string expected";
            return null;
        };

        VisualOperations.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.VisualOperations";
        };

        return VisualOperations;
    })();

    Issue.Bind3d = (function() {

        function Bind3d(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Bind3d.prototype.sceneId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Bind3d.prototype.cadId = 0;
        Bind3d.prototype.instanceCadPartId = 0;

        Bind3d.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sceneId != null && Object.hasOwnProperty.call(message, "sceneId"))
                writer.uint32(9).fixed64(message.sceneId);
            if (message.cadId != null && Object.hasOwnProperty.call(message, "cadId"))
                writer.uint32(16).int32(message.cadId);
            if (message.instanceCadPartId != null && Object.hasOwnProperty.call(message, "instanceCadPartId"))
                writer.uint32(24).int32(message.instanceCadPartId);
            return writer;
        };

        Bind3d.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Bind3d.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Bind3d();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sceneId = reader.fixed64();
                        break;
                    }
                case 2: {
                        message.cadId = reader.int32();
                        break;
                    }
                case 3: {
                        message.instanceCadPartId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Bind3d.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Bind3d.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sceneId != null && message.hasOwnProperty("sceneId"))
                if (!$util.isInteger(message.sceneId) && !(message.sceneId && $util.isInteger(message.sceneId.low) && $util.isInteger(message.sceneId.high)))
                    return "sceneId: integer|Long expected";
            if (message.cadId != null && message.hasOwnProperty("cadId"))
                if (!$util.isInteger(message.cadId))
                    return "cadId: integer expected";
            if (message.instanceCadPartId != null && message.hasOwnProperty("instanceCadPartId"))
                if (!$util.isInteger(message.instanceCadPartId))
                    return "instanceCadPartId: integer expected";
            return null;
        };

        Bind3d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Bind3d";
        };

        return Bind3d;
    })();

    Issue.Field0 = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FieldNone"] = 0;
        values[valuesById[1] = "Header"] = 1;
        values[valuesById[2] = "Reporter"] = 2;
        values[valuesById[4] = "Lines"] = 4;
        values[valuesById[8] = "Title"] = 8;
        values[valuesById[16] = "Status"] = 16;
        values[valuesById[32] = "Assigned"] = 32;
        values[valuesById[64] = "WatcherS"] = 64;
        values[valuesById[128] = "Visibility"] = 128;
        values[valuesById[256] = "Tags"] = 256;
        values[valuesById[512] = "Deadline"] = 512;
        values[valuesById[1024] = "Markups"] = 1024;
        values[valuesById[2048] = "Camera"] = 2048;
        values[valuesById[4096] = "FieldSnapshot"] = 4096;
        values[valuesById[8192] = "Selections"] = 8192;
        values[valuesById[16384] = "Sectioncuts"] = 16384;
        values[valuesById[32768] = "FieldSheet"] = 32768;
        values[valuesById[65536] = "FieldClash"] = 65536;
        values[valuesById[131072] = "FieldVisSelect"] = 131072;
        values[valuesById[262144] = "FieldColorSelect"] = 262144;
        values[valuesById[524288] = "FieldPhaseSelect"] = 524288;
        values[valuesById[1048576] = "Priority"] = 1048576;
        values[valuesById[2097152] = "FieldBind3d"] = 2097152;
        values[valuesById[4194304] = "FieldInternalProperties"] = 4194304;
        values[valuesById[8388608] = "StampColor"] = 8388608;
        values[valuesById[16777216] = "StampAbbr"] = 16777216;
        values[valuesById[33554432] = "StampScale"] = 33554432;
        values[valuesById[67108864] = "ProcoreField"] = 67108864;
        values[valuesById[134217728] = "PinField"] = 134217728;
        values[valuesById[268435456] = "CDELinksField"] = 268435456;
        values[valuesById[536870912] = "CompareSheetField"] = 536870912;
        values[valuesById[1073741824] = "VisualOperationsField"] = 1073741824;
        return values;
    })();

    Issue.Field1 = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "None"] = 0;
        values[valuesById[1] = "SelectionNames"] = 1;
        values[valuesById[2] = "Revizto_Clash"] = 2;
        values[valuesById[4] = "Position_Properties"] = 4;
        values[valuesById[8] = "Default_Sheet"] = 8;
        values[valuesById[16] = "Custom_Status"] = 16;
        values[valuesById[32] = "Custom_Type"] = 32;
        values[valuesById[64] = "Deleted_At"] = 64;
        return values;
    })();

    Issue.Diff = (function() {

        function Diff(properties) {
            this.lines = [];
            this.watchers = [];
            this.tags = [];
            this.selections = [];
            this.sectioncuts = [];
            this.selectionNames = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Diff.prototype.reporter = "";
        Diff.prototype.lines = $util.emptyArray;
        Diff.prototype.title = "";
        Diff.prototype.status = 0;
        Diff.prototype.assigned = "";
        Diff.prototype.watchers = $util.emptyArray;
        Diff.prototype.visibility = 0;
        Diff.prototype.tags = $util.emptyArray;
        Diff.prototype.deadline = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Diff.prototype.camera = null;
        Diff.prototype.snapshot = null;
        Diff.prototype.selections = $util.emptyArray;
        Diff.prototype.sectioncuts = $util.emptyArray;
        Diff.prototype.sheet = null;
        Diff.prototype.clash = null;
        Diff.prototype.visSelect = null;
        Diff.prototype.colorSelect = null;
        Diff.prototype.phaseSelect = null;
        Diff.prototype.version = 0;
        Diff.prototype.priority = 0;
        Diff.prototype.bind3d = null;
        Diff.prototype.selectionsStorage = null;
        Diff.prototype.clashStorage = null;
        Diff.prototype.internalProperties = null;
        Diff.prototype.stampColor = 0;
        Diff.prototype.stampAbbr = "";
        Diff.prototype.stampScale = 0;
        Diff.prototype.procore = null;
        Diff.prototype.pin = null;
        Diff.prototype.cdeLinks = null;
        Diff.prototype.compareSheet = null;
        Diff.prototype.visualOperations = null;
        Diff.prototype.selectionNames = $util.emptyArray;
        Diff.prototype.selectionNamesStorage = null;
        Diff.prototype.reviztoClash = null;
        Diff.prototype.rClashStorage = null;
        Diff.prototype.statusAuto = false;
        Diff.prototype.positionProperties = null;
        Diff.prototype.defaultSheet = null;
        Diff.prototype.customStatus = "";
        Diff.prototype.customType = "";
        Diff.prototype.deletedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        Diff.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reporter != null && Object.hasOwnProperty.call(message, "reporter"))
                writer.uint32(10).string(message.reporter);
            if (message.lines != null && message.lines.length)
                for (let i = 0; i < message.lines.length; ++i)
                    $root.Issue.Line.encode(message.lines[i], writer.uint32(18).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(26).string(message.title);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(32).int32(message.status);
            if (message.assigned != null && Object.hasOwnProperty.call(message, "assigned"))
                writer.uint32(42).string(message.assigned);
            if (message.watchers != null && message.watchers.length)
                for (let i = 0; i < message.watchers.length; ++i)
                    writer.uint32(50).string(message.watchers[i]);
            if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
                writer.uint32(56).int32(message.visibility);
            if (message.tags != null && message.tags.length)
                for (let i = 0; i < message.tags.length; ++i)
                    writer.uint32(66).string(message.tags[i]);
            if (message.deadline != null && Object.hasOwnProperty.call(message, "deadline"))
                writer.uint32(72).int64(message.deadline);
            if (message.camera != null && Object.hasOwnProperty.call(message, "camera"))
                $root.Issue.CameraTransform.encode(message.camera, writer.uint32(90).fork()).ldelim();
            if (message.snapshot != null && Object.hasOwnProperty.call(message, "snapshot"))
                $root.Issue.Snapshot.encode(message.snapshot, writer.uint32(98).fork()).ldelim();
            if (message.selections != null && message.selections.length)
                for (let i = 0; i < message.selections.length; ++i)
                    $root.Issue.Selection.encode(message.selections[i], writer.uint32(106).fork()).ldelim();
            if (message.sectioncuts != null && message.sectioncuts.length)
                for (let i = 0; i < message.sectioncuts.length; ++i)
                    $root.Issue.Plane.encode(message.sectioncuts[i], writer.uint32(114).fork()).ldelim();
            if (message.sheet != null && Object.hasOwnProperty.call(message, "sheet"))
                $root.Issue.Sheet.encode(message.sheet, writer.uint32(122).fork()).ldelim();
            if (message.clash != null && Object.hasOwnProperty.call(message, "clash"))
                $root.Issue.Clash.encode(message.clash, writer.uint32(130).fork()).ldelim();
            if (message.visSelect != null && Object.hasOwnProperty.call(message, "visSelect"))
                $root.Issue.VisSelect.encode(message.visSelect, writer.uint32(138).fork()).ldelim();
            if (message.colorSelect != null && Object.hasOwnProperty.call(message, "colorSelect"))
                $root.Issue.ColorSelect.encode(message.colorSelect, writer.uint32(146).fork()).ldelim();
            if (message.phaseSelect != null && Object.hasOwnProperty.call(message, "phaseSelect"))
                $root.Issue.PhaseSelect.encode(message.phaseSelect, writer.uint32(154).fork()).ldelim();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(160).int32(message.version);
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(168).int32(message.priority);
            if (message.bind3d != null && Object.hasOwnProperty.call(message, "bind3d"))
                $root.Issue.Bind3d.encode(message.bind3d, writer.uint32(178).fork()).ldelim();
            if (message.selectionsStorage != null && Object.hasOwnProperty.call(message, "selectionsStorage"))
                $root.Issue.SelectionStorage.encode(message.selectionsStorage, writer.uint32(186).fork()).ldelim();
            if (message.clashStorage != null && Object.hasOwnProperty.call(message, "clashStorage"))
                $root.Issue.ClashStorage.encode(message.clashStorage, writer.uint32(194).fork()).ldelim();
            if (message.internalProperties != null && Object.hasOwnProperty.call(message, "internalProperties"))
                $root.Issue.InternalProperties.encode(message.internalProperties, writer.uint32(202).fork()).ldelim();
            if (message.stampColor != null && Object.hasOwnProperty.call(message, "stampColor"))
                writer.uint32(208).int32(message.stampColor);
            if (message.stampAbbr != null && Object.hasOwnProperty.call(message, "stampAbbr"))
                writer.uint32(218).string(message.stampAbbr);
            if (message.stampScale != null && Object.hasOwnProperty.call(message, "stampScale"))
                writer.uint32(229).float(message.stampScale);
            if (message.procore != null && Object.hasOwnProperty.call(message, "procore"))
                $root.Issue.Procore.encode(message.procore, writer.uint32(234).fork()).ldelim();
            if (message.pin != null && Object.hasOwnProperty.call(message, "pin"))
                $root.Issue.Pin.encode(message.pin, writer.uint32(242).fork()).ldelim();
            if (message.cdeLinks != null && Object.hasOwnProperty.call(message, "cdeLinks"))
                $root.Issue.CDELinks.encode(message.cdeLinks, writer.uint32(250).fork()).ldelim();
            if (message.compareSheet != null && Object.hasOwnProperty.call(message, "compareSheet"))
                $root.Issue.CompareSheet.encode(message.compareSheet, writer.uint32(258).fork()).ldelim();
            if (message.visualOperations != null && Object.hasOwnProperty.call(message, "visualOperations"))
                $root.Issue.VisualOperations.encode(message.visualOperations, writer.uint32(266).fork()).ldelim();
            if (message.selectionNames != null && message.selectionNames.length)
                for (let i = 0; i < message.selectionNames.length; ++i)
                    $root.Issue.SelectionName.encode(message.selectionNames[i], writer.uint32(274).fork()).ldelim();
            if (message.selectionNamesStorage != null && Object.hasOwnProperty.call(message, "selectionNamesStorage"))
                $root.Issue.SelectionNameStorage.encode(message.selectionNamesStorage, writer.uint32(282).fork()).ldelim();
            if (message.reviztoClash != null && Object.hasOwnProperty.call(message, "reviztoClash"))
                $root.Issue.RClash.encode(message.reviztoClash, writer.uint32(290).fork()).ldelim();
            if (message.rClashStorage != null && Object.hasOwnProperty.call(message, "rClashStorage"))
                $root.Issue.RClashStorage.encode(message.rClashStorage, writer.uint32(298).fork()).ldelim();
            if (message.statusAuto != null && Object.hasOwnProperty.call(message, "statusAuto"))
                writer.uint32(304).bool(message.statusAuto);
            if (message.positionProperties != null && Object.hasOwnProperty.call(message, "positionProperties"))
                $root.Issue.Prop.encode(message.positionProperties, writer.uint32(314).fork()).ldelim();
            if (message.defaultSheet != null && Object.hasOwnProperty.call(message, "defaultSheet"))
                $root.Issue.DefaultSheet.encode(message.defaultSheet, writer.uint32(322).fork()).ldelim();
            if (message.customStatus != null && Object.hasOwnProperty.call(message, "customStatus"))
                writer.uint32(330).string(message.customStatus);
            if (message.customType != null && Object.hasOwnProperty.call(message, "customType"))
                writer.uint32(338).string(message.customType);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(344).int64(message.deletedAt);
            return writer;
        };

        Diff.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Diff.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Diff();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.reporter = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.lines && message.lines.length))
                            message.lines = [];
                        message.lines.push($root.Issue.Line.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.title = reader.string();
                        break;
                    }
                case 4: {
                        message.status = reader.int32();
                        break;
                    }
                case 5: {
                        message.assigned = reader.string();
                        break;
                    }
                case 6: {
                        if (!(message.watchers && message.watchers.length))
                            message.watchers = [];
                        message.watchers.push(reader.string());
                        break;
                    }
                case 7: {
                        message.visibility = reader.int32();
                        break;
                    }
                case 8: {
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    }
                case 9: {
                        message.deadline = reader.int64();
                        break;
                    }
                case 11: {
                        message.camera = $root.Issue.CameraTransform.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.snapshot = $root.Issue.Snapshot.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        if (!(message.selections && message.selections.length))
                            message.selections = [];
                        message.selections.push($root.Issue.Selection.decode(reader, reader.uint32()));
                        break;
                    }
                case 14: {
                        if (!(message.sectioncuts && message.sectioncuts.length))
                            message.sectioncuts = [];
                        message.sectioncuts.push($root.Issue.Plane.decode(reader, reader.uint32()));
                        break;
                    }
                case 15: {
                        message.sheet = $root.Issue.Sheet.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.clash = $root.Issue.Clash.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.visSelect = $root.Issue.VisSelect.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.colorSelect = $root.Issue.ColorSelect.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.phaseSelect = $root.Issue.PhaseSelect.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.version = reader.int32();
                        break;
                    }
                case 21: {
                        message.priority = reader.int32();
                        break;
                    }
                case 22: {
                        message.bind3d = $root.Issue.Bind3d.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.selectionsStorage = $root.Issue.SelectionStorage.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.clashStorage = $root.Issue.ClashStorage.decode(reader, reader.uint32());
                        break;
                    }
                case 25: {
                        message.internalProperties = $root.Issue.InternalProperties.decode(reader, reader.uint32());
                        break;
                    }
                case 26: {
                        message.stampColor = reader.int32();
                        break;
                    }
                case 27: {
                        message.stampAbbr = reader.string();
                        break;
                    }
                case 28: {
                        message.stampScale = reader.float();
                        break;
                    }
                case 29: {
                        message.procore = $root.Issue.Procore.decode(reader, reader.uint32());
                        break;
                    }
                case 30: {
                        message.pin = $root.Issue.Pin.decode(reader, reader.uint32());
                        break;
                    }
                case 31: {
                        message.cdeLinks = $root.Issue.CDELinks.decode(reader, reader.uint32());
                        break;
                    }
                case 32: {
                        message.compareSheet = $root.Issue.CompareSheet.decode(reader, reader.uint32());
                        break;
                    }
                case 33: {
                        message.visualOperations = $root.Issue.VisualOperations.decode(reader, reader.uint32());
                        break;
                    }
                case 34: {
                        if (!(message.selectionNames && message.selectionNames.length))
                            message.selectionNames = [];
                        message.selectionNames.push($root.Issue.SelectionName.decode(reader, reader.uint32()));
                        break;
                    }
                case 35: {
                        message.selectionNamesStorage = $root.Issue.SelectionNameStorage.decode(reader, reader.uint32());
                        break;
                    }
                case 36: {
                        message.reviztoClash = $root.Issue.RClash.decode(reader, reader.uint32());
                        break;
                    }
                case 37: {
                        message.rClashStorage = $root.Issue.RClashStorage.decode(reader, reader.uint32());
                        break;
                    }
                case 38: {
                        message.statusAuto = reader.bool();
                        break;
                    }
                case 39: {
                        message.positionProperties = $root.Issue.Prop.decode(reader, reader.uint32());
                        break;
                    }
                case 40: {
                        message.defaultSheet = $root.Issue.DefaultSheet.decode(reader, reader.uint32());
                        break;
                    }
                case 41: {
                        message.customStatus = reader.string();
                        break;
                    }
                case 42: {
                        message.customType = reader.string();
                        break;
                    }
                case 43: {
                        message.deletedAt = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Diff.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Diff.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reporter != null && message.hasOwnProperty("reporter"))
                if (!$util.isString(message.reporter))
                    return "reporter: string expected";
            if (message.lines != null && message.hasOwnProperty("lines")) {
                if (!Array.isArray(message.lines))
                    return "lines: array expected";
                for (let i = 0; i < message.lines.length; ++i) {
                    let error = $root.Issue.Line.verify(message.lines[i]);
                    if (error)
                        return "lines." + error;
                }
            }
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.assigned != null && message.hasOwnProperty("assigned"))
                if (!$util.isString(message.assigned))
                    return "assigned: string expected";
            if (message.watchers != null && message.hasOwnProperty("watchers")) {
                if (!Array.isArray(message.watchers))
                    return "watchers: array expected";
                for (let i = 0; i < message.watchers.length; ++i)
                    if (!$util.isString(message.watchers[i]))
                        return "watchers: string[] expected";
            }
            if (message.visibility != null && message.hasOwnProperty("visibility"))
                switch (message.visibility) {
                default:
                    return "visibility: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (let i = 0; i < message.tags.length; ++i)
                    if (!$util.isString(message.tags[i]))
                        return "tags: string[] expected";
            }
            if (message.deadline != null && message.hasOwnProperty("deadline"))
                if (!$util.isInteger(message.deadline) && !(message.deadline && $util.isInteger(message.deadline.low) && $util.isInteger(message.deadline.high)))
                    return "deadline: integer|Long expected";
            if (message.camera != null && message.hasOwnProperty("camera")) {
                let error = $root.Issue.CameraTransform.verify(message.camera);
                if (error)
                    return "camera." + error;
            }
            if (message.snapshot != null && message.hasOwnProperty("snapshot")) {
                let error = $root.Issue.Snapshot.verify(message.snapshot);
                if (error)
                    return "snapshot." + error;
            }
            if (message.selections != null && message.hasOwnProperty("selections")) {
                if (!Array.isArray(message.selections))
                    return "selections: array expected";
                for (let i = 0; i < message.selections.length; ++i) {
                    let error = $root.Issue.Selection.verify(message.selections[i]);
                    if (error)
                        return "selections." + error;
                }
            }
            if (message.sectioncuts != null && message.hasOwnProperty("sectioncuts")) {
                if (!Array.isArray(message.sectioncuts))
                    return "sectioncuts: array expected";
                for (let i = 0; i < message.sectioncuts.length; ++i) {
                    let error = $root.Issue.Plane.verify(message.sectioncuts[i]);
                    if (error)
                        return "sectioncuts." + error;
                }
            }
            if (message.sheet != null && message.hasOwnProperty("sheet")) {
                let error = $root.Issue.Sheet.verify(message.sheet);
                if (error)
                    return "sheet." + error;
            }
            if (message.clash != null && message.hasOwnProperty("clash")) {
                let error = $root.Issue.Clash.verify(message.clash);
                if (error)
                    return "clash." + error;
            }
            if (message.visSelect != null && message.hasOwnProperty("visSelect")) {
                let error = $root.Issue.VisSelect.verify(message.visSelect);
                if (error)
                    return "visSelect." + error;
            }
            if (message.colorSelect != null && message.hasOwnProperty("colorSelect")) {
                let error = $root.Issue.ColorSelect.verify(message.colorSelect);
                if (error)
                    return "colorSelect." + error;
            }
            if (message.phaseSelect != null && message.hasOwnProperty("phaseSelect")) {
                let error = $root.Issue.PhaseSelect.verify(message.phaseSelect);
                if (error)
                    return "phaseSelect." + error;
            }
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            if (message.priority != null && message.hasOwnProperty("priority"))
                switch (message.priority) {
                default:
                    return "priority: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.bind3d != null && message.hasOwnProperty("bind3d")) {
                let error = $root.Issue.Bind3d.verify(message.bind3d);
                if (error)
                    return "bind3d." + error;
            }
            if (message.selectionsStorage != null && message.hasOwnProperty("selectionsStorage")) {
                let error = $root.Issue.SelectionStorage.verify(message.selectionsStorage);
                if (error)
                    return "selectionsStorage." + error;
            }
            if (message.clashStorage != null && message.hasOwnProperty("clashStorage")) {
                let error = $root.Issue.ClashStorage.verify(message.clashStorage);
                if (error)
                    return "clashStorage." + error;
            }
            if (message.internalProperties != null && message.hasOwnProperty("internalProperties")) {
                let error = $root.Issue.InternalProperties.verify(message.internalProperties);
                if (error)
                    return "internalProperties." + error;
            }
            if (message.stampColor != null && message.hasOwnProperty("stampColor"))
                if (!$util.isInteger(message.stampColor))
                    return "stampColor: integer expected";
            if (message.stampAbbr != null && message.hasOwnProperty("stampAbbr"))
                if (!$util.isString(message.stampAbbr))
                    return "stampAbbr: string expected";
            if (message.stampScale != null && message.hasOwnProperty("stampScale"))
                if (typeof message.stampScale !== "number")
                    return "stampScale: number expected";
            if (message.procore != null && message.hasOwnProperty("procore")) {
                let error = $root.Issue.Procore.verify(message.procore);
                if (error)
                    return "procore." + error;
            }
            if (message.pin != null && message.hasOwnProperty("pin")) {
                let error = $root.Issue.Pin.verify(message.pin);
                if (error)
                    return "pin." + error;
            }
            if (message.cdeLinks != null && message.hasOwnProperty("cdeLinks")) {
                let error = $root.Issue.CDELinks.verify(message.cdeLinks);
                if (error)
                    return "cdeLinks." + error;
            }
            if (message.compareSheet != null && message.hasOwnProperty("compareSheet")) {
                let error = $root.Issue.CompareSheet.verify(message.compareSheet);
                if (error)
                    return "compareSheet." + error;
            }
            if (message.visualOperations != null && message.hasOwnProperty("visualOperations")) {
                let error = $root.Issue.VisualOperations.verify(message.visualOperations);
                if (error)
                    return "visualOperations." + error;
            }
            if (message.selectionNames != null && message.hasOwnProperty("selectionNames")) {
                if (!Array.isArray(message.selectionNames))
                    return "selectionNames: array expected";
                for (let i = 0; i < message.selectionNames.length; ++i) {
                    let error = $root.Issue.SelectionName.verify(message.selectionNames[i]);
                    if (error)
                        return "selectionNames." + error;
                }
            }
            if (message.selectionNamesStorage != null && message.hasOwnProperty("selectionNamesStorage")) {
                let error = $root.Issue.SelectionNameStorage.verify(message.selectionNamesStorage);
                if (error)
                    return "selectionNamesStorage." + error;
            }
            if (message.reviztoClash != null && message.hasOwnProperty("reviztoClash")) {
                let error = $root.Issue.RClash.verify(message.reviztoClash);
                if (error)
                    return "reviztoClash." + error;
            }
            if (message.rClashStorage != null && message.hasOwnProperty("rClashStorage")) {
                let error = $root.Issue.RClashStorage.verify(message.rClashStorage);
                if (error)
                    return "rClashStorage." + error;
            }
            if (message.statusAuto != null && message.hasOwnProperty("statusAuto"))
                if (typeof message.statusAuto !== "boolean")
                    return "statusAuto: boolean expected";
            if (message.positionProperties != null && message.hasOwnProperty("positionProperties")) {
                let error = $root.Issue.Prop.verify(message.positionProperties);
                if (error)
                    return "positionProperties." + error;
            }
            if (message.defaultSheet != null && message.hasOwnProperty("defaultSheet")) {
                let error = $root.Issue.DefaultSheet.verify(message.defaultSheet);
                if (error)
                    return "defaultSheet." + error;
            }
            if (message.customStatus != null && message.hasOwnProperty("customStatus"))
                if (!$util.isString(message.customStatus))
                    return "customStatus: string expected";
            if (message.customType != null && message.hasOwnProperty("customType"))
                if (!$util.isString(message.customType))
                    return "customType: string expected";
            if (message.deletedAt != null && message.hasOwnProperty("deletedAt"))
                if (!$util.isInteger(message.deletedAt) && !(message.deletedAt && $util.isInteger(message.deletedAt.low) && $util.isInteger(message.deletedAt.high)))
                    return "deletedAt: integer|Long expected";
            return null;
        };

        Diff.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Diff";
        };

        return Diff;
    })();

    Issue.RClashItem = (function() {

        function RClashItem(properties) {
            this.GridIntersectionsInProximity = [];
            this.Contacts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RClashItem.prototype.guid = $util.newBuffer([]);
        RClashItem.prototype.FoundAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.Name = "";
        RClashItem.prototype.state = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.Number = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.ApprovedBy = "";
        RClashItem.prototype.ApprovedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.sceneBindUniqueIdA = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.firstObjectAReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.geometryAReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.sceneBindUniqueIdB = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.firstObjectBReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.geometryBReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashItem.prototype.center = null;
        RClashItem.prototype.Level = "";
        RClashItem.prototype.Room = "";
        RClashItem.prototype.Zone = "";
        RClashItem.prototype.Area = "";
        RClashItem.prototype.Space = "";
        RClashItem.prototype.ClosestGrid = null;
        RClashItem.prototype.GridIntersectionsInProximity = $util.emptyArray;
        RClashItem.prototype.SrcA = "";
        RClashItem.prototype.SrcB = "";
        RClashItem.prototype.Contacts = $util.emptyArray;

        RClashItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                writer.uint32(10).bytes(message.guid);
            if (message.FoundAt != null && Object.hasOwnProperty.call(message, "FoundAt"))
                writer.uint32(16).int64(message.FoundAt);
            if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
                writer.uint32(26).string(message.Name);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(32).int64(message.state);
            if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
                writer.uint32(40).int64(message.Number);
            if (message.ApprovedBy != null && Object.hasOwnProperty.call(message, "ApprovedBy"))
                writer.uint32(50).string(message.ApprovedBy);
            if (message.ApprovedAt != null && Object.hasOwnProperty.call(message, "ApprovedAt"))
                writer.uint32(56).int64(message.ApprovedAt);
            if (message.sceneBindUniqueIdA != null && Object.hasOwnProperty.call(message, "sceneBindUniqueIdA"))
                writer.uint32(65).fixed64(message.sceneBindUniqueIdA);
            if (message.firstObjectAReviztoId != null && Object.hasOwnProperty.call(message, "firstObjectAReviztoId"))
                writer.uint32(73).fixed64(message.firstObjectAReviztoId);
            if (message.geometryAReviztoId != null && Object.hasOwnProperty.call(message, "geometryAReviztoId"))
                writer.uint32(81).fixed64(message.geometryAReviztoId);
            if (message.sceneBindUniqueIdB != null && Object.hasOwnProperty.call(message, "sceneBindUniqueIdB"))
                writer.uint32(89).fixed64(message.sceneBindUniqueIdB);
            if (message.firstObjectBReviztoId != null && Object.hasOwnProperty.call(message, "firstObjectBReviztoId"))
                writer.uint32(97).fixed64(message.firstObjectBReviztoId);
            if (message.geometryBReviztoId != null && Object.hasOwnProperty.call(message, "geometryBReviztoId"))
                writer.uint32(105).fixed64(message.geometryBReviztoId);
            if (message.center != null && Object.hasOwnProperty.call(message, "center"))
                $root.SCenter.encode(message.center, writer.uint32(114).fork()).ldelim();
            if (message.Level != null && Object.hasOwnProperty.call(message, "Level"))
                writer.uint32(122).string(message.Level);
            if (message.Room != null && Object.hasOwnProperty.call(message, "Room"))
                writer.uint32(130).string(message.Room);
            if (message.Zone != null && Object.hasOwnProperty.call(message, "Zone"))
                writer.uint32(138).string(message.Zone);
            if (message.Area != null && Object.hasOwnProperty.call(message, "Area"))
                writer.uint32(146).string(message.Area);
            if (message.Space != null && Object.hasOwnProperty.call(message, "Space"))
                writer.uint32(154).string(message.Space);
            if (message.ClosestGrid != null && Object.hasOwnProperty.call(message, "ClosestGrid"))
                $root.Issue.SGrid.encode(message.ClosestGrid, writer.uint32(162).fork()).ldelim();
            if (message.GridIntersectionsInProximity != null && message.GridIntersectionsInProximity.length)
                for (let i = 0; i < message.GridIntersectionsInProximity.length; ++i)
                    $root.Issue.SGridPair.encode(message.GridIntersectionsInProximity[i], writer.uint32(170).fork()).ldelim();
            if (message.SrcA != null && Object.hasOwnProperty.call(message, "SrcA"))
                writer.uint32(178).string(message.SrcA);
            if (message.SrcB != null && Object.hasOwnProperty.call(message, "SrcB"))
                writer.uint32(186).string(message.SrcB);
            if (message.Contacts != null && message.Contacts.length)
                for (let i = 0; i < message.Contacts.length; ++i)
                    $root.SCenter.encode(message.Contacts[i], writer.uint32(194).fork()).ldelim();
            return writer;
        };

        RClashItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RClashItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.RClashItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.guid = reader.bytes();
                        break;
                    }
                case 2: {
                        message.FoundAt = reader.int64();
                        break;
                    }
                case 3: {
                        message.Name = reader.string();
                        break;
                    }
                case 4: {
                        message.state = reader.int64();
                        break;
                    }
                case 5: {
                        message.Number = reader.int64();
                        break;
                    }
                case 6: {
                        message.ApprovedBy = reader.string();
                        break;
                    }
                case 7: {
                        message.ApprovedAt = reader.int64();
                        break;
                    }
                case 8: {
                        message.sceneBindUniqueIdA = reader.fixed64();
                        break;
                    }
                case 9: {
                        message.firstObjectAReviztoId = reader.fixed64();
                        break;
                    }
                case 10: {
                        message.geometryAReviztoId = reader.fixed64();
                        break;
                    }
                case 11: {
                        message.sceneBindUniqueIdB = reader.fixed64();
                        break;
                    }
                case 12: {
                        message.firstObjectBReviztoId = reader.fixed64();
                        break;
                    }
                case 13: {
                        message.geometryBReviztoId = reader.fixed64();
                        break;
                    }
                case 14: {
                        message.center = $root.SCenter.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.Level = reader.string();
                        break;
                    }
                case 16: {
                        message.Room = reader.string();
                        break;
                    }
                case 17: {
                        message.Zone = reader.string();
                        break;
                    }
                case 18: {
                        message.Area = reader.string();
                        break;
                    }
                case 19: {
                        message.Space = reader.string();
                        break;
                    }
                case 20: {
                        message.ClosestGrid = $root.Issue.SGrid.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        if (!(message.GridIntersectionsInProximity && message.GridIntersectionsInProximity.length))
                            message.GridIntersectionsInProximity = [];
                        message.GridIntersectionsInProximity.push($root.Issue.SGridPair.decode(reader, reader.uint32()));
                        break;
                    }
                case 22: {
                        message.SrcA = reader.string();
                        break;
                    }
                case 23: {
                        message.SrcB = reader.string();
                        break;
                    }
                case 24: {
                        if (!(message.Contacts && message.Contacts.length))
                            message.Contacts = [];
                        message.Contacts.push($root.SCenter.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RClashItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RClashItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.guid != null && message.hasOwnProperty("guid"))
                if (!(message.guid && typeof message.guid.length === "number" || $util.isString(message.guid)))
                    return "guid: buffer expected";
            if (message.FoundAt != null && message.hasOwnProperty("FoundAt"))
                if (!$util.isInteger(message.FoundAt) && !(message.FoundAt && $util.isInteger(message.FoundAt.low) && $util.isInteger(message.FoundAt.high)))
                    return "FoundAt: integer|Long expected";
            if (message.Name != null && message.hasOwnProperty("Name"))
                if (!$util.isString(message.Name))
                    return "Name: string expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (!$util.isInteger(message.state) && !(message.state && $util.isInteger(message.state.low) && $util.isInteger(message.state.high)))
                    return "state: integer|Long expected";
            if (message.Number != null && message.hasOwnProperty("Number"))
                if (!$util.isInteger(message.Number) && !(message.Number && $util.isInteger(message.Number.low) && $util.isInteger(message.Number.high)))
                    return "Number: integer|Long expected";
            if (message.ApprovedBy != null && message.hasOwnProperty("ApprovedBy"))
                if (!$util.isString(message.ApprovedBy))
                    return "ApprovedBy: string expected";
            if (message.ApprovedAt != null && message.hasOwnProperty("ApprovedAt"))
                if (!$util.isInteger(message.ApprovedAt) && !(message.ApprovedAt && $util.isInteger(message.ApprovedAt.low) && $util.isInteger(message.ApprovedAt.high)))
                    return "ApprovedAt: integer|Long expected";
            if (message.sceneBindUniqueIdA != null && message.hasOwnProperty("sceneBindUniqueIdA"))
                if (!$util.isInteger(message.sceneBindUniqueIdA) && !(message.sceneBindUniqueIdA && $util.isInteger(message.sceneBindUniqueIdA.low) && $util.isInteger(message.sceneBindUniqueIdA.high)))
                    return "sceneBindUniqueIdA: integer|Long expected";
            if (message.firstObjectAReviztoId != null && message.hasOwnProperty("firstObjectAReviztoId"))
                if (!$util.isInteger(message.firstObjectAReviztoId) && !(message.firstObjectAReviztoId && $util.isInteger(message.firstObjectAReviztoId.low) && $util.isInteger(message.firstObjectAReviztoId.high)))
                    return "firstObjectAReviztoId: integer|Long expected";
            if (message.geometryAReviztoId != null && message.hasOwnProperty("geometryAReviztoId"))
                if (!$util.isInteger(message.geometryAReviztoId) && !(message.geometryAReviztoId && $util.isInteger(message.geometryAReviztoId.low) && $util.isInteger(message.geometryAReviztoId.high)))
                    return "geometryAReviztoId: integer|Long expected";
            if (message.sceneBindUniqueIdB != null && message.hasOwnProperty("sceneBindUniqueIdB"))
                if (!$util.isInteger(message.sceneBindUniqueIdB) && !(message.sceneBindUniqueIdB && $util.isInteger(message.sceneBindUniqueIdB.low) && $util.isInteger(message.sceneBindUniqueIdB.high)))
                    return "sceneBindUniqueIdB: integer|Long expected";
            if (message.firstObjectBReviztoId != null && message.hasOwnProperty("firstObjectBReviztoId"))
                if (!$util.isInteger(message.firstObjectBReviztoId) && !(message.firstObjectBReviztoId && $util.isInteger(message.firstObjectBReviztoId.low) && $util.isInteger(message.firstObjectBReviztoId.high)))
                    return "firstObjectBReviztoId: integer|Long expected";
            if (message.geometryBReviztoId != null && message.hasOwnProperty("geometryBReviztoId"))
                if (!$util.isInteger(message.geometryBReviztoId) && !(message.geometryBReviztoId && $util.isInteger(message.geometryBReviztoId.low) && $util.isInteger(message.geometryBReviztoId.high)))
                    return "geometryBReviztoId: integer|Long expected";
            if (message.center != null && message.hasOwnProperty("center")) {
                let error = $root.SCenter.verify(message.center);
                if (error)
                    return "center." + error;
            }
            if (message.Level != null && message.hasOwnProperty("Level"))
                if (!$util.isString(message.Level))
                    return "Level: string expected";
            if (message.Room != null && message.hasOwnProperty("Room"))
                if (!$util.isString(message.Room))
                    return "Room: string expected";
            if (message.Zone != null && message.hasOwnProperty("Zone"))
                if (!$util.isString(message.Zone))
                    return "Zone: string expected";
            if (message.Area != null && message.hasOwnProperty("Area"))
                if (!$util.isString(message.Area))
                    return "Area: string expected";
            if (message.Space != null && message.hasOwnProperty("Space"))
                if (!$util.isString(message.Space))
                    return "Space: string expected";
            if (message.ClosestGrid != null && message.hasOwnProperty("ClosestGrid")) {
                let error = $root.Issue.SGrid.verify(message.ClosestGrid);
                if (error)
                    return "ClosestGrid." + error;
            }
            if (message.GridIntersectionsInProximity != null && message.hasOwnProperty("GridIntersectionsInProximity")) {
                if (!Array.isArray(message.GridIntersectionsInProximity))
                    return "GridIntersectionsInProximity: array expected";
                for (let i = 0; i < message.GridIntersectionsInProximity.length; ++i) {
                    let error = $root.Issue.SGridPair.verify(message.GridIntersectionsInProximity[i]);
                    if (error)
                        return "GridIntersectionsInProximity." + error;
                }
            }
            if (message.SrcA != null && message.hasOwnProperty("SrcA"))
                if (!$util.isString(message.SrcA))
                    return "SrcA: string expected";
            if (message.SrcB != null && message.hasOwnProperty("SrcB"))
                if (!$util.isString(message.SrcB))
                    return "SrcB: string expected";
            if (message.Contacts != null && message.hasOwnProperty("Contacts")) {
                if (!Array.isArray(message.Contacts))
                    return "Contacts: array expected";
                for (let i = 0; i < message.Contacts.length; ++i) {
                    let error = $root.SCenter.verify(message.Contacts[i]);
                    if (error)
                        return "Contacts." + error;
                }
            }
            return null;
        };

        RClashItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.RClashItem";
        };

        return RClashItem;
    })();

    Issue.RClash = (function() {

        function RClash(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RClash.prototype.guid = $util.newBuffer([]);
        RClash.prototype.Name = "";
        RClash.prototype.state = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClash.prototype.Number = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClash.prototype.test = "";
        RClash.prototype.testGuid = $util.newBuffer([]);
        RClash.prototype.items = $util.emptyArray;
        RClash.prototype.ColorSelectionA = 0;
        RClash.prototype.ColorSelectionB = 0;
        RClash.prototype.ColorResolvedIgnored = 0;

        RClash.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                writer.uint32(10).bytes(message.guid);
            if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
                writer.uint32(18).string(message.Name);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(24).int64(message.state);
            if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
                writer.uint32(32).int64(message.Number);
            if (message.test != null && Object.hasOwnProperty.call(message, "test"))
                writer.uint32(42).string(message.test);
            if (message.testGuid != null && Object.hasOwnProperty.call(message, "testGuid"))
                writer.uint32(50).bytes(message.testGuid);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.Issue.RClashItem.encode(message.items[i], writer.uint32(58).fork()).ldelim();
            if (message.ColorSelectionA != null && Object.hasOwnProperty.call(message, "ColorSelectionA"))
                writer.uint32(64).int32(message.ColorSelectionA);
            if (message.ColorSelectionB != null && Object.hasOwnProperty.call(message, "ColorSelectionB"))
                writer.uint32(72).int32(message.ColorSelectionB);
            if (message.ColorResolvedIgnored != null && Object.hasOwnProperty.call(message, "ColorResolvedIgnored"))
                writer.uint32(80).int32(message.ColorResolvedIgnored);
            return writer;
        };

        RClash.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RClash.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.RClash();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.guid = reader.bytes();
                        break;
                    }
                case 2: {
                        message.Name = reader.string();
                        break;
                    }
                case 3: {
                        message.state = reader.int64();
                        break;
                    }
                case 4: {
                        message.Number = reader.int64();
                        break;
                    }
                case 5: {
                        message.test = reader.string();
                        break;
                    }
                case 6: {
                        message.testGuid = reader.bytes();
                        break;
                    }
                case 7: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.Issue.RClashItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        message.ColorSelectionA = reader.int32();
                        break;
                    }
                case 9: {
                        message.ColorSelectionB = reader.int32();
                        break;
                    }
                case 10: {
                        message.ColorResolvedIgnored = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RClash.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RClash.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.guid != null && message.hasOwnProperty("guid"))
                if (!(message.guid && typeof message.guid.length === "number" || $util.isString(message.guid)))
                    return "guid: buffer expected";
            if (message.Name != null && message.hasOwnProperty("Name"))
                if (!$util.isString(message.Name))
                    return "Name: string expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (!$util.isInteger(message.state) && !(message.state && $util.isInteger(message.state.low) && $util.isInteger(message.state.high)))
                    return "state: integer|Long expected";
            if (message.Number != null && message.hasOwnProperty("Number"))
                if (!$util.isInteger(message.Number) && !(message.Number && $util.isInteger(message.Number.low) && $util.isInteger(message.Number.high)))
                    return "Number: integer|Long expected";
            if (message.test != null && message.hasOwnProperty("test"))
                if (!$util.isString(message.test))
                    return "test: string expected";
            if (message.testGuid != null && message.hasOwnProperty("testGuid"))
                if (!(message.testGuid && typeof message.testGuid.length === "number" || $util.isString(message.testGuid)))
                    return "testGuid: buffer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.Issue.RClashItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            if (message.ColorSelectionA != null && message.hasOwnProperty("ColorSelectionA"))
                if (!$util.isInteger(message.ColorSelectionA))
                    return "ColorSelectionA: integer expected";
            if (message.ColorSelectionB != null && message.hasOwnProperty("ColorSelectionB"))
                if (!$util.isInteger(message.ColorSelectionB))
                    return "ColorSelectionB: integer expected";
            if (message.ColorResolvedIgnored != null && message.hasOwnProperty("ColorResolvedIgnored"))
                if (!$util.isInteger(message.ColorResolvedIgnored))
                    return "ColorResolvedIgnored: integer expected";
            return null;
        };

        RClash.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.RClash";
        };

        return RClash;
    })();

    Issue.RClashStorage = (function() {

        function RClashStorage(properties) {
            this.items = [];
            this.strings = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RClashStorage.prototype.guid = $util.newBuffer([]);
        RClashStorage.prototype.iName = 0;
        RClashStorage.prototype.state = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashStorage.prototype.Number = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        RClashStorage.prototype.itest = 0;
        RClashStorage.prototype.testGuid = $util.newBuffer([]);
        RClashStorage.prototype.items = $util.emptyArray;
        RClashStorage.prototype.strings = $util.emptyArray;
        RClashStorage.prototype.compressMethod = 0;
        RClashStorage.prototype.compressed = $util.newBuffer([]);
        RClashStorage.prototype.ColorSelectionA = 0;
        RClashStorage.prototype.ColorSelectionB = 0;
        RClashStorage.prototype.ColorResolvedIgnored = 0;

        RClashStorage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                writer.uint32(10).bytes(message.guid);
            if (message.iName != null && Object.hasOwnProperty.call(message, "iName"))
                writer.uint32(16).int32(message.iName);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(24).int64(message.state);
            if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
                writer.uint32(32).int64(message.Number);
            if (message.itest != null && Object.hasOwnProperty.call(message, "itest"))
                writer.uint32(40).int32(message.itest);
            if (message.testGuid != null && Object.hasOwnProperty.call(message, "testGuid"))
                writer.uint32(50).bytes(message.testGuid);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.Issue.RClashStorage.ClashStorageItem.encode(message.items[i], writer.uint32(58).fork()).ldelim();
            if (message.strings != null && message.strings.length)
                for (let i = 0; i < message.strings.length; ++i)
                    writer.uint32(66).string(message.strings[i]);
            if (message.compressMethod != null && Object.hasOwnProperty.call(message, "compressMethod"))
                writer.uint32(72).int32(message.compressMethod);
            if (message.compressed != null && Object.hasOwnProperty.call(message, "compressed"))
                writer.uint32(82).bytes(message.compressed);
            if (message.ColorSelectionA != null && Object.hasOwnProperty.call(message, "ColorSelectionA"))
                writer.uint32(88).int32(message.ColorSelectionA);
            if (message.ColorSelectionB != null && Object.hasOwnProperty.call(message, "ColorSelectionB"))
                writer.uint32(96).int32(message.ColorSelectionB);
            if (message.ColorResolvedIgnored != null && Object.hasOwnProperty.call(message, "ColorResolvedIgnored"))
                writer.uint32(104).int32(message.ColorResolvedIgnored);
            return writer;
        };

        RClashStorage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RClashStorage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.RClashStorage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.guid = reader.bytes();
                        break;
                    }
                case 2: {
                        message.iName = reader.int32();
                        break;
                    }
                case 3: {
                        message.state = reader.int64();
                        break;
                    }
                case 4: {
                        message.Number = reader.int64();
                        break;
                    }
                case 5: {
                        message.itest = reader.int32();
                        break;
                    }
                case 6: {
                        message.testGuid = reader.bytes();
                        break;
                    }
                case 7: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.Issue.RClashStorage.ClashStorageItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        if (!(message.strings && message.strings.length))
                            message.strings = [];
                        message.strings.push(reader.string());
                        break;
                    }
                case 9: {
                        message.compressMethod = reader.int32();
                        break;
                    }
                case 10: {
                        message.compressed = reader.bytes();
                        break;
                    }
                case 11: {
                        message.ColorSelectionA = reader.int32();
                        break;
                    }
                case 12: {
                        message.ColorSelectionB = reader.int32();
                        break;
                    }
                case 13: {
                        message.ColorResolvedIgnored = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RClashStorage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RClashStorage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.guid != null && message.hasOwnProperty("guid"))
                if (!(message.guid && typeof message.guid.length === "number" || $util.isString(message.guid)))
                    return "guid: buffer expected";
            if (message.iName != null && message.hasOwnProperty("iName"))
                if (!$util.isInteger(message.iName))
                    return "iName: integer expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (!$util.isInteger(message.state) && !(message.state && $util.isInteger(message.state.low) && $util.isInteger(message.state.high)))
                    return "state: integer|Long expected";
            if (message.Number != null && message.hasOwnProperty("Number"))
                if (!$util.isInteger(message.Number) && !(message.Number && $util.isInteger(message.Number.low) && $util.isInteger(message.Number.high)))
                    return "Number: integer|Long expected";
            if (message.itest != null && message.hasOwnProperty("itest"))
                if (!$util.isInteger(message.itest))
                    return "itest: integer expected";
            if (message.testGuid != null && message.hasOwnProperty("testGuid"))
                if (!(message.testGuid && typeof message.testGuid.length === "number" || $util.isString(message.testGuid)))
                    return "testGuid: buffer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.Issue.RClashStorage.ClashStorageItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            if (message.strings != null && message.hasOwnProperty("strings")) {
                if (!Array.isArray(message.strings))
                    return "strings: array expected";
                for (let i = 0; i < message.strings.length; ++i)
                    if (!$util.isString(message.strings[i]))
                        return "strings: string[] expected";
            }
            if (message.compressMethod != null && message.hasOwnProperty("compressMethod"))
                switch (message.compressMethod) {
                default:
                    return "compressMethod: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.compressed != null && message.hasOwnProperty("compressed"))
                if (!(message.compressed && typeof message.compressed.length === "number" || $util.isString(message.compressed)))
                    return "compressed: buffer expected";
            if (message.ColorSelectionA != null && message.hasOwnProperty("ColorSelectionA"))
                if (!$util.isInteger(message.ColorSelectionA))
                    return "ColorSelectionA: integer expected";
            if (message.ColorSelectionB != null && message.hasOwnProperty("ColorSelectionB"))
                if (!$util.isInteger(message.ColorSelectionB))
                    return "ColorSelectionB: integer expected";
            if (message.ColorResolvedIgnored != null && message.hasOwnProperty("ColorResolvedIgnored"))
                if (!$util.isInteger(message.ColorResolvedIgnored))
                    return "ColorResolvedIgnored: integer expected";
            return null;
        };

        RClashStorage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.RClashStorage";
        };

        RClashStorage.IGridPair = (function() {

            function IGridPair(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            IGridPair.prototype.iItemA = 0;
            IGridPair.prototype.iItemB = 0;

            IGridPair.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.iItemA != null && Object.hasOwnProperty.call(message, "iItemA"))
                    writer.uint32(8).int32(message.iItemA);
                if (message.iItemB != null && Object.hasOwnProperty.call(message, "iItemB"))
                    writer.uint32(16).int32(message.iItemB);
                return writer;
            };

            IGridPair.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            IGridPair.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.RClashStorage.IGridPair();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.iItemA = reader.int32();
                            break;
                        }
                    case 2: {
                            message.iItemB = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            IGridPair.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            IGridPair.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.iItemA != null && message.hasOwnProperty("iItemA"))
                    if (!$util.isInteger(message.iItemA))
                        return "iItemA: integer expected";
                if (message.iItemB != null && message.hasOwnProperty("iItemB"))
                    if (!$util.isInteger(message.iItemB))
                        return "iItemB: integer expected";
                return null;
            };

            IGridPair.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.RClashStorage.IGridPair";
            };

            return IGridPair;
        })();

        RClashStorage.IGrid = (function() {

            function IGrid(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            IGrid.prototype.gridPair = null;

            IGrid.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.gridPair != null && Object.hasOwnProperty.call(message, "gridPair"))
                    $root.Issue.RClashStorage.IGridPair.encode(message.gridPair, writer.uint32(10).fork()).ldelim();
                return writer;
            };

            IGrid.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            IGrid.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.RClashStorage.IGrid();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.gridPair = $root.Issue.RClashStorage.IGridPair.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            IGrid.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            IGrid.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.gridPair != null && message.hasOwnProperty("gridPair")) {
                    let error = $root.Issue.RClashStorage.IGridPair.verify(message.gridPair);
                    if (error)
                        return "gridPair." + error;
                }
                return null;
            };

            IGrid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.RClashStorage.IGrid";
            };

            return IGrid;
        })();

        RClashStorage.ClashStorageItem = (function() {

            function ClashStorageItem(properties) {
                this.GridIntersectionsInProximity = [];
                this.Contacts = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ClashStorageItem.prototype.guid = $util.newBuffer([]);
            ClashStorageItem.prototype.FoundAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.iName = 0;
            ClashStorageItem.prototype.state = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.Number = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.iApprovedBy = 0;
            ClashStorageItem.prototype.ApprovedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.sceneBindUniqueIdA = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.firstObjectAReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.geometryAReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.sceneBindUniqueIdB = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.firstObjectBReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.geometryBReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ClashStorageItem.prototype.center = null;
            ClashStorageItem.prototype.iLevel = 0;
            ClashStorageItem.prototype.iRoom = 0;
            ClashStorageItem.prototype.iZone = 0;
            ClashStorageItem.prototype.iArea = 0;
            ClashStorageItem.prototype.iSpace = 0;
            ClashStorageItem.prototype.ClosestGrid = null;
            ClashStorageItem.prototype.GridIntersectionsInProximity = $util.emptyArray;
            ClashStorageItem.prototype.isrcA = 0;
            ClashStorageItem.prototype.isrcB = 0;
            ClashStorageItem.prototype.Contacts = $util.emptyArray;

            ClashStorageItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                    writer.uint32(10).bytes(message.guid);
                if (message.FoundAt != null && Object.hasOwnProperty.call(message, "FoundAt"))
                    writer.uint32(16).int64(message.FoundAt);
                if (message.iName != null && Object.hasOwnProperty.call(message, "iName"))
                    writer.uint32(24).int32(message.iName);
                if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                    writer.uint32(32).int64(message.state);
                if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
                    writer.uint32(40).int64(message.Number);
                if (message.iApprovedBy != null && Object.hasOwnProperty.call(message, "iApprovedBy"))
                    writer.uint32(48).int32(message.iApprovedBy);
                if (message.ApprovedAt != null && Object.hasOwnProperty.call(message, "ApprovedAt"))
                    writer.uint32(56).int64(message.ApprovedAt);
                if (message.sceneBindUniqueIdA != null && Object.hasOwnProperty.call(message, "sceneBindUniqueIdA"))
                    writer.uint32(65).fixed64(message.sceneBindUniqueIdA);
                if (message.firstObjectAReviztoId != null && Object.hasOwnProperty.call(message, "firstObjectAReviztoId"))
                    writer.uint32(73).fixed64(message.firstObjectAReviztoId);
                if (message.geometryAReviztoId != null && Object.hasOwnProperty.call(message, "geometryAReviztoId"))
                    writer.uint32(81).fixed64(message.geometryAReviztoId);
                if (message.sceneBindUniqueIdB != null && Object.hasOwnProperty.call(message, "sceneBindUniqueIdB"))
                    writer.uint32(89).fixed64(message.sceneBindUniqueIdB);
                if (message.firstObjectBReviztoId != null && Object.hasOwnProperty.call(message, "firstObjectBReviztoId"))
                    writer.uint32(97).fixed64(message.firstObjectBReviztoId);
                if (message.geometryBReviztoId != null && Object.hasOwnProperty.call(message, "geometryBReviztoId"))
                    writer.uint32(105).fixed64(message.geometryBReviztoId);
                if (message.center != null && Object.hasOwnProperty.call(message, "center"))
                    $root.SCenter.encode(message.center, writer.uint32(114).fork()).ldelim();
                if (message.iLevel != null && Object.hasOwnProperty.call(message, "iLevel"))
                    writer.uint32(120).int32(message.iLevel);
                if (message.iRoom != null && Object.hasOwnProperty.call(message, "iRoom"))
                    writer.uint32(128).int32(message.iRoom);
                if (message.iZone != null && Object.hasOwnProperty.call(message, "iZone"))
                    writer.uint32(136).int32(message.iZone);
                if (message.iArea != null && Object.hasOwnProperty.call(message, "iArea"))
                    writer.uint32(144).int32(message.iArea);
                if (message.iSpace != null && Object.hasOwnProperty.call(message, "iSpace"))
                    writer.uint32(152).int32(message.iSpace);
                if (message.ClosestGrid != null && Object.hasOwnProperty.call(message, "ClosestGrid"))
                    $root.Issue.RClashStorage.IGrid.encode(message.ClosestGrid, writer.uint32(162).fork()).ldelim();
                if (message.GridIntersectionsInProximity != null && message.GridIntersectionsInProximity.length)
                    for (let i = 0; i < message.GridIntersectionsInProximity.length; ++i)
                        $root.Issue.RClashStorage.IGridPair.encode(message.GridIntersectionsInProximity[i], writer.uint32(170).fork()).ldelim();
                if (message.isrcA != null && Object.hasOwnProperty.call(message, "isrcA"))
                    writer.uint32(176).int32(message.isrcA);
                if (message.isrcB != null && Object.hasOwnProperty.call(message, "isrcB"))
                    writer.uint32(184).int32(message.isrcB);
                if (message.Contacts != null && message.Contacts.length)
                    for (let i = 0; i < message.Contacts.length; ++i)
                        $root.SCenter.encode(message.Contacts[i], writer.uint32(194).fork()).ldelim();
                return writer;
            };

            ClashStorageItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            ClashStorageItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.RClashStorage.ClashStorageItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.guid = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.FoundAt = reader.int64();
                            break;
                        }
                    case 3: {
                            message.iName = reader.int32();
                            break;
                        }
                    case 4: {
                            message.state = reader.int64();
                            break;
                        }
                    case 5: {
                            message.Number = reader.int64();
                            break;
                        }
                    case 6: {
                            message.iApprovedBy = reader.int32();
                            break;
                        }
                    case 7: {
                            message.ApprovedAt = reader.int64();
                            break;
                        }
                    case 8: {
                            message.sceneBindUniqueIdA = reader.fixed64();
                            break;
                        }
                    case 9: {
                            message.firstObjectAReviztoId = reader.fixed64();
                            break;
                        }
                    case 10: {
                            message.geometryAReviztoId = reader.fixed64();
                            break;
                        }
                    case 11: {
                            message.sceneBindUniqueIdB = reader.fixed64();
                            break;
                        }
                    case 12: {
                            message.firstObjectBReviztoId = reader.fixed64();
                            break;
                        }
                    case 13: {
                            message.geometryBReviztoId = reader.fixed64();
                            break;
                        }
                    case 14: {
                            message.center = $root.SCenter.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.iLevel = reader.int32();
                            break;
                        }
                    case 16: {
                            message.iRoom = reader.int32();
                            break;
                        }
                    case 17: {
                            message.iZone = reader.int32();
                            break;
                        }
                    case 18: {
                            message.iArea = reader.int32();
                            break;
                        }
                    case 19: {
                            message.iSpace = reader.int32();
                            break;
                        }
                    case 20: {
                            message.ClosestGrid = $root.Issue.RClashStorage.IGrid.decode(reader, reader.uint32());
                            break;
                        }
                    case 21: {
                            if (!(message.GridIntersectionsInProximity && message.GridIntersectionsInProximity.length))
                                message.GridIntersectionsInProximity = [];
                            message.GridIntersectionsInProximity.push($root.Issue.RClashStorage.IGridPair.decode(reader, reader.uint32()));
                            break;
                        }
                    case 22: {
                            message.isrcA = reader.int32();
                            break;
                        }
                    case 23: {
                            message.isrcB = reader.int32();
                            break;
                        }
                    case 24: {
                            if (!(message.Contacts && message.Contacts.length))
                                message.Contacts = [];
                            message.Contacts.push($root.SCenter.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ClashStorageItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            ClashStorageItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.guid != null && message.hasOwnProperty("guid"))
                    if (!(message.guid && typeof message.guid.length === "number" || $util.isString(message.guid)))
                        return "guid: buffer expected";
                if (message.FoundAt != null && message.hasOwnProperty("FoundAt"))
                    if (!$util.isInteger(message.FoundAt) && !(message.FoundAt && $util.isInteger(message.FoundAt.low) && $util.isInteger(message.FoundAt.high)))
                        return "FoundAt: integer|Long expected";
                if (message.iName != null && message.hasOwnProperty("iName"))
                    if (!$util.isInteger(message.iName))
                        return "iName: integer expected";
                if (message.state != null && message.hasOwnProperty("state"))
                    if (!$util.isInteger(message.state) && !(message.state && $util.isInteger(message.state.low) && $util.isInteger(message.state.high)))
                        return "state: integer|Long expected";
                if (message.Number != null && message.hasOwnProperty("Number"))
                    if (!$util.isInteger(message.Number) && !(message.Number && $util.isInteger(message.Number.low) && $util.isInteger(message.Number.high)))
                        return "Number: integer|Long expected";
                if (message.iApprovedBy != null && message.hasOwnProperty("iApprovedBy"))
                    if (!$util.isInteger(message.iApprovedBy))
                        return "iApprovedBy: integer expected";
                if (message.ApprovedAt != null && message.hasOwnProperty("ApprovedAt"))
                    if (!$util.isInteger(message.ApprovedAt) && !(message.ApprovedAt && $util.isInteger(message.ApprovedAt.low) && $util.isInteger(message.ApprovedAt.high)))
                        return "ApprovedAt: integer|Long expected";
                if (message.sceneBindUniqueIdA != null && message.hasOwnProperty("sceneBindUniqueIdA"))
                    if (!$util.isInteger(message.sceneBindUniqueIdA) && !(message.sceneBindUniqueIdA && $util.isInteger(message.sceneBindUniqueIdA.low) && $util.isInteger(message.sceneBindUniqueIdA.high)))
                        return "sceneBindUniqueIdA: integer|Long expected";
                if (message.firstObjectAReviztoId != null && message.hasOwnProperty("firstObjectAReviztoId"))
                    if (!$util.isInteger(message.firstObjectAReviztoId) && !(message.firstObjectAReviztoId && $util.isInteger(message.firstObjectAReviztoId.low) && $util.isInteger(message.firstObjectAReviztoId.high)))
                        return "firstObjectAReviztoId: integer|Long expected";
                if (message.geometryAReviztoId != null && message.hasOwnProperty("geometryAReviztoId"))
                    if (!$util.isInteger(message.geometryAReviztoId) && !(message.geometryAReviztoId && $util.isInteger(message.geometryAReviztoId.low) && $util.isInteger(message.geometryAReviztoId.high)))
                        return "geometryAReviztoId: integer|Long expected";
                if (message.sceneBindUniqueIdB != null && message.hasOwnProperty("sceneBindUniqueIdB"))
                    if (!$util.isInteger(message.sceneBindUniqueIdB) && !(message.sceneBindUniqueIdB && $util.isInteger(message.sceneBindUniqueIdB.low) && $util.isInteger(message.sceneBindUniqueIdB.high)))
                        return "sceneBindUniqueIdB: integer|Long expected";
                if (message.firstObjectBReviztoId != null && message.hasOwnProperty("firstObjectBReviztoId"))
                    if (!$util.isInteger(message.firstObjectBReviztoId) && !(message.firstObjectBReviztoId && $util.isInteger(message.firstObjectBReviztoId.low) && $util.isInteger(message.firstObjectBReviztoId.high)))
                        return "firstObjectBReviztoId: integer|Long expected";
                if (message.geometryBReviztoId != null && message.hasOwnProperty("geometryBReviztoId"))
                    if (!$util.isInteger(message.geometryBReviztoId) && !(message.geometryBReviztoId && $util.isInteger(message.geometryBReviztoId.low) && $util.isInteger(message.geometryBReviztoId.high)))
                        return "geometryBReviztoId: integer|Long expected";
                if (message.center != null && message.hasOwnProperty("center")) {
                    let error = $root.SCenter.verify(message.center);
                    if (error)
                        return "center." + error;
                }
                if (message.iLevel != null && message.hasOwnProperty("iLevel"))
                    if (!$util.isInteger(message.iLevel))
                        return "iLevel: integer expected";
                if (message.iRoom != null && message.hasOwnProperty("iRoom"))
                    if (!$util.isInteger(message.iRoom))
                        return "iRoom: integer expected";
                if (message.iZone != null && message.hasOwnProperty("iZone"))
                    if (!$util.isInteger(message.iZone))
                        return "iZone: integer expected";
                if (message.iArea != null && message.hasOwnProperty("iArea"))
                    if (!$util.isInteger(message.iArea))
                        return "iArea: integer expected";
                if (message.iSpace != null && message.hasOwnProperty("iSpace"))
                    if (!$util.isInteger(message.iSpace))
                        return "iSpace: integer expected";
                if (message.ClosestGrid != null && message.hasOwnProperty("ClosestGrid")) {
                    let error = $root.Issue.RClashStorage.IGrid.verify(message.ClosestGrid);
                    if (error)
                        return "ClosestGrid." + error;
                }
                if (message.GridIntersectionsInProximity != null && message.hasOwnProperty("GridIntersectionsInProximity")) {
                    if (!Array.isArray(message.GridIntersectionsInProximity))
                        return "GridIntersectionsInProximity: array expected";
                    for (let i = 0; i < message.GridIntersectionsInProximity.length; ++i) {
                        let error = $root.Issue.RClashStorage.IGridPair.verify(message.GridIntersectionsInProximity[i]);
                        if (error)
                            return "GridIntersectionsInProximity." + error;
                    }
                }
                if (message.isrcA != null && message.hasOwnProperty("isrcA"))
                    if (!$util.isInteger(message.isrcA))
                        return "isrcA: integer expected";
                if (message.isrcB != null && message.hasOwnProperty("isrcB"))
                    if (!$util.isInteger(message.isrcB))
                        return "isrcB: integer expected";
                if (message.Contacts != null && message.hasOwnProperty("Contacts")) {
                    if (!Array.isArray(message.Contacts))
                        return "Contacts: array expected";
                    for (let i = 0; i < message.Contacts.length; ++i) {
                        let error = $root.SCenter.verify(message.Contacts[i]);
                        if (error)
                            return "Contacts." + error;
                    }
                }
                return null;
            };

            ClashStorageItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.RClashStorage.ClashStorageItem";
            };

            return ClashStorageItem;
        })();

        RClashStorage.CompressMethod = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "None"] = 0;
            values[valuesById[1] = "Deflate"] = 1;
            return values;
        })();

        RClashStorage.Compressed = (function() {

            function Compressed(properties) {
                this.items = [];
                this.strings = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Compressed.prototype.items = $util.emptyArray;
            Compressed.prototype.strings = $util.emptyArray;

            Compressed.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.Issue.RClashStorage.ClashStorageItem.encode(message.items[i], writer.uint32(10).fork()).ldelim();
                if (message.strings != null && message.strings.length)
                    for (let i = 0; i < message.strings.length; ++i)
                        writer.uint32(18).string(message.strings[i]);
                return writer;
            };

            Compressed.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            Compressed.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.RClashStorage.Compressed();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.Issue.RClashStorage.ClashStorageItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(message.strings && message.strings.length))
                                message.strings = [];
                            message.strings.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Compressed.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            Compressed.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.Issue.RClashStorage.ClashStorageItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.strings != null && message.hasOwnProperty("strings")) {
                    if (!Array.isArray(message.strings))
                        return "strings: array expected";
                    for (let i = 0; i < message.strings.length; ++i)
                        if (!$util.isString(message.strings[i]))
                            return "strings: string[] expected";
                }
                return null;
            };

            Compressed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.RClashStorage.Compressed";
            };

            return Compressed;
        })();

        return RClashStorage;
    })();

    Issue.Prop = (function() {

        function Prop(properties) {
            this.GridIntersectionsInProximity = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Prop.prototype.Level = "";
        Prop.prototype.Room = "";
        Prop.prototype.Zone = "";
        Prop.prototype.Area = "";
        Prop.prototype.Space = "";
        Prop.prototype.ClosestGrid = null;
        Prop.prototype.GridIntersectionsInProximity = $util.emptyArray;

        Prop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Level != null && Object.hasOwnProperty.call(message, "Level"))
                writer.uint32(10).string(message.Level);
            if (message.Room != null && Object.hasOwnProperty.call(message, "Room"))
                writer.uint32(18).string(message.Room);
            if (message.Zone != null && Object.hasOwnProperty.call(message, "Zone"))
                writer.uint32(26).string(message.Zone);
            if (message.Area != null && Object.hasOwnProperty.call(message, "Area"))
                writer.uint32(34).string(message.Area);
            if (message.Space != null && Object.hasOwnProperty.call(message, "Space"))
                writer.uint32(42).string(message.Space);
            if (message.ClosestGrid != null && Object.hasOwnProperty.call(message, "ClosestGrid"))
                $root.Issue.SGrid.encode(message.ClosestGrid, writer.uint32(50).fork()).ldelim();
            if (message.GridIntersectionsInProximity != null && message.GridIntersectionsInProximity.length)
                for (let i = 0; i < message.GridIntersectionsInProximity.length; ++i)
                    $root.Issue.SGridPair.encode(message.GridIntersectionsInProximity[i], writer.uint32(58).fork()).ldelim();
            return writer;
        };

        Prop.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Prop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Prop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Level = reader.string();
                        break;
                    }
                case 2: {
                        message.Room = reader.string();
                        break;
                    }
                case 3: {
                        message.Zone = reader.string();
                        break;
                    }
                case 4: {
                        message.Area = reader.string();
                        break;
                    }
                case 5: {
                        message.Space = reader.string();
                        break;
                    }
                case 6: {
                        message.ClosestGrid = $root.Issue.SGrid.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        if (!(message.GridIntersectionsInProximity && message.GridIntersectionsInProximity.length))
                            message.GridIntersectionsInProximity = [];
                        message.GridIntersectionsInProximity.push($root.Issue.SGridPair.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Prop.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Prop.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Level != null && message.hasOwnProperty("Level"))
                if (!$util.isString(message.Level))
                    return "Level: string expected";
            if (message.Room != null && message.hasOwnProperty("Room"))
                if (!$util.isString(message.Room))
                    return "Room: string expected";
            if (message.Zone != null && message.hasOwnProperty("Zone"))
                if (!$util.isString(message.Zone))
                    return "Zone: string expected";
            if (message.Area != null && message.hasOwnProperty("Area"))
                if (!$util.isString(message.Area))
                    return "Area: string expected";
            if (message.Space != null && message.hasOwnProperty("Space"))
                if (!$util.isString(message.Space))
                    return "Space: string expected";
            if (message.ClosestGrid != null && message.hasOwnProperty("ClosestGrid")) {
                let error = $root.Issue.SGrid.verify(message.ClosestGrid);
                if (error)
                    return "ClosestGrid." + error;
            }
            if (message.GridIntersectionsInProximity != null && message.hasOwnProperty("GridIntersectionsInProximity")) {
                if (!Array.isArray(message.GridIntersectionsInProximity))
                    return "GridIntersectionsInProximity: array expected";
                for (let i = 0; i < message.GridIntersectionsInProximity.length; ++i) {
                    let error = $root.Issue.SGridPair.verify(message.GridIntersectionsInProximity[i]);
                    if (error)
                        return "GridIntersectionsInProximity." + error;
                }
            }
            return null;
        };

        Prop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Prop";
        };

        return Prop;
    })();

    Issue.SGridPair = (function() {

        function SGridPair(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        SGridPair.prototype.ItemA = "";
        SGridPair.prototype.ItemB = "";

        SGridPair.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ItemA != null && Object.hasOwnProperty.call(message, "ItemA"))
                writer.uint32(10).string(message.ItemA);
            if (message.ItemB != null && Object.hasOwnProperty.call(message, "ItemB"))
                writer.uint32(18).string(message.ItemB);
            return writer;
        };

        SGridPair.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        SGridPair.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SGridPair();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ItemA = reader.string();
                        break;
                    }
                case 2: {
                        message.ItemB = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SGridPair.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        SGridPair.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ItemA != null && message.hasOwnProperty("ItemA"))
                if (!$util.isString(message.ItemA))
                    return "ItemA: string expected";
            if (message.ItemB != null && message.hasOwnProperty("ItemB"))
                if (!$util.isString(message.ItemB))
                    return "ItemB: string expected";
            return null;
        };

        SGridPair.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.SGridPair";
        };

        return SGridPair;
    })();

    Issue.SGrid = (function() {

        function SGrid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        SGrid.prototype.GridPair = null;

        SGrid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.GridPair != null && Object.hasOwnProperty.call(message, "GridPair"))
                $root.Issue.SGridPair.encode(message.GridPair, writer.uint32(10).fork()).ldelim();
            return writer;
        };

        SGrid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        SGrid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SGrid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.GridPair = $root.Issue.SGridPair.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SGrid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        SGrid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.GridPair != null && message.hasOwnProperty("GridPair")) {
                let error = $root.Issue.SGridPair.verify(message.GridPair);
                if (error)
                    return "GridPair." + error;
            }
            return null;
        };

        SGrid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.SGrid";
        };

        return SGrid;
    })();

    Issue.SelectionName = (function() {

        function SelectionName(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        SelectionName.prototype.name = "";
        SelectionName.prototype.cadId = 0;
        SelectionName.prototype.instanceCadPartId = 0;
        SelectionName.prototype.entityId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        SelectionName.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(10).string(message.name);
            if (message.cadId != null && Object.hasOwnProperty.call(message, "cadId"))
                writer.uint32(16).int32(message.cadId);
            if (message.instanceCadPartId != null && Object.hasOwnProperty.call(message, "instanceCadPartId"))
                writer.uint32(24).int32(message.instanceCadPartId);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(32).int64(message.entityId);
            return writer;
        };

        SelectionName.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        SelectionName.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SelectionName();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.cadId = reader.int32();
                        break;
                    }
                case 3: {
                        message.instanceCadPartId = reader.int32();
                        break;
                    }
                case 4: {
                        message.entityId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SelectionName.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        SelectionName.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.cadId != null && message.hasOwnProperty("cadId"))
                if (!$util.isInteger(message.cadId))
                    return "cadId: integer expected";
            if (message.instanceCadPartId != null && message.hasOwnProperty("instanceCadPartId"))
                if (!$util.isInteger(message.instanceCadPartId))
                    return "instanceCadPartId: integer expected";
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                if (!$util.isInteger(message.entityId) && !(message.entityId && $util.isInteger(message.entityId.low) && $util.isInteger(message.entityId.high)))
                    return "entityId: integer|Long expected";
            return null;
        };

        SelectionName.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.SelectionName";
        };

        return SelectionName;
    })();

    Issue.SelectionNameStorage = (function() {

        function SelectionNameStorage(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        SelectionNameStorage.prototype.items = $util.emptyArray;
        SelectionNameStorage.prototype.compressMethod = 0;
        SelectionNameStorage.prototype.compressed = $util.newBuffer([]);

        SelectionNameStorage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.Issue.SelectionNameStorage.SelectionItem.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            if (message.compressMethod != null && Object.hasOwnProperty.call(message, "compressMethod"))
                writer.uint32(16).int32(message.compressMethod);
            if (message.compressed != null && Object.hasOwnProperty.call(message, "compressed"))
                writer.uint32(26).bytes(message.compressed);
            return writer;
        };

        SelectionNameStorage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        SelectionNameStorage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SelectionNameStorage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.Issue.SelectionNameStorage.SelectionItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.compressMethod = reader.int32();
                        break;
                    }
                case 3: {
                        message.compressed = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SelectionNameStorage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        SelectionNameStorage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.Issue.SelectionNameStorage.SelectionItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            if (message.compressMethod != null && message.hasOwnProperty("compressMethod"))
                switch (message.compressMethod) {
                default:
                    return "compressMethod: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.compressed != null && message.hasOwnProperty("compressed"))
                if (!(message.compressed && typeof message.compressed.length === "number" || $util.isString(message.compressed)))
                    return "compressed: buffer expected";
            return null;
        };

        SelectionNameStorage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.SelectionNameStorage";
        };

        SelectionNameStorage.SelectionItem = (function() {

            function SelectionItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            SelectionItem.prototype.name = "";
            SelectionItem.prototype.cadId = 0;
            SelectionItem.prototype.instanceCadPartId = 0;
            SelectionItem.prototype.entityId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            SelectionItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(10).string(message.name);
                if (message.cadId != null && Object.hasOwnProperty.call(message, "cadId"))
                    writer.uint32(16).int32(message.cadId);
                if (message.instanceCadPartId != null && Object.hasOwnProperty.call(message, "instanceCadPartId"))
                    writer.uint32(24).int32(message.instanceCadPartId);
                if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                    writer.uint32(32).int64(message.entityId);
                return writer;
            };

            SelectionItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            SelectionItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SelectionNameStorage.SelectionItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.cadId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.instanceCadPartId = reader.int32();
                            break;
                        }
                    case 4: {
                            message.entityId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            SelectionItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            SelectionItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.cadId != null && message.hasOwnProperty("cadId"))
                    if (!$util.isInteger(message.cadId))
                        return "cadId: integer expected";
                if (message.instanceCadPartId != null && message.hasOwnProperty("instanceCadPartId"))
                    if (!$util.isInteger(message.instanceCadPartId))
                        return "instanceCadPartId: integer expected";
                if (message.entityId != null && message.hasOwnProperty("entityId"))
                    if (!$util.isInteger(message.entityId) && !(message.entityId && $util.isInteger(message.entityId.low) && $util.isInteger(message.entityId.high)))
                        return "entityId: integer|Long expected";
                return null;
            };

            SelectionItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.SelectionNameStorage.SelectionItem";
            };

            return SelectionItem;
        })();

        SelectionNameStorage.CompressMethod = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "None"] = 0;
            values[valuesById[1] = "Deflate"] = 1;
            return values;
        })();

        SelectionNameStorage.Compressed = (function() {

            function Compressed(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Compressed.prototype.items = $util.emptyArray;

            Compressed.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.Issue.SelectionNameStorage.SelectionItem.encode(message.items[i], writer.uint32(10).fork()).ldelim();
                return writer;
            };

            Compressed.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            Compressed.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SelectionNameStorage.Compressed();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.Issue.SelectionNameStorage.SelectionItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Compressed.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            Compressed.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.Issue.SelectionNameStorage.SelectionItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };

            Compressed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.SelectionNameStorage.Compressed";
            };

            return Compressed;
        })();

        return SelectionNameStorage;
    })();

    Issue.CDELink = (function() {

        function CDELink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CDELink.prototype.provider = "";
        CDELink.prototype.fileId = "";
        CDELink.prototype.name = "";
        CDELink.prototype.url = "";
        CDELink.prototype.companyId = "";
        CDELink.prototype.projectId = "";
        CDELink.prototype.versionId = "";

        CDELink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
                writer.uint32(10).string(message.provider);
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(18).string(message.fileId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(34).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(42).string(message.url);
            if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                writer.uint32(50).string(message.companyId);
            if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
                writer.uint32(58).string(message.projectId);
            if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
                writer.uint32(66).string(message.versionId);
            return writer;
        };

        CDELink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        CDELink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.CDELink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.provider = reader.string();
                        break;
                    }
                case 2: {
                        message.fileId = reader.string();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.url = reader.string();
                        break;
                    }
                case 6: {
                        message.companyId = reader.string();
                        break;
                    }
                case 7: {
                        message.projectId = reader.string();
                        break;
                    }
                case 8: {
                        message.versionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CDELink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        CDELink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.provider != null && message.hasOwnProperty("provider"))
                if (!$util.isString(message.provider))
                    return "provider: string expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.companyId != null && message.hasOwnProperty("companyId"))
                if (!$util.isString(message.companyId))
                    return "companyId: string expected";
            if (message.projectId != null && message.hasOwnProperty("projectId"))
                if (!$util.isString(message.projectId))
                    return "projectId: string expected";
            if (message.versionId != null && message.hasOwnProperty("versionId"))
                if (!$util.isString(message.versionId))
                    return "versionId: string expected";
            return null;
        };

        CDELink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.CDELink";
        };

        return CDELink;
    })();

    Issue.CDELinks = (function() {

        function CDELinks(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CDELinks.prototype.items = $util.emptyArray;

        CDELinks.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.Issue.CDELink.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        CDELinks.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        CDELinks.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.CDELinks();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.Issue.CDELink.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CDELinks.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        CDELinks.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.Issue.CDELink.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };

        CDELinks.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.CDELinks";
        };

        return CDELinks;
    })();

    Issue.MarkupType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MarkupTypeNone"] = 0;
        values[valuesById[1] = "Arrow"] = 1;
        values[valuesById[2] = "Rect"] = 2;
        values[valuesById[3] = "Ellipse"] = 3;
        values[valuesById[4] = "Text"] = 4;
        values[valuesById[5] = "Callout"] = 5;
        values[valuesById[6] = "Polyline"] = 6;
        values[valuesById[7] = "Pen"] = 7;
        return values;
    })();

    Issue.MarkupAlignment = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Left"] = 0;
        values[valuesById[1] = "Center"] = 1;
        values[valuesById[2] = "Right"] = 2;
        return values;
    })();

    Issue.MarkupRect = (function() {

        function MarkupRect(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MarkupRect.prototype.center = null;
        MarkupRect.prototype.size = null;
        MarkupRect.prototype.rotation = 0;

        MarkupRect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.center != null && Object.hasOwnProperty.call(message, "center"))
                $root.Issue.Vector2.encode(message.center, writer.uint32(10).fork()).ldelim();
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                $root.Issue.Vector2.encode(message.size, writer.uint32(18).fork()).ldelim();
            if (message.rotation != null && Object.hasOwnProperty.call(message, "rotation"))
                writer.uint32(29).float(message.rotation);
            return writer;
        };

        MarkupRect.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        MarkupRect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.MarkupRect();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.center = $root.Issue.Vector2.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.size = $root.Issue.Vector2.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.rotation = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MarkupRect.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        MarkupRect.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.center != null && message.hasOwnProperty("center")) {
                let error = $root.Issue.Vector2.verify(message.center);
                if (error)
                    return "center." + error;
            }
            if (message.size != null && message.hasOwnProperty("size")) {
                let error = $root.Issue.Vector2.verify(message.size);
                if (error)
                    return "size." + error;
            }
            if (message.rotation != null && message.hasOwnProperty("rotation"))
                if (typeof message.rotation !== "number")
                    return "rotation: number expected";
            return null;
        };

        MarkupRect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.MarkupRect";
        };

        return MarkupRect;
    })();

    Issue.MarkupArrow = (function() {

        function MarkupArrow(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MarkupArrow.prototype.start = null;
        MarkupArrow.prototype.end = null;
        MarkupArrow.prototype.size = 0;
        MarkupArrow.prototype.startType = 0;
        MarkupArrow.prototype.endType = 0;

        MarkupArrow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.Issue.Vector2.encode(message.start, writer.uint32(10).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.Issue.Vector2.encode(message.end, writer.uint32(18).fork()).ldelim();
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(29).float(message.size);
            if (message.startType != null && Object.hasOwnProperty.call(message, "startType"))
                writer.uint32(32).int32(message.startType);
            if (message.endType != null && Object.hasOwnProperty.call(message, "endType"))
                writer.uint32(40).int32(message.endType);
            return writer;
        };

        MarkupArrow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        MarkupArrow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.MarkupArrow();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.start = $root.Issue.Vector2.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.end = $root.Issue.Vector2.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.size = reader.float();
                        break;
                    }
                case 4: {
                        message.startType = reader.int32();
                        break;
                    }
                case 5: {
                        message.endType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MarkupArrow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        MarkupArrow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.start != null && message.hasOwnProperty("start")) {
                let error = $root.Issue.Vector2.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.Issue.Vector2.verify(message.end);
                if (error)
                    return "end." + error;
            }
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size !== "number")
                    return "size: number expected";
            if (message.startType != null && message.hasOwnProperty("startType"))
                if (!$util.isInteger(message.startType))
                    return "startType: integer expected";
            if (message.endType != null && message.hasOwnProperty("endType"))
                if (!$util.isInteger(message.endType))
                    return "endType: integer expected";
            return null;
        };

        MarkupArrow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.MarkupArrow";
        };

        return MarkupArrow;
    })();

    Issue.MarkupStroke = (function() {

        function MarkupStroke(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MarkupStroke.prototype.color = 0;
        MarkupStroke.prototype.thickness = 0;
        MarkupStroke.prototype.type = 0;

        MarkupStroke.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(8).int32(message.color);
            if (message.thickness != null && Object.hasOwnProperty.call(message, "thickness"))
                writer.uint32(21).float(message.thickness);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(24).int32(message.type);
            return writer;
        };

        MarkupStroke.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        MarkupStroke.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.MarkupStroke();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.color = reader.int32();
                        break;
                    }
                case 2: {
                        message.thickness = reader.float();
                        break;
                    }
                case 3: {
                        message.type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MarkupStroke.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        MarkupStroke.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isInteger(message.color))
                    return "color: integer expected";
            if (message.thickness != null && message.hasOwnProperty("thickness"))
                if (typeof message.thickness !== "number")
                    return "thickness: number expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isInteger(message.type))
                    return "type: integer expected";
            return null;
        };

        MarkupStroke.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.MarkupStroke";
        };

        return MarkupStroke;
    })();

    Issue.MarkupFill = (function() {

        function MarkupFill(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MarkupFill.prototype.color = 0;
        MarkupFill.prototype.opacity = 0;

        MarkupFill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(8).int32(message.color);
            if (message.opacity != null && Object.hasOwnProperty.call(message, "opacity"))
                writer.uint32(21).float(message.opacity);
            return writer;
        };

        MarkupFill.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        MarkupFill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.MarkupFill();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.color = reader.int32();
                        break;
                    }
                case 2: {
                        message.opacity = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MarkupFill.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        MarkupFill.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isInteger(message.color))
                    return "color: integer expected";
            if (message.opacity != null && message.hasOwnProperty("opacity"))
                if (typeof message.opacity !== "number")
                    return "opacity: number expected";
            return null;
        };

        MarkupFill.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.MarkupFill";
        };

        return MarkupFill;
    })();

    Issue.MarkupText = (function() {

        function MarkupText(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MarkupText.prototype.text = "";
        MarkupText.prototype.color = 0;
        MarkupText.prototype.size = 0;
        MarkupText.prototype.alignment = 0;

        MarkupText.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(10).string(message.text);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(16).int32(message.color);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(29).float(message.size);
            if (message.alignment != null && Object.hasOwnProperty.call(message, "alignment"))
                writer.uint32(32).int32(message.alignment);
            return writer;
        };

        MarkupText.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        MarkupText.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.MarkupText();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.text = reader.string();
                        break;
                    }
                case 2: {
                        message.color = reader.int32();
                        break;
                    }
                case 3: {
                        message.size = reader.float();
                        break;
                    }
                case 4: {
                        message.alignment = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MarkupText.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        MarkupText.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isInteger(message.color))
                    return "color: integer expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size !== "number")
                    return "size: number expected";
            if (message.alignment != null && message.hasOwnProperty("alignment"))
                switch (message.alignment) {
                default:
                    return "alignment: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        MarkupText.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.MarkupText";
        };

        return MarkupText;
    })();

    Issue.Markup = (function() {

        function Markup(properties) {
            this.points = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Markup.prototype.type416 = 0;
        Markup.prototype.rect = null;
        Markup.prototype.fill = null;
        Markup.prototype.stroke = null;
        Markup.prototype.textData = null;
        Markup.prototype.arrow = null;
        Markup.prototype.points = $util.emptyArray;
        Markup.prototype.calloutBorder = false;
        Markup.prototype.type417 = 0;

        Markup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type416 != null && Object.hasOwnProperty.call(message, "type416"))
                writer.uint32(8).int32(message.type416);
            if (message.rect != null && Object.hasOwnProperty.call(message, "rect"))
                $root.Issue.MarkupRect.encode(message.rect, writer.uint32(18).fork()).ldelim();
            if (message.fill != null && Object.hasOwnProperty.call(message, "fill"))
                $root.Issue.MarkupFill.encode(message.fill, writer.uint32(26).fork()).ldelim();
            if (message.stroke != null && Object.hasOwnProperty.call(message, "stroke"))
                $root.Issue.MarkupStroke.encode(message.stroke, writer.uint32(34).fork()).ldelim();
            if (message.textData != null && Object.hasOwnProperty.call(message, "textData"))
                $root.Issue.MarkupText.encode(message.textData, writer.uint32(42).fork()).ldelim();
            if (message.arrow != null && Object.hasOwnProperty.call(message, "arrow"))
                $root.Issue.MarkupArrow.encode(message.arrow, writer.uint32(50).fork()).ldelim();
            if (message.points != null && message.points.length)
                for (let i = 0; i < message.points.length; ++i)
                    $root.Issue.Vector2.encode(message.points[i], writer.uint32(58).fork()).ldelim();
            if (message.calloutBorder != null && Object.hasOwnProperty.call(message, "calloutBorder"))
                writer.uint32(64).bool(message.calloutBorder);
            if (message.type417 != null && Object.hasOwnProperty.call(message, "type417"))
                writer.uint32(72).int32(message.type417);
            return writer;
        };

        Markup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Markup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Markup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type416 = reader.int32();
                        break;
                    }
                case 2: {
                        message.rect = $root.Issue.MarkupRect.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.fill = $root.Issue.MarkupFill.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.stroke = $root.Issue.MarkupStroke.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.textData = $root.Issue.MarkupText.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.arrow = $root.Issue.MarkupArrow.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        if (!(message.points && message.points.length))
                            message.points = [];
                        message.points.push($root.Issue.Vector2.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        message.calloutBorder = reader.bool();
                        break;
                    }
                case 9: {
                        message.type417 = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Markup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Markup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type416 != null && message.hasOwnProperty("type416"))
                switch (message.type416) {
                default:
                    return "type416: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.rect != null && message.hasOwnProperty("rect")) {
                let error = $root.Issue.MarkupRect.verify(message.rect);
                if (error)
                    return "rect." + error;
            }
            if (message.fill != null && message.hasOwnProperty("fill")) {
                let error = $root.Issue.MarkupFill.verify(message.fill);
                if (error)
                    return "fill." + error;
            }
            if (message.stroke != null && message.hasOwnProperty("stroke")) {
                let error = $root.Issue.MarkupStroke.verify(message.stroke);
                if (error)
                    return "stroke." + error;
            }
            if (message.textData != null && message.hasOwnProperty("textData")) {
                let error = $root.Issue.MarkupText.verify(message.textData);
                if (error)
                    return "textData." + error;
            }
            if (message.arrow != null && message.hasOwnProperty("arrow")) {
                let error = $root.Issue.MarkupArrow.verify(message.arrow);
                if (error)
                    return "arrow." + error;
            }
            if (message.points != null && message.hasOwnProperty("points")) {
                if (!Array.isArray(message.points))
                    return "points: array expected";
                for (let i = 0; i < message.points.length; ++i) {
                    let error = $root.Issue.Vector2.verify(message.points[i]);
                    if (error)
                        return "points." + error;
                }
            }
            if (message.calloutBorder != null && message.hasOwnProperty("calloutBorder"))
                if (typeof message.calloutBorder !== "boolean")
                    return "calloutBorder: boolean expected";
            if (message.type417 != null && message.hasOwnProperty("type417"))
                if (!$util.isInteger(message.type417))
                    return "type417: integer expected";
            return null;
        };

        Markup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Markup";
        };

        return Markup;
    })();

    Issue.Comment = (function() {

        function Comment(properties) {
            this.markups = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Comment.prototype.type = 0;
        Comment.prototype.reporter = "";
        Comment.prototype.guid = "";
        Comment.prototype.created = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Comment.prototype.author = "";
        Comment.prototype.text = "";
        Comment.prototype.mimeType = "";
        Comment.prototype.links = null;
        Comment.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Comment.prototype.filename = "";
        Comment.prototype.bufferOriginal = $util.newBuffer([]);
        Comment.prototype.bufferSmall = $util.newBuffer([]);
        Comment.prototype.differences0 = 0;
        Comment.prototype.prevMarker = null;
        Comment.prototype.newMarker = null;
        Comment.prototype.id = 0;
        Comment.prototype.markups = $util.emptyArray;
        Comment.prototype.subMarkups = "";
        Comment.prototype.internalProperties = null;
        Comment.prototype.subMarkupsType = 0;
        Comment.prototype.differences1 = 0;
        Comment.prototype.clashSync = false;
        Comment.prototype.rclashSync = false;

        Comment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(8).int32(message.type);
            if (message.reporter != null && Object.hasOwnProperty.call(message, "reporter"))
                writer.uint32(18).string(message.reporter);
            if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                writer.uint32(26).string(message.guid);
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                writer.uint32(32).int64(message.created);
            if (message.author != null && Object.hasOwnProperty.call(message, "author"))
                writer.uint32(42).string(message.author);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(50).string(message.text);
            if (message.mimeType != null && Object.hasOwnProperty.call(message, "mimeType"))
                writer.uint32(58).string(message.mimeType);
            if (message.links != null && Object.hasOwnProperty.call(message, "links"))
                $root.Issue.Comment.LinksStruct.encode(message.links, writer.uint32(66).fork()).ldelim();
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(72).int64(message.size);
            if (message.filename != null && Object.hasOwnProperty.call(message, "filename"))
                writer.uint32(82).string(message.filename);
            if (message.bufferOriginal != null && Object.hasOwnProperty.call(message, "bufferOriginal"))
                writer.uint32(90).bytes(message.bufferOriginal);
            if (message.bufferSmall != null && Object.hasOwnProperty.call(message, "bufferSmall"))
                writer.uint32(98).bytes(message.bufferSmall);
            if (message.differences0 != null && Object.hasOwnProperty.call(message, "differences0"))
                writer.uint32(104).int32(message.differences0);
            if (message.prevMarker != null && Object.hasOwnProperty.call(message, "prevMarker"))
                $root.Issue.Diff.encode(message.prevMarker, writer.uint32(114).fork()).ldelim();
            if (message.newMarker != null && Object.hasOwnProperty.call(message, "newMarker"))
                $root.Issue.Diff.encode(message.newMarker, writer.uint32(122).fork()).ldelim();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(128).int32(message.id);
            if (message.markups != null && message.markups.length)
                for (let i = 0; i < message.markups.length; ++i)
                    $root.Issue.Markup.encode(message.markups[i], writer.uint32(138).fork()).ldelim();
            if (message.subMarkups != null && Object.hasOwnProperty.call(message, "subMarkups"))
                writer.uint32(146).string(message.subMarkups);
            if (message.internalProperties != null && Object.hasOwnProperty.call(message, "internalProperties"))
                $root.Issue.InternalProperties.encode(message.internalProperties, writer.uint32(154).fork()).ldelim();
            if (message.subMarkupsType != null && Object.hasOwnProperty.call(message, "subMarkupsType"))
                writer.uint32(160).int32(message.subMarkupsType);
            if (message.differences1 != null && Object.hasOwnProperty.call(message, "differences1"))
                writer.uint32(168).int32(message.differences1);
            if (message.clashSync != null && Object.hasOwnProperty.call(message, "clashSync"))
                writer.uint32(176).bool(message.clashSync);
            if (message.rclashSync != null && Object.hasOwnProperty.call(message, "rclashSync"))
                writer.uint32(184).bool(message.rclashSync);
            return writer;
        };

        Comment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Comment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Comment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.reporter = reader.string();
                        break;
                    }
                case 3: {
                        message.guid = reader.string();
                        break;
                    }
                case 4: {
                        message.created = reader.int64();
                        break;
                    }
                case 5: {
                        message.author = reader.string();
                        break;
                    }
                case 6: {
                        message.text = reader.string();
                        break;
                    }
                case 7: {
                        message.mimeType = reader.string();
                        break;
                    }
                case 8: {
                        message.links = $root.Issue.Comment.LinksStruct.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.size = reader.int64();
                        break;
                    }
                case 10: {
                        message.filename = reader.string();
                        break;
                    }
                case 11: {
                        message.bufferOriginal = reader.bytes();
                        break;
                    }
                case 12: {
                        message.bufferSmall = reader.bytes();
                        break;
                    }
                case 13: {
                        message.differences0 = reader.int32();
                        break;
                    }
                case 14: {
                        message.prevMarker = $root.Issue.Diff.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.newMarker = $root.Issue.Diff.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.id = reader.int32();
                        break;
                    }
                case 17: {
                        if (!(message.markups && message.markups.length))
                            message.markups = [];
                        message.markups.push($root.Issue.Markup.decode(reader, reader.uint32()));
                        break;
                    }
                case 18: {
                        message.subMarkups = reader.string();
                        break;
                    }
                case 19: {
                        message.internalProperties = $root.Issue.InternalProperties.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.subMarkupsType = reader.int32();
                        break;
                    }
                case 21: {
                        message.differences1 = reader.int32();
                        break;
                    }
                case 22: {
                        message.clashSync = reader.bool();
                        break;
                    }
                case 23: {
                        message.rclashSync = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Comment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Comment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.reporter != null && message.hasOwnProperty("reporter"))
                if (!$util.isString(message.reporter))
                    return "reporter: string expected";
            if (message.guid != null && message.hasOwnProperty("guid"))
                if (!$util.isString(message.guid))
                    return "guid: string expected";
            if (message.created != null && message.hasOwnProperty("created"))
                if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                    return "created: integer|Long expected";
            if (message.author != null && message.hasOwnProperty("author"))
                if (!$util.isString(message.author))
                    return "author: string expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                if (!$util.isString(message.mimeType))
                    return "mimeType: string expected";
            if (message.links != null && message.hasOwnProperty("links")) {
                let error = $root.Issue.Comment.LinksStruct.verify(message.links);
                if (error)
                    return "links." + error;
            }
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            if (message.filename != null && message.hasOwnProperty("filename"))
                if (!$util.isString(message.filename))
                    return "filename: string expected";
            if (message.bufferOriginal != null && message.hasOwnProperty("bufferOriginal"))
                if (!(message.bufferOriginal && typeof message.bufferOriginal.length === "number" || $util.isString(message.bufferOriginal)))
                    return "bufferOriginal: buffer expected";
            if (message.bufferSmall != null && message.hasOwnProperty("bufferSmall"))
                if (!(message.bufferSmall && typeof message.bufferSmall.length === "number" || $util.isString(message.bufferSmall)))
                    return "bufferSmall: buffer expected";
            if (message.differences0 != null && message.hasOwnProperty("differences0"))
                switch (message.differences0) {
                default:
                    return "differences0: enum value expected";
                case 0:
                case 1:
                case 2:
                case 4:
                case 8:
                case 16:
                case 32:
                case 64:
                case 128:
                case 256:
                case 512:
                case 1024:
                case 2048:
                case 4096:
                case 8192:
                case 16384:
                case 32768:
                case 65536:
                case 131072:
                case 262144:
                case 524288:
                case 1048576:
                case 2097152:
                case 4194304:
                case 8388608:
                case 16777216:
                case 33554432:
                case 67108864:
                case 134217728:
                case 268435456:
                case 536870912:
                case 1073741824:
                    break;
                }
            if (message.prevMarker != null && message.hasOwnProperty("prevMarker")) {
                let error = $root.Issue.Diff.verify(message.prevMarker);
                if (error)
                    return "prevMarker." + error;
            }
            if (message.newMarker != null && message.hasOwnProperty("newMarker")) {
                let error = $root.Issue.Diff.verify(message.newMarker);
                if (error)
                    return "newMarker." + error;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.markups != null && message.hasOwnProperty("markups")) {
                if (!Array.isArray(message.markups))
                    return "markups: array expected";
                for (let i = 0; i < message.markups.length; ++i) {
                    let error = $root.Issue.Markup.verify(message.markups[i]);
                    if (error)
                        return "markups." + error;
                }
            }
            if (message.subMarkups != null && message.hasOwnProperty("subMarkups"))
                if (!$util.isString(message.subMarkups))
                    return "subMarkups: string expected";
            if (message.internalProperties != null && message.hasOwnProperty("internalProperties")) {
                let error = $root.Issue.InternalProperties.verify(message.internalProperties);
                if (error)
                    return "internalProperties." + error;
            }
            if (message.subMarkupsType != null && message.hasOwnProperty("subMarkupsType"))
                if (!$util.isInteger(message.subMarkupsType))
                    return "subMarkupsType: integer expected";
            if (message.differences1 != null && message.hasOwnProperty("differences1"))
                switch (message.differences1) {
                default:
                    return "differences1: enum value expected";
                case 0:
                case 1:
                case 2:
                case 4:
                case 8:
                case 16:
                case 32:
                case 64:
                    break;
                }
            if (message.clashSync != null && message.hasOwnProperty("clashSync"))
                if (typeof message.clashSync !== "boolean")
                    return "clashSync: boolean expected";
            if (message.rclashSync != null && message.hasOwnProperty("rclashSync"))
                if (typeof message.rclashSync !== "boolean")
                    return "rclashSync: boolean expected";
            return null;
        };

        Comment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Comment";
        };

        Comment.CommentType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Text"] = 0;
            values[valuesById[1] = "Attachment"] = 1;
            values[valuesById[2] = "Differences"] = 2;
            values[valuesById[3] = "Markups"] = 3;
            return values;
        })();

        Comment.LinksStruct = (function() {

            function LinksStruct(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            LinksStruct.prototype.original = "";
            LinksStruct.prototype.small = "";

            LinksStruct.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                    writer.uint32(10).string(message.original);
                if (message.small != null && Object.hasOwnProperty.call(message, "small"))
                    writer.uint32(18).string(message.small);
                return writer;
            };

            LinksStruct.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            LinksStruct.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Comment.LinksStruct();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.original = reader.string();
                            break;
                        }
                    case 2: {
                            message.small = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            LinksStruct.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            LinksStruct.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.original != null && message.hasOwnProperty("original"))
                    if (!$util.isString(message.original))
                        return "original: string expected";
                if (message.small != null && message.hasOwnProperty("small"))
                    if (!$util.isString(message.small))
                        return "small: string expected";
                return null;
            };

            LinksStruct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.Comment.LinksStruct";
            };

            return LinksStruct;
        })();

        return Comment;
    })();

    Issue.Selection = (function() {

        function Selection(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Selection.prototype.authoringToolId = "";
        Selection.prototype.uniqueReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        Selection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.authoringToolId != null && Object.hasOwnProperty.call(message, "authoringToolId"))
                writer.uint32(10).string(message.authoringToolId);
            if (message.uniqueReviztoId != null && Object.hasOwnProperty.call(message, "uniqueReviztoId"))
                writer.uint32(16).int64(message.uniqueReviztoId);
            return writer;
        };

        Selection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Selection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Selection();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.authoringToolId = reader.string();
                        break;
                    }
                case 2: {
                        message.uniqueReviztoId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Selection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Selection.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.authoringToolId != null && message.hasOwnProperty("authoringToolId"))
                if (!$util.isString(message.authoringToolId))
                    return "authoringToolId: string expected";
            if (message.uniqueReviztoId != null && message.hasOwnProperty("uniqueReviztoId"))
                if (!$util.isInteger(message.uniqueReviztoId) && !(message.uniqueReviztoId && $util.isInteger(message.uniqueReviztoId.low) && $util.isInteger(message.uniqueReviztoId.high)))
                    return "uniqueReviztoId: integer|Long expected";
            return null;
        };

        Selection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Selection";
        };

        return Selection;
    })();

    Issue.SelectionStorage = (function() {

        function SelectionStorage(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        SelectionStorage.prototype.items = $util.emptyArray;
        SelectionStorage.prototype.compressMethod = 0;
        SelectionStorage.prototype.compressed = $util.newBuffer([]);

        SelectionStorage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.Issue.SelectionStorage.SelectionItem.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            if (message.compressMethod != null && Object.hasOwnProperty.call(message, "compressMethod"))
                writer.uint32(16).int32(message.compressMethod);
            if (message.compressed != null && Object.hasOwnProperty.call(message, "compressed"))
                writer.uint32(26).bytes(message.compressed);
            return writer;
        };

        SelectionStorage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        SelectionStorage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SelectionStorage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.Issue.SelectionStorage.SelectionItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.compressMethod = reader.int32();
                        break;
                    }
                case 3: {
                        message.compressed = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SelectionStorage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        SelectionStorage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.Issue.SelectionStorage.SelectionItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            if (message.compressMethod != null && message.hasOwnProperty("compressMethod"))
                switch (message.compressMethod) {
                default:
                    return "compressMethod: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.compressed != null && message.hasOwnProperty("compressed"))
                if (!(message.compressed && typeof message.compressed.length === "number" || $util.isString(message.compressed)))
                    return "compressed: buffer expected";
            return null;
        };

        SelectionStorage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.SelectionStorage";
        };

        SelectionStorage.SelectionItem = (function() {

            function SelectionItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            SelectionItem.prototype.authoringToolId = "";
            SelectionItem.prototype.uniqueReviztoId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            SelectionItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.authoringToolId != null && Object.hasOwnProperty.call(message, "authoringToolId"))
                    writer.uint32(10).string(message.authoringToolId);
                if (message.uniqueReviztoId != null && Object.hasOwnProperty.call(message, "uniqueReviztoId"))
                    writer.uint32(16).int64(message.uniqueReviztoId);
                return writer;
            };

            SelectionItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            SelectionItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SelectionStorage.SelectionItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.authoringToolId = reader.string();
                            break;
                        }
                    case 2: {
                            message.uniqueReviztoId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            SelectionItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            SelectionItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.authoringToolId != null && message.hasOwnProperty("authoringToolId"))
                    if (!$util.isString(message.authoringToolId))
                        return "authoringToolId: string expected";
                if (message.uniqueReviztoId != null && message.hasOwnProperty("uniqueReviztoId"))
                    if (!$util.isInteger(message.uniqueReviztoId) && !(message.uniqueReviztoId && $util.isInteger(message.uniqueReviztoId.low) && $util.isInteger(message.uniqueReviztoId.high)))
                        return "uniqueReviztoId: integer|Long expected";
                return null;
            };

            SelectionItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.SelectionStorage.SelectionItem";
            };

            return SelectionItem;
        })();

        SelectionStorage.CompressMethod = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CompressMethodNone"] = 0;
            values[valuesById[1] = "Deflate"] = 1;
            return values;
        })();

        SelectionStorage.Compressed = (function() {

            function Compressed(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Compressed.prototype.items = $util.emptyArray;

            Compressed.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.Issue.SelectionStorage.SelectionItem.encode(message.items[i], writer.uint32(10).fork()).ldelim();
                return writer;
            };

            Compressed.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            Compressed.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.SelectionStorage.Compressed();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.Issue.SelectionStorage.SelectionItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Compressed.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            Compressed.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.Issue.SelectionStorage.SelectionItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };

            Compressed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.SelectionStorage.Compressed";
            };

            return Compressed;
        })();

        return SelectionStorage;
    })();

    Issue.ClashItem = (function() {

        function ClashItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ClashItem.prototype.center = null;
        ClashItem.prototype.distance = 0;
        ClashItem.prototype.src0 = "";
        ClashItem.prototype.src1 = "";
        ClashItem.prototype.category0 = "";
        ClashItem.prototype.category1 = "";
        ClashItem.prototype.discipline0 = 0;
        ClashItem.prototype.discipline1 = 0;
        ClashItem.prototype.level = "";
        ClashItem.prototype.grid1 = "";
        ClashItem.prototype.grid2 = "";
        ClashItem.prototype.authoringToolId0 = "";
        ClashItem.prototype.authoringToolId1 = "";
        ClashItem.prototype.name = "";
        ClashItem.prototype.navisworksStatus = 0;
        ClashItem.prototype.guid = "";

        ClashItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.center != null && Object.hasOwnProperty.call(message, "center"))
                $root.Vector3.encode(message.center, writer.uint32(10).fork()).ldelim();
            if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                writer.uint32(21).float(message.distance);
            if (message.src0 != null && Object.hasOwnProperty.call(message, "src0"))
                writer.uint32(26).string(message.src0);
            if (message.src1 != null && Object.hasOwnProperty.call(message, "src1"))
                writer.uint32(34).string(message.src1);
            if (message.category0 != null && Object.hasOwnProperty.call(message, "category0"))
                writer.uint32(42).string(message.category0);
            if (message.category1 != null && Object.hasOwnProperty.call(message, "category1"))
                writer.uint32(50).string(message.category1);
            if (message.discipline0 != null && Object.hasOwnProperty.call(message, "discipline0"))
                writer.uint32(56).int32(message.discipline0);
            if (message.discipline1 != null && Object.hasOwnProperty.call(message, "discipline1"))
                writer.uint32(64).int32(message.discipline1);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(74).string(message.level);
            if (message.grid1 != null && Object.hasOwnProperty.call(message, "grid1"))
                writer.uint32(82).string(message.grid1);
            if (message.grid2 != null && Object.hasOwnProperty.call(message, "grid2"))
                writer.uint32(90).string(message.grid2);
            if (message.authoringToolId0 != null && Object.hasOwnProperty.call(message, "authoringToolId0"))
                writer.uint32(98).string(message.authoringToolId0);
            if (message.authoringToolId1 != null && Object.hasOwnProperty.call(message, "authoringToolId1"))
                writer.uint32(106).string(message.authoringToolId1);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(122).string(message.name);
            if (message.navisworksStatus != null && Object.hasOwnProperty.call(message, "navisworksStatus"))
                writer.uint32(128).int32(message.navisworksStatus);
            if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                writer.uint32(138).string(message.guid);
            return writer;
        };

        ClashItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ClashItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.ClashItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.center = $root.Vector3.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.distance = reader.float();
                        break;
                    }
                case 3: {
                        message.src0 = reader.string();
                        break;
                    }
                case 4: {
                        message.src1 = reader.string();
                        break;
                    }
                case 5: {
                        message.category0 = reader.string();
                        break;
                    }
                case 6: {
                        message.category1 = reader.string();
                        break;
                    }
                case 7: {
                        message.discipline0 = reader.int32();
                        break;
                    }
                case 8: {
                        message.discipline1 = reader.int32();
                        break;
                    }
                case 9: {
                        message.level = reader.string();
                        break;
                    }
                case 10: {
                        message.grid1 = reader.string();
                        break;
                    }
                case 11: {
                        message.grid2 = reader.string();
                        break;
                    }
                case 12: {
                        message.authoringToolId0 = reader.string();
                        break;
                    }
                case 13: {
                        message.authoringToolId1 = reader.string();
                        break;
                    }
                case 15: {
                        message.name = reader.string();
                        break;
                    }
                case 16: {
                        message.navisworksStatus = reader.int32();
                        break;
                    }
                case 17: {
                        message.guid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ClashItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ClashItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.center != null && message.hasOwnProperty("center")) {
                let error = $root.Vector3.verify(message.center);
                if (error)
                    return "center." + error;
            }
            if (message.distance != null && message.hasOwnProperty("distance"))
                if (typeof message.distance !== "number")
                    return "distance: number expected";
            if (message.src0 != null && message.hasOwnProperty("src0"))
                if (!$util.isString(message.src0))
                    return "src0: string expected";
            if (message.src1 != null && message.hasOwnProperty("src1"))
                if (!$util.isString(message.src1))
                    return "src1: string expected";
            if (message.category0 != null && message.hasOwnProperty("category0"))
                if (!$util.isString(message.category0))
                    return "category0: string expected";
            if (message.category1 != null && message.hasOwnProperty("category1"))
                if (!$util.isString(message.category1))
                    return "category1: string expected";
            if (message.discipline0 != null && message.hasOwnProperty("discipline0"))
                if (!$util.isInteger(message.discipline0))
                    return "discipline0: integer expected";
            if (message.discipline1 != null && message.hasOwnProperty("discipline1"))
                if (!$util.isInteger(message.discipline1))
                    return "discipline1: integer expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (!$util.isString(message.level))
                    return "level: string expected";
            if (message.grid1 != null && message.hasOwnProperty("grid1"))
                if (!$util.isString(message.grid1))
                    return "grid1: string expected";
            if (message.grid2 != null && message.hasOwnProperty("grid2"))
                if (!$util.isString(message.grid2))
                    return "grid2: string expected";
            if (message.authoringToolId0 != null && message.hasOwnProperty("authoringToolId0"))
                if (!$util.isString(message.authoringToolId0))
                    return "authoringToolId0: string expected";
            if (message.authoringToolId1 != null && message.hasOwnProperty("authoringToolId1"))
                if (!$util.isString(message.authoringToolId1))
                    return "authoringToolId1: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.navisworksStatus != null && message.hasOwnProperty("navisworksStatus"))
                if (!$util.isInteger(message.navisworksStatus))
                    return "navisworksStatus: integer expected";
            if (message.guid != null && message.hasOwnProperty("guid"))
                if (!$util.isString(message.guid))
                    return "guid: string expected";
            return null;
        };

        ClashItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.ClashItem";
        };

        return ClashItem;
    })();

    Issue.Clash = (function() {

        function Clash(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Clash.prototype.name = "";
        Clash.prototype.test = "";
        Clash.prototype.group = "";
        Clash.prototype.items = $util.emptyArray;
        Clash.prototype.guid = "";
        Clash.prototype.testGuid = "";
        Clash.prototype.navisworksStatus = 0;
        Clash.prototype.version = 0;
        Clash.prototype.assigned = "";

        Clash.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(10).string(message.name);
            if (message.test != null && Object.hasOwnProperty.call(message, "test"))
                writer.uint32(18).string(message.test);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                writer.uint32(26).string(message.group);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.Issue.ClashItem.encode(message.items[i], writer.uint32(34).fork()).ldelim();
            if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                writer.uint32(42).string(message.guid);
            if (message.testGuid != null && Object.hasOwnProperty.call(message, "testGuid"))
                writer.uint32(50).string(message.testGuid);
            if (message.navisworksStatus != null && Object.hasOwnProperty.call(message, "navisworksStatus"))
                writer.uint32(56).int32(message.navisworksStatus);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(64).int32(message.version);
            if (message.assigned != null && Object.hasOwnProperty.call(message, "assigned"))
                writer.uint32(74).string(message.assigned);
            return writer;
        };

        Clash.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Clash.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Clash();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.test = reader.string();
                        break;
                    }
                case 3: {
                        message.group = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.Issue.ClashItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        message.guid = reader.string();
                        break;
                    }
                case 6: {
                        message.testGuid = reader.string();
                        break;
                    }
                case 7: {
                        message.navisworksStatus = reader.int32();
                        break;
                    }
                case 8: {
                        message.version = reader.int32();
                        break;
                    }
                case 9: {
                        message.assigned = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Clash.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Clash.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.test != null && message.hasOwnProperty("test"))
                if (!$util.isString(message.test))
                    return "test: string expected";
            if (message.group != null && message.hasOwnProperty("group"))
                if (!$util.isString(message.group))
                    return "group: string expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.Issue.ClashItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            if (message.guid != null && message.hasOwnProperty("guid"))
                if (!$util.isString(message.guid))
                    return "guid: string expected";
            if (message.testGuid != null && message.hasOwnProperty("testGuid"))
                if (!$util.isString(message.testGuid))
                    return "testGuid: string expected";
            if (message.navisworksStatus != null && message.hasOwnProperty("navisworksStatus"))
                if (!$util.isInteger(message.navisworksStatus))
                    return "navisworksStatus: integer expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            if (message.assigned != null && message.hasOwnProperty("assigned"))
                if (!$util.isString(message.assigned))
                    return "assigned: string expected";
            return null;
        };

        Clash.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Clash";
        };

        return Clash;
    })();

    Issue.ClashStorage = (function() {

        function ClashStorage(properties) {
            this.items = [];
            this.strings = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ClashStorage.prototype.iname = 0;
        ClashStorage.prototype.itest = 0;
        ClashStorage.prototype.igroup = 0;
        ClashStorage.prototype.items = $util.emptyArray;
        ClashStorage.prototype.iguid = 0;
        ClashStorage.prototype.itestGuid = 0;
        ClashStorage.prototype.navisworksStatus = 0;
        ClashStorage.prototype.version = 0;
        ClashStorage.prototype.strings = $util.emptyArray;
        ClashStorage.prototype.compressMethod = 0;
        ClashStorage.prototype.compressed = $util.newBuffer([]);
        ClashStorage.prototype.iassigned = 0;

        ClashStorage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.iname != null && Object.hasOwnProperty.call(message, "iname"))
                writer.uint32(8).int32(message.iname);
            if (message.itest != null && Object.hasOwnProperty.call(message, "itest"))
                writer.uint32(16).int32(message.itest);
            if (message.igroup != null && Object.hasOwnProperty.call(message, "igroup"))
                writer.uint32(24).int32(message.igroup);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.Issue.ClashStorage.ClashStorageItem.encode(message.items[i], writer.uint32(34).fork()).ldelim();
            if (message.iguid != null && Object.hasOwnProperty.call(message, "iguid"))
                writer.uint32(40).int32(message.iguid);
            if (message.itestGuid != null && Object.hasOwnProperty.call(message, "itestGuid"))
                writer.uint32(48).int32(message.itestGuid);
            if (message.navisworksStatus != null && Object.hasOwnProperty.call(message, "navisworksStatus"))
                writer.uint32(56).int32(message.navisworksStatus);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(64).int32(message.version);
            if (message.strings != null && message.strings.length)
                for (let i = 0; i < message.strings.length; ++i)
                    writer.uint32(74).string(message.strings[i]);
            if (message.compressMethod != null && Object.hasOwnProperty.call(message, "compressMethod"))
                writer.uint32(80).int32(message.compressMethod);
            if (message.compressed != null && Object.hasOwnProperty.call(message, "compressed"))
                writer.uint32(90).bytes(message.compressed);
            if (message.iassigned != null && Object.hasOwnProperty.call(message, "iassigned"))
                writer.uint32(96).int32(message.iassigned);
            return writer;
        };

        ClashStorage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ClashStorage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.ClashStorage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.iname = reader.int32();
                        break;
                    }
                case 2: {
                        message.itest = reader.int32();
                        break;
                    }
                case 3: {
                        message.igroup = reader.int32();
                        break;
                    }
                case 4: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.Issue.ClashStorage.ClashStorageItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        message.iguid = reader.int32();
                        break;
                    }
                case 6: {
                        message.itestGuid = reader.int32();
                        break;
                    }
                case 7: {
                        message.navisworksStatus = reader.int32();
                        break;
                    }
                case 8: {
                        message.version = reader.int32();
                        break;
                    }
                case 9: {
                        if (!(message.strings && message.strings.length))
                            message.strings = [];
                        message.strings.push(reader.string());
                        break;
                    }
                case 10: {
                        message.compressMethod = reader.int32();
                        break;
                    }
                case 11: {
                        message.compressed = reader.bytes();
                        break;
                    }
                case 12: {
                        message.iassigned = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ClashStorage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ClashStorage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.iname != null && message.hasOwnProperty("iname"))
                if (!$util.isInteger(message.iname))
                    return "iname: integer expected";
            if (message.itest != null && message.hasOwnProperty("itest"))
                if (!$util.isInteger(message.itest))
                    return "itest: integer expected";
            if (message.igroup != null && message.hasOwnProperty("igroup"))
                if (!$util.isInteger(message.igroup))
                    return "igroup: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.Issue.ClashStorage.ClashStorageItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            if (message.iguid != null && message.hasOwnProperty("iguid"))
                if (!$util.isInteger(message.iguid))
                    return "iguid: integer expected";
            if (message.itestGuid != null && message.hasOwnProperty("itestGuid"))
                if (!$util.isInteger(message.itestGuid))
                    return "itestGuid: integer expected";
            if (message.navisworksStatus != null && message.hasOwnProperty("navisworksStatus"))
                if (!$util.isInteger(message.navisworksStatus))
                    return "navisworksStatus: integer expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            if (message.strings != null && message.hasOwnProperty("strings")) {
                if (!Array.isArray(message.strings))
                    return "strings: array expected";
                for (let i = 0; i < message.strings.length; ++i)
                    if (!$util.isString(message.strings[i]))
                        return "strings: string[] expected";
            }
            if (message.compressMethod != null && message.hasOwnProperty("compressMethod"))
                switch (message.compressMethod) {
                default:
                    return "compressMethod: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.compressed != null && message.hasOwnProperty("compressed"))
                if (!(message.compressed && typeof message.compressed.length === "number" || $util.isString(message.compressed)))
                    return "compressed: buffer expected";
            if (message.iassigned != null && message.hasOwnProperty("iassigned"))
                if (!$util.isInteger(message.iassigned))
                    return "iassigned: integer expected";
            return null;
        };

        ClashStorage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.ClashStorage";
        };

        ClashStorage.ClashStorageItem = (function() {

            function ClashStorageItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ClashStorageItem.prototype.center = null;
            ClashStorageItem.prototype.distance = 0;
            ClashStorageItem.prototype.isrc0 = 0;
            ClashStorageItem.prototype.isrc1 = 0;
            ClashStorageItem.prototype.icategory0 = 0;
            ClashStorageItem.prototype.icategory1 = 0;
            ClashStorageItem.prototype.discipline0 = 0;
            ClashStorageItem.prototype.discipline1 = 0;
            ClashStorageItem.prototype.ilevel = 0;
            ClashStorageItem.prototype.igrid1 = 0;
            ClashStorageItem.prototype.igrid2 = 0;
            ClashStorageItem.prototype.iauthoringToolId0 = 0;
            ClashStorageItem.prototype.iauthoringToolId1 = 0;
            ClashStorageItem.prototype.iname = 0;
            ClashStorageItem.prototype.navisworksStatus = 0;
            ClashStorageItem.prototype.iguid = 0;

            ClashStorageItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.center != null && Object.hasOwnProperty.call(message, "center"))
                    $root.Vector3.encode(message.center, writer.uint32(10).fork()).ldelim();
                if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                    writer.uint32(21).float(message.distance);
                if (message.isrc0 != null && Object.hasOwnProperty.call(message, "isrc0"))
                    writer.uint32(24).int32(message.isrc0);
                if (message.isrc1 != null && Object.hasOwnProperty.call(message, "isrc1"))
                    writer.uint32(32).int32(message.isrc1);
                if (message.icategory0 != null && Object.hasOwnProperty.call(message, "icategory0"))
                    writer.uint32(40).int32(message.icategory0);
                if (message.icategory1 != null && Object.hasOwnProperty.call(message, "icategory1"))
                    writer.uint32(48).int32(message.icategory1);
                if (message.discipline0 != null && Object.hasOwnProperty.call(message, "discipline0"))
                    writer.uint32(56).int32(message.discipline0);
                if (message.discipline1 != null && Object.hasOwnProperty.call(message, "discipline1"))
                    writer.uint32(64).int32(message.discipline1);
                if (message.ilevel != null && Object.hasOwnProperty.call(message, "ilevel"))
                    writer.uint32(72).int32(message.ilevel);
                if (message.igrid1 != null && Object.hasOwnProperty.call(message, "igrid1"))
                    writer.uint32(80).int32(message.igrid1);
                if (message.igrid2 != null && Object.hasOwnProperty.call(message, "igrid2"))
                    writer.uint32(88).int32(message.igrid2);
                if (message.iauthoringToolId0 != null && Object.hasOwnProperty.call(message, "iauthoringToolId0"))
                    writer.uint32(96).int32(message.iauthoringToolId0);
                if (message.iauthoringToolId1 != null && Object.hasOwnProperty.call(message, "iauthoringToolId1"))
                    writer.uint32(104).int32(message.iauthoringToolId1);
                if (message.iname != null && Object.hasOwnProperty.call(message, "iname"))
                    writer.uint32(112).int32(message.iname);
                if (message.navisworksStatus != null && Object.hasOwnProperty.call(message, "navisworksStatus"))
                    writer.uint32(120).int32(message.navisworksStatus);
                if (message.iguid != null && Object.hasOwnProperty.call(message, "iguid"))
                    writer.uint32(128).int32(message.iguid);
                return writer;
            };

            ClashStorageItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            ClashStorageItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.ClashStorage.ClashStorageItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.center = $root.Vector3.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.distance = reader.float();
                            break;
                        }
                    case 3: {
                            message.isrc0 = reader.int32();
                            break;
                        }
                    case 4: {
                            message.isrc1 = reader.int32();
                            break;
                        }
                    case 5: {
                            message.icategory0 = reader.int32();
                            break;
                        }
                    case 6: {
                            message.icategory1 = reader.int32();
                            break;
                        }
                    case 7: {
                            message.discipline0 = reader.int32();
                            break;
                        }
                    case 8: {
                            message.discipline1 = reader.int32();
                            break;
                        }
                    case 9: {
                            message.ilevel = reader.int32();
                            break;
                        }
                    case 10: {
                            message.igrid1 = reader.int32();
                            break;
                        }
                    case 11: {
                            message.igrid2 = reader.int32();
                            break;
                        }
                    case 12: {
                            message.iauthoringToolId0 = reader.int32();
                            break;
                        }
                    case 13: {
                            message.iauthoringToolId1 = reader.int32();
                            break;
                        }
                    case 14: {
                            message.iname = reader.int32();
                            break;
                        }
                    case 15: {
                            message.navisworksStatus = reader.int32();
                            break;
                        }
                    case 16: {
                            message.iguid = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ClashStorageItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            ClashStorageItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.center != null && message.hasOwnProperty("center")) {
                    let error = $root.Vector3.verify(message.center);
                    if (error)
                        return "center." + error;
                }
                if (message.distance != null && message.hasOwnProperty("distance"))
                    if (typeof message.distance !== "number")
                        return "distance: number expected";
                if (message.isrc0 != null && message.hasOwnProperty("isrc0"))
                    if (!$util.isInteger(message.isrc0))
                        return "isrc0: integer expected";
                if (message.isrc1 != null && message.hasOwnProperty("isrc1"))
                    if (!$util.isInteger(message.isrc1))
                        return "isrc1: integer expected";
                if (message.icategory0 != null && message.hasOwnProperty("icategory0"))
                    if (!$util.isInteger(message.icategory0))
                        return "icategory0: integer expected";
                if (message.icategory1 != null && message.hasOwnProperty("icategory1"))
                    if (!$util.isInteger(message.icategory1))
                        return "icategory1: integer expected";
                if (message.discipline0 != null && message.hasOwnProperty("discipline0"))
                    if (!$util.isInteger(message.discipline0))
                        return "discipline0: integer expected";
                if (message.discipline1 != null && message.hasOwnProperty("discipline1"))
                    if (!$util.isInteger(message.discipline1))
                        return "discipline1: integer expected";
                if (message.ilevel != null && message.hasOwnProperty("ilevel"))
                    if (!$util.isInteger(message.ilevel))
                        return "ilevel: integer expected";
                if (message.igrid1 != null && message.hasOwnProperty("igrid1"))
                    if (!$util.isInteger(message.igrid1))
                        return "igrid1: integer expected";
                if (message.igrid2 != null && message.hasOwnProperty("igrid2"))
                    if (!$util.isInteger(message.igrid2))
                        return "igrid2: integer expected";
                if (message.iauthoringToolId0 != null && message.hasOwnProperty("iauthoringToolId0"))
                    if (!$util.isInteger(message.iauthoringToolId0))
                        return "iauthoringToolId0: integer expected";
                if (message.iauthoringToolId1 != null && message.hasOwnProperty("iauthoringToolId1"))
                    if (!$util.isInteger(message.iauthoringToolId1))
                        return "iauthoringToolId1: integer expected";
                if (message.iname != null && message.hasOwnProperty("iname"))
                    if (!$util.isInteger(message.iname))
                        return "iname: integer expected";
                if (message.navisworksStatus != null && message.hasOwnProperty("navisworksStatus"))
                    if (!$util.isInteger(message.navisworksStatus))
                        return "navisworksStatus: integer expected";
                if (message.iguid != null && message.hasOwnProperty("iguid"))
                    if (!$util.isInteger(message.iguid))
                        return "iguid: integer expected";
                return null;
            };

            ClashStorageItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.ClashStorage.ClashStorageItem";
            };

            return ClashStorageItem;
        })();

        ClashStorage.CompressMethod = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CompressMethodNone"] = 0;
            values[valuesById[1] = "Deflate"] = 1;
            return values;
        })();

        ClashStorage.Compressed = (function() {

            function Compressed(properties) {
                this.items = [];
                this.strings = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Compressed.prototype.items = $util.emptyArray;
            Compressed.prototype.strings = $util.emptyArray;

            Compressed.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.Issue.ClashStorage.ClashStorageItem.encode(message.items[i], writer.uint32(10).fork()).ldelim();
                if (message.strings != null && message.strings.length)
                    for (let i = 0; i < message.strings.length; ++i)
                        writer.uint32(18).string(message.strings[i]);
                return writer;
            };

            Compressed.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            Compressed.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.ClashStorage.Compressed();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.Issue.ClashStorage.ClashStorageItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(message.strings && message.strings.length))
                                message.strings = [];
                            message.strings.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Compressed.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            Compressed.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.Issue.ClashStorage.ClashStorageItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.strings != null && message.hasOwnProperty("strings")) {
                    if (!Array.isArray(message.strings))
                        return "strings: array expected";
                    for (let i = 0; i < message.strings.length; ++i)
                        if (!$util.isString(message.strings[i]))
                            return "strings: string[] expected";
                }
                return null;
            };

            Compressed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.ClashStorage.Compressed";
            };

            return Compressed;
        })();

        return ClashStorage;
    })();

    Issue.VisSelect = (function() {

        function VisSelect(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        VisSelect.prototype.group = null;
        VisSelect.prototype.discipline = 0;
        VisSelect.prototype.sceneVisibility = 0;

        VisSelect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.VisGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
            if (message.discipline != null && Object.hasOwnProperty.call(message, "discipline"))
                writer.uint32(16).int32(message.discipline);
            if (message.sceneVisibility != null && Object.hasOwnProperty.call(message, "sceneVisibility"))
                writer.uint32(24).int32(message.sceneVisibility);
            return writer;
        };

        VisSelect.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        VisSelect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.VisSelect();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group = $root.VisGroup.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.discipline = reader.int32();
                        break;
                    }
                case 3: {
                        message.sceneVisibility = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        VisSelect.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        VisSelect.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.VisGroup.verify(message.group);
                if (error)
                    return "group." + error;
            }
            if (message.discipline != null && message.hasOwnProperty("discipline"))
                if (!$util.isInteger(message.discipline))
                    return "discipline: integer expected";
            if (message.sceneVisibility != null && message.hasOwnProperty("sceneVisibility"))
                if (!$util.isInteger(message.sceneVisibility))
                    return "sceneVisibility: integer expected";
            return null;
        };

        VisSelect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.VisSelect";
        };

        return VisSelect;
    })();

    Issue.ColorSelect = (function() {

        function ColorSelect(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ColorSelect.prototype.group = null;

        ColorSelect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.ColorGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
            return writer;
        };

        ColorSelect.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        ColorSelect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.ColorSelect();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group = $root.ColorGroup.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ColorSelect.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        ColorSelect.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.ColorGroup.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        ColorSelect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.ColorSelect";
        };

        return ColorSelect;
    })();

    Issue.PhaseSelect = (function() {

        function PhaseSelect(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PhaseSelect.prototype.sceneState = 0;
        PhaseSelect.prototype.filter = 0;
        PhaseSelect.prototype.activeName = "";

        PhaseSelect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sceneState != null && Object.hasOwnProperty.call(message, "sceneState"))
                writer.uint32(8).int32(message.sceneState);
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                writer.uint32(24).int32(message.filter);
            if (message.activeName != null && Object.hasOwnProperty.call(message, "activeName"))
                writer.uint32(34).string(message.activeName);
            return writer;
        };

        PhaseSelect.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        PhaseSelect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.PhaseSelect();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sceneState = reader.int32();
                        break;
                    }
                case 3: {
                        message.filter = reader.int32();
                        break;
                    }
                case 4: {
                        message.activeName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PhaseSelect.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        PhaseSelect.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sceneState != null && message.hasOwnProperty("sceneState"))
                if (!$util.isInteger(message.sceneState))
                    return "sceneState: integer expected";
            if (message.filter != null && message.hasOwnProperty("filter"))
                if (!$util.isInteger(message.filter))
                    return "filter: integer expected";
            if (message.activeName != null && message.hasOwnProperty("activeName"))
                if (!$util.isString(message.activeName))
                    return "activeName: string expected";
            return null;
        };

        PhaseSelect.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.PhaseSelect";
        };

        return PhaseSelect;
    })();

    Issue.Procore = (function() {

        function Procore(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Procore.prototype.id = "";
        Procore.prototype.link = "";
        Procore.prototype.status = "";
        Procore.prototype.RFILink = "";
        Procore.prototype.type = "";
        Procore.prototype.RFINumber = "";
        Procore.prototype.RFIDraft = false;
        Procore.prototype.RFISubject = "";
        Procore.prototype.officialReply = "";

        Procore.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(10).string(message.id);
            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                writer.uint32(18).string(message.link);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(26).string(message.status);
            if (message.RFILink != null && Object.hasOwnProperty.call(message, "RFILink"))
                writer.uint32(34).string(message.RFILink);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(42).string(message.type);
            if (message.RFINumber != null && Object.hasOwnProperty.call(message, "RFINumber"))
                writer.uint32(50).string(message.RFINumber);
            if (message.RFIDraft != null && Object.hasOwnProperty.call(message, "RFIDraft"))
                writer.uint32(56).bool(message.RFIDraft);
            if (message.RFISubject != null && Object.hasOwnProperty.call(message, "RFISubject"))
                writer.uint32(66).string(message.RFISubject);
            if (message.officialReply != null && Object.hasOwnProperty.call(message, "officialReply"))
                writer.uint32(74).string(message.officialReply);
            return writer;
        };

        Procore.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Procore.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Procore();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.link = reader.string();
                        break;
                    }
                case 3: {
                        message.status = reader.string();
                        break;
                    }
                case 4: {
                        message.RFILink = reader.string();
                        break;
                    }
                case 5: {
                        message.type = reader.string();
                        break;
                    }
                case 6: {
                        message.RFINumber = reader.string();
                        break;
                    }
                case 7: {
                        message.RFIDraft = reader.bool();
                        break;
                    }
                case 8: {
                        message.RFISubject = reader.string();
                        break;
                    }
                case 9: {
                        message.officialReply = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Procore.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Procore.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.link != null && message.hasOwnProperty("link"))
                if (!$util.isString(message.link))
                    return "link: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.RFILink != null && message.hasOwnProperty("RFILink"))
                if (!$util.isString(message.RFILink))
                    return "RFILink: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isString(message.type))
                    return "type: string expected";
            if (message.RFINumber != null && message.hasOwnProperty("RFINumber"))
                if (!$util.isString(message.RFINumber))
                    return "RFINumber: string expected";
            if (message.RFIDraft != null && message.hasOwnProperty("RFIDraft"))
                if (typeof message.RFIDraft !== "boolean")
                    return "RFIDraft: boolean expected";
            if (message.RFISubject != null && message.hasOwnProperty("RFISubject"))
                if (!$util.isString(message.RFISubject))
                    return "RFISubject: string expected";
            if (message.officialReply != null && message.hasOwnProperty("officialReply"))
                if (!$util.isString(message.officialReply))
                    return "officialReply: string expected";
            return null;
        };

        Procore.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Procore";
        };

        return Procore;
    })();

    Issue.InternalProperties = (function() {

        function InternalProperties(properties) {
            this.list = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        InternalProperties.prototype.list = $util.emptyArray;

        InternalProperties.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.list != null && message.list.length)
                for (let i = 0; i < message.list.length; ++i)
                    $root.Issue.InternalProperties.KeyValue.encode(message.list[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        InternalProperties.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        InternalProperties.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.InternalProperties();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.Issue.InternalProperties.KeyValue.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        InternalProperties.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        InternalProperties.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.list != null && message.hasOwnProperty("list")) {
                if (!Array.isArray(message.list))
                    return "list: array expected";
                for (let i = 0; i < message.list.length; ++i) {
                    let error = $root.Issue.InternalProperties.KeyValue.verify(message.list[i]);
                    if (error)
                        return "list." + error;
                }
            }
            return null;
        };

        InternalProperties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.InternalProperties";
        };

        InternalProperties.KeyValue = (function() {

            function KeyValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            KeyValue.prototype.key = "";
            KeyValue.prototype.value = $util.newBuffer([]);

            KeyValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(10).string(message.key);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(18).bytes(message.value);
                return writer;
            };

            KeyValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            KeyValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.InternalProperties.KeyValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.key = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            KeyValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            KeyValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.key != null && message.hasOwnProperty("key"))
                    if (!$util.isString(message.key))
                        return "key: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            KeyValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/Issue.InternalProperties.KeyValue";
            };

            return KeyValue;
        })();

        return InternalProperties;
    })();

    Issue.DefaultSheet = (function() {

        function DefaultSheet(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        DefaultSheet.prototype.guid = "";

        DefaultSheet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
                writer.uint32(10).string(message.guid);
            return writer;
        };

        DefaultSheet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        DefaultSheet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.DefaultSheet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.guid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DefaultSheet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        DefaultSheet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.guid != null && message.hasOwnProperty("guid"))
                if (!$util.isString(message.guid))
                    return "guid: string expected";
            return null;
        };

        DefaultSheet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.DefaultSheet";
        };

        return DefaultSheet;
    })();

    Issue.Pin = (function() {

        function Pin(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Pin.prototype.visibility = false;
        Pin.prototype.scale = 0;
        Pin.prototype.index = 0;

        Pin.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
                writer.uint32(8).bool(message.visibility);
            if (message.scale != null && Object.hasOwnProperty.call(message, "scale"))
                writer.uint32(21).float(message.scale);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(24).int32(message.index);
            return writer;
        };

        Pin.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Pin.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Issue.Pin();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.visibility = reader.bool();
                        break;
                    }
                case 2: {
                        message.scale = reader.float();
                        break;
                    }
                case 3: {
                        message.index = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Pin.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Pin.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.visibility != null && message.hasOwnProperty("visibility"))
                if (typeof message.visibility !== "boolean")
                    return "visibility: boolean expected";
            if (message.scale != null && message.hasOwnProperty("scale"))
                if (typeof message.scale !== "number")
                    return "scale: number expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            return null;
        };

        Pin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/Issue.Pin";
        };

        return Pin;
    })();

    return Issue;
})();

export const Vector3 = $root.Vector3 = (() => {

    function Vector3(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Vector3.prototype.x = 0;
    Vector3.prototype.y = 0;
    Vector3.prototype.z = 0;

    Vector3.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.x != null && Object.hasOwnProperty.call(message, "x"))
            writer.uint32(13).float(message.x);
        if (message.y != null && Object.hasOwnProperty.call(message, "y"))
            writer.uint32(21).float(message.y);
        if (message.z != null && Object.hasOwnProperty.call(message, "z"))
            writer.uint32(29).float(message.z);
        return writer;
    };

    Vector3.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Vector3.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Vector3();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.x = reader.float();
                    break;
                }
            case 2: {
                    message.y = reader.float();
                    break;
                }
            case 3: {
                    message.z = reader.float();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Vector3.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Vector3.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.x != null && message.hasOwnProperty("x"))
            if (typeof message.x !== "number")
                return "x: number expected";
        if (message.y != null && message.hasOwnProperty("y"))
            if (typeof message.y !== "number")
                return "y: number expected";
        if (message.z != null && message.hasOwnProperty("z"))
            if (typeof message.z !== "number")
                return "z: number expected";
        return null;
    };

    Vector3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Vector3";
    };

    return Vector3;
})();

export const Vector3d = $root.Vector3d = (() => {

    function Vector3d(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Vector3d.prototype.x = 0;
    Vector3d.prototype.y = 0;
    Vector3d.prototype.z = 0;

    Vector3d.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.x != null && Object.hasOwnProperty.call(message, "x"))
            writer.uint32(9).double(message.x);
        if (message.y != null && Object.hasOwnProperty.call(message, "y"))
            writer.uint32(17).double(message.y);
        if (message.z != null && Object.hasOwnProperty.call(message, "z"))
            writer.uint32(25).double(message.z);
        return writer;
    };

    Vector3d.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Vector3d.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Vector3d();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.x = reader.double();
                    break;
                }
            case 2: {
                    message.y = reader.double();
                    break;
                }
            case 3: {
                    message.z = reader.double();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Vector3d.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Vector3d.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.x != null && message.hasOwnProperty("x"))
            if (typeof message.x !== "number")
                return "x: number expected";
        if (message.y != null && message.hasOwnProperty("y"))
            if (typeof message.y !== "number")
                return "y: number expected";
        if (message.z != null && message.hasOwnProperty("z"))
            if (typeof message.z !== "number")
                return "z: number expected";
        return null;
    };

    Vector3d.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Vector3d";
    };

    return Vector3d;
})();

export const VisGroup = $root.VisGroup = (() => {

    function VisGroup(properties) {
        this.childs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    VisGroup.prototype.name = "";
    VisGroup.prototype.flags = 0;
    VisGroup.prototype.childs = $util.emptyArray;
    VisGroup.prototype.type = 0;
    VisGroup.prototype.hash = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    VisGroup.prototype.unique = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    VisGroup.prototype.proto3Flags = 0;
    VisGroup.prototype.proto3Type = 0;

    VisGroup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(10).string(message.name);
        if (message.flags != null && Object.hasOwnProperty.call(message, "flags"))
            writer.uint32(16).int32(message.flags);
        if (message.childs != null && message.childs.length)
            for (let i = 0; i < message.childs.length; ++i)
                $root.VisGroup.encode(message.childs[i], writer.uint32(26).fork()).ldelim();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(32).int32(message.type);
        if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
            writer.uint32(41).fixed64(message.hash);
        if (message.unique != null && Object.hasOwnProperty.call(message, "unique"))
            writer.uint32(48).uint64(message.unique);
        if (message.proto3Flags != null && Object.hasOwnProperty.call(message, "proto3Flags"))
            writer.uint32(56).int32(message.proto3Flags);
        if (message.proto3Type != null && Object.hasOwnProperty.call(message, "proto3Type"))
            writer.uint32(64).int32(message.proto3Type);
        return writer;
    };

    VisGroup.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    VisGroup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VisGroup();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 2: {
                    message.flags = reader.int32();
                    break;
                }
            case 3: {
                    if (!(message.childs && message.childs.length))
                        message.childs = [];
                    message.childs.push($root.VisGroup.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.type = reader.int32();
                    break;
                }
            case 5: {
                    message.hash = reader.fixed64();
                    break;
                }
            case 6: {
                    message.unique = reader.uint64();
                    break;
                }
            case 7: {
                    message.proto3Flags = reader.int32();
                    break;
                }
            case 8: {
                    message.proto3Type = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    VisGroup.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    VisGroup.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.flags != null && message.hasOwnProperty("flags"))
            switch (message.flags) {
            default:
                return "flags: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 8:
            case 16:
            case -1:
                break;
            }
        if (message.childs != null && message.hasOwnProperty("childs")) {
            if (!Array.isArray(message.childs))
                return "childs: array expected";
            for (let i = 0; i < message.childs.length; ++i) {
                let error = $root.VisGroup.verify(message.childs[i]);
                if (error)
                    return "childs." + error;
            }
        }
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.hash != null && message.hasOwnProperty("hash"))
            if (!$util.isInteger(message.hash) && !(message.hash && $util.isInteger(message.hash.low) && $util.isInteger(message.hash.high)))
                return "hash: integer|Long expected";
        if (message.unique != null && message.hasOwnProperty("unique"))
            if (!$util.isInteger(message.unique) && !(message.unique && $util.isInteger(message.unique.low) && $util.isInteger(message.unique.high)))
                return "unique: integer|Long expected";
        if (message.proto3Flags != null && message.hasOwnProperty("proto3Flags"))
            switch (message.proto3Flags) {
            default:
                return "proto3Flags: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 8:
            case 16:
            case -1:
                break;
            }
        if (message.proto3Type != null && message.hasOwnProperty("proto3Type"))
            switch (message.proto3Type) {
            default:
                return "proto3Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        return null;
    };

    VisGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/VisGroup";
    };

    VisGroup.GroupType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "vNone"] = 0;
        values[valuesById[1] = "vRoot"] = 1;
        values[valuesById[2] = "vScene"] = 2;
        values[valuesById[3] = "vCategory"] = 3;
        values[valuesById[4] = "vFamily"] = 4;
        values[valuesById[5] = "vObject"] = 5;
        values[valuesById[6] = "vLevel"] = 6;
        return values;
    })();

    VisGroup.Flags = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Invisible"] = 0;
        values[valuesById[1] = "Visible"] = 1;
        values[valuesById[2] = "Transparent"] = 2;
        values[valuesById[3] = "Mixed"] = 3;
        values[valuesById[4] = "Opaque"] = 4;
        values[valuesById[8] = "ImageSheet"] = 8;
        values[valuesById[16] = "SheetSet"] = 16;
        values[valuesById[-1] = "Undefined"] = -1;
        return values;
    })();

    return VisGroup;
})();

export const ColorGroup = $root.ColorGroup = (() => {

    function ColorGroup(properties) {
        this.childs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ColorGroup.prototype.name = "";
    ColorGroup.prototype.colorArgb = 0;
    ColorGroup.prototype.childs = $util.emptyArray;
    ColorGroup.prototype.type = 0;
    ColorGroup.prototype.hash = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    ColorGroup.prototype.unique = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    ColorGroup.prototype.proto3Type = 0;

    ColorGroup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(10).string(message.name);
        if (message.colorArgb != null && Object.hasOwnProperty.call(message, "colorArgb"))
            writer.uint32(16).uint32(message.colorArgb);
        if (message.childs != null && message.childs.length)
            for (let i = 0; i < message.childs.length; ++i)
                $root.ColorGroup.encode(message.childs[i], writer.uint32(26).fork()).ldelim();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(32).int32(message.type);
        if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
            writer.uint32(41).fixed64(message.hash);
        if (message.unique != null && Object.hasOwnProperty.call(message, "unique"))
            writer.uint32(48).uint64(message.unique);
        if (message.proto3Type != null && Object.hasOwnProperty.call(message, "proto3Type"))
            writer.uint32(56).int32(message.proto3Type);
        return writer;
    };

    ColorGroup.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ColorGroup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ColorGroup();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 2: {
                    message.colorArgb = reader.uint32();
                    break;
                }
            case 3: {
                    if (!(message.childs && message.childs.length))
                        message.childs = [];
                    message.childs.push($root.ColorGroup.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.type = reader.int32();
                    break;
                }
            case 5: {
                    message.hash = reader.fixed64();
                    break;
                }
            case 6: {
                    message.unique = reader.uint64();
                    break;
                }
            case 7: {
                    message.proto3Type = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ColorGroup.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ColorGroup.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.colorArgb != null && message.hasOwnProperty("colorArgb"))
            if (!$util.isInteger(message.colorArgb))
                return "colorArgb: integer expected";
        if (message.childs != null && message.hasOwnProperty("childs")) {
            if (!Array.isArray(message.childs))
                return "childs: array expected";
            for (let i = 0; i < message.childs.length; ++i) {
                let error = $root.ColorGroup.verify(message.childs[i]);
                if (error)
                    return "childs." + error;
            }
        }
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.hash != null && message.hasOwnProperty("hash"))
            if (!$util.isInteger(message.hash) && !(message.hash && $util.isInteger(message.hash.low) && $util.isInteger(message.hash.high)))
                return "hash: integer|Long expected";
        if (message.unique != null && message.hasOwnProperty("unique"))
            if (!$util.isInteger(message.unique) && !(message.unique && $util.isInteger(message.unique.low) && $util.isInteger(message.unique.high)))
                return "unique: integer|Long expected";
        if (message.proto3Type != null && message.hasOwnProperty("proto3Type"))
            switch (message.proto3Type) {
            default:
                return "proto3Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        return null;
    };

    ColorGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ColorGroup";
    };

    ColorGroup.GroupType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "cNone"] = 0;
        values[valuesById[1] = "cRoot"] = 1;
        values[valuesById[2] = "cScene"] = 2;
        values[valuesById[3] = "cCategory"] = 3;
        values[valuesById[4] = "cFamily"] = 4;
        values[valuesById[5] = "cObject"] = 5;
        values[valuesById[6] = "cLevel"] = 6;
        return values;
    })();

    return ColorGroup;
})();

export const SCenter = $root.SCenter = (() => {

    function SCenter(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SCenter.prototype.Abs = null;
    SCenter.prototype.Bind = null;

    SCenter.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Abs != null && Object.hasOwnProperty.call(message, "Abs"))
            $root.Vector3d.encode(message.Abs, writer.uint32(10).fork()).ldelim();
        if (message.Bind != null && Object.hasOwnProperty.call(message, "Bind"))
            $root.Vector3d.encode(message.Bind, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    SCenter.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SCenter.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SCenter();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Abs = $root.Vector3d.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.Bind = $root.Vector3d.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SCenter.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SCenter.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.Abs != null && message.hasOwnProperty("Abs")) {
            let error = $root.Vector3d.verify(message.Abs);
            if (error)
                return "Abs." + error;
        }
        if (message.Bind != null && message.hasOwnProperty("Bind")) {
            let error = $root.Vector3d.verify(message.Bind);
            if (error)
                return "Bind." + error;
        }
        return null;
    };

    SCenter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SCenter";
    };

    return SCenter;
})();

export const IssueOperation = $root.IssueOperation = (() => {

    function IssueOperation(properties) {
        this.inComments = [];
        this.outComments = [];
        this.tags = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssueOperation.prototype.code = 0;
    IssueOperation.prototype.inMarker = null;
    IssueOperation.prototype.inComments = $util.emptyArray;
    IssueOperation.prototype.outMarker = null;
    IssueOperation.prototype.outComments = $util.emptyArray;
    IssueOperation.prototype.commentsReaded = 0;
    IssueOperation.prototype.tags = $util.emptyArray;
    IssueOperation.prototype["import"] = false;
    IssueOperation.prototype.newTag = "";
    IssueOperation.prototype.created = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    IssueOperation.prototype.uploadingFields = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    IssueOperation.prototype.result = null;
    IssueOperation.prototype.results = null;
    IssueOperation.prototype.localFilePath = "";
    IssueOperation.prototype.localFileHash = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    IssueOperation.prototype.fullFilePath = "";
    IssueOperation.prototype.clashSync = false;
    IssueOperation.prototype.rclashSync = false;
    IssueOperation.prototype.initiatorEmail = "";
    IssueOperation.prototype.projectUuid = "";

    IssueOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.inMarker != null && Object.hasOwnProperty.call(message, "inMarker"))
            $root.Issue.encode(message.inMarker, writer.uint32(18).fork()).ldelim();
        if (message.inComments != null && message.inComments.length)
            for (let i = 0; i < message.inComments.length; ++i)
                $root.Issue.Comment.encode(message.inComments[i], writer.uint32(26).fork()).ldelim();
        if (message.outMarker != null && Object.hasOwnProperty.call(message, "outMarker"))
            $root.Issue.encode(message.outMarker, writer.uint32(34).fork()).ldelim();
        if (message.outComments != null && message.outComments.length)
            for (let i = 0; i < message.outComments.length; ++i)
                $root.Issue.Comment.encode(message.outComments[i], writer.uint32(42).fork()).ldelim();
        if (message.commentsReaded != null && Object.hasOwnProperty.call(message, "commentsReaded"))
            writer.uint32(48).int32(message.commentsReaded);
        if (message.tags != null && message.tags.length)
            for (let i = 0; i < message.tags.length; ++i)
                writer.uint32(58).string(message.tags[i]);
        if (message["import"] != null && Object.hasOwnProperty.call(message, "import"))
            writer.uint32(64).bool(message["import"]);
        if (message.newTag != null && Object.hasOwnProperty.call(message, "newTag"))
            writer.uint32(74).string(message.newTag);
        if (message.created != null && Object.hasOwnProperty.call(message, "created"))
            writer.uint32(80).int64(message.created);
        if (message.uploadingFields != null && Object.hasOwnProperty.call(message, "uploadingFields"))
            writer.uint32(88).uint64(message.uploadingFields);
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            $root.IssueOperation.Result.encode(message.result, writer.uint32(98).fork()).ldelim();
        if (message.results != null && Object.hasOwnProperty.call(message, "results"))
            $root.IssueOperation.Result.encode(message.results, writer.uint32(106).fork()).ldelim();
        if (message.localFilePath != null && Object.hasOwnProperty.call(message, "localFilePath"))
            writer.uint32(114).string(message.localFilePath);
        if (message.localFileHash != null && Object.hasOwnProperty.call(message, "localFileHash"))
            writer.uint32(121).fixed64(message.localFileHash);
        if (message.fullFilePath != null && Object.hasOwnProperty.call(message, "fullFilePath"))
            writer.uint32(130).string(message.fullFilePath);
        if (message.clashSync != null && Object.hasOwnProperty.call(message, "clashSync"))
            writer.uint32(136).bool(message.clashSync);
        if (message.rclashSync != null && Object.hasOwnProperty.call(message, "rclashSync"))
            writer.uint32(144).bool(message.rclashSync);
        if (message.initiatorEmail != null && Object.hasOwnProperty.call(message, "initiatorEmail"))
            writer.uint32(154).string(message.initiatorEmail);
        if (message.projectUuid != null && Object.hasOwnProperty.call(message, "projectUuid"))
            writer.uint32(162).string(message.projectUuid);
        return writer;
    };

    IssueOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssueOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssueOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.inMarker = $root.Issue.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    if (!(message.inComments && message.inComments.length))
                        message.inComments = [];
                    message.inComments.push($root.Issue.Comment.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.outMarker = $root.Issue.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    if (!(message.outComments && message.outComments.length))
                        message.outComments = [];
                    message.outComments.push($root.Issue.Comment.decode(reader, reader.uint32()));
                    break;
                }
            case 6: {
                    message.commentsReaded = reader.int32();
                    break;
                }
            case 7: {
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push(reader.string());
                    break;
                }
            case 8: {
                    message["import"] = reader.bool();
                    break;
                }
            case 9: {
                    message.newTag = reader.string();
                    break;
                }
            case 10: {
                    message.created = reader.int64();
                    break;
                }
            case 11: {
                    message.uploadingFields = reader.uint64();
                    break;
                }
            case 12: {
                    message.result = $root.IssueOperation.Result.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.results = $root.IssueOperation.Result.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.localFilePath = reader.string();
                    break;
                }
            case 15: {
                    message.localFileHash = reader.fixed64();
                    break;
                }
            case 16: {
                    message.fullFilePath = reader.string();
                    break;
                }
            case 17: {
                    message.clashSync = reader.bool();
                    break;
                }
            case 18: {
                    message.rclashSync = reader.bool();
                    break;
                }
            case 19: {
                    message.initiatorEmail = reader.string();
                    break;
                }
            case 20: {
                    message.projectUuid = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssueOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssueOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            switch (message.code) {
            default:
                return "code: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                break;
            }
        if (message.inMarker != null && message.hasOwnProperty("inMarker")) {
            let error = $root.Issue.verify(message.inMarker);
            if (error)
                return "inMarker." + error;
        }
        if (message.inComments != null && message.hasOwnProperty("inComments")) {
            if (!Array.isArray(message.inComments))
                return "inComments: array expected";
            for (let i = 0; i < message.inComments.length; ++i) {
                let error = $root.Issue.Comment.verify(message.inComments[i]);
                if (error)
                    return "inComments." + error;
            }
        }
        if (message.outMarker != null && message.hasOwnProperty("outMarker")) {
            let error = $root.Issue.verify(message.outMarker);
            if (error)
                return "outMarker." + error;
        }
        if (message.outComments != null && message.hasOwnProperty("outComments")) {
            if (!Array.isArray(message.outComments))
                return "outComments: array expected";
            for (let i = 0; i < message.outComments.length; ++i) {
                let error = $root.Issue.Comment.verify(message.outComments[i]);
                if (error)
                    return "outComments." + error;
            }
        }
        if (message.commentsReaded != null && message.hasOwnProperty("commentsReaded"))
            if (!$util.isInteger(message.commentsReaded))
                return "commentsReaded: integer expected";
        if (message.tags != null && message.hasOwnProperty("tags")) {
            if (!Array.isArray(message.tags))
                return "tags: array expected";
            for (let i = 0; i < message.tags.length; ++i)
                if (!$util.isString(message.tags[i]))
                    return "tags: string[] expected";
        }
        if (message["import"] != null && message.hasOwnProperty("import"))
            if (typeof message["import"] !== "boolean")
                return "import: boolean expected";
        if (message.newTag != null && message.hasOwnProperty("newTag"))
            if (!$util.isString(message.newTag))
                return "newTag: string expected";
        if (message.created != null && message.hasOwnProperty("created"))
            if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                return "created: integer|Long expected";
        if (message.uploadingFields != null && message.hasOwnProperty("uploadingFields"))
            if (!$util.isInteger(message.uploadingFields) && !(message.uploadingFields && $util.isInteger(message.uploadingFields.low) && $util.isInteger(message.uploadingFields.high)))
                return "uploadingFields: integer|Long expected";
        if (message.result != null && message.hasOwnProperty("result")) {
            let error = $root.IssueOperation.Result.verify(message.result);
            if (error)
                return "result." + error;
        }
        if (message.results != null && message.hasOwnProperty("results")) {
            let error = $root.IssueOperation.Result.verify(message.results);
            if (error)
                return "results." + error;
        }
        if (message.localFilePath != null && message.hasOwnProperty("localFilePath"))
            if (!$util.isString(message.localFilePath))
                return "localFilePath: string expected";
        if (message.localFileHash != null && message.hasOwnProperty("localFileHash"))
            if (!$util.isInteger(message.localFileHash) && !(message.localFileHash && $util.isInteger(message.localFileHash.low) && $util.isInteger(message.localFileHash.high)))
                return "localFileHash: integer|Long expected";
        if (message.fullFilePath != null && message.hasOwnProperty("fullFilePath"))
            if (!$util.isString(message.fullFilePath))
                return "fullFilePath: string expected";
        if (message.clashSync != null && message.hasOwnProperty("clashSync"))
            if (typeof message.clashSync !== "boolean")
                return "clashSync: boolean expected";
        if (message.rclashSync != null && message.hasOwnProperty("rclashSync"))
            if (typeof message.rclashSync !== "boolean")
                return "rclashSync: boolean expected";
        if (message.initiatorEmail != null && message.hasOwnProperty("initiatorEmail"))
            if (!$util.isString(message.initiatorEmail))
                return "initiatorEmail: string expected";
        if (message.projectUuid != null && message.hasOwnProperty("projectUuid"))
            if (!$util.isString(message.projectUuid))
                return "projectUuid: string expected";
        return null;
    };

    IssueOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssueOperation";
    };

    IssueOperation.Code = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Add"] = 0;
        values[valuesById[1] = "Get"] = 1;
        values[valuesById[2] = "Change"] = 2;
        values[valuesById[3] = "Delete"] = 3;
        values[valuesById[4] = "AddComments"] = 4;
        values[valuesById[5] = "Readed"] = 5;
        values[valuesById[6] = "ReadedComments"] = 6;
        values[valuesById[7] = "GetChanges"] = 7;
        values[valuesById[8] = "GetComments"] = 8;
        values[valuesById[9] = "AddTags"] = 9;
        values[valuesById[10] = "EditTags"] = 10;
        values[valuesById[11] = "DelTags"] = 11;
        values[valuesById[12] = "FileUpload"] = 12;
        values[valuesById[13] = "AddStatuses"] = 13;
        values[valuesById[14] = "AddWorkflow"] = 14;
        values[valuesById[15] = "AddTypes"] = 15;
        values[valuesById[16] = "DeleteDefaultType"] = 16;
        return values;
    })();

    IssueOperation.Result = (function() {

        function Result(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Result.prototype.id = 0;
        Result.prototype.info = "";
        Result.prototype.userInfo = "";

        Result.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).int32(message.id);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(18).string(message.info);
            if (message.userInfo != null && Object.hasOwnProperty.call(message, "userInfo"))
                writer.uint32(26).string(message.userInfo);
            return writer;
        };

        Result.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        Result.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssueOperation.Result();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.info = reader.string();
                        break;
                    }
                case 3: {
                        message.userInfo = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Result.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        Result.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            if (message.userInfo != null && message.hasOwnProperty("userInfo"))
                if (!$util.isString(message.userInfo))
                    return "userInfo: string expected";
            return null;
        };

        Result.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/IssueOperation.Result";
        };

        return Result;
    })();

    return IssueOperation;
})();

export const IssuePresetsOperation = $root.IssuePresetsOperation = (() => {

    function IssuePresetsOperation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssuePresetsOperation.prototype.code = 0;
    IssuePresetsOperation.prototype.presets = "";

    IssuePresetsOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.presets != null && Object.hasOwnProperty.call(message, "presets"))
            writer.uint32(18).string(message.presets);
        return writer;
    };

    IssuePresetsOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssuePresetsOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssuePresetsOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.presets = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssuePresetsOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssuePresetsOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isInteger(message.code))
                return "code: integer expected";
        if (message.presets != null && message.hasOwnProperty("presets"))
            if (!$util.isString(message.presets))
                return "presets: string expected";
        return null;
    };

    IssuePresetsOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssuePresetsOperation";
    };

    IssuePresetsOperation.Code = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Add"] = 0;
        values[valuesById[1] = "Edit"] = 1;
        values[valuesById[2] = "Remove"] = 2;
        return values;
    })();

    return IssuePresetsOperation;
})();

export const LicenseOperation = $root.LicenseOperation = (() => {

    function LicenseOperation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    LicenseOperation.prototype.code = 0;
    LicenseOperation.prototype.uuid = "";
    LicenseOperation.prototype.licenseId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    LicenseOperation.prototype.relatedEntity = null;
    LicenseOperation.prototype.highlights = "";

    LicenseOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
            writer.uint32(18).string(message.uuid);
        if (message.licenseId != null && Object.hasOwnProperty.call(message, "licenseId"))
            writer.uint32(24).int64(message.licenseId);
        if (message.relatedEntity != null && Object.hasOwnProperty.call(message, "relatedEntity"))
            $root.LicenseOperation.RelatedEntity.encode(message.relatedEntity, writer.uint32(34).fork()).ldelim();
        if (message.highlights != null && Object.hasOwnProperty.call(message, "highlights"))
            writer.uint32(42).string(message.highlights);
        return writer;
    };

    LicenseOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    LicenseOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LicenseOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.uuid = reader.string();
                    break;
                }
            case 3: {
                    message.licenseId = reader.int64();
                    break;
                }
            case 4: {
                    message.relatedEntity = $root.LicenseOperation.RelatedEntity.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.highlights = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    LicenseOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    LicenseOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            switch (message.code) {
            default:
                return "code: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
                break;
            }
        if (message.uuid != null && message.hasOwnProperty("uuid"))
            if (!$util.isString(message.uuid))
                return "uuid: string expected";
        if (message.licenseId != null && message.hasOwnProperty("licenseId"))
            if (!$util.isInteger(message.licenseId) && !(message.licenseId && $util.isInteger(message.licenseId.low) && $util.isInteger(message.licenseId.high)))
                return "licenseId: integer|Long expected";
        if (message.relatedEntity != null && message.hasOwnProperty("relatedEntity")) {
            let error = $root.LicenseOperation.RelatedEntity.verify(message.relatedEntity);
            if (error)
                return "relatedEntity." + error;
        }
        if (message.highlights != null && message.hasOwnProperty("highlights"))
            if (!$util.isString(message.highlights))
                return "highlights: string expected";
        return null;
    };

    LicenseOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LicenseOperation";
    };

    LicenseOperation.Code = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UnknownLicenseOperation"] = 0;
        values[valuesById[1] = "NewRole"] = 1;
        values[valuesById[2] = "EditRole"] = 2;
        values[valuesById[3] = "DeleteRole"] = 3;
        values[valuesById[4] = "ProjectAdded"] = 4;
        values[valuesById[5] = "ProjectDeleted"] = 5;
        values[valuesById[6] = "UpdateDashboard"] = 6;
        values[valuesById[7] = "UpdateGraph"] = 7;
        values[valuesById[8] = "LicenseUpdated"] = 8;
        values[valuesById[9] = "TeamUpdated"] = 9;
        values[valuesById[10] = "HighlightsUpdated"] = 10;
        return values;
    })();

    LicenseOperation.RelatedEntity = (function() {

        function RelatedEntity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RelatedEntity.prototype.action = 0;
        RelatedEntity.prototype.title = "";
        RelatedEntity.prototype.uuid = "";
        RelatedEntity.prototype.graphUuid = "";

        RelatedEntity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(8).int32(message.action);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(18).string(message.title);
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(26).string(message.uuid);
            if (message.graphUuid != null && Object.hasOwnProperty.call(message, "graphUuid"))
                writer.uint32(34).string(message.graphUuid);
            return writer;
        };

        RelatedEntity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RelatedEntity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LicenseOperation.RelatedEntity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.action = reader.int32();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                case 3: {
                        message.uuid = reader.string();
                        break;
                    }
                case 4: {
                        message.graphUuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RelatedEntity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RelatedEntity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.action != null && message.hasOwnProperty("action"))
                switch (message.action) {
                default:
                    return "action: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.graphUuid != null && message.hasOwnProperty("graphUuid"))
                if (!$util.isString(message.graphUuid))
                    return "graphUuid: string expected";
            return null;
        };

        RelatedEntity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/LicenseOperation.RelatedEntity";
        };

        return RelatedEntity;
    })();

    LicenseOperation.RelatedEntityAction = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Create"] = 0;
        values[valuesById[1] = "Update"] = 1;
        values[valuesById[2] = "Delete"] = 2;
        return values;
    })();

    return LicenseOperation;
})();

export const MailOperation = $root.MailOperation = (() => {

    function MailOperation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    MailOperation.prototype.code = 0;
    MailOperation.prototype.from = "";
    MailOperation.prototype.to = "";
    MailOperation.prototype.body = $util.newBuffer([]);
    MailOperation.prototype.licenseId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    MailOperation.prototype.projectId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    MailOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.from != null && Object.hasOwnProperty.call(message, "from"))
            writer.uint32(18).string(message.from);
        if (message.to != null && Object.hasOwnProperty.call(message, "to"))
            writer.uint32(26).string(message.to);
        if (message.body != null && Object.hasOwnProperty.call(message, "body"))
            writer.uint32(34).bytes(message.body);
        if (message.licenseId != null && Object.hasOwnProperty.call(message, "licenseId"))
            writer.uint32(40).int64(message.licenseId);
        if (message.projectId != null && Object.hasOwnProperty.call(message, "projectId"))
            writer.uint32(48).int64(message.projectId);
        return writer;
    };

    MailOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    MailOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MailOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.from = reader.string();
                    break;
                }
            case 3: {
                    message.to = reader.string();
                    break;
                }
            case 4: {
                    message.body = reader.bytes();
                    break;
                }
            case 5: {
                    message.licenseId = reader.int64();
                    break;
                }
            case 6: {
                    message.projectId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    MailOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    MailOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isInteger(message.code))
                return "code: integer expected";
        if (message.from != null && message.hasOwnProperty("from"))
            if (!$util.isString(message.from))
                return "from: string expected";
        if (message.to != null && message.hasOwnProperty("to"))
            if (!$util.isString(message.to))
                return "to: string expected";
        if (message.body != null && message.hasOwnProperty("body"))
            if (!(message.body && typeof message.body.length === "number" || $util.isString(message.body)))
                return "body: buffer expected";
        if (message.licenseId != null && message.hasOwnProperty("licenseId"))
            if (!$util.isInteger(message.licenseId) && !(message.licenseId && $util.isInteger(message.licenseId.low) && $util.isInteger(message.licenseId.high)))
                return "licenseId: integer|Long expected";
        if (message.projectId != null && message.hasOwnProperty("projectId"))
            if (!$util.isInteger(message.projectId) && !(message.projectId && $util.isInteger(message.projectId.low) && $util.isInteger(message.projectId.high)))
                return "projectId: integer|Long expected";
        return null;
    };

    MailOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MailOperation";
    };

    MailOperation.Code = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Undefined"] = 0;
        values[valuesById[1] = "ProjectInvite"] = 1;
        return values;
    })();

    return MailOperation;
})();

export const NotificationOperation = $root.NotificationOperation = (() => {

    function NotificationOperation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    NotificationOperation.prototype.code = 0;
    NotificationOperation.prototype.notificationContent = "";

    NotificationOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.notificationContent != null && Object.hasOwnProperty.call(message, "notificationContent"))
            writer.uint32(18).string(message.notificationContent);
        return writer;
    };

    NotificationOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    NotificationOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NotificationOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.notificationContent = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    NotificationOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    NotificationOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            switch (message.code) {
            default:
                return "code: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.notificationContent != null && message.hasOwnProperty("notificationContent"))
            if (!$util.isString(message.notificationContent))
                return "notificationContent: string expected";
        return null;
    };

    NotificationOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/NotificationOperation";
    };

    NotificationOperation.Code = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NewNotification"] = 0;
        values[valuesById[1] = "MarkRead"] = 1;
        values[valuesById[2] = "MarkUnread"] = 2;
        return values;
    })();

    return NotificationOperation;
})();

export const ObjInt = $root.ObjInt = (() => {

    function ObjInt(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ObjInt.prototype.val = 0;

    ObjInt.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.val != null && Object.hasOwnProperty.call(message, "val"))
            writer.uint32(8).int32(message.val);
        return writer;
    };

    ObjInt.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ObjInt.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObjInt();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.val = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ObjInt.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ObjInt.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.val != null && message.hasOwnProperty("val"))
            if (!$util.isInteger(message.val))
                return "val: integer expected";
        return null;
    };

    ObjInt.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObjInt";
    };

    return ObjInt;
})();

export const ObjBool = $root.ObjBool = (() => {

    function ObjBool(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ObjBool.prototype.val = false;

    ObjBool.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.val != null && Object.hasOwnProperty.call(message, "val"))
            writer.uint32(8).bool(message.val);
        return writer;
    };

    ObjBool.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ObjBool.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObjBool();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.val = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ObjBool.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ObjBool.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.val != null && message.hasOwnProperty("val"))
            if (typeof message.val !== "boolean")
                return "val: boolean expected";
        return null;
    };

    ObjBool.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObjBool";
    };

    return ObjBool;
})();

export const ObjFloat = $root.ObjFloat = (() => {

    function ObjFloat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ObjFloat.prototype.val = 0;

    ObjFloat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.val != null && Object.hasOwnProperty.call(message, "val"))
            writer.uint32(13).float(message.val);
        return writer;
    };

    ObjFloat.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ObjFloat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObjFloat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.val = reader.float();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ObjFloat.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ObjFloat.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.val != null && message.hasOwnProperty("val"))
            if (typeof message.val !== "number")
                return "val: number expected";
        return null;
    };

    ObjFloat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObjFloat";
    };

    return ObjFloat;
})();

export const ObjFloats = $root.ObjFloats = (() => {

    function ObjFloats(properties) {
        this.val = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ObjFloats.prototype.val = $util.emptyArray;

    ObjFloats.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.val != null && message.val.length) {
            writer.uint32(10).fork();
            for (let i = 0; i < message.val.length; ++i)
                writer.float(message.val[i]);
            writer.ldelim();
        }
        return writer;
    };

    ObjFloats.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ObjFloats.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObjFloats();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.val && message.val.length))
                        message.val = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.val.push(reader.float());
                    } else
                        message.val.push(reader.float());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ObjFloats.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ObjFloats.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.val != null && message.hasOwnProperty("val")) {
            if (!Array.isArray(message.val))
                return "val: array expected";
            for (let i = 0; i < message.val.length; ++i)
                if (typeof message.val[i] !== "number")
                    return "val: number[] expected";
        }
        return null;
    };

    ObjFloats.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObjFloats";
    };

    return ObjFloats;
})();

export const ObjInts = $root.ObjInts = (() => {

    function ObjInts(properties) {
        this.val = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ObjInts.prototype.val = $util.emptyArray;

    ObjInts.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.val != null && message.val.length) {
            writer.uint32(10).fork();
            for (let i = 0; i < message.val.length; ++i)
                writer.int32(message.val[i]);
            writer.ldelim();
        }
        return writer;
    };

    ObjInts.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ObjInts.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObjInts();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.val && message.val.length))
                        message.val = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.val.push(reader.int32());
                    } else
                        message.val.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ObjInts.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ObjInts.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.val != null && message.hasOwnProperty("val")) {
            if (!Array.isArray(message.val))
                return "val: array expected";
            for (let i = 0; i < message.val.length; ++i)
                if (!$util.isInteger(message.val[i]))
                    return "val: integer[] expected";
        }
        return null;
    };

    ObjInts.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObjInts";
    };

    return ObjInts;
})();

export const Obj = $root.Obj = (() => {

    function Obj(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Obj.prototype.asInt = null;
    Obj.prototype.asString = "";
    Obj.prototype.asItemList = null;
    Obj.prototype.asBool = null;
    Obj.prototype.asBytes = $util.newBuffer([]);
    Obj.prototype.asFloat = null;

    Obj.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.asInt != null && Object.hasOwnProperty.call(message, "asInt"))
            $root.ObjInt.encode(message.asInt, writer.uint32(10).fork()).ldelim();
        if (message.asString != null && Object.hasOwnProperty.call(message, "asString"))
            writer.uint32(18).string(message.asString);
        if (message.asItemList != null && Object.hasOwnProperty.call(message, "asItemList"))
            $root.ObjItemList.encode(message.asItemList, writer.uint32(26).fork()).ldelim();
        if (message.asBool != null && Object.hasOwnProperty.call(message, "asBool"))
            $root.ObjBool.encode(message.asBool, writer.uint32(34).fork()).ldelim();
        if (message.asBytes != null && Object.hasOwnProperty.call(message, "asBytes"))
            writer.uint32(42).bytes(message.asBytes);
        if (message.asFloat != null && Object.hasOwnProperty.call(message, "asFloat"))
            $root.ObjFloat.encode(message.asFloat, writer.uint32(50).fork()).ldelim();
        return writer;
    };

    Obj.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Obj.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Obj();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.asInt = $root.ObjInt.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.asString = reader.string();
                    break;
                }
            case 3: {
                    message.asItemList = $root.ObjItemList.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.asBool = $root.ObjBool.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.asBytes = reader.bytes();
                    break;
                }
            case 6: {
                    message.asFloat = $root.ObjFloat.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Obj.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Obj.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.asInt != null && message.hasOwnProperty("asInt")) {
            let error = $root.ObjInt.verify(message.asInt);
            if (error)
                return "asInt." + error;
        }
        if (message.asString != null && message.hasOwnProperty("asString"))
            if (!$util.isString(message.asString))
                return "asString: string expected";
        if (message.asItemList != null && message.hasOwnProperty("asItemList")) {
            let error = $root.ObjItemList.verify(message.asItemList);
            if (error)
                return "asItemList." + error;
        }
        if (message.asBool != null && message.hasOwnProperty("asBool")) {
            let error = $root.ObjBool.verify(message.asBool);
            if (error)
                return "asBool." + error;
        }
        if (message.asBytes != null && message.hasOwnProperty("asBytes"))
            if (!(message.asBytes && typeof message.asBytes.length === "number" || $util.isString(message.asBytes)))
                return "asBytes: buffer expected";
        if (message.asFloat != null && message.hasOwnProperty("asFloat")) {
            let error = $root.ObjFloat.verify(message.asFloat);
            if (error)
                return "asFloat." + error;
        }
        return null;
    };

    Obj.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Obj";
    };

    return Obj;
})();

export const ObjItem = $root.ObjItem = (() => {

    function ObjItem(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ObjItem.prototype.key = 0;
    ObjItem.prototype.obj = null;

    ObjItem.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            writer.uint32(8).int32(message.key);
        if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
            $root.Obj.encode(message.obj, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    ObjItem.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ObjItem.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObjItem();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.key = reader.int32();
                    break;
                }
            case 2: {
                    message.obj = $root.Obj.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ObjItem.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ObjItem.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.key != null && message.hasOwnProperty("key"))
            if (!$util.isInteger(message.key))
                return "key: integer expected";
        if (message.obj != null && message.hasOwnProperty("obj")) {
            let error = $root.Obj.verify(message.obj);
            if (error)
                return "obj." + error;
        }
        return null;
    };

    ObjItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObjItem";
    };

    return ObjItem;
})();

export const ObjItemList = $root.ObjItemList = (() => {

    function ObjItemList(properties) {
        this.items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ObjItemList.prototype.items = $util.emptyArray;

    ObjItemList.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.items != null && message.items.length)
            for (let i = 0; i < message.items.length; ++i)
                $root.ObjItem.encode(message.items[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    ObjItemList.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ObjItemList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObjItemList();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.ObjItem.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ObjItemList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ObjItemList.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.items != null && message.hasOwnProperty("items")) {
            if (!Array.isArray(message.items))
                return "items: array expected";
            for (let i = 0; i < message.items.length; ++i) {
                let error = $root.ObjItem.verify(message.items[i]);
                if (error)
                    return "items." + error;
            }
        }
        return null;
    };

    ObjItemList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObjItemList";
    };

    return ObjItemList;
})();

export const Msg = $root.Msg = (() => {

    function Msg(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Msg.prototype.operation = null;
    Msg.prototype.returnCode = null;
    Msg.prototype.eventCode = null;
    Msg.prototype.actorId = null;
    Msg.prototype.args = $util.newBuffer([]);

    Msg.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
            $root.ObjInt.encode(message.operation, writer.uint32(10).fork()).ldelim();
        if (message.returnCode != null && Object.hasOwnProperty.call(message, "returnCode"))
            $root.ObjInt.encode(message.returnCode, writer.uint32(18).fork()).ldelim();
        if (message.eventCode != null && Object.hasOwnProperty.call(message, "eventCode"))
            $root.ObjInt.encode(message.eventCode, writer.uint32(26).fork()).ldelim();
        if (message.actorId != null && Object.hasOwnProperty.call(message, "actorId"))
            $root.ObjInt.encode(message.actorId, writer.uint32(34).fork()).ldelim();
        if (message.args != null && Object.hasOwnProperty.call(message, "args"))
            writer.uint32(42).bytes(message.args);
        return writer;
    };

    Msg.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Msg.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Msg();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.operation = $root.ObjInt.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.returnCode = $root.ObjInt.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.eventCode = $root.ObjInt.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.actorId = $root.ObjInt.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.args = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Msg.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Msg.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.operation != null && message.hasOwnProperty("operation")) {
            let error = $root.ObjInt.verify(message.operation);
            if (error)
                return "operation." + error;
        }
        if (message.returnCode != null && message.hasOwnProperty("returnCode")) {
            let error = $root.ObjInt.verify(message.returnCode);
            if (error)
                return "returnCode." + error;
        }
        if (message.eventCode != null && message.hasOwnProperty("eventCode")) {
            let error = $root.ObjInt.verify(message.eventCode);
            if (error)
                return "eventCode." + error;
        }
        if (message.actorId != null && message.hasOwnProperty("actorId")) {
            let error = $root.ObjInt.verify(message.actorId);
            if (error)
                return "actorId." + error;
        }
        if (message.args != null && message.hasOwnProperty("args"))
            if (!(message.args && typeof message.args.length === "number" || $util.isString(message.args)))
                return "args: buffer expected";
        return null;
    };

    Msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Msg";
    };

    return Msg;
})();

export const OperationEnum = $root.OperationEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Undefined"] = 0;
    values[valuesById[1] = "Authenticate"] = 1;
    values[valuesById[2] = "Join"] = 2;
    values[valuesById[3] = "Leave"] = 3;
    values[valuesById[4] = "CameraShare"] = 4;
    values[valuesById[5] = "Unique"] = 5;
    return values;
})();

export const ReturnEnum = $root.ReturnEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Undefined"] = 0;
    values[valuesById[1] = "OperationNotAllowedInCurrentState"] = 1;
    values[valuesById[2] = "AuthenticationFailed"] = 2;
    values[valuesById[3] = "Exception"] = 3;
    return values;
})();

export const EventEnum = $root.EventEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Undefined"] = 0;
    values[valuesById[1] = "Join"] = 1;
    values[valuesById[2] = "Leave"] = 2;
    values[valuesById[3] = "Props"] = 3;
    values[valuesById[4] = "CameraPosition"] = 4;
    values[valuesById[5] = "CameraRequest_Join"] = 5;
    values[valuesById[6] = "CameraAnswer_Join_Cancel"] = 6;
    values[valuesById[7] = "CameraRequest_Swap"] = 7;
    values[valuesById[8] = "CameraRequest_Swap_Cancel"] = 8;
    values[valuesById[9] = "CameraAnswer_Swap_Cancel"] = 9;
    values[valuesById[10] = "Marker_Operation"] = 10;
    values[valuesById[11] = "Viewpoint_Operation"] = 11;
    values[valuesById[12] = "Project_Operation"] = 12;
    values[valuesById[13] = "Mail_Operation"] = 13;
    values[valuesById[14] = "License_Operation"] = 14;
    values[valuesById[15] = "FilterPresets_Operation"] = 15;
    values[valuesById[16] = "IssuePresets_Operation"] = 16;
    values[valuesById[17] = "ExternalService_Operation"] = 17;
    values[valuesById[18] = "UserDataChange_Operation"] = 18;
    values[valuesById[19] = "SearchSets_Operation"] = 19;
    values[valuesById[20] = "FavoriteAttribs_Operation"] = 20;
    values[valuesById[21] = "LinkPresets_Operation"] = 21;
    values[valuesById[22] = "AppearSets_Operation"] = 22;
    values[valuesById[23] = "ClashTests_Operation"] = 23;
    values[valuesById[24] = "MasterProps"] = 24;
    values[valuesById[25] = "NotificationOperation"] = 25;
    values[valuesById[26] = "Scheduler_Operation"] = 26;
    return values;
})();

export const ActorFlag = $root.ActorFlag = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Undefined"] = 0;
    values[valuesById[1] = "Editor"] = 1;
    values[valuesById[2] = "Dublicate"] = 2;
    values[valuesById[4] = "Ignore"] = 4;
    values[valuesById[8] = "Master"] = 8;
    values[valuesById[16] = "DisableCameraShare"] = 16;
    values[valuesById[32] = "DisableIssues"] = 32;
    return values;
})();

export const PropertyId = $root.PropertyId = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Undefined"] = 0;
    values[valuesById[1] = "NickName"] = 1;
    values[valuesById[2] = "ActorFlags"] = 2;
    values[valuesById[3] = "MasterCameraActorID"] = 3;
    values[valuesById[4] = "ProductVer"] = 4;
    values[valuesById[11] = "SemanticsTable"] = 11;
    values[valuesById[12] = "FirstName"] = 12;
    values[valuesById[13] = "LastName"] = 13;
    values[valuesById[14] = "FOV"] = 14;
    values[valuesById[15] = "VisibilityDistance"] = 15;
    values[valuesById[16] = "CameraHeight"] = 16;
    values[valuesById[17] = "BacklightObject"] = 17;
    values[valuesById[18] = "ShowObjectInfo"] = 18;
    values[valuesById[19] = "ClippingPlane"] = 19;
    values[valuesById[20] = "AvatarUrl"] = 20;
    values[valuesById[21] = "EMail"] = 21;
    values[valuesById[22] = "SheetPort"] = 22;
    values[valuesById[23] = "SheetPortPosition"] = 23;
    values[valuesById[24] = "BacklightObjects"] = 24;
    values[valuesById[25] = "Isolate"] = 25;
    values[valuesById[26] = "BacklightObjectsAux"] = 26;
    values[valuesById[27] = "VisSelect"] = 27;
    values[valuesById[28] = "ColorSelect"] = 28;
    values[valuesById[29] = "PhaseSelect"] = 29;
    values[valuesById[30] = "Product"] = 30;
    values[valuesById[31] = "DeviceID"] = 31;
    return values;
})();

export const ProductEnum = $root.ProductEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "ViewerNative"] = 0;
    values[valuesById[1] = "ViewerMobile"] = 1;
    values[valuesById[2] = "ViewerWeb"] = 2;
    values[valuesById[3] = "SchedulerNative"] = 3;
    values[valuesById[4] = "SchedulerWeb"] = 4;
    return values;
})();

export const ArgId = $root.ArgId = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Undefined"] = 0;
    values[valuesById[1] = "Room"] = 1;
    values[valuesById[2] = "Auth"] = 2;
    values[valuesById[3] = "CameraShareCommand"] = 3;
    values[valuesById[4] = "CameraShareMaster"] = 4;
    values[valuesById[5] = "CameraShareSlave"] = 5;
    values[valuesById[6] = "Guid"] = 6;
    values[valuesById[7] = "LicenseId"] = 7;
    return values;
})();

export const CameraShareCommandEnum = $root.CameraShareCommandEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Undefined"] = 0;
    values[valuesById[1] = "Join"] = 1;
    values[valuesById[2] = "SlaveBreak"] = 2;
    values[valuesById[3] = "MasterBreak"] = 3;
    values[valuesById[4] = "Swap"] = 4;
    return values;
})();

export const SheetPort = $root.SheetPort = (() => {

    function SheetPort(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SheetPort.prototype.SheetId = "";
    SheetPort.prototype.PortId = "";

    SheetPort.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.SheetId != null && Object.hasOwnProperty.call(message, "SheetId"))
            writer.uint32(10).string(message.SheetId);
        if (message.PortId != null && Object.hasOwnProperty.call(message, "PortId"))
            writer.uint32(18).string(message.PortId);
        return writer;
    };

    SheetPort.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SheetPort.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SheetPort();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.SheetId = reader.string();
                    break;
                }
            case 2: {
                    message.PortId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SheetPort.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SheetPort.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.SheetId != null && message.hasOwnProperty("SheetId"))
            if (!$util.isString(message.SheetId))
                return "SheetId: string expected";
        if (message.PortId != null && message.hasOwnProperty("PortId"))
            if (!$util.isString(message.PortId))
                return "PortId: string expected";
        return null;
    };

    SheetPort.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SheetPort";
    };

    return SheetPort;
})();

export const ProjectOperation = $root.ProjectOperation = (() => {

    function ProjectOperation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ProjectOperation.prototype.code = 0;
    ProjectOperation.prototype.oldLicense = 0;
    ProjectOperation.prototype.newLicense = 0;
    ProjectOperation.prototype.externalLinks = "";
    ProjectOperation.prototype.relatedEntity = null;

    ProjectOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.oldLicense != null && Object.hasOwnProperty.call(message, "oldLicense"))
            writer.uint32(16).int32(message.oldLicense);
        if (message.newLicense != null && Object.hasOwnProperty.call(message, "newLicense"))
            writer.uint32(24).int32(message.newLicense);
        if (message.externalLinks != null && Object.hasOwnProperty.call(message, "externalLinks"))
            writer.uint32(34).string(message.externalLinks);
        if (message.relatedEntity != null && Object.hasOwnProperty.call(message, "relatedEntity"))
            $root.ProjectOperation.RelatedEntity.encode(message.relatedEntity, writer.uint32(42).fork()).ldelim();
        return writer;
    };

    ProjectOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ProjectOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProjectOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.oldLicense = reader.int32();
                    break;
                }
            case 3: {
                    message.newLicense = reader.int32();
                    break;
                }
            case 4: {
                    message.externalLinks = reader.string();
                    break;
                }
            case 5: {
                    message.relatedEntity = $root.ProjectOperation.RelatedEntity.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ProjectOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ProjectOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            switch (message.code) {
            default:
                return "code: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
                break;
            }
        if (message.oldLicense != null && message.hasOwnProperty("oldLicense"))
            if (!$util.isInteger(message.oldLicense))
                return "oldLicense: integer expected";
        if (message.newLicense != null && message.hasOwnProperty("newLicense"))
            if (!$util.isInteger(message.newLicense))
                return "newLicense: integer expected";
        if (message.externalLinks != null && message.hasOwnProperty("externalLinks"))
            if (!$util.isString(message.externalLinks))
                return "externalLinks: string expected";
        if (message.relatedEntity != null && message.hasOwnProperty("relatedEntity")) {
            let error = $root.ProjectOperation.RelatedEntity.verify(message.relatedEntity);
            if (error)
                return "relatedEntity." + error;
        }
        return null;
    };

    ProjectOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProjectOperation";
    };

    ProjectOperation.Code = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Undefined"] = 0;
        values[valuesById[1] = "UpdateRevision"] = 1;
        values[valuesById[2] = "UpdateAccess"] = 2;
        values[valuesById[3] = "UpdateTeam"] = 3;
        values[valuesById[4] = "UpdateLicense"] = 4;
        values[valuesById[5] = "UpdateExternalLinks"] = 5;
        values[valuesById[6] = "UpdateReport"] = 6;
        values[valuesById[7] = "UpdateDashboard"] = 7;
        values[valuesById[8] = "UpdateGraph"] = 8;
        values[valuesById[9] = "UpdateProps"] = 9;
        values[valuesById[10] = "UpdateClashTests"] = 10;
        values[valuesById[11] = "UpdateWorkflowRelatedProps"] = 11;
        return values;
    })();

    ProjectOperation.RelatedEntity = (function() {

        function RelatedEntity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        RelatedEntity.prototype.action = 0;
        RelatedEntity.prototype.title = "";
        RelatedEntity.prototype.uuid = "";
        RelatedEntity.prototype.graphUuid = "";

        RelatedEntity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(8).int32(message.action);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(18).string(message.title);
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(26).string(message.uuid);
            if (message.graphUuid != null && Object.hasOwnProperty.call(message, "graphUuid"))
                writer.uint32(34).string(message.graphUuid);
            return writer;
        };

        RelatedEntity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        RelatedEntity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProjectOperation.RelatedEntity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.action = reader.int32();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                case 3: {
                        message.uuid = reader.string();
                        break;
                    }
                case 4: {
                        message.graphUuid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        RelatedEntity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        RelatedEntity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.action != null && message.hasOwnProperty("action"))
                switch (message.action) {
                default:
                    return "action: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.graphUuid != null && message.hasOwnProperty("graphUuid"))
                if (!$util.isString(message.graphUuid))
                    return "graphUuid: string expected";
            return null;
        };

        RelatedEntity.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProjectOperation.RelatedEntity";
        };

        return RelatedEntity;
    })();

    ProjectOperation.RelatedEntityAction = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Create"] = 0;
        values[valuesById[1] = "Update"] = 1;
        values[valuesById[2] = "Delete"] = 2;
        return values;
    })();

    return ProjectOperation;
})();

export const SchedulerOperation = $root.SchedulerOperation = (() => {

    function SchedulerOperation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SchedulerOperation.prototype.code = 0;
    SchedulerOperation.prototype.deviceId = "";
    SchedulerOperation.prototype.projectUuid = "";
    SchedulerOperation.prototype.taskDefinitionUuid = "";
    SchedulerOperation.prototype.taskRunUuid = "";
    SchedulerOperation.prototype.bodyJson = "";

    SchedulerOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
            writer.uint32(18).string(message.deviceId);
        if (message.projectUuid != null && Object.hasOwnProperty.call(message, "projectUuid"))
            writer.uint32(26).string(message.projectUuid);
        if (message.taskDefinitionUuid != null && Object.hasOwnProperty.call(message, "taskDefinitionUuid"))
            writer.uint32(34).string(message.taskDefinitionUuid);
        if (message.taskRunUuid != null && Object.hasOwnProperty.call(message, "taskRunUuid"))
            writer.uint32(42).string(message.taskRunUuid);
        if (message.bodyJson != null && Object.hasOwnProperty.call(message, "bodyJson"))
            writer.uint32(50).string(message.bodyJson);
        return writer;
    };

    SchedulerOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SchedulerOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SchedulerOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.deviceId = reader.string();
                    break;
                }
            case 3: {
                    message.projectUuid = reader.string();
                    break;
                }
            case 4: {
                    message.taskDefinitionUuid = reader.string();
                    break;
                }
            case 5: {
                    message.taskRunUuid = reader.string();
                    break;
                }
            case 6: {
                    message.bodyJson = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SchedulerOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SchedulerOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isInteger(message.code))
                return "code: integer expected";
        if (message.deviceId != null && message.hasOwnProperty("deviceId"))
            if (!$util.isString(message.deviceId))
                return "deviceId: string expected";
        if (message.projectUuid != null && message.hasOwnProperty("projectUuid"))
            if (!$util.isString(message.projectUuid))
                return "projectUuid: string expected";
        if (message.taskDefinitionUuid != null && message.hasOwnProperty("taskDefinitionUuid"))
            if (!$util.isString(message.taskDefinitionUuid))
                return "taskDefinitionUuid: string expected";
        if (message.taskRunUuid != null && message.hasOwnProperty("taskRunUuid"))
            if (!$util.isString(message.taskRunUuid))
                return "taskRunUuid: string expected";
        if (message.bodyJson != null && message.hasOwnProperty("bodyJson"))
            if (!$util.isString(message.bodyJson))
                return "bodyJson: string expected";
        return null;
    };

    SchedulerOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SchedulerOperation";
    };

    SchedulerOperation.Code = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "None"] = 0;
        values[valuesById[1] = "TaskDefinitionAdd"] = 1;
        values[valuesById[2] = "TaskDefinitionModify"] = 2;
        values[valuesById[3] = "TaskDefinitionDelete"] = 3;
        values[valuesById[4] = "TaskRunAdd"] = 4;
        values[valuesById[5] = "TaskRunModify"] = 5;
        values[valuesById[6] = "MessageAdd"] = 6;
        return values;
    })();

    return SchedulerOperation;
})();

export const IssuePresetOperation = $root.IssuePresetOperation = (() => {

    function IssuePresetOperation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssuePresetOperation.prototype.operation = 0;
    IssuePresetOperation.prototype.presets = "";

    IssuePresetOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
            writer.uint32(8).int32(message.operation);
        if (message.presets != null && Object.hasOwnProperty.call(message, "presets"))
            writer.uint32(18).string(message.presets);
        return writer;
    };

    IssuePresetOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssuePresetOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssuePresetOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.operation = reader.int32();
                    break;
                }
            case 2: {
                    message.presets = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssuePresetOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssuePresetOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.operation != null && message.hasOwnProperty("operation"))
            switch (message.operation) {
            default:
                return "operation: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.presets != null && message.hasOwnProperty("presets"))
            if (!$util.isString(message.presets))
                return "presets: string expected";
        return null;
    };

    IssuePresetOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssuePresetOperation";
    };

    return IssuePresetOperation;
})();

export const IssuePresetOperationEnum = $root.IssuePresetOperationEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Add"] = 0;
    values[valuesById[1] = "Edit"] = 1;
    values[valuesById[2] = "Remove"] = 2;
    return values;
})();

export const FilterSetEnum = $root.FilterSetEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "All"] = 0;
    values[valuesById[1] = "Any"] = 1;
    values[valuesById[2] = "NoneOf"] = 2;
    return values;
})();

export const IssueFilterSet = $root.IssueFilterSet = (() => {

    function IssueFilterSet(properties) {
        this.Rules = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssueFilterSet.prototype.guid = "";
    IssueFilterSet.prototype.Name = "";
    IssueFilterSet.prototype.Share = 0;
    IssueFilterSet.prototype.Rules = $util.emptyArray;
    IssueFilterSet.prototype.Sorting = null;

    IssueFilterSet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
            writer.uint32(10).string(message.guid);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(18).string(message.Name);
        if (message.Share != null && Object.hasOwnProperty.call(message, "Share"))
            writer.uint32(24).int32(message.Share);
        if (message.Rules != null && message.Rules.length)
            for (let i = 0; i < message.Rules.length; ++i)
                $root.IssueFilterSetRule.encode(message.Rules[i], writer.uint32(34).fork()).ldelim();
        if (message.Sorting != null && Object.hasOwnProperty.call(message, "Sorting"))
            $root.SortingPreset.encode(message.Sorting, writer.uint32(42).fork()).ldelim();
        return writer;
    };

    IssueFilterSet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssueFilterSet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssueFilterSet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.guid = reader.string();
                    break;
                }
            case 2: {
                    message.Name = reader.string();
                    break;
                }
            case 3: {
                    message.Share = reader.int32();
                    break;
                }
            case 4: {
                    if (!(message.Rules && message.Rules.length))
                        message.Rules = [];
                    message.Rules.push($root.IssueFilterSetRule.decode(reader, reader.uint32()));
                    break;
                }
            case 5: {
                    message.Sorting = $root.SortingPreset.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssueFilterSet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssueFilterSet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.guid != null && message.hasOwnProperty("guid"))
            if (!$util.isString(message.guid))
                return "guid: string expected";
        if (message.Name != null && message.hasOwnProperty("Name"))
            if (!$util.isString(message.Name))
                return "Name: string expected";
        if (message.Share != null && message.hasOwnProperty("Share"))
            switch (message.Share) {
            default:
                return "Share: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.Rules != null && message.hasOwnProperty("Rules")) {
            if (!Array.isArray(message.Rules))
                return "Rules: array expected";
            for (let i = 0; i < message.Rules.length; ++i) {
                let error = $root.IssueFilterSetRule.verify(message.Rules[i]);
                if (error)
                    return "Rules." + error;
            }
        }
        if (message.Sorting != null && message.hasOwnProperty("Sorting")) {
            let error = $root.SortingPreset.verify(message.Sorting);
            if (error)
                return "Sorting." + error;
        }
        return null;
    };

    IssueFilterSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssueFilterSet";
    };

    return IssueFilterSet;
})();

export const RuleTypeEnum = $root.RuleTypeEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "ByType"] = 0;
    values[valuesById[1] = "ByAssignee"] = 1;
    values[valuesById[2] = "ByPriority"] = 2;
    values[valuesById[3] = "ByStatus"] = 3;
    values[valuesById[4] = "ByReporter"] = 4;
    values[valuesById[5] = "ByWatchers"] = 5;
    values[valuesById[6] = "ByTag"] = 6;
    values[valuesById[7] = "BySheets"] = 7;
    values[valuesById[8] = "ByDiscipline"] = 8;
    values[valuesById[9] = "ByCategory"] = 9;
    values[valuesById[10] = "ByLevel"] = 10;
    values[valuesById[11] = "BySourceFile"] = 11;
    values[valuesById[12] = "ByTest"] = 12;
    values[valuesById[13] = "ByGridX"] = 13;
    values[valuesById[14] = "ByGridY"] = 14;
    values[valuesById[15] = "ByType2"] = 15;
    values[valuesById[16] = "ByCreatedFrom"] = 16;
    values[valuesById[17] = "ByStampAbbrr"] = 17;
    values[valuesById[18] = "ByStampCategory"] = 18;
    values[valuesById[19] = "ByStampColor"] = 19;
    values[valuesById[20] = "ByPrivacy"] = 20;
    values[valuesById[21] = "ByProcoreCoordinationIssueType"] = 21;
    values[valuesById[22] = "ByProcoreCoordinationIssueStatus"] = 22;
    values[valuesById[23] = "ByAttachedDocs"] = 23;
    values[valuesById[24] = "ByProcoreRFIType"] = 24;
    values[valuesById[25] = "ByProcoreRFIStatus"] = 25;
    values[valuesById[26] = "ByDeadline"] = 26;
    values[valuesById[27] = "ByCreatedDate"] = 27;
    values[valuesById[28] = "ByClosedDate"] = 28;
    values[valuesById[29] = "ByType3"] = 29;
    values[valuesById[30] = "ByStamp2"] = 30;
    values[valuesById[31] = "ByZone"] = 31;
    values[valuesById[32] = "ByArea"] = 32;
    values[valuesById[33] = "ByRoom"] = 33;
    values[valuesById[34] = "BySpace"] = 34;
    values[valuesById[35] = "ByAssigneeCompany"] = 35;
    values[valuesById[36] = "ByAssigneeLocation"] = 36;
    values[valuesById[37] = "ByAssigneeDepartment"] = 37;
    values[valuesById[38] = "BySheetTags"] = 38;
    values[valuesById[39] = "ByCustomType"] = 39;
    values[valuesById[40] = "ByCustomStatus"] = 40;
    values[valuesById[41] = "ByStatusCategory"] = 41;
    values[valuesById[42] = "ByDeletedAt"] = 42;
    values[valuesById[43] = "ByDuration"] = 43;
    return values;
})();

export const IssueFilterSetRule = $root.IssueFilterSetRule = (() => {

    function IssueFilterSetRule(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssueFilterSetRule.prototype.RuleType = 0;
    IssueFilterSetRule.prototype.FilterSet = 0;
    IssueFilterSetRule.prototype.Items = $util.emptyArray;

    IssueFilterSetRule.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.RuleType != null && Object.hasOwnProperty.call(message, "RuleType"))
            writer.uint32(8).int32(message.RuleType);
        if (message.FilterSet != null && Object.hasOwnProperty.call(message, "FilterSet"))
            writer.uint32(16).int32(message.FilterSet);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                writer.uint32(26).string(message.Items[i]);
        return writer;
    };

    IssueFilterSetRule.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssueFilterSetRule.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssueFilterSetRule();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.RuleType = reader.int32();
                    break;
                }
            case 2: {
                    message.FilterSet = reader.int32();
                    break;
                }
            case 3: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssueFilterSetRule.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssueFilterSetRule.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.RuleType != null && message.hasOwnProperty("RuleType"))
            switch (message.RuleType) {
            default:
                return "RuleType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
            case 28:
            case 29:
            case 30:
            case 31:
            case 32:
            case 33:
            case 34:
            case 35:
            case 36:
            case 37:
            case 38:
            case 39:
            case 40:
            case 41:
            case 42:
            case 43:
                break;
            }
        if (message.FilterSet != null && message.hasOwnProperty("FilterSet"))
            switch (message.FilterSet) {
            default:
                return "FilterSet: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.Items != null && message.hasOwnProperty("Items")) {
            if (!Array.isArray(message.Items))
                return "Items: array expected";
            for (let i = 0; i < message.Items.length; ++i)
                if (!$util.isString(message.Items[i]))
                    return "Items: string[] expected";
        }
        return null;
    };

    IssueFilterSetRule.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssueFilterSetRule";
    };

    return IssueFilterSetRule;
})();

export const ShareEnum = $root.ShareEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Personal"] = 0;
    values[valuesById[1] = "Everyone"] = 1;
    return values;
})();

export const SortingPreset = $root.SortingPreset = (() => {

    function SortingPreset(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SortingPreset.prototype.Primary = null;
    SortingPreset.prototype.Secondary = null;
    SortingPreset.prototype.UnreadFirst = null;

    let $oneOfFields;

    Object.defineProperty(SortingPreset.prototype, "_UnreadFirst", {
        get: $util.oneOfGetter($oneOfFields = ["UnreadFirst"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    SortingPreset.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Primary != null && Object.hasOwnProperty.call(message, "Primary"))
            $root.SortingPresetOption.encode(message.Primary, writer.uint32(10).fork()).ldelim();
        if (message.Secondary != null && Object.hasOwnProperty.call(message, "Secondary"))
            $root.SortingPresetOption.encode(message.Secondary, writer.uint32(18).fork()).ldelim();
        if (message.UnreadFirst != null && Object.hasOwnProperty.call(message, "UnreadFirst"))
            writer.uint32(24).bool(message.UnreadFirst);
        return writer;
    };

    SortingPreset.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SortingPreset.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SortingPreset();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Primary = $root.SortingPresetOption.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.Secondary = $root.SortingPresetOption.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.UnreadFirst = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SortingPreset.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SortingPreset.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.Primary != null && message.hasOwnProperty("Primary")) {
            let error = $root.SortingPresetOption.verify(message.Primary);
            if (error)
                return "Primary." + error;
        }
        if (message.Secondary != null && message.hasOwnProperty("Secondary")) {
            let error = $root.SortingPresetOption.verify(message.Secondary);
            if (error)
                return "Secondary." + error;
        }
        if (message.UnreadFirst != null && message.hasOwnProperty("UnreadFirst")) {
            properties._UnreadFirst = 1;
            if (typeof message.UnreadFirst !== "boolean")
                return "UnreadFirst: boolean expected";
        }
        return null;
    };

    SortingPreset.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SortingPreset";
    };

    return SortingPreset;
})();

export const SortingPresetOption = $root.SortingPresetOption = (() => {

    function SortingPresetOption(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SortingPresetOption.prototype.Type = 0;
    SortingPresetOption.prototype.IsAscending = false;

    SortingPresetOption.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
            writer.uint32(8).int32(message.Type);
        if (message.IsAscending != null && Object.hasOwnProperty.call(message, "IsAscending"))
            writer.uint32(16).bool(message.IsAscending);
        return writer;
    };

    SortingPresetOption.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SortingPresetOption.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SortingPresetOption();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Type = reader.int32();
                    break;
                }
            case 2: {
                    message.IsAscending = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SortingPresetOption.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SortingPresetOption.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.Type != null && message.hasOwnProperty("Type"))
            if (!$util.isInteger(message.Type))
                return "Type: integer expected";
        if (message.IsAscending != null && message.hasOwnProperty("IsAscending"))
            if (typeof message.IsAscending !== "boolean")
                return "IsAscending: boolean expected";
        return null;
    };

    SortingPresetOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SortingPresetOption";
    };

    return SortingPresetOption;
})();

export const IssueFilterSetOperation = $root.IssueFilterSetOperation = (() => {

    function IssueFilterSetOperation(properties) {
        this.inPresets = [];
        this.inOrders = [];
        this.outPresets = [];
        this.outOrders = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssueFilterSetOperation.prototype.code = 0;
    IssueFilterSetOperation.prototype.inPresets = $util.emptyArray;
    IssueFilterSetOperation.prototype.inOrders = $util.emptyArray;
    IssueFilterSetOperation.prototype.outPresets = $util.emptyArray;
    IssueFilterSetOperation.prototype.outOrders = $util.emptyArray;

    IssueFilterSetOperation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(8).int32(message.code);
        if (message.inPresets != null && message.inPresets.length)
            for (let i = 0; i < message.inPresets.length; ++i)
                $root.IssueFilterSet.encode(message.inPresets[i], writer.uint32(18).fork()).ldelim();
        if (message.inOrders != null && message.inOrders.length)
            for (let i = 0; i < message.inOrders.length; ++i)
                $root.IssueFilterSetOrderItem.encode(message.inOrders[i], writer.uint32(26).fork()).ldelim();
        if (message.outPresets != null && message.outPresets.length)
            for (let i = 0; i < message.outPresets.length; ++i)
                $root.IssueFilterSet.encode(message.outPresets[i], writer.uint32(34).fork()).ldelim();
        if (message.outOrders != null && message.outOrders.length)
            for (let i = 0; i < message.outOrders.length; ++i)
                $root.IssueFilterSetOrderItem.encode(message.outOrders[i], writer.uint32(42).fork()).ldelim();
        return writer;
    };

    IssueFilterSetOperation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssueFilterSetOperation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssueFilterSetOperation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.inPresets && message.inPresets.length))
                        message.inPresets = [];
                    message.inPresets.push($root.IssueFilterSet.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    if (!(message.inOrders && message.inOrders.length))
                        message.inOrders = [];
                    message.inOrders.push($root.IssueFilterSetOrderItem.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    if (!(message.outPresets && message.outPresets.length))
                        message.outPresets = [];
                    message.outPresets.push($root.IssueFilterSet.decode(reader, reader.uint32()));
                    break;
                }
            case 5: {
                    if (!(message.outOrders && message.outOrders.length))
                        message.outOrders = [];
                    message.outOrders.push($root.IssueFilterSetOrderItem.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssueFilterSetOperation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssueFilterSetOperation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            switch (message.code) {
            default:
                return "code: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.inPresets != null && message.hasOwnProperty("inPresets")) {
            if (!Array.isArray(message.inPresets))
                return "inPresets: array expected";
            for (let i = 0; i < message.inPresets.length; ++i) {
                let error = $root.IssueFilterSet.verify(message.inPresets[i]);
                if (error)
                    return "inPresets." + error;
            }
        }
        if (message.inOrders != null && message.hasOwnProperty("inOrders")) {
            if (!Array.isArray(message.inOrders))
                return "inOrders: array expected";
            for (let i = 0; i < message.inOrders.length; ++i) {
                let error = $root.IssueFilterSetOrderItem.verify(message.inOrders[i]);
                if (error)
                    return "inOrders." + error;
            }
        }
        if (message.outPresets != null && message.hasOwnProperty("outPresets")) {
            if (!Array.isArray(message.outPresets))
                return "outPresets: array expected";
            for (let i = 0; i < message.outPresets.length; ++i) {
                let error = $root.IssueFilterSet.verify(message.outPresets[i]);
                if (error)
                    return "outPresets." + error;
            }
        }
        if (message.outOrders != null && message.hasOwnProperty("outOrders")) {
            if (!Array.isArray(message.outOrders))
                return "outOrders: array expected";
            for (let i = 0; i < message.outOrders.length; ++i) {
                let error = $root.IssueFilterSetOrderItem.verify(message.outOrders[i]);
                if (error)
                    return "outOrders." + error;
            }
        }
        return null;
    };

    IssueFilterSetOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssueFilterSetOperation";
    };

    return IssueFilterSetOperation;
})();

export const IssueFilterSetOperationCodeEnum = $root.IssueFilterSetOperationCodeEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "GetAll"] = 0;
    values[valuesById[1] = "Add"] = 1;
    values[valuesById[2] = "Edit"] = 2;
    values[valuesById[3] = "Visibility"] = 3;
    values[valuesById[4] = "Delete"] = 4;
    values[valuesById[5] = "Reorder"] = 5;
    return values;
})();

export const IssueFilterSetOrderItem = $root.IssueFilterSetOrderItem = (() => {

    function IssueFilterSetOrderItem(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssueFilterSetOrderItem.prototype.guid = "";
    IssueFilterSetOrderItem.prototype.Order = 0;

    IssueFilterSetOrderItem.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
            writer.uint32(10).string(message.guid);
        if (message.Order != null && Object.hasOwnProperty.call(message, "Order"))
            writer.uint32(16).int32(message.Order);
        return writer;
    };

    IssueFilterSetOrderItem.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssueFilterSetOrderItem.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssueFilterSetOrderItem();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.guid = reader.string();
                    break;
                }
            case 2: {
                    message.Order = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssueFilterSetOrderItem.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssueFilterSetOrderItem.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.guid != null && message.hasOwnProperty("guid"))
            if (!$util.isString(message.guid))
                return "guid: string expected";
        if (message.Order != null && message.hasOwnProperty("Order"))
            if (!$util.isInteger(message.Order))
                return "Order: integer expected";
        return null;
    };

    IssueFilterSetOrderItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssueFilterSetOrderItem";
    };

    return IssueFilterSetOrderItem;
})();

export const IssueFilterSetOrder = $root.IssueFilterSetOrder = (() => {

    function IssueFilterSetOrder(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssueFilterSetOrder.prototype.Items = $util.emptyArray;

    IssueFilterSetOrder.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.IssueFilterSetOrderItem.encode(message.Items[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    IssueFilterSetOrder.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssueFilterSetOrder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssueFilterSetOrder();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.IssueFilterSetOrderItem.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssueFilterSetOrder.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssueFilterSetOrder.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.Items != null && message.hasOwnProperty("Items")) {
            if (!Array.isArray(message.Items))
                return "Items: array expected";
            for (let i = 0; i < message.Items.length; ++i) {
                let error = $root.IssueFilterSetOrderItem.verify(message.Items[i]);
                if (error)
                    return "Items." + error;
            }
        }
        return null;
    };

    IssueFilterSetOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssueFilterSetOrder";
    };

    return IssueFilterSetOrder;
})();

export const IssueFilterSetRules = $root.IssueFilterSetRules = (() => {

    function IssueFilterSetRules(properties) {
        this.Rules = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    IssueFilterSetRules.prototype.Rules = $util.emptyArray;

    IssueFilterSetRules.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Rules != null && message.Rules.length)
            for (let i = 0; i < message.Rules.length; ++i)
                $root.IssueFilterSetRule.encode(message.Rules[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    IssueFilterSetRules.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    IssueFilterSetRules.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IssueFilterSetRules();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Rules && message.Rules.length))
                        message.Rules = [];
                    message.Rules.push($root.IssueFilterSetRule.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    IssueFilterSetRules.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    IssueFilterSetRules.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.Rules != null && message.hasOwnProperty("Rules")) {
            if (!Array.isArray(message.Rules))
                return "Rules: array expected";
            for (let i = 0; i < message.Rules.length; ++i) {
                let error = $root.IssueFilterSetRule.verify(message.Rules[i]);
                if (error)
                    return "Rules." + error;
            }
        }
        return null;
    };

    IssueFilterSetRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IssueFilterSetRules";
    };

    return IssueFilterSetRules;
})();

export { $root as default };
