<template>
    <v-tabs
        v-model="model"
        :fixed-tabs="fixedTabs"
        @change="change"
    >
        <slot name="customTabs" />
        <template v-if="!$slots.customTabs">
            <v-tab
                v-for="(tab, index) in tabs"
                :key="index"
                :ripple="false"
                :class="{ 'has-icon': tab.icon }"
                :data-test="tab.dataTest"
            >
                <v-icon
                    v-if="tab.icon"
                    small
                >
                    {{ tab.icon }}
                </v-icon>
                <span class="label">{{ tab.label }}</span>
            </v-tab>
        </template>
    </v-tabs>
</template>

<script lang="ts">
    import { Component, Emit, Prop } from 'vue-property-decorator';
    import { ITab } from '@/types/ui';
    import { Color } from '@/constants';
    import VuetifyElement from '@/components/common/VuetifyElement.vue';

    @Component
    export default class WsTableHeader extends VuetifyElement {
        @Prop() public tabs!: ITab[];
        @Prop({ type: Boolean, default: false }) public fixedTabs!: boolean;

        public readonly Color = Color;

        @Emit()
        public change(index: number) {
            return index;
        }
    }
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

::v-deep .v-tabs-bar {
    height: 42px;
}

::v-deep .v-tab {
    position: relative;
    padding: 4px 16px 4px 16px;
    text-transform: none;
    letter-spacing: inherit;
    margin-bottom: -8px; // To extend clickable zone.
    z-index: 1;
    &.has-icon {
        padding-left: 8px
    }

    .label {
        font-size: 14px;
        line-height: 21px;
        transition: color 0.2s ease;
        color: $light-solid-60;
    }

    .theme--light.v-tabs & {
        &:hover,
        &:focus {
            &::before {
                opacity: 0;
            }
        }
    }

    &:hover,
    &:focus {
        &::before {
            opacity: 0;
        }

        .label {
            color: $light-solid-100;
        }

        .v-icon.v-icon {
            color: $light-solid-80;
        }
    }

    &--active,
    .theme--light.v-tabs &--active {
        .label {
            color: $light-solid-100;
        }
    }
}

::v-deep .v-icon.v-icon {
    width: 24px;
    height: 24px;
    margin: 0 4px 0 0;
    color: $light-solid-80;
}

::v-deep .v-slide-group__content {
    border-bottom: 2px solid $light-solid-10;
}

::v-deep .v-tabs-slider {
    background-color: $light-accent;
}

::v-deep .v-tabs-slider-wrapper {
    bottom: -2px;
}

</style>