<template>
    <div
        :class="{ threshold: isThreshold }"
        :style="style"
        class="drag-content"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import WsDragZone from '@/components/common/dragZone/WsDragZone.vue';

interface StyleProps {
    width?: string;
    height?: string;
    flexGrow?: number;
    minWidth?: string;
}

@Component({
    name: 'WsDragContent',
})
export default class WsDragContent extends Vue {
    @Prop({ type: Boolean, default: false }) public fixed!: boolean;
    @Prop({ type: Boolean, default: false }) public collapsed!: boolean;
    @Prop({ type: String }) public startSize!: string;
    @Prop({ type: Number }) public minSize!: number;

    public sizeAttr: number | null = parseInt(this.startSize, 10);
    public isMinSize = false;
    public isMaxSize = false;

    get size() {
        return this.sizeAttr;
    }

    get parent() {
        return this.$parent as WsDragZone;
    }

    get horizontal() {
        return this.parent?.horizontal;
    }

    get isThreshold() {
        return this.isMinSize || this.isMaxSize;
    }

    get style(): StyleProps {
        const style: StyleProps = {
            flexGrow: Number(!this.isMaxSize),
        };

        const size = !this.collapsed
            ? this.startSize || `${ this.size }px`
            : `${ this.size }px`;

        if (!this.fixed && this.size !== null) {
            if (this.horizontal) {
                style.width = size;
                style.minWidth = `${this.getMinSize()}px`;
            } else {
                style.height = size;
            }
        }

        return style;
    }

    public getTargetChild() {
        return this.$el?.children[0];
    }

    public getIsFixed() {
        return this.fixed || this.getMaxSize() === this.getMinSize();
    }

    public getCss(element: Element, attr: string): string {
        if (!element) {
            return '';
        }

        const css = document.defaultView?.getComputedStyle(element, null);
        const cssKey = attr as keyof CSSStyleDeclaration;
        return css?.[cssKey] as string;
    }

    public getSize(element: Element): number {
        return element?.getBoundingClientRect()[this.horizontal ? 'width' : 'height'];
    }

    public setSize(size: number) {
        this.sizeAttr = size;
    }

    public getSizeAttr(type: string) {
        return `${ type }${ this.horizontal ? 'Width' : 'Height' }`;
    }

    public getMinSize(): number {
        if (this.fixed) {
            return this.getSize(this.$el);
        }

        if (this.minSize) {
            return this.minSize;
        }

        const css: string = this.getCss(this.getTargetChild(), this.getSizeAttr('min'));
        let minSize: number = 0;

        if (['0px', 'auto'].includes(css)) {
            minSize = 0;
        } else if (css.includes('%')) {
            // @ts-ignore
            minSize = parseFloat(css) / 100 * this.getSize(this.$parent.$el);
        } else if (css.includes('px')) {
            minSize = parseFloat(css);
        }

        if (minSize < 0) {
            minSize = 0;
        }

        return minSize;
    }

    public getMaxSize() {
        if (this.fixed) {
            return this.getSize(this.$el);
        }

        const targetMaxSize = this.getCss(this.getTargetChild(), this.getSizeAttr('max'));
        const currentMaxSize = this.getCss(this.$el, this.getSizeAttr('max'));
        const css: string = targetMaxSize === 'none' ? currentMaxSize : targetMaxSize;
        let maxSize: number = 200;

        if (css.includes('%')) {
            // @ts-ignore
            maxSize = parseFloat(css) / 100 * this.getSize(this.$parent.$el);
        } else if (css.includes('px')) {
            maxSize = parseFloat(css);
        }

        return maxSize;
    }

    public checkMinMaxSize() {
        this.isMinSize = this.fixed || this.size === this.getMinSize();
        this.isMaxSize = this.fixed || this.size === this.getMaxSize();
    }

    public mounted() {
        this.checkMinMaxSize();
    }
}
</script>

<style lang="scss" scoped>
.drag-content {
    max-width: 2000px;
    flex-grow: 1;
}
</style>
