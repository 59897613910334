<template>
    <WsTableFilterElement
        v-model="popover"
        :label="label"
        :disabled="disabled"
        :placement="placement"
        :append-to-body="appendToBody"
        @open="openDropdown"
        @clear="clear"
    >
        <template v-if="searchable && (items.length || filteredItems.length) && !colorSelect">
            <WsInput
                v-model="search"
                :placeholder="$t('Simple_word.search')"
                hide-details
                clearable
            />
            <hr>
        </template>

        <slot />

        <v-list
            v-if="filteredItems.length"
            :class="{ 'color-select': colorSelect }"
            class="list"
            dense
        >
            <template v-if="!colorSelect">
                <v-list-item
                    :input-value="allSelected"
                    @click="toggleAll"
                >
                    <template>
                        <v-list-item-action>
                            <v-checkbox
                                :input-value="allSelected"
                                :indeterminate="filteredItems.length !== selected.length && Boolean(selected.length)"
                            />
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title>
                                <span class="select-all">{{ $t('Text.selectAll') }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-list-item>

                <hr>
            </template>

            <WsLazyRender :items="filteredItems">
                <template #default="{ items }">
                    <v-list-item
                        v-for="(item, j) in items"
                        :key="'item' + j"
                        :value="item"
                        :input-value="isActive(item)"
                        @click="toggle(item)"
                    >
                        <template>
                            <v-list-item-action>
                                <v-checkbox :input-value="isActive(item)" />
                            </v-list-item-action>
                            <v-list-item-content :class="{ 'amp-mask': ampMask }">
                                <v-list-item-title class="list-title">
                                    <WsSelectColorItem v-if="colorSelect" :color="item.color" :rgb="isRGBColor(item.color)" />
                                    <CustomIssueType
                                        v-else-if="type === IssuesFilterType.customType && item.rawValue"
                                        :issue-type="item.rawValue"
                                    />
                                    <CustomStatusItem
                                        v-else-if="type === IssuesFilterType.customStatus && item.rawValue"
                                        :status="item.rawValue"
                                    />
                                    <WsTruncateAuto v-else :value="item.text" :key="refreshKey" />
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </WsLazyRender>
        </v-list>

        <div v-else class="no-data">
            {{ items.length ? $t('errors.search_no_matches') : $t('errors.noItemSelection') }}
        </div>

        <template #button v-if="translatedSelected.length || placeholder">
            <template v-if="translatedSelected.length">
                <span v-for="(item, index) in translatedSelected" :key="'selected' + index">
                    <span v-if="colorSelect" :style="{ background: getColorCircleBackground(item) }" class="color-circle small" />
                    <span v-if="!colorSelect" :class="{ 'amp-mask': ampMask }">{{ item.text }}</span>
                    <span v-if="index + 1 < translatedSelected.length">, </span>
                </span>
            </template>
            <template v-else>{{ placeholder }}</template>
        </template>
    </WsTableFilterElement>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { ISelectedItem } from '@/types/ISelectedItem';
import WsSelectColorItem from '@/components/common/WsSelectColorItem.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';
import WsTableFilterElement from '@/components/common/WsTableFilterElement.vue';
import WsInput from '@/components/common/WsInput.vue';
import CustomIssueType from '@/domain/issueType/components/CustomIssueType.vue';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import CustomStatusItem from '@/domain/customStatus/components/CustomStatusItem.vue';

@Component({
    components: {
        WsSelectColorItem,
        CustomStatusItem,
        CustomIssueType,
        WsTruncateAuto,
        WsTableFilterElement,
        WsLazyRender,
        WsInput,
    },
})
export default class WsTableFilterSelect extends Vue {
    @Prop({ required: true }) public value!: Array<string | number>;
    @Prop({ required: true }) public items!: ISelectedItem[];
    @Prop({ type: Boolean, default: false }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean }) public colorSelect!: boolean;
    @Prop({ type: String }) public type!: string;
    @Prop({ default: 'bottom' }) public placement!: string;
    @Prop({ default: true }) public appendToBody!: boolean;
    @Prop() public placeholder!: TranslateResult;
    @Prop() public label!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;

    public search = '';
    public popover = false;
    public selected: ISelectedItem[] = [];
    public allItems: ISelectedItem[] = [];
    public refreshKey = 0;

    public readonly IssuesFilterType = IssuesFilterType;

    get translatedSelected(): ISelectedItem[] {
        return this.selected.map((selectedItem) => this.items.find(({ value }) => selectedItem.value === value) || selectedItem);
    }

    get filteredItems(): ISelectedItem[] {
        if (this.search) {
            const result = this.allItems.filter((item) => String(item.text).toLowerCase().includes(this.search.toLowerCase()));

            if (this.type === 'id') {
                const searchArray = this.search.split(',');
                searchArray.forEach((text: string) => {
                    if (text) {
                        result.push({
                            text,
                            value: text,
                        });
                    }
                });
            }
            return _.uniqBy(result, 'value');
        }
        const itemsOrAllItems = this.allItems.length ? this.allItems : this.items;
        if (this.type === 'id') {
            return _.uniqBy([
                ...itemsOrAllItems,
                ...this.value.map((val) => ({ text: String(val), value: String(val) })),
            ], 'value');
        }
        return itemsOrAllItems;
    }
    get allSelected() {
        return this.filteredItems.length === this.selected.length;
    }

    @Emit()
    public input(value: Array<string | number>) {
        return value;
    }
    @Emit()
    public clear() {
        this.input([]);
        return;
    }

    @Watch('search', { immediate: true, deep: true })
    public afterSearch() {
        if (this.type === 'id') {
            this.selected = this.selected.filter((selectedItem: any) => this.filteredItems.find(({ value }: any) => selectedItem.value === value));
        }
    }

    @Watch('items', { immediate: true, deep: true })
    @Watch('value', { immediate: true, deep: true })
    public afterValue() {
        if (!this.value || !this.value.length) {
            this.selected = [];
        } else {
            this.selected = this.value
                .map((value) => this.filteredItems.find((item) => item.value === value))
                .filter((value) => Boolean(value)) as ISelectedItem[];
        }
    }

    @Watch('popover')
    public changePopover(newValue: boolean) {
        // On open set filter type, on close reset to null.
        const commitedValue = newValue ? this.type : null;

        if (this.$store) {
            this.$store.commit('setOpenedFilter', commitedValue);
        }
    }

    public mounted() {
        this.popover = Boolean(this.type) && this.type === this.$store?.getters.openedFilter;
    }

    public isActive(item: ISelectedItem) {
        return this.selected.map(({ value }) => value).includes(item.value);
    }

    public openDropdown() {
        this.allItems = _.uniqBy([...this.translatedSelected, ...this.items], 'value');
        this.refreshKey++;
    }

    public toggle(item: ISelectedItem) {
        const i = this.selected.map(({ value }) => value).indexOf(item.value);

        if (i > -1) {
            this.selected.splice(i, 1);
        } else {
            this.selected.push(item);
        }

        const values = this.selected.map(({ value }) => value);
        this.input(values);
    }

    public toggleAll() {
        if (this.allSelected) {
            this.selected = [];
        } else {
            this.selected = [...this.filteredItems];
        }
        const values = this.selected.map(({ value }) => value);
        this.input(values);
    }

    public isRGBColor(color: string) {
        return color && color.split(',').length === 3;
    }

    public getColorCircleBackground(item: any) {
        if (item.color) {
            if (this.isRGBColor(item.color)) {
                return `rgb(${item.color})`;
            }

            return item.color;
        }

        return item.value;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

$local-margin: 12px;

.ws-select {
    .v-list {
        margin-left: -$local-margin;

        hr {
            margin: $local-margin/2 $local-margin $local-margin/2 $local-margin*4;

            &:after {
                right: -$local-margin;
            }
        }
    }
    .v-list-item {
        padding: 0 $local-margin;
    }
    .v-list-item__action {
        margin: 0 $local-margin 0 0;
    }
}
.list {
    max-height: 200px;
    overflow-y: auto;

    &.color-select {
        max-height: 414px;
        padding: 0;

        .v-list-item__content {
            padding: 4px 0;
        }
    }

    @include scrollbar;
}
hr {
    margin: 10px 0;
}
.no-data {
    text-align: center;
}
.v-list-item .v-list-item__title.list-title {
    display: flex;
    font-weight: normal;
    font-size: $font-size;
    line-height: 18px;
}
.select-all {
    font-size: 12px;
    line-height: $line-height-small;
    font-weight: 300;
}
.color-circle {
    display: inline-block;
    width: 38px;
    height: 38px;
    border-radius: 100%;

    &.small {
        width: 14px;
        height: 14px;
        border: 1px solid $white;
    }
}

::v-deep .v-input__append-inner {
    margin-top: 6px;
}
</style>
