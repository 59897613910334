<template>
    <div class="issue-comment-diff-component comment-diff">
        <div>
            <div v-for="(diff, key) in comment.diff" :key="key" class="diff">
                <IssueCommentDiffInline
                    v-if="inlineProperties.includes(key)"
                    :diff="diff"
                    :entity="key"
                    class="suppressed"
                    @click="click"
                />

                <div v-else-if="multilineProperties.includes(key)" class="suppressed">
                    <IssueCommentDiffChanged
                        :entity="key"
                        @click="click"
                    />
                    <IssueCommentDiffMultipleItems
                        v-if="Array.isArray(diff.old)"
                        :diff="diff"
                        :entity="key"
                    />
                    <template v-if="!Array.isArray(diff.old)">
                        <span class="highlighting">{{ diff.old }}</span> ➝
                        <span class="highlighting">{{ diff.new }}</span>
                    </template>
                </div>

                <template v-if="specialProperties.includes(key)">
                    <IssueCommentDiffCde v-if="key === 'cdeLinks'" :diff="diff" />
                    <IssueCommentDiffProcore
                        v-if="key === IssueTrackerFields.procore"
                        :diff="diff"
                        :status-diff="comment.diff.status"
                    />
                    <IssueCommentDeletedAt v-if="key === IssueTrackerFields.deletedAt" :diff="diff" />
                    <IssueCommentDiffStatus
                        v-if="[IssueTrackerFields.status, IssueTrackerFields.customStatus].includes(key) && !isDiffHasDeletedAt"
                        :diff="comment.diff"
                        :entity="key"
                    />
                    <IssueCommentDiffType v-if="key === IssueTrackerFields.customType" :diff="comment.diff" />
                </template>
            </div>
        </div>
        <div v-if="comment.isFromClashSync" class="suppressed">{{ $t('Collocation.viaClashSync') }}</div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IssueTrackerFields } from '@/constants/IssueTrackerFields';
import IssueCommentDeletedAt from '@/domain/comment/components/IssueCommentDeletedAt.vue';
import IssueStatusText from '@/domain/issue/components/IssueStatusText.vue';
import IssueCommentDiffMultipleItems from '@/domain/comment/components/IssueCommentDiffMultipleItems.vue';
import IssueCommentDiffChanged from '@/domain/comment/components/IssueCommentDiffChanged.vue';
import IssuePriorityText from '@/domain/issue/components/IssuePriorityText.vue';
import IssueCommentDiffInline from '@/domain/comment/components/IssueCommentDiffInline.vue';
import IssueCommentDiffCde from '@/domain/comment/components/IssueCommentDiffCde.vue';
import IssueCommentDiffProcore from '@/domain/comment/components/IssueCommentDiffProcore.vue';
import IssueCommentDiffStatus from '@/domain/comment/components/IssueCommentDiffStatus.vue';
import IssueCommentDiffType from '@/domain/comment/components/IssueCommentDiffType.vue';
import {
    IssueTrackerDiffInlineProperties,
    IssueTrackerDiffMultilineProperties, IssueTrackerDiffSpecialProperties,
} from '@/domain/comment/models/IssueTrackerDiffProperties';
import { DiffComment } from '@/domain/comment/models/DiffComment';

@Component({
    components: {
        IssueCommentDeletedAt,
        IssueStatusText,
        IssueCommentDiffMultipleItems,
        IssueCommentDiffChanged,
        IssuePriorityText,
        IssueCommentDiffInline,
        IssueCommentDiffCde,
        IssueCommentDiffProcore,
        IssueCommentDiffStatus,
        IssueCommentDiffType,
    },
})
export default class IssueCommentDiff extends Vue {
    @Prop({ required: true }) public comment!: DiffComment;

    public readonly IssueTrackerFields = IssueTrackerFields;

    public readonly inlineProperties = IssueTrackerDiffInlineProperties;
    public readonly multilineProperties = IssueTrackerDiffMultilineProperties;
    public readonly specialProperties = IssueTrackerDiffSpecialProperties;

    get isDiffHasDeletedAt() {
        return this.comment.diff.deletedAt;
    }

    @Emit()
    public click() {
        return;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.suppressed {
    opacity: .5;
}

.diff:not(:last-child) {
    margin-bottom: 2em;
}

.comment-diff ::v-deep .highlighting {
    display: inline-block;
    word-break: break-word;
}
</style>
