<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :editable="localEditable"
        :label="label"
        :multi-text="showFieldMultipleText"
        :multi-changed="isNewMultiValue"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <div class="issue-type-limiter">
            <CustomIssueType :issue-type="currentIssueType" passive />
        </div>
        <template #action>
            <IssueDetailsEditPopover
                :initial-value="currentValue"
                :items="itemsForSelect"
                :is-reset="false"
                auto-width
                @change="handleSelect"
            >
                <template #item="{ value }">
                    <div>
                        <CustomIssueType :issue-type="value" inline />
                    </div>
                </template>
            </IssueDetailsEditPopover>
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { IChangeCustomTypeConflictParams, IIssuesWithWarningsItem } from '@/types/issue-tracker';
import { BusEvent, ReasonsWhyIssuesHaveProblems } from '@/constants';
import { eventBus } from '@/services/eventBus';
import CustomIssueType from '@/domain/issueType/components/CustomIssueType.vue';
import { Issue } from '@/domain/issue/models/Issue';
import { NAVISWORKS_TYPE_UUID } from '@/domain/issueType/constants/customIssueType';
import { IssueType } from '@/domain/issueType/models/IssueType';
import { Workflow } from '@/domain/workflow/models/Workflow';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/domain/issue/components/details/IssueDetailsEditPopover.vue';

@Component({
    components: {
        CustomIssueType,
        IssueDetailsItem,
        IssueDetailsEditPopover,
    },
})
export default class IssueDetailsCustomType extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: IssueType[];

    get localEditable() {
        if (this.isMultiselectEditModeActive) {
            return this.editable;
        }
        
        if (!this.editable) {
            return false;
        }

        if (this.currentValue === NAVISWORKS_TYPE_UUID) {
            return false;
        }

        return Boolean(this.items.length > 1);
    }

    get itemsForSelect() {
        return this.items?.map((type) => ({
            text: type.name,
            value: type,
            key: type.uuid,
        })) || [];
    }

    get currentIssueType(): IssueType {
        return this.$store.getters.customIssueTypeByUuid(this.currentProject.uuid, this.currentValue);
    }

    get navisIssuesCantChangeType(): Issue[] {
        return this.$store.getters.getNavisIssuesTryingChangeType();
    }

    get isShowWarningIcon(): boolean {
        return Boolean(this.issuesWithPermissionError?.length || this.navisIssuesCantChangeType?.length);
    }

    public handleSelect(value: IssueType) {
        if (this.isMultiselectEditModeActive) {
            this.change(value.uuid);
            return;
        }

        const isEqual = this.currentValue === value.uuid;

        if (isEqual) {
            return;
        }

        const currentIssueWorkflow: Workflow = this.$store.getters.workflowByCustomTypeUuid(this.currentProject.uuid, this.currentIssue.customType);

        if (currentIssueWorkflow.uuid === value.workflowUuid) {
            this.change(value.uuid);
            return;
        }

        const currentStatusUuid = this.currentIssue.customStatus;
        const newWorkflow: Workflow | undefined = this.$store.getters.workflowByUuid(this.currentProject.uuid, value.workflowUuid);

        if (!newWorkflow) {
            return;
        }

        if (newWorkflow.statuses.find((status) => status.uuid === currentStatusUuid)) {
            this.change(value.uuid);
            return;
        }

        const currentUserEmail = this.$store.getters.userData.email;
        const userCanCloseIssue = this.currentIssue.hasPermissions(currentUserEmail, this.currentProject.permissions.closeIssue);

        eventBus.$emit(BusEvent.demandSelectNewStatus, {
            oldTypeUuid: this.currentIssue.customType,
            newTypeUuid: value.uuid,
            currentStatusUuid,
            allowCategoryDone: userCanCloseIssue,
            callback: () => {
                this.change(value.uuid);
            },
        } as IChangeCustomTypeConflictParams);
    }

    public showDialogWithWarningDescription() {
        const problems: IIssuesWithWarningsItem[] = [];

        if (this.issuesWithPermissionError.length) {
            problems.push({
                reason: ReasonsWhyIssuesHaveProblems.permissionError,
                issues: this.issuesWithPermissionError,
            });
        }

        if (this.navisIssuesCantChangeType.length) {
            problems.push({
                reason: ReasonsWhyIssuesHaveProblems.navisIssuesCantChangeType,
                issues: this.navisIssuesCantChangeType,
            });
        }

        if (!problems.length) {
            return;
        }

        eventBus.$emit(BusEvent.showDialogIssuesWithProblems, problems);
    }
}
</script>

<style lang="scss" scoped>
.pointer {
    cursor: pointer;
}

.issue-type-limiter {
    width: 240px;
    overflow: hidden;
}
</style>
