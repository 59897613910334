<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :editable="editable"
        :label="label"
        :multi-text="showFieldMultipleText"
        :multi-changed="isNewMultiValue"
        :is-show-warning-icon="isShowWarningIcon"
        class="issue-details-reporter"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <ProjectMemberItem :email="currentValue" />

        <template #action>
            <IssueDetailsEditPopover
                :initial-value="currentValue"
                :items="items"
                :recent-used-items="recentSelectedUsers"
                :is-reset="false"
                searchable
                @change="handleSelect"
            >
                <template #item="{ value }">
                    <ProjectMemberItem :email="value" />
                </template>

                <template v-if="isShowAssigneeToMe" #afterSearch="{ select }">
                    <v-list-item class="assignee-to-me" @click="selectAssignTome(select)">
                        <a>{{ $t('IssueTracker.info.assignee.toMe') }}</a>
                    </v-list-item>
                </template>
            </IssueDetailsEditPopover>
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { ProjectMembersOption } from '@/models';
import { amplitudeLog } from '@/services';
import { RecentSelectedUsersService } from '@/services/RecentSelectedUsers';
import WsButton from '@/components/common/WsButton.vue';
import ProjectMemberItem from '@/components/project/ProjectMemberItem.vue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/domain/issue/components/details/IssueDetailsEditPopover.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';

@Component({
    components: {
        WsButton,
        IssueDetailsItem,
        IssueDetailsEditPopover,
        ProjectMemberItem,
    },
})
export default class IssueDetailsReporter extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: ProjectMembersOption[];
    @Prop({ type: Boolean, default: false }) public showRecentUsers!: boolean;

    public recentSelectedValues: string[] = [];

    get isShowAssigneeToMe() {
        if (!this.showRecentUsers) {
            return false;
        }

        return this.currentValue !== this.currentUser.email && this.items.some((item) => item.value === this.currentUser.email);
    }

    get recentSelectedUsers() {
        if (!this.showRecentUsers) {
            return [];
        }

        const users = this.items.filter((item) => this.recentSelectedValues.includes(item.value));

        return users.filter((user) => user.value !== this.currentUser.email && user.value !== this.currentValue);
    }

    @Watch('currentIssue')
    public watchCurrentIssue() {
        this.recentSelectedValues = this.getRecentSelectedEmails();
    }

    public created() {
        this.recentSelectedValues = this.getRecentSelectedEmails();
    }

    public handleSelect(value: string) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
            this.updateRecentSelected(value);
        }
    }

    private getRecentSelectedEmails(): string[] {
        return RecentSelectedUsersService.getRecentSelectedUsers(this.projectId);
    }

    public updateRecentSelected(value: string) {
        if (!this.showRecentUsers || value === this.currentUser.email) {
            return false;
        }

        this.recentSelectedValues = RecentSelectedUsersService.saveRecentSelectedUser(this.projectId, value);
    }

    public selectAssignTome(selectCallback: (payload: any) => void) {
        selectCallback(this.currentUser.email);
        amplitudeLog(AmplitudeEvent.itUsedRecentUsers, { assigneeToMe: true });
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.pointer {
    cursor: pointer;
}

.assignee-to-me {
    padding: 0;
    margin: 0;
    border-bottom: $border;

    > a {
        color: $primary-blue;
        padding: 0 $default-padding;
    }
}
</style>
