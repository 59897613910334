<template>
    <SkeletonBone full />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonSimple extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
</style>
