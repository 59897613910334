<template>
    <div
        :class="{
            successfulness,
            danger,
            alert,
            information,
        }"
        class="ws-label"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsLabel extends Vue {
    @Prop({ required: false, type: Boolean }) public successfulness !: boolean;
    @Prop({ required: false, type: Boolean }) public danger!: boolean;
    @Prop({ required: false, type: Boolean }) public alert!: boolean;
    @Prop({ required: false, type: Boolean }) public information!: boolean;

    public readonly Color = Color;
}
</script>