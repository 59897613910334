<template>
    <div class="component-modal-header header">
        <div class="logo-line container">
            <ReviztoLogoFull class="revizto-logo" @click="redirectToMain" />
            <WsCloseButton
                v-if="hasClose"
                round
                @click="close"
            />
        </div>
        <slot name="second-line" />
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';
import ReviztoLogoFull from '@/components/svg/ReviztoLogoFull.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';

@Component({
    components: {
        ReviztoLogoFull,
        WsCloseButton,
    },
})
export default class ModalHeader extends Vue {
    @Prop({ type: Boolean, default: true }) public hasClose!: boolean;

    @Emit()
    public close() {
        return;
    }
    public redirectToMain() {
        this.close();
        this.$router.push({ name: RouterNames.StartPage });
    }
}
</script>

<style scoped lang="scss">
.header {
    margin-bottom: 30px;
}

.logo-line {
    padding: 15px;
    position: relative;
}
.component-modal-header {
    ::v-deep .ws-close-button {
        position: absolute;
        top: 23px !important;
        right: 20px;
    }
}

.revizto-logo {
    cursor: pointer;
}
</style>
