<template>
    <v-list-item
        :value="item.value"
        :ripple="false"
        @click="click"
    >
        <WsTooltip
            :disabled="!item.disabled"
            :tooltip="disabledTooltip"
        >
            <template #activator="{ on }">
                <div
                    v-on="on"
                    :class="{
                        disabled: item.disabled,
                        checked: isActive
                    }"
                    :id="item.label"
                    class="menu-item"
                >
                    <WsCheckbox2
                        v-if="isShowCheckbox"
                        :value="isActive"
                        :disabled="item.disabled"
                    />
                    <slot />
                </div>
            </template>
        </WsTooltip>
    </v-list-item>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IComboboxOption } from '@/types/common';
import { i18n } from '@/services/i18n';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsCheckbox2 from './WsCheckbox2.vue';

@Component({
    components: {
        WsTooltip,
        WsCheckbox2,
    },
})
export default class WsMultiSelect extends Vue {
    @Prop({ required: true, default: () => [] }) public item!: IComboboxOption;
    @Prop({ default: i18n.t('Simple_word.disabled') }) public disabledTooltip!: string;
    @Prop({ type: Boolean, default: true }) public isActive!: boolean;
    @Prop({ type: Boolean, default: true }) public isItemsSlots!: boolean;
    @Prop({ type: Boolean, default: true }) public isShowCheckbox!: boolean;

    @Emit()
    public click() {}
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.menu-item {
    display: flex;
    align-items: center;
    padding: 8px 20px 10px 20px;
    gap: 16px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    min-height: 48px;
    color: $light-solid-100;
    cursor: pointer;

    &.disabled {
        opacity: 0.75;
        background-color: $light-solid-10;
        cursor: not-allowed;
    }

    &.checked {
        background-color: $light-accent-10;
    }

    &:hover {
        background-color: $light-accent-10;
    }

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-break: break-word;
    }
}
</style>