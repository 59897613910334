<template>
    <div class="component-locale-switcher top-menu-item">
        <WsPopover
            v-model="isLanguageSelectOpened"
            :width="130"
            placement="bottom-end"
            no-padding
        >
            <template #reference>
                <div
                    :class="{ 'is-focus': isLanguageSelectOpened }"
                    class="selected-language"
                    @click="isLanguageSelectOpened = !isLanguageSelectOpened"
                >
                    {{ currentLanguage }}
                </div>
            </template>

            <div
                v-for="language in languagesOptions"
                :key="language.key"
                class="locale-switcher-item"
                @click="selectLocale(language.key)"
            >
                <img :src="`/images/languages/${language.key}.png`" :alt="language.key">
                <span
                    :class="{ 'selected': currentLanguage === language.key }"
                    class="locale-switcher-locale-name"
                >
                    {{ language.value }}
                </span>
            </div>
        </WsPopover>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { LanguageKey, Languages } from '@/constants';
import WsPopover from '@/components/common/WsPopover.vue';

@Component({
    components: {
        WsPopover,
    },
})
export default class LocaleSwitcher extends Vue {
    @Prop({ default: LanguageKey.en }) public currentLanguage!: string;
    @Prop({ default: LanguageKey.en }) public defaultLanguage!: string;
    public isLanguageSelectOpened = false;

    get languagesOptions() {
        return Languages;
    }

    @Emit('select')
    public select(language: string) {
        return language;
    }

    public selectLocale(localeKey: any) {
        this.select(localeKey);
        this.isLanguageSelectOpened = false;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/wsui/index.scss';

$switcherSize: 24px;

.selected-language {
    border: 0;
    text-transform: capitalize;
    background-color: $select-black;
    color: $white;
    height: $switcherSize;
    line-height: $switcherSize;
    width: 48px;
    text-align: center;
    cursor: pointer;
    user-select: none;

    &:hover,
    &.is-focus {
        background-color: $primary-blue;
    }
}

::v-deep {
    .locale-switcher-item {
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 6px 0;
        cursor: pointer;
        user-select: none;

        &:hover {
            background: $color-accent-10;
        }
    }

    .locale-switcher-locale-name {
        margin-left: 10px;
        color: $menu;
        user-select: none;

        &.selected {
            font-weight: 700;
        }
    }
}

</style>
