<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :editable="editable"
        :label="label"
        :multi-text="showFieldMultipleText"
        :multi-changed="isNewMultiValue"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <WsSelectColorItem
            :color="currentValue"
            color-only
            dense
            rgb
        />

        <template #action>
            <IssueDetailsEditPopover
                :initial-value="currentValue"
                :items="itemsForSelect"
                :is-reset="false"
                @change="handleSelect"
            >
                <template #item="{ value }">
                    <WsSelectColorItem :color="value" rgb />
                </template>
            </IssueDetailsEditPopover>
        </template>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import WsSelectColorItem from '@/components/common/WsSelectColorItem.vue';
import { Issue } from '@/domain/issue/models/Issue';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/domain/issue/components/details/IssueDetailsEditPopover.vue';
import IssueDetailsBase from '@/domain/issue/components/details/IssueDetailsBase.vue';
import { StampColorsPaletteRGBEnum } from '@/domain/stamp/constants/StampColorsPaletteRGBEnum';

@Component({
    components: {
        IssueDetailsItem,
        IssueDetailsEditPopover,
        WsSelectColorItem,
    },
})
export default class IssueDetailsStampColor extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    get currentValue() {
        if (this.multiEditNewValues) {
            return StampColorsPaletteRGBEnum[this.multiEditNewValues.value];
        }

        const rawValue = this.showFieldMultipleText
            ? this.multiValue
            : this.currentIssue[this.valueKey as keyof Issue];

        return StampColorsPaletteRGBEnum[rawValue];
    }

    get itemsForSelect(): Array<{ text: string; value: string }> {
        return this.items.map((value) => ({ text: value, value }));
    }

    public handleSelect(value: string) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.color {
    height: 24px;
    width: 48px;
    display: block;
    border-radius: 15px;
}
</style>
