<template>
    <div class="skeleton-issue-info-wrapper">
        <div v-for="index in 5" :key="index" class="skeleton-issue-info">
            <div class="skeleton-issue-info-title">
                <SkeletonBone />
                <SkeletonBone avatar />
            </div>

            <div class="skeleton-issue-info-content">
                <SkeletonBone text-full />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonIssueInfo extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.skeleton-issue-info-wrapper {
    display: flex;
    flex-direction: column;
}
.skeleton-issue-info {
    display: flex;
    flex-direction: column;
    padding: $container-padding;
    grid-gap: $small-padding;
}
.skeleton-issue-info-title {
    display: grid;
    grid-template-columns: auto 24px;
    grid-gap: $default-gap;
    align-items: center;
}
.skeleton-issue-info-content {
    display: flex;
    flex-direction: column;
    gap: $small-padding;
}
</style>
