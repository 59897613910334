import _ from 'lodash';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { MutableIssueField } from '@/types/MutableIssueField';
import { BooleanNumber } from '@/types/BooleanNumber';
import { DEADLINE_NOT_SET } from '@/constants';
import { IPreview } from '@/models';
// @ts-ignore
import { Protobuf } from '@/services/Protobuf';
import { IssueStatusEnum } from '@/domain/issue/constants/IssueStatus';
import { IssuePriorityEnum } from '@/domain/issue/constants/IssuePriority';
import {
    geClashSourceFile,
    getClashArea, getClashCategory, getClashGrid,
    getClashLevel,
    getClashRoom,
    getClashSpace, getClashTest,
    getClashZone,
} from '@/domain/issue/services/IssueProperties';
import { StampColorsPaletteRGBEnum } from '@/domain/stamp/constants/StampColorsPaletteRGBEnum';

interface IssueMutableField<T> {
    value: T;
    timestamp: number; // seconds
}

interface Snapshot {
    storage: string;
    file: string;
    timestamp: string;
}

export interface ProcoreRFILink {
    id: number;
    RFILink: string;
    status: string;
    RFIDraft: boolean;
    RFISubject: string;
    RFINumber: string;
    officialReply: string;
}

export interface CdeLink {
    service?: string;
    provider: string;
    name: string;
    externalProjectId: string;
    externalFileId: string;
    externalFileVersionId: string;
    externalFileUrl: string;
    externalCompanyId: string;
}

interface IssueRead {
    comments: number; // количество прочитанных комментариев
    issue: boolean; // прочитана ли ишью, если да то труе
}

const MutableIssueFields = new Set([
    MutableIssueField.customType,
    MutableIssueField.customStatus,
    MutableIssueField.compareSheet,
    MutableIssueField.cdeLinks,
    MutableIssueField.priority,
    MutableIssueField.assignee,
    MutableIssueField.reporter,
    MutableIssueField.visibility,
    MutableIssueField.status,
    MutableIssueField.tags,
    MutableIssueField.watchers,
    MutableIssueField.stampAbbr,
    MutableIssueField.stampColor,
    MutableIssueField.deadline,
    MutableIssueField.statusAuto,
    MutableIssueField.created,
    MutableIssueField.title,
    MutableIssueField.procore,
]);

export class Issue {
    public readonly id: number;
    public readonly uuid: string;
    public readonly author: any;
    public readonly version: string;
    public readonly hasPreview: any;
    public readonly updated: string;
    public readonly commented: string;
    public readonly type: number;
    public readonly binding: number;
    public comments: number;
    public read: IssueRead;
    public preview: IPreview;
    public assigneeInfo: any;

    private _isBCF: boolean = false;
    private _isNavisClash: boolean = false;
    private _clashDiscipline: number[] = [];
    private _clashLevel: string[] = [];
    private _clashArea: string[] = [];
    private _clashRoom: string[] = [];
    private _clashGridX: string[] = [];
    private _clashGridY: string[] = [];
    private _clashSpace: string[] = [];
    private _clashZone: string[] = [];
    private _clashSourceFile: string[] = [];
    private _clashCategory: string[] = [];
    private _clashTest: string[] = [];
    private _grids: string[] = [];
    private _countClashes: number | any = 0;
    private _countrClashes: number | any = 0;
    private _isClash: boolean = false;
    private _isRClash: boolean = false;
    private _customType: IssueMutableField<string>;
    private _title: IssueMutableField<string>;
    private _status: IssueMutableField<IssueStatusEnum>;
    private _customStatus: IssueMutableField<string>;
    private _assignee: IssueMutableField<string>;
    private _watchers: IssueMutableField<string[]>;
    private _reporter: IssueMutableField<string>;
    private _visibility: IssueMutableField<BooleanNumber>;
    private _tags: IssueMutableField<string[]>;
    private _created: IssueMutableField<string>;
    private _deadline: IssueMutableField<Moment>;
    private _deletedAt: IssueMutableField<string>;
    private _markup: IssueMutableField<any>;
    private _snapshot: IssueMutableField<Snapshot>;
    private _camera: IssueMutableField<any>;
    private _sheet: IssueMutableField<any|any[]>;
    private _selections: IssueMutableField<string|any[]>;
    private _sectioncuts: IssueMutableField<string|any[]>;
    private _clashes: IssueMutableField<any>;
    private _visibilitySelection: IssueMutableField<any>;
    private _colorSelection: IssueMutableField<any>;
    private _phaseSelection: IssueMutableField<any>;
    private _priority: IssueMutableField<IssuePriorityEnum>;
    private _bind3d: IssueMutableField<any>;
    private _internalProperties: IssueMutableField<string>;
    private _stampColor: IssueMutableField<number>;
    private _stampAbbr: IssueMutableField<string>;
    private _stampScale: IssueMutableField<number>;
    private _backgroundType: IssueMutableField<any>;
    private _procore: IssueMutableField<ProcoreRFILink>|null;
    private _pin: IssueMutableField<any>;
    private _cdeLinks: IssueMutableField<CdeLink[]>;
    private _compareSheet: IssueMutableField<any>;
    private _visualOperations: IssueMutableField<any>;
    private _selectionNames: IssueMutableField<any>;
    private _rClashes: IssueMutableField<any>;
    private _statusAuto: IssueMutableField<any>;
    private _clashTestUuid: IssueMutableField<any>;
    private _positionProperties: IssueMutableField<string>;
    private _unpackInternalProperties: { list: any[] };
    private _unpackClashes: any;
    private _unpackrClashes: any;
    private _unpackPositionProperties: any;

    constructor(issue: any = {}) {
        this.id = issue.id;
        this.uuid = issue.uuid || uuidv4();
        this.author = issue.author;
        this.version = issue.version;
        this.updated = issue.updated;
        this.commented = issue.commented;
        this.hasPreview = issue.hasPreview || false;
        this.type = issue.type || 0;
        this.binding = issue.binding || 0;
        this.comments = issue.comments || 0;
        this.read = issue.unread;
        this.preview = issue.preview;

        this._customType = this.convertTimestampToLocal(issue.customType);
        this._title = this.convertTimestampToLocal(issue.title);
        this._status = this.convertTimestampToLocal(issue.status);
        this._customStatus = this.convertTimestampToLocal(issue.customStatus);
        this._assignee = this.convertTimestampToLocal(issue.assignee);
        this._watchers = this.convertTimestampToLocal(issue.watchers);
        this._reporter = this.convertTimestampToLocal(issue.reporter);
        this._visibility = this.convertTimestampToLocal(issue.visibility);
        this._tags = this.convertTimestampToLocal(issue.tags);
        this._created = this.convertTimestampToLocal(issue.created);
        this._deadline = this.convertTimestampToLocal(issue.deadline);
        this._markup = this.convertTimestampToLocal(issue.markup);
        this._snapshot = this.convertTimestampToLocal(issue.snapshot);
        this._camera = this.convertTimestampToLocal(issue.camera || { value: [] });
        this._sheet = this.convertTimestampToLocal(issue.sheet);
        this._selections = this.convertTimestampToLocal(issue.selections);
        this._sectioncuts = this.convertTimestampToLocal(issue.sectioncuts);
        this._deletedAt = this.convertTimestampToLocal(issue.deletedAt);

        this._clashes = this.convertTimestampToLocal(issue.clashes);

        this._visibilitySelection = this.convertTimestampToLocal(issue.visibilitySelection);
        this._colorSelection = this.convertTimestampToLocal(issue.colorSelection);
        this._phaseSelection = this.convertTimestampToLocal(issue.phaseSelection);
        this._priority = this.convertTimestampToLocal(issue.priority);
        this._bind3d = this.convertTimestampToLocal(issue.bind3d);
        this._internalProperties = this.convertTimestampToLocal(issue.internalProperties);
        this._stampColor = this.convertTimestampToLocal(issue.stampColor);
        this._stampAbbr = this.convertTimestampToLocal(issue.stampAbbr);
        this._stampScale = this.convertTimestampToLocal(issue.stampScale);
        this._backgroundType = this.convertTimestampToLocal(issue.backgroundType);
        this._procore = this.convertTimestampToLocal(issue.procore || { value: null });
        this._pin = this.convertTimestampToLocal(issue.pin);
        this._cdeLinks = this.convertTimestampToLocal(issue.cdeLinks);
        this._compareSheet = this.convertTimestampToLocal(issue.compareSheet);
        this._visualOperations = this.convertTimestampToLocal(issue.visualOperations);
        this._selectionNames = this.convertTimestampToLocal(issue.selectionNames);
        this._rClashes = this.convertTimestampToLocal(issue.rClashes);
        this._statusAuto = this.convertTimestampToLocal(issue.statusAuto);
        this._clashTestUuid = this.convertTimestampToLocal(issue.clashTestUuid);
        this._positionProperties = this.convertTimestampToLocal(issue.positionProperties);

        this._unpackInternalProperties = { list: [] };
        if (this._internalProperties) {
            this.unpackInternalProperties(this._internalProperties.value);
        }

        this._unpackClashes = [];
        if (this._clashes) {
            this.unpackClashes(this._clashes.value);
        }

        this._unpackrClashes = [];
        if (this._rClashes) {
            this.unpackRClashes(this._rClashes.value);
        }

        this._unpackPositionProperties = [];
        if (this._positionProperties) {
            this.unpackPositionProperties(this._positionProperties.value);
        }

        this.setIsNavisClash();
        this.setClashDiscipline();
        this.setIsBCF();
        this.setClashLevel();
        this.setClashArea();
        this.setClashRoom();
        this.setClashGridX();
        this.setClashGridY();
        this.setClashSpace();
        this.setClashZone();
        this.setClashSourceFile();
        this.setClashCategory();
        this.setClashTest();
        this.setGrids();
        this.setCountClashes();
        this.setCountrClashes();
        this.setIsClash();
        this.setIsRClash();

        this._unpackInternalProperties = { list: [] };
        this._unpackPositionProperties = [];
        this._unpackClashes = [];
        this._unpackrClashes = [];
    }

    get isBCF() {
        return this._isBCF;
    }

    get isNavisClash() {
        return this._isNavisClash;
    }

    get clashDiscipline() {
        return this._clashDiscipline;
    }

    get clashLevel() {
        return this._clashLevel;
    }

    get clashArea() {
        return this._clashArea;
    }

    get clashRoom() {
        return this._clashRoom;
    }

    get clashGridX() {
        return this._clashGridX;
    }

    get clashGridY() {
        return this._clashGridY;
    }

    get clashSpace() {
        return this._clashSpace;
    }

    get clashZone() {
        return this._clashZone;
    }

    get clashSourceFile() {
        return this._clashSourceFile;
    }

    get clashCategory() {
        return this._clashCategory;
    }

    get clashTest() {
        return this._clashTest;
    }

    get grids() {
        return this._grids;
    }

    get countClashes() {
        return this._countClashes;
    }

    get countrClashes() {
        return this._countrClashes;
    }

    get isClash() {
        return this._isClash;
    }

    get isRClash() {
        return this._isRClash;
    }

    get snapshot() {
        return this._snapshot?.value;
    }

    get isDeleted() {
        return this.status === IssueStatusEnum.deleted || this._deletedAt?.value;
    }

    get isUnread(): boolean {
       return this.read.issue;
    }

    get countUnreadComments(): number {
        return this.read.comments;
    }
    get sheet(): string {
        return this._sheet.value;
    }
    get compareSheet(): string {
        return this._compareSheet.value;
    }
    set compareSheet(value: string) {
        this._compareSheet = this.mutableFieldSetter(value);
    }

    get cdeLinks(): CdeLink[] {
        return this._cdeLinks.value;
    }
    set cdeLinks(value: CdeLink[]) {
        this._cdeLinks = this.mutableFieldSetter(value);
    }

    get priority(): IssuePriorityEnum {
        return this._priority.value;
    }
    set priority(value: IssuePriorityEnum) {
        this._priority = this.mutableFieldSetter(value);
    }

    get assignee(): string {
        return this._assignee.value;
    }
    set assignee(value: string) {
        this._assignee = this.mutableFieldSetter(value);
    }

    get reporter(): string {
        return this._reporter.value;
    }
    set reporter(value: string) {
        this._reporter = this.mutableFieldSetter(value);
    }

    get visibility(): boolean {
        return Boolean(this._visibility.value);
    }
    set visibility(value: boolean) {
        this._visibility = this.mutableFieldSetter(value ? 1 : 0);
    }

    get status(): IssueStatusEnum {
        return this._status.value;
    }

    get tags(): string[] {
        return this._tags.value;
    }
    set tags(value: string[]) {
        this._tags = this.mutableFieldSetter(value);
    }

    get watchers(): string[] {
        return this._watchers.value;
    }
    set watchers(value: string[]) {
        this._watchers = this.mutableFieldSetter(value);
    }

    get stampAbbr(): string {
        return this._stampAbbr.value;
    }
    set stampAbbr(value: string) {
        this._stampAbbr = this.mutableFieldSetter(value);
    }

    get stampColorValue(): string {
        return StampColorsPaletteRGBEnum[this._stampColor.value];
    }
    set stampColor(value: number) {
        this._stampColor = this.mutableFieldSetter(value);
    }
    get stampColor() {
        return this._stampColor.value;
    }

    get deadline(): Moment {
        return this._deadline.value;
    }
    set deadline(value: Moment) {
        this._deadline = this.mutableFieldSetter(value);
    }

    get isDeadlineExpired(): any {
        return moment(this.deadline).isSameOrBefore(moment(), 'day') && this.deadline.toString() !== DEADLINE_NOT_SET;
    }

    get isDeadLineToday(): boolean {
        return Math.abs(moment.utc(this.deadline).diff(moment.utc(), 'days')) === 0;
    }

    get statusAuto(): string {
        return this._statusAuto.value;
    }
    set statusAuto(value: string) {
        this._statusAuto = this.mutableFieldSetter(value);
    }

    get created(): string {
        return this._created.value;
    }
    set created(value: string) {
        this._created = this.mutableFieldSetter(value);
    }

    get customStatus(): string {
        return this._customStatus.value;
    }
    set customStatus(value: string) {
        this._customStatus = this.mutableFieldSetter(value);
    }

    get customType(): string {
        return this._customType.value;
    }
    set customType(value: string) {
        this._customType = this.mutableFieldSetter(value);
    }

    get title(): string {
        return this._title.value;
    }
    set title(value: string) {
        this._title = this.mutableFieldSetter(value);
    }

    get procore(): ProcoreRFILink | null {
        return this._procore ? this._procore.value : null;
    }
    set procore(value: ProcoreRFILink|null) {
        this._procore = value ? this.mutableFieldSetter(value) : null;
    }

    get is3d() {
        return !this.is2d && !_.isEmpty(this._camera.value);
    }

    get is2d() {
        return this._sheet.value && this._sheet.value?.is3d === false;
    }

    get createdFrom() {
        if (this.is2d) {
            return 2;
        }
        if (this.is3d) {
            return 3;
        }
        return 0;
    }

    get duration() {
        return Math.ceil((moment().utc().unix() - this._customStatus.timestamp) / 86400);
    }

    public setFieldValue(field: string, value: any) {
        if (MutableIssueFields.has(field as MutableIssueField)) {
            try {
                (this as any)[field] = value;
            } catch (error) {
                window.console.warn(`Can't find setter for Issue field ${field}\n`, error);
            }
        }
    }

    public getMyRole(userMail: string): number {
        let result = 4;

        if (this._assignee?.value === userMail) {
            result += 2;
        }

        if (this.author?.email === userMail) {
            result += 1;
        }

        return result;
    }

    public hasPermissions(currentUserEmail: string, permissionLevel: number) {
        if (!permissionLevel) {
            return false;
        }

        // not a typo, it`s bool logic
        return Boolean(this.getMyRole(currentUserEmail) & permissionLevel);
    }

    private convertTimestampToLocal(field: IssueMutableField<any>) {
        if (field) {
            return { value: field.value, timestamp: moment.utc(field.timestamp).unix() };
        }
        return field;
    }
    private mutableFieldSetter<T>(value: T): IssueMutableField<T> {
        return {
            value,
            timestamp: moment.utc().unix(),
        };
    }

    private setIsNavisClash() {
        this._isNavisClash = !_.isEmpty(this._unpackClashes);
    }

    private setClashDiscipline() {
        this._clashDiscipline =  _.uniq(_.concat(...(this._unpackClashes.items || []).map((item: any) => [item.discipline0, item.discipline1])));
    }

    private setClashLevel() {
        this._clashLevel = getClashLevel(this._unpackClashes, this._unpackrClashes, this._unpackPositionProperties);
    }

    private setClashArea() {
        this._clashArea = getClashArea(this._unpackrClashes, this._unpackPositionProperties);
    }

    private setClashRoom() {
        this._clashRoom = getClashRoom(this._unpackrClashes, this._unpackPositionProperties);
    }

    private setClashGridX() {
        const arrClashes = _.uniq(_.concat(...(this._unpackClashes.items || []).map((item: any) => [item.igrid1])))
            .map((num) => this._unpackClashes.strings[num - 1]);
        const rClosestGrid = _.uniq((this._unpackrClashes.items || []).map((item: any) => item.ClosestGrid?.gridPair.iItemA))
            .map((num) => this._unpackrClashes.strings?.[num as number - 1]);
        const rApproximateGrids = _.uniq(_.concat(
            ...(this._unpackrClashes.items || []).map((item: any) => item.GridIntersectionsInProximity.map((pair: any) => pair.iItemA)),
        )).map((num) => this._unpackrClashes.strings?.[num - 1]);
        const arrRClashes = [...rClosestGrid, ...rApproximateGrids];
        const closestGrid = (this._unpackPositionProperties || {}).ClosestGrid?.GridPair.ItemA;
        const approximateGrids = ((this._unpackPositionProperties || {}).GridIntersectionsInProximity || []).map((pair: any) => pair.ItemA);
        const arrPosProp: string[] =  [closestGrid, ...approximateGrids];

        this._clashGridX = [...arrClashes, ...arrRClashes, ...arrPosProp].filter(Boolean);
    }

    private setClashGridY() {
        const arrClashes = _.uniq(_.concat(...(this._unpackClashes.items || []).map((item: any) => [item.igrid2])))
            .map((num) => this._unpackClashes.strings[num - 1]);
        const rClosestGrid = _.uniq((this._unpackrClashes.items || []).map((item: any) => item.ClosestGrid?.gridPair.iItemB))
            .map((num) => this._unpackrClashes.strings?.[num as number - 1]);
        const rApproximateGrids = _.uniq(_.concat(
            ...(this._unpackrClashes.items || []).map((item: any) => item.GridIntersectionsInProximity.map((pair: any) => pair.iItemB)),
        )).map((num) => this._unpackrClashes.strings?.[num - 1]);

        const arrRClashes = [...rClosestGrid, ...rApproximateGrids];
        const closestGrid = (this._unpackPositionProperties || {}).ClosestGrid?.GridPair.ItemB;
        const approximateGrids = _.uniq(((this._unpackPositionProperties || {}).GridIntersectionsInProximity || []).map((pair: any) => pair.ItemB));
        const arrPosProp: string[] =  [closestGrid, ...approximateGrids];

        this._clashGridY = [...arrClashes, ...arrRClashes, ...arrPosProp].filter(Boolean);
    }

    private setIsBCF() {
        this._isBCF = _.some(this._unpackInternalProperties.list, { key: 'BcfGuid' });
    }

    private setClashSpace() {
        this._clashSpace = getClashSpace(this._unpackrClashes, this._unpackPositionProperties);
    }

    private setClashZone() {
        this._clashZone = getClashZone(this._unpackrClashes, this._unpackPositionProperties);
    }

    private setClashSourceFile() {
        this._clashSourceFile = geClashSourceFile(this._unpackClashes, this._unpackrClashes);
    }

    private setClashCategory() {
        this._clashCategory = getClashCategory(this._unpackClashes);
    }

    private setClashTest() {
        this._clashTest = getClashTest(this._unpackClashes, this._unpackrClashes);
    }

    private setGrids() {
        this._grids = getClashGrid(this._unpackClashes, this._unpackrClashes, this._unpackPositionProperties);
    }

    private setIsClash() {
        this._isClash = !_.isArray(this._unpackClashes);
    }

    private setIsRClash() {
        this._isRClash = !_.isArray(this._unpackrClashes);
    }

    private setCountClashes() {
        this._countClashes = this._unpackClashes?.items?.length;
    }

    private setCountrClashes() {
        this._countrClashes = this._unpackrClashes?.items?.length;
    }

    private unpackClashes(value: any) {
        if (value?.length === 0) {
            return;
        }

        try {
            if (typeof value.protoBase64 !== 'undefined') {
                this._unpackClashes = Protobuf.decodeClashes(value);

                if (this._unpackClashes.compressMethod === 1 && this._unpackClashes.compressed) {
                    const decompressed: any = Protobuf.decompressClashes(this._unpackClashes.compressed);

                    if (decompressed.strings) {
                        this._unpackClashes.strings = decompressed.strings;
                    }
                    if (decompressed.items) {
                        this._unpackClashes.items = decompressed.items;
                    }
                }
            }
        } catch (e) {
            window.console.warn('Issue.unpackClashes error:', e);
        }
    }

    private unpackRClashes(value: any) {
        if (value?.length === 0) {
            return;
        }

        try {
            if (typeof value.protoBase64 !== 'undefined') {
                this._unpackrClashes = Protobuf.decodeRClashes(value);

                if (this._unpackrClashes.compressMethod === 1 && this._unpackrClashes.compressed) {
                    const decompressed: any = Protobuf.decompressRClashes(this._unpackrClashes.compressed);

                    if (decompressed.strings) {
                        this._unpackrClashes.strings = decompressed.strings;
                    }
                    if (decompressed.items) {
                        this._unpackrClashes.items = decompressed.items;
                    }
                }

            }
        } catch (e) {
            window.console.warn('Issue.unpackRClashes error:', e);
        }
    }

    private unpackPositionProperties(value: string) {
        if (value?.length === 0) {
            return;
        }
        try {
            this._unpackPositionProperties = Protobuf.decodeProperty(value);
        } catch (e) {
            window.console.warn('Issue.unpackPositionProperties error:', e);
        }
    }

    private unpackInternalProperties(value: string) {
        if (value === '') {
            return;
        }

        try {
            this._unpackInternalProperties = Protobuf.decodeInternalProperties(value);

        } catch (e) {
            window.console.warn('Issue.unpackInternalProperties error:', e);
        }
    }
}
