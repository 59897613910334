<template>
    <div v-click-outside="clickOutsideHandler" class="pinned-comments">
        <div class="header">{{ $t('IssueTracker.chat.pinnedMessages') }} ({{ pinnedComments ? pinnedComments.length : 0 }})</div>
        <div class="comments" v-if="pinnedComments && pinnedComments.length > 0">
            <div v-for="group in groupsByDate" :key="group.date">
                <div class="date">
                    {{ dateFormatter(group.date) }}
                </div>
                <IssueComment
                    v-for="(comment, commentIndex) in group.comments"
                    :key="comment.uuid + commentIndex"
                    :comment-index="commentIndex"
                    :comment="comment"
                    is-pinned-format
                    @show-image="scrollToComment(comment.uuid)"
                    @click="scrollToComment(comment.uuid)"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import IssueComment from '@/domain/comment/components/IssueComment.vue';
import { Issue } from '@/domain/issue/models/Issue';
import { groupsByDate, issueDateFormatter } from '@/domain/comment/services/comments';

@Component({
    components: {
        IconSvg24,
        IssueComment,
    },
})
export default class PinnedComments extends Vue {
    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get pinnedComments() {
        return this.currentIssue ? this.$store.getters.pinnedCommentsByIssue(this.projectId, this.currentIssue.uuid) : [];
    }

    get userData() {
        return this.$store.getters.userData;
    }

    get groupsByDate() {
        return groupsByDate(this.pinnedComments);
    }

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    @Emit()
    public close() {
        return;
    }

    public dateFormatter(date: any) {
        return issueDateFormatter(date);
    }

    public scrollToComment(commentUuid: string) {
        this.close();
        this.$router.push({
            name: RouterNames.ProjectIssueTracker,
            params: {
                language: this.language,
                licenseId: String(this.licenseId),
                projectId: String(this.projectId),
            },
            hash: '#' + commentUuid,
        });
    }

    public clickOutsideHandler() {
        this.close();
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/mixins';

.pinned-comments {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    border-radius: $border-radius-default;
    border: $border;
    position: absolute;
    top: 80px;
    left: 16px;
    right: 16px;
    z-index: 1000;
    background: white;
    max-height: 70%;
    overflow-y: scroll;
    @include scrollbar;
}

.header {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: $select-black;
    padding: 14px 24px;
    border-bottom: $border;
    margin: 0 0 14px 0;
}

.comments {
    padding: 0 8px 16px 8px;
}

.date {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    grid-gap: 12px;
    height: 40px;
    font-size: $font-size;
    letter-spacing: 0.0015em;

    &:before, &:after {
        content: ' ';
        border-top: $border;
    }
}
</style>
