<template>
    <div class="filter-item" :class="{ 'active': model, 'filter-item-disabled': disabled }">
        <WsPopover
            v-model="model"
            :width="width"
            :placement="placement"
            :id="id"
            :stop-click-outside-class="stopClickOutsideClass"
            alignment-node="filter-label"
            class="test-f-filter-panel"
            @show="open"
        >
            <template #reference="{ onClick }">
                <WsButton
                    :disabled="disabled"
                    :outlined="false"
                    class="reference"
                    @click="onClick"
                >
                    <div class="filter-label">{{ label }}</div>
                    <span class="max-text">
                        <slot name="button">
                            {{ $t('Button.all') }}
                        </slot>
                    </span>
                    <div
                        class="dropdown-arrow"
                        :class="{ 'up': model }"
                    >
                        <IconSvg16
                            icon-name="arrow-down"
                            stateless
                        />
                    </div>
                    <WsCloseButton
                        v-if="$slots.button"
                        round
                        small
                        @click.stop="clear"
                    />
                </WsButton>
            </template>

            <slot />
        </WsPopover>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import { PopoverPlacement } from '@/constants';
import WsButton from '@/components/common/WsButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import WsPopover from '@/components/common/WsPopover.vue';

@Component({
    components: {
        WsButton,
        IconSvg16,
        WsCloseButton,
        WsPopover,
    },
})
export default class WsTableFilterElement extends Vue {
    @Prop() public value!: boolean;
    @Prop() public label!: VueI18n.TranslateResult;
    @Prop({ default: 'bottom' }) public placement!: PopoverPlacement;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ default: 300 }) public width!: number;
    @Prop() public appendToBody!: boolean;
    @Prop() public id!: string;
    @Prop({ default: '' }) public stopClickOutsideClass!: string;

    get model() {
        return this.value;
    }
    set model(value: boolean) {
        if (!value && this.$store) {
            this.$store.commit('setOpenedFilter', null);
        }
        this.input(value);
    }

    @Emit()
    public open() {
        return;
    }
    @Emit()
    public clear() {
        this.input(false);
        return;
    }
    @Emit()
    public input(value: boolean) {
        return value;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.max-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.reference {
    font-weight: 500 !important;
    color: $select-black;

    ::v-deep .svg:active {
        color: currentColor;
    }

    ::v-deep .svg-icon svg {
        color: currentColor;
    }
}

.dropdown-arrow {
    display: flex;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    margin-left: 5px;

    &.up {
        transform: rotate(180deg);
    }
}

.ws-close-button {
    margin-left: 5px;
}
</style>
