<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8 20C8 16.134 11.2012 13 15.15 13C19.0988 13 22.3 16.134 22.3 20C22.3 16.134 25.7474 13 30 13C34.2526 13 37.7 16.134 37.7 20C37.7 16.134 40.9012 13 44.85 13C48.7988 13 52 16.134 52 20" stroke="#616161" stroke-width="2" />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType8 extends BaseIcon {}
</script>
