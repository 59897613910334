<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7 15H9V17H7V15Z" fill="black" />
        <path d="M11 15H13V17H11V15Z" fill="black" />
        <path d="M15 15H17V17H15V15Z" fill="black" />
        <path d="M19 15H21V17H19V15Z" fill="black" />
        <path d="M23 15H25V17H23V15Z" fill="black" />
        <path d="M27 15H29V17H27V15Z" fill="black" />
        <path d="M31 15H33V17H31V15Z" fill="black" />
        <path d="M35 15H37V17H35V15Z" fill="black" />
        <path d="M39 15H41V17H39V15Z" fill="black" />
        <path d="M43 15H45V17H43V15Z" fill="black" />
        <path d="M47 15H49V17H47V15Z" fill="black" />
        <path d="M51 15H53V17H51V15Z" fill="black" />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType3 extends BaseIcon {}
</script>
