import _ from 'lodash';
import moment from 'moment';
import { IContacts } from '@/types/license/IContacts';
import { IDeferredOperation } from '@/types/license/IDeferredOperation';
import {
    AuthMethodType,
    DateFormatByNumber,
    ExtraFieldsManagementString,
    LicenseClassEnum,
    LicenseTypeEnum,
    MemberRoleEnum,
    RESPONSE,
    UnlimitedNumber,
} from '@/constants';
import { LicenseHighLights, LicenseOwner } from '@/models';
import { mapFilterItems } from '@/services/mapFilterItems';

const defaultContacts: IContacts = {
    billing: [],
    security: [],
};

export class License {
    public readonly id: number;
    public readonly uuid: string;
    public readonly created: string;
    public expires: number;
    public frozen: boolean;
    public frozenReason: number;
    public frozenReasons: number[];
    public logo: string | File;
    public name: string;
    public owner: LicenseOwner;
    public highLights: LicenseHighLights;
    public plan: any;
    public memberMetaTags: string[];
    public projectMetaTags: string[];
    public region: string;
    public role: MemberRoleEnum;
    public sendEmail: number;
    public slots: any;
    public type: number;
    public memberCompanies: string[];
    public memberDepartments: string[];
    public memberLocations: string[];
    public visibleCompany: boolean;
    public visibleDepartment: boolean;
    public visibleLocation: boolean;
    public disallowCloud: boolean;
    public disallowShared: boolean;
    public isValidAuth: boolean;
    public authMethodTitle: string;
    public authMethodType: AuthMethodType;
    public authMethodEntityId: string;
    public authMethodRequire2FA: boolean;
    public licenseClass: number;
    public licenseOptions: {
        allowApiAccess: boolean;
        allowBCFExport: boolean;
        allowBeExternalGuest: boolean;
        allowedExtraFieldsManagement: ExtraFieldsManagementString | null;
        clashAutomation: boolean;
        domainsForAllowBeExternalGuest: string[];
        extraFieldsManagers: string[];
        isWorkflowEnabled: boolean;
        isSeparatedProcoreStatuses: boolean;
    };
    public allowCloud: boolean;
    public allowShared: boolean;
    public userSlots: number;
    public projectSlots: number;

    public deferredOperations: IDeferredOperation[];
    public contacts: IContacts;

    constructor(license: any = {}) {
        this.id = license.id;
        this.uuid = license.uuid;
        this.created = license.created;
        this.expires = NaN;
        if (license.expires) {
            const [year, month, day] = license.expires.split('-');
            // не используется Date.parse ибо сафари не умеет парсить дату х_Х
            // 23 59 ибо иначе парсится начало дня и в последний день лицензия считает себя истекшей
            // month - 1 ибо месяца считаются 0-11, а прилетают как и положено 1-12
            this.expires = Number(new Date(year, month - 1, day, 23, 59));
        }
        this.frozen = license.frozen;
        this.frozenReason = license.frozenReason;
        this.frozenReasons = license.frozenReasons;
        this.logo = license.logo;
        this.name = _.unescape(license.name);
        this.owner = license.owner;
        this.highLights = new LicenseHighLights(license.highLights);
        this.plan = license.plan || {};
        this.slots = license.slots || {};
        this.memberMetaTags = license.memberMetaTags;
        this.projectMetaTags = license.projectMetaTags;
        this.region = license.region;
        this.role = license.role || MemberRoleEnum.none;
        this.sendEmail = license.sendEmail;
        this.type = license.type || LicenseTypeEnum.Regular;
        this.memberCompanies = license.memberCompanies || [];
        this.memberDepartments = license.memberDepartments || [];
        this.memberLocations = license.memberLocations || [];
        this.visibleCompany = Boolean(license.visibleCompany);
        this.visibleDepartment = Boolean(license.visibleDepartment);
        this.visibleLocation = Boolean(license.visibleLocation);
        this.disallowCloud = Boolean(license.disallowCloud);
        this.disallowShared = Boolean(license.disallowShared);
        this.isValidAuth = Boolean(license.isValidAuth);
        this.authMethodTitle = license.authMethod?.title;
        this.authMethodType = license.authMethod?.type;
        this.authMethodEntityId = license.authMethod?.spEntityId;
        this.authMethodRequire2FA = license.authMethod?.require === '2FA';
        this.licenseClass = license.licenseClass || LicenseClassEnum.Standard;
        this.licenseOptions = license.licenseOptions || {
            allowBeExternalGuest: true,
            clashAutomation: false,
            domainsForAllowBeExternalGuest: [],
        };
        const deferredOperations = Array.isArray(license.deferredOperations) ? license.deferredOperations : [];
        this.deferredOperations = deferredOperations.map((deferred: any) => ({
            ...deferred,
            executeAt: moment.utc(deferred.executeAt).local().format(DateFormatByNumber['6']),
        }));
        this.allowCloud = !license.disallowCloud;
        this.allowShared = !license.disallowShared;
        this.userSlots = license.slots?.users;
        this.projectSlots = license.slots?.projects;
        this.contacts = license.contacts || defaultContacts;
    }

    public static instantiate(license: any): License {
        return new License(license);
    }

    get limitSlotsOfUsers() { return this.plan.users === UnlimitedNumber ? Infinity : this.plan.users; }
    get limitSlotsOfProjects() { return this.plan.projects === UnlimitedNumber ? Infinity : this.plan.projects; }
    get usedSlotsOfProjects() { return this.plan.projects - this.slots.projects; }

    get filterItems() {
        return _.mapValues({
            membersMetaTags: this.memberMetaTags,
            memberCompanies: this.memberCompanies,
            memberDepartments: this.memberDepartments,
            memberLocations: this.memberLocations,
        }, mapFilterItems);
    }

    get isExpired() { return new Date().getTime() > this.expires; }
    get isTeamExceeded() { return this.slots.users < 0; }
    get isSpaceExceeded() { return this.slots.projects < 0; }
    get isFrozen() { return this.isTeamExceeded || this.isSpaceExceeded || this.isExpired; }

    get isBlocked() {
        return this.frozenReason === RESPONSE.LICENSE_BLOCKED;
    }

    get isReviztoPlus() { return this.licenseClass === LicenseClassEnum.ReviztoPlus; }

    // License rights
    get isSuperAdminRights() { return this.role >= MemberRoleEnum.superAdmin; }
    get isAdminRights() { return this.role >= MemberRoleEnum.admin; }
    get isGuestRights() { return this.role >= MemberRoleEnum.guest; }
    get isCreatorRights() { return this.role >= MemberRoleEnum.creator; }

    get isSeparatedProcoreStatuses() {
        return this.licenseOptions?.isSeparatedProcoreStatuses;
    }

    get isAllAdditionalFieldsOff() {
        return !this.visibleCompany && !this.visibleDepartment && !this.visibleLocation;
    }

    get isWorkflowEnabled() {
        return this.licenseOptions?.isWorkflowEnabled;
    }

    public setHighlights(highlights: any) {
        this.highLights = new LicenseHighLights(highlights);
    }

    private isMatch(searchString: string) {
        return !searchString || this.name.toLowerCase().includes(searchString.toLowerCase());
    }

    public static getFilterPredicate(searchString: string) {
        return (license: License) => license.isMatch(searchString);
    }
}
