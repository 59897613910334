<template>
    <IssueDetailsItem
        v-if="showIssueDetail"
        :label="label"
        :multi-text="showFieldMultipleText"
        :collapsable="collapsable"
        :collapsing-key="collapsingKey"
        :is-show-warning-icon="isShowWarningIcon"
        @on-click-on-warning-icon="showDialogWithWarningDescription"
    >
        <span
            v-for="(item, index) in currentValue"
            :key="index"
            class="filter-link amp-mask"
            @click="click(item)"
        >
            {{ item }}{{ getCommaForListItem(currentValue, index) }}
        </span>
    </IssueDetailsItem>
</template>

<script lang="ts">
import { Component, Emit } from 'vue-property-decorator';
import { LOCAL_STORAGE_GRID_KEY } from '@/constants';
import { MAX_NOT_COLLAPSED_LENGTH } from '@/constants/IssueTracker';
import { Issue } from '@/domain/issue/models/Issue';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';
import IssueDetailsClashSimple from '@/domain/issue/components/details/IssueDetailsClashSimple.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsClashGrid extends IssueDetailsClashSimple {
    public readonly collapsingKey = LOCAL_STORAGE_GRID_KEY;

    get joinedList(): string {
        return this.currentValue?.join(this.$t('Punctuation.comma'));
    }

    get collapsable() {
        return this.stringLongerThanPossible(this.joinedList) || this.currentValue?.length > 2;
    }

    @Emit()
    public click(value: string) {
        return { value, type: IssuesFilterType.clashGridX as keyof Issue };
    }

    public stringLongerThanPossible(string: string) {
        if (!string) {
            return false;
        }
        return string.length > MAX_NOT_COLLAPSED_LENGTH;
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.pointer {
    cursor: pointer;
}
</style>
