import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { ReleaseNotesApi } from '@/api';
import { ReleaseNote } from '@/models';

interface IReleaseNotesState {
    notes: Dict<ReleaseNote>;
    chosenReleaseNoteId: number | null;
    isReleaseNotesLoading: boolean;
    isReleaseNoteContentLoading: boolean;
}

export default {
    state: {
        notes: {},
        isReleaseNotesLoading: false,
        isReleaseNoteContentLoading: false,
        chosenReleaseNoteId: null,
    } as IReleaseNotesState,
    getters: {
        releaseNotes(state: IReleaseNotesState) {
            return _.values(state.notes).sort((a, b) => {
                const aDate = new Date(a.releaseMonth).getTime();
                const bDate = new Date(b.releaseMonth).getTime();
                return bDate !== aDate ? bDate > aDate ? -1 : 1 : 0;
            });
        },
        getChosenReleaseNote(state: IReleaseNotesState) {
            if (!state.chosenReleaseNoteId) return null;
            return state.notes[state.chosenReleaseNoteId] || null;
        },
        chosenReleaseNoteId(state: IReleaseNotesState) {
            return state.chosenReleaseNoteId;
        },
        isReleaseNotesLoading(state: IReleaseNotesState) {
            return state.isReleaseNotesLoading;
        },
        isReleaseNoteContentLoading(state: IReleaseNotesState) {
            return state.isReleaseNoteContentLoading;
        },
        lastReleaseNotesTimeStamp(state: IReleaseNotesState) {
            const releaseTimeArray = _.values(state.notes).map(({ notificationDate }) => {
                return new Date(notificationDate).getTime();
            });

            if (!releaseTimeArray.length) {
                return 0;
            }

            return Math.max(...releaseTimeArray);
        },
    },
    mutations: {
        setReleaseNotes(state: any, notes: ReleaseNote[]) {
            state.notes = _.keyBy(notes, 'id');
        },
        setReleaseNoteContent(state: any, { releaseNote }: { releaseNote: ReleaseNote }) {
            state.notes[releaseNote.id] = releaseNote;
        },
        setIsReleaseNotesLoading(state: any, isloading: boolean) {
            state.isReleaseNotesLoading = isloading;
        },
        setIsReleaseNoteContentLoading(state: any, isloading: boolean) {
            state.isReleaseNoteContentLoading = isloading;
        },
        setChosenNoteId(state: any, noteId: number | null) {
            state.chosenReleaseNoteId = noteId;
        },
    },
    actions: {
        downloadReleaseNotes({ state, commit }: any) {
            if (!_.isEmpty(state.notes)) {
                return;
            }

            commit('setIsReleaseNotesLoading', true);
            return new Promise<void>((resolve, reject) => {
                ReleaseNotesApi.getReleaseNotes()
                    .then((response: any) => {
                        commit('setReleaseNotes', response.map((releaseNoteData: any) => new ReleaseNote(releaseNoteData)));
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setIsReleaseNotesLoading', false);
                    });
            });
        },
        downloadReleaseNoteContent({ state, commit }: any, noteId: string) {
            commit('setChosenNoteId', noteId);

            if (state.notes?.[noteId]?.content.length) {
                return;
            }
            commit('setIsReleaseNoteContentLoading', true);
            return new Promise<void>((resolve, reject) => {
                ReleaseNotesApi.getReleaseNoteContent(noteId)
                    .then((response: any) => {
                        commit('setReleaseNoteContent', { releaseNote: new ReleaseNote(response) });
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit('setIsReleaseNoteContentLoading', false);
                    });
            });
        },
    },
};
