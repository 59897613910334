<template>
    <IssueColumn
        :title="$t('IssueTracker.headers.chat')"
        :collapsed="collapsed"
        :component-name="$options.name"
        :hide-title="hideTitle"
        :has-issues-checked-content="true"
        :is-hide-actions="!isAllowLoadingContent"
        class="tracker-chat"
        icon="chat"
    >
        <template #actions>
            <ChatFilterActions v-if="isCurrentIssueExist" />
        </template>

        <div class="chat-scroll">
            <div>
                <template v-if="isOpenChatSearch">
                    <div class="chat-search">
                        <WsInputSearch
                            v-model="searchQuery"
                            :placeholder="$t('Text.search')"
                            v-focus
                            @clear="cancelSearch"
                        />
                    </div>
                </template>

                <ChatFilter />
            </div>

            <IssueCommentList
                :loading="isShowLoading"
                class="chat-messages"
                @all-items-mounted="onAllChatItemsMounted"
            />

            <IssueCommentAdd v-if="isAllowLoadingContent && !isCloseChatCommentAdd && !isDeleted && isCurrentIssueExist && !isLoadingComments" />
        </div>

        <template v-if="isMultiselectEditModeActive && !collapsed" #closed-background>
            <div class="tracker-chat__multiselect-actions">
                <p class="text-center">
                    {{ $t('IssueTracker.chat.selectedCount_plural', { count: countSelectedIssues }) }}
                </p>
                <WsButton
                    icon="x-big"
                    icon-size="24"
                    @click="onClearSelection"
                >
                    {{ $t('IssueTracker.chat.clearSelections') }}
                </WsButton>
                <WsButton
                    v-if="!isDeleted"
                    icon="send"
                    icon-size="24"
                    @click="openMultiCommentModal"
                >
                    {{ $t('IssueTracker.multiMessage.buttonName') }}
                </WsButton>
            </div>
        </template>
    </IssueColumn>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AmplitudeEvent, BusEvent } from '@/constants';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, isFiltersForDeletedIssues } from '@/services';
import AutofocusMixin from '@/mixins/autofocusMixin';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';
import IssueColumn from '@/components/project/issueTracker/IssueColumn.vue';
import TrackerColumnComponentBase from '@/components/project/issueTracker/columns/TrackerColumnComponentBase.vue';
import ChatFilter from '@/components/project/issueTracker/ChatFilter.vue';
import ChatFilterActions from '@/components/project/issueTracker/ChatFilterActions.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsInputSearch from '@/components/common/WsInputSearch.vue';
import WsButton from '@/components/common/WsButton.vue';
import IssueCommentAdd from '@/domain/comment/components/IssueCommentAdd.vue';
import IssueCommentList from '@/domain/comment/components/IssueCommentList.vue';
import { TrackerFilters } from '@/domain/issueFilter/types/ProjectIssuesFilters';
import { AbstractComment } from '@/domain/comment/models/AbstractComment';
import { Issue } from '@/domain/issue/models/Issue';

@Component({
    name: 'TrackerChat',
    components: {
        WsSkeletonLoader,
        WsButton,
        ChatFilter,
        IssueColumn,
        IssueCommentList,
        IssueCommentAdd,
        ChatFilterActions,
        WsInputSearch,
        IconSvg24,
    },
})
export default class TrackerChat extends Mixins(AutofocusMixin, TrackerColumnComponentBase) {
    public searchQueryPrivate = this.filters.search;
    public allChatItemsMounted = false;

    get isLoadingComments() {
        return this.$store.getters.isLoadingComments;
    }

    get isCloseChatCommentAdd() {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get isOpenChatSearch(): boolean {
        return this.$store.getters.isChatSearchOpen;
    }

    get searchQuery() {
        return this.searchQueryPrivate;
    }

    set searchQuery(value) {
        this.setSearchQueryDebounced(value);
    }

    get setSearchQueryDebounced() {
        return _.debounce((value: string) => {
            this.$store.commit('setCommentsSearchValue', value);
            amplitudeLog(AmplitudeEvent.searchComments, { value });
        }, 350);
    }

    get filters(): any {
        return this.$store.getters.commentsFilters;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get project() {
        return this.$store.getters.projectById(this.projectId);
    }

    get isCurrentIssueExist() {
        return this.$store.getters.selectedIssueByProjectId(this.projectId) !== undefined;
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get currentIssueComments(): AbstractComment[] {
        return this.currentIssue
            ? this.$store.getters.commentsByIssue(this.projectId, this.currentIssue.uuid)
            : [];
    }

    get trackerFilters(): TrackerFilters {
        return this.$store.getters.trackerFiltersByProjectId(this.projectId);
    }

    get isDeleted() {
        return isFiltersForDeletedIssues(this.trackerFilters);
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.multiSelectedIssues.length > 1;
    }

    get countSelectedIssues() {
        return this.$store.getters.multiSelectedIssues.length;
    }

    get isShowLoading() {
        if (!this.isAllowLoadingContent || this.isLoadingComments || !this.isCurrentIssueExist) {
            return true;
        }

        if (!this.currentIssueComments.length) {
            return false;
        }

        return !this.allChatItemsMounted;
    }

    @Watch('currentIssue', { immediate: true, deep: true })
    public currentIssueChanged() {
        this.resetAll();
    }

    public reset() {
        this.$store.commit('resetChatFilters');
    }

    public resetAll() {
        this.reset();
        this.$store.commit('setChatFiltersOpen', false);
        this.cancelSearch();
    }

    public cancelSearch() {
        if (this.isOpenChatSearch) {
            this.filters.search = '';
            this.searchQueryPrivate = '';
            this.$store.commit('setCommentSearchOpen', false);
        }
    }

    public onAllChatItemsMounted() {
        this.allChatItemsMounted = true;
        this.emitContentLoadedEvent();
    }

    public onClearSelection() {
        this.$store.dispatch('setMultiSelectEditMode', false);
    }

    public openMultiCommentModal() {
        eventBus.$emit(BusEvent.showMultiIssueComment);
        amplitudeLog(AmplitudeEvent.itOpenMulticommentFromChat, { count: this.countSelectedIssues });
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.tracker-chat {
    min-width: 263px;
}

.tracker-chat ::v-deep .tracker-chat__multiselect-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    justify-content: center;

    p {
        margin-bottom: 0;
    }

    .ws-button.v-btn + .ws-button.v-btn {
        margin-left: 0;
    }
}

.chat-scroll {
    display: grid;
    position: relative;
    grid-template-rows: auto 1fr auto;
    @include scrollbar;
}

.chat-messages {
    @include scrollbar;

    overflow: auto;
}

.chat-search {
    display: flex;
    padding: $container-padding;
    border-bottom: $border;
}
</style>
