<template>
    <div id="comments" class="issue-comment-list">
        <div v-if="loading" class="issue-comment-list__skeleton-wrapper">
            <WsSkeletonLoader content="chat-message" />
        </div>

        <div v-if="!loading && !groupsByDate.length" class="no-messages">
            {{ $t('IssueTracker.chat.noMessages') }}
        </div>

        <template v-for="(group, groupIndex) in groupsByDate">
            <span
                v-if="groupIndex === 0"
                :key="'top-spacer' + groupIndex"
                class="top-spacer"
            />
            <span :key="`divider-${group.date}`" class="comments-divider" />
            <div :key="group.date" class="comments-group-date">
                <span>
                    {{ dateFormatter(group.date).toUpperCase() }}
                </span>
            </div>
            <IssueComment
                v-for="(comment, commentIndex) in group.comments"
                :key="comment.uuid"
                :comment-index="commentIndex"
                :comment="comment"
                @show-image="showImage(groupIndex, commentIndex)"
                @mounted="issueCommentMounted"
            />
        </template>

        <ImageViewer
            v-if="imageComment"
            :comment="imageComment"
            @close="closeImage"
            @next="showNextImage"
            @prev="showPrevImage"
        />
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { ColorizeHtml, sleep } from '@/services';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';
import ImageViewer from '@/components/project/issueTracker/ImageViewer.vue';
import IssueComment from '@/domain/comment/components/IssueComment.vue';
import { Issue } from '@/domain/issue/models/Issue';
import { AbstractComment } from '@/domain/comment/models/AbstractComment';
import { MarkupComment } from '@/domain/comment/models/MarkupComment';
import { FileComment } from '@/domain/comment/models/FileComment';
import { DiffComment } from '@/domain/comment/models/DiffComment';
import {
    IssueTrackerDiffInlineProperties,
    IssueTrackerDiffMultilineProperties, IssueTrackerDiffSpecialProperties,
} from '@/domain/comment/models/IssueTrackerDiffProperties';
import { groupsByDate, issueDateFormatter } from '@/domain/comment/services/comments';

const DatesStickyTimeoutMS = 1000;

@Component({
    components: {
        WsSkeletonLoader,
        ImageViewer,
        IssueComment,
    },
})
export default class IssueCommentList extends Vue {
    @Prop({ type: Boolean, default: false }) public loading!: boolean;
    public commentsList: any = {};
    public imageComment: any = null;
    private mountedComments: number = 0;
    private smoothLoadingTimeoutID: number | null = null;
    private datesStickyTimeoutID = 0;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get projectMembers() {
        return this.$store.getters.projectMembersByProjectId(this.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get filters(): any {
        return this.$store.getters.commentsFilters;
    }

    get userData() {
        return this.$store.getters.userData;
    }

    get currentIssueComments(): AbstractComment[] {
        return this.currentIssue
            ? this.$store.getters.commentsByIssue(this.projectId, this.currentIssue.uuid)
            : [];
    }

    get pendingIssueComments(): AbstractComment[] {
        return this.currentIssue
            ? this.$store.getters.pendingCommentsByIssue(this.projectId, this.currentIssue.uuid)
            : [];
    }

    get allFilteredComments(): AbstractComment[] {
        return [...this.currentIssueComments, ...this.pendingIssueComments].filter(this.filterComments);
    }

    get groupsByDate() {
        return groupsByDate(this.allFilteredComments);
    }

    get allProjectIssues() {
        return this.$store.getters.allIssuesByProjectId(this.projectId);
    }

    @Watch('allProjectIssues', { deep: true, immediate: true })
    public async allProjectIssuesChanged(newValue: any, oldValue: any) {
        if (!oldValue || !this.currentIssue) {
            return;
        }

        const currentIssueIndex = newValue.findIndex((issue: any) => issue?.uuid === this.currentIssue.uuid);

        if (currentIssueIndex === -1) {
            this.$store.commit('setCommentsForIssue', {
                projectId: this.projectId,
                issueUuid: this.currentIssue.uuid,
                comments: [],
                fromRequest: false,
            });
        }
    }

    @Watch('groupsByDate', { deep: true, immediate: true })
    public async commentsChanged() {
        await sleep(300);
        if (!this.$route.hash) {
            this.commentsList.scrollTop = this.commentsList.scrollHeight;
        }
    }

    @Watch('filters.search')
    public colorizeHtml(newVal: string, oldVal: string) {
        setTimeout(() => {
            const colorize = new ColorizeHtml(this.filters.search, '#comments .highlighting');
            if (oldVal.length >= 3) {
                colorize.clean();
            }
            if (this.filters.search.length >= 3) {
                colorize.highlight();
            }
        }, 500);
    }

    @Watch('loading', { immediate: true })
    public loadingChanged(loading: boolean) {
        if (loading) {
            return;
        }

        if (!this.allFilteredComments.length) {
            this.allItemsMounted();
        } else {
            // In case when an attachments fall to load or load very slowly, we should show others comments
            this.smoothLoadingTimeoutID = Number(setTimeout(() => {
                if (this.mountedComments !== this.allFilteredComments.length) {
                    this.mountedComments = this.allFilteredComments.length;
                    this.smoothLoadingTimeoutID = null;
                    this.allItemsMounted();
                }
            }, 2000));
        }
    }

    @Emit('all-items-mounted')
    public allItemsMounted() {
        return;
    }

    public mounted() {
        this.commentsList = document.querySelector('.tracker-chat .column-body .chat-messages');

        this.watchScrollForStickyDates();
    }

    public beforeDestroy() {
        if (this.smoothLoadingTimeoutID) {
            clearTimeout(this.smoothLoadingTimeoutID);
        }

        this.stopDatesStickyTimeout();
    }

    public dateFormatter(date: any) {
        return issueDateFormatter(date);
    }

    public showImage(groupIndex: number, commentIndex: number) {
        const imageComment: any = this.groupsByDate[groupIndex].comments[commentIndex];
        if (!(imageComment instanceof MarkupComment) || this.currentIssue.isDeleted) {
            this.imageComment = imageComment;
        } else {
            // find background for markup
            const commentIndexAtAll = this.currentIssueComments.findIndex(({ uuid }: any) => uuid === imageComment.uuid);
            const issuesWithSnapshot = this.currentIssueComments.slice(commentIndexAtAll, this.currentIssueComments.length);
            const issueWithSnapshot: any = issuesWithSnapshot.filter(({ diff }: any) => diff?.snapshot)[0];
            this.$store.commit('setMarkupEditorContent', imageComment.markup || []);
            this.$store.commit('setMarkupEditorBackground', {
                small: issueWithSnapshot?.diff?.snapshot?.old?.links?.small ?? this.currentIssue.preview?.small,
                original: issueWithSnapshot?.diff?.snapshot?.old?.links?.original ?? this.currentIssue.preview?.original,
            });
            this.$store.commit('setMarkupEditorPreviousBackground', {
                small: this.currentIssue.preview?.small,
                original: this.currentIssue.preview?.original,
            });

            this.$store.commit('setMarkupEditorIsOpen', true);
            this.$store.commit('setMarkupEditorBackgroundUpdated', true);
        }
    }

    public closeImage() {
        this.imageComment = null;
    }

    public showNextImage() {
        let isCurrentCommentBehind = false;
        let isNextCommentSelected = false;
        this.groupsByDate.forEach((group: any) => {
            group.comments.forEach((comment: any) => {
                if (comment instanceof FileComment) {
                    if (!isNextCommentSelected) {
                        if (isCurrentCommentBehind) {
                            this.imageComment = comment;
                            isNextCommentSelected = true;
                        }
                        if (this.imageComment.uuid === comment.uuid) {
                            isCurrentCommentBehind = true;
                        }
                    }
                }
            });
        });
    }

    public showPrevImage() {
        let isCurrentCommentBehind = false;
        let isNextCommentSelected = false;
        _.clone(this.groupsByDate).reverse().forEach((group: any) => {
            _.clone(group.comments).reverse().forEach((comment: any) => {
                if (comment instanceof FileComment) {
                    if (!isNextCommentSelected) {
                        if (isCurrentCommentBehind) {
                            this.imageComment = comment;
                            isNextCommentSelected = true;
                        }
                        if (this.imageComment.uuid === comment.uuid) {
                            isCurrentCommentBehind = true;
                        }
                    }
                }
            });
        });
    }

    public filterComments(comment: AbstractComment) {
        const isDiff = comment instanceof DiffComment;
        return !isDiff || _.intersection(
            (comment as DiffComment)?.diff ? Object.keys((comment as DiffComment)?.diff) : [],
            [
                ...IssueTrackerDiffInlineProperties,
                ...IssueTrackerDiffMultilineProperties,
                ...IssueTrackerDiffSpecialProperties,
            ],
        ).length > 0;
    }

    public issueCommentMounted() {
        this.mountedComments++;

        if (this.mountedComments !== this.allFilteredComments.length) {
            return;
        }

        this.commentsList.scrollTop = this.commentsList.scrollHeight;

        this.$nextTick(() => {
            this.allItemsMounted();
        });
    }

    private getDatesElements() {
        return document.querySelectorAll('.comments-group-date');
    }

    private stopDatesStickyTimeout() {
        clearTimeout(this.datesStickyTimeoutID);
    }

    private watchScrollForStickyDates() {
        this.commentsList.onscroll = () => {
            if (this.datesStickyTimeoutID) {
                this.stopDatesStickyTimeout();
            }

            this.getDatesElements().forEach((dateEl) => {
                dateEl.classList.add('comments-group-date__sticky');
            });

            this.datesStickyTimeoutID = Number(setTimeout(() => {
                this.getDatesElements().forEach((dateEl) => {
                    dateEl.classList.remove('comments-group-date__sticky');
                });
            }, DatesStickyTimeoutMS));
        };
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.issue-comment-list__skeleton-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $white;
    z-index: 2;
}

.no-messages {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: $light-solid-80;
}

$date-divider-padding: 9px;

.comments-group-date {
    position: relative;
    display: block;
    text-align: center;
    height: 40px;
    padding: 0 $date-divider-padding $date-divider-padding;
    font-size: 10px;
    letter-spacing: 0.0015em;
    z-index: 1;

    > span {
        display: inline-block;
        background: white;
        padding: 2px 12px;
        min-width: 120px;
        text-align: center;
        border-radius: 16px;
    }

    &__sticky {
        position: sticky;
        top: 7px;
    }
}

.comments-divider {
    position: relative;
    bottom: -10px;
    display: block;
    border-top: $border;
}

.top-spacer {
    display: block;
    height: $date-divider-padding;
}
</style>
