// @ts-ignore
import settings from 'reviztoSettings';
// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { ActionContext, Module } from 'vuex';
import _ from 'lodash';
import { IUserInfo } from '@/types/UserInfo';
import { Dict } from '@/types/Dict';
import Router from '@/router';
import {
    AppSSOKeyName,
    appSSOKeyNamesList,
    BusEvent,
    DateFormat,
    RESPONSE,
    RouterNames,
    SAMLCookie,
    SAMLCookieNamesList,
} from '@/constants';
import http from '@/api/http';
import AuthHttp from '@/api/authHttp';
import UserApi from '@/api/user.api';
import { httpParams } from '@/api/httpParams';
import { AppSSO, IActiveSessions, IUserSettings, ProtoLicense } from '@/models';
import { LoginLicense } from '@/models/SSO/LoginLicense';
import { setDateFormatterSettings } from '@/services/MomentFormats';
import { RecentSelectedUsersService } from '@/services/RecentSelectedUsers';
import { eventBus } from '@/services/eventBus';
import { CookieHelper, dateTimeFormatter, RandomService, SSOKey } from '@/services';

export interface IBrowserTimezone {
    timezone: string;
    offset: string;
}

interface IAuthStorage {
    user: IUserInfo;
    promiseUserData: any;
    isLoadingUserData: boolean;
    isSendingUserData: boolean;
    isSendingAvatar: boolean;
    isUpdatingTwoFactorMethod: boolean;
    isOauthChecking: boolean;
    authError: string;
    isShowBoardingWelcome: boolean;
    isShowBoardingSteps: boolean;
    isLoadingTimezones: boolean;
    companyNews: {
        title?: string;
        description?: string;
        linkHref?: string;
        linkText?: string;
        imageUrl?: string;
        color?: string;
        backgroundColor?: string;
    };
    isLoadingCompanyNews: boolean;
    oauth: {
        [serviceName: string]: {
            url: string;
        },
    };
    lastAuth: string;
    activeSessions: IActiveSessions | null;
    isLoadingActiveSessions: boolean;
    timezones: Dict<string>,
    browserTimezone: IBrowserTimezone,
}

const prepareSessions = (sessions: IActiveSessions): IActiveSessions => {
    return {
        ...sessions,
        currentSessions: sessions.currentSessions.map((session) => {
            return {
                ...session,
                updatedAt:  session?.updatedAt ? dateTimeFormatter(session.updatedAt, true, DateFormat['YYYY-MM-DD']) : '',
            };
        }),
    };
};

export default {
    state: {
        user: http.getUserInfo(),
        promiseUserData: null,
        isLoadingUserData: false,
        isSendingUserData: false,
        isSendingAvatar: false,
        isUpdatingTwoFactorMethod: false,
        isOauthChecking: false,
        authError: '',
        isShowBoardingWelcome: false,
        isShowBoardingSteps: false,
        companyNews: {},
        isLoadingCompanyNews: false,
        isLoadingTimezones: false,
        oauth: {
            google: {
                url: '',
            },
        },
        lastAuth: CookieHelper.get('lastAuth') || 'login',
        activeSessions: {
            appLimit: NaN,
            currentSessions: [],
        },
        isLoadingActiveSessions: false,
        timezones: {},
        browserTimezone: {
            timezone: '',
            offset: '',
        },
    } as IAuthStorage,
    getters: {
        user(state: IAuthStorage) {
            return state.user;
        },
        userData(state: IAuthStorage) {
            return state.user.data;
        },
        userTimezones(state: IAuthStorage) {
            return state.timezones;
        },
        deviceId(state: IAuthStorage) {
            return state.user.device_id;
        },
        isAuthenticate(state: IAuthStorage): boolean {
            return Boolean(state.user.key);
        },
        currentCountry(state: IAuthStorage): string {
            return state.user.country;
        },
        currentLanguage(state: IAuthStorage): string { // todo это не currentLanguage, а userLanguage (язык выбранных в настройках профиля); currentLanguage - это выбранный язык на сайте в данный момент
            return state.user.language.toLowerCase();
        },
        authError(state: IAuthStorage): string {
            return state.authError;
        },
        isLoadingUserData(state: IAuthStorage): boolean {
            return state.isLoadingUserData;
        },
        isSendingUserData(state: IAuthStorage): boolean {
            return state.isSendingUserData;
        },
        isSendingAvatar(state: IAuthStorage): boolean {
            return state.isSendingAvatar;
        },
        isUpdatingTwoFactorMethod(state: IAuthStorage): boolean {
            return state.isUpdatingTwoFactorMethod;
        },
        isOauthChecking(state: IAuthStorage): boolean {
            return state.isOauthChecking;
        },
        isLoadingTimezones(state: IAuthStorage): boolean {
            return state.isLoadingTimezones;
        },
        baseURLbyRegionId(state: IAuthStorage, getters: any) {
            return (regionId: string) => {
                const region = getters.regionById(regionId);
                return `${region.services.api.protocol}://${region.services.api.host}/${httpParams.version}`;
            };
        },
        baseURL(state: IAuthStorage, getters: any): string {
            const region = getters.regionById(getters.regionId);
            return `${region.services.api.protocol}://${region.services.api.host}/${httpParams.version}`;
        },
        isShowBoardingWelcome(state: IAuthStorage): boolean {
            return state.isShowBoardingWelcome;
        },
        isShowBoardingSteps(state: IAuthStorage): boolean {
            return state.isShowBoardingSteps;
        },
        companyNews(state: IAuthStorage): any {
            return state.companyNews;
        },
        isLoadingCompanyNews(state: IAuthStorage): boolean {
            return state.isLoadingCompanyNews;
        },
        oauth(state: IAuthStorage) {
            return state.oauth;
        },
        lastAuth(state: IAuthStorage) {
            return state.lastAuth;
        },
        isLoadingActiveSessions(state: IAuthStorage) {
            return state.isLoadingActiveSessions;
        },
        activeSessions(state: IAuthStorage) {
            return state.activeSessions;
        },
        appSSO() {
            return new AppSSO({
                key: CookieHelper.get(AppSSOKeyName.app_sso_key),
                region: CookieHelper.get(AppSSOKeyName.app_sso_region),
                merge: CookieHelper.get(AppSSOKeyName.app_sso_merge),
                licenseUuid: CookieHelper.get(AppSSOKeyName.app_sso_license_uuid),
            });
        },
        getAppSSO() {
            return () => new AppSSO({
                key: CookieHelper.get(AppSSOKeyName.app_sso_key),
                region: CookieHelper.get(AppSSOKeyName.app_sso_region),
                merge: CookieHelper.get(AppSSOKeyName.app_sso_merge),
                licenseUuid: CookieHelper.get(AppSSOKeyName.app_sso_license_uuid),
            });
        },
        SAMLData() {
            return {
                authNRequestId: CookieHelper.get(SAMLCookie.authNRequestId),
                transitionalAuth: CookieHelper.get(SAMLCookie.transitionalAuth),
                SAMLResponse: CookieHelper.get(SAMLCookie.SAMLResponse),
            };
        },
        browserTimezone(state: IAuthStorage) {
            return state.browserTimezone;
        },
    },
    mutations: {
        setCurrentLanguage(state: IAuthStorage, language: string) {
            state.user.language = language;
            CookieHelper.set('language', language);
            http.setUser(state.user);
        },
        setUserKey(state: IAuthStorage, key: string = '') {
            state.user.key = key;
            CookieHelper.set('key', key);
            http.setUser(state.user);
        },
        setDeviceId(state: IAuthStorage, deviceId: string) {
            state.user.device_id = deviceId;
            CookieHelper.set('device_id', deviceId);
            http.setUser(state.user);
        },
        setAuthError(state: IAuthStorage, error: string = '') {
            state.authError = error;
        },
        setUserData(state: IAuthStorage, data: any) {
            state.user.data = data;
            http.setUser(state.user);
            setDateFormatterSettings(data.dateFormat, data.timeFormat);
        },
        setIsLoadingUserData(state: IAuthStorage, value: boolean) {
            state.isLoadingUserData = value;
        },
        setIsSendingUserData(state: IAuthStorage, value: boolean) {
            state.isSendingUserData = value;
        },
        setIsSendingAvatar(state: IAuthStorage, value: boolean) {
            state.isSendingAvatar = value;
        },
        setIsUpdatingTwoFactorMethod(state: IAuthStorage, value: boolean) {
            state.isUpdatingTwoFactorMethod = value;
        },
        setIsOauthChecking(state: IAuthStorage, value: boolean) {
            state.isOauthChecking = value;
        },
        setIsLoadingTimezones(state: IAuthStorage, value: boolean) {
            state.isLoadingTimezones = value;
        },
        setIsShowBoardingWelcome(state: IAuthStorage, value: boolean = true) {
            state.isShowBoardingWelcome = value;
        },
        setIsShowBoardingSteps(state: IAuthStorage, value: boolean = true) {
            state.isShowBoardingSteps = value;
        },
        setCompanyNews(state: IAuthStorage, data: any) {
            state.companyNews = data;
        },
        setTimezones(state: IAuthStorage, data: any) {
            state.timezones = data;
        },
        setIsLoadingCompanyNews(state: IAuthStorage, value: boolean) {
            state.isLoadingCompanyNews = value;
        },
        setBrowserTimezone(state: IAuthStorage, value: IBrowserTimezone) {
            state.browserTimezone = value;
        },
        setLastAuth(state: IAuthStorage, value?: string) {
            if (value) {
                state.lastAuth = value;
                CookieHelper.set('lastAuth', value);
            }
        },
        setIsLoadingActiveSessions(state: IAuthStorage, value: boolean) {
            state.isLoadingActiveSessions = value;
        },
        setActiveSessions(state: IAuthStorage, value: IActiveSessions) {
            state.activeSessions = value;
        },
        setSSOCookies(_state: IAuthStorage, query: any) {
            appSSOKeyNamesList.forEach((key: string) => VueCookies.set(
                key,
                query[key],
                '20m',
                undefined,
                '.' + settings.baseHost,
            ));
        },
        removeSSOCookies() {
            appSSOKeyNamesList.forEach((key: string) => {
                VueCookies.remove(key);
                VueCookies.remove(key, undefined, '.' + settings.baseHost);
            });
        },
        removeSAMLCookies() {
            SAMLCookieNamesList.forEach((cookieName: string) => {
                VueCookies.remove(cookieName);
                VueCookies.remove(cookieName, undefined, '.' + settings.baseHost);
            });
        },
    },
    actions: {
        async logout({ state, commit, dispatch }: any, { isGoToLogin = false, region = '', query } = {}) {
            // проверка-подпорка для случая когда залогиненный юзер открывает страницу регистрации, чтобы не редиректило с нее
            if (state.user.key) {
                try {
                    await UserApi.postLogout();
                } finally {
                    dispatch('disconnectNotifier'); // нужно вызывать до resetRootStore, иначе потеряется intervalId
                    eventBus.$emit(BusEvent.clearAllNotifier);
                    commit('setUserKey');
                    commit('resetRootStore');
                    RecentSelectedUsersService.reset();
                }
            }

            if (isGoToLogin) {
                const paramsObj = region ? { params: { region } } : {};
                const queryObj = query ? { query } : {};
                Router.push({ name: RouterNames.Login, ...paramsObj, ...queryObj });
            }
        },
        async authorizeApp(
            { state, getters, dispatch }: ActionContext<IAuthStorage, any>,
            { email, password, merge, app_sso_key, app_sso_region },
        ) {
            const baseURL = app_sso_region ? getters.baseURLbyRegionId(app_sso_region) : getters.baseURL;
            await dispatch('downloadRegion', { login: email, merge });
            const credentials = { login: email, password, merge };
            const response = await AuthHttp.authApp({ ...credentials, app_sso_key }, state.user.device_id, baseURL);

            if (response.result === RESPONSE.SUCCESS) {
                return response.data;
            } else {
                throw response;
            }
        },
        async authorize({ state, dispatch, getters }: ActionContext<IAuthStorage, any>, { email, password, merge = 0 }: any): Promise<any> {
            await dispatch('downloadRegion', { login: email, merge });
            const credentials = { login: email, password, merge };
            const response = await AuthHttp.auth(credentials, state.user.device_id, getters.baseURL);

            switch (response.result) {
                case (RESPONSE.SECURITY_ERROR):
                    Router.push({
                        name: RouterNames.ChangePassword,
                        params: {
                            uid: response.data.uid,
                            token: response.data.token,
                        },
                    });
                    throw response;
                case (RESPONSE.SUCCESS):
                    if (response.data.token) {
                        await dispatch('successAuth', {
                            ...response.data.token,
                            ssoKey: response.data.ssoKey,
                        });
                    }
                    return response.data;
                default:
                    throw response;
            }
        },
        authorizeOAuth2(
            { state, getters }: ActionContext<IAuthStorage, any>,
            { email, password, merge = 0, authType = 'oauth2code', query = {}, isLoginAccessCode = false, deviceId }
                : { email: string, password: string, merge: number, authType: string, query: any; isLoginAccessCode: boolean, deviceId?: string },
        ): Promise<any> {
            return AuthHttp.authOAuth2(
                { login: email, password, merge, authType, query, isLoginAccessCode },
                deviceId ? deviceId : state.user.device_id,
                getters.baseURL,
            );
        },
        getUserAccessCode(
            { state }: ActionContext<IAuthStorage, any>,
            proxyQuery: any,
        ) {
            return UserApi.getUserAccessCode(
                proxyQuery,
                state.user.device_id,
            );
        },
        async authorizeTwoFA(
            { getters, dispatch }: ActionContext<IAuthStorage, any>,
            { key, code, twoFactorMethod, isAccessCode, authType, query, isLoginAccessCode }: any,
        ) {
            let addObj = {};
            if (isAccessCode || isLoginAccessCode) {
                addObj = { authType: 'oauth2code' };
            } else if (authType) {
                addObj = { authType };
            }

            const response: any = await AuthHttp.authTwoFA({
                key,
                code,
                twoFactorMethod,
                baseURL: getters.baseURL,
                ...addObj,
                query,
                isLoginAccessCode,
            });

            if (response?.token?.user) {
                await dispatch('successAuth', {
                    ...response.token,
                    ssoKey: response.ssoKey,
                });
                return response;
            } else if (response?.code) {
                return response;
            } else {
                throw response;
            }
        },
        authorizeTwoFAApp({ getters }: ActionContext<IAuthStorage, any>, { app_sso_key, key, code, twoFactorMethod }: any) {
            return AuthHttp.authTwoFAApp({
                app_sso_key,
                key,
                code,
                twoFactorMethod,
                baseURL: getters.baseURL,
            });
        },
        successAuth({ commit, dispatch, rootGetters }: ActionContext<IAuthStorage, any>, { user, key, device_id, ssoKey }) {
            commit('setUserData', user);
            commit('setUserKey', key);
            commit('setDeviceId', device_id);

            SSOKey.set(user.email, rootGetters.regionId, ssoKey);

            const deferredRequest = CookieHelper.get('deferredRequest');
            if (deferredRequest?.name) {
                dispatch(deferredRequest.name, deferredRequest.payload, { root: true });
            }
            VueCookies.remove('deferredRequest');
        },
        downloadUserData({ state, commit }: any, isForce = false) {
            if (isForce) {
                state.promiseUserData = null;
            }

            if (!state.promiseUserData) {
                commit('setIsLoadingUserData', true);
                state.promiseUserData = UserApi.getUserSettings().then((response: any) => {
                    commit('setUserData', response);
                }).finally(() => {
                    commit('setIsLoadingUserData', false);
                });
            }

            return state.promiseUserData;
        },
        downloadTimezones({ state, commit }: any) {
            if (!_.isEmpty(state.timezones)) {
                return;
            }

            commit('setIsLoadingTimezones', true);
            return UserApi.getTimezones().then((response: any) => {
                // We need to remove one timezone 'UTC', because it has no sense.
                commit('setTimezones', _.omit(response, 'UTC'));
            }).finally(() => {
                commit('setIsLoadingTimezones', false);
            });

        },
        uploadUserData({ commit }: any, params: IUserSettings) {
            commit('setIsSendingUserData', true);
            return UserApi.postUserSettings(params).finally(() => {
                commit('setIsSendingUserData', false);
            });
        },
        uploadAvatar({ commit }: any, file: File) {
            commit('setIsSendingAvatar', true);
            return UserApi.postUserAvatar(file).finally(() => {
                commit('setIsSendingAvatar', false);
            });
        },
        authCheck() {
            return UserApi.getAuthCheck();
        },
        changeTwoFA({ commit }: any, params: any) {
            commit('setIsUpdatingTwoFactorMethod', true);
            return UserApi.postChangeTwoFA(params).finally(() => {
                commit('setIsUpdatingTwoFactorMethod', false);
            });
        },
        confirmTwoFA({ commit }: any, params: any) {
            commit('setIsUpdatingTwoFactorMethod', true);
            return UserApi.postConfirmTwoFA(params).finally(() => {
                commit('setIsUpdatingTwoFactorMethod', false);
            });
        },
        resendCodeTwoFA(_context: any, { key }: any) {
            return UserApi.postResendCodeTwoFA({ key });
        },
        changeTwoFAMethodToEmail(_context: any, params: any) {
            return UserApi.postChangeTwoFAMethodToEmail(params);
        },
        loadOAuthServicesUrls({ state, getters }: ActionContext<IAuthStorage, any>, { service, landing }) {
            const baseURL = getters.baseURL;
            const data = {
                redirectUri: `${httpParams.workspaceUrl}/${landing}?service=${service}`,
                service,
                source: 'web',
            };
            return AuthHttp.oauthGetServiceUrl(data, baseURL).then((response) => {
                state.oauth[service].url = response.data.url;
            });
        },
        checkOAuthCode({ commit, getters, dispatch }: ActionContext<IAuthStorage, any>, { service, landing, code, merge = 0, authType, deviceId }) {
            const baseURL = getters.baseURL;
            let data: any = {
                redirectUri: `${httpParams.workspaceUrl}/${landing}?service=${service}`,
                service,
                deviceId: deviceId ? deviceId : getters.deviceId,
                source: 'web',
                merge,
            };
            if (authType) {
                data = { ...data, authType, source: 'oauth2' };
            }
            commit('setIsOauthChecking', true);
            return AuthHttp.checkCode(data, baseURL, code).then((responseData: any) => {
                if (responseData.token) {
                    dispatch('successAuth', {
                        ...responseData.token,
                        ssoKey: responseData.ssoKey,
                    });
                }
                return responseData;
            }).finally(() => {
                commit('setIsOauthChecking', false);
            });
        },

        checkOAuthCodeAppSSO({ commit, getters }: ActionContext<IAuthStorage, any>, {
            service, landing, code, regionId, appSSOKey, merge,
        }) {
            const baseURL = regionId ? getters.baseURLbyRegionId(regionId) : getters.baseURL;
            const data = {
                redirectUri: `${httpParams.workspaceUrl}/${landing}?service=${service}`,
                service,
                deviceId: RandomService.string(32),
                source: 'web',
                app_sso_key: appSSOKey,
                merge,
            };
            commit('setIsOauthChecking', true);
            return AuthHttp.checkCodeAppSSO(data, baseURL, code).catch(() => {
                commit('setAuthError', 'Auth error...');
            }).finally(() => {
                commit('setIsOauthChecking', false);
            });
        },

        async getLicensesForAppAuth({ getters }, { data }): Promise<any> {
            const baseURL = data.regionId ? getters.baseURLbyRegionId(data.regionId) : getters.baseURL;
            const response = await AuthHttp.getLicensesForAppAuth(data, baseURL);
            return response.map(ProtoLicense.instantiate);
        },

        postSAMLInitData({ getters }, data) {
            const baseURL = getters.baseURL;
            return AuthHttp.postSAMLInitData({ baseURL, data });
        },

        postSAMLConfirmAuth({ getters }, data) {
            const baseURL = getters.baseURL;
            return AuthHttp.postSAMLConfirmAuth({
                baseURL,
                data: {
                    ...data,
                    device_id: getters.deviceId,
                },
            });
        },

        postSAMLConfirmAuthAppSSO({ getters }, data) {
            const baseURL = getters.baseURL;
            return AuthHttp.postSAMLConfirmAuthAppSSO({ baseURL, data });
        },
        async getActiveSessions({ commit }: any) {
            commit('setIsLoadingActiveSessions', true);
            const activeSessions = await UserApi.getActiveSessions();
            commit('setActiveSessions', prepareSessions(activeSessions));
            commit('setIsLoadingActiveSessions', false);
            return activeSessions;
        },
        postDeleteSession(_context: any, deviceId) {
            return UserApi.postDeleteSession({ deviceIdToRemove: deviceId });
        },

        getSendSSOCode({ getters }: any, params: { email: string; language: string }) {
            return UserApi.getSendSSOCode(params, getters.appSSO?.region);
        },

        async getSSOLicenses({ rootGetters }: any, params: { email: string; code: string }) {
            const appRegionId: string | undefined = rootGetters.appSSO?.region;
            const { ssoKey, licenses } = await UserApi.getSSOLicenses(params, appRegionId);
            SSOKey.set(params.email, appRegionId || rootGetters.regionId, ssoKey);
            return licenses.map(LoginLicense.instantiate);
        },

        async getTrySSOLicenses({ rootGetters }: any, { email, language }: { email: string; language: string }) {
            const appRegionId: string | undefined = rootGetters.appSSO?.region;
            const regionId = appRegionId || rootGetters.regionId;
            const existingSSOKey = SSOKey.get(email, regionId);
            const paramsWithSSOKey = { email, language, ssoKey: existingSSOKey };
            const { ssoKey, licenses } = await UserApi.getTrySSOLicenses(paramsWithSSOKey, appRegionId);
            SSOKey.set(email, regionId, ssoKey);
            return licenses.map(LoginLicense.instantiate);
        },
    },
} as Module<IAuthStorage, any>;
