<template>
    <div
        :class="{ 'list-has-scroll': listHasScroll }"
        class="issue-item-column-check"
    >
        <WsCheckbox
            v-model="isAllOnPageSelected"
            :disabled="loading || !issues.length"
            :indeterminate="isAnyOnPageSelected"
            @change="onCheckboxChange(!isAllOnPageSelected)"
        />
        <v-menu offset-y>
            <template #activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                >
                    <span class="arrow-down" />
                </v-btn>
            </template>

            <v-list data-test="t-select-options-list">
                <v-list-item @click="selectCurrentPage">{{ $t('Table.selectCurrentPage') }}</v-list-item>
                <v-list-item @click="unselectCurrentPage">{{ $t('Table.unselectCurrentPage') }}</v-list-item>
                <v-list-item @click="unselectAll">{{ $t('Table.unselectAllPages') }}</v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import { Issue } from '@/domain/issue/models/Issue';

@Component({
    components: {
        WsCheckbox,
    },
})
export default class IssueItemColumnCheck extends Vue {
    @Prop({ default: false }) public listHasScroll!: boolean;
    @Prop({ default: false }) public loading!: boolean;
    @Prop({ default: [] }) public issues!: Issue[];
    @Prop({ default: [] }) public selected!: Issue[];

    get selectedOnPage(): Issue[] {
        return _.intersectionBy(this.selected, this.issues, 'id');
    }

    get isAllOnPageSelected(): boolean {
        return this.issues.length > 0 && this.selectedOnPage.length === this.issues.length;
    }

    set isAllOnPageSelected(value: boolean) {
        this.$store.commit('multiSelectAllIssues', value ? _.uniqBy([...this.selected, ...this.issues], 'id') : _.xorBy(this.selected, this.issues, 'id'));
    }

    get isAnyOnPageSelected(): boolean {
        return this.selected.length > 0 && !this.isAllOnPageSelected && this.selectedOnPage.length > 0;
    }

    public onCheckboxChange(value: boolean) {
        if (value) {
            this.sendAllIssuesCheckEvent('header-checkbox');
        } else {
            amplitudeLog(AmplitudeEvent.itIssueUnCheck, { method: 'header-checkbox' });
        }
    }

    public selectCurrentPage() {
        this.sendAllIssuesCheckEvent('header-menu');
        this.$store.commit('multiSelectAllIssues', _.uniqBy([...this.selected, ...this.issues], 'id'));
    }

    public unselectCurrentPage() {
        amplitudeLog(AmplitudeEvent.itIssueUnCheck, { method: 'header-menu' });
        this.$store.commit('multiSelectAllIssues', _.xorBy(this.selected, this.selectedOnPage, 'id'));
    }

    public unselectAll() {
        amplitudeLog(AmplitudeEvent.itIssueUnCheck, { method: 'header-menu' });
        this.$store.dispatch('clearMultiSelect');
    }

    public async sendAllIssuesCheckEvent(method: string) {
        // We need to wait untill issues are actually checked.
        await this.$nextTick();
        amplitudeLog(AmplitudeEvent.itIssueCheck, { method, count: this.selectedOnPage });
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.issue-item-column-check {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 40px;
    padding: 0 12px 0 10px;
    text-align: center;
    position: relative;
    margin-right: -$container-padding;

    ::v-deep .v-messages {
        display: none;
    }

    ::v-deep .v-input--selection-controls__ripple {
        height: 30px;
        width: 20px;
        left: 2px;
        top: -4px;
        margin: 0;
        border-radius: $border-radius-small;
        color: rgba(0, 0, 0, 0.4);
    }

    ::v-deep .v-input--selection-controls__input {
        margin-right: 0 !important;
    }

    ::v-deep .v-btn--icon.v-size--default {
        height: 36px;
        width: 22px;
        border-radius: $border-radius-small;
    }

    ::v-deep .v-ripple__container {
        display: none;
    }

    ::v-deep .theme--light.v-btn.v-btn--icon {
        color: rgba(0, 0, 0, 0.4);
    }

    ::v-deep .theme--light.v-btn {
        &:focus::before,
        &:hover::before {
            opacity: 0.2;
        }
    }

    .arrow-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $select-black;
    }

    .v-input--checkbox {
        margin: 5px 0 0 0;
    }
}
</style>
