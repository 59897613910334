import { Dict } from '@/types/Dict';
import { CookieHelper } from '@/services';
import { ApplicationObject } from '@/services/ApplicationObject';

export class CookieObject extends ApplicationObject {
    protected get obj(): Dict {
        return CookieHelper.get(this.key) || {};
    }

    protected save(obj: Dict) {
        CookieHelper.set(this.key, obj);
    }
}
