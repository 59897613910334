<template>
    <div class="component-nav-menu">
        <!--Explicit language in parameters here and below is necessary for routes refresh-->
        <v-progress-circular
            v-if="isLoadingLicenses"
            color="primary"
            size="20"
            width="2"
            indeterminate
        />
        <template v-else>
            <HeaderSelect
                :activator-text="truncateString(license.name, 30) || $t('Menu.select_license')"
                :title="$t('Menu.license')"
                :close-on-list-click="Boolean(activeLicenses.length || frozenLicenses.length)"
                data-test="mnb-licenses-list"
                amp-hide-active-title
                @change="licenseSearch = ''"
            >
                <template #header>
                    <WsInputSearch
                        v-model="licenseSearch"
                        :placeholder="$t('License.search')"
                    />
                </template>

                <template #list>
                    <v-list-item v-if="activeLicenses.length" disabled>
                        <span class="submenu-title">{{ $t('Menu.activeLicenses') }}</span>
                    </v-list-item>
                    <v-list-item v-for="license in activeLicenses" :key="license.id" :value="license.id">
                        <router-link
                            v-if="license.isValidAuth"
                            :to="{ name: RouterNames.MyLicenseProjects, params: { licenseId: license.id, language } }"
                            :class="{ 'router-link-exact-active': license.id === licenseId }"
                        >
                            <div class="color-marker green-dot" />
                            <div class="license-name amp-mask">{{ license.name }}</div>
                        </router-link>
                        <a
                            v-else
                            @click="onNotAuthLicenseClick(license)"
                        >
                            <div class="color-marker green-dot" />
                            <div class="license-name amp-mask">{{ license.name }}</div>
                        </a>
                    </v-list-item>

                    <v-list-item
                        v-if="frozenLicenses.length"
                        :class="{ divided: activeLicenses.length }"
                        disabled
                    >
                        <span class="submenu-title">{{ $t('Menu.suspendedLicenses') }}</span>
                    </v-list-item>
                    <v-list-item v-for="license in frozenLicenses" :key="license.id">
                        <router-link
                            v-if="license.isValidAuth"
                            :to="{ name: RouterNames.MyLicenseProjects, params: { licenseId: license.id, language } }"
                            :class="{ 'router-link-exact-active': license.id === licenseId }"
                        >
                            <div class="color-marker red-dot" />
                            <div class="license-name amp-mask">{{ license.name }}</div>
                        </router-link>
                        <a
                            v-else
                            @click="onNotAuthLicenseClick(license)"
                        >
                            <span class="color-marker red" />
                            <span class="license-name amp-mask">{{ license.name }}</span>
                        </a>
                    </v-list-item>

                    <div v-if="!activeLicenses.length && !frozenLicenses.length" class="no-licenses-wrapper">
                        <div>{{ $t('License.noLicensesMatch') }}</div>
                    </div>
                </template>
            </HeaderSelect>
            <HeaderSelect
                :title="currentCategoryTool"
                :activator-text="currentTool"
                data-test="mnb-tools-list"
                close-on-list-click
                @size="setToolSize"
                @change="clickTools"
            >
                <template #list>
                    <v-list-item disabled>
                        <span class="submenu-title">{{ $t('Menu.tools') }}</span>
                    </v-list-item>
                    <v-list-item v-if="$route.name === RouterNames.MyLicenseProjectsArchived">
                        <router-link :to="{ name: RouterNames.MyLicenseProjectsArchived, params: { licenseId, language } }">
                            {{ $t('Menu.projects') }}
                        </router-link>
                    </v-list-item>
                    <v-list-item v-else>
                        <router-link :to="{ name: RouterNames.MyLicenseProjects, params: { licenseId, language } }">
                            {{ $t('Menu.projects') }}
                        </router-link>
                    </v-list-item>

                    <v-list-item v-if="!license.isAdminRights">
                        <router-link :to="{ name: RouterNames.LicenseSettings, params: { licenseId, language } }">
                            {{ $t('Menu.licenseInfo') }}
                        </router-link>
                    </v-list-item>
                    <v-list-item v-if="license.isAdminRights">
                        <router-link
                            :to="{ name: RouterNames.LicenseDashboards, params: { licenseId, language } }"
                            class="router-not-exact"
                        >
                            {{ $t('Menu.licenseDashboard') }}
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link :to="{ name: RouterNames.ReportingHistory, params: { licenseId, language } }">
                            {{ $t('Menu.reportingHistory') }}
                        </router-link>
                    </v-list-item>

                    <v-list-item
                        v-if="recentProjects.length"
                        :class="{ divided: Boolean(activeLicenses.length) }"
                        disabled
                    >
                        <span class="submenu-title">{{ $t('Menu.recentProjects') }}</span>
                    </v-list-item>
                    <v-list-item v-for="recentProject in recentProjects" :key="recentProject.uuid">
                        <router-link
                            :to="{ name: recentProject.componentName, params: { ...recentProject.params, language } }"
                            class="amp-mask"
                            @click.native="trackRecentProject(recentProject)"
                        >
                            {{ recentProject.shortTitle }}
                        </router-link>
                    </v-list-item>

                    <v-list-item
                        v-if="license.isAdminRights"
                        :class="{ divided: Boolean(activeLicenses.length) }"
                        disabled
                    >
                        <span class="submenu-title">{{ $t('Menu.licenseManagement') }}</span>
                    </v-list-item>
                    <v-list-item v-if="license.isAdminRights">
                        <router-link :to="{ name: RouterNames.LicenseSettings, params: { licenseId, language } }">
                            {{ $t('Menu.licenseInfo') }}
                        </router-link>
                    </v-list-item>
                    <v-list-item v-if="license.isAdminRights">
                        <router-link :to="{ name: RouterNames.LicenseMembers, params: { licenseId, language } }">
                            {{ $t('Menu.userManagement') }}
                        </router-link>
                    </v-list-item>
                    <v-list-item v-if="license.isAdminRights">
                        <router-link :to="{ name: RouterNames.LicenseProjects, params: { licenseId, language } }">
                            {{ $t('Menu.project') }}
                        </router-link>
                    </v-list-item>
                    <v-list-item v-if="license.isAdminRights">
                        <router-link
                            :to="{ name: RouterNames.ProjectRoles, params: { licenseId, language } }"
                            class="router-not-exact"
                        >
                            {{ $t('Menu.projectRoles') }}
                        </router-link>
                    </v-list-item>
                    <v-list-item v-if="license.isAdminRights">
                        <router-link :to="{ name: RouterNames.ActionLog, params: { licenseId, language } }">
                            {{ $t('Menu.actionLog') }}
                        </router-link>
                    </v-list-item>
                </template>
            </HeaderSelect>

            <WsDialog
                v-if="isLoginToLicenseNeeded && nonAuthLicense"
                :title="$t('Login.licenseLogin')"
                width="400px"
                @close="isLoginToLicenseNeeded = false"
            >
                <LoginMergeForm
                    :license="nonAuthLicense"
                    :close="() => isLoginToLicenseNeeded = false"
                />
            </WsDialog>
        </template>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { AmplitudeEvent, RouterNames } from '@/constants';
import { License, RecentProject } from '@/models';
import { truncateString } from '@/services';
import { amplitudeMixin } from '@/mixins';
import HeaderSelect from '@/components/header/HeaderSelect.vue';
import LoginMergeForm from '@/components/user/LoginMergeForm.vue';
import WsDialog from '@/components/common/WsDialog.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsInputSearch from '@/components/common/WsInputSearch.vue';

@Component({
    components: {
        WsInputSearch,
        WsInput,
        HeaderSelect,
        LoginMergeForm,
        WsDialog,
    },
})
export default class NavMenu extends amplitudeMixin {
    @Prop({ type: String }) public mode!: string;

    public readonly truncateString = truncateString;
    public readonly RouterNames = RouterNames;

    public isLoginToLicenseNeeded = false;
    public nonAuthLicense: License | null = null;
    public licenseSearch = '';

    get language() {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get currentUserId(): number {
        return this.$store.getters.userData.id;
    }

    get regionId(): string {
        return this.$store.getters.regionId;
    }

    get recentProjects(): RecentProject[] {
        return this.$store.getters.recentProjects(this.licenseId, this.currentUserId, this.regionId);
    }

    get licensesCanView() {
        return this
            .orderLicenses(this.$store.getters.licensesCanView)
            .filter(License.getFilterPredicate(this.licenseSearch));
    }

    get activeLicenses() {
        return this.licensesCanView.filter((license) => !license.frozen);
    }

    get frozenLicenses() {
        return this.licensesCanView.filter((license) => license.frozen);
    }

    get isLoadingLicenses(): boolean {
        return this.$store.getters.isLoadingLicenses;
    }

    get currentCategoryTool() {
        const routeName = this.$route.name || '';
        switch (routeName) {
            case RouterNames.LicenseSettings:
                return this.license.isAdminRights ? this.$t('Menu.licenseManagement') : this.$t('Menu.tools');
            case RouterNames.LicenseMembers:
            case RouterNames.LicenseMembersApproval:
            case RouterNames.LicenseMembersAuthMethods:
            case RouterNames.LicenseMembersImportUsers:
            case RouterNames.LicenseActivity:
            case RouterNames.LicenseMembersAdditionalFields:
            case RouterNames.LicenseProjects:
            case RouterNames.ProjectAccessRole:
            case RouterNames.ProjectRoles:
            case RouterNames.ActionLog:
                return this.$t('Menu.licenseManagement');
            default:
                return this.$t('Menu.tools');
        }
    }

    get currentTool() {
        const routeName = this.$route.name || '';

        switch (routeName) {
            case RouterNames.MyLicenseProjects:
            case RouterNames.MyLicenseProjectsArchived:
                return this.$t('Menu.projects');
            case RouterNames.LicenseDashboard:
            case RouterNames.LicenseDashboards:
                return this.$t('Menu.licenseDashboard');
            case RouterNames.LicenseSettings:
                return this.$t('Menu.licenseInfo');
            case RouterNames.LicenseMembers:
            case RouterNames.LicenseMembersApproval:
            case RouterNames.LicenseMembersAuthMethods:
            case RouterNames.LicenseMembersImportUsers:
            case RouterNames.LicenseActivity:
            case RouterNames.LicenseMembersAdditionalFields:
                return this.$t('Menu.userManagement');
            case RouterNames.LicenseProjects:
                return this.$t('Menu.project');
            case RouterNames.ProjectAccessRole:
                return this.$t('Menu.projectRoles');
            case RouterNames.ProjectRoles:
                return this.$t('Menu.projectRoles');
            case RouterNames.ReportingHistory:
                return this.$t('Menu.reportingHistory');
            case RouterNames.ActionLog:
                return this.$t('Menu.actionLog');
            default:
                return this.$t('Menu.selectTool');
        }
    }

    public clickTools(isClickOpen: boolean) {
        if (isClickOpen) {
            this.amplitudeLog(AmplitudeEvent.navbarOpenToolsMenu);
        }
    }

    public onNotAuthLicenseClick(nonAuthLicense: License) {
        this.nonAuthLicense = nonAuthLicense;
        this.isLoginToLicenseNeeded = true;
    }

    public orderLicenses(licenses: any): License[] {
        return _.sortBy(licenses, ['frozen', 'name']);
    }

    public trackRecentProject(recentProject: RecentProject) {
        this.amplitudeLog(AmplitudeEvent.recentProjectOpen, { recentProjectUuid: recentProject.uuid });
    }

    public setToolSize(size: number) {
        this.$store.commit('setHeaderComponentSize', { name: 'tool', size });
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.license-name {
    overflow: hidden;
    text-overflow: ellipsis;
}
.tool {
    max-width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-licenses-wrapper {
    height: 160px;
    display: flex;
    color: $text-dark;
    pointer-events: none;
    justify-content: center;
    align-items: center;
}
</style>
