import { TranslateResult } from 'vue-i18n';
import {
    DateFormatByNumber,
    DEADLINE_NOT_SET,
} from '@/constants';
import { ISorting } from '@/domain/issue/services/IssueOrderParse';
import { TrackerFilters } from '@/domain/issueFilter/types/ProjectIssuesFilters';
import { IssueTrackerFilterValue } from '@/domain/issueFilter/services/IssueTrackerFilterValue';
import { IssueFilterExpr } from '@/domain/issueFilter/constants/IssueFilterExpr';
import { IssuesFilterType } from '@/domain/issueFilter/constants/IssuesFilterType';
import { IssueDirections, IssueSort, IssueSorts } from '@/domain/issue/constants/IssueSort';
import { diffRows } from '@/domain/comment/types/comment';
import { StampColorsPaletteRGB } from '@/domain/stamp/constants/StampColorsPaletteRGBEnum';

const findColorIndex = (color: string) => {
    return StampColorsPaletteRGB.findIndex((item: string) => color === item) + 1;
};

const prepareValueForChangeIssueField = ({ row, type, newValue }: any) => {
    if (row === 'stampColor') {
        newValue = findColorIndex(newValue);
    }

    if (type === 'date' && (typeof newValue !== 'string')) {
        newValue = newValue.startDate ? newValue.startDate.format(`${DateFormatByNumber[6]} HH:mm:ss`) : DEADLINE_NOT_SET;
    }

    return newValue;
};

const createDiff = ({ sourceValue, value, type }: any) => {
    const diffValues: any = {
        [diffRows.oldValue]: sourceValue,
        [diffRows.newValue]: value,
    };

    if (type === 'switch') {
        diffValues[diffRows.oldValue] = sourceValue ? 1 : 0;
        diffValues[diffRows.newValue] = value ? 1 : 0;
    }

    return diffValues;
};

const issuesSortApiParams = (sort: ISorting): any => {
    const apiParams: any[] = [];
    if (sort[IssueSorts.unread]) {
        apiParams.push({
            [IssueSort.field]: IssueSorts.unread,
            [IssueSort.direction]: IssueDirections.ASC,
        });
    }
    if (sort[IssueSorts.primary]) {
        apiParams.push({
            [IssueSort.field]: sort[IssueSorts.primary][IssueSort.field],
            [IssueSort.direction]: sort[IssueSorts.primary][IssueSort.direction],
        });
    }
    if (sort[IssueSorts.secondary]) {
        apiParams.push({
            [IssueSort.field]: sort[IssueSorts.secondary][IssueSort.field],
            [IssueSort.direction]: sort[IssueSorts.secondary][IssueSort.direction],
        });
    }

    return apiParams;
};

const issuePrefix = (abbr: string | undefined, id: number, text: TranslateResult) =>  {
    return (abbr ? `${abbr}-` : `${text} `) + id;
};

const isFiltersForDeletedIssues = (filters: TrackerFilters) => {
    const deletedAtFilter = (filters[IssuesFilterType.deletedAt] as IssueTrackerFilterValue);

    return deletedAtFilter?.modifier === IssueFilterExpr.EQUALS && deletedAtFilter?.selections[0] === 1;
};

const openInAppLink = (currentProject: any, projectId: number, currentIssueId: number) => {
    const prefix = currentProject.isV4 ? 'revizto' : 'revizto5';
    return `${prefix}://viewer/${projectId}/issue_tracker/${currentIssueId}`;
};

export {
    findColorIndex,
    prepareValueForChangeIssueField,
    createDiff,
    issuesSortApiParams,
    issuePrefix,
    isFiltersForDeletedIssues,
    openInAppLink,
};
