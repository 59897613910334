<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator';
import WsInput from '@/components/common/WsInput.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class WsInputNumber extends WsInput {
    // @ts-ignore
    @Prop({ default: 'number' }) public type!: 'number';

    @Emit()
    public input(value: string) {
        return Number(String(value).trim()) as any; // hack for avoid type of input emit in WsInput
    }

    @Emit()
    public change(value: string) {
        return Number(String(value).trim()) as any;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-text-field  {
    ::v-deep input {
        padding: 6px 0 6px;
    }
    ::v-deep .v-input__control {
        > .v-input__slot > .v-text-field__slot {
            margin-right: -9px;
        }
    }
}
.v-text-field--outlined.v-input--dense.v-text-field--outlined {
    ::v-deep .v-input__control {
        > .v-input__slot {
            min-height: 36px;
        }
    }
}
.v-input {
    ::v-deep .v-messages__message {
        line-height: 1.4;
    }
}
</style>
