import { FieldVariants } from '@/models';
import { Issue } from '@/domain/issue/models/Issue';

export const mixIssueFieldVariants = (issue: Issue, fieldVariants: FieldVariants): FieldVariants => {
    return  {
        ...fieldVariants,
        assignees: [...new Set([issue.assignee, ...fieldVariants.assignees ])],
        clashAreas: [...new Set([...issue.clashArea, ...fieldVariants.clashAreas ])],
        clashCategories: [...new Set([...issue.clashCategory, ...fieldVariants.clashCategories ])],
        clashDisciplines: [...new Set([...issue.clashDiscipline, ...fieldVariants.clashDisciplines ])] as number[],
        clashGridXs: [...new Set([...issue.clashGridX, ...fieldVariants.clashGridXs ])],
        clashGridYs: [...new Set([...issue.clashGridY, ...fieldVariants.clashGridYs ])],
        clashLevels: [...new Set([...issue.clashLevel, ...fieldVariants.clashLevels ])],
        clashRooms: [...new Set([...issue.clashRoom, ...fieldVariants.clashRooms ])],
        clashSourceFiles: [...new Set([...issue.clashSourceFile, ...fieldVariants.clashSourceFiles ])],
        clashSpaces: [...new Set([...issue.clashSpace, ...fieldVariants.clashSpaces ])],
        clashTests: [...new Set([...issue.clashTest, ...fieldVariants.clashTests ])],
        clashZones: [...new Set([...issue.clashZone, ...fieldVariants.clashZones ])],
        reporters: [...new Set([issue.reporter, ...fieldVariants.reporters ])],
        tags: [...new Set([...issue.tags, ...fieldVariants.tags ])],
    } as FieldVariants;
};
