<template>
    <div class="component-filter-controls filter-controls">
        <WsButton
            :icon="isShowFilters ? 'arrow-double-up' : 'arrow-double-down'"
            icon-size="24"
            class="collapse-button"
            data-test="f-show-filters-button"
            @click="toggle"
        >
            {{ $t('Button.filters') }}
        </WsButton>

        <WsButton
            class="action-button"
            size="small"
            data-test="f-reset-filters-button"
            @click="resetAll"
        >
            {{ $t('Button.reset') }}
        </WsButton>

        <div v-show="$slots.default && isShowFilters">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        WsButton,
    },
})
export default class WsFilterControls extends Vue {
    @Prop({ type: String, required: true }) public location!: string;

    get isShowFilters() {
        return !this.$store.getters.isFilterCollapsed(this.location);
    }

    @Emit()
    public resetAll() {
        return;
    }

    public toggle() {
        this.$store.dispatch('toggleFiltersCollapsed', this.location);
    }
}
</script>

<style lang="scss" scoped>
.filter-controls {
    display: inline-block;
}
</style>
