import { DateFormat, PeriodType, PreviewSize, TimeFormat } from '@/constants';
import { IReportDeliverySettings, LicenseMember } from '@/models';
import { formDayShift, formTimeShiftFromServer } from '@/services';
import { IIssueFilter } from '@/domain/issueFilter/types/IIssueFilter';
import { ReportFormat } from '@/domain/report/constants/ReportFormat';
import { ReportVisibility } from '@/domain/report/constants/ReportVisibility';
import { IssuesFilterConstructor } from '@/domain/issueFilter/models/IssuesFilter';

export class Report {
    public readonly uuid: string;
    public title: string;
    public format: number;
    public visibility: number;
    public delivery: IReportDeliverySettings;
    public author: LicenseMember;

    public alwaysFilters: IIssueFilter[];
    public anyFilters: IIssueFilter[];
    public customFields: string[];
    public reportSort: string[];

    constructor(report: any = {}) {
        this.uuid = report.uuid || '';
        this.title = report.title || '';
        this.format = report.format || ReportFormat.XLS;
        this.author = new LicenseMember(report.author);
        this.delivery = report.delivery || {
            periodType: PeriodType.none,
            days: [],
            dayShift: 0,
            subscribers: [],
            includeCompanyLogo: 0,
            includeUserComments: 1,
            includeAttachments: 0,
            includeFieldChanges: 0,
            includeMarkupChanges: 0,
            previewSize: PreviewSize.SMALL_IMAGES,
            timeFormat: TimeFormat['24h'],
            dateFormat: DateFormat['DD/MM/YYYY'],
        };
        this.delivery.time = report.delivery?.time ? formTimeShiftFromServer(report.delivery.time) : '08:00';
        this.visibility = typeof report.visibility === 'undefined' ? ReportVisibility.author : report.visibility;
        this.alwaysFilters = this.initIssuesFilters(report.alwaysFilters || []);
        this.anyFilters = this.initIssuesFilters(report.anyFilters || []);
        this.customFields = report.customFields || [];
        this.reportSort = report.reportSort || [{ field: 'id', direction: 'asc' }];
    }

    get apiParams(): any {
        // тут лежит не только дата, а весь объект деливери
        const shiftedDelivery = formDayShift(this.delivery);
        return {
            title: this.title,
            format: this.format,
            visibility: this.visibility,
            alwaysFiltersDTO: this.getValidFiltersParams(this.alwaysFilters),
            anyFiltersDTO: this.getValidFiltersParams(this.anyFilters),
            customFields: this.customFields,
            reportSort: this.reportSort,
            ...shiftedDelivery,
        };
    }

    /**
     * Функция инициализации фильтров
     */
    public initIssuesFilters(filters: any[]): IIssueFilter[] {
        return filters ? filters.map((filter: any) => new IssuesFilterConstructor(filter) as IIssueFilter) : [];
    }

    /**
     * Функция подготовки массива фильтров для отправки на сервер
     */
    public getValidFiltersParams(filters: IIssueFilter[]) {
        return filters
            .filter((filter: IIssueFilter) => filter.isValidParams())
            .map((filter: IIssueFilter) => filter.getParams());
    }
}
