import { v4 as uuidv4 } from 'uuid';
import { Color } from '@/constants';
import { IIssueFilter } from '@/domain/issueFilter/types/IIssueFilter';
import { IssuesFilterConstructor } from '@/domain/issueFilter/models/IssuesFilter';
import { TimelineLineType } from '@/domain/chart/constants/TimelineLineType';

function initIssuesFilters(filters: any[]): IIssueFilter[] {
    if (!filters) {
        return [];
    }

    return filters.map((filter: any) => new IssuesFilterConstructor(filter) as IIssueFilter);
}

export class DashboardDataLine {
    public readonly uuid: string;
    public title: string;
    public type: number;
    public color: string;
    public alwaysFiltersDTO: IIssueFilter[];
    public anyFiltersDTO: IIssueFilter[];

    constructor(line: any = {}) {
        this.uuid = line.uuid || uuidv4();
        this.title = line.title || 'Line 1';
        this.type = line.type || TimelineLineType.issues;
        this.color = line.color || Color.timeline.red;

        this.alwaysFiltersDTO = initIssuesFilters(line.alwaysFilters);
        this.anyFiltersDTO = initIssuesFilters(line.anyFilters);
    }
}
