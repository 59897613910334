<template>
    <svg
        width="60"
        height="32"
        viewBox="0 0 60 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M43.2701 14.9997L32.3541 5.7631L33.6459 4.23633L47.5481 15.9997L33.6459 27.7631L32.3541 26.2363L43.2701 16.9997H14V14.9997H43.2701Z"
            fill="black"
        />
    </svg>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

@Component
export default class LineType12 extends BaseIcon {}
</script>
