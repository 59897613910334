// @ts-ignore
import settings from 'reviztoSettings';
import { amplitude } from '@/plugins/useAmplitude';

export function amplitudeLog(name: string, payload?: any) {
    if (settings.amplitudeApiKey) {
        amplitude.logEvent(name, payload);
        return;
    } else if (process.env.NODE_ENV !== 'development') {
        window.console.info('Amplitude event', name, payload);
    }
}
