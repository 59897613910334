import { DACH } from '@/domain/stamp/constants/templates/DACH';
import { FR } from '@/domain/stamp/constants/templates/FR';
import { UK1 } from '@/domain/stamp/constants/templates/UK1';
import { UK2 } from '@/domain/stamp/constants/templates/UK2';
import { APAC } from '@/domain/stamp/constants/templates/APAC';
import { NA } from '@/domain/stamp/constants/templates/NA';

export const NO_CATEGORY_UUID = 'no-category-uuid';
export enum NodeRole {
    Stamp = 1,
    Category = 2,
}

export const NO_STAMP = 'noStamp';
export const ANY_STAMP_UUID = '78f63fa3-0bfa-44ec-928f-5c92bab647f2';
export const NO_CATEGORY = 'noCategory';
export const NO_STAMPS_SETS = [NO_STAMP, NO_CATEGORY, ANY_STAMP_UUID];
export const PROD_SERVERS: any = {
    'us-east-1': ['NA'],
    'eu-west-1': ['DACH', 'FR', 'UK1', 'UK2'],
    'sa-east-1': ['NA'],
    'ap-southeast-1': ['APAC'],
    'ap-southeast-2': ['APAC'],
    'ap-northeast-1': ['APAC'],
    'eu-west-2': ['DACH', 'FR', 'UK1', 'UK2'],
    'ca-central-1': ['NA'],
    'me-central-1': ['DACH', 'FR', 'UK1', 'UK2'],
};
export const DEFAULT_STAMP_ABBR = 'ABC';
export const DEMO_STAMPS_CATEGORIES = ['DACH', 'FR', 'UK1', 'UK2', 'APAC', 'NA'];
export const DEMO_STAMPS: any = {
    DACH,
    FR,
    UK1,
    UK2,
    APAC,
    NA,
};
export enum CreateStampSource {
    CategoryPanel = 'button-in-category',
    StampsPanel = 'button-in-stamps-panel',
    TopBarPanel = 'top-bar-stamps-panel',
}
