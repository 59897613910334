<template>
    <button
        :style="buttonStyle"
        :class="buttonClasses"
        @click="click"
    >
        <IconSvg24
            v-if="big"
            icon-name="close"
            :color="Color.solid60"
            stateless
            passive
        />
        <IconSvg16
            v-else
            icon-name="close"
            :color="Color.solid60"
            stateless
            passive
        />
    </button>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg16,
        IconSvg24,
    },
})
export default class WsCloseButton extends Vue {
    @Prop({ default: Color.closeBlack }) public color!: string;
    @Prop({ type: Boolean, default: false }) public round!: boolean;
    @Prop({ type: Boolean, default: false }) public big!: boolean;
    @Prop({ type: Boolean, default: false }) public small!: boolean;

    public readonly Color = Color;

    get buttonStyle() {
        return `color: ${this.color};`;
    }

    get buttonClasses() {
        return {
            'ws-close-button--round': this.round,
            'ws-close-button--small': this.small,
            'ws-close-button': true,
        };
    }

    @Emit()
    public click() {
        return;
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/helpers/a11y.scss';

.ws-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-huge;
    font-weight: $font-weight;
    width: 20px;
    height: 20px;
    transition: color ease-out .2s;
    @include a11y-clickable-area-set(relative, 8px);

    &:hover {
        color: $primary-blue;
    }

    &--small {
        width: 14px;
        height: 14px;
    }

    &--round {
        font-size: $font-size-medium;
        line-height: 1;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-solid-60;
        border-radius: 50% !important;
        background-color: $light-solid-5;
        transition: 0.2s background-color ease-out, 0.2s color ease-out;
        z-index: 10;

        &:hover {
            background-color: $hover-light-blue;
            color: $light-solid-80;
        }
    }
}
</style>
