<template>
    <svg
        width="124"
        height="32"
        viewBox="0 0 124 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M48.2 12.4004C47.3 12.5004 46.5 12.7004 45.8 12.9004C45.3 13.1004 44.8 13.3004 44.4 13.5004C44.1 13.7004 43.9 14.1004 43.9 14.4004V22.2004C43.9 22.5004 43.6 22.8004 43.3 22.8004H41.6C41.3 22.8004 41 22.5004 41 22.2004V10.1004C41 9.80039 41.3 9.50039 41.6 9.50039H43.3C43.6 9.50039 43.9 9.80039 43.9 10.1004V10.7004C43.9 11.0004 43.9 11.3004 43.9 11.4004V11.5004C43.9 11.5004 44.1 11.3004 44.3 11.1004C44.4 11.0004 44.6 10.9004 44.8 10.8004C45.2 10.5004 45.6 10.3004 46 10.1004C46.4 9.90039 46.9 9.70039 47.4 9.60039C47.7 9.50039 47.9 9.50039 48.2 9.40039C48.5 9.40039 48.8 9.60039 48.8 10.0004V11.8004C48.8 12.0004 48.5 12.3004 48.2 12.4004Z" fill="black" />
        <path d="M52.7 17.6C52.7 18.1 52.8001 18.5 52.9001 18.8C53.1001 19.1 53.3 19.4 53.5 19.6C53.8 19.8 54.1 20 54.4001 20.1C54.8001 20.2 55.1 20.3 55.5 20.3C55.9 20.3 56.4 20.2 57 20.1C57.4 20 57.9 19.8 58.3 19.5C58.6 19.3 58.9 19.3 59.2 19.6C59.5 19.9 59.8 20.3 60.1 20.7C60.3 21 60.3 21.4 60 21.6C59.4 22.1 58.7 22.5 58.1 22.7C57.3 22.9 56.4 23.1 55.6 23.1C54.8 23.1 54.1001 23 53.4001 22.8C52.7001 22.6 52.1 22.3 51.6 21.9C51.1 21.5 50.6 21 50.3 20.3C50 19.7 49.8 18.9 49.8 18.1V14.5C49.8 13.7 49.9 12.9 50.2 12.3C50.5 11.6 50.9001 11.1 51.4001 10.6C51.9001 10.1 52.5 9.8 53.2 9.5C53.9 9.1 54.6 9 55.4001 9C56.2001 9 57 9.1 57.6 9.4C58.3 9.6 58.9001 10 59.4001 10.4C59.9001 10.9 60.3 11.4 60.5 12C60.8 12.6 60.9001 13.4 60.9001 14.2V16.3C60.9001 16.6 60.6 16.9 60.3 16.9H53.2C52.9 17 52.7 17.2 52.7 17.6ZM57.3 12.3C56.8 11.9 56.2001 11.6 55.4001 11.6C54.7001 11.6 54.1 11.8 53.5 12.3C53 12.8 52.7 13.4 52.7 14.3C52.7 14.6 52.9 14.7 53.2 14.7H57.5C57.8 14.7 58 14.6 58 14.3C58.1 13.5 57.8 12.8 57.3 12.3Z" fill="black" />
        <path d="M68.3 18.5C68.8 16.8 70.4 11.3 70.9 9.5C71 9.3 71.4 9 71.7 9H73.6C74 9 74.1 9.3 74 9.6L69.6 22.5C69.5 22.8 69.1 23.1 68.8 23.1H67.1C66.8 23.1 66.4 22.8 66.3 22.5L61.9 9.6C61.8 9.3 62 9 62.3 9H64.3C64.6 9 65 9.3 65.1 9.6L67.8 18.6C67.9 18.9 68 19.2 68.1 19.2C68.1 19.1 68.2 18.8 68.3 18.5Z" fill="black" />
        <path d="M76 5.5C76 5.1 76.1 4.7 76.4 4.4C76.7 4.1 77 4 77.4 4C77.8 4 78.2 4.1 78.5 4.4C78.8 4.7 79 5.1 79 5.5C79 5.9 78.9 6.3 78.6 6.6C78.3 6.9 77.9 7 77.5 7C77.1 7 76.7 6.9 76.5 6.6C76.1 6.3 76 5.9 76 5.5ZM76.1 22.4V9.6C76.1 9.3 76.4 9 76.7 9H78.4C78.7 9 79 9.3 79 9.6V22.4C79 22.7 78.7 23 78.4 23H76.7C76.3 23 76.1 22.7 76.1 22.4Z" fill="black" />
        <path d="M80.1 22.4V21.3C80.1 20.9 80.3 20.4 80.5 20.2L86.9 12.2C87.1 11.9 87 11.7 86.7 11.7H81.2C80.9 11.7 80.6 11.4 80.6 11.1V9.6C80.6 9.2 80.9 9 81.2 9H90.4C90.7 9 91 9.3 91 9.6V10.7C91 11.1 90.8 11.6 90.6 11.8L84.2 19.8C84 20.1 84.1 20.3 84.4 20.3H90.3C90.7 20.3 90.9 20.6 90.9 20.9V22.2C90.9 22.5 90.6 22.8 90.3 22.8H80.7C80.4 23 80.1 22.7 80.1 22.4Z" fill="black" />
        <path d="M92 11.3008V10.1008C92 9.80078 92.3 9.50078 92.6 9.50078H93.6C93.9 9.50078 94 9.20078 94 8.90078V6.90078C94 6.60078 94.2 6.30078 94.6 6.30078H96.4C96.7 6.30078 97 6.60078 97 6.90078V8.90078C97 9.20078 97.3 9.50078 97.6 9.50078H99.4C99.7 9.50078 100 9.80078 100 10.1008V11.3008C100 11.6008 99.7 11.9008 99.4 11.9008H97.6C97.3 11.9008 97 12.2008 97 12.5008V18.0008C97 18.8008 97.1 19.5008 97.4 19.8008C97.6 20.2008 97.8 20.4008 98.6 20.4008C98.8 20.4008 99.1 20.4008 99.5 20.3008C99.7 20.3008 99.9 20.2008 100.1 20.2008C100.4 20.1008 100.7 20.3008 100.8 20.6008L101.1 21.9008C101.2 22.2008 101 22.6008 100.7 22.7008C100.3 22.8008 100 22.9008 99.6 22.9008C98.8 23.0008 98.4 23.0008 98 23.0008C97.2 23.0008 96.5 22.9008 96 22.7008C95.5 22.5008 95 22.2008 94.7 21.8008C94.4 21.4008 94.3 20.9008 94.2 20.4008C94 19.8008 94 19.2008 94 18.4008V12.5008C94 12.2008 93.7 11.9008 93.4 11.9008H92.6C92.3 11.9008 92 11.6008 92 11.3008Z" fill="black" />
        <path d="M114 18.1004C114 18.9004 113.8 19.6004 113.5 20.2004C113.2 20.8004 112.7 21.3004 112.2 21.7004C111.6 22.1004 111 22.4004 110.3 22.6004C109.6 22.8004 108.8 22.9004 108.1 22.9004C107.3 22.9004 106.5 22.8004 105.8 22.6004C105.1 22.4004 104.4 22.1004 103.9 21.7004C103.4 21.3004 102.9 20.8004 102.6 20.2004C102.3 19.6004 102.1 18.9004 102.1 18.1004V14.2004C102.1 13.4004 102.3 12.7004 102.6 12.1004C102.9 11.5004 103.4 11.0004 103.9 10.6004C104.4 10.2004 105.1 9.90039 105.8 9.70039C106.5 9.50039 107.3 9.40039 108.1 9.40039C108.9 9.40039 109.7 9.50039 110.4 9.70039C111.1 9.90039 111.7 10.2004 112.3 10.7004C112.8 11.1004 113.3 11.6004 113.6 12.3004C113.9 12.9004 114.1 13.6004 114.1 14.4004V18.1004H114ZM111 14.8004C111 14.3004 110.9 13.9004 110.7 13.5004C110.5 13.1004 110.3 12.9004 110 12.6004C109.7 12.4004 109.4 12.2004 109 12.1004C108.6 12.0004 108.3 11.9004 107.9 11.9004C107.6 11.9004 107.2 12.0004 106.8 12.1004C106.4 12.2004 106.1 12.4004 105.8 12.6004C105.5 12.8004 105.3 13.1004 105.1 13.5004C104.9 13.9004 104.8 14.3004 104.8 14.8004V17.7004C104.8 18.7004 105.1 19.4004 105.7 19.9004C106.3 20.3004 107 20.5004 107.8 20.5004C108.1 20.5004 108.5 20.5004 108.9 20.3004C109.3 20.2004 109.6 20.0004 109.9 19.8004C110.2 19.6004 110.4 19.3004 110.6 18.9004C110.8 18.5004 110.9 18.1004 110.9 17.6004V14.8004H111Z" fill="black" />
        <path d="M32 4C32 1.8 30.2 0 28 0H4C1.8 0 0 1.8 0 4V28C0 30.2 1.8 32 4 32H28C30.2 32 32 30.2 32 28V4Z" fill="#EC2605" />
        <path d="M29.8001 12.4996C30.5001 14.5996 29.3001 16.8996 27.2001 17.5996C26.2001 17.8996 25.1001 17.7996 24.2001 17.3996C22.9001 16.7996 21.1001 16.7996 20.6001 18.4996C20.1001 20.1996 21.4001 21.1996 22.9001 21.4996C23.9001 21.6996 24.8001 22.1996 25.4001 23.0996C26.7001 24.8996 26.3001 27.3996 24.5001 28.6996C22.7001 29.9996 20.2001 29.5996 18.9001 27.7996C18.3001 26.9996 18.0001 25.9996 18.2001 24.9996C18.4001 23.4996 17.8001 21.8996 16.0001 21.8996C14.2001 21.8996 13.7001 23.4996 13.8001 24.9996C13.9001 25.9996 13.7001 26.9996 13.1001 27.8996C11.8001 29.6996 9.30005 30.0996 7.50005 28.7996C5.70005 27.4996 5.30005 24.9996 6.60005 23.1996C7.20005 22.2996 8.10005 21.7996 9.10005 21.5996C10.5001 21.2996 11.9001 20.1996 11.4001 18.5996C10.9001 16.8996 9.10005 16.8996 7.80005 17.4996C6.90005 17.8996 5.90005 17.9996 4.80005 17.6996C2.70005 16.9996 1.50005 14.6996 2.20005 12.5996C2.90005 10.4996 5.20005 9.29961 7.30005 9.99961C8.30005 10.2996 9.10005 10.9996 9.60005 11.8996C10.7001 13.8996 12.0001 13.8996 13.5001 12.9996C14.2001 12.5996 15.1001 12.2996 16.0001 12.2996C16.9001 12.2996 17.8001 12.5996 18.5001 12.9996C20.1001 13.8996 21.3001 13.8996 22.4001 11.8996C22.9001 10.9996 23.7001 10.2996 24.7001 9.99961C26.9001 9.19961 29.1001 10.3996 29.8001 12.4996ZM16.0001 10.5996C18.2001 10.5996 20.0001 8.79961 20.0001 6.59961C20.0001 4.39961 18.2001 2.59961 16.0001 2.59961C13.8001 2.59961 12.0001 4.39961 12.0001 6.59961C12.0001 8.69961 13.8001 10.5996 16.0001 10.5996Z" fill="white" />
    </svg>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ReviztoLogoFull extends Vue {}
</script>
