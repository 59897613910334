<template>
    <IssueDetailsItem
        v-if="showPreview"
        :label="$t('IssueTracker.headers.markup')"
    >
        <WsImage
            v-if="issuePreview"
            :src="issuePreview.small"
            class="issue-details-preview"
            @click="openPreview"
        />
    </IssueDetailsItem>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { IPreview } from '@/models';
import { AbstractComment } from '@/domain/comment/models/AbstractComment';
import { Issue } from '@/domain/issue/models/Issue';
import { MarkupComment } from '@/domain/comment/models/MarkupComment';
import IssueDetailsItem from '@/domain/issue/components/details/IssueDetailsItem.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsPreview extends Vue {
    get showPreview(): boolean {
        const hasPreview = Boolean(this.issuePreview?.small);
        const selectedOne = this.multiSelectedIssuesCount < 2;
        return hasPreview && selectedOne && this.isCollapsedPreviewColumn;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get currentIssueComments(): AbstractComment[] {
        return this.$store.getters.commentsByIssue(this.projectId, this.currentIssue.uuid);
    }

    get issuePreview(): IPreview | null {
        let preview: IPreview | null = null;
        _.forEachRight(this.currentIssueComments, (comment: AbstractComment) => {
            if (comment instanceof MarkupComment) {
                preview = comment.preview;
                return false;
            }
        });
        return preview || this.currentIssue.preview;
    }

    get multiSelectedIssuesCount() {
        return this.$store.getters.multiSelectedIssues.length;
    }

    get isCollapsedPreviewColumn() {
        const trackerColumns = this.$store.getters.trackerColumns;
        const previewColumn = trackerColumns.find(({ name }: any) => name === 'TrackerPreview');
        return previewColumn.collapsed;
    }

    public openPreview() {
        window.dispatchEvent(new Event('editMarkup'));
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.issue-details-preview {
    &:hover {
        cursor: zoom-in;
    }
}
</style>
