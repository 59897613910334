<template>
    <div :class="{ collapsed }" class="resizable-column">
        <div
            v-if="isColumnClosed"
            :class="{ 'issue-column-background_is-background-solid': isBackgroundSolid }"
            class="issue-column-background"
        >
            <slot name="closed-background" />
        </div>

        <div :class="{ 'bottom-padding': collapsed && hideTitle }" class="column-header" @click="toggleAllHeader">
            <div v-if="!collapsed" class="column-title">
                {{ title }}&nbsp;
                <template v-if="isIssuesLoaded && selectedIssuesCount">
                    <div class="issue-count">{{ $t('IssueTracker.countIssues', { issuesCount, selectedIssuesCount }) }}</div>
                </template>
                <template v-if="isIssuesLoaded && !selectedIssuesCount">
                    <div class="issue-count single">{{ issuesCount }}</div>
                </template>
            </div>

            <slot name="additional" />

            <div
                v-if="collapsable"
                :class="{ collapsed }"
                class="collapsing-icon"
                @click.stop="toggleCollapseColumn"
            >
                <IconSvg24
                    v-if="!collapsed"
                    icon-name="arrow-double-left"
                    stateless
                />
                <IconSvg24
                    v-if="collapsed"
                    icon-name="arrow-double-right"
                    stateless
                />
            </div>

            <IconSvg24
                v-if="collapsed"
                :icon-name="icon"
                :tooltip="title"
                placement="right"
                stateless
                block
            />
        </div>

        <template v-if="!collapsed">
            <div class="column-actions">
                <slot v-if="!isHideActions" name="actions" />
            </div>

            <div class="column-body">
                <slot />
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import { AmplitudeEvent, BusEvent } from '@/constants';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog } from '@/services';
import { IColumnSize, IColumnToggle } from '@/storage/issueTracker.storage';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg24,
    },
})
export default class IssueColumn extends Vue {
    @Prop({ required: true }) public componentName!: string;
    @Prop({ required: true }) public icon!: string; // https://vuetifyjs.com/en/components/icons/
    @Prop({ required: true }) public title!: VueI18n.TranslateResult;
    @Prop() public issuesCount!: number | undefined;
    @Prop({ type: Boolean, default: false }) public collapsed!: boolean;
    @Prop({ type: Boolean, default: false }) public hideTitle!: boolean;
    @Prop({ type: Boolean, default: false }) public isHideActions!: boolean;
    @Prop({ type: Boolean, default: true }) public collapsable!: boolean;
    @Prop({ type: Boolean, default: true }) public resizable!: boolean;
    @Prop({ type: Boolean, default: false }) public hasIssuesCheckedContent!: boolean;
    @Prop({ default: 'right' }) public collapsingSide!: 'right' | 'left';

    get selectedIssuesCount() {
        return this.$store.getters.multiSelectedIssues.length;
    }

    get isBackgroundSolid() {
        return this.hasIssuesCheckedContent && !this.collapsed;
    }

    get isColumnClosed() {
        return this.$store.getters.isColumnClosed(this.componentName);
    }

    get isIssuesLoaded() {
        return this.issuesCount !== undefined;
    }

    @Emit()
    public toggleCollapseColumn() {
        amplitudeLog(AmplitudeEvent.itPanelsVisibilityChange, {
            panel: this.componentName,
            action: this.collapsed ? 'show' : 'collapse',
        });

        const toggle: IColumnToggle = {
            name: this.componentName,
            side: this.collapsingSide,
            collapsed: !this.collapsed,
        };

        this.$store.dispatch('toggleColumn', toggle);
        eventBus.$emit(BusEvent.toggleColumn);

        return !this.collapsed;
    }

    @Emit()
    public resize(data: any) {
        const resize: IColumnSize = {
            name: this.componentName,
            size: data.width,
        };
        this.$store.dispatch('resizeColumn', resize);
        return;
    }

    public toggleAllHeader() {
        if (this.collapsed) {
            this.toggleCollapseColumn();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.column-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $select-black;
}

.resizable-column {
    @include scrollbar;

    overflow-y: auto;
    display: grid;
    grid-template-rows: 33px 40px 1fr;
    position: relative;

    &.collapsed {
        display: flex;
        width: 40px;
        min-width: 40px;
        max-width: 40px;

        .column-header {
            flex-direction: column;
            justify-content: flex-start;
            grid-gap: 0;
            background: $light;
            padding: 0;
            cursor: pointer;
            width: 100%;
            transition-duration: .2s;
            overflow: hidden;

            &:hover {
                background: $divider-gray;
            }

            &.bottom-padding {
                padding-bottom: $container-padding;
            }
        }

        .column-title {
            transform: rotate(-90deg);

            height: 100px;
            display: flex;
            align-items: center;
            overflow: hidden;
        }

        .column-body {
            display: none;
        }
    }
}

.column-header {
    padding: 0 $container-padding;
    border-bottom: $border;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;

    &:not(:hover) {
        .suppressed {
            color: $disable-gray;
        }
    }
}

.suppressed {
    transition-duration: .2s;
}

.collapsing-icon {
    $extend: $container-padding - 9;

    transition-property: background;
    transition-duration: .2s;
    padding: $extend;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:not(.collapsed) {
        margin-right: -12px;

        &:hover {
            background: $disable-gray;
        }
    }
}

.column-body {
    @include scrollbar;
    overflow-y: auto;
    display: grid;
}

.issue-count {
    border: 1px solid $primary-blue;
    background: $primary-blue;
    border-radius: 15px;
    color: white;
    display: inline-block;
    padding: 0 6px;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;

    &.single {
        border-color: $select-black;
        background: transparent;
        color: $select-black;
        font-weight: 500;
    }
}

.column-actions {
    height: 40px;
    background: $light;
    display: flex;
    align-items: center;
    padding: 0 $container-padding;
}

.issue-column-background {
    background: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    opacity: 0.7;

    &_is-background-solid {
        opacity: 1;
    }
}
</style>
