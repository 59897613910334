<template>
    <div
        class="issue-stamp-abbr"
        :style="style"
    >
        {{ abbr }}
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StampColorsPaletteRGB, StampColorsPaletteRGBEnum } from '@/domain/stamp/constants/StampColorsPaletteRGBEnum';

@Component
export default class StampAbbr extends Vue {
    @Prop({ default: false }) public abbr!: string;
    @Prop({ default: false }) public color!: number;

    get stampColor() {
        return StampColorsPaletteRGBEnum[this.color] || StampColorsPaletteRGB[0];
    }

    get style() {
        return `color:rgb(${this.stampColor});border-color:rgb(${this.stampColor});`;
    }

}
</script>

<style scoped lang="scss">
@import '@/styles/variables';

.issue-stamp-abbr {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    min-width: 48px;
    padding: 0 6px;
    width: auto;
    border-radius: 15px;
    border: 2px solid;
    font-weight: bold;
    font-size: 12px;
    background: $white;
}

</style>
