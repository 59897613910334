import _ from 'lodash';
import {
    Protobuf,
} from '@/services';
import { CdeLink, ProcoreRFILink } from '@/domain/issue/models/Issue';
import { AbstractComment } from '@/domain/comment/models/AbstractComment';
import {
    getClashArea, getClashGrid,
    getClashLevel,
    getClashRoom,
    getClashSpace,
    getClashZone,
} from '@/domain/issue/services/IssueProperties';

export interface IDiffItem {
    old: string | string[] | CdeLink[] | ProcoreRFILink;
    new: string | string[] | CdeLink[] | ProcoreRFILink;
}

function newDiffItem(oldProps: any, newProps: any) {
    return {
        old: oldProps,
        new: newProps,
    };
}

export class DiffComment extends AbstractComment {
    public diff: { [key: string]: IDiffItem };

    private readonly _unpackClashes?: { old: any; new: any };
    private readonly _unpackRClashes?: { old: any; new: any };
    private readonly _unpackPositionProperties?: { old: any; new: any };

    constructor(comment: any = {}) {
        super(comment);
        this.diff = comment.diff;

        if (this.diff?.positionProperties) {
            const oldValue = this.diff.positionProperties.old as string | undefined;
            const newValue = this.diff.positionProperties.new as string | undefined;
            const oldProps = oldValue && this.decodeProperty(oldValue);
            const newProps = newValue && this.decodeProperty(newValue);

            this._unpackPositionProperties = newDiffItem(oldProps, newProps);
            delete this.diff.positionProperties;
        }

        if (this.diff?.clashes) {
            const oldProps = this.unpackClashes(this.diff.clashes.old);
            const newProps = this.unpackClashes(this.diff.clashes.new);
            this._unpackClashes = newDiffItem(oldProps, newProps);
            delete this.diff.clashes;
        }

        if (this.diff?.rClashes) {
            const oldProps = this.unpackRClashes(this.diff.rClashes.old);
            const newProps = this.unpackRClashes(this.diff.rClashes.new);
            this._unpackRClashes = newDiffItem(oldProps, newProps);
            delete this.diff.rClashes;
        }

        if (this._unpackPositionProperties || this._unpackClashes || this._unpackRClashes) {
            const oldClashes = this._unpackClashes?.old;
            const newClashes = this._unpackClashes?.new;
            const oldRClashes = this._unpackRClashes?.old;
            const newRClashes = this._unpackRClashes?.new;
            const oldPositionProperties = this._unpackPositionProperties?.old;
            const newPositionProperties = this._unpackPositionProperties?.new;

            const posProperties = {
                level: {
                    old: getClashLevel(oldClashes, oldRClashes, oldPositionProperties),
                    new: getClashLevel(newClashes, newRClashes, newPositionProperties),
                },
                area: {
                    old: getClashArea(oldRClashes, oldPositionProperties),
                    new: getClashArea(newRClashes, newPositionProperties),
                },
                room: {
                    old: getClashRoom(oldRClashes, oldPositionProperties),
                    new: getClashRoom(newRClashes, newPositionProperties),
                },
                space: {
                    old: getClashSpace(oldRClashes, oldPositionProperties),
                    new: getClashSpace(newRClashes, newPositionProperties),
                },
                zone: {
                    old: getClashZone(oldRClashes, oldPositionProperties),
                    new: getClashZone(newRClashes, newPositionProperties),
                },
                grid: {
                    old: getClashGrid(oldClashes, oldRClashes, oldPositionProperties),
                    new: getClashGrid(newClashes, newRClashes, newPositionProperties),
                },
            };
            Object.entries(posProperties).forEach(([key, value]) => {
                if (!_.isEqual(value.old, value.new)) {
                    this.diff[key] = value;
                }
            });
        }
    }
  
    private unpackClashes(value: any, _isRClash: boolean = false): any {
        let result: {
            items?: any[];
            strings?: string[];
            compressed?: any;
            compressMethod?: number;
        } = {};

        if (value?.length === 0) {
            return result;
        }

        try {
            if (typeof value.protoBase64 !== 'undefined') {
                result = Protobuf.decodeClashes(value);

                if (result.compressMethod === 1 && result.compressed) {
                    const decompressed: any = Protobuf.decompressClashes(result.compressed);
                    if (decompressed.strings) {
                        result.strings = decompressed.strings;
                    }
                    if (decompressed.items) {
                        result.items = decompressed.items;
                    }
                }
            }
        } catch (e) {
            window.console.warn('DiffComment.unpackClashes error:', e);
        }
        return result;
    }

    private unpackRClashes(value: any) {
        let result: {
            items?: any[];
            strings?: string[];
            compressed?: any;
            compressMethod?: number;
        } = {};

        if (value?.length === 0) {
            return result;
        }

        try {
            if (typeof value.protoBase64 !== 'undefined') {
                result = Protobuf.decodeRClashes(value);

                if (result.compressMethod === 1 && result.compressed) {
                    const decompressed: any = Protobuf.decompressRClashes(result.compressed);
                    if (decompressed.strings) {
                        result.strings = decompressed.strings;
                    }
                    if (decompressed.items) {
                        result.items = decompressed.items;
                    }
                }
            }

            return result;
        } catch (e) {
            window.console.warn('DiffComment.unpackRClashes error:', e);
        }
    }

    public decodeProperty(value: string) {
        return Protobuf.decodeProperty(value);
    }
}
