<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

@Component
export default class TrackerColumnComponentBase extends EventListenersBase {
    @Prop({ required: true, default: false }) public collapsed!: boolean;
    @Prop({ required: true, default: false }) public fixed!: boolean;
    @Prop({ default: false }) public hideTitle!: boolean;
    @Prop({ default: true }) public isAllowLoadingContent!: boolean;

    @Emit('content-loaded')
    public emitContentLoadedEvent() {
        return this.$options.name;
    }
}
</script>
