<template>
    <div class="component-notification-filter">
        <div class="filters-box">
            <WsTableFilterSelect
                v-model="filterAuthor.selections"
                :items="filterAuthorOptions"
                :label="$t('Notification.author')"
                :append-to-body="false"
                placement="bottom-start"
                amp-mask
                @input="change"
            />
        </div>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { LicenseMember, NotificationRecord } from '@/models';
import WsTableFilterSelect from '@/components/common/WsTableFilterSelect.vue';

@Component({
    components: {
        WsTableFilterSelect,
    },
})
export default class NotificationFilter extends Vue {
    @Prop({ required: true }) public records!: NotificationRecord[];

    public filterAuthor = {
        selections: [] as string[],
    };

    get filterAuthorOptions() {
        return this.records.map(({ authorEmail }) => ({
            value: authorEmail,
            text: this.nameFormatter(authorEmail),
        }));
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get licenseMembers(): LicenseMember[] {
        return this.$store.getters.licenseMembersByLicenseId(this.licenseId);
    }

    get licenseMembersByEmail() {
        return _.keyBy(this.licenseMembers, 'email');
    }

    get filteredRecords(): NotificationRecord[] {
        const filterAuthorSet = new Set(this.filterAuthor.selections);
        if (!filterAuthorSet.size) {
            return this.records;
        }
        return this.records.filter(({ authorEmail }) => filterAuthorSet.has(authorEmail));
    }

    @Emit()
    public change() {
        return this.filteredRecords;
    }

    public created() {
        this.$store.dispatch('loadLicenseMembers', {
            licenseId: this.licenseId,
        });
    }

    public nameFormatter(email: string) {
        return this.licenseMembersByEmail[email]?.fullname || email;
    }
}
</script>

<style lang="scss" scoped>
.notification-filter > .filters-box {
    display: flex;
    padding: 5px 0;
    border-bottom: none;
}
</style>
