<template>
    <div
        class="ws-multiselect"
        :class="{ disabled }"
    >
        <div
            :id="activatorId"
            class="ws-multiselect-custom-activator"
        >
            <div v-if="$slots.activator || $scopedSlots.activator">
                <slot :name="MultiSelectNamedSlot.Activator" :is-open="isOpen" />
            </div>
            <div
                v-if="!$slots.activator && !$scopedSlots.activator"
                :class="{ 'ws-multiselect-field--error': error }"
                class="ws-multiselect-field"
            >
                <div
                    :class="{ 'has-right': $slots.right }"
                    class="content"
                >
                    <div v-if="!selected.length || hideValues" class="placeholder">{{ inputPlaceholder }}</div>
                    <div
                        v-if="!hideValues"
                        class="values"
                    >
                        <div
                            v-for="item in localSelected"
                            :key="item.value"
                            class="value-item"
                        >
                            <WsTruncate
                                :value="item.label"
                                :size="selectedLabelSize"
                                single-line
                                :amp-mask="ampMask"
                            />
                            <IconSvg16
                                icon-name="x"
                                passive
                                @click.stop="onMenuItemChange(item)"
                            />
                        </div>
                    </div>
                    <div class="right">
                        <slot :name="MultiSelectNamedSlot.Right" />
                        <span
                            v-if="!$slots.right"
                            :class="{ open: isOpen }"
                            class="values-arrow"
                            @click.stop="toggleMenu"
                        >
                            <IconSvg16
                                icon-name="arrow-down"
                                passive
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <v-menu
            v-model="isOpen"
            :close-on-content-click="false"
            :max-width="width"
            :min-width="width"
            :activator="`#${activatorId}`"
            content-class="ws-multiselect-menu"
            transition="fade-transition"
            offset-y
            offset-overflow
            @input="onChangeDropdownVisibility"
        >
            <v-list>
                <div class="header">
                    <WsInputSearchV2
                        v-if="searchable"
                        v-model="searchQuery"
                        :placeholder="$t('Simple_word.search')"
                        autofocus
                    />
                    <WsCheckbox2
                        v-if="isShowSelectAll"
                        :value="isAllSelected"
                        :indeterminate="isIndeterminate"
                        :label="$t('Text.selectAll')"
                        class="header-checkbox"
                        @change="onToggleAll"
                    />
                </div>
                <div :style="styles" class="menu-list">
                    <WsLazyRender :items="itemsToShow">
                        <template #default="{ items }">
                            <WsMultiSelectItem
                                v-for="item in items"
                                :key="item.value"
                                :item="item"
                                :disabled-tooltip="disabledTooltip"
                                :is-active="isActive(item)"
                                :is-items-slots="isItemsSlots"
                                :is-show-checkbox="!single"
                                @click="onMenuItemChange(item)"
                            >
                                <slot
                                    v-if="isItemsSlots"
                                    :name="item.value"
                                />
                                <span v-else>
                                    <WsTruncate
                                        :value="item.value"
                                        :size="itemStringLimit"
                                        single-line
                                        amp-mask
                                    />
                                </span>
                            </WsMultiSelectItem>
                        </template>
                    </WsLazyRender>
                    <v-list-item v-if="isEmptyList" disabled>
                        <div class="empty">
                            {{ $t('errors.noItemSelection') }}
                        </div>
                    </v-list-item>
                    <v-list-item v-if="isEmptySearch">
                        <div class="not-found empty">
                            <div v-if="!creatable" class="not-found-text">{{ $t('errors.search_no_matches') }}</div>
                            <button
                                v-if="creatable"
                                class="menu-create-button"
                                @click="createNewItem()"
                            >
                                <div class="menu-create-button-text">{{ searchQuery }}</div>
                                <div class="menu-create-button-action">{{ createActionText }}</div>
                            </button>
                        </div>
                    </v-list-item>
                </div>
            </v-list>
        </v-menu>
        <div v-if="error" class="ws-multiselect-error">
            {{ errorText }}
        </div>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { IComboboxOption } from '@/types/common';
import { i18n } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsInputSearchV2 from '@/components/common/WsInputSearchV2.vue';
import WsMultiSelectItem from '@/components/common/WsMultiSelectItem.vue';

enum MultiSelectNamedSlot {
    Activator = 'activator',
    Right = 'right'
}

@Component({
    components: {
        IconSvg16,
        WsTruncate,
        WsLazyRender,
        WsTooltip,
        WsInputSearchV2,
        WsMultiSelectItem,
    },
})
export default class WsMultiSelect extends Vue {
    @VModel() selected!: string[];
    @Prop({ required: true, default: () => [] }) public options!: IComboboxOption[];
    @Prop({ default: 'value' }) public valueKey!: string;
    @Prop({ default: 'label' }) public labelKey!: string;
    @Prop({ default: i18n.t('Simple_word.disabled') }) public disabledTooltip!: string;
    @Prop({ default: i18n.t('Text.selectOrEnter') }) public inputPlaceholder!: string;
    @Prop({ default: '336px' }) public maxHeight!: string;
    @Prop({ default: '336px' }) public minHeight!: string;
    @Prop({ default: '351px' }) public width!: string;
    @Prop({ type: Boolean, default: false }) public single!: boolean;
    @Prop({ type: Boolean, default: false }) public error!: boolean;
    @Prop({ type: Boolean, default: false }) public hideValues!: boolean;
    @Prop({ type: Boolean, default: false }) public creatable!: boolean;
    @Prop({ type: Boolean, default: true }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ default: '' }) public errorText!: string;
    @Prop({ default: i18n.t('Simple_word.new') }) public createActionText!: string;
    @Prop({ default: 15 }) public selectedLabelSize!: number;
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;

    public readonly componentUuid = uuidv4();
    public readonly activatorId = `activator-${this.componentUuid}`;
    public readonly attachSelector = `[data-uuid="${this.componentUuid}"]`;
    public readonly MultiSelectNamedSlot = MultiSelectNamedSlot;
    public readonly itemStringLimit = 35;
    public isOpen = false;

    public searchQuery = '';

    get localOptions() {
        return this.options;
    }

    get localSelected() {
        return this.options.filter(({ value }) => this.selected.includes(value));
    }

    get itemsToShow(): IComboboxOption[]  {
        const lowerQuery = this.searchQuery.toLowerCase();

        if (!lowerQuery.length) {
            return this.localOptions;
        }

        return this.localOptions.filter(
            (option) => (
                option.searchValue.includes(lowerQuery)
            ),
        );
    }

    get isShowSelectAll() {
        return !this.single && (!this.isEmptyList && !this.isEmptySearch) && this.activeItemsToShow.length;
    }

    get activeItemsToShow(): IComboboxOption[] {
        return this.itemsToShow.filter(({ disabled }) => !disabled);
    }

    get isEmptySearch() {
        return Boolean(this.searchQuery.length) && !this.itemsToShow.length;
    }

    get isEmptyList() {
        return !this.itemsToShow.length && !this.searchQuery.length;
    }

    get styles() {
        return `max-height: ${this.maxHeight}; min-height: ${this.minHeight}`;
    }

    // If search value is not empty, we need to toggle only items which shows.
    get itemsToToggleAll() {
        return this.searchQuery.length ? this.itemsToShow : this.localOptions;
    }

    get isIndeterminate() {
        return this.localSelected.length > 0
            && _.intersectionBy(this.localSelected, this.itemsToToggleAll, 'value').length < this.itemsToToggleAll.length;
    }

    get isAllSelected() {
        return _.intersectionBy(this.localSelected, this.itemsToToggleAll, 'value').length === this.itemsToToggleAll.length;
    }

    get activeLocalOptions() {
        return this.localOptions.filter(({ disabled }) => !disabled);
    }

    get isItemsSlots() {
        const namedSlots = Object.values(MultiSelectNamedSlot);
        // Here we see if it is slots besides named one
        return Boolean(_.difference(Object.keys(this.$slots), namedSlots).length);
    }

    @Emit()
    public change() {
        return this.selected;
    }

    @Emit()
    public async createNewItem() {
        const newItem = {
            value: this.searchQuery,
            label: this.searchQuery,
            searchValue: this.searchQuery,
        };
        this.localOptions.push(newItem);
        this.onMenuItemChange(newItem);
        this.clearSearch();

        return this.localOptions;
    }

    public onChangeDropdownVisibility(value: boolean) {
        if (!value) {
            this.clearSearch();
        }
    }

    public isActive(item: IComboboxOption) {
        return _.some(this.localSelected, { value: item.value });
    }

    public onMenuItemChange(item: IComboboxOption) {
        if (item.disabled) {
            return;
        }

        if (this.single) {
            this.onSingleChange(item);
        } else {
            this.onMultiChange(item);
        }

        this.change();
    }

    public onMultiChange(item: IComboboxOption) {
        const i = this.selected.findIndex((element) => element === item.value);

        if (i > -1) {
            this.selected.splice(i, 1);
        } else {
            this.selected.push(item.value);
        }
    }

    public onSingleChange(item: IComboboxOption) {
        this.selected = [item.value];
        this.toggleMenu();
    }

    public toggleMenu() {
        this.isOpen = !this.isOpen;
    }

    public async onToggleAll(value: boolean) {
        this.selected = value ? this.itemsToToggleAll.map(({ value }) => value) : [];

        if (!this.localSelected) {
            return;
        }

        await this.$nextTick();
        this.change();
    }

    public clearSearch() {
        this.searchQuery = '';
    }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.ws-multiselect {
    .ws-multiselect-field {
        position: relative;
        padding: 5px 8px 5px 12px;
        border: 1px solid $grey-middle;
        border-radius: 4px;

        &--error {
            border-color: $light-error;
            margin: 0 0 4px;
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .ws-multiselect-error {
        color: $light-error;
        font-size: 12px;
        line-height: 16px;
    }

    .placeholder {
        font-size: 14px;
        height: 20px;
        color: $light-solid-60;
    }

    .projects-table {
        margin-top: 10px;
    }

    .content {
        padding-right: 24px;
        cursor: text;

        &.has-right {
            padding-right: 140px;
        }
    }

    .values {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
    }

    .value-item {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        background-color: $light-solid-5;
        color: $light-solid-80;
        padding: 2px 4px 2px 8px;
        border-radius: 2px;

        ::v-deep {
            .svg-icon {
                cursor: pointer;
            }
        }
    }

    .right {
        position: absolute;
        top: 0px;
        right: 6px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .values-arrow {
        display: inline-flex;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;

        &.open {
            transform: rotate(180deg);
        }
    }

    ::v-deep .v-messages {
        display: none;
    }

    ::v-deep .v-input__slot {
        margin: 0;
    }

    ::v-deep .v-input--checkbox {
        margin: 0;
    }

}

.ws-multiselect-menu {
    border: none !important;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);

    .header {
        position: relative;
        padding: 16px 16px 8px;
        border-bottom: 1px solid $light-solid-10;

        &:before {
            content: '';
            position: absolute;
            height: 1px;
            width: 16px;
            background-color: $white;
            left: 0;
            bottom: -1px;
        }

        ::v-deep .v-input--checkbox {
            margin-top: 8px;
        }

        ::v-deep .v-label {
            color: $light-solid-100;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .header-checkbox {
        padding: 17px 0 0 4px;
        grid-gap: 15px;

        .label {
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            color: $light-solid-100;
        }
    }

    .menu-create-button {
        display: flex;
        width: 100%;
        justify-content: space-between;
        line-height: 20px;
        letter-spacing: 0.056px;
        margin: 8px 0 0;
        cursor: pointer;
        gap: 15px;
    }

    .menu-create-button-text {
        color: $light-solid-100;
        font-weight: 700;
        word-break: break-all;
    }

    .menu-create-button-action {
        color: $light-accent;
        flex-shrink: 0
    }

    .menu-list {
        @include scrollbarNew;
        .v-list-item {
            padding: 0;
        }
    }

    .empty {
        width: 100%;
        padding: 0 16px;
        text-align: center;
        color: $dark-solid-60;
    }

    .not-found {
        width: 100%;
        padding: 16px;
    }

    .not-found-text {
        color: $dark-solid-60;
    }

    .theme--light.v-list-item:hover::before {
        opacity: 0;
    }

    .v-list {
        padding: 0;
    }

}

</style>
