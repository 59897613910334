<template>
    <v-text-field
        v-model="model"
        :label="label"
        :error-count="maxMessages"
        :messages="messages"
        :error-messages="errorMessages"
        :success-messages="successMessages"
        :error="error"
        :rules="rules"
        :hide-details="hideDetails"
        :hint="hint"
        :persistent-hint="persistentHint"
        :outlined="outlined"
        :dense="dense"
        :required="required"
        :placeholder="placeholder"
        :type="localType"
        :append-icon="icon"
        :clearable="clearable"
        :autofocus="autofocus"
        :disabled="disabled"
        :name="name"
        :maxlength="maxlength"
        :validate-on-blur="validateOnBlur"
        :class="{ small }"
        class="test-f-search"
        @click:append="showText = !showText"
        @click:clear="clear"
        @change="change"
        @blur="handleBlur"
        @focus="handleFocus"
    >
        <slot />
        <template #prepend-inner>
            <IconSvg24 icon-name="lupa" stateless :focused="focused" />
        </template>

        <template #label v-if="required">
            <span class="required-star">*</span>{{ label }}
        </template>
    </v-text-field>
</template>

<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator';
import WsInput from '@/components/common/WsInput.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg24,
    },
})
export default class WsInputSearch extends WsInput {
    // @ts-ignore
    @Prop({ default: 'auto' }) public hideDetails!: boolean | string;
    // @ts-ignore
    @Prop({ type: Boolean, default: true }) public clearable!: boolean;
    // @ts-ignore
    @Prop({ type: Boolean, default: false }) public small!: boolean;

    public focused: boolean = false;

    @Emit()
    public clear(value: any) {
        return value;
    }

    public handleFocus(value: any) {
        this.focused = true;
        this.focus(value);
    }
    public handleBlur(value: any) {
        this.focused = false;
        this.blur(value);
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__prepend-inner {
    padding-right: 4px;
}
</style>
