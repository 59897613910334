<template>
    <div class="issue-comment-diff-deleted-at">
        <template v-if="isDelete">
            {{ $t('IssueTracker.diffComments.issueDeleted') }}
        </template>
        <template v-else>
            {{ $t('IssueTracker.diffComments.issueRestored') }}
        </template>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { IDiffItem } from '@/domain/comment/models/DiffComment';

@Component
export default class IssueCommentDeletedAt extends Vue {
    @Prop({ required: true }) public diff!: Dict<IDiffItem>;

    get isDelete() {
        return Boolean(this.diff.new);
    }
}
</script>
