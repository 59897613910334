import http from '@/api/http';

export default class CommentApi  {
    /**
     * Получение списка комментов для ишьюсов
     * Временный метод
     */
    public static getComments(issueUuid: string, params: any): Promise<any> {
        return http.get(`issue/${issueUuid}/comments`, params).then((response) => response.data);
    }

    public static postComments(_issueUuid: string, params: any): Promise<any[]> {
        return http.post('comment/add', params, true, true).then((response) => response.data);
    }

    /**
     * Read comments for one issue
     */
    public static readIssueComments({ projectId, issueUuid, count }: { projectId: number, issueUuid: string, count: number }) {
        return http.post(`issue/${issueUuid}/comments/read`, { project_id: projectId, count }).then((response) => response.data);
    }

    /**
     * Pin chat comment
     */
    public static pinChatComment(projectId: number, issueUuid: string, commentUuid: string): Promise<any> {
        return http.post(`project/${projectId}/issue/${issueUuid}/pin-comment`, { commentid: commentUuid }).then((response) => response.data);
    }

    /**
     * Pin chat comment
     */
    public static unpinChatComment(projectId: number, issueUuid: string, commentUuid: string): Promise<any> {
        return http.post(`project/${projectId}/issue/${issueUuid}/unpin-comment`, { commentid: commentUuid  }).then((response) => response.data);
    }
}