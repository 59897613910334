<template>
    <div class="revizto-logo" ref="logo">
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="24"
                height="24"
                rx="3"
                :fill="Color.miskRed"
            />
            <path
                d="M22.3515 9.51962C22.8674 11.1065 21.9956 12.8109 20.4088 13.3268C19.648 13.5749 18.8643 13.5031 18.1917 13.1896C17.1991 12.726 15.8898 12.775 15.4817 14.0125C15.08 15.2565 16.1118 16.063 17.1861 16.2719C17.9175 16.4123 18.5933 16.8172 19.0635 17.4637C20.0463 18.8155 19.7459 20.706 18.3942 21.6855C17.0457 22.6684 15.1519 22.368 14.1723 21.0162C13.7022 20.3697 13.5291 19.5991 13.6173 18.8645C13.7544 17.7739 13.3005 16.5429 11.9977 16.5429C10.6917 16.5429 10.2411 17.7739 10.3782 18.8612C10.4697 19.5959 10.2933 20.3664 9.82643 21.0129C8.84362 22.3614 6.9531 22.6618 5.6046 21.6823C4.25283 20.6995 3.95244 18.8122 4.93525 17.4605C5.40543 16.814 6.08131 16.4123 6.80944 16.2687C7.88693 16.0597 8.91872 15.2532 8.51711 14.0092C8.11223 12.7684 6.80291 12.7195 5.80704 13.1864C5.13768 13.4998 4.35079 13.5717 3.59001 13.3235C2.00315 12.8076 1.13462 11.1032 1.64725 9.51636C2.16314 7.92623 3.86754 7.06097 5.45767 7.57686C6.21845 7.82501 6.80944 8.34417 7.17187 8.99067C7.98815 10.4698 8.94484 10.4828 10.1138 9.78084C10.6623 9.44453 11.3055 9.25188 11.9977 9.25188H12.0043C12.6965 9.25188 13.343 9.44453 13.8915 9.78084C15.0572 10.4828 16.0171 10.4698 16.8367 8.99067C17.1926 8.34417 17.7868 7.82501 18.5476 7.57686C20.1345 7.06097 21.8389 7.9295 22.358 9.51636L22.3515 9.51962ZM12.001 8.04704C13.6695 8.04704 15.0245 6.69201 15.0245 5.02352C15.0245 3.35503 13.6695 2 12.001 2C10.3325 2 8.97749 3.35177 8.97749 5.02352C8.97749 6.69201 10.3325 8.04704 12.001 8.04704Z"
                fill="white"
            />
        </svg>
        <div
            :class="{ 'white-text': white }"
            class="workspace-name"
        >
            <slot>{{ $t('Menu.workspace') }}</slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { Color } from '@/constants';
import ResizeMixin from '@/mixins/resizeMixin';

@Component
export default class ReviztoLogo extends ResizeMixin {
    @Prop({ type: Boolean, default: false }) public white!: boolean;

    public readonly Color = Color;

    public handleResize() {
        const el = this.$refs.logo as HTMLElement;
        this.$store.commit('setHeaderComponentSize', { name: 'logo', size: el.offsetWidth });
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.revizto-logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 200px;
    font-weight: bold;
    color: $menu;
    box-sizing: content-box;

    svg {
        flex-shrink: 0;
    }

    > div {
        margin-left: $default-padding;
        line-height: 14px;
        font-weight: 700;
        font-size: 14px;
    }
    .white-text {
        color: $white;
    }
    .workspace-name {
        white-space: nowrap;
    }
}
</style>
