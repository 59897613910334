<template>
    <div class="presets-skeleton">
        <div>
            <SkeletonBone class="presets-skeleton_first-row-left" />
            <SkeletonBone class="presets-skeleton_first-row-right" />
        </div>
        <div>
            <SkeletonBone class="presets-skeleton_second-row" />
        </div>
        <div>
            <SkeletonBone class="presets-skeleton_third-row-left" />
            <SkeletonBone class="presets-skeleton_third-row-right" />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonTrackerPresets extends Vue {
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.presets-skeleton {
    display: flex;
    flex-direction: column;

    > * {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $default-gap;
        gap: $default-gap;
        height: 52px;
    }

    > div:first-child {
        border-bottom: $border;
    }

    .presets-skeleton_first-row-left,
    .presets-skeleton_second-row,
    .presets-skeleton_third-row-left {
        width: 100%;
    }

    .presets-skeleton_first-row-right {
        width: 36px;
    }

    .presets-skeleton_third-row-right {
        width: 36px;
    }
}
</style>
