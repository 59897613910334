<template>
    <v-file-input
        :value="value"
        :accept="accept"
        :label="label"
        :counter="counter"
        :multiple="multiple"
        :show-size="showSize"
        :small-chips="smallChips"
        :placeholder="placeholder || $t('Collocation.selectFile')"
        :append-icon="appendIcon"
        :class="[
            onlySlot ? 'only-slot' : null,
            hideIcon ? 'hide-icon' : null,
        ]"
        :single-line="singleLine"
        class="ws-file-input"
        ref="fileInput"
        prepend-icon=""
        truncate-length="50"
        outlined
        dense
        @change="change"
        @click="onClick"
        @click:append="onClickAppendIcon"
    >
        <template #selection="selection">
            <v-chip v-if="showFileList" small label>
                {{ selection.text }}
                <IconSvg16
                    passive
                    icon-name="x"
                    @click.stop.prevent="removeSelected(selection)"
                />
            </v-chip>
        </template>
        <template #label>
            <slot />
        </template>
    </v-file-input>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsFileInput extends Vue {
    @Prop() public value: File[] | null = null;
    @Prop({ type: String, default: '*' }) public accept!: string;
    @Prop({ default: '' }) public label!: TranslateResult;
    @Prop({ type: Boolean, default: true }) public multiple!: boolean;
    @Prop({ type: Boolean, default: true }) public singleLine!: boolean;
    @Prop({ type: Boolean, default: false }) public hideIcon!: boolean;
    @Prop({ type: Boolean, default: true }) public smallChips!: boolean;
    @Prop({ type: Boolean, default: false }) public counter!: boolean;
    @Prop({ type: Boolean, default: false }) public showSize!: boolean;
    @Prop({ type: Boolean, default: false }) public loading!: boolean;
    @Prop({ type: Boolean, default: true }) public showFileList!: boolean;
    @Prop({ type: Boolean, default: false }) public onlySlot!: boolean;
    @Prop({ type: String, default: '' }) public placeholder!: TranslateResult;
    @Prop({ type: String, default: '$file' }) public appendIcon!: string;

    @Emit('change')
    public change(files: File[]) {
        return files;
    }

    onClick(event: MouseEvent) {
        event.stopPropagation();
    }

    public removeSelected(selection: any) {
        const newValue = [...this.value as File[]];
        newValue.splice(selection.index, 1);

        this.change(newValue);
    }

    public onClickAppendIcon() {
        (this.$refs.fileInput as any)?.$refs?.input.click();
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.ws-file-input.v-file-input {
    cursor: pointer;

    ::v-deep .v-chip {
        border-radius: $border-radius-small;
        background: var(--light-solid-5, #F3F3F3);
    }
}

.ws-file-input ::v-deep .v-text-field__slot:hover {
    cursor: pointer;
}

::v-deep {
    .v-text-field__details {
        display: none !important;
    }
    
    .v-input__slot {
        cursor: pointer !important;
    }
}

.ws-file-input {
    &.hide-icon {
        ::v-deep {
            .v-input__append-inner {
                display: none !important;
            }
        }
    }
}

.only-slot {
    height: 14px !important;
}

.only-slot ::v-deep {
    fieldset {
        display: none !important;
    }
}

</style>
