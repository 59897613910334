<template>
    <v-text-field
        v-model="model"
        :required="required"
        :disabled="disabled"
        :min="min"
        :max="max"
        :suffix="suffix"
        :class="{
            'has-suffix': suffix,
        }"
        :id="id"
        ref="vTextField"
        class="ws-input-number-extended-component"
        type="number"
        @change="change"
        @blur="blur"
    >
        <slot />

        <template #label v-if="required">
            <span class="required-star">*</span>{{ label }}
        </template>

        <template #append>
            <div class="arrows">
                <span class="arrow-up" @click="up" />
                <span class="arrow-down" @click="down" />
            </div>
        </template>
    </v-text-field>
</template>

<script lang="ts">
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class WsInputNumberExtended extends VuetifyElement {
    @Prop({ type: Number, default: 1 }) public min!: number;
    @Prop({ type: Number, default: 100 }) public max!: number;
    // @ts-ignore
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    // @ts-ignore
    @Prop({ type: Boolean, default: false }) public required!: boolean;
    @Prop({ type: String, default: '' }) public suffix!: string;

    public start = 0;
    public id = 'input-' + uuidv4();

    @Emit()
    public input(value: string) {
        if (Number(value) > this.max) {
            return this.max;
        }

        if (Number(value) < this.min) {
            return this.min;
        }

        return Number(value);
    }

    @Emit()
    public change(value: string) {
        if (Number(value) > this.max) {
            return this.max;
        }

        if (Number(value) < this.min) {
            return this.min;
        }

        return Number(value);
    }

    @Emit()
    public blur(event: MouseEvent) {
        const target = event?.target as HTMLInputElement;
        if (target) {
            target.value = this.model;
        }

        const vTextField = this.$refs.vTextField as any;
        if (vTextField && vTextField.initialValue && vTextField.lazyValue) {
            vTextField.initialValue = this.model;
            vTextField.lazyValue = this.model;
        }

        return this.model;
    }

    @Watch('model')
    public onModelChanged(model: number) {
        if (model > this.max) {
            this.model = this.max;
        }
    }

    public mounted() {
        const handler = (evt: any) => {
            if (Number(evt.target.value) > this.max) {
                evt.target.value = this.max;
                this.value = this.max;
            }
        };
        this.eventListeners.add({ node: document, selector: `#${this.id}`, event: 'input', handler });
    }

    public up() {
        if (this.model < this.max) {
            this.model++;
        }
    }

    public down() {
        if (this.model > (this.min)) {
            this.model--;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.v-text-field {
    padding: 0 !important;
    margin: 0 !important;
    height: 32px !important;
}

::v-deep {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
        text-align: center;

    }

    .v-input {
        width: 80px;
        height: 32px;
    }

    .v-input__slot {
        height: 32px !important;
        border: solid 1px $default-black;
        border-radius: 24px !important;
        text-align: center;

        &:before,
        &:after {
            display: none;
        }
    }

    .arrows {
        width: $default-icon-height;
        height: $default-icon-height;
        border: solid 1px $default-black;
        border-radius: $default-icon-height;
        margin: -5px -41px 0 8px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: stretch;
        align-content: center;
        z-index: 100;
        position: relative;
        cursor: default;
    }

    .arrow-up {
        cursor: pointer;
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid $default-black;

        &:active {
            border-top-color: $medium-gray;
        }
    }

    .arrow-down {
        cursor: pointer;
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid $default-black;

        &:active {
            border-top-color: $medium-gray;
        }
    }
}

.ws-input-number-extended-component ::v-deep.v-text-field__suffix {
    padding-left: 2px;
    padding-right: 0.7rem;
}

.ws-input-number-extended-component.has-suffix ::v-deep.v-text-field__slot input {
    text-align: right;
}

</style>
