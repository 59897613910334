export class RandomService {
    public static string(size: number = 8): string {
        let result = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < size; i++) {
            result += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return result;
    }

    public static stringDifferent(sizeMin: number = 8, sizeMax: number = 12): string {
        let result = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-';
        const countOfCharts = Math.floor(Math.random() * (sizeMax - sizeMin + 1)) + sizeMin;

        for (let i = 0; i < countOfCharts; i++) {
            result += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return result;
    }

    public static deviceId() {
        return RandomService.string(16);
    }
}
