export const LICENSE_IMAGE_ASPECT_RATIO = 334 / 177;
export const USER_IMAGE_ASPECT_RATIO = 1;
export const COMBO_BOX_MIN_ITEMS_IN_LIST = 5;
export const COOKIE_KEY_RESOURCE_IDENTIFIER = 'integration-resourceIdentifier';
export const MULTIPLE = 'multiple';
export const FIELD_MAX_LENGTH = 255;
export const NAME_MAX_LENGTH = 64;
export const TAGS_MAX_LENGTH = 64;
export const COMMA_SPACER = ', ';
export const BYTES_IN_MIB = 1048576;
export const TRUNCATE_FIXED_LIMIT = 200;
export const TRUNCATE_TOOLTIP_MAX_WIDTH = 250;
export const SIDEBAR_DEFAULT_WIDTH = 600;
