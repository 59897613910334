<template>
    <div>
        <WsTruncateAuto :value="fullName || email" css-class="name amp-mask" />
        <WsTruncateAuto v-if="member" :value="member.company" css-class="company amp-mask" />
    </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import ProjectMemberName from '@/components/project/ProjectMemberName.vue';

@Component({
    components: {
        WsTruncateAuto,
    },
})
export default class ProjectMemberItem extends ProjectMemberName {
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

::v-deep .name {
    font-weight: 400;
    font-size: $font-size;
    line-height: 18px;
    color: $select-black;
}

::v-deep .company {
    font-size: 13px;
    line-height: $line-height-small;
    color: $default-black;
}
</style>
