import http from '@/api/http';

export default class IssuePresetApi {
    /**
     * Получение списка пресетов фильтров проекта
     */
    public static getIssueFilterPresets(projectId: number): Promise<any> {
        return http.get(`project/${projectId}/issue-filter-set`).then((response) => response.data);
    }

    /**
     * Save filters as a new preset
     */
    public static postAddIssuesFilterSet(projectId: number, entities: any[]): Promise<any> {
        return http.post(`project/${projectId}/issue-filter-set/add`, {
            entities: JSON.stringify(entities),
        }).then((response) => response.data);
    }

    /**
     * Edit a preset
     */
    public static postEditIssuesFilterSet(projectId: number, entities: any[]): Promise<any> {
        return http.post(`project/${projectId}/issue-filter-set/edit`, {
            entities: JSON.stringify(entities),
        }).then((response) => response.data);
    }

    /**
     * Edit preset's visibility
     */
    public static postEditIssuesFilterSetVisibility(projectId: number, entities: any[]): Promise<any> {
        return http.post(`project/${projectId}/issue-filter-set/visibility`, {
            entities: JSON.stringify(entities),
        }).then((response) => response.data);
    }

    /**
     * Remove presets
     */
    public static postRemoveIssueFilterSet(projectId: number, entities: string[]): Promise<any> {
        return http.post(`project/${projectId}/issue-filter-set/remove`, {
            entities: JSON.stringify(entities),
        }).then((response) => response.data);
    }

    /**
     * Reorder presets
     */
    public static postReorderIssueFilterSets(projectId: number, order: string) {
        return http.post(`project/${projectId}/issue-filter-set/re-order`, { order })
            .then((response) => response.data);
    }
}