import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { Deep } from '@/services/Deep';

// This can be used to create a class that will store deep object data in localStorage, cookies, or any other storage.
export abstract class ApplicationObject {
    constructor(protected readonly key: string) {}

    protected abstract get obj(): Dict;

    protected abstract save(obj: Dict): void;

    public set(pathArray: string[] | string, value: any) {
        pathArray = _.castArray(pathArray);
        const modifiedObj = Deep.setWithPathArray(this.obj, pathArray, value);
        this.save(modifiedObj);
    }

    public get(pathArray: string[] | string) {
        pathArray = _.castArray(pathArray);
        return Deep.getWithPathArray(this.obj, pathArray);
    }
}