export enum SAMLCookie {
    authNRequestId = 'authNRequestId',
    transitionalAuth = 'transitionalAuth',
    SAMLResponse = 'SAMLResponse',
}

export const SAMLCookieNamesList = [
    SAMLCookie.authNRequestId,
    SAMLCookie.transitionalAuth,
    SAMLCookie.SAMLResponse,
];
