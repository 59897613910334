<template>
    <div
        v-click-outside="onClickOutside"
        :id="popoverId"
        :class="{ 'no-padding': noPadding, ['ws-popover-node-' + id]: id }"
        class="ws-popover"
    >
        <slot name="reference" :on-click="handleClick" />

        <div
            v-if="isOpen"
            ref="popover"
            :style="popoverStyles"
            :class="[
                isArrowShifted ? 'arrow-shifted-' + this.isArrowShifted : null,
                `placement-${placement}`,
            ]"
            class="slot"
        >
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import { Dict } from '@/types/Dict';
import { TUuid } from '@/types/common';
import { PopoverPlacement } from '@/constants';

@Component({ })
export default class WsPopover extends Vue {
    @VModel({ type: Boolean }) isOpen!: boolean;
    @VModel({ type: Boolean, default: false }) noPadding!: boolean;
    @Prop({ type: Number, default: 300 }) public width!: number;
    @Prop({ default: PopoverPlacement.bottom }) public placement!: PopoverPlacement;
    @Prop({ default: '' }) public alignmentNode!: string;
    @Prop({ default: '' }) public id!: TUuid;
    @Prop({ default: '' }) public stopClickOutsideClass!: string;

    public popoverUuid = uuid();
    public marginForCenterOfAlignmentNode: null | number  = null;
    public isArrowShifted = '';
    public isLeftOverflowed = false;
    public isRightOverflowed = true;

    @Emit()
    public show() {
        return;
    }

    @Watch('isOpen')
    public onStateChanged(value: boolean) {
        if (value) {
            this.show();
            this.touchUpPosition();
        }
    }

    get popoverId() {
        return `ws-popover-${this.popoverUuid}`;
    }

    get popoverStyles() {
        const styles: Dict = {
            width: this.width + 'px',
        };

        if (this.isLeftOverflowed) {
            styles['left'] = '0';
            styles['right'] = 'auto';
            styles['margin-left'] = '0';
            return styles;
        }

        if (this.isRightOverflowed) {
            styles['right'] = '0';
            styles['left'] = 'auto';
            styles['margin-left'] = '0';
            return styles;
        }

        styles['margin-left'] = `-${this.marginForCenterOfAlignmentNode}px`;
        return styles;
    }

    public handleClick() {
        this.isOpen = !this.isOpen;
    }

    public onClickOutside(e: Event) {
        if (this.stopClickOutsideClass) {
            let element = e.target as HTMLElement;

            if (!element) {
                return;
            }

            while (element !== document.body) {
                if (element.classList.contains(this.stopClickOutsideClass)) {
                    return;
                }

                if (!element.parentElement) {
                    return;
                }

                element = element.parentElement;
            }
        }

        this.isOpen = false;
    }

    public close() {
        this.isOpen = false;
    }

    public getAlignmentNodeRect() {
        if (!this.alignmentNode) {
            return;
        }

        const querySelector= this.alignmentNode.length ? `#${this.popoverId} .${this.alignmentNode}` : `#${this.popoverId}`;
        const alignmentNode = document.querySelector(querySelector);
        
        return alignmentNode?.getBoundingClientRect();
    }

    public mounted() {
        const alignmentRect = this.getAlignmentNodeRect();

        if (!alignmentRect) {
            return;
        }

        this.marginForCenterOfAlignmentNode = alignmentRect?.width
            ? Math.round((this.width / 2) - (Number(alignmentRect?.width) / 2) - 16)
            : null;
    }

    public touchUpPosition() {
        const rect = this.getAlignmentNodeRect();
        if (!rect) {
            return;
        }

        switch (this.placement) {
            // Now we support only bottom and same placements
            case PopoverPlacement.bottomEnd:
            case PopoverPlacement.bottom:
            case PopoverPlacement.bottomStart:
            default:
                if (rect.x < this.marginForCenterOfAlignmentNode!) {
                    this.marginForCenterOfAlignmentNode = rect.x - 16 - 8;
                    this.isArrowShifted = 'left';
                }

                const halfOfWidth = this.width / 2;
                const halfOfAlignmentNodeWidth = rect.width / 2;
                const overflowedWidth = halfOfWidth - halfOfAlignmentNodeWidth;

                if ((rect.x - overflowedWidth) < 0) {
                    this.isLeftOverflowed = true;
                    this.isRightOverflowed = false;
                    this.isArrowShifted = 'left';
                    return;
                }

                if ((rect.right + overflowedWidth) > window.innerWidth) {
                    this.isRightOverflowed = true;
                    this.isLeftOverflowed = false;
                    this.isArrowShifted = 'right';
                    return;
                }

                this.isLeftOverflowed = false;
                this.isRightOverflowed = false;
        }
    }
}
</script>

<style scoped lang="scss">
    .ws-popover {
        position: relative;
        
        .slot {
            position: absolute;
            top: calc(100% + 12px);
            padding: 15px;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
            z-index: 1000;
            background: white;
            border-radius: 4px;
            border: 1px solid #ebeef5;

            &:after {
                display: block;
                content: "";
                position: absolute;
                top: -6px;
                left: calc(50% - 5px);
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 6px solid white;
                filter: drop-shadow(0 2px 12px rgba(0,0,0,.03));
                z-index: 1000;
            }

            &.arrow-shifted-left {
                &:after {
                    left: 30px;
                }
            }

            &.arrow-shifted-right {
                &:after {
                    right: 30px;
                }
            }

            &.placement-bottom {
                left: 0;
                margin-left: calc(-50% - 10px);
            }

            &.placement-bottom-start {
                left: 0;
                margin-left: 0;
            }

            &.placement-bottom-end {
                right: 0;
                margin-left: 0;
            }
        }
    }
</style>
