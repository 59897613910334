<template>
    <div :class="wrapperClass">
        <v-dialog
            v-model="visible"
            :attach="stayInPlace ? `.${wrapperClass}` : false"
            :fullscreen="fullscreen"
            :transition="transition"
            :width="width"
            :max-width="maxWidth"
            :persistent="persistent"
            :no-click-animation="noAnimationOnClick"
            :content-class="contentClass"
            :hide-overlay="hideOverlay"
            overlay-opacity="0.5"
            ref="vdialog"
            @click:outside="outsideClick"
        >
            <v-card v-loading="isLoading">
                <v-card-title v-if="!isHeaderEmpty">
                    <div class="dialog-header">
                        <div :class="{ 'amp-mask': ampMaskTitle }" class="dialog-title">
                            <WsTruncateAuto
                                v-if="normalizedTitle.length && truncateTitle"
                                :value="normalizedTitle"
                                :max-width="600"
                            />
                            <template v-if="!truncateTitle">
                                {{ normalizedTitle }}
                            </template>
                            <template v-if="subtitle">
                                <span v-html="sanitizeHtml(subtitle)" class="subtitle" />
                            </template>
                        </div>
                    </div>
                </v-card-title>
                <WsCloseButton v-if="showClose" round @click="close" />
                <div
                    :class="{ 'with-buttons': $slots.buttons, 'full-width': fullWidth }"
                    class="dialog-body-wrap"
                >
                    <div class="body">
                        <div v-if="$slots.subheader" class="sub-header">
                            <slot name="subheader" />
                        </div>
                        <div class="dialog-body" :class="{ 'full-width': fullWidth }">
                            <slot />

                            <div class="dialog-result" v-if="$slots.result">
                                <div>
                                    <slot name="result" />
                                </div>
                            </div>
                            <div class="dialog-error" v-if="$slots.error">
                                <simple-svg filepath="/images/icons/error-circle.svg" width="20px" height="20px" />
                                <div>
                                    <slot name="error" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="$slots.buttons"
                        :class="[
                            { 'separated': separatedButtons },
                            ...buttonsClasses,
                        ]"
                        class="dialog-buttons"
                    >
                        <slot name="buttons" />
                    </div>
                </div>
            </v-card>
            <div v-if="overlay" class="ws-dialog-overlay">
                <div class="overlay-content">
                    <div class="bold">
                        {{ $t('Components.dialog.unsavedChanges.title') }}
                    </div>
                    {{ $t('Components.dialog.unsavedChanges.text') }}
                    <div class="overlay-buttons">
                        <WsButton
                            new
                            low-priority
                            small
                            @click="forceClose()"
                        >
                            {{ $t('Components.dialog.unsavedChanges.discard') }}
                        </WsButton>
                        <WsButton
                            new
                            low-priority
                            small
                            @click="keepEdit"
                        >
                            {{ $t('Components.dialog.unsavedChanges.keep') }}
                        </WsButton>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import { TranslateResult } from 'vue-i18n';
import { sanitizeHtml } from '@/services';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        WsButton,
        WsTooltip,
        WsTruncateAuto,
        WsCloseButton,
    },
})
export default class WsDialog extends Vue {
    @Prop() public title!: TranslateResult;
    @Prop() public subtitle!: string;
    @Prop({ type: String, default: '15vh' }) public top!: string; // Margin from top of the window, in vh only.
    @Prop({ type: String, default: '50%' }) public width!: string;
    @Prop({ type: String, default: '' }) public maxWidth!: string;
    @Prop({ type: String, default: '.v-application' }) public attach!: string;
    @Prop({ type: String, default: '' }) public dataTest!: string;
    @Prop({ type: String, default: '' }) public dialogClass!: string;
    @Prop({ type: Array, default: () => [] }) public buttonsClasses!: string[];

    @Prop({ type: Boolean, default: false }) public loading!: boolean;
    @Prop({ type: Boolean, default: false }) public fullscreen!: boolean;
    @Prop({ type: Boolean, default: false }) public fullWidth!: boolean;
    @Prop({ type: Boolean, default: false }) public appendToBody!: boolean;
    @Prop({ type: Boolean, default: true }) public closeOnClickModal!: boolean;
    @Prop({ type: Boolean, default: false }) public noClickAnimation!: boolean;
    @Prop({ type: Boolean, default: false }) public modalAppendToBody!: boolean;
    @Prop({ type: Boolean, default: false }) public separatedButtons!: boolean;
    @Prop({ type: Boolean, default: false }) public noBodyScroll!: boolean;
    @Prop({ type: Boolean, default: false }) public fixedButtons!: boolean;
    @Prop({ type: Boolean, default: true }) public showClose!: boolean;
    @Prop({ type: Boolean, default: false }) public hideOverlay!: boolean;
    @Prop({ type: Boolean, default: false }) public stayInPlace!: boolean;
    @Prop({ type: Boolean, default: true }) public truncateTitle!: boolean;
    @Prop({ type: Boolean, default: false }) public ampMaskTitle!: boolean;
    @Prop({ type: Boolean, default: false }) public overlay!: boolean;

    public readonly sanitizeHtml = sanitizeHtml;
    public readonly uuid = uuidv4();

    public visible = true;

    get wrapperClass() {
        return `ws-dialog-wrapper-${this.uuid}`;
    }

    get contentClass() {
        const extenderHeaderClass = this.$slots.subheader ? 'extended-header' : '';
        const emptyHeaderClass = this.isHeaderEmpty ? 'empty-header' : '';
        const fixedButtonsClass = this.fixedButtons ? 'fixed-buttons' : '';
        const noBodyScroll = this.noBodyScroll ? 'no-body-scroll' : '';

        return `ws-dialog ${extenderHeaderClass} ${emptyHeaderClass} ${fixedButtonsClass} ${noBodyScroll} ${this.dialogClass}`;
    }

    get persistent() {
        return this.fullscreen || !this.closeOnClickModal;
    }

    get noAnimationOnClick() {
        return this.fullscreen || this.noClickAnimation;
    }

    get transition() {
        return this.fullscreen ? 'dialog-fade-transition' : 'dialog-transition';
    }

    get isHeaderEmpty() {
        return !this.normalizedTitle.length && !this.subtitle;
    }

    get isLoading() {
        if (this.loading !== undefined) {
            return this.loading;
        }
        return this.$store.getters.isDialogLoading;
    }

    get normalizedTitle() {
        return this.title ? this.title.toString() : '';
    }

    public mounted() {
        const vdialogComponent = this.$refs.vdialog as Vue | undefined;

        if (!vdialogComponent?.$refs.dialog) {
            return;
        }

        if (!this.fullscreen) {
            (vdialogComponent.$refs.dialog as HTMLElement).style.maxHeight = `calc(100% - (${this.top} + 5vh))`;
            (vdialogComponent.$refs.dialog as HTMLElement).style.marginTop = this.top;
        }

        if (this.dataTest) {
            (vdialogComponent.$refs.dialog as HTMLElement).dataset.test = this.dataTest;
        }

    }

    @Watch('visible')
    public afterClose(value: boolean) {
        if (!value) {
            this.close();
        }
    }

    @Emit()
    public close() {
        return;
    }

    @Emit()
    public keepEdit() {
        return;
    }

    @Emit()
    public outsideClick() {
        return;
    }

    @Emit()
    public forceClose() {
        return;
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';
@import '@/styles/wsui/colors.scss';

$line-size: 70px;
$padding: 24px;

.word-break {
    word-break: break-all;
}

.ws-dialog-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.ws-close-button {
    position: absolute;
    top: 11px;
    right: 20px;
}

.sub-header {
    border-bottom: 1px solid $divider-gray;
    padding: 0 $padding;
}

.dialog-title {
    font-size: 24px;
    font-weight: $font-weight;
    overflow: hidden;
}

 .v-dialog .v-card {
    display: grid;
    height: 100%;
    overflow: hidden;
    .v-card__title {
        padding: 0;
    }
}

.dialog-header {
    display: flex;
    min-height: $line-size;
    padding: 14px $padding;
    border-bottom: 1px solid $divider-gray;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;

    .extended-header & {
        border-bottom: none;
    }

    .dialog-title {
        width: 90%;
    }
}

::v-deep .ws-dialog {
    color: $select-black;
    background-color: $white;
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
}

::v-deep .dialog-buttons div.container {
    padding: 0;
}

.dialog-body {
    height: 100%;
    padding: $padding;
    font-size: $font-size;
    text-align: left;
    text-overflow: ellipsis;

    .no-body-scroll & {
        overflow: hidden;
    }

    &.full-width {
        display: grid;
        padding: 0;
    }

    ::v-deep hr::after {
        left: -$padding;
        right: -$padding;
    }
}
.dialog-error {
    padding-top: $padding;
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 10px;
    align-content: baseline;
    text-align: left;
    padding-bottom: 32px;
    color: $error;
}
.dialog-result {
    padding: 0 $padding;
    text-align: left;
}
.dialog-buttons {
    padding: $container-padding $padding;
    text-align: right;

    &.separated {
        border-top: $border;
    }
}
.dialog-body-wrap {
    display: grid;
    padding-bottom: $container-padding;
    @include scrollbar;

    &.with-buttons {
        grid-template-rows: 1fr $line-size;
        padding-bottom: 0;
    }

    .fixed-buttons & {
        overflow: hidden;
    }
}

.body {
    grid-template-columns: 1fr;

    .no-body-scroll & {
        overflow: hidden;
    }

    .fixed-buttons:not(.no-body-scroll) & {
        @include scrollbar;
    }
}

::v-deep .v-dialog--fullscreen {
    .v-card {
        display: grid;
        height: 100vh;
        grid-template-rows: $line-size 1fr;
    }

    .dialog-body-wrap {
        padding-bottom: 0;
    }

    &.empty-header .v-card {
        grid-template-rows: 1fr;
    }
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 80%;
}

::v-deep {
    .v-dialog--fullscreen {
        .dialog-title .activator {
            font-size: $font-size-huge;
            line-height: 28px;
        }

        .dialog-header {
            border-bottom: transparent;
        }

        .v-card__title {
            border-bottom: solid 1px $color-solid-10;
        }

        .ws-close-button {
            top: 27px;
        }

        .widget-close .ws-close-button {
            top: 0;
        }
    }
}

::v-deep {
    .v-card__title {
        overflow: hidden;
    }
}

::v-deep {
    .ws-dialog {
        position: relative;
    }

    .ws-dialog-overlay {
        background: #fff;
        position: absolute;
        top: 44px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;

        .bold {
            font-weight: bold;
            color: $light-solid-80;
        }

        .overlay-buttons {
            margin: 16px 0 0 0;
            text-align: center;
            display: flex;
            gap: 16px;
        }
    }
}
</style>
