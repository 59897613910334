<template>
    <div class="component-login">
        <div class="auth-box" v-loading="isLoadingAfterOAuth">
            <div v-if="isLoadingAfterOAuth" class="loader-box" />
            <div v-else class="auth-form-box">
                <ReviztoLogo />
                <ForgotPasswordForm v-if="isForgotPasswordRoute" />
                <ChangePasswordForm v-else-if="isChangePasswordRoute" />
                <RegisterForm v-else-if="isRegisterRoute" />
                <AuthVariants v-else @authorized="goToWorkspace" />
            </div>
            <CompanyNews />
        </div>
    </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { BusEvent, CookieName, NoAccessCode, RESPONSE, RouterNames } from '@/constants';
import { License } from '@/models';
import { eventBus } from '@/services/eventBus';
import { CookieHelper, RandomService } from '@/services';
import ForgotPasswordForm from '@/components/user/ForgotPasswordForm.vue';
import ChangePasswordForm from '@/components/user/ChangePasswordForm.vue';
import RegisterForm from '@/components/user/RegisterForm.vue';
import ReviztoLogo from '@/components/svg/ReviztoLogo.vue';
import AuthVariants from '@/components/user/AuthVariants.vue';
import CompanyNews from '@/components/user/CompanyNews.vue';

@Component({
    components: {
        ForgotPasswordForm,
        ChangePasswordForm,
        RegisterForm,
        ReviztoLogo,
        AuthVariants,
        CompanyNews,
    },
})
export default class Login extends Vue {
    @Prop({ default: true }) public visible!: boolean;

    public isLoadingAfterOAuth = false;

    get language(): string {
        return this.$route.params.language;
    }

    get licenses(): License[] {
        return this.$store.getters.licenses;
    }

    get availableLicenses(): License[] {
        return this.$store.getters.licensesWithValidAuth;
    }

    get isForgotPasswordRoute() {
        return Boolean(this.$route.meta?.isForgotPasswordRoute);
    }

    get isChangePasswordRoute() {
        return Boolean(this.$route.meta?.isChangePasswordRoute);
    }

    get isRegisterRoute() {
        return Boolean(this.$route.meta?.isRegisterRoute);
    }

    get startPathAfterLogin(): string {
        return this.$store.getters.startPathAfterLogin;
    }

    public created() {
        this.authorizeWsAtGoogle();
        this.checkAppSSOKeysFromQuery();
    }

    public checkAppSSOKeysFromQuery() {
        const query = this.$route.query;
        if (query?.app_sso_key) {
            this.$store.commit('setSSOCookies', query);
        } else {
            this.$store.commit('removeSSOCookies');
        }
    }

    public authorizeWsAtGoogle() {
        const { code, service } = this.$route.query;
        if (!code || !service) {
            return;
        }

        let payload: any = {
            code,
            service,
            landing: 'oauth',
        };
        let isAccessCode = false;

        if (CookieHelper.isTrue('isAccessCode')) {
            isAccessCode = true;
            payload = { ...payload, authType: 'oauth2code', deviceId: RandomService.deviceId() };
            CookieHelper.remove('isAccessCode');
        }

        this.isLoadingAfterOAuth = true;
        this.$store.dispatch('checkOAuthCode', payload).then((data) => {
            const loginAccessCodeData = CookieHelper.get(CookieName.loginAccessCodeData);

            if (isAccessCode) {
                const { code: accessCode, licenses } = data;
                let props = this.$router.resolve({
                    name: RouterNames.AccessCode,
                    query: { accessCode, licenses: JSON.stringify(licenses) },
                });

                location.href = props.href;
                return;
            }

            // It means, that we are logging from PowerBi
            if (loginAccessCodeData) {
                // In this method we get the link to redirect
                return this.$store.dispatch('getUserAccessCode', loginAccessCodeData);
            }
            return this.goToWorkspace();
        }).catch((error) => {
            if (error.result === RESPONSE.NO_LICENSES_FOUND) {
                this.$router.push({
                    name: RouterNames.AccessCode,
                    params: { accessCode: NoAccessCode, licenses: JSON.stringify([]) },
                });
                return;
            }
            return this.$router.push({ name: RouterNames.Login });
        }).finally(() => {
            this.isLoadingAfterOAuth = false;
        });
    }

    public async goToWorkspace() {
        await this.$store.dispatch('downloadRegionServiceData');
        await this.$store.dispatch('loadLicenses', true);

        // This two request is neccessary for release notes notifications
        await this.$store.dispatch('downloadUserData');
        await this.$store.dispatch('downloadReleaseNotes');

        if (!this.licenses.length) {
            await this.$router.push({ name: RouterNames.StartPage });
            return;
        }

        if (this.$route.query.redirect === '/user/security') {
            await this.$router.push({ name: RouterNames.UserSecurity });
            return;
        }

        if (!this.availableLicenses.length) {
            this.$router.push({
                name: RouterNames.SelectLicense,
                query: this.$route.query,
            });
            return;
        }

        const isSwitchedLicense = this.checkSwitchLicense();
        if (!isSwitchedLicense) {
            const resolved: Route = this.$router.resolve({ path: this.startPathAfterLogin }).resolved;
            await this.$router.push({
                name: resolved.name || RouterNames.StartPage,
                query: resolved.query,
                params: {
                    ..._.cloneDeep(resolved.params),
                    language: this.language,
                },
            });
        }

        this.$store.dispatch('connectNotifier');
        this.$store.commit('loadRecentProjects');

        this.synchronizeLanguage();

        if (VueCookies.get(CookieName.isShowOnboardingSteps)) {
            this.$store.commit('setIsShowBoardingSteps');
            VueCookies.remove(CookieName.isShowOnboardingSteps);
        }

        eventBus.$emit(BusEvent.showReleaseNotesNotification);
    }

    public async synchronizeLanguage() {
        const currentLanguage = this.$store.getters.currentLanguage;
        await this.$store.dispatch('downloadUserData');
        const user = this.$store.getters.user;
        const userLanguage = user?.language;
        if (userLanguage && userLanguage !== currentLanguage) {
            this.$store.dispatch('uploadUserData', { language: currentLanguage });
        }
    }

    public checkSwitchLicense() {
        const resolved: Route = this.$router.resolve({ path: this.startPathAfterLogin }).resolved;
        const licenseId = Number(resolved.params.licenseId);
        if (!licenseId) {
            return;
        }

        if (!_.find(this.availableLicenses, (license: License) => license.id === licenseId)) {
            const orderedLicenses = _.sortBy(this.availableLicenses, (license: License) => license.frozen);
            const switchedLicenseId = orderedLicenses[0].id;

            this.$router.push({
                name: RouterNames.StartPage,
                params: { licenseId: String(switchedLicenseId), language: this.language },
            });
            return true;
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .revizto-logo {
    padding: 0 15px;
}
</style>
