<template>
    <WsTableFilterElement
        v-model="popover"
        :label="label"
        :width="570"
        :id="id"
        :stop-click-outside-class="StopClickOutsideFilterClass"
        @clear="clear"
    >
        <div class="filter-date-grid">
            <div class="reset">
                <div class="link" @click="onReset">{{ $t('IssueTracker.timeFilters.reset') }}</div>
            </div>
            <div class="value">
                <template v-if="dateFilterType === DateFilterOptions.durationMoreThan">
                    {{ $t('IssueTracker.timeFilters.durationMoreThan', { days: form.durationDays, unit: form.durationUnits }) }}
                </template>
                <template v-else-if="dateFilterType === DateFilterOptions.durationLessThan">
                    {{ $t('IssueTracker.timeFilters.durationLessThan', { days: form.durationDays, unit: form.durationUnits }) }}
                </template>
                <template v-else-if="dateFilterType === DateFilterOptions.durationEqual">
                    {{ $t('IssueTracker.timeFilters.durationEqual', { days: form.durationDays, unit: form.durationUnits }) }}
                </template>
                <template v-else>
                    {{ $t('Simple_word.all') }}
                </template>
            </div>
            <div class="choose-mode">
                <v-radio-group v-model="dateFilterType">
                    <div class="radios">
                        <v-radio :value="DateFilterOptions.durationMoreThan" :label="$t('Dashboard.chart.settings.filter.expr.duration.7')" />
                        <v-radio :value="DateFilterOptions.durationEqual" :label="$t('Dashboard.chart.settings.filter.expr.duration.4')" />
                        <v-radio :value="DateFilterOptions.durationLessThan" :label="$t('Dashboard.chart.settings.filter.expr.duration.5')" />
                    </div>
                </v-radio-group>
            </div>
            <div class="main">
                <div v-if="[DateFilterOptions.durationMoreThan, DateFilterOptions.durationEqual, DateFilterOptions.durationLessThan].includes(dateFilterType)" class="form">
                    <WsInputNumber
                        v-model="form.durationDays"
                        :maxlength="5"
                        :min="0"
                        outlined
                        dense
                        hide-details
                        type="number"
                        class="days-number"
                    />
                    <WsSelect
                        v-model="form.durationUnits"
                        :items="timeUnits"
                        :attach="`.ws-popover-node-${id} .time-limit-unit`"
                        class="time-limit-unit"
                    />
                </div>
            </div>
        </div>

        <slot />

        <template #button>
            <template v-if="dateFilterType === DateFilterOptions.durationMoreThan">
                {{ $t('IssueTracker.timeFilters.durationMoreThan', { days: form.durationDays, unit: form.durationUnits }) }}
            </template>
            <template v-else-if="dateFilterType === DateFilterOptions.durationLessThan">
                {{ $t('IssueTracker.timeFilters.durationLessThan', { days: form.durationDays, unit: form.durationUnits }) }}
            </template>
            <template v-else-if="dateFilterType === DateFilterOptions.durationEqual">
                {{ $t('IssueTracker.timeFilters.durationEqual', { days: form.durationDays, unit: form.durationUnits }) }}
            </template>
            <template v-else>
                {{ $t('Simple_word.all') }}
            </template>
        </template>
    </WsTableFilterElement>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import WsDateRangePicker from '@/components/common/WsDateRangePicker.vue';
import WsTableFilterDatepicker from '@/components/common/WsTableFilterDatepicker.vue';
import FilterByDateBase from '@/components/common/FilterByDateBase.vue';

@Component({
    components: {
        WsDateRangePicker,
        WsTableFilterDatepicker,
    },
})
export default class WsIssueTrackerFilterDuration extends FilterByDateBase {
    public id = uuid();
}
</script>

<style scoped lang="scss">
@import '@/styles/itDateFilters.scss';
.form {
    display: grid;
    grid-template-columns: 2fr 3fr 3fr;
    grid-column-gap: 5px;
}
</style>
