<template>
    <div v-if="items.length">
        <p v-if="question" class="question" v-html="sanitizeHtml(question)" />

        <div v-if="!isSingleMode" class="items">
            <div v-if="itemLabel" class="item-label">{{ itemLabel }}</div>
            <div class="items-list">
                <template v-if="localItemsWithUuids.length">
                    <div v-for="({ item, uuid }, index) in localItemsWithUuids" :key="uuid" class="item-row">
                        <WsTooltip
                            :disabled="!isTitleTruncatedArr[index]"
                            :tooltip="nameFilter(item)"
                            activator-class="item-title"
                            :amp-mask="isMembers || ampMask"
                        >
                            {{ nameFilter(item) }}
                        </WsTooltip>
                        <IconSvg24
                            v-if="removable"
                            icon-name="close"
                            stateless
                            @click="remove(uuid)"
                        />
                    </div>
                </template>
                <template v-else-if="!localItemsWithUuids.length">
                    {{ isProjects ? $t('Collocation.noProjects') : $t('Collocation.noUsers') }}
                </template>
            </div>
        </div>

        <div v-if="description" v-html="sanitizeHtml(description)" />
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import { TranslateResult } from 'vue-i18n';
import {
    LicenseMember,
    MemberForApproval,
    MemberForApprovalEnhanced,
    Project,
    ProjectMember,
} from '@/models';
import { isOverflownElementHorizontally, sanitizeHtml } from '@/services';
import WsTooltip from '@/components/common/WsTooltip.vue';
import EventListenersBase from '@/components/common/EventListenersBase.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import { nameFilter } from '@/domain/issueFilter/services/FiltersService';
import { IssueFilterPreset } from '@/domain/issuePreset/models/IssueFilterPreset';

type IItem = MemberForApprovalEnhanced
    | MemberForApproval
    | LicenseMember
    | ProjectMember
    | Project
    | IssueFilterPreset;

const MemberClasses = [LicenseMember, MemberForApproval, MemberForApprovalEnhanced, ProjectMember];

@Component({
    components: {
        WsTooltip,
        IconSvg24,
    },
})
export default class AffectedItems extends EventListenersBase {
    @Prop() public items!: IItem[];
    @Prop() public question!: TranslateResult;
    @Prop() public description!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public removable!: boolean;
    @Prop({ type: Boolean, default: false }) public isSingleMode!: boolean;
    @Prop({ type: Boolean, default: false }) public noLabel!: boolean;
    @Prop({ type: Boolean, default: false }) public ampMask!: boolean;

    public readonly nameFilter = nameFilter;
    public readonly sanitizeHtml = sanitizeHtml;

    public localItemsWithUuids: Array<{ item: IItem, uuid: string }> = [];
    public isTitleTruncatedArr: boolean[] = [];

    get localItems() {
        return this.localItemsWithUuids.map(({ item }) => item);
    }

    get isProjects() {
        return Boolean(this.items.length && this.items[0] instanceof Project);
    }

    get isMembers() {
        return this.items.length > 0 && MemberClasses.some((MemberClass) => this.items[0] instanceof MemberClass);
    }

    get itemLabel(): TranslateResult {
        if (this.noLabel) {
            return '';
        }

        if (this.isProjects) {
            return this.$t('Simple_word.projects');
        }

        if (this.items.length === 1) {
            return this.$t('Simple_word.user');
        }

        return this.$t('Simple_word.users');
    }

    @Emit()
    public remove(uuid: string) {
        this.localItemsWithUuids = this.localItemsWithUuids.filter(({ uuid: runningUuid }) => runningUuid !== uuid);
        return this.localItems;
    }

    public created() {
        this.localItemsWithUuids = this.items.map((item) => {
            const uuid = uuidv4();
            return { item, uuid };
        });
    }

    public async mounted() {
        await this.$nextTick();
        this.calculateTitleTruncated();
        this.eventListeners.add({ event: 'resize', handler: this.calculateTitleTruncated });
    }

    public calculateTitleTruncated() {
        const titles: HTMLElement[] = Array.from(document.querySelectorAll('.item-title'));
        this.isTitleTruncatedArr = titles.map(isOverflownElementHorizontally);
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.items {
    margin: 15px 0;
}
.items-list {
    max-height: 8em;
    overflow: auto;
    border: 1px solid $default-black;
    border-radius: 3px;
    line-height: 1.5em;
    padding: 0.1em 0.4em;

    @include scrollbar;
}
.item-label {
    font-size: 12px;
    line-height: $line-height-small;
    letter-spacing: 0.004em;
    background: $white;
    color: $medium-gray;
    padding: 0 8px;
}
.item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-title {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
    }

    ::v-deep .svg-icon {
        height: 21px;
        margin-top: -3px;
    }
}

.icon {
    cursor: pointer;
}
.question {
    font-weight: 500;
}
</style>
