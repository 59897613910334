import { ScheduleTriggers } from './ScheduleTriggers';

export interface Schedule {
    enabled: boolean;
    endDate: number;
    triggers: ScheduleTriggers;
}

export enum ClashAutomationSettings {
    All = 0,
    Labels = 1,
    Tests = 2,
}

export enum TaskStatus {
    Queued = 'queued',
    Skipped = 'skipped',
    Success = 'success',
    Failure = 'failure',
    Running = 'running',
    PartialFailure = 'partialFailure',
    DeviceUnreachable = 'deviceUnreachable',
}

export enum TaskRunMessageLevel {
    Error = 4,
    Success = 1,
    Warning = 3,
    Info = 2
}

export enum TaskType {
    Import = 'import',
    Export = 'export',
    ClashDetection = 'clashDetection',
}